/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../API/api";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../forms/helpercomponents/CustomGridCells/DateCell";
import { LookupCell } from "../forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT } from "../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { utility } from "../utility/utilityProvider";
import { ArrayCell } from "../forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../forms/helpercomponents/buttons/RoundButton";
import { PlatformCell } from "../forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { IconCell } from "../forms/helpercomponents/CustomGridCells/IconCell";
import socket from "../socket/socket";
import { getter } from "@progress/kendo-data-query";
import { MyToggleButton } from "../forms/helpercomponents/CustomGridCells/MyToggleButton";
import EditRoundButton from "../forms/helpercomponents/buttons/EditRoundButton";
import CollectionSelection from "../forms/CollectionSelection";
import { ConfirmAlert } from "../../ConfirmAlert";
import Preview from "../../modules/platformLayout/tv/homePage/components/Preview";
import { Loader } from "../forms/helpercomponents/Loader";
import BossDialog from "../../components/BossDialog";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const PageCollection = (props) => {

  //possible props
  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showImport = props.showImport ?? false;
  let showExport = props.showExport ?? true;
  let showArchive = props.showArchive ?? true;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showDelete = props.showDelete ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let CustomEditForm = props.customEditForm ?? EditForm;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? true;
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let sort = props.sort ?? { SID: 1 };
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let myMenuModel = props.menuModel ?? collectionMenuModel;
  // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let disableMutliSelect = props.disableMutliSelect ?? false;

  //Add this for media management preference column
  const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [showPreviewCollection, setShowPreviewCollection] = useState(false);

  const [selectedState, setSelectedState] = useState({});
  const [showRails, setShowRails] = useState(false);
  const [railsGridData, setRailsGridData] = useState([]);
  const [showAttachRails, setShowAttachRails] = useState(false);
  const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
  const [gridSort, setGridSort] = useState([initialSort]);

  const selectedItemsRef = useRef([]);
  const setSelectedItem = (items) => {
    selectedItemsRef.current = items;
  }

  const previewRef = useRef();
  const setPreviewRef = (data) => {
    previewRef.current = data
  }

  const railsRef = useRef();
  const setRailsRef = (data) => {
    railsRef.current = data
  }

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const location = useLocation();
  const lang = useTranslation();

  let href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement
  }


  useEffect(() => {
    setShowArchived(false);
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false);
  }, [props.entityname, props.gridData, props.title]);

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    let sortEvent = gridSort[0] ?? initialSort;
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        setIsLoading(true);
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setIsLoading(true);
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
    }
  }, [searchText]);

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false, item: item } });
    }
  };

  //publish for OTT
  const onPublish = async (itemID) => {
    console.log('publish');
    const res = await API.onPublishSeason(itemID);
    if (res.success) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

  };

  const handleNavigate = (item) => {
    if (item?.Module) {
      window.open(item.path, "MyTargetWindowName");
    } else {
      navigate(`${item.path}`, { state: item });
    }
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    console.log(dataItem);
    onConfirmDelete(dataItem)
  };

  const handleArchive = async (dataItem) => {
    if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
      var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
        _id: dataItem._id,
        archive: !dataItem.Archive,
        entityName: entityName
      })
    } else {
      var resArchiveUpdated = await API.saveData(entityName, {
        ...dataItem,
        Archive: !dataItem.Archive
      })
    }

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: MODULE.PAGE, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
      API.SaveLogs(logData);
      let sortEvent = gridSort[0] ?? initialSort;
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
    setShowPreferences(!showPreferences);

  }

  const setAsDefault = async (data) => {

    let setAsDefaultRes = await API.setAsDefault(entityName, data._id);
    if (setAsDefaultRes.success) {
      let sortEvent = gridSort[0] ?? initialSort;
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      toast.error(setAsDefaultRes.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const preview = (props) => {
    setPreviewRef(props)
    setShowPreviewCollection(true);
  }

  const onShowRails = (props) => {
    console.log(props);
    setRailsRef(props);
    setRailsGridData(props.Rails);
    setShowRails(true);
  }

  const onAttachRails = (item) => {
    setRailsRef(item);
    setShowAttachRails(true);
  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      publish={onPublish}
      history={history}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
      openNewEditForm={openNewEditForm}
      setAsDefault={setAsDefault}
      preview={preview}
      showRails={onShowRails}
      attachRails={onAttachRails}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDateCell = (props) => <DateOnlyCell {...props} />;
  const MyArrayCell = (props) => <ArrayCell {...props} />;
  const MyHrefCell = (props) => <HrefCell {...props} />;
  const MyEnumCell = (props) => <EnumCell {...props} />;

  const MyTimeCell = (props) => <TimeCell {...props} />;

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const MyImageCell = (props) => <ImageCell {...props} />;

  const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;

  const MyPlatfromCell = (props) => <PlatformCell {...props} />;

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.CurrentStatus} titleColor="#333" tileBackColor="#72eda9" />
    </div>
  )

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, isActiveRail) => {

    console.log('here')

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
        let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
        let json = await API.getPagesV2(
          { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
          columns,
          searchText,
          isActiveRail
        );

        console.log(json.data)
        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
        setIsNoRecords(true);
      }
    }
  };

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }

  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  }

  const onConfirmDelete = async (dataItem) => {
    let res = await API.deleteData(props.entityname, dataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    let current_page = page.skip / page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, '', showArchived);
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname);
  }

  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;

    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);

    setPage(e.page);
    cancelSelectedItem()
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true);
    } else {
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false);
    }
    cancelSelectedItem();
  }

  const refreshCollection = () => {
    setIsLoading(true);
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived);
    setGridSort([initialSort]);
  }

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort;
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived);
  }

  const downloadExcel = async () => {

    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.exportGridExcel(gridData, dataColumns);
    console.log(res.data);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    selectedIds.forEach((value) => {
      if (newSelectedState[value]) {
        let filter = gridData.find((obj) => obj._id == value);
        tempSelected.push(filter);
      }
    });

    setSelectedItem(tempSelected);
  };


  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    if (checked) {
      selectedIds.forEach((value) => {
        if (newSelectedState[value]) {
          let filtered = gridData.find((obj) => obj._id == value);
          tempSelected.push(filtered);
        }
      });
    }
    setSelectedItem(tempSelected);
  };

  const onArchiveButtonClick = async () => {
    if (selectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    for (const element of selectedItemsRef.current) {
      const item = element;
      await handleArchive(item);
    }
    cancelSelectedItem();
  }

  const onEnterEdit = () => {
    enterEdit({ SID: 0 });
    cancelSelectedItem();
  }

  const cancelSelectedItem = () => {
    setSelectedItem([]);
    setSelectedState({});
  }

  const editRailsGridData = (props) => {
    let data = props.dataItem;
    window.open(`/home/Rails/${data.SID}`, '_blank');
  }

  const deattachRails = async (data) => {

    let finalData = {
      _id: railsRef.current._id,
      SID: railsRef.current.SID,
      ott_rails: railsRef.current.ott_rails.filter(x => x != data._id)
    }

    let res = await API.saveData(ENTITYNAME.Page, finalData);

    let logData = { event: LOGEVENT.DEATTACH_CAMPAIGN, module: MODULE.PAGE, data: res.data, message: res.message };
    API.SaveLogs(logData);

    if (res.success) {
      // for ui 
      let deleteData = railsGridData.filter(x => x._id != data._id);
      setRailsGridData(deleteData);
      toast.success(lang.deattach_successfully_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      refreshCollection();
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const myRailsCommandCell = (props) => {
    return (
      <div className="mt-1" style={{ display: "flex" }}>
        <EditRoundButton onClick={() => editRailsGridData(props)} />
        <RoundButton icon="trash" onClick={() => ConfirmAlert(() => deattachRails(props.dataItem), () => { }, lang.confirm_deattach_label, lang.are_you_sure_do_you_want_to_deattach_rail_label)} />
      </div>
    );
  }

  const handleSelectedRails = async (data) => {

    let storeRailsData = []
    let duplicateData = [];

    data.map((item) => {
      let railAlreadyExist = railsRef.current.ott_rails.some(x => x == item._id);
      if (railAlreadyExist) {
        duplicateData.push(item);
      } else {
        storeRailsData.push(item._id);
      }
    });

    if (duplicateData.length > 0 && storeRailsData.length == 0) {
      toast.info(lang.rail_is_already_attach_in_selected_page_info_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let msg = "";
    if (duplicateData.length != 0) {
      let temp = duplicateData.map((obj) => obj["Title"]);
      msg = utility.subString(temp.join(", "), 50) + '\n' + lang.already_exists_global_error_message;
    }

    toast.info(lang.successfully_add_rails_message + '\n' + msg, {
      position: toast.POSITION.TOP_RIGHT
    });

    let updatedData = {
      _id: railsRef.current._id,
      SID: railsRef.current.SID,
      ott_rails: [...railsRef.current.ott_rails, ...storeRailsData]
    }

    let res = await API.saveData(ENTITYNAME.Page, updatedData);

    let logData = { event: LOGEVENT.INSERT_RAILS, module: MODULE.PAGE, data: res.data, message: res.message };
    API.SaveLogs(logData);


    if (res.success) {
      refreshCollection();
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  }

  return (
    <div>
      {showTitle && (
        <>
          <h1 className="page-title txt-color-bludeDark">
            <i
              className="fa fa-table marlr"
              style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{myTitle === "" ? props.entityname : myTitle}</span>
          </h1>
        </>
      )}
      <div className="flex-container">
        <div className="martb mar">
          {showBack && (
            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
          )}

          {showAdd && (
            <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
          )}
        </div>


        <div className="flex-container-reverse flex-item-auto">

          <div className="input-group input-group w-300 martb" >
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              placeholder={lang.search_button_text}
              onChange={onSearch}
              onFocus={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(true);
                }
              }}
              onBlur={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(false);
                }
              }}
            />
            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
              <div className="input-group-append">
                <span className="input-group-text">
                  <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                </span>
              </div>
            </button>
          </div>
          {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
            <PreferenceRoundButton
              icon={"gear"}
              title={lang.preference_button_tooltip}
              onClick={() => { setShowPreferences(!showPreferences) }}
            />
          </div>}
          <div className="mt-1" style={{ paddingTop: "12px" }}>
            <RefreshButton
              onClick={refreshCollection}
            />
          </div>
          {showArchive && disableMutliSelect && (<div className="mt-1" style={{ paddingTop: "12px" }}>
            <RoundButton
              icon={"inbox"}
              // style={{ margin: "0px 03px" }}
              // iconColor={"white"}
              title={showArchived ? lang.un_archive_label : lang.archive}
              onClick={onArchiveButtonClick}
            ></RoundButton></div>
          )}
          {showImport && (
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"upload"}
                btnColor={"warning"}
                // style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={lang.import_file_label}
                onClick={() => navigate('/home/DataMigration', { state: { mediaCategory: props.mediaCategory } })} //route navigate to data migration module
              /></div>
          )}
          {showExport && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"info"}
                style={{ color: 'white' }}
                // iconColor={"white"}
                title={lang.export_button_tooltip}
                onClick={downloadExcel}
              /></div>
          )}

          {!hideArchive && <div className="d-inline mt-3 mr-2">
            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
          </div>}
        </div>
      </div>
      {isLoading && <Loader />}

      {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
      {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
      {!isLoading && !isNoRecords &&
        <Grid
          style={props.gridstyle ?? {
            height: "76vh",
          }}
          sort={gridSort}
          sortable={sortable}
          onSortChange={onSortChange}
          rowRender={gridRowRender}
          filterable={filterable}
          pageable={isPageable}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChange}
          total={total}
          resizable={true}

          data={
            gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: disableMutliSelect,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >

          {/* Multi Select Checkbox row */}
          {disableMutliSelect && (<GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex(
                (item) => !selectedState[idGetter(item)]
              ) === -1
            }
            locked={true}
            filterable={false}
          />
          )}

          {/* Edit Row */}
          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.type === COLUMNSTYPE.datetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyTimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.name === "SID") {
              return;
              //we are not returning any value for SID column
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyImageCell}
                  title={column.label}
                  width={column.width ?? 100}
                  sortable={false}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={85}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCheckBoxCell}
                  title={column.label}
                  width={column.width ?? 80}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyArrayCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.href) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyHrefCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.enum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyEnumCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.platform) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyPlatfromCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.status) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={Mystatuscell}
                  title={column.label}
                  width={column.width ?? 200}
                  sortable={false}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.localdatetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={LocalDateTimeColumn}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.icon) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={IconCell}
                  title={column.label}
                  width={column.width ?? 80}
                  sortable={false}
                />
              );
            }
            else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>}

      {openForm && (
        <CustomEditForm
          {...props} //to get other props from customEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          refresh={() => loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived)} //to refresh the collection when data added
          item={editItem}
          dataColumns={dataColumns}
        />
      )}

      {deleteError &&
        <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
      {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

      {showPreviewCollection && <BossDialog
        title={previewRef.current.isMobile ? lang.mobile_preview_label : lang.tv_preview_label}
        onClose={() => { setShowPreviewCollection(false) }}
        width={previewRef.current.isMobile ? "750px" : "1200px"}
        height={previewRef.current.isMobile ? "580px" : "700px"}
      >
        <div className="row">
          <div className={previewRef.current.isMobile ? "col mt-4" : "col"} style={previewRef.current.isMobile ? { marginLeft: '13%', height: '100%', scale: '0.7', color: 'white' } : { marginTop: '-5px', marginLeft: '4px' }}>
            <div className="row" style={previewRef.current.isMobile ? { display: "flex" } : { display: "block" }}>
              <Preview pageData={previewRef.current} isMobile={previewRef.current.isMobile} ></Preview>
            </div>
          </div>
        </div>
        </BossDialog>}

      {showRails && <BossDialog
        title={lang.rails_dialog_header}
        onClose={() => setShowRails(false)}
        width={"700px"}
      >
        <Grid data={railsGridData} style={{ height: "35vh", marginTop: "5px" }}>
          <GridColumn cell={myRailsCommandCell} width={"70px"} />
          <GridColumn field="Title" title={lang.title_column} editable={false} />
          <GridColumn field="OttRailTypeSID" title={lang.ott_rails_type_column} cell={MyDatalookupCell} editable={false} />
          <GridColumn field="PlatformSIDs" title={lang.platform_column} cell={PlatformCell} editable={false} />
        </Grid>
      </BossDialog>}
      {showAttachRails && <CollectionSelection entityname={ENTITYNAME.Rails} closeForm={() => setShowAttachRails(false)} width="700px" setDataList={handleSelectedRails} />}

    </div>
  );
};
