/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import {ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "../../locale/useTranslation";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";

export const AirTimeConsumptionReport = (props) => {
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const blankDataItem = {Channel : {}, FromDate: defaultDates.FirstDate, ToDate: defaultDates.FirstDate}
    const [dataItem, setDataItem] = useState(blankDataItem);
    const navigate = useNavigate();
    const [channel, setChannel] = useState([]);
    const lang = useTranslation();
    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }
    const payloadRef = useRef({});
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const onChange = async (e) => {     
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } 
        else
        {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }  
    };

     const isValid = () => {
        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate < dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true
    }

     const airTimeConsumptionExportData = async () => {
        if (isValid()) {
            var fromdate = (new Date(dataItem.FromDate)).setHours(0, 0, 0, 0); 
            var todate = (new Date(dataItem.ToDate)).setHours(23, 59, 59);
            payloadRef.current = {
                startdate : fromdate,
                enddate : todate,
                channelsid : selectedChannelRef.current.SID,
                channelName : selectedChannelRef.current.FullChannelName,
            }
            loadAirTimeConsumptionData();
        }
    }

    const loadAirTimeConsumptionData = async () => { 
        var payload = {...payloadRef.current,
        }     
        var res = await API.getAirTimeConsumptionReport(payload);
         if (res.success) {
            if(res.dataLength == 0){
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });             
                return;
            }
            else
            {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }            
         }
         else {
             toast.error(res.message, {
                 position: toast.POSITION.TOP_RIGHT
             });
         }
    };   

     const onCancel = () => {
        navigate(-1);
      }

    return (<>    
     <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.air_time_consumption_report_header}</span>
        </h1>    
        <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
            <div className="row">
                <div className="flex-container wrap">
                    <div className="row mt-1 mb-1" style={{marginLeft:'-16px'}}>
                        <div className="col">
                            <ActionButton title={lang.download_air_time_consumption_report_tooltip_button} name={lang.export_button_text} btnColor={'info'} onClick={airTimeConsumptionExportData} />
                            <CancelButton onClick={onCancel}></CancelButton>
                        </div>
                    </div>
                </div> 
            </div>  
            <div className="row mt-3">
                <div className="col-3">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.from_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} max={dataItem.ToDate} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.to_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                    </div>
                </div> 
                <div className="col-3">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            data={channel}
                            name={"Channel"}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>      
            </div>                                        
        </div>      
    </>)
}
export default AirTimeConsumptionReport;