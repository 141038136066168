/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as API from '../../framework/API/api';
import { ENTITYNAME, LOCALSTORAGE_KEY} from "../../framework/constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { ConfirmAlert } from "../../ConfirmAlert";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../framework/utility/utilityProvider";
import MediaTemplateMataDataEditForm from "./MediaTemplateMataDataEditForm";

export const MediaTemplateEditForm = (props) => {
console.log(props.mediaData?.MediaCategory.SID > 0 ? props.mediaData?.MediaCategory : {});
  const navigate = useNavigate();
  const location = useLocation();
  const { SID } = useParams();
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  const isEdit = SID > 0 ? true : false;
  let blankDataItem = {
    SID: SID,
    Name: props.Name ?? '',
    Description: props.Description ?? '',
    MediaCategory: props.mediaData?.MediaCategory ?? {},
    Value: [],
    IsGlobal: false,
    Archive: false,
  }

  const [mediaTemplateEntity, setMediaTemplateEntity] = useState(blankDataItem);
  const [mediaTemplateMetaData, setMediaTemplateMetaData] = useState(props.metaData ??
    {
    PGRating: {},
    CensorRating: {},
    MediaTechnicalPrototype: {},
    Languages: [],
    Keywords: '',
    ProductionYear: "",
    MamID: '',
    Categories: [],
    AudioTrack: [],
  }
);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [dataItem, setDataItem] = useState(props.mediaData ?? {...mediaTemplateEntity, MediaCategory: props.mediaData?.MediaCategory ?? {}});
  const [languages, setLanguages] = useState([]);
  const [mediaCategorys, setMediaCategorys] = useState([]);
  const lang = useTranslation();
  useEffect(() => {
    loadcombo();
    if (isEdit) {
      loadMediaTemplate(SID);
    }
  }, [])

  const loadcombo = async () => {
    let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { query: ['Archive', '!=', true], sort: { Description: 1 } });
    // let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == true && item.SID != MEDIACATEGORIES.Episode);
    setMediaCategorys(mediaCategory.data);
    let languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(languages.data);
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == "IsGlobal") {
      setDataItem({ ...dataItem, IsGlobal: !dataItem.IsGlobal });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };
  const handleExpand = (bool) => {
    console.log("in");
  }

  const handleMetaData = (data) => {
    let metaData = { ...data }
    if (isEdit && mediaTemplateMetaData.hasOwnProperty('_id')) {
      metaData = { _id: mediaTemplateMetaData._id, ...data }
    }
    setMediaTemplateMetaData(metaData);
  }

  const loadMediaTemplate = async (SID) => {
    console.log("hhddhdh")
    let res = { message: "Not Valid", success: false };
    res = await API.getEntity(ENTITYNAME.MediaTemplate, parseInt(SID));
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setDataItem({ ...dataItem, ...res.data, MediaCategory: res.data.mediaCategory })
    console.log(res.data.Value)
    setMediaTemplateMetaData(res.data.Value.metaData)
  }


  const isValid = () => {
    if (!dataItem.Name) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.MediaCategory || Object.keys(dataItem.MediaCategory).length == 0) {
      toast.error(`${lang.please_select_media_category_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }    
    return true
  }

  const onSave = async () => {
    let localmediaTemplateEntity = { ...mediaTemplateMetaData };
    let finalData = {
      PGRating: localmediaTemplateEntity?.PGRating ? {
        _id: localmediaTemplateEntity?.PGRating?._id,
        Description: localmediaTemplateEntity?.PGRating?.Description
      } : {},
      CensorRating: localmediaTemplateEntity?.CensorRating ? {
        _id: localmediaTemplateEntity?.CensorRating?._id,
        Description: localmediaTemplateEntity?.CensorRating?.Description
      } : {},
      MediaTechnicalPrototype: localmediaTemplateEntity?.MediaTechnicalPrototype ? {
        _id: localmediaTemplateEntity?.MediaTechnicalPrototype?._id,
        Description: localmediaTemplateEntity?.MediaTechnicalPrototype?.Description,
      } : {},
      Languages: localmediaTemplateEntity?.Languages?.length > 0 ? localmediaTemplateEntity.Languages.map((x) => {
        return {
          _id: x?._id,
          Description: x?.Description,
        }
      }) : [],
      Keywords: localmediaTemplateEntity?.Keywords ?? '',
      ProductionYear: localmediaTemplateEntity?.ProductionYear ?? '',
      Categories: localmediaTemplateEntity?.Categories?.length > 0 ? localmediaTemplateEntity?.Categories?.map((x) => {
        return {
          _id: x._id,
          Name: x.Name,
          parent: x.parent
        }
      }) : [],
      AudioTrack: localmediaTemplateEntity?.AudioTrack?.length > 0 ? localmediaTemplateEntity?.AudioTrack.map((x) => {
        return {
          Language: {
            "_id": x.Language?._id,
            "Description": x.Language?.Description,
          },
          TrackNumber: x?.TrackNumber
        }
      }) : [],
      MamID: localmediaTemplateEntity?.MamID ?? '',
    }
    if (isValid()) {
      let data = {
        _id: dataItem?._id,
        SID: parseInt(dataItem?.SID),
        Name: dataItem.Name,
        Description: dataItem?.Description,
        mediaCategory: {
          _id: dataItem.MediaCategory._id,
          SID: dataItem.MediaCategory.SID,
          Description: dataItem.MediaCategory.Description,
        },
        Value: {
          metaData: finalData ?? []
        },
        IsGlobal: dataItem?.IsGlobal ?? false,
        Archive: dataItem?.Archive ?? false,
      }
      let res = await API.saveData(ENTITYNAME.MediaTemplate, data);
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      setMediaTemplateMetaData({
        PGRating: {},
        CensorRating: {},
        MediaTechnicalPrototype: {},
        Languages: [],
        Keywords: '',
        ProductionYear: "",
        Synopsis: [],
        AudioTrack: [],
        dynamicField: {},
        Categories: []
      });
      toast.success("Saved Successfully", {
        position: toast.POSITION.TOP_RIGHT
      });
      navigate(-1);
    }
  }

  const onCancel = () => {
    console.log(mediaTemplateEntity);
    if (mediaTemplateEntity.SID == 0) {
      ConfirmAlert(() => {
        if (location.state && location.state.prevPath) {
          navigate(location.state.prevPath)
        } else {
          navigate(-1) ? navigate(-1) : window.close();
        }
      }, () => { return; }, "Discard changes?", "Are you sure you want to discard changes?")
    } else {
      if (location.state && location.state.prevPath) {
        navigate(location.state.prevPath)
      } else {
        navigate(-1) ? navigate(-1) : window.close();
      }
    }
  }

  return (<>
    <EditPageHeader title={lang.new_button_dialog_header} onSubmit={onSave} onCancel={onCancel} />
    <div className="row mt-2">
      <div className="col-4 form-group">
        <label htmlFor="">{lang.name_label} *</label>
        <input
          type="text"
          className="form-control form-control-sm"
          name="Name"
          value={dataItem.Name}
          onChange={onChange}
        />
      </div>
      <div className="col-4 form-group">
        <label htmlFor="">{lang.description_label}</label>
        <input
          type="text"
          className="form-control form-control-sm"
          name="Description"
          value={dataItem.Description}
          onChange={onChange}
        />
      </div>
      <div className="col-4 form-group">
        <label htmlFor="TabView">{lang.media_category_label} *</label>
        <DropDownList
          style={{ backgroundColor: '#F0F0F0' }}
          data={mediaCategorys}
          name={"MediaCategory"}
          textField="Description"
          dataItemKey="_id"
          value={dataItem.MediaCategory}
          onChange={onChange}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-2  form-group">
        <input type="checkbox" name={"IsGlobal"} value={dataItem.IsGlobal} checked={dataItem.IsGlobal} onChange={onChange} />
        <label className="pl-2" htmlFor="TabView">{lang.global_label}</label>
      </div>
      <div className="col-2  form-group">
        <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
        <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
      </div>
    </div>
    <div className='row'>
      <div className='col-12'> <Box sx={{ width: '100%', height: '100%', typography: 'body1' }}>
        <TabContext value={tabNumber}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label={lang.meta_data_label} value="1" />
            </TabList>
          </Box>
          <TabPanel value={"1"}>
            <MediaTemplateMataDataEditForm metaData={mediaTemplateMetaData} getMetaData={handleMetaData}/>
          </TabPanel>
        </TabContext>
      </Box>
      </div>
    </div>
  </>);
}
