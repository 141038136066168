import React from 'react'
import { Outlet } from 'react-router-dom'

const CollectionWithNewForm = () => {

  return (<>
    <Outlet/>
    </>
  )
}

export default CollectionWithNewForm