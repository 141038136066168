/* eslint-disable */
import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const CustomPaginationDropDown = (props) => {
    const { total, take, onPageChange, page, skip } = props;

    // Calculate total number of pages
    const totalPages = Math.ceil(total / take);

    // Calculate current range
    const currentStartItem = skip + 1;
    const currentEndItem = Math.min(skip + take, total);

    // Create an array of page numbers
    const pageOptions = Array.from(Array(totalPages).keys()).map((page) => page + 1);

    const myButtonStyle = {
        marginLeft: "1em",
        marginRight: "0.7em",
        height: "calc(1.4285714286em + 10px)",
        backgroundColor: "white",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
    };

    return (
        <div style={{ backgroundColor: "#FAFAFA", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
                <button
                    style={myButtonStyle}
                    disabled={skip === 0}
                    onClick={() => onPageChange({ skip: 0, take })}
                >
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                </button>
                <button
                    style={myButtonStyle}
                    disabled={skip === 0}
                    onClick={() => onPageChange({ skip: skip - take, take })}
                >
                    <i className="fa fa-caret-left" aria-hidden="true"></i>
                </button>
                <DropDownList
                    style={{ width: "5em" }}
                    data={pageOptions}
                    value={(skip / take) + 1}
                    onChange={(e) => onPageChange({ skip: (e.target.value - 1) * take, take })}
                />
                <button
                    style={myButtonStyle}
                    disabled={(skip + take) >= total}
                    onClick={() => onPageChange({ skip: skip + take, take })}
                >
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                </button>
                <button
                    style={myButtonStyle}
                    disabled={(skip + take) >= total}
                    onClick={() => onPageChange({ skip: (totalPages - 1) * take, take })}
                >
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                    <i className="fa fa-caret-right" aria-hidden="true"></i>
                </button>
            </div>
            <div>
                {currentStartItem} - {currentEndItem} of {total} items
            </div>
        </div>
    );
};

export default CustomPaginationDropDown;