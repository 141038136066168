/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import BossDialog from "../../components/BossDialog";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";


export const ContentEditForm = (props) => {

  const [mediaCategoryType, setMediaCategoryType] = useState([]);
  const lang = useTranslation();

  const blankDataItem = {
    ...props.item,
    SID: props.item.SID ?? 0,
    MediaCategoryType: props.item.MediaCategoryType ?? {},
    Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description ?? '',
    NextNumber: props.item.NextNumber ?? 1,
    Prefix: props.item.Prefix ?? '',
    Length: props.item.Length ?? 5,
    Archive: props.item.Archive ?? false
  }

  const [dataItem, setDataItem] = useState(blankDataItem);


  useEffect(() => {
    loadcombo();
  }, [])


  const loadcombo = async () => {

    var mediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 } });
    let filtered = mediaCategoryTypeRes.data
    if (props.filterMediaCategory) {
      filtered = mediaCategoryTypeRes.data.filter((x) => x.MediaCategorySID == props.filterMediaCategory)
      setDataItem({
        ...blankDataItem,
        MediaCategoryType: filtered?.length > 0 ? filtered[0] : {}
      })
    }
    setMediaCategoryType(filtered);
  }

  //function to check the validation in form
  function validationCheck() {

    if (dataItem.Description == undefined || dataItem.Description == "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.MediaCategoryType == undefined || Object.keys(dataItem.MediaCategoryType).length == 0) {
      toast.error(`${lang.please_select_media_category_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Prefix == undefined || dataItem.Prefix == "") {
      toast.error(`${lang.please_enter_prefix_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.NextNumber == undefined || dataItem.NextNumber == "") {
      toast.error(`${lang.please_enter_next_number_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.NextNumber <= 0) {
      toast.error(`${lang.next_number_can_not_be_negative_or_zero_error_meessage}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Length || dataItem.Length <= 0 || dataItem.Length > 10) {
      toast.error(`${lang.number_length_should_be_between_1_to_10_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {

    if (validationCheck()) {
      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID ?? 0,
        MediaCategoryType: {
          _id: dataItem.MediaCategoryType._id,
          SID: dataItem.MediaCategoryType.SID,
          Description: dataItem.MediaCategoryType.Description,
          MediaCategorySID: dataItem.MediaCategoryType.MediaCategorySID
        },
        Description: dataItem.Description,
        Prefix: dataItem.Prefix,
        NextNumber: parseInt(dataItem.NextNumber),
        Length: parseInt(dataItem.Length),
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Prefix", "=", dataItem.Prefix]],
      }
      console.log(saveData);

      let res = await API.saveData(ENTITYNAME.Content, saveData);
      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.CONTENT, data: res.data, message: res.message };
        API.SaveLogs(logData);
        utility.deleteLocalStorageItem(ENTITYNAME.Content);
        props.cancelEdit();
        props.refresh();
      } else if (res.code == 413) {
        toast.error(lang.entry_already_exists_in_system_for_same_prefix_and_suffix_error_message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const onChangeForm = (e) => {

    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  return (
    <BossDialog
      title={props.item.SID > 0 ? props.item.Description : "Create Content"}
      onClose={props.cancelEdit}
      width={"770px"}
    >
      <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
      <Form
        initialValues={dataItem}
        render={(formRenderProps) => (
          <FormElement>
            <div className="row mt-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <label>{lang.description_label} *</label>
                    <input
                      name={"Description"}
                      type="text"
                      className="form-control form-control-sm"
                      onChange={onChangeForm}
                      value={dataItem.Description}
                    />
                  </div>
                  <div className="col-6">
                    <label>{lang.media_category_type_label} *</label>
                    <DropDownList
                      style={{ backgroundColor: 'white' }}
                      name={"MediaCategoryType"}
                      textField={"Description"}
                      value={dataItem.MediaCategoryType}
                      data={mediaCategoryType}
                      onChange={onChangeForm}
                    />
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    <label>{lang.prefix_label} *</label>
                    <input
                      name={"Prefix"}
                      type="text"
                      className="form-control form-control-sm"
                      value={dataItem.Prefix}
                      onChange={onChangeForm}
                    />
                  </div>
                  <div className="col-6" >
                    <label>{lang.next_number_label} *</label>
                    <input
                      name="NextNumber"
                      className="form-control form-control-sm"
                      value={dataItem.NextNumber}
                      type="number"
                      onChange={onChangeForm}
                      min={0}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6" >
                    <label>{lang.number_length_label} *</label>
                    <input
                      name="Length"
                      className="form-control form-control-sm"
                      value={dataItem.Length}
                      type="number"
                      onChange={onChangeForm}
                      min={0}
                    />
                  </div>
                  <div className="col-2 mt-3" >
                    <input type={"checkbox"} name={"Archive"}
                      onChange={(e) => onChangeForm(e)}
                      value={dataItem.Archive}
                      checked={dataItem.Archive} />
                    <label className="ml-1">{lang.archive}</label>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </BossDialog>)
};