import React, { useEffect, useRef, useState } from "react";
import { APPLICATION, CONTENT_TYPE, ENTITYNAME, FONT_WEIGHT, LOCALSTORAGE_KEY, OVERLAYTYPE, POSITION, COMPANYTYPE, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import { DigitalSign_ContentColumns } from "../../framework/utility/customColumns";
import DigitalSignCollectionSelection from "./DigitalSignCollectionSelection";

const OverlayEditForm = (props) => {

  const { SID } = useParams();
  const loc = useLocation();
  const lang = useTranslation();
  const navigate = useNavigate();
  const [fontWeights, setFontWeights] = useState([])
  const [fontFamilys, setFontFamilys] = useState([])
  const [openImageBank, setOpenImageBank] = useState(false);
  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
  const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
  const [selectedCompany, setSelectedCompany] = useState((SID == 0 && companies?.length > 0) ? companies[0] : {});

  const [dataItem, setDataItem] = useState({
    SID: SID,
    Name: '',
    OverlayType: {
      _id: OVERLAYTYPE.Image, Description: utility.getKeyByValue(OVERLAYTYPE, OVERLAYTYPE.Image)
    },
    ImageUrl: '',
    Shape: {},
    ShapeHeight: 100,
    ShapeWidth: 100,
    BackgroundColor: '#000000',
    BorderRadius: '',
    Text: '',
    Position: {
      _id: POSITION.TopLeft, Description: utility.getKeyByValue(POSITION, POSITION.TopLeft)
    },
    Application: {
      _id: APPLICATION.DigitalClock, Description: utility.getKeyByValue(APPLICATION, APPLICATION.DigitalClock)
    },
    Location: "Delhi",
    TextPosition: {},
    FontSize: 24,
    FontWeight: {},
    FontFamily: {},
    FontColor: '#F7A227',
    IsTextMarquee: false,
    Opacity: 1,
    TextOpacity: 1,
    Layer: 1,
    Archive: false
  })

  useEffect(() => {
    loadcombo();
  }, []);

  const loadcombo = async () => {
    let fontWeightRes = Object.keys(FONT_WEIGHT).map(x => {
      return { _id: FONT_WEIGHT[x], Description: x }
    })
    setFontWeights(fontWeightRes)
    let fontFamilyRes = await API.getDataLookup(ENTITYNAME.FontFamily);
    setFontFamilys(fontFamilyRes.data);

    if (SID > 0) {
      loadEditData(fontWeightRes, fontFamilyRes.data);
    }

  }

  const loadEditData = async (fontWeightRes, fontFamilyRes) => {
    const res = await API.getEntity(ENTITYNAME.DigitalSignOverlay, parseInt(SID));
    if (res.success) {
      setDataItem({
        ...dataItem,
        ...res.data,
        FontWeight: fontWeightRes?.find(X => X?._id == res?.data?.FontWeight) ?? { _id: 4, Description: "w400" },
        FontFamily: fontFamilyRes?.find(X => X?.Description == res?.data?.FontFamily) ?? { SID: 21, Description: 'Roboto' },
        Text: res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.Text : res.data.Text : res.data.Text,
      })
      setSelectedCompany(companies?.length > 0 ? companies.find(x => x?._id == res.data?.Company_id) : {});
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == "IsTextMarquee") {
      setDataItem({ ...dataItem, IsTextMarquee: !dataItem.IsTextMarquee });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  }

  const isValid = () => {
    if (isEnterpriseLogin && (!selectedCompany || Object.keys(selectedCompany).length == 0)) {
      toast.info(lang.please_select_company_error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
      return false;
    }
    if (!dataItem.Name || dataItem.Name.trim() == "") {
      toast.error('Name is required', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ShapeHeight > 100 || dataItem.ShapeH < 1) {
      toast.error(`${lang.width_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ShapeWidth > 100 || dataItem.ShapeWidth < 1) {
      toast.error(`${lang.width_percentage_should_be_greater_than_zero_and_less_than_hundred_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true
  }

  const handleSubmit = async () => {

    if (isValid()) {
      let localData = {
        _id: dataItem._id,
        SID: loc.state?.copy ? 0 : parseInt(dataItem.SID),
        Name: dataItem.Name,
        OverlayType: dataItem.OverlayType,
        ImageUrl: dataItem.OverlayType?._id == OVERLAYTYPE.Image ? dataItem.ImageUrl : "https://media.comicbook.com/files/img/default-movie.png",
        ShapeHeight: dataItem.ShapeHeight,
        ShapeWidth: dataItem.ShapeWidth,
        BackgroundColor: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.BackgroundColor : "",
        BorderRadius: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.BorderRadius : 0,
        Text: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.Text : "",
        TextPosition: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.TextPosition : {},
        Position: dataItem.Position,
        FontSize: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.FontSize : 0,
        FontWeight: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem?.FontWeight?._id : "",
        FontFamily: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem?.FontFamily?.Description : "",
        FontColor: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.FontColor : "",
        IsTextMarquee: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? dataItem.IsTextMarquee ?? false : false,
        TestOpacity: dataItem.OverlayType?._id == OVERLAYTYPE.Shape ? parseFloat(dataItem.TextOpacity) : 0,
        Opacity: parseFloat(dataItem.Opacity),
        Layer: parseInt(dataItem.Layer.toString().length > 0 ? dataItem.Layer : '0'),
        Application: dataItem.Application,
        Archive: dataItem.Archive ?? false,
        Location: dataItem.Location,
        Company_id: isEnterpriseLogin ? selectedCompany?._id : company?._id
      }

      // need to implement validation
      if (loc.state?.copy) {
        delete localData._id;
      }

      let res = await API.saveData(ENTITYNAME.DigitalSignOverlay, localData);
      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DIGITAL_SIGN_OVERLAY, data: res.data, message: res.message };
        API.SaveLogs(logData);
        navigate("/home/digitalSignBoardOverlay");
        utility.deleteLocalStorageItem(ENTITYNAME.DigitalSignOverlay);
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const handleImageSelect = (dataList) => {
    setDataItem({ ...dataItem, ImageUrl: dataList[0].url });
  }

  const calculateInset = (positionId, shapeHeight, shapeWidth, containerHeight, containerWidth) => {
    const halfContainerHeight = containerHeight / 2;
    const halfContainerWidth = containerWidth / 2;
    const halfShapeHeight = (shapeHeight / 100) * containerHeight / 2;
    const halfShapeWidth = (shapeWidth / 100) * containerWidth / 2;

    switch (positionId) {
      case POSITION.TopLeft:
        return "0% auto auto 0%";
      case POSITION.TopCenter:
        return `0% auto auto ${halfContainerWidth - halfShapeWidth}px`;
      case POSITION.TopRight:
        return `0% 0% auto auto`;
      case POSITION.BottomRight:
        return `auto 0% 0% auto`;
      case POSITION.BottomLeft:
        return `auto auto 0% 0%`;
      case POSITION.BottomCenter:
        return `auto ${halfContainerWidth - halfShapeWidth}px 0% ${halfContainerWidth - halfShapeWidth}px`;
      case POSITION.Center:
        return `${halfContainerHeight - halfShapeHeight - 10}px ${halfContainerWidth - halfShapeWidth}px ${halfContainerHeight - halfShapeHeight}px ${halfContainerWidth - halfShapeWidth}px`;
      case POSITION.CenterLeft:
        return `${halfContainerHeight - halfShapeHeight - 10}px auto auto 0%`;
      case POSITION.CenterRight:
        return `${halfContainerHeight - halfShapeHeight - 10}px 0 auto auto`;
      default:
        return "0% 0% 0% 0%";
    }
  };

  const shapeStyle = {
    display: "flex",
    height: `${dataItem.ShapeHeight}%`,
    width: `${dataItem.ShapeWidth}%`,
    backgroundColor: dataItem.BackgroundColor,
    borderRadius: `${dataItem.BorderRadius}px`,
    opacity: dataItem.Opacity,
    position: "absolute",
    overflow: 'hidden',
    inset: calculateInset(dataItem.Position._id, dataItem.ShapeHeight, dataItem.ShapeWidth, 400, 600),
  };

  const textStyle = {
    position: "absolute",
    width: `100%`,
    textAlign: (dataItem.TextPosition?._id == POSITION.TopLeft || dataItem.TextPosition?._id == POSITION.CenterLeft || dataItem.TextPosition?._id == POSITION.BottomLeft) ? "left" : (dataItem.TextPosition?._id == POSITION.TopCenter || dataItem.TextPosition?._id == POSITION.Center || dataItem.TextPosition?._id == POSITION.BottomCenter) ? "center" : "right",
    bottom: dataItem.TextPosition?.Description == 'TopCenter' ? null : dataItem.TextPosition?.Description?.includes('Bottom') ? 0
      : dataItem.TextPosition?.Description?.includes('Center') ? `${(parseInt(dataItem.ShapeHeight) / 2) + (parseInt(dataItem.ShapeHeight) - 5)}px` : null,
    fontSize: `${dataItem.FontSize}px`,
    color: dataItem.FontColor,
    fontWeight: dataItem?.FontWeight?.Description?.slice(1),
    fontFamily: dataItem.FontFamily?.Description,
    opacity: dataItem.TextOpacity,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    animation: dataItem.IsTextMarquee ? 'marquee 10s linear infinite' : 'none'
  };

  const imageStyle = {
    textAlign: (dataItem.Position?._id == POSITION.TopLeft || dataItem.Position?._id == POSITION.CenterLeft || dataItem.Position?._id == POSITION.BottomLeft) ? "left" : (dataItem.Position?._id == POSITION.TopCenter || dataItem.Position?._id == POSITION.BottomCenter || dataItem.Position?._id == POSITION.Center) ? "center" : "right",
    opacity: dataItem.Opacity,
    height: `${dataItem.ShapeHeight}%`,
    width: `${dataItem.ShapeWidth}%`,
    position: "absolute",
    overflow: 'hidden',
    inset: calculateInset(dataItem?.Position?._id, dataItem?.ShapeHeight, dataItem?.ShapeWidth, 400, 600),
  };


  return (
    <div className="row m-1 mb-5" >
      <EditPageHeader title={SID > 0 ? `${lang.digital_sign_board_overlay_editpage_header}  -> ` + ' ' + dataItem.Shape.Description : `${lang.digital_sign_board_overlay_editpage_header}  -> ` + ' ' + 'New'} onSubmit={handleSubmit} onCancel={() => navigate("/home/digitalSignBoardOverlay")} />
      <div className="col-6">
        {isEnterpriseLogin && <div className="row mt-2">
          <div className="col-6 mt-1">
            <label>{lang.company_label} *</label>
          </div>
          <div className="col-6">
            <DropDownList
              style={{ backgroundColor: "white" }}
              data={companies}
              name="Company"
              textField="Name"
              dataItemKey="_id"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
            />
          </div>
        </div>}
        <div className="row mt-2">
          <div className="col-6 mt-1">
            <label>{lang.name_label} *</label>
          </div>
          <div className="col mb-2">
            <input
              name={"Name"}
              type="text"
              className="form-control form-control-sm"
              onChange={onChange}
              value={dataItem.Name}
            />
          </div>
          <div className="col-6 mt-1 mb-1">
            <label >{lang.layer_label}</label>
          </div>
          <div className="col-2 mb-2">
            <input
              type="number"
              name="Layer"
              className="form-control form-control-sm"
              value={dataItem.Layer}
              onChange={onChange}
            />
          </div>
          <div className="col-6 mt-2">
            <label >{lang.overlay_type_label}</label>
          </div>
          <div className="col">
            <DropDownList
              style={{ backgroundColor: "white" }}
              data={Object.keys(OVERLAYTYPE).map((x) => {
                return {
                  _id: OVERLAYTYPE[x], Description: x
                }
              })}
              name="OverlayType"
              textField="Description"
              dataItemKey="_id"
              value={dataItem.OverlayType}
              onChange={onChange}
            />
          </div>
        </div>
        {(dataItem.OverlayType._id == OVERLAYTYPE.Image || dataItem.OverlayType._id == OVERLAYTYPE.Application) ?
          <>

            {dataItem.OverlayType._id != OVERLAYTYPE.Application && <div className="row mt-2">
              <div className="col-6">
                <label >{lang.height_label}</label>
              </div>
              <div className="col-2">
                <input
                  type="number"
                  name="ShapeHeight"
                  className="form-control form-control-sm"
                  value={dataItem.ShapeHeight}
                  onChange={onChange}
                />
              </div>
              <div>
                <label><b>%</b></label>
              </div>
            </div>
            }
            {dataItem.OverlayType._id != OVERLAYTYPE.Application && <div className="row mt-2">
              <div className="col-6">
                <label >{lang.width_label}</label>
              </div>
              <div className="col-2">
                <input
                  type="number"
                  name="ShapeWidth"
                  className="form-control form-control-sm"
                  value={dataItem.ShapeWidth}
                  onChange={onChange}
                />
              </div>
              <div>
                <label><b>%</b></label>
              </div>
            </div>
            }
            {dataItem.OverlayType._id == OVERLAYTYPE.Application ?
              <div className="row mt-2">
                <div className="col-6"><label>{"Application"} *</label></div>
                <div className="col-6">
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={Object.keys(APPLICATION).map((x) => {
                      return {
                        _id: APPLICATION[x], Description: x
                      }
                    })}
                    name="Application"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.Application}
                    onChange={onChange}
                  />
                </div>
              </div>
              : <div className="row mt-3">
                <div className="col-6">
                  <label>{lang.image_url_label} *</label>
                </div>
                <div className="col-6 d-flex">
                  <input
                    type="text"
                    name="ImageUrl"
                    className="form-control form-control-sm"
                    value={dataItem.ImageUrl}
                    onChange={onChange}
                  />
                  <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                    style={{ height: "32px", margin: '0px 0px 0px 3px' }} onClick={() => { setOpenImageBank(true) }}><i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            }
            {(dataItem.OverlayType._id == OVERLAYTYPE.Application && dataItem.Application._id == APPLICATION.Weather
            ) &&
              <div className="row mt-2">
                <div className="col-6"><label>{"Location"} *</label></div>

                <div className="col mb-2">
                  <input
                    name={"Location"}
                    type="text"
                    className="form-control form-control-sm"
                    onChange={onChange}
                    value={dataItem.Location}
                  />
                </div>
              </div>
            }
            <div className="row mt-2">
              <div className="col-6"><label>{lang.position_label} *</label></div>
              <div className="col-6">
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={Object.keys(POSITION).map((x) => {
                    return {
                      _id: POSITION[x], Description: x
                    }
                  })}
                  name="Position"
                  textField="Description"
                  dataItemKey="_id"
                  value={dataItem.Position}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6 mt-1">
                <label >{lang.opacity_label}</label>
              </div>
              <div className="col-2">
                <input
                  type="number"
                  name="Opacity"
                  className="form-control form-control-sm"
                  value={dataItem.Opacity}
                  onChange={onChange}
                  max={1}
                  min={0}
                />
              </div>
              <span style={{ marginLeft: '52.5%', fontSize: '9px' }}>{lang.opacity_note}</span>
            </div>
          </>
          : <>
            <p style={{ position: "absolute", backgroundColor: "white", paddingLeft: '03px', paddingRight: '03px', marginLeft: '5px', marginTop: "-3px" }}>{lang.shape_label}</p>
            <div className="mt-2 mb-3" style={{ border: "1px solid #bbbbbb", padding: "5px" }}>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang.height_label}</label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="ShapeHeight"
                    className="form-control form-control-sm"
                    value={dataItem.ShapeHeight}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label><b>%</b></label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang.width_label}</label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="ShapeWidth"
                    className="form-control form-control-sm"
                    value={dataItem.ShapeWidth}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <label><b>%</b></label>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang.background_color_label}</label>
                </div>
                <div className="col">
                  <input
                    type="color"
                    name="BackgroundColor"
                    className="cursor-pointer"
                    value={dataItem.BackgroundColor}
                    onChange={onChange}
                  />
                  <span className="ml-2">{dataItem?.BackgroundColor}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang?.border_radius_label}</label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="BorderRadius"
                    className="form-control form-control-sm"
                    value={dataItem.BorderRadius}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6"><label>{lang.position_label} *</label></div>
                <div className="col-6">
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={Object.keys(POSITION).map((x) => {
                      return {
                        _id: POSITION[x], Description: x
                      }
                    })}
                    name="Position"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.Position}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-1">
                  <label >{lang.opacity_label}</label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="Opacity"
                    className="form-control form-control-sm"
                    value={dataItem.Opacity}
                    onChange={onChange}
                    max={1}
                    min={0}
                  />
                </div>
                <span style={{ marginLeft: '52.5%', fontSize: '9px' }}>{lang.opacity_note}</span>
              </div>
            </div>
            <p style={{ position: "absolute", backgroundColor: "white", paddingLeft: '03px', paddingRight: '03px', marginLeft: '5px', marginTop: "-9px" }}>{lang.text_label}</p>
            <div className="mt-2" style={{ border: "1px solid #bbbbbb", padding: "5px" }}>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang.text_label}</label>
                </div>
                <div className="col-6">
                  <textarea
                    name={"Text"}
                    type="text"
                    className="form-control form-control-sm"
                    onChange={onChange}
                    value={dataItem.Text}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <label >{lang.font_color_label}</label>
                </div>
                <div className="col">
                  <input
                    type="color"
                    name="FontColor"
                    className="cursor-pointer"
                    value={dataItem.FontColor}
                    onChange={onChange}
                  />
                  <span className="ml-2">{dataItem?.FontColor}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6"><label>{lang.text_position_label} *</label></div>
                <div className="col-6">
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={Object.keys(POSITION).map((x) => {
                      return {
                        _id: POSITION[x], Description: x
                      }
                    })}
                    name="TextPosition"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.TextPosition}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6"><label>{lang.font_size_label} *</label></div>
                <div className="col-2">
                  <input
                    type="number"
                    name="FontSize"
                    className="form-control form-control-sm"
                    value={dataItem.FontSize}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-1"><label>{lang.font_weights_label} *</label></div>
                <div className="col-6">
                  <DropDownList
                    data={fontWeights}
                    name="FontWeight"
                    style={{ backgroundColor: "white" }}
                    textField='Description'
                    dataItemKey='_id'
                    value={dataItem.FontWeight}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-1"><label>{lang.font_family_label} *</label></div>
                <div className="col-6">
                  <DropDownList
                    data={fontFamilys}
                    name="FontFamily"
                    style={{ backgroundColor: "white" }}
                    textField='Description'
                    dataItemKey='_id'
                    value={dataItem.FontFamily}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-1">
                  <label >{lang.text_opacity_label}</label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="TextOpacity"
                    className="form-control form-control-sm"
                    value={dataItem.TextOpacity}
                    onChange={onChange}
                    max={1}
                    min={0}
                  />
                </div>
                <span style={{ marginLeft: '52.5%', fontSize: '9px' }}>{lang.opacity_note}</span>
              </div>
              <div className="row mt-2">
                <div className="col-6 mt-1">
                  <label >{lang.text_marquee_label}</label>
                </div>
                <div className="col" style={{ marginLeft: '-10px' }}>
                  <Switch name={"IsTextMarquee"} checked={dataItem.IsTextMarquee} value={dataItem.IsTextMarquee} onChange={onChange} />
                </div>
              </div>
            </div>
          </>}
        <div className="row mt-2">
          <div className="col-6">
            <label >{lang.archived_label}</label>
          </div>
          <div className="col">
            <input
              type="checkbox"
              name="Archive"
              value={dataItem.Archive}
              checked={dataItem.Archive}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      {/* Screen */}
      <div className="col-4 mt-4">
        <div
          className="preview-container"
          style={{
            border: "15px solid #333",
            borderRadius: "20px",
            boxShadow: "0 0 30px rgba(0, 0, 0, 0.8)",
            overflow: "hidden",
            width: "600px",
            height: "400px",
            position: "relative"
          }}
        >
          {dataItem.OverlayType._id == OVERLAYTYPE.Image &&
            <div style={imageStyle}>
              {dataItem?.ImageUrl != "" && <img src={dataItem?.ImageUrl} style={{ objectFit: "cover", width: '100%', height: '100%' }} />}
            </div>
          }
          {dataItem.OverlayType._id === OVERLAYTYPE.Shape &&
            <div style={shapeStyle}>
              <div style={textStyle}>
                {dataItem.Text}
              </div>
            </div>
          }
        </div>
      </div>
      {openImageBank && <DigitalSignCollectionSelection entityname={ENTITYNAME.DigitalSignContent} wherestatement={['content_type', '=', CONTENT_TYPE.Image]} customColumns={DigitalSign_ContentColumns} title={lang.select_url_tooltip} closeForm={() => setOpenImageBank(!openImageBank)} setDataList={handleImageSelect} mode={"single"} width={"50vw"} />}
    </div >
  )
}
export default OverlayEditForm