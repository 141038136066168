import 'material-symbols';
import { PLAYLIST_SCHEDULE_TYPE } from '../../../constant/constant';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';

export const IconCell = (props) => {
  const field = props.field || "";
  const width = props.width ?? "50px";
  var value = props.dataItem[field];
  value = value.includes('http') ? 'disabled_by_default' : value
  var iconStyle = {fontSize: '30px', width: width}

  return <td>
    <span style={iconStyle} className="material-symbols-outlined ml-2">{value && value}</span>
  </td>;
};

export const ScheduleTypeIconCell = (props) => {
  const field = props.field || "";
  const width = props.width ?? "20px";
  var value = props.dataItem[field];

  if(field.includes('.')){
    var keys = field.split('.');
    value = keys.length > 1 ? props.dataItem?.[keys[0]]?.[keys[1]] : props.dataItem[keys[0]];
  }
  var iconStyle = {fontSize: '20px', width: width}
  let icon = value == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover ? "hand-pointer" : "clock"

  return <td colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}>
    <i style={iconStyle} className={`fa-regular fa-${icon}`}></i>
  </td>;
};