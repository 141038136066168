import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { useTranslation } from "./locale/useTranslation";
import { utility } from "./framework/utility/utilityProvider";
import { ENTITYNAME, LOCALSTORAGE_KEY } from "./framework/constant/constant";
import * as API from "../src/framework/API/api";

function ConfirmAlert(onClickYes,onClickNo,title,message,note = ''){
    const lang = useTranslation();
    confirmAlert({
        title: title,
        message: <div>
            <div>{message}</div>
            {note && note.length > 0 && <p className="text-muted" style={{ fontSize: "12px" }}>{note}</p>}
        </div>,
        buttons: [
            {
            label: `${lang.yes_label}`,
            onClick: () => onClickYes()
            },
            {
            label: `${lang.no_label}`,
            onClick: () => onClickNo()
            }
        ]
    })
    
}

function ConfirmDeleteAlert(onClickYes,onClickNo){
    const lang = useTranslation();
    confirmAlert({
        title: `${lang.confirm_delete_label}`,
        message: `${lang.are_you_sure_you_want_to_delete}`,
        buttons: [
            {
            label: `${lang.yes_label}`,
            onClick: () => onClickYes()
            },
            {
            label: `${lang.no_label}`,
            onClick: () => onClickNo()
            }
        ]
    })
    
}

function ConfirmLogoutAlert(onClickOK,title,message){
    const lang = useTranslation();
    confirmAlert({
        title: title,
        message: message,
        buttons: [
            {
            label: `${lang.ok_label}`,
            onClick: () => onClickOK()
            }
        ]
    })
    
}

function ConfirmAlertOk(onClickOK,title,message){
    const lang = useTranslation();
    confirmAlert({
        title: title,
        message: message,
        buttons: [
            {
            label: `${lang.ok_label}`,
            onClick: () => onClickOK()
            },
        ]
    })
    
}

function ConfirmAlertOneTime(onClickYes,onClickNo,title,message){

    const lang = useTranslation();
    const handleShowOneTime = () => {
        console.log("hideDeleteConfirmation - true");
        let schedulePreference = utility.getValue(LOCALSTORAGE_KEY.schedulepreference);
        if(schedulePreference?.data?.length == 0){
            return;
        }   
        let dataToSave = {
            ...schedulePreference?.data[0],
            value: {
                ...schedulePreference?.data[0]?.value,
                hideDeleteConfirmation: document.getElementById("oneTime").checked ? true : false
            }
        }
        API.saveData(ENTITYNAME.UserPreference, dataToSave);
        utility.setValue(LOCALSTORAGE_KEY.schedulepreference, {...schedulePreference, data: [dataToSave]});

    }

    confirmAlert({
        title: title,
        message: <div>
            <div>{message}</div>
            <div style={{marginTop: '20px'}}>
                <input type="checkbox" id="oneTime" name="oneTime" value="oneTime" />
                <label className="pl-1" htmlFor="oneTime">{lang.dont_show_this_message_again}</label>
            </div>
        </div>,
        buttons: [
            {
            label: `${lang.yes_label}`,
            onClick: () => {
                onClickYes();
                if (document.getElementById("oneTime").checked) {
                    handleShowOneTime();
                }
            }
            },
            {
            label: `${lang.no_label}`,
            onClick: () => onClickNo()
            }
        ]
    })
    
}

export {ConfirmAlert,ConfirmDeleteAlert,ConfirmLogoutAlert, ConfirmAlertOk, ConfirmAlertOneTime}