/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { useTranslation } from "../../locale/useTranslation";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { MakeGoodSpotTransferCollection } from "./MakeGoodSpotTransferCollection";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { orderBy } from "@progress/kendo-react-data-tools";

const initialSort = [
    {
        field: "SID",
        dir: "asc",
    },
];

const MakeGoodSpot = () => {
    const [gridData, setGridData] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [bookedSpots, setBookedSpots] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const lang = useTranslation();
    const filterDataRef = useRef();
    const [sort, setSort] = useState(initialSort);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const res = await API.getMakeGoodSpotData();
        if (res.success) {
            //SHOWING RECENT CAMPAIGN ABOVE
            let data = res.data.sort((a, b) => parseInt(b.Campaign.PeriodFrom) - parseInt(a.Campaign.PeriodFrom));
            filterDataRef.current = data;
            setGridData(data);
        } else {
            console.log(res.message)
        }
        setIsLoading(false);
    }

    const MyCommandCell = (props) => (
        <div style={{ display: "flex", marginTop: "03px", marginLeft: "08px" }}>
            <RoundButton icon={"nfc-symbol"} title={lang.transfer_button_tooltip} onClick={() => transferMakeGoodSpot(props)} />
        </div>
    )

    const refreshMakeGoodSptCollection = () => {
        loadData();
    }

    const transferMakeGoodSpot = (props) => {
        setBookedSpots(props.dataItem)
        setOpenForm(true);
    }

    const canceltransferMakeGoodSpot = () => {
        setOpenForm(false);
    }

    const onSearch = (e) => {
        const value = e.target.value?.trim();

        if (!value || value == "") {
            setGridData(filterDataRef.current);
            return;
        } else {
            let data = filterDataRef.current.filter((item) => {
                return item.Campaign.CampaignNo.toLowerCase().includes(value.toLowerCase()) || item.Campaign.Title.toLowerCase().includes(value.toLowerCase());
            });
            setGridData(data);
        }
    }

    return <>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.makegoodspot_title}</span>
        </h1>
        <div className="flex-container">
            <div className="flex-container-reverse flex-item-auto">
                <div className="input-group input-group w-300 martb" >
                    <input
                        type="text"
                        className="form-control shadow-inset-2 pt-0 pb-0"
                        id="searchInput"
                        aria-label="type 2 or more letters"
                        placeholder={lang.search_button_text}
                        onChange={onSearch}
                    />
                    <button id="search" style={{ border: "0px", backgroundColor: "white", height: "40px" }}>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>
                </div>
                <div className="mr-2" style={{ marginTop: "15px" }}>
                    <RoundButton
                        icon={"refresh"}
                        title={lang.refresh_button_tooltip}
                        onClick={refreshMakeGoodSptCollection}
                    />
                </div>
            </div>
        </div>

        {isLoading ? <Loader />
            : <Grid
                data={orderBy(gridData, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                    setSort(e.sort);
                }}
                style={{ margin: "0px 10px 10px 10px", borderRadius: '5px!important', height: "75vh" }}
            >
                <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                <Column field="Campaign.CampaignNo" title={lang.campaign_label} width="160px" />
                <Column field="Campaign.Title" title={lang.title_column} width="200px" />
                <Column field="Campaign.PeriodFrom" title={lang.period_from_column} cell={DateOnlyCell} width="150px" />
                <Column field="Campaign.PeriodTo" title={lang.period_to_column} cell={DateOnlyCell} width="150px" />
                <Column field="TotalSpot" title={lang.total_spot_column} width="100px" />
            </Grid>}



        {openForm && <MakeGoodSpotTransferCollection refreshdata={refreshMakeGoodSptCollection} closeForm={canceltransferMakeGoodSpot} data={bookedSpots} />}
    </>
}




export default MakeGoodSpot;