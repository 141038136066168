import React, { startTransition, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { utility } from '../../framework/utility/utilityProvider';
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { ImageCell } from '../../framework/forms/helpercomponents/CustomGridCells/ImageCell';
import { LookupCell } from '../../framework/forms/helpercomponents/CustomGridCells/LookupCell';
import { MyToggleButton } from '../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton';
import { ArrayCell } from '../../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { CheckboxCell } from '../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import { HrefCell } from '../../framework/forms/helpercomponents/CustomGridCells/HrefCell';
import { EnumCell } from '../../framework/forms/helpercomponents/CustomGridCells/EnumCell';
import { PlatformCell, arrayEnumCell } from '../../framework/forms/helpercomponents/CustomGridCells/PlatformCell';
import MyStatusCell from '../../components/MyStatusCell';
import { IconCell } from '../../framework/forms/helpercomponents/CustomGridCells/IconCell';
import BackRoundButton from '../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../../framework/forms/helpercomponents/buttons/MenuButton';
import { getter } from '@progress/kendo-data-query';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';
import UpdateMultipleEpisodeDialog from '../../components/UpdateMultipleEpisodeDialog';

const MediaEpisodesBySeasonAssetIdCollection = (props) => {
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);

    const AssetId = window.location.pathname.split("/").pop();

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [season, setSeason] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dataColumns, setDataColumns] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [showUpdateMultiple, setShowUpdateMultiple] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);

        let seasonId = await getSeasonIdFromAssetId();
        if (seasonId) {
            let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            let columns = await API.getFormData(ENTITYNAME.MediaEpisode, currentUserId);
            setDataColumns(columns);
            let res = await API.getData(ENTITYNAME.MediaEpisode, { query: [`Season_id`, '=', seasonId] });
            if (res.success) {
                setData(res.data);
            } else {
                toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } else {
            toast.error('Season not found for this AssetId', { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false);
    }
    const getSeasonIdFromAssetId = async () => {
        let res = await API.getData(ENTITYNAME.Season, { query: ["AssetId", "=", AssetId] });
        if (res.success && res.data.length > 0) {
            setSeason(res.data[0]);
            return res.data[0]._id;
        } else {
            return null;
        }
    }
    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        startTransition(() => {
            if (text == "") {
                setFilterData(data);
                return;
            } else {
                setFilterData(data.filter((item) => item.Title.toLowerCase().includes(text.toLowerCase())));
            }
        })
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.forEach((value) => {
            if (newSelectedState[value]) {
                let filter = data.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        if (checked) {
            selectedIds.forEach((value) => {
                if (newSelectedState[value]) {
                    let filtered = data.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const multipleUpdateFormClose = () => {
        setShowUpdateMultiple(false);
    }

    const menu = (props) => {
        let dataItem = props.dataItem;

        return [
            {
                label: 'Options',
                items: [
                    {
                        label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => {
                            navigate(`/home/MediaEpisode/Episode/${dataItem.SID}`)
                        }
                    },
                    selectedItem.length > 1 && { label: 'Update Multiple', icon: 'pi pi-fw pi-pencil', command: () => { setShowUpdateMultiple(true) } },

                ]
            }
        ]
    }

    return (
        <div>

            <h1 className="page-title txt-color-bludeDark mb-0 mt-0">
                <i
                    className="fa fa-table mr-1"
                    style={{ fontSize: "20px", color: "GrayText" }}
                ></i>
                <span>{season ? (season.Title + ' - S' + season.SeasonNumber + ' - Episodes - ' + data.length) : 'Season'}</span>
            </h1>
            <div className="flex-container">
                <div style={{ display: "flex", alignItems: "center" }}>

                    <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>

                    {(selectedItem.length > 1) && (
                        <EditRoundButton title={'Update Multiple'} onClick={() => { setShowUpdateMultiple(true) }} ></EditRoundButton>
                    )}
                </div>
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={'Search'}
                            onChange={onSearch}
                        />
                    </div>
                </div>
            </div>

            {isLoading && <Loader />}
            {!isLoading &&
                <Grid
                    style={{
                        height: "78vh",
                    }}
                    data={
                        filterData.length > 0 ? filterData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        })) : data.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                    resizable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >

                    {/* Multi Select Checkbox row */}
                    <GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            data.findIndex(
                                (item) => !selectedState[idGetter(item)]
                            ) === -1
                        }
                        locked={true}
                        filterable={false}
                    />


                    {/* Edit Row */}

                    <GridColumn cell={(props) => <td >
                        <MenuButton {...{ collectionMenuModel: () => menu(props) }} />
                    </td>} width={"60px"} locked={true} resizable={false} />


                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateOnlyCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.type === COLUMNSTYPE.datetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                            //we are not returning any value for SID column
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={(props) => <LookupCell {...props} dataColumns={dataColumns} />}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.toggle) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={(props) => <MyToggleButton
                                        {...props}
                                        entityName={ENTITYNAME.MediaEpisode}
                                        size={"small"}
                                    />}
                                    title={column.label}
                                    width={85}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.checkbox) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={CheckboxCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.href) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={HrefCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.enum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={EnumCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.platform) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={PlatformCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.status) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyStatusCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.icon) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={IconCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.arrayenum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={arrayEnumCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}
            {showUpdateMultiple && <UpdateMultipleEpisodeDialog selectedEpisode={selectedItem} setSelectedEpisode={setSelectedItem} closeForm={multipleUpdateFormClose} />}
        </div>
    );
}

export default MediaEpisodesBySeasonAssetIdCollection;
