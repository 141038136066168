/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { FILEURL, ENTITYNAME, MODULETYPES } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { EditPageHeader } from "../../components/EditPageHeader";
import {MultiSelect } from "@progress/kendo-react-dropdowns";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { getSelectedState, GridNoRecords } from "@progress/kendo-react-grid";
import { filterBy, getter } from "@progress/kendo-data-query";


export const InterstitialHistoryReport = (props) => {    
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const blankDataItem = { FromDate: defaultDates.FirstDate,   ToDate: defaultDates.FirstDate, Channel : {}, MediaCategory : {}, }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const navigate = useNavigate();
    const [channel, setChannel] = useState([]);
    const lang = useTranslation();
    const [mediaCategory, setMediaCategory] = useState([]);
    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }
    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const selectedMediaCategoryRef = useRef();
    const setSelectedMediaCategoryRef = (data) => {
        selectedMediaCategoryRef.current = data;
    }
    const payloadRef = useRef({});
    const [interstitialData, setInterstitialData] = useState([]);
    const [filtermediacategoryType, setfiltermediacategoryType] = useState([]);  
    //const [selectedMediaCategoryTypeItem , setselectedMediaCategoryTypeItem] = useState([]);
    const selectedMediaCategoryTypeItemRef = useRef([]);
    const setselectedMediaCategoryTypeItemRef = (items) => {
        selectedMediaCategoryTypeItemRef.current = items;
    }

  const [selectedState, setSelectedState] = useState({});
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {       
    var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
    var mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, {sort: { Description: 1 },  query: ['isLong' , '=' , false]});
    setMediaCategory(mediaCategoryRes.data);
    var mediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType, {sort: { Description: 1 }});
    setMediaCategoryType(mediaCategoryTypeRes.data); 
    }

    const onSelectedMediaCategoryType = (e, props) => { 
        
        var index = filtermediacategoryType.find((y)=> y.SID == props.dataItem.SID)
        if(e.target.checked )   
        {
            setselectedMediaCategoryTypeItemRef([...selectedMediaCategoryTypeItemRef.current, index]);  
        }   
      else{

        var fitler =  selectedMediaCategoryTypeItemRef.current.filter((x)=> x.SID != props.dataItem.SID  )
        setselectedMediaCategoryTypeItemRef(fitler)
      }
      console.log(selectedMediaCategoryTypeItemRef.current.map((item)=> {return item.SID}))
      

    }
        
    const borderWithTitle = {
        position: "relative",
        fontWeight: "500",
        fontSize: "12px",
        top: "-0.8em",
        marginLeft: "0.8em",
        display: "inline",
        backgroundColor: "white"
    };

    const isValidforExport = () => {
        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate < dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.MediaCategory).length == 0) {
            toast.error(`${lang.please_select_media_category_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        
        if(selectedMediaCategoryTypeItemRef.current.length == 0)
        {
            toast.error(`${lang.please_select_atleast_one_media_category_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true
    }

    const onExportInterstitialReport = async () => {
        if (isValidforExport()) {
            var fromdate = (new Date(dataItem.FromDate)).setHours(0, 0, 0, 0); 
            var todate = (new Date(dataItem.ToDate)).setHours(23, 59, 59); 
            payloadRef.current = {
                startdate : fromdate,
                enddate : todate,
                channelsid : selectedChannelRef.current.map((item)=> {return item.SID}),
                mediacategorytypesid : selectedMediaCategoryTypeItemRef.current.map((item)=> {return item.SID}),
            }
            loadData();
           
        }
    }

    const loadData = async () => {        
        var res = await API.getInterstitialHistoryReport(payloadRef.current);
       // setInterstitialData(resBookinSchedule);
        if (res.success) {
            if(res.dataLength == 0){
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });             
                return;
            }
            else
            {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
                toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
                });
            }
           
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const onChange = async (e) => {    
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
       
        else if (e.target.name == 'MediaCategory') {           
            var item = '';
            var items = [];
            var mediaCategoryTypeFiltered = mediaCategoryType.filter((item) => item.MediaCategorySID == e.target.value.SID);
            setfiltermediacategoryType(mediaCategoryTypeFiltered);
            setSelectedMediaCategoryRef(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            setselectedMediaCategoryTypeItemRef(items);
            setSelectedState({});  
            }
       else{
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
       }        
    };

    const onCancel = () => {
        navigate(-1);
      }

      const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);    
        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];    
        if (checked) {
          selectedIds.map((value) => {
            if (newSelectedState[value]) {
              var filtered = filtermediacategoryType.find((obj) => obj._id == value);
              tempSelected.push(filtered);
            }
          });
        }
        setselectedMediaCategoryTypeItemRef(tempSelected);
      };

      const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);    
        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];    
        selectedIds.map((value) => {
          if (newSelectedState[value]) {
            var filter = filtermediacategoryType.find((obj) => obj._id == value);
            tempSelected.push(filter);
          }
        });
    
        setselectedMediaCategoryTypeItemRef(tempSelected);
      };

      const gridRowRender = (tr, props) => {
        const trProps = {
          onContextMenu: (e) => {
            ref.current = props.dataItem;
            if (Object.keys(selectedState).length == 0) {
              toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              return;
            }
            cm.current.show(e);
          },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
      };

    return (
    <>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.interstitial_history_report_header}</span>
        </h1>   
        <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
           <div className="row">
                <div className="flex-container wrap">
                    <div className="row mt-1 mb-1" style={{marginLeft:'-25px'}}>
                        <div className="col">
                            {/* <ActionButton title={'Next'} name={'Next'} btnColor={'warning'} onClick={loadMediaCategoryTypeData   } /> */}
                            <ActionButton title={lang.download_interstitial_history_report_tooltip_button} name={lang.export_button_text} btnColor={'info'} onClick={onExportInterstitialReport} />
                            <CancelButton onClick={onCancel}></CancelButton>
                        </div>
                    </div>                    
                </div> 
            </div>               
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.from_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} max={dataItem.ToDate} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.to_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                    </div>
                </div>    
            </div>
            <div className="row">
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <MultiSelect
                            data={channel}
                            name={"Channel"}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>{lang.media_category_label} *</label>
                        <DropDownList
                            style={{backgroundColor: 'white'}}
                            name={"MediaCategory"}
                            textField={"Description"}
                            value={dataItem.MediaCategory}
                            data={mediaCategory}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>    
            <div className="mt-3" style={{ width: "43.8vw", height: "40vh", margin: "1px 0px 15px 0px", border: '1px solid black', borderRadius: '5px!important' }}>
            <div style={borderWithTitle}>&nbsp; {lang.media_category_type_label} &nbsp;</div>
                <div className="row">                    
                    <div className="col-12">      
                        <Grid style={{ height: "37vh", margin: "0px 10px 10px 10px",}}
                         data={filtermediacategoryType.map((item) => ({
                              ...item,
                              [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                          selectedField={SELECTED_FIELD}
                          selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                          }}
                          dataItemKey={DATA_ITEM_KEY}
                          onSelectionChange={onSelectionChange}
                          resizable={true}
                          onHeaderSelectionChange={onHeaderSelectionChange}
                          rowRender={gridRowRender}
                         
                        >
                        <Column
                field={SELECTED_FIELD}
                width="50px"
                headerSelectionValue={
                    filtermediacategoryType.findIndex(
                    (item) => !selectedState[idGetter(item)]
                  ) === -1
                }
                locked={true}
                filterable={false}
              />
                    <Column field="Description" title={lang.media_category_type_column} />
                        </Grid>
                    </div>
                </div>
            </div> 
        </div>       
    </>)
}
export default InterstitialHistoryReport;