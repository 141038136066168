import { getter } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getSelectedState, Grid, GridColumn } from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import * as API from '../../framework/API/api'
import { ENTITYNAME, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import { utility } from '../../framework/utility/utilityProvider';
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

const CheckListPreference = (props) => {

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    const lang = useTranslation();
    const [checkList,setCheckList] = useState([]);

    const [checkListPreference,setCheckListPreference]=useState({
        SID : 0,
        user_id : user._id,
        entityName : ENTITYNAME.CheckListPreference,
        checklist : []
    })


    const [selectedState, setSelectedState] = useState({});

    useEffect(() => {
        loadData();
        loadcombo();
    },[]);

    const loadcombo = async() => {
        var checklistRes = await API.getDataLookup(ENTITYNAME.CheckList, { sort: { SID: 1 } });
        setCheckList(checklistRes.data);
    }

    const loadData = async() => {
       
        var args = {query: [ ['user_id','=',user._id], ['entityName','=',ENTITYNAME.CheckListPreference] ]}
        var preferenceRes = await API.getData(ENTITYNAME.UserPreference, args);

        if(preferenceRes.success && preferenceRes.data.length != 0){
            setCheckListPreference(preferenceRes.data[0]);
            var checklist = {}
            preferenceRes.data[0].checklist.map((x) => {
                checklist = {...checklist,[x._id] : true}
            });
            setSelectedState(checklist);
        }
        
    }

    const onSave = async() => {
        console.log(selectedState);

        var ids = Object.keys(selectedState);
        var selectedCheckListItem =  [];

        for (let i = 0; i < ids.length; i++) {
            const id = ids[i];

            if(selectedState[id]){
                var item = checkList.find((x) => x._id == id);
                selectedCheckListItem.push(item);
            }
            
        }

        var saveData = {
            ...checkListPreference,
            checklist : selectedCheckListItem
        }
        delete saveData._id

        var res = await API.saveData(ENTITYNAME.UserPreference,saveData);

        if(!res.success){
            console.log(res)
            return;
        }
        console.log(res);
        toast.success(`${lang.preference_saved_successfully}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        props.closeForm();


    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
          event,
          selectedState: selectedState,
          dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
    };
    
    
    const onHeaderSelectionChange = useCallback((event) => {

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        console.log(newSelectedState)
    }, []);


    return (
    <BossDialog
        title={`${lang.checklist_preference_label}`}
        onClose={props.closeForm}
        width={"25%"}
    >
                <div className="row">             
                    <div className="col-12">
                        <Grid style={{ height: "40vh"}}
                            data={
                                checkList.map((item) => ({
                                  ...item,
                                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                              selectedField={SELECTED_FIELD}
                              selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                              }}
                              dataItemKey={DATA_ITEM_KEY}
                              onSelectionChange={onSelectionChange}
                              onHeaderSelectionChange={onHeaderSelectionChange}
                        >
                            <GridColumn
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    checkList.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                                locked={true}
                                filterable={false}
                            />
                            <GridColumn field="Description" title={`${lang.select_all_label}`} />
                        </Grid>
                    </div>
                    <div  className='col-12 text-right mt-2' >
                        <SaveButton onClick={onSave}></SaveButton>
                    </div>
                    
                </div>


    </BossDialog>
  )
}

export default CheckListPreference