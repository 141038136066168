/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
    Grid,
    GridColumn,
    GridNoRecords,
    getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, MILLISECONDSINADAY } from "../constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateOnlyCell, DateTimeCell } from "./helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "./helpercomponents/CustomGridCells/TimeCell";
import moment from "moment";
import RefreshButton from "./helpercomponents/buttons/RefreshButton";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const OpenScheduleDraftCollectionSelection = memo((props) => {

    const lang = useTranslation();

    var wherestatement = props.wherestatement ?? [];
    var andWhereStatement = props.andWhereStatement;

    const [gridData, setGridData] = useState([]);
    const [originalGridData, setoriginalGridData] = useState([]);
    const [dataColumns, setDataColumns] = useState(props.customColumns ?? []);
    const [searchText, setSearchText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedState, setSelectedState] = useState({});
    const [count, setCount] = useState(0);
    const [filterDataItem, setFilterDataItem] = useState({});
    const gridRef = useRef();
    const per_page = props.perPage ?? 50;
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
        setFilterDataItem({
            Channel: props.selectedChannel,
            ScheduleDate: props.scheduleDate
        })
    }, []);

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText) => {
        try {

            var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            var columns = await API.getFormData(ENTITYNAME.ProgramScheduleDraft, currentUserId);

            setIsLoading(true);
            var json = await API.getEntitiesWithSearch(ENTITYNAME.ProgramScheduleDraft, { perPage: per_page, page: 1, query: wherestatement }, columns, searchText);

            setoriginalGridData(json.data);
            setIsLoading(false);

            setGridData(json.data);
            setCount(json.pagination.total);
            setDataColumns(columns);

        } catch (error) {
            console.log("error", error);
        }
    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
            props.setDataList([event.dataItems[event.startRowIndex]]);

        }, [selectedState]
    );


    //implementing search
    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            onSearchClick();
        }
    }

    const handleServerSideSearch = (e) => {
        setSearchText(() => e.target.value);

        let date = new Date(`${filterDataItem.ScheduleDate} 00:00:00`).getTime()

        let filter = [
            ["Channel.SID", "=", filterDataItem.SID],
            ['ScheduleDate','>=',date],
            ['ScheduleDate','<=',date + (MILLISECONDSINADAY - 1000)]
        ]

        if (e.target.value == '') {
            loaddata({ per_page: 1000 }, filter, andWhereStatement, '');
        }
    };

    const onSearchClick = () => {

        let date = new Date(`${filterDataItem.ScheduleDate} 00:00:00`).getTime()

        let filter = [
            ["Channel.SID", "=", filterDataItem.SID],
            ['ScheduleDate','>=',date],
            ['ScheduleDate','<=',date + (MILLISECONDSINADAY - 1000)]
        ]

        if (searchText != '') {
            loaddata({ per_page: 1000 }, filter, andWhereStatement, searchText);
        } else {
            loaddata({ per_page: 1000 }, filter, andWhereStatement, '');
        }

    }

    const checkIfItemExist = (item) => {

        var isFound = false;
        var existingEntries = props.existingItems ?? [];
        // console.log("checking duplicate")
        // console.log(item.SID);
        // console.log(props);
        if (props.checkDuplicate ?? false) {
            isFound = existingEntries.some(element => {
                console.log("props");
                console.log(props);
                if (element.SID === item.SID) {
                    return true;
                }

                return false;
            });
        }
        // console.log("isFound");
        // console.log(isFound);
        return isFound;

    };

    //load for infinit scroll

    const loadInfiniteData = async () => {
        setCurrentPage(currentPage + 1);
        if (count > originalGridData.length) {
            let json = await API.getEntitiesWithSearch(props.entityname, { perPage: per_page, page: currentPage + 1, query: wherestatement }, dataColumns, searchText);
            setoriginalGridData((old) => [...old, ...json.data]);
            setGridData((old) => [...old, ...json.data]);
            setSelectedState({});
        }
    }

    const refreshCollection = () => {
        loaddata({ per_page: per_page }, wherestatement, andWhereStatement, searchText);
        setFilterDataItem({
            Channel: props.selectedChannel,
            ScheduleDate: props.scheduleDate
        })
    }

    //for infinite scroll
    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.className.indexOf('k-grid-content') === -1) {
            return;
        }

        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            await loadInfiniteData()
        }
    };

    const onFilterDataItemChange = (e) => {

        let filter = []

        if(e.target.name == "Channel"){
            let date = new Date(`${filterDataItem.ScheduleDate} 00:00:00`).getTime()

            filter = [
                ["Channel.SID", "=", e.target.value.SID],
                ['ScheduleDate','>=',date],
                ['ScheduleDate','<=',date + (MILLISECONDSINADAY - 1000)]
            ]

        }else if(e.target.name == "ScheduleDate"){
            let date = new Date(`${e.target.value} 00:00:00`).getTime()

            filter = [
                ["Channel.SID", "=", filterDataItem.Channel.SID],
                ['ScheduleDate','>=',date],
                ['ScheduleDate','<=',date + (MILLISECONDSINADAY - 1000)]
            ]
            
        }

        setFilterDataItem({ ...filterDataItem, [e.target.name] : e.target.value })
        loaddata({ per_page: per_page }, filter, andWhereStatement, searchText);

    }

    return (
        <>
            <div className="row" style={{margin : '-20px 0px 0px 0px' }}>
                <div className="col-2"></div>
                <div className="col-1" style={{ padding : '5px 0px 0px 30px'}} >
                    <RefreshButton onClick={refreshCollection} />
                </div>
                <div className="col-3" style={{ padding : '0px 0px 0px'}} >
                        <div className="form-group">
                          {/* <label htmlFor="">{lang.order_date_label}</label> */}
                          <input type={'date'}
                            name={"ScheduleDate"}
                            placeholder={""}
                            onChange={onFilterDataItemChange}
                            value={filterDataItem.ScheduleDate}
                            className="form-control"
                          />
                        </div>
                      </div>
                <div className="col-3">
                    <div className="form-group">
                        {/* <label htmlFor="">{lang.channel_column} *</label> */}
                        <DropDownList
                            style={{ backgroundColor: "white", height: "37px" }}
                            data={props.channelList}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={filterDataItem.Channel}
                            onChange={onFilterDataItemChange}
                        />
                    </div>
                </div>
                <div className="col-3" style={{ padding : '0px 0px 0px'}} >
                    <div className="input-group input-group mb" >
                        <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onKeyDown={onSearchEnter} onChange={handleServerSideSearch} />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} onClick={onSearchClick}>
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <Grid
                    ref={gridRef}
                    data={gridData.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "single",
                    }}
                    resizable={true}
                    onSelectionChange={onSelectionChange}
                    style={{ height: "35vh" }}
                    onScroll={scrollHandler}
                >
                    {isLoading && <GridNoRecords><i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i></GridNoRecords>}

                    <GridColumn field="FileName" title={'FileName'} editable={false} />
                    <GridColumn field="ScheduleDate" title={"Schedule Date"} editable={false} cell={DateOnlyCell} />
                    <GridColumn field="Remarks" title={'Remark'} editable={false} />
                    <GridColumn field="addOn" title={'Add On'} editable={false} cell={DateTimeCell} />
                    <GridColumn field="addBy.name" title={'Added By'} editable={false} />
                </Grid>
            </div>
            <div className="row">
                <div className="col-10 mt-1">
                    <p className='muted'>{lang.schedule_draft_3_month_auto_delete_note}</p>
                </div>
                <div className="col-2 text-right mt-1">{`${lang.total_label} ${count}`}</div>
            </div>
        </>
    );
});

export default OpenScheduleDraftCollectionSelection;
