import React, { useEffect, useRef, useState } from 'react'
import {
    Grid,
    GridColumn as Column, getSelectedState,
} from "@progress/kendo-react-grid";
import { useTranslation } from '../../../locale/useTranslation';
import { ShortTimeCell, TimeCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { ContextMenu } from 'primereact/contextmenu';
import { SCHEDULECOLORPREFERENCE } from '../../../framework/constant/constant';

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";


export const SchedulingAnnouncement = (props) => {
    const { selectedState } = props;
    const lang = useTranslation();
    const sa = useRef();//FOR SCHEDULER Announcement Event right Click
    const [selectedAnnouncement,setSelectedAnnouncement] = useState({});
    const [data,setData] = useState([]);

    useEffect(() => {
        setData(selectedState[0] == undefined ?
            [] : selectedState[0].Announcements ? selectedState[0].Announcements : []
        )
    },[selectedState])

    const GridRowRenderAnnouncement = (tr, props) => {
        const trProps = {
          style: props.dataItem.Style,
          draggable: false,
          onContextMenu: (e) => {
            e.preventDefault();
            sa.current?.show(e);
            setSelectedAnnouncement(props.dataItem);
            var newData = [...data.map((y) => {
                delete y[SELECTED_FIELD];
                return y;
            })]
            var index = data.findIndex((x) => x._id == props.dataItem._id);
            newData.splice(index,1,{...props.dataItem,[SELECTED_FIELD] : true})
            setData(newData);

          },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
      };

      const onSelectionChange = (event) => {
        var item = data[event.startRowIndex];
        var newData = [...data.map((y) => {
            delete y[SELECTED_FIELD];
            return y;
        })]
        let alreadySelected = selectedAnnouncement && selectedAnnouncement._id == item._id ? true : false;
        if(alreadySelected){
            setSelectedAnnouncement({});
        }else{
            newData.splice(event.startRowIndex,1,{...item,[SELECTED_FIELD] : true})
            setData(newData);
            setSelectedAnnouncement(item);
        }
      };



    return (
        <div style={{
            position: 'absolute',
            left: 0,
            height: "80%",
            width: '100%',
            padding: '8px',
            marginTop: '-15px',
        }}>
            <Grid
                data={data.map((item) => ({
                    ...item,
                    // [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                rowRender={GridRowRenderAnnouncement}
                style={{ height: "100%" }}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    mode: {
                    value: "multiple",
                    label: "Multiple selection mode",
                    },
                }}
                onSelectionChange={onSelectionChange}
                resizable = {true}
            >
                <Column field="Title" title={lang.title_column} />
                <Column field="Description" title={lang.description_column} />
                <Column field="AssetId" title={lang.assetId_column} />
                <Column field="Duration" title={lang.duration_label} cell={TimeCell} />
                <Column field="TimeRangeFrom" title={lang.time_range_from_label} cell={ShortTimeCell} />
                <Column field="TimeRangeTo" title={lang.time_range_to_label} cell={ShortTimeCell} />
                <Column field="SpotRate" title={lang.spot_rate_label} />
            </Grid>
            <ContextMenu
                ref={sa}
                model={[
                    //delete announcement
                    {
                        label: `${lang.delete_actionbutton_menutext}`,
                        // disabled: !lockStatus.canModify,
                        icon: "pi pi-fw pi-trash",
                        command: () => {
                            props.onDeleteAnnouncement(selectedAnnouncement);
                        },
                    },
                ]}
            />
        </div>


    )
}
