import { useEffect, useState } from "react";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import * as API from '../../framework/API/api'
import { ENTITYNAME } from "../../framework/constant/constant";
import { toast } from "react-toastify";

const DynamicFieldsTab = (props) => {

    //possible props
    //props.dynamicField

    
    // const dynamicField = props.dataItem ?? {};
    const [dynamicField ,setDynamicField] = useState(props.dataItem ?? {});
    const [dataColumns, setDataColumns] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {

        const response = await API.getData(ENTITYNAME.FormFields, { query: ['entityName', '=', ENTITYNAME.CABLE_LABS] });
        console.log(response);
        if (response.success) {
            setDataColumns(response.data.sort((a, b) => a.sequence - b.sequence));
        } else {
            toast.error(response.message);
        }

    };

    const onChange = (saveData) => {

        let item = {
            ...saveData,
            ...dynamicField,
        }
        
        delete item._id;
        delete item.SID;
        delete item.Archive;
        delete item.Publish;
        delete item.addOn;
        delete item.addBy;

        props.setDynamicFieldData(item);
    }

    return <EditForm showPopup={false} onChange={onChange} item={dynamicField} dataColumns={dataColumns} />

}

export default DynamicFieldsTab;