/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { EditPageHeader } from '../../components/EditPageHeader';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const MediaMamAssetsEditForm = (props) => {

  const lang = useTranslation();

  let blankDataItem = {
    SID: props.item.SID ?? 0,
    Title: '',
    AssetId: "",
    StartTime: "00:00:00:00",
    EndTime:  "00:00:00:00",
    ActualDuration: "00:00:00:00",
    ProgramEditStatus: '',
    ReferenceId: '',
    TXReady: false,
    SubtitleAvailble: false,
    CensorshipReportAvailable: false,
    QcPass: false,
    Archive: false,
    MediaEpisode_id: '',
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  console.log(props.item)
  useEffect(() => {
    loadcombo();
    if (props.item.SID > 0 || props.item.copy) {
      loadEditData();
    }
  }, []);


  const loadcombo = async () => {

  }

  const loadEditData = async () => {
    setDataItem({
      ...dataItem,
      ...props.item,
      SID : props.item.copy ? 0 : parseInt(props.item.SID),
      Title : props.item.copy ? 'Copy of ' + props.item.Title : props.item.Title,
      StartTime:  utility.convertMilisecondsToStringWithFrames(props.item.StartTime) ?? "00:00:00:00",
      EndTime: utility.convertMilisecondsToStringWithFrames(props.item.EndTime) ?? "00:00:00:00",
      ActualDuration: utility.convertMilisecondsToStringWithFrames(props.item.ActualDuration) ?? "00:00:00:00",
    })
  }

  const onChange = async (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
      console.log(!dataItem.Archive)
    }
    else if (e.target.name == "TXReady") {
      setDataItem({ ...dataItem, TXReady: !dataItem.TXReady });
      console.log(!dataItem.TXReady)
    }
    else if (e.target.name == "SubtitleAvailble") {
      setDataItem({ ...dataItem, SubtitleAvailble: !dataItem.SubtitleAvailble });
      console.log(!dataItem.SubtitleAvailble)
    }
    else if (e.target.name == "CensorshipReportAvailable") {
      setDataItem({ ...dataItem, CensorshipReportAvailable: !dataItem.CensorshipReportAvailable });
      console.log(!dataItem.CensorshipReportAvailable)
    }
    else if (e.target.name == "QcPass") {
      setDataItem({ ...dataItem, QcPass: !dataItem.QcPass });
      console.log(!dataItem.QcPass)
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  function validationCheck() {

    if (dataItem.Title == undefined || dataItem.Title =='' ) {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.AssetId == undefined || dataItem.AssetId == '' ) {
      toast.error(`${lang.please_enter_assetid_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.StartTime == undefined || dataItem.StartTime == '' || utility.convertStringWithFramesToMilliseconds(dataItem.StartTime) == 0 ) {
      toast.error(lang.please_enter_valid_start_time_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.EndTime == undefined || dataItem.EndTime == '' ||  utility.convertStringWithFramesToMilliseconds(dataItem.EndTime) == 0) {
      toast.error(lang.please_enter_valid_end_time_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (utility.convertStringWithFramesToMilliseconds(dataItem.EndTime) <= utility.convertStringWithFramesToMilliseconds(dataItem.StartTime)) {
      toast.error(lang.end_time_should_be_greater_than_start_time_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.ActualDuration == undefined || dataItem.ActualDuration == '' ||  utility.convertStringWithFramesToMilliseconds(dataItem.ActualDuration) == 0) {
      toast.error(lang.please_enter_valid_actual_duration_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.ProgramEditStatus == undefined || dataItem.ProgramEditStatus == '' ) {
      toast.error(`${lang.please_enter_programeditstatus_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ReferenceId == undefined || dataItem.ReferenceId == '') {
      toast.error(`${lang.please_enter_reference_no_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSaveData = async () => {

    if (validationCheck()) {
      let data = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Title: dataItem.Title,
        AssetId: dataItem.AssetId,
        StartTime: utility.convertStringWithFramesToMilliseconds(dataItem.StartTime)  ?? "00:00:00:00",
        EndTime: utility.convertStringWithFramesToMilliseconds(dataItem.EndTime)  ?? "00:00:00:00",
        ActualDuration: utility.convertStringWithFramesToMilliseconds(dataItem.ActualDuration) ?? "00:00:00:00",
        ProgramEditStatus: dataItem.ProgramEditStatus,
        ReferenceId: dataItem.ReferenceId,
        MediaEpisode_id: dataItem.MediaEpisode_id ?? '',
        TXReady: dataItem.TXReady ? true : false,
        SubtitleAvailble: dataItem.SubtitleAvailble ? true : false,
        CensorshipReportAvailable: dataItem.CensorshipReportAvailable ? true : false,
        QcPass: dataItem.QcPass ? true : false,
        Archive: dataItem.Archive ? true : false,
      }
      props.item.copy && delete data._id
      console.log(data)

      var res = await API.saveData(ENTITYNAME.MediaMamAssets, data);
      if (res.success) {
        let logData = {event: data.SID==0 ? LOGEVENT.CREATE_MAM_ASSETS : LOGEVENT.UPDATE_MAM_ASSETS, module: MODULE.MEDIA_EPISODE_MAMASSETS, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.cancelEdit();
        props.refresh();
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };
  
  return (
    <BossDialog
      title={props.SID > 0 ? dataItem.Title : `${lang.create_mamasset_dialog_header}`}
      onClose={props.cancelEdit}
      width={"65vw"}
    >

      <div className="row">
        <div className="col-12">
          <EditPageHeader onSubmit={onSaveData} onCancel={props.cancelEdit} showTitle={false} />

          <div className="row mt-2" >
            <div className='col-6'>
            <div className="form-group">
                <label htmlFor="">{lang.title_label} *</label>
                <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.Title} onChange={(e) => onChange(e)} />
              </div>
            </div>
            <div className='col-6'>
            <div className="form-group">
                <label htmlFor="">{lang.asset_id_label} *</label>
                <input name="AssetId" type="text" className="form-control form-control-sm" value={dataItem.AssetId} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.start_time_label} *</label>
                <TimePickerWithFormat
                  className="form-control form-control-sm mt-1"
                  name="StartTime"
                  value={dataItem.StartTime}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.end_time_label} *</label>
                <TimePickerWithFormat
                  className="form-control form-control-sm mt-1"
                  name="EndTime"
                  value={dataItem.EndTime}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.actual_duration_label} *</label>
                <TimePickerWithFormat
                  className="form-control form-control-sm mt-1"
                  name="ActualDuration"
                  value={dataItem.ActualDuration}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.program_edit_status_label} *</label>
                <input name="ProgramEditStatus" type="text" className="form-control form-control-sm" value={dataItem.ProgramEditStatus} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.reference_no_label} *</label>
                <input name="ReferenceId" type="text" className="form-control form-control-sm" value={dataItem.ReferenceId} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-3">
              <input type="checkbox" name={"TXReady"} value={dataItem.TXReady} checked={dataItem.TXReady} onChange={onChange} /> {lang.tx_ready_label}
            </div>
            <div className="col">
              <input type="checkbox" name={"SubtitleAvailble"} value={dataItem.SubtitleAvailble} checked={dataItem.SubtitleAvailble} onChange={onChange} /> {lang.subtitle_availble_label}
            </div>
            <div className="col-3">
              <input type="checkbox" name={"CensorshipReportAvailable"} value={dataItem.CensorshipReportAvailable} checked={dataItem.CensorshipReportAvailable} onChange={onChange} /> {lang.censorship_report_available_label}
            </div>
            <div className="col">
              <input type="checkbox" name={"QcPass"} value={dataItem.QcPass} checked={dataItem.QcPass} onChange={onChange} /> {lang.qcpass_label}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input style={{ marginTop: "05px" }} type="checkbox" name={"Archive"} checked={dataItem.Archive} onChange={onChange} /> {lang.archive}
            </div>
          </div>
        </div>
      </div>
    </BossDialog>
  )
}

export default MediaMamAssetsEditForm