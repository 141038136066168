/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Form, FormElement } from '@progress/kendo-react-form'
import { EditPageHeader } from '../../../components/EditPageHeader'
import * as API from "../../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from '../../../framework/constant/constant'
import { TimeRangePicker } from '../../../framework/forms/helpercomponents/timepicker/TimeRangePicker'
import { utility } from '../../../framework/utility/utilityProvider'
import { toast } from 'react-toastify';
import { useTranslation } from '../../../locale/useTranslation'
import { RadioButton } from "@progress/kendo-react-inputs";
import BossDialog from '../../../components/BossDialog'

const UpdateBookingLineNumberEditMultipleForm = (props) => {

    let blankDataItem = {
        SpotRate: 0,
        TimeRangeFrom: "00:00",
        TimeRangeTo: "23:59",
        MediaEpisode: {},
        UpdateChannel: false,
        UpdateCommercial: false,
        UpdateRate: false,
        UpdateFromToTime: false,
        UpdateDealType: false,
        Channel: {},
        Commercial: {},
        DealType: {},
        TotalSpot: 0,
        TotalAmount: 0,
        TotalFCT: 0
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();
    const [rateType, setRateType] = useState("Rate");
    const [dealType, setDealType] = useState([]);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let dealType = await API.getDataLookup(ENTITYNAME.DealType, { sort: { Description: 1 } });
        setDealType(dealType.data);
    }

    const onChange = (e) => {

        if (e.target.name == 'UpdateChannel') {
            setDataItem({ ...dataItem, UpdateChannel: !dataItem.UpdateChannel });
        }

        else if (e.target.name == 'UpdateCommercial') {
            setDataItem({ ...dataItem, UpdateCommercial: !dataItem.UpdateCommercial });
        }
        else if (e.target.name == 'UpdateRate') {
            setDataItem({ ...dataItem, UpdateRate: !dataItem.UpdateRate });
        }

        else if (e.target.name == 'UpdateFromToTime') {
            setDataItem({ ...dataItem, UpdateFromToTime: !dataItem.UpdateFromToTime });
        }
        else if (e.target.name == 'UpdateDealType') {
            setDataItem({ ...dataItem, UpdateDealType: !dataItem.UpdateDealType });
        }

        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }

    }

    const isValid = () => {

        //IF NOTHING SELECTED TO UPDATE
        if (!dataItem.UpdateChannel && !dataItem.UpdateCommercial && !dataItem.UpdateRate && !dataItem.UpdateFromToTime && !dataItem.UpdateDealType) {
            toast.error(`${lang.please_select_atleast_one_value_to_update_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        //FOR CHANNEL
        if (dataItem.UpdateChannel && (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0)) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        //FOR COMMERCIAL
        if (dataItem.UpdateCommercial && (dataItem.Commercial == undefined || Object.keys(dataItem.Commercial).length == 0)) {
            toast.error(`${lang.please_select_house_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.UpdateRate && (dataItem.SpotRate == "" || dataItem.SpotRate == undefined || dataItem.SpotRate < 0)) {
            toast.error(`${rateType == 'Rate' ? lang.invalid_spotRate_error_message : lang.invalid_rate10Sec_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        //FOR DEAL TYPE
        if (dataItem.UpdateDealType && (dataItem.DealType == undefined || Object.keys(dataItem.DealType).length == 0)) {
            toast.error(`${lang.please_select_deal_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.UpdateRate && dataItem.UpdateCommercial) {

            // CALCULATING TOTALSPOTS, TOTALFCT,TOTALAMOUT
            for (let i = 0; i < props.bookingsForMultiEdit.length; i++) {
                let booking = props.bookingsForMultiEdit[i];
                console.log(booking);
                let updatedTotalSpots = 0;
                props.days.map((day) => {
                    let spot = booking[day] != undefined ? booking[day]['daySpots'] : 0;
                    updatedTotalSpots = updatedTotalSpots + spot;
                });
                dataItem.TotalSpot = dataItem.TotalSpot + updatedTotalSpots;
                dataItem.TotalAmount = dataItem.TotalAmount + (parseInt(dataItem.SpotRate) * updatedTotalSpots);
                dataItem.TotalFCT = dataItem.TotalFCT + (updatedTotalSpots * dataItem.Commercial.Duration / 1000);
            }

            //FOR TOTAL BOOKED AMOUNT
            if ((props.totalBookedAmount + dataItem.TotalAmount) > props.selectedCampaign.TargetBudget) {
                toast.error(`${lang.exceding_target_budget_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            //FOR TOTAL BOOKED SECONDS
            if ((props.totalBookedSeconds + dataItem.TotalFCT) > props.selectedCampaign.TargetSeconds) {
                toast.error(`${lang.exceding_target_seconds_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

            //FOR TOTAL BOOKED SPOTS
            //   if((props.totalBookedSpots + dataItem.TotalSpot) > props.selectedCampaign.TargetSpots)
            if (dataItem.TotalSpot > props.selectedCampaign.TargetSpots) {
                toast.error(`${lang.exceding_target_spots_limit_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }
        }

        return true;
    }

    const onSave = async () => {

        if (!isValid()) {
            return;
        }

        var updatedBookingsToSave = [];
        for (let i = 0; i < props.bookingsForMultiEdit.length; i++) {
            var booking = props.bookingsForMultiEdit[i];

            var commercial = dataItem.UpdateCommercial ? dataItem.Commercial : booking.MediaEpisode;
            var durationInSeconds = commercial.Duration / 1000;

            var bookingEntityToSave = { ...booking };
            if (dataItem.UpdateChannel) {
                bookingEntityToSave = { ...bookingEntityToSave, Channel: dataItem.Channel };
            }

            if (dataItem.UpdateCommercial) {
                //CASE IF COMMERCIAL AND SPOT REATE BOTH ARE CHOOSEN TO UPDATE ELSE FROM SAVED BOOKING
                var spotRate = dataItem.UpdateRate ? dataItem.SpotRate : booking.SpotRate;
                //var rate10Sec = dataItem.UpdateRate ? dataItem.SpotRate : booking.Rate10Sec;
                console.log(spotRate);

                //CASE IF COMMERCIAL AND SPOT REATE BOTH ARE CHOOSEN TO UPDATE
                var isRate10Sec = dataItem.UpdateRate && rateType == 'Rate' ? false : booking.IsRate10SecBooked;//(booking.IsRate10SecBooked ? true: false);  

                // console.log(isRate10Sec);

                var updatedSpotRate = isRate10Sec ? spotRate / 10 * durationInSeconds : spotRate;
                var updatedRate10Sec = isRate10Sec ? spotRate : (spotRate / durationInSeconds) * 10;

                bookingEntityToSave = { ...bookingEntityToSave, MediaEpisode: commercial, SpotRate: parseFloat(updatedSpotRate).toFixed(2), Rate10Sec: parseFloat(updatedRate10Sec).toFixed(2), IsRate10SecBooked: isRate10Sec }
            }

            if (dataItem.UpdateRate) {
                var spotRate = rateType == 'Rate' ? dataItem.SpotRate : dataItem.SpotRate / 10 * durationInSeconds;
                var rate10Sec = rateType == 'Rate' ? (dataItem.SpotRate / durationInSeconds) * 10 : dataItem.SpotRate;
                var isRate10Sec = rateType == 'Rate' ? false : true;
                bookingEntityToSave = { ...bookingEntityToSave, SpotRate: parseFloat(spotRate).toFixed(2), Rate10Sec: parseFloat(rate10Sec).toFixed(2), IsRate10SecBooked: isRate10Sec }
            }

            //UPDATEING FROM TIME AND TO TIME IF SELECTED  ELSE FROM SAVED // IT WAS CAUSING ISSUE ON SAVE AS API ACCEPTS TIME IN "HH:MM" FORMAT
            var fromTime = dataItem.UpdateFromToTime ? dataItem.FromTime : utility.convertMilisecondsToShortTimeString(booking.TimeRangeFrom);
            var toTime = dataItem.UpdateFromToTime ? dataItem.ToTime : utility.convertMilisecondsToShortTimeString(booking.TimeRangeTo);

            bookingEntityToSave = { ...bookingEntityToSave, TimeRangeFrom: fromTime, TimeRangeTo: toTime }

            if (dataItem.UpdateDealType) {
                bookingEntityToSave = { ...bookingEntityToSave, DealType: dataItem.DealType }
            }

            updatedBookingsToSave.push(bookingEntityToSave);
        }

        props.updateBookingsOnMultiEditSave(updatedBookingsToSave);
        let logCapture = { event: LOGEVENT.UPDATE_BOOKING_LINE_NUMBER, module: MODULE.UPDATE_BOOKING_LINE_NUMBER, data: updatedBookingsToSave, message: "Update Multiple Booking Line Number" };
        API.SaveLogs(logCapture);

    }

    let boxWithWhiteBackground = {
        backgroundColor: '#fff',
        borderRadius: 4,
        borderWidth: 0.5,
        borderColor: '#000',
        padding: 8
    }

    return (
        <BossDialog
            title={lang.update_booking_multiple_dialog_header}
            onClose={props.onClose}
            width={"60%"}
            height={"650px"}
        >
            <EditPageHeader title={""} onSubmit={onSave} onCancel={props.onClose} showTitle={false} />
            <Form
                render={(formRenderProps) => (
                    <FormElement style={boxWithWhiteBackground}>

                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-3'>
                                <label>{lang.campaign_label}</label>
                            </div>
                            <div className='col-md-3' style={{ marginLeft: "-15px" }}>
                                <label style={{ color: "blue", fontWeight: "bold" }}>{props.selectedCampaign.CampaignNo}</label>
                            </div>
                        </div>

                        {/* CHANNEL GROUP */}
                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="checkbox" disabled={props.editDisable} name={"UpdateChannel"}
                                            value={dataItem.UpdateChannel} checked={dataItem.UpdateChannel} onChange={onChange} />
                                        <span style={{ fontSize: "12px", marginLeft: "3px" }}>Change</span>
                                    </div>
                                </div>

                                <div className="row" style={boxWithWhiteBackground}>
                                    <div className="col-3">
                                        <label>{lang.channel_label}</label>
                                    </div>
                                    <div className="col-3" style={{ marginLeft: "-15px" }}>

                                        <DropDownList
                                            data={props.selectedCampaign.Channels}
                                            name="Channel"
                                            textField="FullChannelName"
                                            dataItemKey="_id"
                                            value={dataItem.Channel}
                                            onChange={onChange}
                                            disabled={!dataItem.UpdateChannel}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* COMMERCIAL GROUP */}
                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input type="checkbox" disabled={props.editDisable} name={"UpdateCommercial"}
                                            value={dataItem.UpdateCommercial} checked={dataItem.UpdateCommercial} onChange={onChange} />
                                        <span style={{ fontSize: "12px", marginLeft: "3px" }}>Change</span>
                                    </div>
                                </div>

                                <div className="row" style={boxWithWhiteBackground}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className="col-3">
                                                <label>{lang.house_number_label}</label>
                                            </div>
                                            <div className="col-3" style={{ marginLeft: "-15px" }}>
                                                <DropDownList
                                                    data={props.selectedCampaign.Commercials ?? []}
                                                    name="Commercial"
                                                    textField="AssetId"
                                                    dataItemKey="_id"
                                                    value={dataItem.Commercial}
                                                    onChange={onChange}
                                                    disabled={!dataItem.UpdateCommercial}
                                                    validator={(value) => value ? "" : "Please select the value"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: "15px" }}>
                                            <div className="col-3">
                                                <label>{lang.title_label}</label>
                                            </div>
                                            <div className="col-3" style={{ marginLeft: "-15px" }}>
                                                <label>{dataItem.Commercial.Title}</label>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginTop: "15px" }}>
                                            <div className="col-3">
                                                <label>{lang.commercial_duration_lable}</label>
                                            </div>
                                            <div className="col-3" style={{ marginLeft: "-15px" }}>
                                                <label>{dataItem.Commercial.Duration != undefined ? utility.convertMilisecondsToStringWithFrames(dataItem.Commercial.Duration) : 0}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* RATE GROUP */}
                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-12'>
                                <div className='row' >
                                    <div className='col-md-12'>
                                        <input type="checkbox" name={"UpdateRate"}
                                            value={dataItem.UpdateRate} checked={dataItem.UpdateRate} onChange={onChange} />
                                        <span style={{ fontSize: "12px", marginLeft: "3px" }}>Change</span>
                                    </div>
                                </div>

                                <div className="row" style={boxWithWhiteBackground}>

                                    <div className="col-3" style={{ marginTop: "5px", marginLeft: "10px", padding: "0" }}>
                                        <RadioButton
                                            name="RateType"
                                            value="Rate"
                                            checked={rateType === "Rate"}
                                            label={lang.rate_label}
                                            onChange={(e) => { onChange(e); setRateType(e.value) }}
                                            disabled={!dataItem.UpdateRate}
                                        />
                                        <RadioButton
                                            style={{ marginLeft: "5px" }}
                                            name="RateType"
                                            value="Rate10Sec"
                                            checked={rateType === "Rate10Sec"}
                                            label={lang.rate10sec_label}
                                            onChange={(e) => { onChange(e); setRateType(e.value) }}
                                            disabled={!dataItem.UpdateRate}
                                        />
                                    </div>

                                    <div className='col-md-3' style={{ marginLeft: "-20px" }}>
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            name="SpotRate"
                                            value={dataItem.SpotRate}
                                            disabled={!dataItem.UpdateRate}
                                            onChange={(e) => onChange(e)} />
                                    </div>

                                </div>

                            </div>

                        </div>

                        {/* TIME RANGE GROUP */}
                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-12'>
                                <div className='row' >
                                    <div className='col-md-12'>
                                        <input type="checkbox" disabled={props.editDisable} name={"UpdateFromToTime"}
                                            value={dataItem.UpdateFromToTime} checked={dataItem.UpdateFromToTime} onChange={onChange} />
                                        <span style={{ fontSize: "12px", marginLeft: "3px" }}>Change</span>
                                    </div>
                                </div>

                                <div className="row" style={boxWithWhiteBackground}>

                                    <div className='col-md-12'>
                                        <div className="row">
                                            <div className="col-3">
                                                <label htmlFor="FromTime">{lang.from_time_label} *</label>
                                            </div>
                                            <div className="col-3">
                                                <TimeRangePicker
                                                    name="FromTime"
                                                    value={dataItem.FromTime}
                                                    onChange={(e) => onChange(e)}
                                                    disabled={!dataItem.UpdateFromToTime}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mt-1" >
                                            <div className="col-3">
                                                <label htmlFor="ToTime">{lang.to_time_label} *</label>
                                            </div>
                                            <div className="col-3">
                                                <TimeRangePicker
                                                    name="ToTime"
                                                    mask={'23:59'}
                                                    value={dataItem.ToTime}
                                                    onChange={(e) => onChange(e)}
                                                    disabled={!dataItem.UpdateFromToTime}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        {/* DEAL TYPE GROUP */}
                        <div className="row form-box mt-2" style={boxWithWhiteBackground}>
                            <div className='col-md-12'>
                                <div className='row' >
                                    <div className='col-md-12'>
                                        <input type="checkbox" disabled={props.editDisable} name={"UpdateDealType"}
                                            value={dataItem.UpdateDealType} checked={dataItem.UpdateDealType} onChange={onChange} />
                                        <span style={{ fontSize: "12px", marginLeft: "3px" }}>Change</span>
                                    </div>
                                </div>

                                <div className="row" style={boxWithWhiteBackground}>
                                    <div className="col-3">
                                        <label>{lang.deal_type_label}</label>
                                    </div>
                                    <div className="col-3" style={{ marginLeft: "-15px" }}>

                                        <DropDownList
                                            data={dealType}
                                            name="DealType"
                                            textField="Description"
                                            // dataItemKey="_id"
                                            value={dataItem.DealType}
                                            onChange={onChange}
                                            disabled={!dataItem.UpdateDealType}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* STATUS BAR */}
                        <div className='row mt-2'>
                            <div className='col-md-12' style={{ backgroundColor: "lightblue", height: "30px", textAlign: "center" }}>
                                <label style={{ color: "red", fontSize: "12px", fontWeight: "bold", marginTop: "3px" }}>{props.editDisable ? 'This line used in scheduled. You can update spot rate only' : ''}</label>
                            </div>
                        </div>


                    </FormElement>
                )}
            />

        </BossDialog>
    )
}

export default UpdateBookingLineNumberEditMultipleForm