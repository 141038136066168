export const hn = () => {
  return {
    dashboard: 'डैशबोर्ड',
    cms_dashboard: 'सीएमएस',
    content_dashboard: 'सामग्री',
    sales_dashboard: 'बिक्री',
    booking_status_dashboard: 'बुकिंग स्टेटस',
    booking_summary_dashboard: 'बुकिंग सारांश',
    contract_menu: 'अनुबंध',
    contract_sub_menu: 'अनुबंध',
    media_management_menu: 'मीडिया प्रबंधन',
    series_sub_menu: 'श्रृंखला',
    season_sub_menu: 'सीजन',
    program_sub_menu: 'कार्यक्रम',
    movie_sub_menu: 'मूवी',
    trailer_sub_menu: 'Trailer',
    interstitial_sub_menu: 'अंतरालीय',
    commercial_sub_menu: 'वाणिज्यिक',
    filler_sub_menu: 'फिलर',
    music_sub_menu: 'संगीत',
    break_sub_menu: 'ब्रेक',
    media_episode_group_sub_menu: 'मीडिया एपिसोड ग्रुप',
    planning_menu: 'योजना',
    planning_sub_menu: 'योजना',
    formats_sub_menu: 'प्रारूप',
    program_schedule_clash_sub_menu: 'प्रोग्राम शेड्यूल क्लैश',
    scheduling_menu: 'शेड्यूलिंग',
    curate_menu: 'क्यूरेट',
    timeline_sub_menu: 'टाइमलाइन',
    rails_sub_menu: 'रेल',
    pages_sub_menu: 'पृष्ठ',
    assets_sub_menu: 'संपत्ति',
    tv_preview_sub_menu: 'टीवी पूर्वावलोकन',
    web_preview_sub_menu: 'Web पूर्वावलोकन',
    mobile_preview_sub_menu: 'मोबाइल पूर्वावलोकन',
    ingestion_menu: 'अंतर्ग्रहण',
    posters_sub_menu: 'पोस्टर',
    ingestion_template_sub_menu: 'अंतर्ग्रहण टेम्पलेट',
    partner_ingestion_sub_menu: 'पार्टनर अंतर्ग्रहण',
    jobs_sub_menu: 'नौकरियां',
    sales_menu: 'बिक्री',
    agency_sub_menu: 'एजेंसी',
    client_sub_menu: 'ग्राहक',
    campaign_sub_menu: 'अभियान',
    booking_sub_menu: 'बुकिंग',
    booking_transfer_sub_menu: 'बुकिंग ट्रांसफर',
    update_booking_line_number_sub_menu: 'अपडेट बुकिंग लाइन नंबर',
    sales_team_sub_menu: 'बिक्री टीम',
    sales_executive_sub_menu: 'बिक्री कार्यकारी',
    reconciliation_menu: 'सुलह',
    as_run_reconciliation_sub_menu: 'जैसा कि सुलह चलाएं',
    import_planning_sub_menu: 'आयात योजना',
    import_asRun_sub_menu: 'आयात करें',
    manage_asRun_sub_menu: 'Asrun प्रबंधित करें',
    export_menu: 'निर्यात',
    epg_sub_menu: 'EPG',
    epg_template_sub_menu: 'ईपीजी टेम्पलेट',
    secondary_event_menu: 'सेकेंडरी इवेंट',
    prototype_sub_menu: 'प्रोटोटाइप',
    styleSheet_sub_menu: 'स्टाइलशीट',
    default_prototype_sub_menu: 'डिफ़ॉल्ट प्रोटोटाइप',
    protoType_source_sub_menu: 'प्रोटोटाइप स्रोत',
    secondary_event_dynamic_rule_sub_menu: 'सेकेंडरीइवेंट डायनेमिक नियम',
    billing_menu: 'बिलिंग',
    billing_sub_menu: 'बिलिंग',
    generated_bills_sub_menu: 'जेनरेट किए गए बिल',
    masters_menu: 'मास्टर्स',
    master_menu_title: 'मास्टर',
    language_sub_menu: 'भाषा',
    content_sub_menu: 'सामग्री',
    assets_type_sub_menu: 'संपत्ति प्रकार',
    rails_type_sub_menu: 'रेल प्रकार',
    posters_type_sub_menu: "पोस्टर प्रकार",
    users_sub_menu: 'उपयोगकर्ता',
    department_sub_menu: 'विभाग',
    role_sub_menu: 'भूमिका',
    content_category_sub_menu: 'सामग्री श्रेणी',
    region_sub_menu: 'क्षेत्र',
    brand_sub_menu: 'ब्रांड',
    product_sub_menu: 'उत्पाद',
    bank_account_sub_menu: 'बैंक खाता',
    tax_sub_menu: 'टैक्स',
    licensor_sub_menu: 'लाइसेंसकर्ता',
    channel_sub_menu: 'चैनल',
    currency_sub_menu: 'मुद्रा',
    time_range_sub_menu: 'टाइम रेंज',
    repeat_pattern_sub_menu: 'रिपीट पैटर्न',
    invoice_content_sub_menu: 'चालान सामग्री',
    segemnt_type_sub_menu: 'सेगमेंट प्रकार',
    variation_sub_menu: 'भिन्नता',
    break_format_sub_menu: 'ब्रेक फॉर्मेट',
    payment_provider_sub_menu: 'भुगतान प्रदाता',
    ott_period_type_sub_menu: 'ओट पीरियड टाइप',
    payment_type_sub_menu: 'भुगतान प्रकार',
    schedule_constraints_sub_menu: 'अनुसूची बाधाएं',
    monetization_menu: 'मुद्रीकरण',
    ott_period_sub_menu: 'अवधि',
    subscriber_sub_menu: 'सब्सक्राइबर',
    ott_campaign_sub_menu: 'अभियान',
    coupon_sub_menu: 'कूपन',
    data_migration_menu: 'डेटा माइग्रेशन',
    form_fields_menu: 'फॉर्म फ़ील्ड्स',
    save_button_tooltip: 'बचाओ',
    save_button_text: 'बचाओ',
    back_button_tooltip: 'वापस',
    add_button_tooltip: 'जोड़ें',
    add_button_text: 'जोड़ें',
    add_multiple_button_text: 'एकाधिक जोड़ें',
    add_dialog_header: 'जोड़ें',
    new_button_dialog_header: 'नया',
    new_title_editpageheader: 'नया',
    edit_button_tooltip: 'संपादित करें',
    update_button_tooltip: 'अद्यतन',
    remove_button_tooltip: 'हटाओ',
    delete_button_tooltip: 'हटाएं',
    select_button_tooltip: 'चयन करें',
    select_button_text: 'चयन करें',
    cancel_button_tooltip: 'रद्द करें',
    cancel_button_text: 'रद्द करें',
    preference_button_tooltip: 'वरीयता',
    options: 'विकल्प',
    refresh_button_tooltip: 'ताज़ा करो',
    archive: 'पुरालेख',
    ExportBreaksOnly: 'Export Breaks Only',
    loading_pleasewait: 'लोड करें कृपया प्रतीक्षा करें ...',
    no_records_found: 'कोई रिकॉर्ड नहीं मिला!!',
    contract_number_label: 'अनुबंध संख्या',
    platform_type_label: 'प्लेटफ़ॉर्म प्रकार',
    licensor_label: 'लाइसेंसकर्ता',
    payee_label: 'पायी',
    start_date_label: 'प्रारंभ दिनांक',
    end_Date_label: 'समाप्ति तिथि',
    channel_label: 'चैनल',
    platform_label: 'मंच',
    region_label: 'क्षेत्र',
    exhibition_label: 'प्रदर्शनी',
    repeat_label: 'दोहराएं',
    play_time_number_label: 'प्ले टाइम नंबर',
    playTime_label: 'प्ले टाइम',
    is_unlimited_label: 'असीमित है',
    add_media_episode_button_tooltip: 'मीडिया एपिसोड जोड़ें',
    remove_media_episode_button_tooltip: 'मीडिया एपिसोड हटाओ',
    assetId_column: 'एसेट आईडी',
    title_column: 'शीर्षक',
    media_category_column: 'मीडिया श्रेणी',
    start_date_column: 'प्रारंभ दिनांक',
    end_date_column: 'समाप्ति तिथि',
    is_unlimited_column: 'असीमित है',
    exhibition_column: 'प्रदर्शनी',
    repeat_column: 'दोहराएं',
    play_time_number_column: 'PlayTimeNumber',
    playTime_column: 'प्लेटाइम',
    media_library_dialog_header: 'मीडिया लाइब्रेरी',
    summary: 'सारांश',
    create_series_dialog_header: 'श्रृंखला बनाएं',
    title_label: 'शीर्षक',
    content_label: 'सामग्री',
    asset_id_label: 'एसेट आईडी',
    description_label: 'विवरण',
    synopsis_label: 'सारांश',
    islive_label: 'IsLive',
    seasons_title: 'मौसम',
    series_cardlist_header: 'सीजन',
    season_cardlist_header: 'एपिसोड',
    sidefix_season_tooltip: 'सीजन',
    s_content_label: 'एस कंटेंट',
    season_no_label: 'सीजन नंबर',
    ep_content_label: 'Ep. Content',
    episodes_label: 'एपिसोड',
    no_of_episodes_label: 'एपिसोड की संख्या',
    duration_label: 'अवधि (hh:mm:ss:ff)',
    total_block_duration_label: 'कुल ब्लॉक अवधि (एचएच: मिमी: एसएस: एफएफ)',
    season_number_column: 'सीजन नंबर',
    no_of_episodes_column: 'नहीं। एपिसोड के बारे में',
    season_content_column: 'सीजन कंटेंट',
    episode_content_column: 'एपिसोड सामग्री',
    duration_column: 'अवधि',
    timerangefrom_column: 'से',
    timerangeto_column: 'नहीं',
    select_image_collection_header: 'छवि का चयन करें',
    description_column: 'विवरण',
    meta_data_label: 'मेटा डेटा',
    posters_label: 'पोस्टर',
    publishing_label: 'प्रकाशन',
    search_by_title: '  शीर्षक से खोजें',
    genre_label: 'शैली',
    language_label: 'भाषा',
    pg_rating_label: 'पीजी रेटिंग',
    keywords_label: 'कीवर्ड',
    add_poster_button_tooltip: 'पोस्टर जोड़ें',
    poster_type_column: 'पोस्टर प्रकार',
    image_column: 'छवि',
    edit_poster_dialog_header: 'पोस्टर संपादित करें',
    poster_type_label: 'पोस्टर प्रकार',
    url_label: 'यू आर एल',
    select_url_tooltip: 'यू आर एल का चयन करें',
    add_publishing_button_tooltip: 'प्रकाशन जोड़ें',
    unlimited_rights_grid: 'असीमित अधिकार',
    tba_label: 'टीबीए',
    tba_column: 'टीबीए',
    publishing_dialog_header: 'प्रकाशन बनाएँ',
    platform_type_column: 'प्लेटफ़ॉर्म प्रकार',
    channel_column: 'चैनल',
    source_column: 'स्रोत',
    enter_title: 'शीर्षक दर्ज करें',
    create_season_dialog_header: 'सीजन बनाएं',
    season_number_label: 'सीजन नंबर',
    series_label: 'श्रृंखला',
    starting_episode_label: 'शुरुआती एपिसोड',
    select_series_collection_header: 'श्रृंखला का चयन करें',
    video_label: 'वीडियो',
    add_video_button_tooltip: 'वीडियो जोड़ें',
    video_type_column: 'वीडियो प्रकार',
    video_type_label: 'वीडियो प्रकार',
    url_column: 'यू आर एल',
    edit_video_dialog_header: 'वीडियो संपादित करें',
    create_video_dialog_header: 'वीडियो बनाएं',
    episode_title_label: 'एपिसोड शीर्षक',
    episode_number_label: 'एपिसोड नंबर',
    release_date_label: 'रिलीज़ की तारीख',
    version_label: 'संस्करण',
    content_category_label: 'सामग्री श्रेणी',
    format_label: 'प्रारूप',
    cast_and_crew_label: 'कास्ट एंड क्रू',
    dynamic_fields_label: 'गतिशील फ़ील्ड',
    censor_rating_label: 'सेंसर रेटिंग',
    technical_prototype_label: 'तकनीकी प्रोटोटाइप',
    production_year_label: 'प्रोडक्शन ईयर',
    language_column: 'भाषा',
    synopsis_column: 'सारांश',
    cast_column: 'कास्ट',
    name_column: 'नाम',
    name_label: 'नाम',
    cast_type_label: 'कास्ट टाइप',
    segment_type_column: 'प्रकार',
    material_id_column: 'सामग्री आईडी',
    actual_duration_column: 'वास्तविक अवधि',
    is_default_column: 'डिफ़ॉल्ट है',
    is_hard_parted_column: 'मुश्किल से अलग है',
    edit_version_dialog_header: 'संस्करण संपादित करें',
    create_version_dialog_header: 'संस्करण बनाएँ',
    segment_type_label: 'सेगमेंट प्रकार',
    material_id_label: 'सामग्री आईडी',
    is_hard_parted_label: 'मुश्किल से अलग है',
    is_default_label: 'डिफ़ॉल्ट है',
    generate_segments_button_text: 'सेगमेंट उत्पन्न करें',
    auto_distribute_button_text: 'ऑटो डिस्ट्रीब्यूट',
    sequence_column: 'अनुक्रम',
    media_id_column: 'मीडिया आईडी',
    start_time_column: 'प्रारंभ समय',
    end_time_column: 'अंत समय',
    previewed_column: 'पूर्वावलोकन',
    parent_content_category_column: 'अभिभावक सामग्री श्रेणी',
    select_format_collection: 'प्रारूप का चयन करें',
    select_content_category_collection_header: 'सामग्री श्रेणी का चयन करें',
    manual_assetid_label: 'मैनुअल एसेट आईडी',
    media_category_label: 'मीडिया श्रेणी',
    media_category_type_label: 'मीडिया श्रेणी प्रकार',
    tc_in_label: 'TC In (hh:mm:ss:ff)',
    tc_out_label: 'Tc Out (hh:mm:ss:ff)',
    media_episode_group_dialog_header: 'मीडिया एपिसोड ग्रुप',
    program_group_dialog_header: 'प्रोग्राम ग्रुप',
    interstital_group_dialog_header: 'इंटरस्टिटल ग्रुप',
    media_category_type_column: 'मीडिया श्रेणी प्रकार',
    media_category_type_collection_header: 'मीडिया श्रेणी प्रकार',
    episode_dialog_header: 'एपिसोड',
    sidefixmenu_rails_asset_tooltip: 'संपत्ति',
    cardlist_rails_title: 'संपत्ति',
    cardlist_rails_assets_title: 'एसेट लाइब्रेरी',
    rails_title_editpageheader: 'रेल',
    display_title_label: 'प्रदर्शन शीर्षक',
    rails_type_label: 'प्रकार',
    rails_note_label: 'नोट: उपयोगकर्ता चयन कर सकते हैं',
    publish_label: 'प्रकाशित करें',
    published_label: 'प्रकाशित',
    unpublished_label: 'अप्रकाशित',
    rails_p_label: 'पी',
    rails_up_label: 'यूपी',
    ott_assets_collection_header: 'ओट एसेट्स',
    options_actionbutton_menutext: 'विकल्प',
    delete_actionbutton_option: 'हटाएं',
    moveup_actionbutton_option: 'ऊपर बढ़ो',
    movedown_actionbutton_option: 'नीचे हटो',
    sidefixmenu_rails_tooltip: 'रेल',
    cardlist_page_rails_title: 'रेल',
    cardlist_rails_page_title: 'रेल पुस्तकालय',
    theme_label: 'थीम',
    icon_url_label: 'आइकन यूआरएल',
    please_fill_out_details_label: 'कृपया इस फ़ील्ड को भरें।',
    assets_dialog_header: 'संपत्ति बनाएं',
    validation: 'कृपया मान का चयन करें',
    asset_type_label: 'संपत्ति प्रकार',
    portrait_image_url_label: 'पोर्ट्रेट इमेज यूआरएल',
    landscape_image_url_label: 'लैंडस्केप इमेज यूआरएल',
    video_playback_url_label: 'वीडियो प्लेबैक यूआरएल',
    select_portal_image_url_collection_header: 'पोर्ट्रेट छवि यूआरएल का चयन करें',
    select_landscape_image_url_collection_header: 'लैंडस्केप इमेज यूआरएल का चयन करें',
    create_poster_dialog_header: 'पोस्टर बनाएं',
    image_url_label: 'छवि यू आर एल',
    file_path_label: 'फ़ाइल पथ',
    poster_note_label: 'नोट: छवि सहेजने पर अपलोड की जाएगी और यूआरएल असाइन किया जाएगा',
    template_name_label: 'टेम्पलेट नाम',
    module_type_label: 'मॉड्यूल प्रकार',
    ingestion_template_type_label: 'अंतर्ग्रहण टेम्पलेट प्रकार',
    save_template_text: 'टेम्पलेट सहेजें',
    send_text: 'भेजें',
    send_button_tooltip: 'भेजें',
    params_label: 'परम्स',
    body_label: 'शरीर',
    response_label: 'प्रतिक्रिया',
    select_file_label: 'फ़ाइल का चयन करें',
    load_button_text: 'लोड',
    ingestion_template_note_label: 'अच्छी तरह से सहेजते समय पहले परम मान को सर्चटेक्स्ट से बदलें  ',
    key_column: 'कुंजी',
    value_column: 'मूल्य',
    key_label: 'कुंजी',
    value_label: 'मूल्य',
    template_details_label: 'टेम्पलेट विवरण',
    template_label: 'टेम्पलेट',
    search_title_label: 'खोज शीर्षक',
    ingest_button_text: 'इंगेस्ट',
    ingest_button_tooltip: 'इंगेस्ट',
    load_button_tooltip: 'लोड',
    ingestion_details_label: 'अंतर्ग्रहण विवरण',
    pg_rating_column: 'पीजीरेटिंग',
    genres_column: 'शैलियों',
    show_response_label: 'प्रतिक्रिया दिखाएं',
    response_dialog_header: 'प्रतिक्रिया',
    eye_tooltip: 'आंख',
    agency_dialog_header: 'एजेंसी बनाएं',
    code_label: 'कोड',
    address_label: 'पता',
    pincode_label: 'पिन कोड',
    email_label: 'ईमेल',
    credit_limit_label: 'क्रेडिट सीमा',
    pan_label: 'पैन',
    gst_number_label: 'जीएसटी नंबर',
    default_tax_label: 'डिफ़ॉल्ट कर',
    is_credit_unlimited_label: 'क्रेडिट असीमित है',
    client_dialog_header: 'ग्राहक बनाएँ',
    agency_label: 'एजेंसी',
    campaign_number_label: 'अभियान संख्या',
    order_date_label: 'आदेश तिथि *',
    period_from_label: 'पीरियड फ्रॉम',
    period_to_label: 'पीरियड टू',
    campaign_type_label: 'अभियान प्रकार',
    deal_type_label: 'डील टाइप',
    client_label: 'ग्राहक',
    location_label: 'स्थान',
    product_label: 'उत्पाद',
    commission_label: 'आयोग',
    target_budget_label: 'लक्ष्य बजट',
    target_spots_label: 'लक्ष्य स्पॉट',
    target_seconds_label: 'लक्ष्य सेकंड',
    currency_label: 'मुद्रा',
    discount_label: 'छूट',
    tax_applicable_label: 'कर लागू',
    actual_booking_label: 'वास्तविक बुकिंग',
    commercial_label: 'वाणिज्यिक',
    fct_label: 'एफसीटी',
    billing_label: 'बिलिंग',
    channel_tab_label: 'चैनल',
    channel_name_column: 'चैनल का नाम',
    epg_code_column: 'EPG कोड',
    asrun_code_column: 'Asrun Code',
    content_column: 'सामग्री',
    add_channel_button_tooltip: 'चैनल जोड़ें',
    add_commercial_button_tooltip: 'वाणिज्यिक जोड़ें',
    add_fct_button_tooltip: 'एफसीटी जोड़ें',
    add_billing_button_tooltip: 'बिलिंग जोड़ें',
    channel_collection_header: 'चैनल',
    commercials_collection_header: 'विज्ञापन',
    fct_column: 'एफसीटी',
    from_date_column: 'तारीख से',
    to_date_column: 'आज तक',
    campaign_type_column: 'कैम्पागिन प्रकार',
    rate10sec_column: 'रेट 10 सेकंड',
    days_column: 'दिन',
    fct_dialog_header: 'एफसीटी',
    billing_dialog_header: 'बिलिंग',
    from_date_label: 'तारीख से',
    to_date_label: 'आज तक',
    rate10sec_label: 'Rate10Sec',
    day_label: 'दिन',
    select_all_label: 'सभी का चयन करें',
    sun_label: 'सूर्य',
    mon_label: 'मॉन',
    tue_label: 'तू',
    wed_label: 'वेड',
    thu_label: 'थू',
    fri_label: 'फ्री',
    sat_label: 'सत',
    amount_column: 'राशि',
    amount_label: 'राशि',
    booking_label: 'बुकिंग',
    campaign_label: 'अभियान',
    year_label: 'वर्ष',
    months_label: 'महीने',
    booking_collection_header: 'बुकिंग के लिए अभियान का चयन करें  ',
    from_label: 'से:',
    to_label: 'टू:',
    total_spot_label: 'कुल स्पॉट',
    total_seconds_label: 'कुल सेकंड',
    total_budget_label: 'कुल बजट:',
    position_label: 'स्थिति',
    download_template_tooltip: 'डाउनलोड टेम्पलेट',
    file_tooltip: 'फ़ाइल चुनें',
    line_no_column: 'लाइन नंबर',
    commercial_column: 'वाणिज्यिक',
    program_column: 'कार्यक्रम',
    house_number_column: 'घर नंबर',
    type_column: 'प्रकार',
    deal_type_column: 'डील टाइप',
    spot_rate_column: 'स्पॉट रेट',
    position_column: 'स्थिति',
    from_time_column: 'से',
    to_time_column: 'तो',
    product_code_column: 'उत्पाद कोड',
    total_spot_column: 'कुल स्थान',
    total_fct_column: 'टोटल एफसीटी',
    total_amount_column: 'कुल राशि',
    booked_spots_lable: 'बुक किए गए स्पॉट :',
    current_spots_label: 'वर्तमान स्पॉट: ',
    booked_seconds_label: 'बुक किए गए सेकंड :',
    current_seconds_label: 'वर्तमान सेकंड :',
    booked_amount_label: 'बुक की गई राशि :',
    current_amount_label: 'वर्तमान राशि :',
    add_booking_dialog_header: 'बुकिंग जोड़ें',
    type_label: 'प्रकार',
    from_time_label: 'समय से',
    spot_per_day_label: 'स्पॉट (प्रति दिन)',
    spot_per_week_label: 'स्पॉट (प्रति सप्ताह)',
    rate_label: 'दर',
    estimate_code_label: 'अनुमान कोड',
    remark_label: 'टिप्पणी',
    count_label: 'गिनती',
    transfer_from_label: 'ट्रांसफर फ्रॉम',
    transfer_to_label: 'ट्रांसफर टू',
    transfer_button_tooltip: 'स्थानांतरण',
    transfer_button_text: 'स्थानांतरण',
    campaign_column: 'अभियान',
    booking_date_column: 'बुकिंग की तारीख',
    time_range_from_column: 'टाइम रेंज फ्रॉम',
    time_range_to_column: 'टाइम रेंज टू',
    edit_actionbutton_menutext: 'संपादित करें',
    delete_actionbutton_menutext: 'हटाएं',
    cut_actionbutton_menutext: 'कट',
    update_booking_line_number_label: 'अपडेट बुकिंग लाइन नंबर',
    select_campaign_collection_text: 'अभियान का चयन करें',
    edit_campaign_collection_text: 'अभियान संपादित करें',
    select_campaign_collection_tooltip: 'चयन करें',
    already_scheduled_label: 'पहले से ही निर्धारित',
    not_scheduled_label: 'अनुसूचित नहीं',
    edit_booking_dialog_header: 'बुकिंग संपादित करें',
    spot_rate_label: 'स्पॉट रेट',
    time_range_from_label: 'टाइमरेंज फ्रॉम ',
    time_range_to_label: 'टाइमरेंज टू',
    auto_reconcile_button_tooltip: 'ऑटो सुलह',
    auto_reconcile_button_text: 'ऑटो सुलह',
    import_as_run_button_text: 'चलाने के रूप में आयात  करें',
    import_as_run_button_tooltip: 'चलाने के रूप में आयात  करें',
    reconciled_r_label: "R",
    reconciled_label: 'सुलह',
    non_reconciled_label: 'गैर सामंजस्यपूर्ण',
    non_reconciled_nr_label: 'एनआर',
    total_label: 'कुल:',
    total_commercial: 'कुल वाणिज्यिक:',
    sno_column: 'नहीं',
    slot_date_time_column: 'स्लॉट डेट टाइम',
    episode_number_column: 'एपिसोड नंबर',
    category_column: 'श्रेणी',
    reconciled_status_column: 'सुलह की स्थिति',
    is_reconciled_column: 'सुलह हो गई है',
    reconsilation_by_column: 'पुनर्मिलन द्वारा',
    status_column: 'स्थिति',
    ref_force_match_label: 'फोर्स मैच',
    force_match_label: 'फोर्स मैच',
    force_match_fm_label: 'एफएम',
    ref_force_unmatch_label: 'फोर्स अनमैच',
    ref_cancel_label: 'रद्द करें',
    purge_button_tooltip: 'पर्ज',
    unpurge_button_tooltip: 'अनपर्ज',
    campaign_no_label: 'अभियान संख्या',
    fic_dxb_0002486_label: 'FIC-DXB-0002486',
    total_spot_857_label: '857',
    total_amount_label: 'कुल राशि',
    total_seconds_19935_label: '19935',
    total_cast_2478_label: '2478.00',
    purged_label: 'शुद्ध',
    date_column: 'तारीख',
    is_purged_column: 'चोरी हो रही है',
    ref_purged_label: 'पर्ज',
    ref_unpurged_label: 'अनपर्ज',
    ref_calculate_spot_rate_label: 'स्पॉट रेट की गणना करें',
    total_fct_label: 'टोटल एफसीटी',
    total_spot_rate: 'कुल स्पॉट रेट',
    ref_calculate_fct_label: 'एफसीटी की गणना करें',
    ref_change_time_range_label: 'समय सीमा बदलें',
    ref_transfer_label: 'स्थानांतरण',
    ref_update_estimate_code_label: 'अद्यतन अनुमान कोड',
    ref_change_commercial_label: 'वाणिज्यिक बदलें',
    ref_change_spot_rate_label: 'स्पॉट रेट बदलें',
    change_time_range_dialog_header: 'समय सीमा बदलें',
    start_time_label: 'प्रारंभ समय',
    to_time_label: 'समय के लिए',
    transfer_dialog_header: 'स्थानांतरण',
    transfer_to_date_label: 'ट्रांसफर टू डेट',
    change_spot_rate_dialog_header: 'स्पॉट रेट बदलें',
    change_spot_rate_label: 'स्पॉट रेट बदलें',
    change_commercial_dialog_header: 'विज्ञापन बदलें',
    change_commercial_label: 'वाणिज्यिक बदलें',
    update_product_estimate_code_dialog_header: 'उत्पाद अनुमान कोड अपडेट करें',
    update_button_text: 'अपडेट',
    update_all_button_text: 'अपडेट ऑल',
    add_file_label: 'फ़ाइल जोड़ें',
    import_file_label: 'फ़ाइलें आयात करें',
    status_label: 'स्थिति',
    file_name_column: 'फ़ाइल का नाम',
    message_column: 'संदेश',
    file_upload_tooltip: 'फ़ाइल अपलोड',
    import_file_name_column: 'फ़ाइल का नाम आयात करें',
    comment_load_data_label: 'डेटा लोड करने के लिए लोड क्लिक करें',
    program_label: 'कार्यक्रम',
    select_program_booking_collection_header: 'बुकिंग के लिए प्रोग्राम का चयन करें  ',
    export_report_dialog_header: 'निर्यात ईपीजी',
    import_template_dialog_heade: 'आयात टेम्पलेट',
    filters_label: 'फिल्टर',
    operator_label: 'ऑपरेटर',
    operator_column: 'ऑपरेटर',
    export_button_tooltip: 'निर्यात प्लेलिस्ट',
    export_button_text: 'निर्यात',
    up_button_tooltip: 'ऊपर',
    down_button_tooltip: 'नीचे',
    export_template_editpageheader: 'निर्यात ईपीजी टेम्पलेट',
    save_template_as_label: 'टेम्पलेट को इस रूप में सहेजें',
    module_name_label: 'मॉड्यूल नाम',
    selected_Columns_label: 'चयनित स्तंभ',
    available_Column_label: 'उपलब्ध कॉलम',
    display_name_label: 'प्रदर्शन नाम',
    add_blank_button_tooltip: 'रिक्त जोड़ें',
    add_blank_button_text: 'रिक्त जोड़ें',
    add_column_button_tooltip: 'स्तंभ जोड़ें',
    add_column_button_text: 'स्तंभ जोड़ें',
    available_Column: 'उपलब्ध कॉलम',
    display_column: 'प्रदर्शन नाम',
    format_name_column: 'प्रारूप',
    id_label: 'आईडी',
    source_label: 'स्रोत',
    segment_position_label: 'सेगमेंट स्थिति',
    group_label: 'समूह',
    select_media_episode_collection_header: 'मीडिया एपिसोड का चयन करें',
    start_type_label: 'स्टार्ट टाइप',
    start_offset_label: 'ऑफसेट शुरू करें',
    time_hh_mm_ss_ff_label: 'hh:mm:ss:ff',
    negative_label: 'नकारात्मक',
    end_type_label: 'अंत प्रकार',
    end_offset_label: 'ऑफसेट खत्म करें',
    add_secondary_event_button_toottip: 'माध्यमिक घटनाएँ जोड़ें',
    text_number_column: 'टेक्स्ट नंबर',
    prefix_column: 'उपसर्ग',
    rule_column: 'नियम',
    text_number_label: 'टेक्स्ट नंबर',
    prefix_label: 'उपसर्ग',
    rule_label: 'नियम',
    style_sheet_editpageheader: 'स्टाइल शीट',
    add_prototype_button_tooltip: 'प्रोटोटाइप जोड़ें',
    id_column: 'आईडी',
    protoType_source_column: 'ProtoType स्रोत',
    group_column: 'समूह',
    select_prototype_collection_header: 'प्रोटोटाइप का चयन करें',
    default_prototype_editpageheader: 'डिफ़ॉल्ट प्रोटोटाइप',
    create_secondary_event_dynamic_rule_dialog_header: 'द्वितीयक इवेंट डायनेमिक नियम बनाएँ',
    formula_label: 'सूत्र',
    secondary_event_overview_dialog_header: 'सेकेंडरी इवेंट ओवरव्यू',
    start_type_column: 'स्टार्ट टाइप',
    start_offset_column: 'ऑफसेट शुरू करें',
    end_type_column: 'एंडटाइप',
    end_offset_column: 'ऑफसेट खत्म करो',
    confirm_button_text: 'पुष्टि करें',
    billing_period_label: 'बिलिंग अवधि',
    upto_label: 'ऊपर',
    generate_bill_button_tooltip: 'बिल उत्पन्न करें',
    generate_bill_button_text: 'बिल उत्पन्न करें',
    agency_column: 'एजेंसी',
    client_column: 'ग्राहक',
    comission_column: 'कमीशन %',
    from_column: 'से',
    to_column: 'नहीं',
    total_budget_spot_column: 'कुल बजट स्पॉट',
    total_booked_spot_column: 'टोटल बुक्ड स्पॉट्स',
    missing_spot_column: 'लापता स्थान',
    reconciled_spots_column: 'सुलह किए गए स्थान',
    generated_bill_Spots_column: 'जेनरेटेड बिल स्पॉट्स',
    contact_label: 'कॉन्टेक्ट',
    contact_info_label: 'संपर्क जानकारी',
    telephone_label: 'टेलीफोन',
    address1_label: 'पता 1',
    address2_label: 'पता 2',
    agency_info_label: 'एजेंसी की जानकारी',
    spots_label: 'स्पॉट्स',
    amounts_label: 'राशि',
    total_booked_label: 'कुल बुक किया गया',
    missing_label: 'लापता',
    generated_bill_label: 'जनरेटेड बिल',
    payment_recived_label: 'भुगतान पुनः प्राप्त',
    due_amount_label: 'देय राशि',
    credit_balance_label: 'क्रेडिट बैलेंस',
    selected_campaign_info_label: 'चयनित अभियान जानकारी',
    booked_label: 'बुक किया गया',
    detail_for_selected_date_range_and_campaign_label: 'चयनित  दिनांक सीमा और अभियान के लिए विवरण',
    invoice_denomination_dialog_header: 'चालान संप्रदाय',
    invoice_period_label: 'चालान अवधि',
    invoice_campaign_brabd_label: 'अभियान ब्रांड',
    select_period_label: 'अवधि का चयन करें',
    show_button_tooltip: 'दिखाओ',
    show_button_text: 'दिखाओ',
    details_label: 'विवरण',
    caption_column: 'कैप्शन',
    spots_column: 'स्पॉट्स',
    tax_column: 'टैक्स',
    tax_label: 'टैक्स',
    tax_not_applicable_label: 'कर लागू नहीं',
    discount_amount_label: 'छूट राशि',
    invoice_date_label: 'चालान दिनांक',
    bank_account_label: 'बैंक खाता',
    gross_total_label: 'ग्रॉस टोटल',
    tax_amount_label: 'कर राशि',
    commission_amount_label: 'कमीशन राशि',
    target_amount_label: 'लक्ष्य राशि',
    invoice_no_column: 'चालान संख्या',
    campaign_no_column: 'अभियान संख्या',
    invoice_date_column: 'चालान दिनांक',
    period_from_column: 'पीरियड फ्रॉम',
    period_to_column: 'पीरियड टू',
    validated_amount_column: 'मान्य राशि',
    discount_percentage_column: 'छूट प्रतिशत',
    discount_amount_column: 'छूट राशि',
    company_menu: 'कंपनी',
    logo_url_label: 'लोगो यू आर एल',
    suffix_label: 'प्रत्यय',
    next_number_label: 'अगला नंबर',
    full_channel_name_label: 'पूर्ण चैनल का नाम',
    short_channel_name_label: 'लघु चैनल का नाम',
    epg_code_label: 'EPG कोड',
    as_run_code_label: 'रन कोड के रूप में',
    automation_channel_name_label: 'स्वचालन चैनल का नाम',
    color_label: 'रंग',
    automation_label: 'स्वचालन',
    repeated_pattern_details_dialog_header: 'दोहराए गए पैटर्न विवरण',
    add_repeat_button_tooltip: 'दोहराएं जोड़ें',
    create_repeat_pattern_editpage_header: 'दोहराए जाने का पैटर्न बनाएं',
    create_variation_editpage_header: 'विविधता बनाएं',
    create_break_format_editpage_header: 'ब्रेक प्रारूप बनाएं',
    edit_variation_editpage_header: 'भिन्नता संपादित करें',
    edit_break_format_editpage_header: 'ब्रेक प्रारूप संपादित करें',
    add_primary_pattern_button_tolltip: 'प्राथमिक जोड़ें',
    primary_events_no_column: 'प्राथमिक घटनाएं नहीं',
    repeat_events_no_column: 'रिपीट इवेंट्स नंबर',
    nominal_start_column: 'नाममात्र की शुरुआत',
    primary_events_no_label: 'प्राथमिक घटनाएं नहीं',
    days_label: 'दिन',
    times_label: 'टाइम्स',
    create_invoice_content_dialog_header: 'चालान सामग्री बनाएँ',
    number_length_label: 'संख्या लंबाई',
    label: 'लेबल',
    sequence_label: 'अनुक्रम',
    entity_name_label: 'इकाई नाम',
    max_length_label: 'अधिकतम लंबाई',
    width_label: 'चौड़ाई',
    required_label: 'आवश्यक',
    edit_label: 'संपादित करें',
    slot_time_column: 'स्लॉट टाइम',
    please_generate_segment_to_load_media_label: 'कृपया मीडिया लोड करने के लिए सेगमेंट उत्पन्न करें! ',
    filter_button_tooltip: 'फ़िल्टर',
    search_content_dialog_header: 'सामग्री खोजें',
    clear_button_tooltip: 'स्पष्ट',
    clear_button_text: 'स्पष्ट',
    search_button_tooltip: 'खोजें',
    search_button_text: 'खोजें',
    select_preference_for_dialog_header: 'के लिए कॉलम वरीयता का चयन करें',
    available_label: 'उपलब्ध',
    preference_label: 'वरीयता',
    note_preference: 'नोट:- डिफ़ॉल्ट कॉलम के लिए, वरीयता खाली छोड़ दें',
    house_number_label: 'घर का नंबर',
    category_type_label: 'श्रेणी प्रकार',
    slot_time_label: 'स्लॉट टाइम',
    actual_duration_label: 'वास्तविक अवधि',
    nominal_duration_label: 'नाममात्र अवधि',
    segments_label: 'सेगमेंट',
    recursive_button_text: 'रिकर्सिव',
    recursive_dialog_header: 'रिकर्सिव',
    generate_button_toottip: 'जनरेट करें',
    generate_button_text: 'जनरेट करें',
    inclusion_range_label: 'समावेशन रेंज',
    episode_range_label: 'एपिसोड रेंज',
    example_label: 'उदाहरण',
    exclusion_range_label: 'बहिष्करण रेंज',
    exclude_days_label: 'दिनों को छोड़ दो',
    format_icon_tooltip: 'प्रारूप',
    no_segment_icon_tooltip: 'कोई खंड नहीं',
    contract_icon_tooltip: 'अनुबंध',
    slot_duration_title_label: 'स्लॉट अवधि',
    maximize_button_tooltip: 'अधिकतम',
    copy_Schedule_button_tooltip: 'कॉपी',
    planing_preference_button_tooltip: 'योजना वरीयता',
    export_planning_button_tooltip: 'निर्यात योजना',
    selected_label: 'चयनित',
    copied_label: 'कॉपी किया गया',
    select_channels_dialog_header: 'चैनलों का चयन करें',
    date_label: 'दिनांक',
    copy_Schedule_dialog_header: 'कॉपी शेड्यूल',
    copy_from_label: 'से कॉपी करें',
    from_channel_label: 'चैनल से',
    activity_label: 'गतिविधि',
    create_button_tootltip: 'बनाएं',
    create_button_text: 'बनाएं',
    planing_preference_dialog_header: 'योजना वरीयता ',
    events_label: 'घटनाएं',
    columns_label: 'कॉलम',
    general_label: 'जनरल',
    note_select_the_events_you_want_to_change: 'उन ईवेंट का चयन करें जिन्हें आप बदलना चाहते हैं',
    events_type_lable: 'इवेंट्स टाइप',
    font_size_label: 'फ़ॉन्ट आकार',
    foreground_Color_label: 'अग्रभूमि रंग',
    background_color_label: 'पृष्ठभूमि रंग',
    available_columns_label: 'उपलब्ध कॉलम',
    view_type_label: 'प्रकार देखें',
    show_format_label: 'शो फॉर्मेट',
    show_no_seg_label: 'शो नो सेग',
    show_contract_label: 'अनुबंध दिखाएं',
    day_Start_time_label: 'दिन की शुरुआत का समय',
    prime_end_time_label: 'प्राइम एंड टाइम',
    prime_Start_time_label: 'प्राइम स्टार्ट टाइम',
    episode_Column: 'एपिसोड',
    season_Column: 'सीजन',
    versions_column: 'संस्करण',
    search_filter_dialog_header: 'खोज फ़िल्टर',
    release_year_label: 'रिलीज ईयर',
    is_live_label: 'IsLive',
    save_as_template_label: 'टेम्पलेट के रूप में सहेजें',
    show_search_options_media_liberary_tooltip: 'खोज विकल्प दिखाएँ',
    place_holder_dialog_header: 'प्लेस होल्डर',
    please_choose_one_publishing_rights_collection_header: 'कृपया एक प्रकाशन अधिकार चुनें',
    export_excel_dialog_header: 'निर्यात Excel',
    export_excel_button_tooltip: 'निर्यात एक्सेल',
    export_csv_button_tooltip: 'निर्यात सीएसवी',
    export_excel_button_text: 'निर्यात एक्सेल',
    history_dialog_header: 'इतिहास',
    slot_date_column: 'स्लॉट डेट',
    exhibition_no_column: 'प्रदर्शनी संख्या',
    repeat_no_column: 'रिपीट नो',
    planning_loaded_label_note: 'प्लानिंग लोडेड',
    planning_loading_label_note: 'योजना लोडिंग ...',
    time_column: 'समय',
    prototypes_column: 'प्रोटोटाइप',
    info_column: 'जानकारी',
    cut_button_tooltip: 'कट',
    paste_button_tooltip: 'पेस्ट',
    download_pdf_button_tooltip: 'पीडीएफ डाउनलोड करें',
    open_planning_button_tooltip: 'खुली योजना',
    delete_all_footer_button_tooltip: 'सभी फुटर हटाएं',
    delete_All_secondary_events_button_tooltip: 'सभी माध्यमिक ईवेंट हटाएँ',
    global_replacement_button_tooltip: 'ग्लोबल रिप्लेसमेंट',
    apply_style_sheet_button_tooltip: 'स्टाइल शीट लागू करें',
    apply_button_tooltip: 'आवेदन करें',
    apply_button_text: 'आवेदन करें',
    schedule_preference_button_tooltip: 'अनुसूची वरीयता',
    auto_save_button_tooltip: 'ऑटो बचाओ',
    secondary_events_label: 'माध्यमिक घटनाएं',
    text_1_column: 'पाठ 1',
    text_2_column: 'पाठ 2',
    text_3_column: 'पाठ 3',
    prototypes_label: 'प्रोटोटाइप',
    insert_break_actionbutton_menutext: 'ब्रेक डालें',
    delete_header_actionbutton_menutext: 'हेडर हटाएं',
    program_replacement_actionbutton_menutext: 'प्रोग्राम रिप्लेसमेंट',
    attach_format_reload_actionbutton_menutext: 'Attach Format & Reload',
    history_actionbutton_menutext: 'इतिहास',
    select_program_to_replace_with_collection_title: 'बदलने के लिए प्रोग्राम का चयन करें',
    global_replacement_dialog_header: 'ग्लोबल रिप्लेसमेंट',
    replace_button_tooltip: 'बदलें',
    replace_button_text: 'बदलें',
    replacement_method_label: 'प्रतिस्थापन विधि',
    include_isBreak_label: 'इज़ब्रेक शामिल करें',
    replacement_material_label: 'प्रतिस्थापन सामग्री',
    delete_all_button_tooltip: 'सभी हटाएं',
    delete_all_button_text: 'सभी हटाएं',
    group_all_button_tooltip: 'ग्रुप मीडिया एपिसोड',
    group_all_button_text: 'ग्रुप मीडिया एपिसोड',
    select_media_episode_dialoag_header: 'मीडिया एपिसोड का चयन करें',
    select_media_episode_group_dialoag_header: 'मीडिया एपिसोड समूह का चयन करें',
    remove_filter_actionbutton_menutext: 'फ़िल्टर निकालें',
    filter_actionbutton_menutext: 'फ़िल्टर',
    copy_actionbutton_menutext: 'कॉपी',
    paste_actionbutton_menutext: 'पेस्ट',
    se_overview_actionbutton_menutext: 'एसई अवलोकन',
    global_replacement_actionbutton_menutext: 'ग्लोबल रिप्लेसमेंट',
    sales_team_label: 'बिक्री टीम',
    username_label: 'उपयोगकर्ता नाम',
    department_label: 'विभाग',
    password_label: 'पासवर्ड',
    phone_no_label: 'फोन नंबर',
    pobox_label: 'पीओ बॉक्स',
    reporting_to_lable: 'रिपोर्टिंग',
    role_label: 'भूमिका',
    issalesexecutive_label: 'IsSalesExecutive',
    sales_executive_editpageheader: 'बिक्री कार्यकारी',
    email_column: 'ईमेल',
    phone_no_column: 'फोन नहीं',
    select_sales_executive_colllection_header: 'बिक्री कार्यकारी का चयन करें',
    select_collection_button_text: 'चयन करें',
    export_planet_cast_button_tooltip: 'एक्सपोर्ट प्लैनेट कास्ट',
    check_list_button_tooltip: 'सूचियों की जाँच करें',
    check_lists_dialog_header: 'चेकलिस्ट',
    success_label: 'सफलता',
    failure_lable: 'विफलता',
    module_menu: 'मॉड्यूल',
    sales_team_dailog_header: 'बिक्री टीम बनाएं',
    module_dialog_header: 'मॉड्यूल',
    role_dialog_header: 'भूमिका',
    admin_label: 'व्यवस्थापक',
    sales_label: 'बिक्री',
    media_management_label: 'मीडिया प्रबंधन',
    rights_manager_label: 'अधिकार प्रबंधक',
    role_editpageheader: 'भूमिका',
    select_module_collection_header: 'मॉड्यूल का चयन करें',
    users_editpage_header: 'उपयोगकर्ता',
    select_Role_collection_header: 'भूमिका का चयन करें',
    module_column: 'मॉड्यूल',
    role_column: 'भूमिका',
    add_role_collection_text: 'भूमिका जोड़ें',
    add_role_button_text: '  भूमिका से जोड़ें  ',
    add_module_collection_text: 'मॉड्यूल जोड़ें',
    sales_executive_editpage_header: 'बिक्री कार्यकारी',
    timeline_page_breadcrumb: 'टाइम लाइन',
    assets_type_page_breadcrumb: 'संपत्ति प्रकार',
    rails_type_page_breadcrumb: 'रेल प्रकार',
    posters_type_page_breadcrumb: 'पोस्टर प्रकार',
    department_page_breadcrumb: 'विभाग',
    users_page_breadcrumb: 'उपयोगकर्ता',
    role_page_breadcrumb: 'भूमिका',
    assets_page_breadcrumb: 'संपत्ति',
    posters_page_breadcrumb: 'पोस्टर',
    client_page_breadcrumb: 'ग्राहक',
    agency_page_breadcrumb: 'एजेंसी',
    sales_team_page_breadcrumb: 'बिक्री टीम',
    sales_executive_page_breadcrumb: 'बिक्री कार्यकारी',
    content_page_breadcrumb: 'सामग्री',
    channel_page_breadcrumb: 'चैनल',
    currency_page_breadcrumb: 'मुद्रा',
    repeat_pattern_page_breadcrumb: 'रिपीट पैटर्न',
    invoice_content_page_breadcrumb: 'चालान सामग्री',
    segment_type_page_breadcrumb: 'सेगमेंट प्रकार',
    contract_page_breadcrumb: 'अनुबंध',
    series_page_breadcrumb: 'श्रृंखला',
    season_page_breadcrumb: 'सीजन',
    programs_page_breadcrumb: 'कार्यक्रम',
    episode_page_breadcrumb: 'एपिसोड',
    movies_page_breadcrumb: 'फिल्में',
    trailer_page_breadcrumb: 'ट्रेलर',
    interstitial_page_breadcrumb: 'अंतरालीय',
    commercial_page_breadcrumb: 'वाणिज्यिक',
    music_page_breadcrumb: 'संगीत',
    filler_page_breadcrumb: 'फिलर',
    break_page_breadcrumb: 'ब्रेक',
    group_page_breadcrumb: 'समूह',
    program_group_page_breadcrumb: 'प्रोग्राम ग्रुप',
    interstital_group_page_breadcrumb: 'इंटरस्टिटल ग्रुप',
    schedule_constraints_page_breadcrumb: 'अनुसूची बाधाएं',
    campaign_page_breadcrumb: 'अभियान',
    utilisied_label: 'उपयोग किया गया',
    over_booked_label: 'ओवर बुक्ड',
    counts_label: 'मायने रखता है',
    active_label: 'सक्रिय',
    top_10_agency_label: 'शीर्ष 10 एजेंसी',
    top_10_client_label: 'शीर्ष 10 ग्राहक',
    pending_vs_on_air_booking_label: 'लंबित बनाम ऑनएयरबुकिंग',
    revenue_by_location_label: 'स्थान द्वारा रेवेनु  ',
    revenue_by_months_label: 'रेवेनु बाय मंथ्स',
    booking_by_deal_type_label: 'डीलटाइप द्वारा  बुकिंग',
    time_range_label: 'टाइम रेंज',
    language_page_breadcrumb: 'भाषा',
    region_page_breadcrumb: 'क्षेत्र',
    brand_page_breadcrumb: 'ब्रांड',
    product_page_breadcrumb: 'उत्पाद',
    rails_page_breadcrumb: 'रेल',
    page_breadcrumb: 'पृष्ठ',
    content_caegory_page_breadcrumb: 'सामग्री श्रेणी',
    ingestion_page_breadcrumb: 'अंतर्ग्रहण',
    jobs_page_breadcrumb: 'नौकरियां',
    prototype_page_breadcrumb: 'प्रोटोटाइप',
    prototype_style_sheet_page_breadcrumb: 'प्रोटोटाइप स्टाइल शीट',
    default_prototype_style_sheet_page_breadcrumb: 'डिफ़ॉल्ट प्रोटोटाइप स्टाइल शीट',
    prototype_source_page_breadcrumb: 'प्रोटोटाइप स्रोत',
    export_template_page_breadcrumb: 'निर्यात टेम्पलेट',
    form_fields_page_breadcrumb: 'फॉर्म फ़ील्ड्स',
    secondary_event_dynamic_rule_page_breadcrumb: 'सेकेंडरी इवेंट डायनेमिक रूल',
    format_page_breadcrumb: 'प्रारूप',
    time_range_page_breadcrumb: 'टाइम रेंज',
    company_page_breadcrumb: 'कंपनी',
    bank_account_page_breadcrumb: 'बैंक खाता',
    tax_page_breadcrumb: 'टैक्स',
    licensor_page_breadcrumb: 'लाइसेंसकर्ता',
    delete_successfully_grid_data_success_toast_message: 'सफलतापूर्वक हटाएं',
    delete_all_successfully_grid_data_success_toast_message: 'सभी सफलतापूर्वक हटाएं',
    please_select_first_contract_collection_error_message: 'कृपया पहले चयन करें!!',
    please_enter_contarct_number_contract_error_message: 'कृपया अनुबंध संख्या दर्ज करें',
    please_select_platform_type_error_message: 'कृपया प्लेटफ़ॉर्म प्रकार का चयन करें',
    please_select_licensor_error_message: 'कृपया लाइसेंसकर्ता का चयन करें',
    please_select_payee_error_message: 'कृपया भुगतानकर्ता का चयन करें',
    please_select_platform_error_message: 'कृपया प्लेटफ़ॉर्म का चयन करें',
    please_select_channel_error_message: 'कृपया चैनल का चयन करें',
    please_select_region_error_message: 'कृपया क्षेत्र का चयन करें',
    please_select_content_error_message: 'कृपया सामग्री का चयन करें',
    please_select_play_time_error_message: 'कृपया प्ले टाइम चुनें',
    please_select_media_category_type_error_message: 'कृपया मीडिया श्रेणी प्रकार का चयन करें',
    please_select_genres_media_episode_error_message: 'कृपया शैलियों का चयन करें',
    please_select_department_error_message: 'कृपया विभाग का चयन करें',
    please_enter_title_error_message: 'कृपया शीर्षक दर्ज करें',
    please_enter_description_error_message: 'कृपया विवरण दर्ज करें',
    please_enter_name_error_message: 'कृपया नाम दर्ज करें',
    please_enter_phone_no_error_message: 'कृपया फोन नंबर दर्ज करें',
    please_enter_email_error_message: 'कृपया वैध ईमेल दर्ज करें',
    please_select_roletype_error_message: 'कृपया रोलटाइप का चयन करें',
    please_enter_user_name_users_error_message: 'कृपया उपयोगकर्ता नाम दर्ज करें',
    please_enter_password_users_error_message: 'कृपया पासवर्ड दर्ज करें',
    invalid_duration_error_message: 'कृपया मान्य अवधि दर्ज करें',
    please_attach_atleast_one_media_episode_group_error_message: 'कृपया कम से कम एक मीडिया संलग्न करें',
    please_add_atleast_one_module_rights_users_error_message: 'कृपया कम से कम एक मॉड्यूल अधिकार जोड़ें',
    please_select_reportingto_user_error_message: 'कृपया रिपोर्टिंग का चयन करें',
    please_select_sales_team_error_message: 'कृपया बिक्री टीम का चयन करें',
    exhibition_should_not_be_zero_error_message: 'प्रदर्शनी शून्य होनी चाहिए',
    repeat_should_not_be_zero_error_message: 'दोहराव शून्य से अधिक होना चाहिए',
    play_time_number_should_not_be_zero_error_message: 'प्ले टाइम नंबर शून्य से अधिक होना चाहिए',
    end_date_should_be_greater_than_start_date_error_message: 'समाप्ति तिथि प्रारंभ तिथि से अधिक होनी चाहिए',
    please_attach_atleast_one_media_contract_error_message: 'कृपया कम से कम एक मीडिया संलग्न करें',
    please_select_season_content_series_error_message: 'कृपया सीजन सामग्री का चयन करें',
    value_is_not_greater_then_zero_season_number_series_error_message: 'सीजन की संख्या शून्य से अधिक होनी चाहिए  ',
    please_select_epiosde_content_series_error_message: 'कृपया एपिसोड सामग्री का चयन करें',
    value_is_not_greater_then_zero_episode_number_series_error_message: 'एपिसोड संख्या शून्य से अधिक होनी चाहिए  ',
    please_attach_series_season_error_message: 'कृपया श्रृंखला संलग्न करें',
    please_add_atleast_one_season_series_error_message: 'कृपया कम से कम एक सीजन जोड़ें',
    entering_same_season_number_twice_series_error_message: 'एक ही सीजन नंबर में दो बार प्रवेश करना',
    enter_title_expender: 'शीर्षक दर्ज करें',
    please_select_poster_type_first_media_episode_poster_error_message: 'कृपया पोस्टर प्रकार का चयन करें',
    please_select_url_first_media_episode_poster_error_message: 'कृपया पहले यू आर एल का चयन करें',
    please_select_the_value_in_start_date_media_episode_publishing_error_message: 'कृपया प्रारंभ दिनांक में मान का चयन करें',
    please_select_the_value_in_end_date_media_episode_publishing_error_message: 'कृपया समाप्ति दिनांक में मान का चयन करें',
    please_select_start_date_smaller_than_end_date_media_episode_publishing_error_message: 'कृपया समाप्ति-तिथि से छोटी प्रारंभ-तिथि का चयन करें',
    you_delete_this_deal_right_from_contract_only_media_episode_publishing_error_message: 'आप इस सौदे को केवल अनुबंध से हटाते हैं !!',
    please_select_ottvideo_type_media_episode_video_error_message: 'कृपया वीडियो प्रकार का चयन करें',
    create_dialog_header: 'बनाएं',
    synopsis_can_not_be_null_media_episode_Synopsis_error_message: 'सारांश  शून्य नहीं हो सकता है',
    please_select_language_media_episode_Synopsis_error_message: 'कृपया भाषा का चयन करें',
    please_enter_name_cast_crew_media_episode_error_message: 'कृपया नाम दर्ज करें',
    please_select_casttype_cast_crew_media_episode_error_message: 'कृपया कास्ट टाइप दर्ज करें',
    please_select_segment_type_media_episode_version_error_message: 'कृपया सेगमेंट प्रकार का चयन करें',
    please_generate_segments_first_media_episode_version_error_message: 'कृपया सेगमेंट उत्पन्न करें',
    media_episode_entity_duration_segment_error_message: 'वास्तविक अवधि शून्य है। कृपया विभाजन लागू करने की अवधि में मान्य अवधि अद्यतन करें',
    please_select_brand_media_episode_error_message: 'कृपया ब्रांड का चयन करें',
    please_select_product_media_episode_error_message: 'कृपया उत्पाद का चयन करें',
    please_enter_value_media_episode_error_message: 'कृपया मान दर्ज करें',
    please_enter_valid_value_tcin_should_not_be_greater_than_tcout_media_episode_error_message: 'कृपया मान्य मान दर्ज करें, TCIn TcOut से बड़ा नहीं होना चाहिए',
    error_while_generating_house_number_media_episode_error_message: 'हाउस नंबर जनरेट करते समय त्रुटि',
    already_exists_global_error_message: 'पहले से मौजूद है',
    you_can_update_this_deal_right_from_contract_only_media_episode_publishing_error_message: 'आप इस सौदे को केवल अनुबंध से अपडेट कर सकते हैं !!',
    this_segment_is_already_been_scheduled_segmnet_media_episode_error_message: 'यह खंड पहले से ही निर्धारित किया गया है',
    please_enter_title_display_rails_error_message: 'कृपया शीर्षक प्रदर्शन दर्ज करें',
    atleast_attach_one_ottassets_rails_error_message: 'कम से कम एक ओटासेट संलग्न करें',
    please_enter_enter_icon_uel_error_message: 'कृपया आइकन यू आर एल दर्ज करें',
    create_label: 'बनाएं',
    edit_lable: 'संपादित करें',
    select_label: 'चयन करें',
    please_select_media_category_type_first_media_episode_group_error_message: 'कृपया पहले मीडिया श्रेणी प्रकार का चयन करें',
    please_select_role_users_error_message: 'कृपया भूमिका का चयन करें',
    user_name_error_message: 'उपयोगकर्ता नाम',
    no_data_found_scheduling_reconciliation_error_message: 'कोई डेटा नहीं मिला! ',
    schedule_and_as_run_data_load_successfully_scheduling_reconciliation_success_message: 'शेड्यूल और AsRun डेटा सफलतापूर्वक लोड किया गया',
    error_while_loading_as_run_data_scheduling_reconciliation_error_message: 'AsRun डेटा लोड करते समय त्रुटि:  ',
    auto_recocile_successfully_scheduling_reconciliation_success_message: 'ऑटो ने सफलतापूर्वक सामंजस्य स्थापित किया !!',
    select_first_scheduling_reconciliation_error_message: 'पहले चुनें! ',
    already_reconsiled_scheduling_reconciliation_error_message: 'पहले से ही पुनर्निर्धारित! ',
    select_item_have_different_assetid_scheduling_reconciliation_error_message: 'चयनित आइटम में अलग-अलग एसेट आईडी हैं! ',
    force_unmatch_successfully_scheduling_reconciliation_success_message: 'फोर्स अनमैच्ड सफलतापूर्वक !!',
    please_select_schedule_and_as_run_item_scheduling_reconciliation_error_message: 'कृपया शेड्यूल का चयन करें और आइटम चलाएं! ',
    select_item_is_not_reconsiled_scheduling_reconciliation_error_message: 'चयनित आइटम पुन: कॉन्फ़िगर नहीं किया गया है  ! ',
    select_schedule_and_asrun_item_not_reconciled_together_scheduling_reconciliation_error_message: 'चयनित शेड्यूल और एसरन आइटम एक साथ मेल नहीं खाते हैं',
    select_schedule_item_already_billed_error_message: 'Selected schedule item is already billed.',
    saved_successfully_scheduling_reconciliation_success_message: 'सफलतापूर्वक !!! बचाया गया',
    role_type_label: 'रोल टाइप',
    please_load_first_scheduling_reconciliation_error_message: 'कृपया पहले लोड करें !!',
    tx_mode_label: 'TX मोड',
    segment_count_label: 'सेगमेंट काउंट',
    create_segment_type_dialog_header: 'सेगमेंट प्रकार बनाएं',
    please_enter_type_name_segment_type_error_message: 'कृपया नाम टाइप करें',
    please_select_tx_mode_segment_type_error_message: 'कृपया TX मोड का चयन करें',
    please_enter_segment_count_segment_type_error_message: 'कृपया सेगमेंट गिनती दर्ज करें',
    interstitial_group_sub_menu: 'अंतरालीय समूह',
    program_group_sub_menu: 'प्रोग्राम ग्रुप',
    variation_page_breadcrumb: 'भिन्नता',
    breakformat_page_breadcrumb: 'ब्रेक फॉर्मेट',
    payment_provider_page_breadcrumb: 'भुगतान प्रदाता',
    ott_period_type_page_breadcrumb: 'ओट पीरियड टाइप',
    payment_type_page_breadcrumb: 'भुगतान प्रकार',
    ott_period_page_breadcrumb: 'अवधि',
    subscriber_page_breadcrumb: 'सब्सक्राइबर',
    media_type_label: 'मीडिया प्रकार',
    atleast_attach_one_item_variation_error_message: 'कम से कम एक आइटम संलग्न करें',
    please_select_media_type_error_message: 'कृपया मीडिया प्रकार का चयन करें',
    season_number_should_not_be_zero_error_message: 'सीजन नंबर शून्य से अधिक होना चाहिए',
    number_of_epiosde_should_not_be_zero_error_message: '  एपिओस्डे की संख्या  शून्य होनी चाहिए',
    starting_epiosde_should_not_be_zero_error_message: 'एपिओस्डे शुरू  करना शून्य से अधिक होना चाहिए',
    please_select_potraitimageurl_otteditform_error_message: 'कृपया Portrait छवि यू आर एल का चयन करें',
    please_select_landscapeimageurl_otteditform_error_message: 'कृपया लैंडस्केप छवि यू आर एल का चयन करें',
    include_pre_opening_label: 'प्री ओपनिंग शामिल करें',
    include_closing_label: 'समापन शामिल करें',
    break_number_column: 'ब्रेक नंबर',
    break_duration_column: 'ब्रेक अवधि',
    commercial_duration_column: 'वाणिज्यिक अवधि',
    promo_duration_Column: 'प्रोमो अवधि',
    total_break_duration_label: 'कुल ब्रेक अवधि',
    segment_count_should_be_zero_error_message: 'सेगमेंट काउंट 1 से अधिक होना चाहिए',
    invalid_slot_duration_error_message: 'अमान्य स्लॉट अवधि',
    invalid_total_break_duration_error_message: 'अमान्य कुल ब्रेक अवधि',
    break_not_distribute_breakformat_error_message: 'ब्रेक वितरित नहीं किए गए',
    slot_duration_should_Be_greater_than_total_break_duration_breakformat_error_message: 'स्लॉट अवधि कुल ब्रेक अवधि से अधिक होनी चाहिए',
    break_count_segment_count_does_not_match_breakformat_error_message: 'ब्रेक काउंट और सेगमेंट काउंट मेल नहीं खाता',
    please_enter_template_name_error_toast: 'कृपया टेम्पलेट नाम दर्ज करें',
    please_select_module_type_error_message: 'कृपया मॉड्यूल प्रकार का चयन करें',
    please_select_ingestion_template_type_error_message: 'कृपया अंतर्ग्रहण टेम्पलेट प्रकार का चयन करें',
    saved_successfully_message: 'सफलतापूर्वक सहेजा गया',
    please_enter_code_error_message: 'कृपया कोड दर्ज करें',
    please_select_agency_error_message: 'कृपया एजेंसी का चयन करें',
    please_select_default_tax_error_message: 'कृपया डिफ़ॉल्ट कर का चयन करें',
    please_select_atleast_one_Sales_executive: 'कृपया कम से कम एक बिक्री कार्यकारी का चयन करें',
    add_sales_executive_button_tooltip: 'बिक्री कार्यकारी जोड़ें',
    please_enter_prefix_error_message: 'कृपया उपसर्ग दर्ज करें',
    please_enter_suffix_error_message: 'कृपया प्रत्यय दर्ज करें',
    please_enter_next_number_error_message: 'कृपया अगला नंबर दर्ज करें',
    please_enter_number_length_error_message: 'कृपया संख्या लंबाई दर्ज करें',
    please_attach_atleast_one_media_error_message: 'कृपया कम से कम एक मीडिया संलग्न करें',
    please_enter_full_Channel_name_error_message: 'कृपया पूर्ण चैनल का नाम दर्ज करें',
    please_enter_short_Channel_name_error_message: 'कृपया लघु चैनल का नाम दर्ज करें',
    please_select_atleast_one_repeat_pattern_error_message: 'कृपया कम से कम एक दोहराव का चयन करें! ',
    primary_delete_succesfully_repeat_pattern_error_message: 'प्राथमिक रूप से सफलतापूर्वक हटाएं',
    repeat_delete_succesfully_repeat_pattern_error_message: 'सफलतापूर्वक हटाएं दोहराएं',
    please_select_atleast_one_days_repeat_pattern_error_message: 'कृपया कम से कम एक दिन का चयन करें! ',
    please_enter_automation_channel_name_error_message: 'कृपया स्वचालन चैनल का नाम दर्ज करें',
    please_enter_epg_code_error_message: 'कृपया EPG कोड दर्ज करें',
    please_select_automation_error_message: 'कृपया स्वचालन का चयन करें',
    please_enter_color_error_message: 'कृपया रंग दर्ज करें',
    please_enter_valid_start_time_message: 'कृपया मान्य प्रारंभ समय दर्ज करें',
    please_add_atleast_one_module_role_error_message: 'कृपया कम से कम एक मॉड्यूल जोड़ें! ',
    data_loaded_successfully_ingestion_template_message: 'डेटा सफलतापूर्वक लोड किया गया',
    please_enter_positive_value_in_creditlimit_error_message: 'कृपया क्रेडिट लिमिट में सकारात्मक मूल्य दर्ज करें',
    please_enter_gst_no_error_message: 'कृपया जीएसटी नंबर दर्ज करें',
    please_enter_campaign_no_error_message: 'कृपया अभियान संख्या दर्ज करें',
    please_select_client_error_message: 'कृपया क्लाइंट का चयन करें',
    period_from_should_be_less_then_periodto_campaign_error_message: 'पीरियड टू से पीरियड टू से कम होना चाहिए',
    commission_exceeded_from_limit_campaign_error_message: 'कमीशन सीमा से अधिक हो गया।',
    discount_exceeded_from_limit_campaign_error_message: 'छूट सीमा से अधिक हो गई।',
    please_enter_target_budget_error_message: 'कृपया लक्ष्य बजट दर्ज करें',
    please_enter_target_spots_error_message: 'कृपया लक्ष्य स्थान दर्ज करें',
    please_enter_target_seconds_error_message: 'कृपया लक्ष्य सेकंड दर्ज करें',
    please_attach_atleast_one_commercials_campaign_error_message: 'कृपया कम से कम एक विज्ञापन संलग्न करें',
    please_attach_atleast_one_channel_campaign_error_message: 'कृपया कम से कम एक चैनल संलग्न करें',
    please_select_campaign_type_error_message: 'कृपया अभियान प्रकार का चयन करें',
    please_enter_rate10sec_error_message: 'कृपया Rate10Sec का चयन करें',
    please_enter_fct_error_message: 'कृपया एफसीटी दर्ज करें',
    please_enter_from_date_error_message: 'कृपया  दिनांक से दर्ज  करें',
    please_enter_to_date_error_message: 'कृपया आज तक दर्ज करें',
    please_enter_amount_error_message: 'कृपया राशि दर्ज करें',
    data_loading_error_message: 'डेटा लोडिंग ...',
    no_record_found_error_message: 'कोई रिकॉर्ड नहीं मिला',
    total_sum_spot_rate_success_message: 'कुल राशि स्पॉट दर',
    total_fct_success_message: 'टोटल एफसीटी',
    booking_is_used_in_schedule_show_spot_error_message: 'बुकिंग अनुसूची में उपयोग की जाती है',
    changes_Will_Reflect_in_new_planned_items_only_segmenttype_error_message: 'परिवर्तन  केवल नई नियोजित वस्तुओं में प्रतिबिंबित होंगे',
    please_select_campaign_first_booking_error_message: 'कृपया पहले अभियान का चयन करें',
    error_reading_file_booking_error_message: 'फ़ाइल पढ़ने में त्रुटि',
    wrong_month_selected_booking_error_message: 'गलत महीने का चयन',
    wrong_year_selected_booking_error_message: 'गलत वर्ष का चयन',
    error_downloading_template_booking_error_message: 'त्रुटि डाउनलोड टेम्पलेट',
    please_select_a_value_to_update_booking_error_message: 'कृपया अद्यतन करने के लिए एक मान का चयन करें',
    exceding_target_spots_limit_booking_error_message: 'लक्ष्य स्पॉट सीमा को हटाना',
    exceding_target_budget_booking_error_message: 'लक्ष्य बजट का विस्तार',
    commercials_not_updated_in_line_no_booking_error_message: 'लाइन नंबर में विज्ञापन अपडेट नहीं किए गए  ',
    exceding_target_seconds_booking_error_message: 'लक्ष्य सेकंड को हटाना ',
    booking_saved_booking_error_message: 'बुकिंग सेव',
    no_data_found_booking_error_message: 'कोई डेटा नहीं मिला',
    from_time_should_be_less_than_To_time_booking_error_message: 'समय से समय से कम होना चाहिए  ',
    please_enter_spot_booking_error_message: 'कृपया स्थान दर्ज करें',
    please_enter_rate_booking_error_message: 'कृपया दर दर्ज करें',
    please_select_transfer_from_date_booking_transfer_error_message: 'कृपया दिनांक से स्थानांतरण का चयन करें  ',
    please_select_transfer_date_booking_transfer_error_message: 'कृपया स्थानांतरण तिथि का चयन करें',
    please_select_item_booking_transfer_error_message: 'कृपया पहले आइटम का चयन करें! ',
    booking_transfer_succesfully_to_booking_transfer_error_message: 'बुकिंग ट्रांसफर सफलतापूर्वक ',
    exceding_target_spots_limit_update_booking_line_number_error_message: 'लक्ष्य स्पॉट सीमा को हटाना',
    exceding_target_budget_update_booking_line_number_error_message: 'लक्ष्य बजट का विस्तार ',
    exceding_target_seconds_update_booking_line_number_error_message: 'लक्ष्य सेकंड निकालना',
    loading_data_update_booking_line_number_error_message: 'डेटा लोड करना !!',
    data_loaded_update_booking_line_number_success_message: 'डेटा सफलतापूर्वक लोड किया गया!!',
    loading_booking_update_booking_line_number_success_message: 'बुकिंग लोड करना ...',
    bill_is_already_generated_for_this_linenumber_update_booking_line_number_success_message: 'इस लाइन नंबर के लिए बिल पहले से ही जनरेट किया गया है  !!',
    please_select_file_error_message: 'कृपया फ़ाइल का चयन करें',
    downlaod_file_error_message: 'फ़ाइल डाउनलोड करें',
    no_import_type_defined_error_message: 'कोई आयात प्रकार परिभाषित नहीं',
    upload_Successfully_import_success_message: 'सफलतापूर्वक अपलोड करें',
    asrun_Save_Successfully_import_success_message: 'Asrun Save सफलतापूर्वक',
    asrun_save_failed_import_error_message: 'Asrun Save विफल',
    update_failed_import_error_message: 'अपलोड विफल',
    assest_not_found_import_message: 'नहीं मिला',
    planning_imported_successfully_import_message: 'योजना सफलतापूर्वक आयात की गई',
    import_planning_failed_import_message: 'आयात योजना विफल रही।',
    read_to_upload_import_message: 'अपलोड करने के लिए पढ़ें',
    please_select_from_date: 'कृपया दिनांक से चुनें',
    please_select_to_date_greater_than_from_date_error_message: 'कृपया दिनांक से अधिक दिनांक का चयन करें',
    please_select_the_value_in_template_name_first_export_report_error_message: 'कृपया पहले टेम्पलेट नाम में मान का चयन करें! ',
    please_select_the_value_in_format_first_export_report_error_message: 'कृपया पहले प्रारूप में मान का चयन करें',
    please_add_at_least_one_item_list_export_report_error_message: 'कृपया कम से कम एक आइटम सूची जोड़ें।',
    please_select_the_value_in_filters_first_export_report_error_message: 'कृपया पहले फ़िल्टर में मान का चयन करें।',
    Please_select_the_value_in_Operator_first_export_report_error_message: 'कृपया पहले ऑपरेटर में मान का चयन करें।',
    value_can_not_be_null_export_report_error_message: 'मूल्य शून्य नहीं हो सकता',
    report_is_exported_successfully_message: 'ईपीजी सफलतापूर्वक निर्यात किया जाता है',
    please_select_valid_dates_export_template_error_message: 'कृपया मान्य दिनांक का चयन करें',
    exporting_data_please_wait_export_template_error_message: 'डेटा निर्यात करना कृपया प्रतीक्षा करें ...',
    successfully_exported_export_template_message: 'सफलतापूर्वक निर्यात किया गया',
    no_data_found_to_export_template_message: 'निर्यात करने के लिए कोई डेटा नहीं मिला',
    please_select_the_value_in_available_column_export_template_error_message: 'कृपया उपलब्ध स्तंभ में मान का चयन करें',
    please_enter_display_name_error_message: 'कृपया प्रदर्शन नाम दर्ज करें',
    please_enter_savetempleteas_export_template_error_message: 'कृपया टेम्पलटे को बचाओ में प्रवेश करें',
    please_add_column_atleast_one_item_export_template_error_message: 'कृपया कम से कम एक कॉलम जोड़ें',
    is_saved_successfully_export_template_message: 'सफलतापूर्वक सहेजा गया है',
    please_select_module_error_message: 'कृपया मॉड्यूल का चयन करें',
    please_enter_id_error_message: 'कृपया आईडी दर्ज करें',
    please_select_source_error_message: 'कृपया स्रोत का चयन करें',
    please_select_atleast_one_secondary_events_error_message: 'कृपया कम से कम एक द्वितीयक घटना का चयन करें',
    invalid_startoffset_error_message: 'अमान्य स्टार्ट ऑफसेट',
    invalid_endoffset_error_message: 'अमान्य अंत ऑफसेट',
    please_enter_text_no_error_message: 'कृपया पाठ संख्या दर्ज करें',
    please_select_the_value_in_rule_first_error_message: 'कृपया पहले नियम में मान का चयन करें',
    please_attach_atleast_one_prototype_stylesheet_error_message: 'कृपया कम से कम एक प्रोटोटाइप संलग्न करें',
    please_enter_formula_error_message: 'कृपया सूत्र दर्ज करें',
    please_wait_loading_data_billing_error_message: 'कृपया प्रतीक्षा करें, डेटा लोड करें ...',
    data_loaded_successfully_billing_template_message: 'डेटा सफलतापूर्वक लोड किया गया',
    please_select_a_billing_detail_first_billing_template_message: 'कृपया पहले एक बिलिंग विवरण का चयन करें! ',
    no_reconciled_spots_found_for_selected_details_billing_template_message: 'चयनित विवरणों के लिए कोई सामंजस्यपूर्ण स्थान नहीं मिला! ',
    please_select_bank_billing_error_message: 'कृपया बैंक का चयन करें! ',
    please_select_tax_billing_error_message: 'कृपया कर का चयन करें! ',
    please_select_brand_billing_error_message: 'कृपया ब्रांड का चयन करें! ',
    no_invoice_details_are_available_to_bill_billing_error_message: 'बिल के लिए कोई चालान विवरण उपलब्ध नहीं है! ',
    campaign_brands_label: 'अभियान ब्रांड',
    all_label: 'सब',
    selected_brand: 'चयनित ब्रांड',
    brand_label: 'ब्रांड',
    please_select_file_first_datamigration_error_message: 'कृपया पहले !!! फ़ाइल का चयन करें',
    empty_media_grid_datamigration_error_message: 'खाली मीडिया ग्रिड !!',
    please_select_type_datamigration_error_message: 'कृपया प्रकार का चयन करें !!',
    imported_successfully_datamigration_success_message: 'सफलतापूर्वक आयात किया गया',
    please_select_language_error_message: "कृपया भाषा का चयन करें",
    please_select_logo_url_first_error_mesage: 'कृपया पहले लोगो यू आर एल चुनें  ।',
    please_select_segment_type_and_channel_format_error_message: 'कृपया सेगमेंट प्रकार और चैनल का चयन करें',
    new_create_formfield_dialog_header: 'नया बनाएं',
    please_select_segment_type_format_error_message: 'कृपया सेगमेंट प्रकार का चयन करें',
    not_allowed_for_segments_format_error_message: 'सेगमेंट के लिए अनुमति नहीं',
    cannot_delete_segments_format_error_message: 'सेगमेंट को हटा नहीं सकता',
    select_atleast_one_mediaDealRight_planner_error_message: 'कम से कम एक मीडिया डील राइट का चयन करें',
    first_copy_planner_items_you_want_to_paste_planner_error_message: 'पहले योजनाकार आइटम की प्रतिलिपि बनाएं जिन्हें आप पेस्ट करना चाहते हैं।',
    please_select_area_on_planner_where_to_paste_planner_error_message: 'कृपया योजनाकार पर उस क्षेत्र का चयन करें जहां पेस्ट करना है।',
    from_date_cant_be_greater_than_to_date_paste_planner_error_message: 'आज से आज से बड़ा नहीं हो सकता! ',
    call_planner_error_message: 'कॉल',
    data_copy_schedule_please_wait_copyschedule_error_message: 'कृपया प्रतीक्षा करें ...',
    copy_schedule_data_copyschedule_successfully_message: 'शेड्यूल डेटा कॉपी सफलतापूर्वक कॉपी करें....',
    no_data_found_to_copy_schedule_error_message: 'शेड्यूल कॉपी करने के लिए कोई डेटा नहीं मिला',
    applying_recursive_pattern_please_wait_planner_error_message: 'रिकर्सिव पैटर्न लागू करना, कृपया प्रतीक्षा करें ...',
    recursive_pattern_applied_successfully_planner_success_message: 'रिकर्सिव पैटर्न सफलतापूर्वक लागू किया गया',
    pasted_pattern_planner_error_message: 'चिपकाए गए',
    items_pattern_planner_error_message: 'आइटम',
    to_date_is_mandatory_recursive_error_message: 'आज तक अनिवार्य है।',
    to_date_should_be_greater_than_base_program_start_date_recursive_error_message: 'आज तक आधार कार्यक्रम प्रारंभ तिथि से अधिक होना चाहिए',
    select_one_item_to_open_schedule_planner_helper_error_message: 'शेड्यूल खोलने के लिए एक आइटम का चयन करें',
    copy_button_text: 'कॉपी',
    open_schedule_button_text: 'ओपन शेड्यूल',
    paste_button_text: 'पेस्ट',
    delete_button_text: 'हटाएं',
    error_fetching_channel_list_planning_wrapper_error_message: 'त्रुटि लाने वाले चैनल सूची',
    please_select_planning_item_planning_wrapper_error_message: 'कृपया योजना आइटम का चयन करें',
    recursive_applied_successfully_planning_wrapper_message: 'रिकर्सिव सफलतापूर्वक लागू !!',
    select_program_group_label: 'प्रोग्राम समूह का चयन करें',
    please_enter_template_name_to_save_template_planningfilter_error_message: 'कृपया टेम्पलेट सहेजने के लिए टेम्पलेट नाम दर्ज करें',
    selected_content_text_planning_footer: 'चयनित सामग्री',
    copied_content_text_planning_footer: 'कॉपी की गई सामग्री',
    first_copy_planner_items_you_want_to_paste_planning_performance_error_message: 'पहले योजनाकार आइटम की प्रतिलिपि बनाएं जिन्हें आप पेस्ट करना चाहते हैं।',
    please_select_area_on_planner_where_to_paste_planning_performance_error_message: 'कृपया योजनाकार पर उस क्षेत्र का चयन करें जहां पेस्ट करना है।',
    select_atleast_one_mediadealright_planning_performance_error_message: 'कम से कम एक मीडियाडील अधिकार का चयन करें',
    first_select_replace_items_global_replacement_error_message: 'पहले आइटम बदलें चुनें',
    please_select_content_to_delete_planning_performance_error_message: 'कृपया हटाने के लिए सामग्री का चयन करें',
    not_allowed_Control_with_schedule_planning_performance_error_message: 'अनुमति नहीं है, अनुसूची के साथ नियंत्रण',
    entry_already_exist_try_with_different_values_collection_error_message: 'प्रविष्टि पहले से मौजूद है, विभिन्न मूल्यों के साथ प्रयास करें।',
    please_select_first_collection_error_message: 'कृपया पहले चयन करें! ',
    already_attached_collection_error_message: 'पहले से ही संलग्न !!',
    export_current_booking_tooltip: 'निर्यात वर्तमान बुकिंग',
    export_saved_booking_tooltip: 'निर्यात सहेजी गई बुकिंग',
    please_select_poster_type_error_message: 'कृपया पोस्टर प्रकार का चयन करें',
    browser_button_text: 'ब्राउज़ करें',
    file_selected_label: 'फ़ाइल चयनित.',
    only_jpg_jpeg_and_png_files_are_allowed_msg: 'केवल jpg/jpeg और png फ़ाइलों की अनुमति है!',
    please_add_programs_format_error_message: 'कृपया प्रोग्राम प्रारूप जोड़ें',
    please_select_atleast_one_item: 'कृपया कम से कम एक आइटम का चयन करें।',
    please_enter_season_number_error_message: 'कृपया सीजन नंबर दर्ज करें',
    repeat_pattern_label: 'रिपीट पैटर्न',
    select_repeat_pattern_collection_header: 'रिपीट पैटर्न का चयन करें',
    please_select_from_date_error_message: 'कृपया दिनांक से चयन करें  ',
    please_select_to_date_error_message: 'कृपया दिनांक का चयन करें  ',
    please_select_to_date_grater_then_from_date_error_message: 'कृपया दिनांक से अधिक दिनांक का चयन करें',
    parent_content_category_label: 'अभिभावक सामग्री श्रेणी',
    rail_will_be_added_soon2_label: 'रेल जल्द ही जोड़ा जाएगा2!',
    rail_will_be_added_soon3_label: 'रेल जल्द ही जोड़ा जाएगा3!',
    no_select_image_error_message: 'कोई छवि का चयन नहीं',
    please_enter_key_error_message: 'कृपया कुंजी दर्ज करें',
    please_enter_value_error_message: 'कृपया मान दर्ज करें',
    please_wait_generating_bill: 'बिल उत्पन्न करना। कृपया प्रतीक्षा करें...',
    bill_generated_successfully: 'बिल सफलतापूर्वक उत्पन्न हुआ',
    please_select_template_error_message: 'कृपया टेम्पलेट का चयन करें',
    please_select_file_first_error_message: 'कृपया पहले फ़ाइल का चयन करें! ',
    please_enter_first_error_message: 'कृपया पहले प्रवेश करें! ',
    please_select_channel_first: 'कृपया पहले चैनल का चयन करें',
    please_select_start_date_error_message: 'कृपया प्रारंभ दिनांक का चयन करें',
    please_select_end_date_error_message: 'कृपया समाप्ति तिथि का चयन करें',
    please_enter_valid_sequnce_number_error_message: 'कृपया मान्य सेकुंस नंबर दर्ज करें  ',
    program_schedule_clash_breadcrumb: 'प्रोग्राम शेड्यूल क्लैश',
    clash_channel_column: 'क्लैश चैनल',
    clashed_time_Column: 'क्लैश्ड टाइम',
    source_time_Column: 'स्रोत समय',
    no_data_found_error_message: 'कोई डेटा नहीं मिला! ',
    movie_name_column: 'मूवी का नाम',
    create_ott_period_type_dialog_header: 'ओटीटी अवधि बनाएं',
    create_subscriber_dialog_header: 'सब्सक्राइबर बनाएं',
    play_type_label: 'प्ले टाइप',
    ott_period_type_label: 'ओट पीरियड टाइप',
    please_enter_play_time_error_message: 'खेलने का समय शून्य से अधिक होना चाहिए  ',
    please_select_play_type_error_message: 'कृपया प्ले प्रकार का चयन करें',
    please_select_ott_period_type_error_message: 'कृपया ओट पीरियड प्रकार का चयन करें',
    ott_period_label: 'ओट पीरियड',
    payment_type_label: 'भुगतान प्रकार',
    trials_tab_label: 'परीक्षण',
    fixed_trials_tab_label: 'फिक्स्ड ट्रायल',
    reminders_tab_label: 'अनुस्मारक',
    payment_Provider_label: 'भुगतान प्रदाता',
    send_label: 'अनुस्मारक',
    dayPrior_label: 'पूर्व अनुस्मारक दिवस',
    enable: 'सक्षम करें',
    trials_dialog_header: 'परीक्षण',
    reminders_dialog_header: 'अनुस्मारक',
    add_trial_button_tooltip: 'परीक्षण जोड़ें',
    add_reminders_button_tooltip: 'अनुस्मारक जोड़ें',
    paymentPlan_sub_menu: 'भुगतान योजना',
    please_select_Payment_Provider_error_message: 'कृपया भुगतान प्रदाता का चयन करें',
    please_select_PlayType_error_message: 'कृपया प्ले प्रकार का चयन करें',
    please_select_OttPeriod_error_message: 'कृपया ओट अवधि का चयन करें',
    please_select_Currency_error_message: 'कृपया मुद्रा का चयन करें',
    please_select_PaymentType_error_message: 'कृपया भुगतान प्रकार का चयन करें',
    ott_product_sub_menu: 'उत्पाद',
    ott_product_label: 'उत्पाद',
    sales_status_label: 'बिक्री की स्थिति',
    geo_Region_label: 'भू-क्षेत्र',
    create_ott_product_type_dialog_header: 'ओटीटी उत्पाद बनाएं',
    please_select_geo_region_error_message: 'कृपया भू-क्षेत्र का चयन करें',
    please_select_sales_status_error_message: 'कृपया बिक्री स्थिति का चयन करें',
    please_select_Payment_Plan_error_message: 'कृपया भुगतान योजना का चयन करें',
    show_app_bar_label: 'ऐप बार दिखाएं',
    isHomePage_label: 'इज़होमपेज',
    route_type_label: 'मार्ग प्रकार',
    page_label: 'पृष्ठ',
    rails_l_label: 'एल',
    rails_uat_label: 'यू',
    live_label: 'जियो',
    uat_label: 'यूएटी',
    media_is_already_attached_with_another_asset_error_message: 'मीडिया पहले से ही एक और संपत्ति के साथ जुड़ा हुआ है',
    add_payment_plan_tooltip: 'भुगतान योजना जोड़ें',
    trial_price_label: 'ट्रायल प्राइस',
    trial_days_label: 'परीक्षण के दिन',
    sort_index_label: 'सॉर्ट इंडेक्स',
    hide: 'छिपाओ',
    select_payment_plan_collection: 'भुगतान योजना का चयन करें',
    payments_dialog_header: 'भुगतान',
    daysBefore_label: 'दिन पहले',
    subscription_id_label: 'सदस्यताआईडी',
    package_label: 'पैकेज',
    last_updated_label: 'अंतिम अद्यतन',
    please_enter_subscription_id_error_message: 'कृपया सदस्यता आईडी दर्ज करें',
    please_enter_package_error_message: 'कृपया पैकेज दर्ज करें',
    please_select_status_error_message: 'कृपया स्थिति का चयन करें',
    please_enter_last_update_error_message: 'कृपया अंतिम अद्यतन दर्ज करें',
    please_select_route_type: "कृपया मार्ग प्रकार का चयन करें",
    please_select_video_playback_url: 'कृपया वीडियो प्लेबैक यू आर एल का चयन करें',
    please_select_page: 'कृपया पृष्ठ का चयन करें',
    ott_campaign_page_breadcrumb: 'अभियान',
    edit_campaign_editpage_header: 'ओटीटी अभियान संपादित करें',
    add_rails_button_tootip: 'रेल जोड़ें',
    select_rails_collection: 'रेल का चयन करें',
    publish_column: 'Publsih',
    from_date_should_be_less_than_to_date_error_message: 'तारीख से तारीख से कम होना चाहिए',
    please_select_rails_error_message: 'कृपया कम से कम एक रेल का चयन करें',
    ott_rails_type_column: 'ओट रेल प्रकार',
    add_above_option: 'ऊपर जोड़ें',
    add_below_option: 'नीचे जोड़ें',
    select_any_one_collection_selection_error_message: 'किसी एक का चयन करें',
    please_attach_media_first: 'कृपया मीडिया संलग्न करें',
    edit_actionbutton_option: 'संपादित करें',
    profile_label: 'प्रोफ़ाइल',
    asset_label: 'एसेट आईडी',
    job_id_label: 'जॉब आईडी',
    job_started_label: 'शुरू हुआ',
    job_finished_label: 'समाप्त',
    elapsed_label: 'बीता हुआ',
    job_input_file_label: 'इनपुट फ़ाइल',
    media_type_column: 'मीडिया प्रकार',
    file_location_column: 'फ़ाइल स्थान',
    attach_media_button_tooltip: 'अटैच मीडिया',
    attach_media_button_text: 'अटैच मीडिया',
    select_media_collection_header: "मीडिया का चयन करें",
    assets_label_ott_collection: 'संपत्ति',
    select_assets_collection_header: "संपत्ति का चयन करें",
    some_bookings_are_already_Scheduled_Could_not_transfer_error_message: 'कुछ बुकिंग पहले से ही शेड्यूल हैं, ट्रांसफर नहीं हो सकी ',
    jobs_title: 'नौकरियां',
    total_assets_label: 'कुल संपत्ति',
    makegoodspot_sub_menu: 'अच्छा स्थान बनाओ',
    makegoodspot_title: 'अच्छा स्थान बनाएं',
    makegoodspottransfer_dialog_header: 'अच्छा स्पॉट ट्रांसफर बनाएं',
    select_item_date_between_from_and_to_error_message: 'चयनित स्थानांतरण तिथि अभियान अवधि से आज तक के बीच होनी चाहिए',
    please_transfer_error_date_greater_than_bookingdate_message: 'कृपया वर्तमान बुकिंग तिथि से अधिक स्थानांतरण तिथि का चयन करें!',
    latest_jobs_label: 'नवीनतम नौकरियां',
    invalid_date_range_copy_planning_error_message: 'अमान्य दिनांक सीमा',
    date_range_between_seven_day_copyplanning_error_message: 'दिनांक सीमा 7 दिनों के भीतर होनी चाहिए',
    height_label: "ऊंचाई",
    create_ott_asset_label: 'ओटीटी एसेट बनाएं',
    media_label: 'मीडिया',
    recent_jobs_label: 'हाल की नौकरियां',
    please_select_type_error_message: 'कृपया प्रकार का चयन करें',
    create_schedule_constraints_editpage_header: 'अनुसूची बाधाएं बनाएं',
    schedule_constraints_error_message: 'अनुसूची बाधाएं',
    constraints_lable: 'बाधाएं',
    fill_under_run_button_tooltip: 'इंटरस्टीशियल्स के साथ रन के तहत भरें',
    please_select_amount_error_message: 'कृपया राशि का चयन करें',
    please_enter_sort_index_error_message: 'कृपया अनुक्रमणिका सॉर्ट करें',
    please_enter_trial_price_error_message: 'कृपया परीक्षण मूल्य दर्ज करें',
    please_enter_trial_days_error_message: 'कृपया परीक्षण के दिनों में प्रवेश करें',
    please_select_reminder_error_message: 'कृपया अनुस्मारक का चयन करें',
    please_enter_days_before_error_message: 'कृपया दिन पहले दर्ज करें',
    enable_reminder_label: 'अनुस्मारक सक्षम करें',
    coupon_page_breadcrumb: 'कूपन',
    discount_type_label: 'छूट प्रकार',
    discount_value_label: 'डिस्काउंट वैल्यू',
    create_coupon_dialog_header: 'कूपन बनाएं',
    please_select_discount_type_error_message: 'कृपया छूट प्रकार का चयन करें',
    percentage_can_not_be_greater_than_error_message: 'प्रतिशत  100 से अधिक नहीं हो सकता',
    enable_fixed_trial_label: 'फिक्स्ड ट्रायल सक्षम करें',
    limited_period_label: 'सीमित अवधि',
    valid_from_label: 'से मान्य',
    valid_to_label: 'मान्य',
    please_select_valid_from_date_error_message: 'कृपया वैध से चुनें',
    please_select_valid_to_date_error_message: 'कृपया मान्य का चयन करें',
    discount_should_be_greater_then_zero_error_message: 'छूट शून्य से अधिक होनी चाहिए  ',
    please_select_valid_date_error_message: 'कृपया मान्य दिनांक का चयन करें',
    payment_plan_label: 'भुगतान योजना',
    coupon_label: 'कूपन',
    add_coupon_tooltip: 'कूपन जोड़ें',
    select_coupon_collection: 'कूपन का चयन करें',
    code_column: 'कोड',
    discount_type_column: 'छूट प्रकार',
    discount_value_column: 'डिस्काउंट वैल्यू',
    audit_log_menu: 'ऑडिट लॉग',
    audit_log_breadcrumb: 'ऑडिट लॉग',
    genre_sub_menu_: 'शैली',
    media_category_sub_menu: 'मीडिया श्रेणी',
    genre_page_breadcrumb: 'शैली',
    media_category_page_breadcrumb: 'मीडिया श्रेणी',
    module_label: 'मॉड्यूल',
    event_label: 'घटना',
    please_select_event_error_message: 'कृपया इवेंट का चयन करें',
    download_button_tooltip: 'डाउनलोड करें',
    date_time_label: 'डेटटाइम',
    confirm_password_label: 'पासवर्ड की पुष्टि करें',
    password_do_not_match_error_message: 'पासवर्ड मेल नहीं खाता',
    please_enter_confirm_password_users_error_message: 'कृपया पासवर्ड की पुष्टि करें',
    atleast_attach_atleast_one_platform_error_message: 'कृपया कम से कम एक मंच का चयन करें',
    upload_file_button_tooltip: 'फ़ाइल अपलोड करें',
    role_tab_label: 'भूमिका',
    add_channel_button_text: 'चैनल जोड़ें',
    select_channel_collection_header: 'चैनल का चयन करें',
    please_add_atleast_one_channel_users_error_message: 'कृपया कम से कम एक चैनल जोड़ें',
    please_select_order_date_error_message: 'कृपया ऑर्डर दिनांक का चयन करें',
    please_select_period_from_date_error_message: 'कृपया अवधि का चयन करें',
    please_select_period_to_date_error_message: 'कृपया अवधि का चयन करें',
    please_select_deal_type_error_message: 'कृपया डील प्रकार का चयन करें',
    please_select_location_error_message: 'कृपया स्थान का चयन करें',
    please_select_product_error_message: 'कृपया उत्पाद का चयन करें',
    ott_segment_sub_menu: 'सेगमेंट',
    payment_plan_page_breadcrumb: 'भुगतान योजना',
    ott_segment_page_breadcrumb: 'सेगमेंट',
    create_segment_dialog_header: 'सेगमेंट बनाएं',
    segment_label: 'सेगमेंट',
    please_select_segment_error_message: 'कृपया सेगमेंट का चयन करें',
    monetization_dashboard: 'मुद्रीकरण',
    segment_by_subscribe_label: 'सब्सक्राइबर द्वारा सेगमेंट',
    segment_by_product_label: 'उत्पाद द्वारा खंड',
    products_column: 'उत्पाद',
    total_coupon_column: 'कुल कूपन',
    total_subscriber_column: 'कुल सब्सक्राइबर',
    coupon_name_column: 'कूपन नाम',
    pgrating_sub_menu: 'पीजी रेटिंग',
    pgrating_page_breadcrumb: 'पीजी रेटिंग',
    open_in_a_new_tab_label: 'एक नए टैब में खोलें',
    column_preference_label: 'कॉलम वरीयता',
    parent_label: 'माता-पिता',
    overrun_underrun_label: 'ओवररन एंड अंडररन',
    copy_count_label: 'कॉपी काउंट',
    selected_header_label: 'चयनित हेडर',
    selected_count_label: 'चयनित गिनती',
    platform_column: 'प्लेटफॉर्म',
    please_enter_old_password_users_error_message: 'कृपया पुराना पासवर्ड दर्ज करें',
    old_password_does_not_match_error_message: 'पुराना पासवर्ड मेल नहीं खाता',
    please_enter_new_password_error_message: 'कृपया नया पासवर्ड दर्ज करें',
    confirm_password_do_not_match_error_message: 'पुष्टि करें पासवर्ड मेल नहीं खाता',
    old_password_label: 'पुराना पासवर्ड',
    new_password_label: 'नया पासवर्ड',
    account_label: 'खाता',
    change_password_label: 'पासवर्ड बदलें',
    log_out_label: 'लॉग आउट करें',
    media_by_category_menu: 'श्रेणी के अनुसार मीडिया',
    movie_label: 'मूवी',
    season_label: 'सीजन',
    view_details_label: 'विवरण देखें',
    executive_label: 'कार्यकारी',
    subscriber_label: 'ग्राहक',
    confirm_logout_label: 'लॉग आउट की पुष्टि करें',
    are_you_sure_you_want_to_logout: 'क्या आप लॉग आउट करना चाहते हैं ?',
    yes_label: 'हाँ',
    no_label: 'नहीं',
    confirm_delete_label: 'हटाने की पुष्टि करें',
    are_you_sure_you_want_to_delete: 'क्या आप आश्वस्त है कि आपको डिलीट करना है ?',
    scheduling_label: 'शेड्यूलिंग',
    planning_label: 'योजना',
    options_label: 'विकल्प',
    un_archive_label: 'अन-आर्काइव',
    create_from_label: 'से बनाएं',
    category_label: 'श्रेणी',
    select_category_label: 'श्रेणी का चयन करें',
    grid_view_label: 'ग्रिड दृश्य',
    tile_view_label: 'टाइल दृश्य',
    linear_label: 'रेखीय',
    non_linear_label: 'गैर रेखीय',
    audit_log_label: 'ऑडिट लॉग',
    entry_date_and_time_column: 'एंट्रीडेटटाइम',
    event_column: 'घटना',
    user_column: 'उपयोगकर्ता',
    close_button_tooltip: 'बंद करो',
    apply_recursive_button_text_planning: 'पुनरावर्ती लागू करें',
    history_button_text_planning: 'इतिहास',
    open_schedule_button_text_planning: 'ओपन शेड्यूल',
    insert_program_group_label_planning: 'प्रोग्राम समूह सम्मिलित करें',
    export_planner_button_text: 'निर्यात योजनाकार',
    export_epg_button_text: 'निर्यात ईपीजी',
    planning_loaded_label: 'योजना लोडेड',
    planning_loading_error_label: 'योजना लोडिंग त्रुटि',
    error_fetching_planning_data_error_message: 'योजना डेटा लाने में त्रुटि',
    planning_loading_label: 'प्लानिंग लोडिंग',
    creating_label: 'बनाना',
    successfully_created_group_label: 'सफलतापूर्वक बनाया गया समूह!',
    pasted_label: 'चिपकाए गए',
    items_label: 'आइटम',
    last_updated_from_label_msg: 'अंतिम बार अपडेट किया गया',
    cannot_delete_segment_label_msg: 'सेगमेंट को हटा नहीं सकता',
    select_item_to_error_message: 'आइटम का चयन करें',
    cannot_cut_footer_msg_scheduling: 'पैर नहीं काट सकते।',
    cannot_delete_multiple_footer_msg_scheduling: 'एकाधिक पाद लेख नहीं हटा सकता।',
    cannot_delete_multiple_footer_please_select_one_footer_at_a_time_msg_scheduling: 'एकाधिक पाद लेख नहीं हटा सकता, कृपया एक समय में एक पाद लेख का चयन करें।',
    no_item_selected_to_copy_msg_scheduling: 'कॉपी करने के लिए कोई आइटम चयनित नहीं',
    cannot_copy_segment_msg_scheduling: 'कॉपी नहीं कर सकता - SEGMENT',
    cannot_copy_break_msg_scheduling: 'कॉपी नहीं कर सकता - ब्रेक',
    deleting_footer_msg_scheduling: 'फुटर को हटाना',
    footer_deleted_msg_scheduling: 'पाद लेख हटा दिया गया',
    no_footer_to_deleted_error_message: 'हटाने के लिए कोई पाद लेख नहीं',
    all_footer_deleted_error_message: 'सभी पाद लेख हटा दिए गए',
    there_are_some_at_server_side_please_contact_admin: 'सर्वर साइड में कुछ हैं, कृपया व्यवस्थापक से संपर्क करें',
    loading_schedule_please_wait_msg_scheduling: 'लोडिंग शेड्यूल, कृपया प्रतीक्षा करें ...',
    loading_schedule_completed_msg_scheduling: 'लोडिंग शेड्यूल पूरा हुआ !!',
    please_selct_one_item_msg_scheduling: 'कृपया एक आइटम लिखें',
    no_media_selected_msg_scheduling: 'कोई मीडिया चयनित नहीं',
    deleted_secondary_events_msg_scheduling: 'हटाए गए द्वितीयक ईवेंट',
    all_secondary_events_deleted_successfully_msg_scheduling: 'सभी माध्यमिक ईवेंट सफलतापूर्वक हटाए गए !!',
    style_sheet_applied_successfully_msg_scheduling: 'स्टाइल शीट सफलतापूर्वक लागू !!',
    secondary_event_updated_msg_scheduling: 'द्वितीयक ईवेंट अद्यतन',
    no_secondary_events_error_message: 'कोई माध्यमिक घटना नहीं',
    select_target_item_from_schedule_error_message: 'शेड्यूल से लक्ष्य आइटम का चयन करें',
    first_cut_or_copy_item_error_message: 'आइटम को पहले काटें या कॉपी करें।',
    please_select_Item_to_paste_error_message: 'कृपया चिपकाने के लिए आइटम का चयन करें।',
    Please_select_one_Item_where_need_to_paste_error_message: 'कृपया एक आइटम का चयन करें, जहां पेस्ट करने की आवश्यकता है।',
    delete_msg: 'हटाएं',
    cut_msg: 'हटाएं',
    cannot_segment_please_unselect_segment_error_message: 'सेगमेंट नहीं कर सकता, कृपया सेगमेंट का चयन न करें',
    Confirmation_label: 'पुष्टिकरण',
    are_you_sure_you_want_to_delete_footer_label: 'क्या आप वाकई पाद लेख हटाना चाहते हैं?',
    Are_you_sure_you_want_to_items_label_confirm_alert: 'क्या आप वाकई आइटम चाहते हैं',
    cannot_select_footer_error_message: 'पाद लेख का चयन नहीं कर सकता।',
    cannot_multi_select_items_from_different_headers_error_message: 'विभिन्न शीर्ष लेखों से आइटम ों का बहु-चयन नहीं कर सकता.',
    please_select_first_error_message: 'कृपया पहले चयन करें।',
    cannot_select_items_from_different_headers_error_message: 'विभिन्न शीर्ष लेखों से आइटम का चयन नहीं कर सकता.',
    please_choose_right_media_categor_error_message: 'कृपया सही मीडिया श्रेणी चुनें',
    prototype_drop_not_allowed_for_headers_error_message: 'हेडर के लिए प्रोटोटाइप ड्रॉप की अनुमति नहीं है',
    break_type_item_is_not_allowed_error_message: 'ब्रेक टाइप आइटम की अनुमति नहीं है।',
    cannot_move_segment_to_different_program_schedule_error_message: 'सेगमेंट को अलग-अलग प्रोग्राम शेड्यूल में नहीं ले जा सकता',
    prototype_drop_not_allowed_for_headers_and_footers_error_message: 'हेडर और पाद लेखों के लिए प्रोटोटाइप ड्रॉप की अनुमति नहीं है',
    please_select_header_firsts_error_message: 'कृपया पहले हेडर का चयन करें',
    cannot_delete_selected_header_contains_booked_commercials_error_message: 'हटा नहीं सकता !! चयनित हेडर में बुक किए गए विज्ञापन शामिल हैं',
    cannot_delete_selected_header_contains_reconciled_items_error_message: 'हटा नहीं सकता !! चयनित शीर्ष लेख में सामंजस्यपूर्ण आइटम होते हैं',
    confirm_delete_header_confirm_alert_msg: 'हेडर हटाने की पुष्टि करें',
    are_you_sure_you_want_to_delete_confirm_alert_msg: 'क्या आप वाकई हटाना चाहते हैं',
    select_one_program_to_replace_with_error_message: 'बदलने के लिए एक प्रोग्राम का चयन करें',
    header_from_confirm_alert_msg: 'हेडर से',
    please_select_schedule_item_error_message: 'कृपया शेड्यूल आइटम का चयन करें',
    not_allowed_on_this_postion_error_message: 'इस पद पर अनुमति नहीं है',
    please_select_one_item_error_message: 'कृपया एक आइटम का चयन करें',
    no_secondary_events_attached_to_delete_error_message: 'हटाने के लिए कोई द्वितीयक ईवेंट संलग्न नहीं',
    prototype_drop_not_allowed_for_break_error_message: 'प्रोटोटाइप ड्रॉप को ब्रेक के लिए अनुमति नहीं है',
    added_secondary_events_success_message: 'माध्यमिक घटनाओं को जोड़ा गया',
    please_load_schedule_first_error_message: 'कृपया पहले शेड्यूल लोड करें।',
    variations_are_not_available_info_message: 'विविधताएं उपलब्ध नहीं हैं',
    process_completed_success_message: 'प्रक्रिया पूरी हुई',
    select_atleast_one_header_error_message: 'कम से कम एक हेडर का चयन करें',
    user_favourite_page_breadcrumb: 'पसंदीदा',
    please_enter_template_name_to_save_template_error_message: 'कृपया टेम्पलेट सहेजने के लिए टेम्पलेट नाम दर्ज करें',
    rmn_label: 'आर एम एन',
    please_enter_rmn_error_message: 'कृपया आरएमएन नंबर दर्ज करें',
    expand_collapse_headers_label: 'विस्तार / पतन हेडर',
    pages_label: 'पृष्ठ',
    total_product_column: 'कुल उत्पाद',
    select_icon_url_collection_header: 'आइकन का चयन करें',
    planningdistributionformat_sub_menu: 'योजना वितरण प्रारूप',
    planningdistributionformat_page_breadcrumb: 'योजना वितरण प्रारूप',
    edit_planningdistributionformat_editpage_header: 'योजना वितरण प्रारूप संपादित करें',
    create_planningdistributionformat_editpage_header: 'योजना वितरण प्रारूप बनाएं',
    subtitle_label: 'उपशीर्षक',
    subtitle_type_lable: 'उपशीर्षक प्रकार',
    please_select_segment_type_error_message: 'कृपया सेगमेंट प्रकार का चयन करें',
    audio_track_label: 'ऑडियो ट्रैक',
    track_number_label: 'ट्रैक नंबर',
    add_language_group_prototype_button_tooltip: 'भाषा समूह जोड़ें',
    add_language_group_prototype_button_text: 'भाषा समूह जोड़ें',
    please_enter_track_number_error_message: 'कृपया ट्रैक नंबर दर्ज करें',
    please_select_atleast_one_language_error_message: 'कृपया कम से कम एक भाषा का चयन करें',
    language_already_added_error_message: 'भाषा पहले से ही जोड़ी गई है',
    track_number_already_added_error_message: 'ट्रैक नंबर पहले से ही जोड़ा गया है',
    please_select_atleast_one_rule_error_message: 'कृपया कम से कम एक नियम का चयन करें',
    update_template_label: 'अद्यतन टेम्पलेट',
    transmission_label: 'ट्रांसमिशन',
    transmission_column: 'ट्रांसमिशन',
    apply_distribution_button_text_planning: 'योजना वितरित करें',
    min_gap_label: 'मिन गैप',
    apply_fix_gap_label: 'फिक्स गैप लागू करें',
    plan_till_label: 'योजना टिल',
    max_placement_label: 'मैक्स प्लेसमेंट',
    valid_days_label: 'वैध दिन',
    retry_gap_label: 'रीट्री गैप',
    planning_distribution_label: 'योजना वितरण',
    please_enter_minimum_gap_error_message: 'कृपया न्यूनतम अंतर दर्ज करें',
    please_select_plan_till_date_error_message: 'कृपया आज तक की योजना का चयन करें',
    please_enter_max_placement_error_message: 'कृपया मैक्स प्लेसमेंट दर्ज करें',
    please_select_days_error_message: 'कृपया दिनों का चयन करें',
    please_enter_retry_gap_error_message: 'कृपया गैप दर्ज करें',
    please_select_starting_role_error_message: 'कृपया प्रारंभिक भूमिका का चयन करें',
    please_select_atleast_one_role_error_message: 'कृपया कम से कम एक भूमिका का चयन करें',
    roles_label: 'भूमिकाएं',
    starting_role_label: 'शुरुआती भूमिका',
    please_select_workflow_error_message: 'कृपया वर्कफ़्लो का चयन करें',
    notes_label: 'नोट्स',
    todo_tile_label: 'करना है',
    in_progress_tile_label: 'प्रगति पर',
    done_tile_label: 'किया',
    workflow_page_breadcrumb: 'कार्य प्रवाह',
    plan_till_date_cannot_be_less_than_original_planned_item_error_message: 'आज तक की योजना मूल नियोजित मद से कम नहीं हो सकती',
    applying_planning_distribution_template_please_wait_planner_status_message: "योजना वितरण टेम्पलेट लागू करना, कृपया प्रतीक्षा करें ...",
    planning_distribution_template_applied_success_planner_status_message: "योजना वितरण टेम्पलेट सफलतापूर्वक लागू",
    note_for_parent_select_null_label: 'नोट: माता-पिता के लिए शून्य चुनें',
    payments_label: 'पेम्नेट',
    schedule_autofill_button_tooltip: "ऑटो फिल बुक किए गए वाणिज्यिक",
    schedule_reset_all_header_to_nominal_start_time_button_tooltip: "Reset All Header Start Time To Planned Nominal Time",
    work_order_dashboard: 'वर्क ऑर्डर',
    work_orders_label: 'वर्क ऑर्डर',
    role_wise_work_orders_label: 'रोल वाइज वर्क ऑर्डर',
    user_wise_work_orders_label: 'उपयोगकर्ता वार कार्य आदेश',
    media_column: 'मीडिया',
    created_date_column: 'बनाई गई तारीख',
    select_media_info_message: 'मीडिया का चयन करें',
    workflow_label: 'वर्कफ़्लो',
    history_label: 'इतिहास',
    workorder_dialog_header: 'वर्क ऑर्डर बनाएं',
    current_status_column: 'वर्तमान स्थिति',
    workflow_menu: 'वर्कफ़्लो',
    booking_details_label: 'बुकिंग विवरण',
    show_jobs_button_text: 'नौकरियां दिखाएं',
    please_check_label: 'कृपया जांच करें',
    categories_label: 'श्रेणियाँ',
    mamID_label: 'मैम आईडी',
    workflow_dialog_header: 'वर्कफ़्लो बनाएँ',
    job_status_label: 'नौकरी की स्थिति',
    invalid_start_date: 'अमान्य प्रारंभ तिथि',
    invalid_end_date: 'अमान्य समाप्ति तिथि',
    target_budget_should_be_greater_then_zero: 'लक्ष्य बजट शून्य से अधिक होना चाहिए',
    target_spots_should_be_greater_then_zero: 'लक्ष्य स्पॉट शून्य से अधिक होना चाहिए',
    target_seconds_should_be_greater_then_zero: 'लक्ष्य सेकंड शून्य से अधिक होना चाहिए',
    please_select_date_format_error_message: 'कृपया दिनांक प्रारूप का चयन करें',
    update_synopsis: 'अद्यतन सारांश',
    no_data_schedule_to_save_scheduling_reconciliation_error_message: 'कोई डेटा नहीं मिला शेड्यूल',
    no_data_asrun_to_save_scheduling_reconciliation_error_message: 'कोई डेटा नहीं मिला रन के रूप में',
    replace_existing_planning_label: 'मौजूदा योजना को बदलें',
    reset_password_label: 'पासवर्ड रीसेट करें',
    date_column_is_not_present_in_this_csv_file_label: 'इस csv फ़ाइल में दिनांक स्तंभ मौजूद नहीं है',
    time_column_is_not_present_in_this_csv_file_label: 'इस csv फ़ाइल में समय स्तंभ मौजूद नहीं है',
    house_number_column_is_not_present_in_this_csv_file_label: 'मकान संख्या स्तंभ इस csv फ़ाइल में मौजूद नहीं है',
    replace_existing_planning_label: 'मौजूदा योजना को बदलें',
    please_select_date_format_error_message: 'कृपया दिनांक प्रारूप का चयन करें',
    user_favourite_add_button_title: 'पसंदीदा जोड़ें',
    user_favourite_menu: 'पसंदीदा',
    user_favorite_menu_dropdown_label: 'मेनू',
    user_favorite_menu_text_label: 'नाम',
    please_select_user_favourite_menu_error_message: 'कृपया मेनू का चयन करें',
    please_select_user_favourite_name_error_message: 'कृपया नाम दर्ज करें',
    vodtype_label: 'वीओडी प्रकार',
    live_events_menu: 'लाइव इवेंट्स',
    replace_existing_planning_label: 'मौजूदा योजना को बदलें',
    region_column: 'क्षेत्र',
    spot_should_not_be_zero_error_message: 'Spot should be greater than Zero',
    rate_should_not_be_zero_error_message: 'Rate should be greater than Zero',
    please_add_booking_first_booking_error_message: 'Please add booking first',
    tournament_dialog_header : 'Create Tournament',
    live_match_dialog_header : 'Create Match',


  }
}