import React, { useEffect, useRef, useState } from 'react';
import { orderBy, useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { Grid, GridColumn as Column, GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE, TX_MODE } from "../../framework/constant/constant";
import { ContextMenu } from 'primereact/contextmenu';
import { toast } from 'react-toastify';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { SegmentEditFormPopup } from '../Segment/SegmentEditFormPopup';
import { PlanningHelper } from './helper/PlanningHelper';

const PlanningVersionTab = (props) => {
    const { plannerSelectedItem, onShowMedia, onScheduleRefreshWithSocket, fromPlanning, onScheduleRefresh } = props
    //console.log(fromPlanning);
    const DATA_ITEM_KEY = "_id";
    const lang = useTranslation();

    const [data, setData] = useState([]);
    const [txMode, setTxMode] = useState([]);
    const [dataItem, setDataItem] = useState({});
    const contextMenuRef = useRef(null);
    const [disabledMenu, setDisabledMenu] = useState(false);
    const selectedSegmentRef = useRef(null);
    const [showSegmentPopup, setShowSegmentPopup] = useState(false);
    const [editSegment, setEditSegment] = useState({});

    const menuModel = [
        {
            disabled: false,
            label: "Edit",
            icon: `pi pi-fw pi-pencil`,
            command: () => {
                console.log(selectedSegmentRef.current);
                setShowSegmentPopup(true);
            },
        },
        {
            label: `${disabledMenu ? "Re-Apply Version" : "Set as Active Version"}`,
            icon: `pi pi-fw pi-${disabledMenu ? 'sync' : 'circle'} text-success`,
            command: () => {
                onSetActiveVersion();
            },
        },
    ]

    useEffect(() => {
        loadcombo();
        if (plannerSelectedItem?.segments) {

            let validSegments = PlanningHelper.getValidSegmentsByChannel(plannerSelectedItem?.segments,plannerSelectedItem?.Channel)
            setData(validSegments.map(item => {
                if (item._id == plannerSelectedItem.segment._id) {
                    return { ...item, color: "#A0D8B3" }
                } else {
                    return item
                }
            }))
        } else if (plannerSelectedItem?.mediaEpisode && plannerSelectedItem?.Segment_id) {
            loadSegments();
        } else {
            setData([])
        }
    }, [plannerSelectedItem])

    const loadcombo = async () => {
        let txmodeRes = await API.getDataLookup(ENTITYNAME.TXMode, { sort: { Name: 1 } });
        setTxMode(txmodeRes.data);
        setDataItem({ Duration: '', TXMode: txmodeRes.data.find((x) => x.SID == 1) })
    }

    const loadSegments = async () => {
        let mediaEpisodeSegmentres = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: ['MediaEpisodeSID', '=', parseInt(plannerSelectedItem.mediaEpisode.SID)] });

        if (mediaEpisodeSegmentres?.success && mediaEpisodeSegmentres?.data?.length > 0) {
            let validSegments = PlanningHelper.getValidSegmentsByChannel(mediaEpisodeSegmentres?.data,plannerSelectedItem?.Channel)
            setData(validSegments.map(item => {
                if (item._id == plannerSelectedItem.Segment_id) {
                    return { ...item, color: "#A0D8B3" }
                } else {
                    return item
                }
            }))
        } else {
            setData([])
        }
    }

    const expandChange = (event) => {
        let newData = data.map((item) => {
            if (item._id === event.dataItem._id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setData(newData);
    };

    const gridRowRender = (tr, props) => {
        let color = props.dataItem.color;
        if (color) {
            tr.props.style.backgroundColor = color;
        }
        return tr;
    };

    const DetailComponent = (props) => {

        return (
            <>
                <Grid
                    style={{ height: "100%", width: "100%!important" }}
                    data={orderBy(props.dataItem?.SegmentDetails, [
                        {
                            field: "Sequence",
                            dir: "asc",
                        },
                    ]) ?? []}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: {
                            value: "multiple",
                            label: "Multiple selection mode",
                        },
                    }}
                // className={"no-headers-grid"}
                >
                    <Column field="Sequence" title={"Sequence"} width={100} />
                    <Column field="MediaId" title={"MediaId"} width={180} />
                    <Column field="TcIn" title={"TcIn"} width={120} cell={TimeCell} />
                    <Column field="TcOut" title={"TcOut"} width={120} cell={TimeCell} />
                    <Column field="Duration" title={"Duration"} width={120} cell={TimeCell} />
                    <Column field="Remarks" title={"Remarks"} width={180} />
                </Grid>
            </>
        );
    };



    const onContextMenu = (e) => {
        contextMenuRef.current.show(e.syntheticEvent);
        selectedSegmentRef.current = e.dataItem;
        setDisabledMenu(e.dataItem.color == "#A0D8B3" ? true : false);
    };

    const onSetActiveVersion = async (updatedSegmentData = [], segment_id = "") => {

        console.log("changing version");
        console.log(selectedSegmentRef.current);

        let isFromEditSegment = updatedSegmentData.length > 0 && segment_id.length > 0;

        if(updatedSegmentData.length == 0){
            updatedSegmentData = [...data]
        }

        if(segment_id.length == 0){
            segment_id = selectedSegmentRef.current?._id
        }

        //UPDATED SEGMEMTS
        let newData = updatedSegmentData.map(item => {
            if (item._id == segment_id) {
                return { ...item, color: "#A0D8B3" }
            } else {
                delete item.color;
                return item;
            }
        })

        setData(isFromEditSegment ? updatedSegmentData : newData);

        if(isFromEditSegment && plannerSelectedItem?.Segment_id != segment_id){
            onScheduleRefreshWithSocket();
            onScheduleRefresh();
            return;
        }

        let updatedData = {
            ...plannerSelectedItem,
            Segment_id: segment_id
        }

        var res = await API.savePlanning(updatedData, newData);
        if (res.success) {
            onScheduleRefreshWithSocket();
            onScheduleRefresh();

            let logData = { event: LOGEVENT.CHANGE_VERSION, module: MODULE.SCHEDULING, data: selectedSegmentRef.current, message: LOGEVENT.CHANGE_VERSION };
            API.SaveLogs(logData);

        } else {
            setData(updatedSegmentData.map(item => {
                if (item._id == plannerSelectedItem?.Segment_id) {
                    return { ...item, color: "#A0D8B3" }
                } else {
                    return item
                }
            }));
            toast.error('Something went wrong! Could not set as active version.');
        }
    }

    //--------------FAST SEGMENTATION-----------------
    const isValid = () => {

        if (!plannerSelectedItem || Object.keys(plannerSelectedItem).length == 0) {
            toast.error(`${lang.please_select_header_firsts_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Duration == undefined || dataItem.Duration.trim() == "") {
            toast.error(`${lang.please_enter_duration}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        let durationSumInMilliSec = dataItem.Duration.split(',').reduce((acc, o) => acc + parseInt(o), 0) * 60000;
        if (durationSumInMilliSec > plannerSelectedItem.mediaEpisode.Duration) {
            toast.error(`${lang.sum_of_entered_duration_should_be_less_then_or_equal_to_media_duration}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.TXMode || Object.keys(dataItem.TXMode).length == 0) {
            toast.error(`${lang.please_select_tx_mode_segment_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;

    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    //CREATE FAST SEGMENT
    const onCreateSegment = async () => {

        if (!isValid()) return;

        let payload = {
            txmode: dataItem.TXMode,
            duration: dataItem.Duration.split(','),
            mediaEpisodeId: plannerSelectedItem.mediaEpisode._id,
            source: 'planning',
            parentProgramSchedule: plannerSelectedItem
        }

        let response = await API.createFastSegments(payload);
        if (response.success) {
            loadSegments();
            onClear();
            console.log(response.data)
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            console.log(response)
        }
    }

    const onClear = () => {
        setDataItem({ TXMode: txMode.find((x) => x.SID == 1), Duration: '' })
    }

    //-----------END OF FAST SEGMENTATION-----------

    const MyTXModeCell = (props) => {

        let value = props.dataItem.SegmentType ?
            props.dataItem.SegmentType.TXMode ?
                props.dataItem.SegmentType.TXMode.Name : utility.getKeyByValue(TX_MODE, props.dataItem.SegmentType?.TXModeSID)
            : "";

        return <td
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...useTableKeyboardNavigation(props.id)}
            // style={{textAlign : 'center'}}
            style={props.style}
        >
            {value}
        </td>;
    };

    const onCloseEditSegment = () => {
        setShowSegmentPopup(false);
        // console.log(plannerSelectedItem)
        // props.setSelectedHeader(plannerSelectedItem);
    }

    return (
        <>
            <div style={{ margin: props.source == 'planning' ? '0px 9px' : '0px 0px 0px 9px' }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ fontSize: "15px", padding: "10px 5px", margin: 0, letterSpacing: "0.5px" }}>{plannerSelectedItem?.mediaEpisode?.Title ?? ''}</p>
                    <p style={{ fontSize: "14px", padding: "10px 5px", margin: 0, letterSpacing: "0.5px" }}>{plannerSelectedItem?.mediaEpisode?.AssetId ?? ''}</p>
                    {plannerSelectedItem?.mediaEpisode && <i onClick={() => { onShowMedia(plannerSelectedItem.mediaEpisode) }} title="Show Media" className="fas fa-xs fa-arrow-up-right-from-square" style={{ cursor: "pointer", marginLeft: "5px" }} />}
                </div>

                <h1 className="page-title txt-color-bludeDark" style={{margin: '0px'}}>
                    <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} />
                    <span>Media Versions</span>
                </h1>
                {/* <div className="row" style={{ border: 'solid 1px lightgrey', borderRadius: '5px', margin: '1px 2px' }}>
                    <div className="col-4" style={{ padding: '0px 0px 0px 15px' }}>
                        <label htmlFor="TabView">{'Duration'} *</label>
                        <input className="form-control form-control-sm" type="text" name="Duration" onChange={onChange} value={dataItem.Duration} />
                    </div>
                    <div className="col-4" style={{ padding: '0px 0px 0px 15px', margin: '0px 0px 2px 0px' }}>
                        <label htmlFor="TabView">{lang.tx_mode_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={txMode}
                            name="TXMode"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.TXMode}
                            onChange={onChange}
                        />
                    </div>
                    <div className='col-4' style={{ padding: props.source == 'planning' ? '0px 0px 0px 10px' : '5px 0px 0px 10px' }}>
                        <button style={{ margin: '18px 0px 2px 0px', padding: '0px', borderRadius: '5px', width: '35px', height: '30px' }} title={"OK"} onClick={onCreateSegment} className={`btn btn-primary`} >
                            <i className={`fa fa-check fa-fw `}></i>
                        </button>
                        <button style={{ margin: '18px 0px 2px 5px', padding: '0px', borderRadius: '5px', width: '35px', height: '30px' }} title={"Close"} onClick={onClear} className={`btn btn-danger`} >
                            <i className={`fa fa-xmark fa-fw`}></i>
                        </button>
                    </div>
                    <div className='col-12'><p className="text-muted" style={{ fontSize: "9px", margin: '0px' }}>{`Example : 10,12,8 will create segment of 3 of these durations in minutes respectively`}</p></div>
                </div> */}

                <Grid
                    style={{ position: "absolute", left: 0, top: "115px", right: 0, bottom: 20 }}
                    data={data}
                    dataItemKey={DATA_ITEM_KEY}
                    detail={DetailComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    rowRender={gridRowRender}
                    onContextMenu={onContextMenu}
                    selectable={{
                        enabled: true,
                        // drag: false,
                        // cell: false,
                        mode: "multiple",
                    }}
                >
                    <Column field="SegmentType.Description" title={"Segment Type"} width={120} />
                    <Column field="SegmentType.SegmentCount" title={"Count"} width={100} />
                    <Column field="SegmentNumber" title={"Segment Number"} width={150} />
                    <Column field="TX Mode" title={"TX Mode"} width={180} cell={MyTXModeCell} />

                    {/* <Column field="description" title={"description"} width={220} />
            <Column field="startDate" title={"startDate"} width={220} />
            <Column field="endDate" title={"endDate"} width={220} />
            <Column field="status" title={"status"} width={220} />
            <Column field="createdBy" title={"createdBy"} width={220} /> */}
                </Grid>
                <ContextMenu model={menuModel} ref={contextMenuRef} />
            </div>
            <div style={{ display: 'flex', position: "absolute", overflow: 'hidden', right: 0, bottom: 0, padding: "5px", fontSize: "10px", zIndex: 2 }}>
                <i className="fa fa-circle mr-1" style={{ color: "rgb(89 195 125)", marginTop: '2px' }}></i>
                {lang.current_active_version_label}
            </div>
            {showSegmentPopup && <SegmentEditFormPopup mediaEpisodeEntity={plannerSelectedItem.mediaEpisode} segmentData={plannerSelectedItem.segments} activeSegment={plannerSelectedItem.segment} segment={selectedSegmentRef.current} onClose={onCloseEditSegment} setData = {setData} onSetActiveVersion={onSetActiveVersion}/>}
        </>
    );
}

export default PlanningVersionTab;
