/* eslint-disable */
import React, { useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const SegmentTypeEditForm = (props) => {

    const lang = useTranslation();
    const [txModes, setTXModes] = useState([]);

    const blankDataItem = {
        ...props.item,
        Title: props.item.Title ?? "",
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        SegmentCount: props.item.SegmentCount ?? "",
        TXMode: props.item.TXMode ?? {},
        Archive: props.item.Archive ?? false,
        Suffix: props.item.Suffix ?? "",
    }

    useEffect(() => {
        loadcombo();
    }, []);

    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async () => {
        let txModeRes = await API.getDataLookup(ENTITYNAME.TXMode);
        setTXModes(txModeRes.data);
    }


    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Title == "" || dataItem.Title == undefined) {
            toast.error(`${lang.please_enter_title_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.SegmentCount == "" || dataItem.SegmentCount == undefined) {
            toast.error(`${lang.please_enter_segment_count_segment_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.TXMode).length == 0) {
            toast.error(`${lang.please_select_tx_mode_segment_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                SID: dataItem.SID,
                Title: dataItem.Title,
                Description: dataItem.Description,
                SegmentCount: dataItem.SegmentCount,
                TXMode: { _id: dataItem.TXMode._id, SID: dataItem.TXMode.SID, Name: dataItem.TXMode.Name },
                Suffix: dataItem.Suffix ?? "",
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]

            }
            let res = await API.saveData(ENTITYNAME.SegmentType, saveData);
            console.log(saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.SEGMENT_TYPE, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.SegmentType);
                props.refresh();
                props.cancelEdit();
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Title ?? `${lang.create_segment_type_dialog_header}`}
                onClose={props.cancelEdit}
                width={"650px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.title_label} *</label>
                                            <input
                                                name={"Title"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Title}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.description_label} *</label>
                                            <input
                                                name={"Description"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Description}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.segment_count_label} *</label>
                                            <input
                                                name={"SegmentCount"}
                                                type="number"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.SegmentCount}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="TabView">{lang.tx_mode_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={txModes}
                                                name="TXMode"
                                                textField="Name"
                                                dataItemKey="SID"
                                                value={dataItem.TXMode}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>

                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.suffix_label}</label>
                                            <input
                                                name={"Suffix"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Suffix}
                                            />
                                        </div>
                                        <div className='col-6 mt-4'>
                                            <Field
                                                style={{ border: "1px solid grey" }}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>
    )
}

export default SegmentTypeEditForm;