/* eslint-disable */
import { useState, useEffect } from "react";
import * as API from '../../../API/api';

//key name
var foriegnKey = {
  OttAssetType : 'ott_assettype',
  OttPosterType:'ott_postertype',
  OttRailType:'ott_railtype',
  OttTheme:'ott_theme',
  OttVideoType:'ott_videotype',
  OttPlatform:'ott_platform',

}

export const LookupCell = (props) => {

    const field = props.field || "";

    const value = props.dataItem != undefined ? props.dataItem[field] : 1;
    // const value = props.dataItem[field];
    const displayMember =  'Description';
    const [displayValue, setdisplayValue] = useState("");


    useEffect(() => {
        loaddata();
      }, [])


      async function loaddata(){
        
        var entityname = field.replace("SID", "");

        //getting column from endpoint if exist
        var column = props.dataColumns.filter(column=> column.name === field);
        if(column.length > 0)
        {
         
           entityname =  column[0]['endpoint'] && column[0]['endpoint'].length > 0 ? column[0]['endpoint']: entityname ;
         
           //TODO: to impment displaymember
           //displayMember = column[0]['dis'] && column[0]['endpoint'].length > 0 ? column[0]['endpoint'].length: entityname ;
        }
        

        var json = await API.getDataLookup(foriegnKey[entityname]);
        if(json && json.success === true && json.data.length > 0)
        {
           
            var displayValueLocal  = json.data.filter(item=> item.SID == value);
            
            if(displayValueLocal && displayValueLocal.length > 0)
            {
              setdisplayValue(displayValueLocal[0][displayMember]);
            }
        }

      }

     return <td>
          {displayValue} 
    </td>;
  };