import React, { useEffect, useState } from 'react'
import BossDialog from '../../components/BossDialog'
import { useTranslation } from '../../locale/useTranslation'
import { EditPageHeader } from '../../components/EditPageHeader'
import { ENTITYNAME } from '../../framework/constant/constant'
import * as API from "../../framework/API/api_digitalSign"
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { toast } from 'react-toastify'

const DigitalSignSetResolutionForm = (props) => {

    const lang = useTranslation()

    const blankDataItem = {
        Resolution: props?.item?.Resolution ?? {},
    };

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [resolutions, setResolutions] = useState([]);


    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value, });
    };
    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let resolutionRes = await API.getDataLookup(ENTITYNAME.Resolution, { query: ["Archive", "=", false], sort: { Description: 1 } })
        setResolutions(resolutionRes.data)
    }

    const isValid = () => {
        if (!dataItem.Resolution || Object.keys(dataItem.Resolution).length === 0) {
            toast.error(lang.please_select_resolution_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;

    }

    const handleSubmit = () => {
        if (!isValid()) return;
        props?.handleSetResolution({
            _id: dataItem?.Resolution?._id,
            Description: dataItem?.Resolution?.Description
        })
        props?.onClose()
    }

    return (
        <>
            <BossDialog width={"35vw"} title={lang.set_resolution_dialog_header} onClose={props.onClose} >
                <div className='row'>
                    <div className='col-12'>
                        <EditPageHeader
                            showTitle={false}
                            onSubmit={handleSubmit}
                            onCancel={props.onClose}
                        />
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label>{lang.resolution_label} *</label>
                                <DropDownList
                                    data={resolutions}
                                    name="Resolution"
                                    textField="Description"
                                    dataItemKey="_id"
                                    onChange={onChange}
                                    value={dataItem.Resolution}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    )
}



export default DigitalSignSetResolutionForm
