export const colorCell = (props) => {

    const field = props.field || "";
    let value = '';

    if(field.includes('.')){
        //For field value coming as example :- MediaCategory.Duration
        var keys = field.split('.');
        value = keys.length > 1 ? props.dataItem?.[keys[0]]?.[keys[1]] : props.dataItem[keys[0]];
    }else{
        value = props.dataItem[field];
    }

  
    return <td >
          <div className=" text-center mt-1"><input type="color" value={value} disabled /></div>
        </td>
  };