/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, REPLACEMENTMODE } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import BossDialog from "../../components/BossDialog";

export const GlobalReplacement = (props) => {

    const { selectedState,scheduleData,gloabalFindAndReplace } = props;
    const lang = useTranslation();
    const border1 = { border: "2px solid lightgray" }
    const [dataItem, setDataItem] = useState(Object.keys(REPLACEMENTMODE)[0]);
    const [openAllSelectedMedia, setOpenAllSelectedMedia] = useState(false);
    const [openVariance, setOpenVariance] = useState(false);
    const [openMediaEpisodeGroup, setOpenMediaEpisodeGroup] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [includeBreak,setIncludeBreak ] = useState(false);
    const [enableCrossCategoryReplacement,setEnableCrossCategoryReplacement] = useState(false); 
    const [mediaFilter,setMediaFilter] = useState([
        ['MediaCategory.isLong', '=', false],
        ['MediaCategory.SID','=',selectedState[0]?.mediaEpisode?.MediaCategory?.SID]
    ])

    const [totalMediaCount,setTotalMediaCount] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState(0);

    useEffect(() => {

        //SELECTED MEDIA
        let distinctSelected = [...new Set(selectedState.map((x) => x))];

        console.log(distinctSelected)
        setSelectedMedia(distinctSelected)
        

        //TOTAL MEDIA
        let childrens = [];
        scheduleData.map((x) => {
            childrens.push(...x.children)
        })
        let media = childrens.filter((y) => y.mediaEpisode && distinctSelected.some((z) => z.mediaEpisode && z.mediaEpisode._id == y.mediaEpisode._id));
        setTotalMediaCount(media.length)

    }, []);

    const onChange = (e) => {
        setDataItem(e.target.value);
    };

    const onEnableCrossCategoryReplacementChange=(e) => {
        setEnableCrossCategoryReplacement(e.target.checked);
        e.target.checked ? setMediaFilter([['MediaCategory.isLong', '=', false]]) : 
        setMediaFilter([
            ['MediaCategory.isLong', '=', false],
            ['MediaCategory.SID','=',selectedState[0].mediaEpisode.MediaCategory.SID]
        ])
    }

    const deleteSelectedValue = (dataItem) => {
        console.log(dataItem)
        var filtered = gridData.filter((obj) => dataItem._id != obj._id)
        console.log(filtered)
        setGridData(filtered)
    }

    const MyCommandCell = (props) => (
        <td style={{ padding: "0px" }}><DeleteRoundButton onClick={() => deleteSelectedValue(props.dataItem)} /></td>
    )

    const onMediaSelection = (dataList) => {
        setGridData((old) => [...old, ...dataList]);
    }

    const onVarianceSelection = async (dataList) => {
        let mediaRes = await API.getData(ENTITYNAME.MediaEpisode,{ query : [["Variance._id", 'in', dataList.map((item) => item._id)]]})
        if(mediaRes.success){
            setGridData((old) => [...old, ...mediaRes.data]);
        }else {
            toast.error(mediaRes.error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onMediaGroupSelection = async (dataList) => {
        let groupValue = dataList && dataList?.length > 0 ? dataList[0] : {};
        let linkData = {
            link_collection: 'mediaepisode',
            link_field: 'MediaEpisodes'
        }
        let res = await API.getEntity(ENTITYNAME.MediaEpisodeGroup, groupValue?.SID, linkData);
        setGridData((old) => [...old, ...res?.data?.MediaEpisodes]);
    }

    const openCloseAllCollectionSelection = () => {
        setOpenAllSelectedMedia(!openAllSelectedMedia)
    }

    const openCloseVarianceCollectionSelection = () => {
        setOpenVariance(!openVariance)
    }

    const openCloseGroupCollectionSelection = () => {
        setOpenMediaEpisodeGroup(!openMediaEpisodeGroup)
    }

    const deleteAllData = () => {
        setGridData([]);
    }

    return (<>
        {<BossDialog
            width={"910px"}
            title={lang.global_replacement_dialog_header}
            onClose={props.closeForm}
        >
            <div className="row m-2">
                    <div className="" style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
                        <div className="mr-1">
                            <ActionButton title={lang.replace_button_tooltip} name={lang.replace_button_text} onClick={()=>{
                                if(gridData.length==0){
                                    toast.error(`${lang.first_select_replace_items_global_replacement_error_message}`, {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }else{
                                    props.closeForm();
                                    gloabalFindAndReplace(selectedState,gridData,includeBreak,dataItem);
                                }
                                }} />
                            <ActionButton title={lang.cancel_button_tooltip} name={lang.cancel_button_tooltip} btnColor={'danger'} onClick={props.closeForm} />
                        </div>
                    </div>

                <div className="mt-2" style={border1}>
                    <div className="row mt-3 ml-1">
                        <div className="col-4 form-group" style={{ padding : '0px 10px'}}>
                            <label style={{ fontWeight : 'bold'}}>{lang.replacement_method_label} : </label>
                            <DropDownList
                                data={Object.keys(REPLACEMENTMODE)}
                                defaultValue={Object.keys(REPLACEMENTMODE)[0]}
                                value={dataItem}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-2" style={{ padding : '25px 0px 0px 15px'}}>
                            <label className="pr-2">{lang.include_isBreak_label}</label>
                            <input disabled={REPLACEMENTMODE[dataItem] == REPLACEMENTMODE.Selected} defaultChecked={includeBreak} onChange={(e) => { setIncludeBreak(e.target.checked) }} style={{ position: 'relative', top: '2px' }} type={'checkbox'} />
                           
                        </div>
                        <div className="col-3" style={{ paddingTop : '25px'}}>
                            <label className="pr-2">{lang.enable_cross_category_replacement}</label>
                            <input disabled={REPLACEMENTMODE[dataItem] == REPLACEMENTMODE.Selected} defaultChecked={enableCrossCategoryReplacement} onChange={onEnableCrossCategoryReplacementChange} style={{ position: 'relative', top: '2px' }} type={'checkbox'} />
                        </div>
                    </div>
                    <div className="row mt-2">
                    <div className="col-12" style={{textAlign : 'right' ,padding : '0px 25px' ,margin:"-15px 0px"}}>
                                <RoundButton icon = {"plus"} title={'Select Media'} name={"Select Media"} onClick={openCloseAllCollectionSelection} />

                                <RoundButton icon = {"layer-group"} title={"Select Variance"} name={"Select Variance"}  onClick={openCloseVarianceCollectionSelection} />

                                <RoundButton icon={"server"} title={lang.interstitial_group_button_tooltip} name={lang.interstitial_group_button_tooltip} onClick={openCloseGroupCollectionSelection} />

                                <DeleteRoundButton onClick={deleteAllData} />
                            </div>
                    <div className="col-6" style={{ padding : '0px 0px 0px 15px'}}>
                            <label style={{ margin : '0px 0px 0px 10px' , fontWeight : 'bold'}}>Selected Items :</label>
                            <Grid data={selectedMedia} resizable={true} style={{ height: "35vh", margin: "0px 10px 10px 10px", border: '1px solid lightgray', borderRadius: '5px!important' }}>
                                {/* <Column cell={MyCommandCell} width={"40px"} locked={true} /> */}
                                <Column field="mediaEpisode.Title" title={lang.title_column} editable={false} width={'200px'} />
                                <Column field="mediaEpisode.AssetId" title={lang.asset_id_column} editable={false} width={'200px'} />
                                <Column field="mediaEpisode.Duration" title={lang.duration_column} editable={false} cell={TimeCell} width={'200px'}  />
                                <Column field="mediaEpisode.MediaCategoryType.Description" title={lang.media_category_type_column} editable={false} width={'200px'} />
                            </Grid>
                        </div>
                        <div className="col-6" style={{ padding : '0px 15px 0px 0px'}}>
                                
                            
                            <label style={{ margin : '0px 0px 0px 10px' , fontWeight : 'bold'}}>{lang.replacement_material_label} :</label>
                            
                            <Grid data={gridData}  resizable={true} style={{ height: "35vh", margin: "0px 10px 10px 10px", border: '1px solid lightgray', borderRadius: '5px!important' }}>
                                <Column cell={MyCommandCell} width={"40px"} locked={true} />
                                <Column field="Title" title={lang.title_column} editable={false} width={'200px'} />
                                <Column field="AssetId" title={lang.asset_id_column} editable={false} width={'200px'}/>
                                <Column field="Duration" title={lang.duration_column} editable={false} cell={TimeCell} width={'200px'} />
                                <Column field="MediaCategoryType.Description" title={lang.media_category_type_column} editable={false} width={'200px'} />
                            </Grid>
                        </div>
                        
                    </div>
                </div>
            </div>
            <p className='mt-1' style={{  height: '28px', margin: '-16px', padding: '8px', background: "#3A3633" ,color: 'white' }}>
                Selected : {selectedMedia.length} | Total : {totalMediaCount} 
            </p>
            </BossDialog>}

        {openAllSelectedMedia && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_media_dialog_header} wherestatement={mediaFilter} setDataList={onMediaSelection} entityname={ENTITYNAME.MediaEpisode} closeForm={openCloseAllCollectionSelection} width={"50vw"} height={"60vh"} isLongMediaCategory = {false} showMediaCategoryTypeDropDown={true} mediaCategoryType={selectedState[0]?.mediaEpisode?.MediaCategoryType}/>}

        {openVariance && <CollectionSelection entityname={ENTITYNAME.Variance} closeForm={openCloseVarianceCollectionSelection}  title={"Select Family"} setDataList={onVarianceSelection} width={"50vw"} height={"60vh"} checkDuplicate={true} />}

        {openMediaEpisodeGroup && <CollectionSelection entityname={ENTITYNAME.MediaEpisodeGroup} wherestatement={['MediaCategory.isLong', '=', false]} closeForm={openCloseGroupCollectionSelection} setDataList={onMediaGroupSelection} title={lang.select_interstitial_group_dialoag_header} width={"50vw"} checkDuplicate={true} />}
    </>)
}
export default GlobalReplacement;