import * as API_DigiSign from "../../../framework/API/api_digitalSign";
import { MODULE, SOCKET_ACTION } from "../../../framework/constant/constant";

export const DigitalSignHelper = {};

DigitalSignHelper.saveScreenEntity = (dataItem) => {
    let finalData = {
        _id: dataItem?._id,
        SID: dataItem?.SID,
        Name: dataItem?.Name,
        screenGroup: dataItem?.screenGroup && Object.keys(dataItem?.screenGroup).length > 0 ? {
            _id: dataItem?.screenGroup?._id,
            SID: dataItem?.screenGroup?.SID,
            Name: dataItem?.screenGroup?.Name,
            Description: dataItem?.screenGroup?.Description
        } : {},
        SerialNumber: dataItem?.SerialNumber,
        active: dataItem?.active,
        socketID: dataItem?.socketID,
        OsVer: dataItem?.OsVer,
        ModelNo: dataItem?.ModelNo,
        AppVer: dataItem?.AppVer,
        Company_id: dataItem?.Company_id,
        brightness: dataItem?.brightness,
        volume: dataItem?.volume,
        orientation: dataItem?.orientation,
        modBy: dataItem?.modBy,
        modOn: dataItem?.modOn,
        reboot: dataItem?.reboot ?? null,
        debugMode: dataItem?.debugMode ?? false,
        autoRestartPercent: dataItem?.autoRestartPercent ?? 90
    }
    return finalData;
}

DigitalSignHelper.activateScreenFunc = async ({activationCode: activationCode, name: name, selectedPlanId: selectedPlanId, companyId: companyId}) => {
    const activateScreenRes = await API_DigiSign.activateScreen(
        activationCode,
        name,
        selectedPlanId,
        companyId
    );

    if (!activateScreenRes.success) {
        return { success: false, message: activateScreenRes.message };
    }

    if (activateScreenRes.data.isValid) {
        if (activateScreenRes.data.isActive) {
            const { socketID: screenSocketID, screenGroupId } = activateScreenRes.data;
            await API_DigiSign.joinCompany(screenSocketID, companyId, screenGroupId);
            await API_DigiSign.sendEvent(screenSocketID, {
                action: SOCKET_ACTION.DIGI_PLAY_PLAYLIST,
                module: MODULE.ALL,
                data: null,
            });
        }
        return { success: true, message: activateScreenRes.message };
    } else {
        return { success: false, message: activateScreenRes.message };
    }
};