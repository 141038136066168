import React from 'react'

export const Loader = ({height ,width, bgColor}) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height : height ?? '73vh', width : width ?? '100%' , backgroundColor: bgColor}}>
      <i className="fa fa-spinner fa-spin fa-2xl" />
    </div>
  )
  
}
