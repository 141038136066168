import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '../../../locale/useTranslation';
import { getSelectedState, Grid, GridColumn } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-data-query';
import { USER_RESTRICTED_ACTIONS } from '../../constant/constant';

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "SID";
const idGetter = getter(DATA_ITEM_KEY);

const UserRestrictionPopup = (props) => {

    const lang = useTranslation();
    const [selectedState, setSelectedState] = useState({});
    const [selectedGridData, setSelectedGridData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {

        if (props.module && props.module.SID) {

            let moduleRestrictions = getRestrictionActionByModule(props.module.SID);
            setData(moduleRestrictions);

            if(props.restrictions && props.restrictions.length > 0){

                let state = {};
                props.restrictions.filter((x) => x.ModuleSID == props.module.SID).map((y) => { state[y.ActionSID] = true })
                setSelectedState(state);

                let selectedItems = moduleRestrictions.filter((y) => state[y.SID]);
                // console.log(selectedItems)
                setSelectedGridData(selectedItems)

            }

        }

    }, [props.module]);

    const getRestrictionActionByModule = (moduleSID) => {

        let actions = [];
        Object.keys(USER_RESTRICTED_ACTIONS).map((key) => {
            if (USER_RESTRICTED_ACTIONS[key].ModuleSID.includes(moduleSID)) {
                actions.push(USER_RESTRICTED_ACTIONS[key]);
            }
        })

        return actions

    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
        console.log(newSelectedState)

        let selectedItems = data.filter((y) => newSelectedState[y.SID]);

        setSelectedGridData(selectedItems);

        addRestriction(selectedItems);
    };


    const onHeaderSelectionChange = useCallback((event) => {

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });

        setSelectedState(newSelectedState);

        let selectedItems = data.filter((y) => newSelectedState[y.SID]);

        setSelectedGridData(selectedItems);

        addRestriction(selectedItems);

    }, []);

    const addRestriction = (items) => {

        let restrictionItem = items.map((x) => {
            return {
                ModuleSID: props.module.SID,
                Module: props.module.Name,
                ActionSID: x.SID,
                Description: x.DisplayName
            }
        });

        //OTHER MODULE RESTRICTIONS
        let otherModuleRestrictions = props.restrictions.filter((x) => x.ModuleSID != props.module.SID);

        props.setUserRestrictions([...otherModuleRestrictions, ...restrictionItem]);

    }

    return (
        
            <Grid style={{ height: "40vh" }}
                data={
                    data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: "multiple",
                }}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                        data.findIndex((item) => !selectedState[idGetter(item)]) === -1
                    }
                    locked={true}
                    filterable={false}
                />
                {/* ${lang.action_label} */}
                <GridColumn field="DisplayName" title={lang.restriction_actions_label} />
            </Grid>
    )
}

export default UserRestrictionPopup