/* eslint-disable */
import React from 'react'
import { Chart, ChartSeries, ChartSeriesItem, ChartLegend, ChartTooltip, } from '@progress/kendo-react-charts';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { useTranslation } from '../../../locale/useTranslation';
import { seriesChartColorGenerator } from '../../../framework/constant/constant';


const DonutChart = (props) => {

  const navigate = useNavigate();
  const lang = useTranslation();

  return (
    <div>
      <a onClick={(e) => { e.preventDefault(); navigate(props.root) }} style={{ textDecorationLine: "none" }}></a>
      <div className="panel panel-default" style={{ color: "#333", backgroundColor: "#f5f5f5", borderColor: "#ddd" }}>
        <div className="panel-heading cursor-pointer" style={{
          padding: "10px 10px", borderColor: "1px solid transparent", borderBottom: "1px solid transparent", borderTopRightRadius: "1px"   // border-top-right-radius: 1px;
          , borderTopLeftRadius: "1px", textAlignLast: "center"
        }}>{props.title}</div>

        {props?.isLoading ? 
        <Loader height="53.5vh" bgColor="white" /> : props?.listData?.length == 0 ? 
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height : '400px', width : '100%' , backgroundColor: "white"}}>
          {lang.no_data_found_error_message}
        </div> :
        
        <div className="panel-body"><iframe className="chartjs-hidden-iframe" style={{ width: "100%", display: "block", border: "0px", height: "0px", margin: "0px", position: "absolute", inset: "0px" }}></iframe>
          <Chart id="myDispatchChart" style={{ display: "block" }} seriesColors={seriesChartColorGenerator()}>
            <ChartTooltip />
            <ChartSeries>
              <ChartSeriesItem
                autoFit="true"
                type="donut"
                data={props.listData}
                categoryField="name"
                field="value"
              >
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend visible={true} position={"bottom"} />
          </Chart></div>}
      </div>
    </div>
  )
}

export default DonutChart;
