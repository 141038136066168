/* eslint-disable */
import React, { useState, useEffect, useRef, startTransition } from "react";
import { Grid, GridCell, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import {
  MEDIA_IMPORT_ARRAY,
  ENTITYNAME,
  FILEURL,
  SOCKET_EVENTS,
  MODULE,
  SOCKET_ACTION,
} from "../../framework/constant/constant";
import { useLocation } from "react-router-dom";
import { ConfirmAlert } from "../../ConfirmAlert";
import moment from "moment";
import { utility } from "../../framework/utility/utilityProvider";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import useSocket from "../../framework/socket/useSocket";
import InfoRoundButton from "../../components/InfoRoundButton";
import ProgressBarPopup from "../../framework/forms/helpercomponents/ProgressBarPopup";

const IMPORT_STATUS = {
  Pending: 0,
  MissingValue: 1,
  Duplicate: 2,
  Complete: 3
}

const DataMigrationStatusCell = (props) => {

  const field = props.field || "";

  let value = props.dataItem[field];
  var iconClassName = '';
  var style = {}
  if (value == 'Completed' || value == 'Updated') {
    iconClassName = 'fa-regular fa-circle-check fa-lg';
    style = { backgroundColor: 'green', color: 'white', borderRadius: '7px' }
  } else if (value == 'Failed') {
    iconClassName = 'fa-regular fa-circle-xmark fa-lg';
    style = { backgroundColor: 'red', color: 'white', borderRadius: '7px' }
  } else if (value == 'Importing' || value == 'Updating') {
    iconClassName = "fas fa-sync fa-spin"
    style = {}
  } else {
    iconClassName = "fa-solid fa-arrow-up-from-bracket fa-lg"
    style = {}
  }

  return <td style={{ textAlign: 'center' }}>
    <i style={style} className={iconClassName}></i>
  </td>;
};

const DataMigration = (props) => {
  const location = useLocation();
  const mediaImportType = location.state != null
    ? MEDIA_IMPORT_ARRAY.find((x) => x.MediaCategorySID == location.state.mediaCategory) //coming from media management
    : props.importTypeId > 0 ? MEDIA_IMPORT_ARRAY.find((x) => x.Id == props.importTypeId) : { IsContentRequired: false, IsChannelRequired: false } //coming from planning or other module

  const [dataItem, setDataItem] = useState({
    MediaImportType: mediaImportType,
    Content: {},
    fileupload: "",
    GenerateAssetId: false,
    ReplacePlanning: false
  });
  const lang = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [file, setFile] = useState(undefined);
  const [selectedFile, setSelectedFile] = useState(`${lang.select_file_label}`);
  const fileRef = useRef(null);
  var statusMessage = "";
  const setStatusMessage = (data) => {
    statusMessage = data;
    document.getElementById("dataMigrationStatusMessage").innerHTML = data;
  }
  const [isContentRequired, setIsContentRequired] = useState(mediaImportType?.IsContentRequired);
  const [isChannelRequired, setIsChannelRequired] = useState(mediaImportType?.IsChannelRequired);
  const [contentsData, setContentsData] = useState([]);
  const [channels, setChannels] = useState([]);
  const [contents, setContents] = useState();
  const [showUpdateAll, setShowUpdateAll] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  var isImporting = false;

  useEffect(() => {
    loadCombo();
  }, []);

  useSocket(SOCKET_EVENTS.onProgressUpdate, (socketData) => {
    if (socketData.module != MODULE.DATA_MIGRATION) return; //no need to filter active user from module 

    switch (socketData.action) {
      case SOCKET_ACTION.PROGRESS:
        setStatusMessage(socketData?.data.message ?? '...');
        break;

      default:
        break;
    }
  })

  const loadCombo = async () => {
    var contents = await API.getDataLookup(ENTITYNAME.Content, {
      query: ["Archive", "=", false],
    });
    setContentsData(contents.data);

    var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { query: ["Archive", "=", false] });
    setChannels(channelRes.data);

    if (location.state != null) {
      var mediaType = MEDIA_IMPORT_ARRAY.find((x) => x.MediaCategorySID == location.state.mediaCategory)
      var content = contents.data.filter((item) => item.MediaCategoryType.MediaCategorySID == mediaType.MediaCategorySID)
      setContents(content)
    }
  };

  const onChange = (e) => {
    if (e.target.name == "fileupload") {
      setFile(e.target.files[0]);
      setSelectedFile(`${lang.file_selected_label}`);
      setDataItem({ ...dataItem, File: e.target.files[0].name });
    } else if (e.target.name == "MediaImportType") {
      setIsChannelRequired(e.target.value.IsChannelRequired)
      setIsContentRequired(e.target.value.IsContentRequired)
      // e.target.value.IsContentRequired
      //   ? setIsContentRequired(true)
      //   : setIsContentRequired(false);
      if (e.target.value.IsContentRequired) {
        var _contents = contentsData.filter(
          (item) =>
            item.MediaCategoryType.MediaCategorySID == e.target.value.MediaCategorySID
        );
        setContents(_contents);
      }
      setDataItem({ ...dataItem, [e.target.name]: e.target.value, Content: {} });
      setGridData([]);
    } else if (e.target.name == "GenerateAssetId") {
      setDataItem({ ...dataItem, GenerateAssetId: !dataItem.GenerateAssetId });
    } else if (e.target.name == "ReplacePlanning") {
      setDataItem({ ...dataItem, ReplacePlanning: !dataItem.ReplacePlanning });
    } else {
      setDataItem((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  //need to ask how to check the
  const onLoad = async () => {
    if (file == undefined) {
      toast.error(
        `${lang.please_select_file_first_datamigration_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }


    //template format
    if (!file.name.includes(dataItem.MediaImportType.TemplateFormat)) {
      toast.error(
        `${lang.invalid_file_format}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    if (isImporting) {
      toast.info(`${lang.import_already_in_progress}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setShowLoading(true);

    //read data
    var res = { success: false, data: null, message: '' };
    if (dataItem.MediaImportType.Description == "WhatsOn Playlist") res = await API.readWhatsOnPlaylist(file);
    else res = await API.readFile(file);

    setShowLoading(false);
    console.log(res);

    if (res.success == false) {
      toast.info(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (dataItem.MediaImportType.Description == "WhatsOn Playlist") {

      setColumns(Object.keys(res.data.data[0]));
      setGridData(res.data.data);

    }
    else if (dataItem.MediaImportType.Description.includes("Playlist") || dataItem.MediaImportType.Description.includes("Schedule")) {

      setColumns(res.data.columns);

      var gridDataModel = res.data.data.map((obj) => {
        var tempObj = {};
        for (let i = 0; i < res.data.columns.length; i++) {

          if(res.data.columns[i]?.toLowerCase() == "date"){
            tempObj = { ...tempObj, [res.data.columns[i]]: moment(utility.getLocalDateTimeToUtcMiliseconds(new Date(obj[i]))).format("YYYY-MM-DD")}
          }
          else if(res.data.columns[i]?.toLowerCase() == "et time"){
            tempObj = { ...tempObj, [res.data.columns[i]]:  utility.convertMilisecondsToStringWithFrames((new Date(obj[i])).getTime() + 19270000)}
          }
          else{
            tempObj = { ...tempObj, [res.data.columns[i]]: obj[i.toString()] }
          }
          
        }
        return tempObj;
      });

      setGridData(gridDataModel);
    }


    else if (dataItem.MediaImportType.Description.includes("BossWindow")) {

      console.log(res.data.data);
      var gridDataModel = res.data.data.map((obj) => {
        var tempObj = {};
        for (let i = 0; i < res.data.columns.length; i++) {
          tempObj = { ...tempObj, [res.data.columns[i]]: obj[i.toString()] }
        }
        return tempObj;
      });

      console.log(gridDataModel);

      //to show read file in grid
      setColumns(res.data.columns);
      setGridData(gridDataModel);
    }

    else {

      //CHECK VALID TEMPLATE
      var temp = [];
      var missing = [];
      if (res.data.columns.length > 0) {
        dataItem.MediaImportType.TemplateMandatoryFields.map((col) => {
          if (res.data.columns.includes(col)) {
            temp.push(col)
          } else {
            missing.push(col)
          }
        })
      }
      const isValidTemplate = temp.length == dataItem.MediaImportType.TemplateMandatoryFields.length
      console.log(isValidTemplate);

      if (isValidTemplate) {
        var gridDataModel = [];
        if (file.name.slice(file.name.length - 3, file.name.length) == "csv") {
          //setting grid Data for csv type file
          gridDataModel = res.data.data.map((obj) => {
            return { ...obj, success: 'Ready to import', message: "Ready to import", importStatus: IMPORT_STATUS.Pending };
          });
        } else {
          //setting grid Data for xlsx type file
          gridDataModel = res.data.data.map((obj) => {
            var tempObj = {};

            for (let i = 0; i < res.data.columns.length; i++) {
              tempObj = {
                ...tempObj,
                [res.data.columns[i]]: obj[i.toString()],
                success: 'Ready to import',
                message: "Ready to import",
                importStatus: IMPORT_STATUS.Pending
              };
            }

            return tempObj;
          });
        }

        //to show read file in grid
        setColumns(res.data.columns);
        setGridData(gridDataModel);
        setShowLoading(false);
      } else {
        toast.error(`${lang.invalid_template_file}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setGridData([]);
        setShowLoading(false);
        return;
      }



    }


  };

  const onImport = async () => {
    if (gridData == undefined || gridData.length == 0) {
      toast.error(`${lang.empty_media_grid_datamigration_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (isImporting) {
      toast.info(`${lang.import_already_in_progress}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    isImporting = true;

    // this is just special case for keeping code as we are not giving access to user for improting playlist.
    // by vishal
    if (!dataItem.MediaImportType.Description == "Playlist") {

      //changing status on import click
      setGridData(gridData.map((item) => { return { ...item, success: 'Importing', message: 'Importing please wait' } }))

      if (dataItem.MediaImportType == undefined || Object.keys(dataItem.MediaImportType).length == 0) {
        toast.error(`${lang.please_select_type_datamigration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        isImporting = false;
        return;
      }

      if (dataItem.GenerateAssetId && Object.keys(dataItem.Content).length == 0) {
        toast.error(`${lang.please_select_content_error_message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        isImporting = false;
        return;
      }

    }


    var res = {};

    switch (dataItem.MediaImportType.Description) {
      case "Planning":
        var response = await importPlanning();
        if (response.success) {
          console.log(response);
          res = response
        } else {

          toast.info(`${response.message}`,
            { position: toast.POSITION.TOP_RIGHT }
          );
        }
        break;

      case "Segmentation":
        //NEED to Implement
        console.log('NEED TO Implement')

        break;

      case "Playlist":
        var payload = {
          data: gridData,
          channel: dataItem.Channel,
          importRevised: dataItem.ReplacePlanning
        };

        res = await API.ImportPlaylist(
          payload,
          dataItem.MediaImportType
        );
        console.log(res);
        var duplicatedData = res.data.filter(obj => obj.importStatus == IMPORT_STATUS.Duplicate)
        duplicatedData.length > 1 && setShowUpdateAll(true);
        break;

        case "Schedule":
        var payload = {
          data: gridData,
          channel: dataItem.Channel,
          importRevised: dataItem.ReplacePlanning
        };

        res = await API.ImportPlaylist(
          payload,
          dataItem.MediaImportType
        );
        console.log(res);
        var duplicatedData = res.data.filter(obj => obj.importStatus == IMPORT_STATUS.Duplicate)
        duplicatedData.length > 1 && setShowUpdateAll(true);
        break;

      case "WhatsOn Playlist":

        var payload =
        {
          data: gridData,
          channel: dataItem.Channel,
          importRevised: dataItem.ReplacePlanning
        }
        setStatusMessage('importing playlist please wait...')
        res = await API.importWhatsOnPlaylist(payload);
        setStatusMessage(res.message)
        break;

      case "BossWindowSalesExecutives":
          var payload =
          {
            data: gridData,
            
          }
          setStatusMessage('importing Sales Executives,  please wait...')
          res = await API.ImportSalesData(payload,dataItem.MediaImportType);
          setStatusMessage(res.message);
        break;

        case "BossWindowAgency":
          var payload =
          {
            data: gridData,
            
          }
          setStatusMessage('importing Agencies,  please wait...')
          res = await API.ImportSalesData(payload,dataItem.MediaImportType);
          setStatusMessage(res.message);
        break;

        case "BossWindowClient":
          var payload =
          {
            data: gridData,
            
          }
          setStatusMessage('importing Clients,  please wait...')
          res = await API.ImportSalesData(payload,dataItem.MediaImportType);
          setStatusMessage(res.message);
        break;

        case "BossWindowCampaign":
          var payload =
          {
            data: gridData,
            
          }
          setStatusMessage('importing Campaigns,  please wait...')
          res = await API.ImportSalesData(payload,dataItem.MediaImportType);
          console.log(res);
          setStatusMessage(res.message);
        break;




      default://FOR MEDIA MANAGEMNET
        //payload
        var payload = {
          data: gridData,
          MediaImportType: dataItem.MediaImportType,
          GenerateAssetId: dataItem.GenerateAssetId,
          Content: dataItem.GenerateAssetId ? dataItem.Content : {},
          UpdateMedia: false
        };

        res = await API.ImportMediaManagementDataMigration(
          payload,
          dataItem.MediaImportType
        );
        console.log(res);
        var duplicatedData = res.data.filter(obj => obj.importStatus == IMPORT_STATUS.Duplicate)
        duplicatedData.length > 1 && setShowUpdateAll(true);
        break;
    }

    if (res.success) {
      setGridData(res.data);
      setDataItem({
        // MediaImportType: location.state != null || props.importTypeId > 0 ? dataItem.MediaImportType : {},
        MediaImportType: dataItem.MediaImportType,
        Content: {},
        Channel: {},
        fileupload: "",
        File: "",
        GenerateAssetId: false,
        ReplacePlanning: false,
      });
      setSelectedFile(`${lang.select_file_label}`);
      isImporting = false;
      toast.success(
        `${dataItem.MediaImportType.Description} ${lang.imported_successfully_datamigration_success_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
    else {
      toast.error(
        res.message,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      isImporting = false;

    }
  };

  const importPlanning = async () => {
    var planningItems = []; //FOR VALID ROW
    var inValidRows = [];
    var rowData = [];


    if (dataItem.Channel == undefined) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }


    //CHECK IF THE READ DATA HAS TIME,DATE and HOUSENUMBER COLUMNS
    if (!columns.includes("Date") || !columns.includes("Time") || !columns.includes("HouseNumber")) {
      toast.error(`${lang.date_time_houseNumber_column_is_mandatory}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // ADDING ONLY VALID ROWS
    gridData.forEach((item) => {
      //check valid date format
      if (item.Date.length == 0 && !moment(item.Date, "YYYY-MM-DD", true).isValid()) {
        inValidRows.push({ ...item, success: 'Failed', message: 'missing/wrong date format', importStatus: IMPORT_STATUS.MissingValue });
        rowData.push({ ...item, success: 'Failed', message: 'missing/wrong date format', importStatus: IMPORT_STATUS.MissingValue });

        //checking valid time format
        //TODO //item.Time.length == 0 && !moment(item.Time, 'hh:mm:ss', true).isValid()
      } else if (item.Time.length == 0) {

        console.log(item.Time);
        inValidRows.push({ ...item, success: 'Failed', message: 'missing/wrong time format', importStatus: IMPORT_STATUS.MissingValue });
        rowData.push({ ...item, success: 'Failed', message: 'missing/wrong time format', importStatus: IMPORT_STATUS.MissingValue });

        //checking if assetId missing
      } else if (item.HouseNumber.length == 0) {

        inValidRows.push({ ...item, success: 'Failed', message: 'missing House Number', importStatus: IMPORT_STATUS.MissingValue });
        rowData.push({ ...item, success: 'Failed', message: 'missing House Number', importStatus: IMPORT_STATUS.MissingValue });

      } else {
        rowData.push({ ...item, success: 'Importing', message: 'Importing please wait', importStatus: IMPORT_STATUS.Pending });
        var scheduleDate = utility.getLocalDateTimeToUtcMiliseconds(new Date(item.Date));
        var slotDateTime = utility.getLocalDateTimeToUtcMiliseconds(new Date(`${item.Date} ${item.Time}`));
        var assetId = item.HouseNumber;

        var planning = {
          Channel: dataItem.Channel,
          ScheduleDate: scheduleDate,
          SlotDateTime: slotDateTime,
          AssetId: assetId,
        };

        planningItems.push(planning);
      }
    });

    setGridData(rowData);

    //boolean to replicate data 
    var planningResponse = await API.importPlanning(planningItems, dataItem.ReplacePlanning);
    console.log({ ...planningResponse, data: planningResponse.MediaNotFound })
    return { ...planningResponse, data: planningResponse.MediaNotFound };
  };

  const DownloadDataTemplate = () => {
    if (dataItem.MediaImportType.Description == "" || dataItem.MediaImportType.Description == undefined) {
      toast.error(lang.please_select_import_type_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    try {
      switch (dataItem.MediaImportType.Description) {
        case "Series":
          var filePath =
            FILEURL.BASEURL + "downloadTemplate/" + "ImportSeriesTemplate.xlsx";
          window.open(filePath);
          break;

        case "Season":
          var filePath =
            FILEURL.BASEURL + "downloadTemplate/" + "ImportSeasonTemplate.xlsx";
          window.open(filePath);
          break;

        case "Program":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportProgramTemplate.xlsx";
          window.open(filePath);
          break;

        case "Movie":
          var filePath =
            FILEURL.BASEURL + "downloadTemplate/" + "ImportMovieTemplate.xlsx";
          window.open(filePath);
          break;

        case "Episode":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportEpisodeTemplate.xlsx";
          window.open(filePath);
          break;

        case "Interstitial":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportInterstitialTemplate.xlsx";
          window.open(filePath);
          break;

        case "Commercial":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportCommercialTemplate.xlsx";
          window.open(filePath);
          break;

        case "Filler":
          var filePath =
            FILEURL.BASEURL + "downloadTemplate/" + "ImportFillerTemplate.xlsx";
          window.open(filePath);
          break;

        case "Music":
          var filePath =
            FILEURL.BASEURL + "downloadTemplate/" + "ImportMusicTemplate.xlsx";
          window.open(filePath);
          break;

        case "Planning":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportPlanningTemplate.csv";
          window.open(filePath);
          break;

        case "Segmentation":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportSegmentationTemplate.xlsx";
          window.open(filePath);
          break;

        case "Playlist":
          var filePath =
            FILEURL.BASEURL +
            "downloadTemplate/" +
            "ImportPlaylistTemplate.xlsx";
          window.open(filePath);
          break;

        default:
          break;
      }
    } catch (error) {
      toast.error(`${lang.error_downloading_template_booking_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onUpdate = async (data) => {

    if (data.length == 0) {
      data = gridData.filter(item => item.importStatus == IMPORT_STATUS.Duplicate)
    }

    //changing status on import click
    setGridData(gridData.map((item) => { return { ...item, success: 'Updating', message: 'Upadating please wait' } }))

    //payload
    var payload = {
      data: data,
      MediaImportType: dataItem.MediaImportType,
      GenerateAssetId: dataItem.GenerateAssetId,
      Content: dataItem.GenerateAssetId ? dataItem.Content : {},
      UpdateMedia: true
    };

    var res = await API.ImportMediaManagementDataMigration(
      payload,
      dataItem.MediaImportType
    );
    console.log(res);
    if (res.success) {
      setGridData(res.data);
      setDataItem({
        MediaImportType: location.state != null || props.importTypeId > 0 ? dataItem.MediaImportType : {},
        Content: {},
        fileupload: "",
        File: "",
        GenerateAssetId: false,
      });
      setSelectedFile(`${lang.select_file_label}`);
      setShowUpdateAll(false)
      toast.success(`${dataItem.MediaImportType.Description} ${lang.imported_successfully_datamigration_success_message}`,
        { position: toast.POSITION.TOP_RIGHT }
      );
    }
  }

  const UpdateButtonCell = (props) => {

    const field = props.field || "";
    let value = props.dataItem[field];
    let success = props.dataItem.success;

    return <td>
      {value}
      {success == 'Failed' && props.dataItem.importStatus == IMPORT_STATUS.Duplicate &&
        <ActionButton name={lang.update_button_text} style={{ height: '25px', padding: '3px 5px' }} onClick={() => onUpdate([props.dataItem])} />}
    </td>;
  };

  return (
    <div className="row mt-2">
      <div className="mr-3" style={{ padding: "0px 0px 0px 15px" }}>
        <div style={{ display: "flex" }}>
          <label className="mt-1 mr-1" htmlFor="TabView">
            {lang.type_label} :
          </label>
          <div className=" form-group">
            <DropDownList
              style={{
                backgroundColor: "white",
                width: "160px",
              }}
              data={MEDIA_IMPORT_ARRAY}
              name="MediaImportType"
              textField="Description"
              dataItemKey="Id"
              value={dataItem.MediaImportType}
              onChange={onChange}
              disabled={location.state != null || props.importTypeId > 0}
              validator={(value) => (value ? "" : "Please select the value")}
            />
          </div>
        </div>
      </div>
      {isContentRequired && (
        <div className="mt-1 mr-2" style={{ display: "flex" }}>
          <label className="mt-0 mr-2" htmlFor="TabView">
            {"Generate AssetID :"}
          </label>
          <div className=" form-group">
            <input
              name={"GenerateAssetId"}
              type={"Checkbox"}
              value={dataItem.GenerateAssetId}
              onChange={onChange}
              checked={dataItem.GenerateAssetId}
              style={{
                marginLeft: "00px",
                marginRight: "5px",
              }}
            />
          </div>
        </div>
      )}
      {isContentRequired && dataItem.GenerateAssetId && (
        <div style={{ display: "flex" }}>
          <label className="mt-1" htmlFor="TabView">
            {lang.content_label} <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
          </label>
          <div className=" form-group">
            <DropDownList
              style={{
                backgroundColor: "white",
                width: "160px",
              }}
              data={contents}
              name="Content"
              textField="Description"
              value={dataItem.Content}
              onChange={onChange}
            />
          </div>
        </div>
      )}

      {isChannelRequired && (<>
        <div className="mt-1 mr-2" style={{ display: "flex" }}>
          <label className="mt-0 mr-2" htmlFor="TabView">
            {lang.replace_existing_planning_label} :
          </label>
          <div className=" form-group">
            <input
              name={"ReplacePlanning"}
              type={"Checkbox"}
              value={dataItem.ReplacePlanning}
              onChange={onChange}
              checked={dataItem.ReplacePlanning}
              style={{
                marginLeft: "00px",
                marginRight: "5px",
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <label className="mt-1 mr-2" htmlFor="TabView">
            {lang.channel_label} :
          </label>
          <div className=" form-group">
            <DropDownList
              style={{
                backgroundColor: "white",
                width: "160px",
              }}
              data={channels}
              name="Channel"
              textField="FullChannelName"
              value={dataItem.Channel}
              onChange={onChange}
            />
          </div>
        </div>
      </>)}

      <div className="col-2" style={{ padding: "0px 0px 0px 10px" }}>
        <div className="form-group">
          <input
            name="File"
            type="text"
            className="form-control form-control-sm"
            value={dataItem.File}
            onChange={onChange}
            disabled={true}
          />
        </div>
      </div>
      <div className="col-4">
        <label
          className="custom-file-updated-label"
          style={{ margin: "0px", height: "32px" }}
        >
          <input
            ref={fileRef}
            type="file"
            name="fileupload"
            className="custom-file-updated-input"
            hidden="hidden"
            id="fileupload"
            onChange={onChange}
          />
          {selectedFile}
        </label>
        <ActionButton
          name={lang.load_button_text}
          style={{ padding: "5px 12px" }}
          onClick={onLoad}
        />
        <ActionButton
          name={lang.import_file_label}
          style={{ padding: "5px 12px" }}
          onClick={onImport}
          btnColor={"warning"}
        />
        {showUpdateAll && <ActionButton
          name={lang.update_all_button_text}
          style={{ padding: "5px 12px" }}
          btnColor={"success"}
          // onClick={() =>  onUpdate([])}
          onClick={() => ConfirmAlert(
            () => onUpdate([]),
            () => { },
            "Confirm",
            "Are you sure, You want to update all ? ")}
        />}
        <button
          type="button"
          title={lang.download_template_tooltip}
          onClick={DownloadDataTemplate}
          className={`btn btn-default bg-primary btn-circle btn-my ml-2`}
        >
          <i
            className={`fa fa-download fa-fw img-circle`}
            style={{ color: "white" }}
          ></i>
        </button>
      </div>

      <div className="ml-3">
        {!showLoading ? <Grid data={gridData} style={{ height: "86vh", width: "85vw" }}>
          {columns.map((column, index) => {
            return (
              <GridColumn
                key={Math.floor(100 + Math.random() * 900)}
                field={column}
                title={column}
                width={200}
              />
            );
          })}
          {gridData.length > 0 && (
            <GridColumn
              key={1111}
              field={"success"}
              title={"Status"}
              width={70}
              cell={DataMigrationStatusCell}
            />
          )}
          {gridData.length > 0 && (
            <GridColumn
              key={1212}
              field={"message"}
              title={"Message"}
              width={300}
              cell={UpdateButtonCell}
            />
          )}
        </Grid>
          :
          <div className="ml-1 mt-2">
            <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
          </div>}
      </div>
      <div
        className="scheduleFooter ml-3"
        style={{
          width: "85%",
          height: "4%",
          background: " #3a3633",
        }}
      >
        <div className="row ml-2">
          {/* Selected Count */}
          <div className="col-6">
            <div className="flex-container">
              <div title="Copy Count" className="iconBase2">
                <span
                  style={{
                    color: "white",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  &nbsp;{lang.selected_label}
                </span>
              </div>
            </div>
          </div>

          {/* status */}
          <div className="col-6 mt-2" style={{ textAlign: "right" }}>
            <span
              id="dataMigrationStatusMessage"
              style={{
                fontWeight: "400",
                fontFamily: "Roboto",
                fontSize: "12px",
                color: "#ffffff",
              }}
            >
              {statusMessage}
            </span>
          </div>
        </div>
      </div>
      <ProgressBarPopup ModuleID={MODULE.DATA_MIGRATION} />
    </div>
  );
};

export default DataMigration;
