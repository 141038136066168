import { useState, useEffect } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import { toast } from "react-toastify";
import MetaDataTab from "./component/metaDataTab";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import OttAssesTab from "./component/ottAssesTab";
import VersionTab from "./component/versionTab";
import PublishingTab from "./component/publishingTab";
import AttachMediaTab from "./component/attachMediaTab";
import { utility } from "../../../framework/utility/utilityProvider";
import { MediaEpisodeDetailForm } from "../MediaEpisodeDetailForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SeriesDetailForm from "../SeriesDetailForm";
import SeasonDetailForm from "../SeasonDetailForm";
import BossDialog from "../../../components/BossDialog";
import { MediaEpisodeInterstitialEditForm } from "../../Interstitial/MediaEpisodeInterstitialEditForm";
import { DataHelper } from "../../../framework/helper/DataHelper";

const ManageContentEditPanel = (props) => {

    const { transcodeStatus, mediaCategory = {}, entityName, gridData = [], setGridData, selectedItem = {}, setSelectedItem } = props;

    const [showContentEdit, setShowContentEdit] = useState({
        media: false,
        series: false,
        season: false,
        isShort: false
    });
    const [tabNumber, setTabNumber] = useState(mediaCategory?.isLong ? "1" : "4");
    const [tempData, setTempData] = useState({});
    const isHideVersion = entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season || !mediaCategory?.isLong;

    useEffect(() => {
        setTempData(selectedItem)
    }, [selectedItem]);

    const handleTabChange = (event, newValue) => {
        setTabNumber(newValue);
    };

    const onMediaEdit = () => {
        if(entityName == ENTITYNAME.Series) {
            setShowContentEdit({ ...showContentEdit, series: true });
        }else if(entityName == ENTITYNAME.Season) {
            setShowContentEdit({ ...showContentEdit, season: true });
        }else if(!mediaCategory?.isLong) {
            setShowContentEdit({ ...showContentEdit, isShort: true });
        }else{
            setShowContentEdit({ ...showContentEdit, media: true });
        }
    }

    const tabStyle = {
        overflow: 'auto',
        height: "40vh",
        padding: '0px 10px 0px 10px'
    }

    const onContentCancel = () => {
        setShowContentEdit({ media: false, series: false, season: false, isShort: false});
    }

    const handleContentSave = async () => {
        let data = {}
        if (entityName == ENTITYNAME.Series) {
            data = DataHelper.saveSeriesEntity({ ...tempData, SID: parseInt(tempData.SID) }, [mediaCategory], tempData.IsOttAsset, tempData?.ImageUrl);  
        } else if (entityName == ENTITYNAME.Season) {
            data = DataHelper.saveSeasonEntity({ ...tempData, SID: parseInt(tempData.SID) }, [mediaCategory], tempData.IsOttAsset, null, tempData?.ImageUrl);
        } else{
            data = DataHelper.saveMediaEpisodeEntity({ ...tempData, SID: parseInt(tempData.SID) }, mediaCategory, tempData.IsOttAsset);
        }
        console.log(data);
        let res = await API.saveData(entityName, data);

        if (!res.success) {
            toast.error('Failed to save series');
            return;
        }

        setSelectedItem(tempData);
        let updatedData = gridData.map(x => {
            if (x._id == selectedItem._id) {
                return data
            }
            return x
        })
        setGridData(updatedData);
        onContentCancel();

    }

    const setInterstitialData = (data) => {
        setSelectedItem(data);
        let updatedData = gridData.map(x => {
            if (x._id == selectedItem._id) {
                return data
            }
            return x
        })
        setGridData(updatedData);
    }

    return (
        <div style={{ position: "absolute", padding: "10px", top: "91px", right: 0, width: "500px", height: "76%", backgroundColor: "white", transition: 'all 0.3s linear' }} >
            <div style={{ display: "flex", marginBottom: "10px", marginTop: "10px", height: "180px" }}>
                <img src={selectedItem?.ImageUrl} onError={
                    (e) => {
                        e.target.onerror = null;
                        e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
                    }
                } style={{ height: "165px", minWidth: "180px", maxWidth: "180px", borderRadius: "5px", objectFit: "cover" }} className="card-img-top" alt={selectedItem?.Title} />
                <div className="ml-3" style={{ height: "140px", width: "100%" }}>
                    <div className="flex-container">
                        <h1 style={{ marginTop: '0px' }}>{utility.subString(selectedItem?.Title, 15)}</h1>
                        <div className="flex-container-reverse flex-item-auto">
                            <RoundButton icon={"close"} title={"Close"} onClick={() => setSelectedItem({})} />
                            <RoundButton icon={'pen'} title={'Edit'} onClick={onMediaEdit} />
                        </div>
                    </div>
                    <p className="pl-1 mb-1">{selectedItem?.AssetId}</p>
                    <p className="pl-1 mb-1">{selectedItem?.Description?.length > 90 ? selectedItem?.Description?.substring(0, 90) + '...' : selectedItem?.Description}</p>
                    <p className="pl-1 mb-1">{utility.displayArrayInPipe(selectedItem?.Genres, 'Description')}</p>
                    {mediaCategory?.isLong && <><div style={{ display: "flex" }}>
                        <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                            Transcode Status :
                        </p>
                        <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: transcodeStatus == 'Complete' ? 'green' : "grey", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{transcodeStatus}</p>
                    </div>
                        <div style={{ display: "flex" }}>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                                Is Ott Asset :
                            </p>
                            <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: selectedItem?.IsOttAsset ? 'green' : "Red", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{selectedItem?.IsOttAsset ? "Yes" : "No"}</p>
                        </div></>}
                </div>
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabNumber}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label="lab API tabs example"
                            variant="scrollable"
                        >
                            {mediaCategory?.isLong && <Tab label="Meta Data" value="1" />}
                            {mediaCategory?.isLong && <Tab label="OTT Assets" value="2" />}
                            {!isHideVersion && <Tab label="Version" value="3" />}
                            <Tab label="Publishing" value="4" />
                            {mediaCategory?.isLong && <Tab label="Attach Media" value="5" />}
                        </TabList>
                    </Box>
                    {/* Meta Data */}
                    {mediaCategory?.isLong && <TabPanel value="1" style={tabStyle}>
                        <MetaDataTab entityName={entityName} mediaEpisode={selectedItem} gridData={gridData} setGridData={setGridData} />
                    </TabPanel>}
                    {/* OTT Assets */}
                    {mediaCategory?.isLong && <TabPanel value="2" style={tabStyle}>
                        <OttAssesTab entityName={entityName} setMediaEpisode={setSelectedItem} mediaEpisode={selectedItem} gridData={gridData} setGridData={setGridData} />
                    </TabPanel>}
                    {/* Version */}
                    {!isHideVersion && <TabPanel value="3" style={tabStyle}>
                        <VersionTab mediaEpisode={selectedItem} />
                    </TabPanel>}
                    {/* Publishing */}
                    <TabPanel value="4" style={tabStyle}>
                        <PublishingTab mediaEpisode={selectedItem} mediaCategory={mediaCategory} gridData={gridData} setGridData={setGridData}/>
                    </TabPanel>
                    {/* Attach Media */}
                    {mediaCategory?.isLong && <TabPanel value="5" style={tabStyle}>
                        <AttachMediaTab mediaEpisode={selectedItem} />
                    </TabPanel>}
                </TabContext>
            </Box>
            {showContentEdit.series && <SeriesDetailForm isManageContent={true} dataItem={tempData} setDataItem={setTempData} onSave={handleContentSave} onCancelEdit={onContentCancel} />}
            {showContentEdit.season && <SeasonDetailForm isManageContent={true} dataItem={tempData} setDataItem={setTempData} onSave={handleContentSave} onCancelEdit={onContentCancel} /> }
            {showContentEdit.isShort && <BossDialog width="75vw" title="Edit Interstitial" onClose={onContentCancel}>
                <MediaEpisodeInterstitialEditForm mediaCategory={mediaCategory?.SID} isManageContent={true} item={tempData} setDataItem={setInterstitialData} onCancelEdit={onContentCancel} />
            </BossDialog> }
            {showContentEdit.media && <MediaEpisodeDetailForm isManageContent={true} mediaCategory={mediaCategory?.SID} mediaCategoryType={selectedItem?.MediaCategoryType?.SID} dataItem={tempData} setDataItem={setTempData} onSave={handleContentSave} onCancelEdit={onContentCancel} />}
        </div>
    )
};

export default ManageContentEditPanel;