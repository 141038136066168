/* eslint-disable */
import React, { useRef, useState } from "react";
import * as API from "../../../src/framework/API/api";
import { useEffect } from "react";
import { ENTITYNAME, LOCALSTORAGE_KEY, ROLETYPE, SOCKET_EVENTS, WORKORDER_STATUS } from "../../framework/constant/constant";
import SalesDashboardTile from "./SalesDashboardTile";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useTranslation } from "../../locale/useTranslation";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { WorkOrderEditForm } from "../Work Order/WorkOrderEditForm";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { utility } from "../../framework/utility/utilityProvider";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import MyStatusCell from "../../../src/components/MyStatusCell";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import useSocket from "../../framework/socket/useSocket";

const WorkOrderDashboard = () => {

    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);

    const [tileData, setTileData] = useState({
        ToDo : 0,
        InProgress : 0,
        Done: 0
    });
    const [showEditForm,setShowEditForm] = useState(false);
    const [gridData, setGridData] = useState([]);

    const [workOrderGridData, setWorkOrderGridData] = useState([]);
    const [roleWiseData,setRoleWiseData] = useState([]);
    const [userWiseData,setUserWiseData] = useState([]);

    const [archive,setArchive] = useState(false);

    const workOrderItem = useRef();
    const setWorkOrderItem = (data) => {
        workOrderItem.current = data ;
    } 

    const lang = useTranslation();

    useEffect(() => {
        loadData();
    }, [])

      //socket
      const socket = useSocket(SOCKET_EVENTS.onSocketNotification, (socketData) => {

        console.log(currentUser)
        
        var checkRoleInCurrentUser = currentUser.Role.some((x) => x.SID == socketData.reciever.role.SID);
        console.log(checkRoleInCurrentUser)
        // console.log(socketData);

        if(socketData.data.length != 0 && checkRoleInCurrentUser && (socketData.reciever.user.SID == 0 || socketData.reciever.user.SID == currentUser.SID)){
            loadData();
        }
  
    });


    const loadData = async (archive = false) => {

        console.log(archive);

        
        var res = {};

        if(currentUser.RoleType.ID == ROLETYPE.SuperAdmin || currentUser.RoleType.ID == ROLETYPE.Admin){
            res = await API.getData(ENTITYNAME.WorkOrder,{ query: ["Archive", "=", archive] });

        }else{
            //FOR ALL USER (SID = 0)
            var resALL = await API.getData(ENTITYNAME.WorkOrder,{ query: [["User.SID","=",0],["Archive", "=", archive]] });

            //FILTER from resALL of this role only
            var filtered = [];
            if(currentUser.Role.length > 0){
                currentUser.Role.map((item) => {
                    var temp = resALL.data.filter((x) => x.CurrentRole.SID == item.SID);
                    if(temp.length > 0){
                        filtered.push(...temp);
                    }
                })
            }
            //FOR CURRENT USER
            var response = await API.getData(ENTITYNAME.WorkOrder,{ query: [["User.SID","=",currentUser.SID],["Archive", "=", archive]] });

            res = {...response,data:[...response.data,...filtered]}
        }

        setArchive(archive)

        if(res.success){
            var todoCount = res.data.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[0].ID).length;
            var inProgressCount = res.data.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[1].ID).length;
            var doneCount = res.data.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[2].ID).length;

            setTileData({
                ToDo : todoCount,
                InProgress : inProgressCount,
                Done: doneCount
            })
            setWorkOrderGridData(res.data);
            setGridData(res.data);
            // console.log(res.data)
        }else{
            console.log(res.message);
        }

        if(currentUser.RoleType.ID == ROLETYPE.SuperAdmin || currentUser.RoleType.ID == ROLETYPE.Admin){

            var rolewisedata = await API.getRoleWiseWorkOrder();

            var roleDataToShow = rolewisedata.data.map((item) => {
                var todoCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[0].ID).length;
                var inProgressCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[1].ID).length;
                var doneCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[2].ID).length;

                return {
                    ...item,
                    ToDo : todoCount,
                    InProgress : inProgressCount,
                    Done : doneCount
                }
            })

            setRoleWiseData(roleDataToShow)

            var userwisedata = await API.getUserWiseWorkOrder();

            var userDataToShow = userwisedata.data.map((item) => {
                var todoCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[0].ID).length;
                var inProgressCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[1].ID).length;
                var doneCount = item.WorkOrder.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[2].ID).length;

                return {
                    ...item,
                    ToDo : todoCount,
                    InProgress : inProgressCount,
                    Done : doneCount
                }
            })
            setUserWiseData(userDataToShow);
        }

    };

    const onAddItem = () => {
        setWorkOrderItem({SID : 0});
        setShowEditForm(true);
    }

    const onEditItem = (dataItem) => {
        setWorkOrderItem(dataItem);
        setShowEditForm(true);
    }

    const onArchive = async (dataItem) => {
        //COMMENTED NOW
        var resArchive = await API.saveData(ENTITYNAME.WorkOrder, {_id : dataItem._id ,Archive : true});
        if(resArchive.success){
            loadData();
            return;
        }else{
          toast.error(resArchive.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    }

    const MyCommandCell = (props) => (
        <div className="mt-2" style={{ display: "flex" }}>
          <EditRoundButton onClick={() => onEditItem(props.dataItem)} />
          {/* <RoundButton icon={'inbox'} title={lang.archive} onClick={() => onArchive(props.dataItem)}/> */}
        </div>
    )

    const MyHrefCell = (props) => (
        <HrefCell {...props} />
    );

    const StatusCell = (props) => (
        <MyStatusCell {...props} 
            title={props.dataItem.CurrentStatus.Description} 
            titleColor = '#ffffff'
            titleFontSize = '12px'
            tileBackColor={props.dataItem.CurrentStatus.Description == 'To Do' ? "#f58d05" : props.dataItem.CurrentStatus.Description == 'Done' ? "#1ca666" : '#5C469C'} 
        />
    )

    const filterWorkOrder = (currentStatusIndex) => {
        var filterByStatusData = gridData.filter((x) => x.CurrentStatus.ID == WORKORDER_STATUS[currentStatusIndex].ID);
        setWorkOrderGridData(filterByStatusData);
    }

    return (
        <>
            <div className="widget-body">
                <div className="row" style={{ marginLeft: "3px" }}>
                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                        <SalesDashboardTile
                            iconBackColor="#f58d05"
                            bottom="5px"
                            count={tileData.ToDo}
                            icon="list-ul"
                            status={lang.todo_tile_label}
                            description={lang.view_details_label}
                            onClick={() => {filterWorkOrder(0)}}
                        />

                    </div>
                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                        <SalesDashboardTile
                            iconBackColor="#5C469C"
                            bottom="5px"
                            count={tileData.InProgress}
                            icon="sync"
                            status={lang.in_progress_tile_label}
                            description={lang.view_details_label}
                            onClick={() => {filterWorkOrder(1)}}
                        />
                    </div>
                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                        <SalesDashboardTile
                            iconBackColor="#1ca666"
                            bottom="5px"
                            count={tileData.Done}
                            icon="circle-check"
                            status={lang.done_tile_label}
                            description={lang.view_details_label}
                            onClick={() => {filterWorkOrder(2)}}
                        />
                    </div>

                </div>
                <div className="row">
                    <div className="col-6">
                        <AddRoundButton onClick={onAddItem} style= {{marginBottom : '10px'}} />
                        <RefreshButton onClick={() => setWorkOrderGridData(gridData)} style= {{margin : '0px 0px 10px 5px' ,}} />
                    </div>
                    <div className="col-6 text-right mt-3">
                        <input type = 'checkbox' onChange={() => loadData(!archive)} name='Archive' checked={archive} />
                        <label className="pl-2" htmlFor="TabView">{lang.archive}</label> 
                    </div>
                </div>
                <div className="row p-1 dashboard-title-style" style={{marginLeft : '3px'}}>{lang.work_orders_label}</div>
                <div className="row" style={{ marginLeft: "3px" }}>
                    <Grid data={workOrderGridData} 
                        style= {{ height: (currentUser.RoleType.ID == ROLETYPE.SuperAdmin || currentUser.RoleType.ID == ROLETYPE.Admin) ? "25vh" :'55vh'}} 
                    >
                        <Column cell={MyCommandCell} width={"40px"} locked={true} />
                        <Column field="Title" title={lang.title_label} editable={false} />
                        <Column field="CurrentStatus.Description" title={lang.current_status_column} width={"120px"} editable={false} cell={StatusCell} />
                        <Column field="Workflow.Name" title={lang.workflow_label} editable={false} />
                        <Column field="CurrentRole.Name" title={lang.department_label} editable={false} />
                        <Column field="Media.Title" title={lang.media_column} editable={false} cell={MyHrefCell} />
                        <Column field="User.name" title={lang.user_column} editable={false} />
                        <Column field="CreatedDate" title={lang.created_date_column} editable={false} cell={DateCell} />
                    </Grid>
                </div>
                {(currentUser.RoleType.ID == ROLETYPE.SuperAdmin || currentUser.RoleType.ID == ROLETYPE.Admin) && <div className="row mt-3">
                    <div className="col-6">
                    <div className="p-1 dashboard-title-style">{lang.role_wise_work_orders_label}</div>
                        <Grid style={{ height: "25vh" }} data={roleWiseData}>
                            <Column field="Name" title={lang.name_label} editable={false} />
                            <Column field="Description" title={lang.description_label} editable={false} />
                            <Column field="ToDo" title={lang.to_do_column} editable={false} />
                            <Column field="InProgress" title={lang.in_progress_column} editable={false} />
                            <Column field="Done" title={lang.done_column} editable={false} />
                        </Grid>
                    </div>

                    <div className="col-6">
                    <div className="p-1 dashboard-title-style" >{lang.user_wise_work_orders_label}</div>
                        <Grid style={{ height: "25vh" }} data={userWiseData}>
                            <Column field="name" title={lang.name_label} editable={false} />
                            <Column field="Department.Description" title={lang.department_label} editable={false} />
                            <Column field="ToDo" title={lang.to_do_column} editable={false} />
                            <Column field="InProgress" title={lang.in_progress_column} editable={false} />
                            <Column field="Done" title={lang.done_column} editable={false} />
                        </Grid>
                    </div>
                </div>}
            </div>
            {showEditForm && <WorkOrderEditForm title={lang.work_order_button_tooltip} entityName = {ENTITYNAME.WorkOrder} item = {workOrderItem.current} refresh={loadData} cancelEdit={() => setShowEditForm(false)}/>}
        </>
    );
};

export default WorkOrderDashboard;
