/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import * as API from '../../framework/API/api'
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import {DAYS, ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from '../../ConfirmAlert'
import { utility } from "../../framework/utility/utilityProvider";
import SaveButton from "../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { DateCell, DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import moment from "moment";
import BossDialog from "../../components/BossDialog";

function PrototypeRestrictionEditForm(props) {

  let initialValue = {
    Channel: {},
    ValidDays: [],
    FromDate: "",
    ToDate: "",
  }
  const [dataItem, setDataItem] = useState(initialValue);
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
  selectedIndexRef.current = data;
   }
  const [channel, setChannel] = useState([]);
  const [i, setI] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const lang = useTranslation();
  const [prototypeRestriction, setPrototypeRestriction] = useState([]);

  useEffect(() => {
    loadcombo();
  }, []);

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }
  const loadcombo = async () => {  
    var channelRes = await API.getDataLookup(ENTITYNAME.Channel, {sort: { FullChannelName: 1 }});
    setChannel(channelRes.data);
  }

  const isValid = () => {
    if (!dataItem.Channel || Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
      });
      return false;
  }
  if (!dataItem.ValidDays || Object.keys(dataItem.ValidDays).length == 0) {
    toast.error(`${lang.please_select_days_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
    });
    return false;
}
  if (!utility.isValidDate(dataItem.FromDate)) {
    toast.error(`${lang.please_select_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }
  if (!utility.isValidDate(dataItem.ToDate)) {
    toast.error(`${lang.please_select_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }

  if (dataItem.ToDate <= dataItem.FromDate) {
    toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }
    return true;
  }

  const addRestriction = (index) => {
    if (isValid()) {
      const dataNew = {
        ...dataItem,
        Channel : dataItem.Channel,
        ValidDays: dataItem.ValidDays,
        FromDate : new Date(dataItem.FromDate).getTime(),
        ToDate : new Date (dataItem.ToDate).getTime(),
      }
      props.setRestrictionData([...props.data, dataNew])
      setI(i + 1);
      setDataItem(initialValue);
      clearData();
    }
  }

  const MyCommandCell = (props) => (
    <div className="mt-1" style={{ display: "flex" }}>
      <EditRoundButton onClick={() => editFormGrid(props)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeRestrictionData(props.dataIndex), () => { })} />
    </div>
  )

  const editFormGrid = (data) => {
    console.log('here edit from grid');
    let dataItem = {...data.dataItem, FromDate: moment(new Date(data.dataItem.FromDate)).format('YYYY-MM-DD'), ToDate: moment(new Date(data.dataItem.ToDate)).format('YYYY-MM-DD')};
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setToggle(true);
    setEditMode(true);
  }

  const removeRestrictionData = (index) => {
    if (props.data.length == 1) {
      props.setRestrictionData([]);
      setI(i - 1);
      console.log(i);
      // clearData();
    }
    else {
      // console.log(index);
      props.setRestrictionData([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      // clearData();
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const handleSubmit = () => {
    if (isValid()) {
    console.log(props.data)
    let local = [...props.data]
    local[selectedIndexRef.current] = {...dataItem, FromDate : new Date(dataItem.FromDate).getTime(),ToDate : new Date (dataItem.ToDate).getTime(),};
    props.setRestrictionData(local);

      setEditMode(false);
      clearData();
    }
  }

  const clearData = () => {
    setDataItem(initialValue);
    setEditMode(false);
    setToggle(false);
  }

  return (
   <div className="row">
      <div className={"col-12"}>
        <AddRoundButton style={{marginBottom: '5px'}} onClick={() => setToggle(true)}></AddRoundButton>
        <Grid data={props.data} style={{ height: "30vh", marginBottom: "20px"}}>
          <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} />
          <GridColumn field="FromDate" title={lang.from_date_column} cell={DateOnlyCell} editable={false} />
          <GridColumn field="ToDate" title={lang.to_date_column} cell={DateOnlyCell} editable={false} />
          <GridColumn field="ValidDays" title={lang.days_column} cell={ArrayCell} format="Name" />
        </Grid>
      </div>
      {toggle &&
        <BossDialog
          title={Object.keys(dataItem.Channel).length > 0 ? dataItem.Channel.FullChannelName : `${lang.create_restriction_dialog_header}`}
          onClose={() => setToggle(false)}
          width={700}
        >
          <div className="row">
            <div className="col-12">
              <Form
                render={(formRenderProps) => (
                  <FormElement>
                    <div className="row mt-2">
                      <div className="marlr">
                        {!editMode && <SaveButton onClick={() => addRestriction()} />}
                        {editMode && <SaveButton onClick={() => { handleSubmit() }} />}
                        <CancelButton onClick={clearData} />
                      </div>
                    </div>
                    <div className=" mt-2" style={{ border: "groove 1px" }}>
                      <div className="row mb-3 ml-1 mr-1">
                        <div className="col-6">
                          <label htmlFor="TabView">{lang.channel_label} *</label>
                          <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                            validator={(value) =>
                              value ? "" : "Please select the value"
                            }
                          />
                        </div> 
                        <div className="col-6">
                        <div className="form-group">  
                                    <label htmlFor="TabView">{lang.days_label} *</label>     
                                    <MultiSelect
                                        style={{
                                        backgroundColor:"white",
                                        }}
                                        data={DAYS}
                                        name="ValidDays"
                                        textField="Name"
                                        dataItemKey="ID"
                                        value={dataItem.ValidDays}
                                        onChange={onChange}
                                        validator={(value) => value ? "" : "Please select the value"}
                                    /> 
                                </div>
                        </div>
                       
                      </div>
                      <div className="row ml-1 mr-1">                      
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.from_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="FromDate"
                                value={dataItem.FromDate}
                                onChange={onChange}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.to_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="ToDate"
                                value={dataItem.ToDate}
                                onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>    
                    </div>
                  </FormElement>

                )} />
            </div>
          </div>
        </BossDialog>}
    </div>
  )
}

export default PrototypeRestrictionEditForm