/* eslint-disable */
import React, { memo, useEffect, useRef, useState } from "react";
import * as API from "../../framework/API/api";
import moment from "moment";
import { ACCESS_LEVEL, ASSETTYPE, ENTITYNAME, OTTROUTETYPE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import { useTranslation } from "../../locale/useTranslation";
import { DropdownWithDefaultValue } from "../../framework/forms/helpercomponents/dropdown/DropdownWithDefaultValue";
import { utility } from "../../framework/utility/utilityProvider";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import JobsShowForm from "../IngestionTemplate/JobsShowForm";
import { collectionJobsMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { Collection } from "../../framework/forms/Collection";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OttAssetVideoEditForm from "./OttAssetVideoEditForm";
import OttAssetPosterEditForm from "./OttAssetPosterEditForm";
import InfoRoundButton from "../../components/InfoRoundButton";
import BossDialog from "../../components/BossDialog";

const MediaEpisodeAssetEditForm = (props) => {
  const navigate = useNavigate();
  const [ottTabNumber, setOttTabNumber] = React.useState("1");
  const [ottAssetVideos, setOttAssetVideos] = useState(props.dataItem.Videos ?? []);
  const [ottAssetPosters, setOttAssetPosters] = useState(props.dataItem.Posters ?? []);
  const getWebAssetsData = async (value) => {
    let webAssetsRes = await API.getData(ENTITYNAME.WebAssets, { query: ["_id", "=", value] });
    return webAssetsRes?.data?.length > 0 ? webAssetsRes?.data[0] : {};
  }

  const [dataItem, setDataItem] = useState({
    SID: props.dataItem.SID ?? 0,
    OttAssetTypeSID: props.source == 'self' ? ASSETTYPE.Live : ASSETTYPE["Media Asset"],
    Title: props.dataItem.Title ?? '',
    Description: props.dataItem.Description ?? '',
    VideoPlaybackUrl: props.dataItem.VideoPlaybackUrl ?? '',
    routeType: props.dataItem.routeType ?? OTTROUTETYPE.Player, // DEFAULT
    StartDate: props.dataItem.StartDate ?? moment(new Date()).format("YYYY-MM-DD"),
    EndDate: props.dataItem.EndDate ?? moment(new Date()).add(1, 'M').format("YYYY-MM-DD"),
    page_id: props.dataItem.page_id ?? '',
    Archive: props.dataItem.Archive ?? false,
    TBA: props.dataItem.TBA ?? false,
    Source: props.dataItem.Source ?? {},
    IsOttAsset: props.dataItem.IsOttAsset ?? false,
    VodTypes: props.dataItem.VodTypes && props.dataItem.VodTypes.length > 0 ? props.dataItem.VodTypes[0] : {},
    Videos: props.dataItem.Videos ?? [],
    Posters: props.dataItem.Posters ?? [],
    Segments: props.dataItem.Segments ?? [],

  });

  const [startTime, setStartTime] = useState("");
  const [ottAssetType, setOttAssetType] = useState([]);
  const [ottPage, setOttPage] = useState([]);
  const [vodTypes, setVodTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  let providersRef = useRef([]);

  const [routeTypes, setRouteTypes] = useState([]);
  const [jobsCollectionOpen, setJobsCollectionOpen] = useState(false);
  const [showWebAssets, setShowWebAssets] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [mediaOpen, setMediaOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(props.sourceEntity ? props.sourceEntity : {});
  const [source, setSource] = useState('');
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [isAssetTypeText, setIsAssetTypeText] = useState(props?.item?.OttAssetTypeSID == ASSETTYPE.Text ? true : false);
  const [showJobCollection, setShowJobCollection] = useState(false);
  const lang = useTranslation();
  const [posterDimensionPageOpen, setPosterDimensionPageOpen] = useState(false);

  const [accessLevels, setAccessLevels] = useState([]);

  const [segments, setSegments] = useState(props.dataItem.Segments ?? []);

  useEffect(() => {
    pageRander()
  }, [])

  const pageRander = async () => {
    await loadcombo();
    await setEditItem();
  }

  const loadcombo = async () => {
    let ottAssertType = Object.keys(ASSETTYPE).map((item) => {
      return { "Description": item, "ID": ASSETTYPE[item] }
    });
    setOttAssetType(ottAssertType);

    let ottPageLocal = await API.getData(ENTITYNAME.Page, { query: ['Archive', '=', false] });
    setOttPage(ottPageLocal.data);

    let rolesTypes = Object.keys(OTTROUTETYPE).map((item) => {
      return { "Description": item, "ID": OTTROUTETYPE[item] }
    });
    setRouteTypes(rolesTypes);

    let vodtypes = await API.getData(ENTITYNAME.VODType);
    setVodTypes(vodtypes.data);

    let providerRes = await API.getDataLookup(ENTITYNAME.OttProvider, { query: ['Archive', '=', false] });
    providersRef.current = providerRes.data;
    setProviders(providerRes.data);

    let accessLevelRes = Object.keys(ACCESS_LEVEL).map((item) => {
      return { _id: ACCESS_LEVEL[item], Description: item }
    })
    setAccessLevels(accessLevelRes);

    let segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '=', false], sort: { Description: 1 } });
    setSegments(segmentsRes.data);

  }

  const setEditItem = async () => {

    let res = {};
    let isOttAsset = false;

    if (props.source != 'self' && Object.keys(props.dataItem).length == 0) {
      res = await API.getData(props.entityname, { query: [['Source._id', '=', props.sourceEntity._id], ['Source.entityName', '=', props.source]] });
      isOttAsset = props.sourceEntity.IsOttAsset
    } else {
      res = { data: [props.dataItem] };
      isOttAsset = props.dataItem.hasOwnProperty('IsOttAsset') ? props.dataItem.IsOttAsset : false;
    }

    //for media management with no asset attached
    if (res.data.length == 0) {
      res = { data: [dataItem] }
    }

    if (res.data[0] && res.data[0].routeType == OTTROUTETYPE.Page) {
      setShowVideoPlayer(true);
    }

    props.setDataEntity && props.setDataEntity({
      ...res.data[0],
      VodTypes: res.data[0]?.VodTypes ? res.data[0]?.VodTypes[0] ? res.data[0]?.VodTypes[0] : res.data[0]?.VodTypes : {},
      WebAssets: res.data[0]?.WebAssets && res.data[0]?.WebAssets.length > 0 ? await getWebAssetsData(res.data[0]?.WebAssets) : {},
      IsOttAsset: isOttAsset
    });

    let getaccessLevelData = Object.keys(ACCESS_LEVEL).map((key, index) => { return { _id: index, Description: key } });


    let dataItemLocal = {};

    if (res.data.length > 0) {

      dataItemLocal = {
        ...res.data[0],
        SID: res.data[0]?.copy ? 0 : res.data[0].SID,
        Title: res.data[0]?.copy ? 'copy of ' + res.data[0].Title : res.data[0].Title,
        StartDate: res.data[0].StartDate ? moment(new Date(res.data[0].StartDate)).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
        EndDate: res.data[0].EndDate ? moment(new Date(res.data[0].EndDate)).format('YYYY-MM-DD') : moment(new Date()).add(1, 'M').format("YYYY-MM-DD"),
        TBA: res.data[0].TBA,
        VideoPlaybackUrl: res.data[0].VideoPlaybackUrl,
        Description: res.data[0]?.copy ? 'copy of ' + res.data[0].Description : res.data[0].Description,
        OttAssetTypeSID: res.data[0].OttAssetTypeSID ? res.data[0].OttAssetTypeSID : 1,
        Source: res.data[0].Source ?? {},
        routeType: res.data[0].routeType ?? OTTROUTETYPE.Player,
        page_id: res.data[0].page_id ?? '',
        IsOttAsset: isOttAsset,
        VodTypes: res.data[0]?.VodTypes ? res.data[0]?.VodTypes[0] ? res.data[0]?.VodTypes[0] : res.data[0]?.VodTypes : {},
        Videos: res.data[0].Videos ?? [],
        Posters: res.data[0].Posters ?? [],
        RentalRetailPrice: res.data[0].RentalRetailPrice ?? '',
        RentalWholesalePrice: res.data[0].RentalWholesalePrice ?? '',
        accessLevel: getaccessLevelData.find((x) => x._id == res.data[0]?.accessLevel) ?? { _id: ACCESS_LEVEL.Both, Description: utility.getKeyByValue(ACCESS_LEVEL, ACCESS_LEVEL.Both) },
        WebAssets: res.data[0]?.WebAssets && res.data[0]?.WebAssets.length > 0 ? await getWebAssetsData(res.data[0]?.WebAssets) : {},
        Provider: res.data[0]?.Provider ? providersRef.current?.find(x => x?._id == (res.data[0]?.Provider?._id ? res.data[0]?.Provider?._id : res.data[0]?.Provider)) : utility.getDefaultItem(providersRef.current),


      }
      setStartTime(utility.parseAdjust(res.data[0].StartDate));
      setSelectedMedia(res.data[0].Source ?? {});
      setOttAssetVideos(res.data[0].Videos ?? []);
      setOttAssetPosters(res.data[0].Posters ?? []);

    }
    setDataItem(dataItemLocal);
    setShowForm(true);

  }

  const handleMediaSelect = (dataList, src) => {
    setSelectedMedia(dataList[0]);
    setSource(src);

    let tempSource = src;

    if (src.Description != 'Series' && src.Description != 'Season') {
      tempSource = { Description: 'mediaepisode' }
    }

    setDataItem({
      ...dataItem,
      Title: dataList[0].Title,
      Description: dataList[0].Title,
      Source: { _id: dataList[0]._id, SID: dataList[0].SID, Title: dataList[0].Title, MediaCategory: dataList[0].MediaCategory, entityName: tempSource.Description.toLowerCase() }
    })
    props.setDataEntity({
      ...dataItem,
      Title: dataList[0].Title,
      Description: dataList[0].Title,
      Source: { _id: dataList[0]._id, SID: dataList[0].SID, Title: dataList[0].Title, MediaCategory: dataList[0].MediaCategory, entityName: tempSource.Description.toLowerCase() }
    })
  }

  const onChangeForm = (e) => {

    if (e.target.name == "OttAssetTypeSID") {
      if (e.target.value.ID == ASSETTYPE.Web) {
        setDataItem({ ...dataItem, OttAssetTypeSID: e.target.value, routeType: OTTROUTETYPE.WebView });
        props.setDataEntity({ ...dataItem, OttAssetTypeSID: e.target.value, routeType: OTTROUTETYPE.WebView });
        setIsAssetTypeText(false);
      } else if (e.target.value.ID !== ASSETTYPE["Media Asset"]) {
        setSelectedMedia({ Title: "" });
        setDataItem({ ...dataItem, Source: {}, [e.target.name]: e.target.value });
        props.setDataEntity({ ...dataItem, Source: {}, [e.target.name]: e.target.value })
        e.target.value.ID == ASSETTYPE.Text ? setIsAssetTypeText(true) : setIsAssetTypeText(false);
      }
      else {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        props.setDataEntity({ ...dataItem, [e.target.name]: e.target.value })
        setIsAssetTypeText(false);
      }
    } else if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
      props.setDataEntity({ ...dataItem, Archive: !dataItem.Archive })
    } else if (e.target.name == "TBA") {
      setDataItem({ ...dataItem, TBA: !dataItem.TBA });
      props.setDataEntity({ ...dataItem, TBA: !dataItem.TBA })
    } else if (e.target.name == "routeType") {
      if (e.target.value.ID == OTTROUTETYPE.Page) {
        setShowVideoPlayer(true);
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        props.setDataEntity({ ...dataItem, [e.target.name]: e.target.value })
      } else {
        setShowVideoPlayer(false);
        setDataItem({ ...dataItem, page_id: '', [e.target.name]: e.target.value });
        props.setDataEntity({ ...dataItem, page_id: '', [e.target.name]: e.target.value })
      }
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      props.setDataEntity({ ...dataItem, [e.target.name]: e.target.value })
    }

    if (e.target.name === "StartDate") {
      setStartTime(e.target.value);
    }

    if (e.target.name == "Segments") {
      setDataItem({ ...dataItem, Segments: e.target.value });
      props.setDataEntity({ ...dataItem, Segments: e.target.value });
    }

  }

  const onSwitchChange = (e) => {
    setDataItem({ ...dataItem, IsOttAsset: e.target.checked });
    props.setDataEntity({ ...dataItem, IsOttAsset: e.target.checked });
  }

  const openMedia = (e) => {
    if (Object.keys(selectedMedia).length > 0) {
      navigate(`/home/MediaEpisode/${selectedMedia.MediaCategory.Description}/${selectedMedia.SID}`, { state: { copy: false } })
    } else {
      console.log('Select Media');
      toast.info(`Select Media`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    e.preventDefault();
  }

  const deleteSelectedMedia = () => {
    setSelectedMedia({ Title: "" });
    props.setDataEntity({ ...dataItem, Source: {} })
  }

  const handleAssetsTabChange = (event, newValue) => {
    setOttTabNumber(`${newValue}`);
  };

  const setOttVideosData = (videos) => {
    setOttAssetVideos(videos);
    setDataItem({ ...dataItem, Videos: videos, Posters: ottAssetPosters });
    props.setDataEntity({ ...dataItem, Videos: videos, Posters: ottAssetPosters });

  }
  const setOttPostersData = (data) => {
    setOttAssetPosters(data);
    setDataItem({ ...dataItem, Posters: data, Videos: ottAssetVideos });
    props.setDataEntity({ ...dataItem, Posters: data, Videos: ottAssetVideos });
  }

  const handleJobData = (data) => {
    setDataItem({ ...dataItem, VideoPlaybackUrl: data[0].HlsUrl });
    props.setDataEntity({ ...dataItem, VideoPlaybackUrl: data[0].HlsUrl });
  }

  const onClosedPosterDimensionPage = async () => {
    setPosterDimensionPageOpen(false);
  }

  const handleSeletedWebAssets = (data) => {
    setDataItem({ ...dataItem, WebAssets: data[0] });
    props.setDataEntity({ ...dataItem, WebAssets: data[0] });
  }

  const OttAssetTypeSID = typeof dataItem.OttAssetTypeSID == 'number' ? dataItem.OttAssetTypeSID : dataItem.OttAssetTypeSID.ID;

  return (
    <>
      <div className="col-12">
        {props.source != 'self' && <>
          <div className="row">
            <div className="col-6">
              <label htmlFor=""><h3 style={{ margin: '0px' }}>{lang.create_ott_asset_label}</h3></label>
              <Switch checked={dataItem.IsOttAsset} onChange={onSwitchChange} />
            </div>
            {dataItem.IsOttAsset && <div className="col-6 text-right">
              <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                style={{ height: "30px" }} onClick={() => setJobsCollectionOpen(true)}>
                {lang.show_jobs_button_text}
              </button>
            </div>}
          </div>
        </>}

        {(props.source == 'self' || dataItem.IsOttAsset) && <>
          <div className="row">
            <div className={props.source != 'self' ? 'col-6 mt-2' : isAssetTypeText ? 'col-12 mt-2' : 'col-6 mt-2'}>
              <label htmlFor="">{lang.asset_type_label} *</label>
              <InfoRoundButton message={lang.show_poster_dimension_title_label} onClick={(e) => { setPosterDimensionPageOpen(true); }}></InfoRoundButton>
              <DropdownWithDefaultValue
                name={"OttAssetTypeSID"}
                textField={"Description"}
                value={dataItem.OttAssetTypeSID}
                data={ottAssetType}
                datakey={"ID"}
                onChange={onChangeForm}
                disabled={props.source != 'self'}
              />
            </div>

            {!isAssetTypeText && <div className="col-6 mt-2">
              <label htmlFor="">{lang.vodtype_label} *</label>
              <DropDownList
                style={{ backgroundColor: 'white', border: '1px solid lightgrey' }}
                data={vodTypes}
                name={"VodTypes"}
                textField="Name"
                dataItemKey="_id"
                value={dataItem.VodTypes}
                onChange={onChangeForm}
              />

            </div>}
            {dataItem.VodTypes.Name == "TVOD" && <div className="col-6 mt-2">
              <label htmlFor="">{lang.rental_retail_price_label}</label>
              <input name={"RentalRetailPrice"} className="form-control form-control-sm" type={'number'} value={dataItem.RentalRetailPrice} onChange={onChangeForm} />
            </div>
            }

            {dataItem.VodTypes.Name == "TVOD" && <div className="col-6 mt-2">
              <label htmlFor="">{lang.rental_wholesale_price_label}</label>
              <input name={"RentalWholesalePrice"} className="form-control form-control-sm" type={'number'} value={dataItem.RentalWholesalePrice} onChange={onChangeForm} />
            </div>
            }

            {props.source == 'self' && OttAssetTypeSID != ASSETTYPE["Media Asset"] && <div className="col-12 mt-2">
              <label htmlFor="">{lang.title_label} *</label>
              <input name={"Title"} className="form-control form-control-sm" type={'text'} value={dataItem.Title} onChange={onChangeForm} />
            </div>}

            {props.source == 'self' && OttAssetTypeSID == ASSETTYPE["Media Asset"] && <>
              <div className="col-10 mt-2" >
                <label htmlFor="">{lang.media_label} *</label>
                <input
                  name={"Media"}
                  className="form-control form-control-sm"
                  type={'text'}
                  value={selectedMedia.Title}
                  disabled={true}
                  onChange={() => ""}
                />
              </div>

              <button type="button" className="ml-2 k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                style={{ height: "30px", marginTop: "28px" }} onClick={(e) => { setMediaOpen(true); e.preventDefault(); }}>
                {'...'}
              </button>

              {/* button to show media */}
              <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
                style={{ height: "30px", marginTop: "28px", marginLeft: '3px' }} onClick={openMedia}>
                <i className="fa-solid fa-eye"></i>
              </button>
              <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-info"
                style={{ height: "30px", marginTop: "28px", marginLeft: '3px' }} onClick={(e) => { deleteSelectedMedia(); e.preventDefault() }}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </>}

            <div className="col-12 mt-2" >
              <div className="form-group">
                <label htmlFor="">{lang.description_label}</label>
                <textarea
                  name={"Description"}
                  className="form-control form-control-sm"
                  type={'text'}
                  value={dataItem.Description}
                  onChange={onChangeForm}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <label htmlFor="">{lang.access_level_label} *</label>
              <DropDownList
                data={accessLevels}
                style={{ backgroundColor: 'white' }}
                name="accessLevel"
                textField="Description"
                dataItemKey="_id"
                value={dataItem.accessLevel}
                onChange={onChangeForm}
              />
            </div>
            <div className="col-6">
              <label htmlFor="">{'Provider'}</label>
              <DropDownList
                style={{ backgroundColor: 'white', border: '1px solid lightgrey' }}
                data={providers}
                name={"Provider"}
                textField="Description"
                dataItemKey="_id"
                value={dataItem.Provider}
                onChange={onChangeForm}
              />
            </div>

          </div>

          <div className="row mt-2">
            {!isAssetTypeText && <div className="col-6">
              <label htmlFor="">{lang.route_type_label} *</label>
              <DropdownWithDefaultValue
                name={"routeType"}
                textField={"Description"}
                value={dataItem.routeType}
                data={routeTypes}
                datakey={"ID"}
                onChange={onChangeForm}
                disabled={OttAssetTypeSID == ASSETTYPE.Web}
              />
            </div>}

            <div className="col-6">
              <label htmlFor="TabView">{lang.segment_label} </label><span className="float-right" style={{ fontSize: "10px" }}>Note: If Segment not select by default all</span>
              <MultiSelect
                style={{
                  backgroundColor: "white",
                }}
                data={segments}
                name={"Segments"}
                textField={"Description"}
                dataItemKey={"_id"}
                value={dataItem.Segments}
                onChange={onChangeForm}
              />
            </div>

            {showVideoPlayer && <div className="col-6">
              <label htmlFor="">{lang.page_label} *</label>
              <DropdownWithDefaultValue
                name={"page_id"}
                textField={"Name"}
                value={dataItem.page_id}
                datakey={"_id"}
                data={ottPage}
                onChange={onChangeForm}
              />
            </div>}
            {OttAssetTypeSID == ASSETTYPE.Web && <>
              <div className="col-5" >
                <label htmlFor="">{lang.web_assets_label} *</label>
                <input
                  name={"WebAssets"}
                  className="form-control form-control-sm"
                  type={'text'}
                  value={dataItem?.WebAssets?.Name}
                  disabled={true}
                  onChange={() => ""}
                />
              </div>
              <div className="col-1">
                <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                  style={{ height: "29px", marginTop: "20px" }} onClick={(e) => { setShowWebAssets(true); e.preventDefault(); }}>
                  {'...'}
                </button>
              </div> </>}
          </div>

          <div className="row mt-3">
            <div className='col'>
              <input name={"Archive"} type={'checkbox'} onChange={onChangeForm} value={dataItem.Archive} checked={dataItem.Archive} style={{ margin: '0px 5px 0px 2px' }} />
              <label htmlFor="">{lang.archive}</label>
            </div>
          </div>

          {/* VIDEO AND POSTER TABS */}

          {!isAssetTypeText && <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={ottTabNumber}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleAssetsTabChange} aria-label="lab API tabs example">
                      <Tab label={lang.ott_assets_video} value="1" />
                      <Tab label={lang.ott_assets_poster} value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value={"1"} style={{ marginLeft: -30, marginRight: -30, marginBottom: -10 }}>
                    <OttAssetVideoEditForm data={ottAssetVideos} setOttVideosData={setOttVideosData} />
                  </TabPanel>
                  <TabPanel value={"2"} style={{ marginLeft: -30, marginRight: -30, marginBottom: -10 }}>
                    <OttAssetPosterEditForm data={ottAssetPosters} setOttPostersData={setOttPostersData} />
                  </TabPanel>

                </TabContext>
              </Box>
            </div>

          </div>}

        </>}
      </div>

      {mediaOpen && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} mode={'single'} width={"50vw"} />}
      {jobsCollectionOpen && <BossDialog title={`Jobs`} onClose={() => setJobsCollectionOpen(false)} width="80vw" height="95vh">
        <Collection entityname={ENTITYNAME.OttTranscodeStatus} showAdd={false} showTitle={false} showBack={false} title={lang.jobs_page_breadcrumb} wherestatement={['AssetId', '=', props.sourceEntity.AssetId]} customEditForm={JobsShowForm} menuModel={collectionJobsMenuModel} />
      </BossDialog>}
      {showJobCollection && <CollectionSelection title={lang.select_playback_url_header} setDataList={handleJobData} entityname={ENTITYNAME.OttTranscodeStatus} closeForm={() => setShowJobCollection(false)} mode={'single'} width={"50vw"} />}
      {showWebAssets && <CollectionSelection wherestatement={["Archive", "=", false]} title={lang.select_web_assets_dialog_header} entityname={ENTITYNAME.WebAssets} setDataList={handleSeletedWebAssets} closeForm={() => setShowWebAssets(false)} width="730px" mode="single" />}
      {
        posterDimensionPageOpen &&
        <BossDialog
          title={lang.poster_dimension_title_label}
          onClose={onClosedPosterDimensionPage}
          width={"20vw"}

        >
          <div className="row ml-1" style={{ textAlign: "center" }}>
            <div className="ml-4 mr-4" style={{ fontWeight: "700", fontSize: "14px" }}>
              <div className="row mb-2">{lang.hero_banner_mobile_label} :</div>
              <div className="row  mb-2">{lang.hero_banner_tv_label} :</div>
              <div className="row  mb-2">{lang.square_label} :</div>
              <div className="row  mb-2">{lang.circle_label} :</div>
              <div className="row  mb-2">{lang.landscape_label} :</div>
            </div>
            <div className="ml-4" style={{ fontWeight: "500" }}>
              <div className="row  mb-2">1280 * 720 PX</div>
              <div className="row  mb-2">1280 * 360 px</div>
              <div className="row  mb-2">512 * 512 PX</div>
              <div className="row  mb-2">512 * 512 PX</div>
              <div className="row  mb-2">328 * 185 PX</div>
            </div>
          </div>
        </BossDialog>
      }
    </>
  )
};

export default memo(MediaEpisodeAssetEditForm);