/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { useNavigate } from "react-router-dom";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { ENTITYNAME } from "../../framework/constant/constant";
import { FaqEditForm } from "./FaqEditForm";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import ImportFaqFile from "./ImportFaqFile";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExpanderFaq } from "./ExpanderFaq";

export const FaqCollection = () => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const [gridData, setGridData] = useState([]);
    const [openForm, setFormOpen] = useState();
    const [refreshTemplate, setRefreshTemplate] = useState(false);
    const [faqcategory,setFaqCategory]= useState([]);
    const [showImportFile, setShowImportFile] = useState(false);
    const [filterGridData, setFilterGridData] = useState([]);
    const [faqcategoryItem, setFaqCategoryItem] = useState({
        _id: 0,
        Description: "All",
    });

    useEffect(() => {
        loadData();
    }, [refreshTemplate]);

    const loadData = async () => {
        var faqCategoryRes = await API.getDataLookup(ENTITYNAME.FaqCategory, {sort: { Description: 1 }});
        // unshift() adds new items to the beginning of an array:
        faqCategoryRes.data.unshift({
            _id: 0,
            Description: "All",
        });
        setFaqCategory(faqCategoryRes.data);
        var faqCategoryRes = await API.getDataLookup(ENTITYNAME.Faq);
        setGridData(faqCategoryRes.data);
        setFilterGridData(faqCategoryRes.data);
    }

    const onChange = (e) => {
        setFaqCategoryItem(e.target.value);
        if (e.target.value._id == 0) {
            loadData();
        } else {
            var data = filterGridData.filter((obj) => obj.FaqCategory._id == e.target.value._id);
            setGridData(data);
        }
    };

    const MyExpenderCommandCell = (props) => (
        <div className="" style={{ overflow: 'hidden', overflowY: "auto" }}>
           <ExpanderFaq title={`${lang.question_label} - ` + props.dataItem.Question} propsStyle={{fontSize: "14px", fontWeight: '400', fontFamily: "Roboto"}}>
               <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td><span className="font-weight-bold" >{`${lang.answer_label} -` }</span> {props.dataItem.Answer}</td>
                        </tr>                 
                    </tbody>
                </table>
            </ExpanderFaq>      
        </div>
    )
    
    var defaultKeys = [];
    const onSearch = (e) => {
        console.log("search")
        e.preventDefault();
        const keyword = e.target.value?.trim();
        var datakey = Object.keys(gridData[0]);
        datakey?.map((key) => {
            if (key == "Question" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            } 
            else if (key == "FaqCategory.Description" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            }
        });
        var dataStore = [];
        if (keyword != "") {
            defaultKeys?.map((item) => {
                var filterDataValue = gridData.filter((obj) => obj[item] && obj[item].toLowerCase().includes(keyword.toLowerCase()));
                if (filterDataValue.length != 0) {
                    dataStore.push(...filterDataValue);
                }
            });
            const uniqData = [...new Set(dataStore)];
            setGridData(uniqData);
        } else {
            setGridData(gridData);
        }
    }

    const refreshCollection = () => {
        loadData();
        setRefreshTemplate();
        setFaqCategoryItem({_id: 0,Description: "All"});
    }

    const enterEdit = (item) => {
      console.log("onEnterEdit");
      setFormOpen(true);      
    };

    const cancelFaqEditForm = () => {
      setFormOpen(false);
  }
    
    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }}></i>
            <span>{lang.faq_page_breadcrumb}</span>
        </h1>
        <div className="flex-container">
            <div className="martb mar mt-3">
              <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>               
              <AddRoundButton onClick={() => enterEdit({ SID: 0 })}/>       
            </div>
            <div className="form-group ml-1">
                <label htmlFor="TabView">{lang.faq_category_label} *</label>
                <DropDownList
                    style={{
                    backgroundColor:"white",
                    }}
                    data={faqcategory}
                    name="FaqCategory"
                    textField="Description"
                    dataItemKey="_id"
                    value={faqcategoryItem}
                    onChange={onChange}
                />  
              </div>      
            <div className="flex-container-reverse flex-item-auto">
                <div className="input-group input-group w-300 martb" >
                    <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" onChange={onSearch} placeholder={lang.search_button_text}/>
                </div>
                <div className="mt-1" style={{ paddingTop: "12px" }}>
                    <RoundButton
                        icon={"upload"}
                        btnColor={"warning"}
                        style={{ margin: "0px 03px" }}
                        title={lang.import_file_label}
                        onClick={() => {setShowImportFile(true);}}
                    />
                </div>
                <div className="mt-1" style={{ paddingTop: "12px" }}>
                    <RefreshButton onClick={refreshCollection}/>
                </div>
            </div>
        </div>
        
        <div className="row">
            {/* <div className="ml-4">
               <Grid data={faqcategory} style={{ height: '74.5vh' }}>
                    <Column title={lang.select_a_category_column} cell={MyCommandCell} width={"280px"}/>                     
                </Grid>
            </div> */}
            <div className="col-12">
               <Grid data={gridData} style={{ height: '74.5vh'}}>     
                    <GridColumn title={lang.faqs_column} cell={MyExpenderCommandCell} />                       
                </Grid>
            </div>
        </div>
        {openForm && <FaqEditForm closeForm={cancelFaqEditForm} refresh={refreshCollection}/>}
        {showImportFile && <ImportFaqFile onClose={() => setShowImportFile(false)} refresh={refreshCollection}/>}
    </>)
}
export default FaqCollection;