import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../../locale/useTranslation'
import { toast } from "react-toastify";
import { EditPageHeader } from "../../../components/EditPageHeader";
import * as API from '../../../framework/API/api'
import { RadioButton } from '@progress/kendo-react-inputs';
import OpenScheduleDraftCollectionSelection from '../../../framework/forms/OpenScheduleDraftCollectionSelection';
import moment from 'moment';
import BossDialog from '../../../components/BossDialog';

const OpenScheduleDraftDialog = (props) => {

    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({
        database: true,
        file: false
    })
    
    const [selectedDataItem,setSelectedDataItem] = useState([]);
    const [fileDataItem, setFileDataItem] = useState({ file: "" });
    const [files, setFiles] = useState([]);// to store selected file
    const fileRef = useRef(null);

    useEffect(() => {
    }, [])

    const handleFileChange = (e) => {

        setFileDataItem({ ...fileDataItem, [e.target.name]: e.target.value });
        setFiles(e.target.files[0]);

    };

    const setAsDefault = () => {
        setSelectedDataItem([]);
        setFiles([]);
        setFileDataItem({ file: "" })
    }

    const onConfirm = async () => {

        let finalData = [];

        if (dataItem.database) {
            if(selectedDataItem.length == 0){
                toast.error(lang.please_selct_one_item_msg_scheduling, { position: toast.POSITION.TOP_RIGHT });
                return;
            }
            finalData = selectedDataItem[0]
        } else {

            //CALL READ FILE API HERE AND PASS DATA 
            let response = await API.readScheduleBssFile(files);
            if (response.success) {
                finalData = response.data
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                return;
            }

        }

        //PASS DATA HERE
        props.setIsDraftMode(true);
        props.setDraftData(finalData)
        props.loadDraftdata(finalData.ScheduleData);
        setAsDefault()
        props.onClose()

    }


    return (
        <BossDialog
            title={`Open Schedule Draft`}
            onClose={props.onClose}
            width={dataItem.file ? "500px" : "900px"}
        >
            <EditPageHeader title={""} onSubmit={onConfirm} saveButtonTitle={'Open'} onCancel={props.onClose} showTitle={false} />
            <div className="row">
                <div className="col-12" style={{ marginTop: "22px" }}>
                    <RadioButton
                        name="database"
                        value="database"
                        checked={dataItem.database}
                        label={"Database"}
                        onChange={(e) => { 
                            setDataItem({ database: e.value, file: false })
                            setAsDefault();
                        }}
                    />
                    <RadioButton
                        style={{ marginLeft: "10px" }}
                        name="file"
                        value="file"
                        checked={dataItem.file}
                        label={"File"}
                        onChange={(e) => { 
                            setDataItem({ file: e.value, database: false }) 
                            setAsDefault();
                        }}
                    />
                </div>
                <div className='col-12 mt-3'>
                    {dataItem.database ? <OpenScheduleDraftCollectionSelection 
                        addButtonTitle={'Select'} 
                        setDataList = {setSelectedDataItem} 
                        wherestatement = {[
                            ["Channel.SID", "=",props.selectedChannel.SID],
                            ['ScheduleDate','>=',new Date(`${moment(new Date(props.date)).format('YYYY-MM-DD')} 00:00:00`).getTime()],
                            ['ScheduleDate','<=',new Date(`${moment(new Date(props.date)).format('YYYY-MM-DD')} 23:59:59`).getTime()]
                        ]} 
                        channelList = {props.channelList} 
                        selectedChannel={props.selectedChannel} 
                        scheduleDate = {moment(new Date(props.date)).format('YYYY-MM-DD')}
                        closeForm={() => {}} /> 
                        :
                        <div style={{ display: 'flex' }}>
                            <input type="text" className="form-control form-control-sm" name="filename" value={fileDataItem.file ?? ""} disabled={true} />
                            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '0px 0px 0px 3px' }}>
                                <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                                    <input
                                        ref={fileRef}
                                        id="fileupload"
                                        name="file"
                                        className='custom-file-updated-input form-control form-control-sm'
                                        type="file"
                                        hidden="hidden"
                                        onChange={handleFileChange}
                                        accept={".bss"}
                                    />
                                </label>
                            </button>
                        </div>}
                </div>
            </div>

        </BossDialog >
    )
}

export default OpenScheduleDraftDialog