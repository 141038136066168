/* eslint-disable */
import { EditPageHeader } from "../../components/EditPageHeader";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import BossDialog from "../../components/BossDialog";

export const MediaEpisodeMultiEdit = (props) => {

    const subHeadingStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "500", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 10px", padding: 0 }
    const style = { display: "grid", gridTemplateColumns: "0.9fr 1.1fr", alignItems: "start", padding: "10px 0" }

    const lang = useTranslation();

    const [selectedFields, setSelectedFields] = useState({
        PGRating: { isSelected: false, value: "" },
        CensorRating: { isSelected: false, value: "" },
        Languages: { isSelected: false, value: [] },
        ProductionYear: { isSelected: false, value: "" },
        Categories: { isSelected: false, value: [] },
        Genres: { isSelected: false, value: [] },
        SubGenres: { isSelected: false, value: [] },
        ProductionYear: { isSelected: false, value: "" },
        MediaTechnicalPrototype: { isSelected: false, value: "" },
        Keywords: { isSelected: false, value: "" },
        MamID: { isSelected: false, value: "" }
    });

    const [pgRatings, setPGRatings] = useState([]);
    const [censorRatings, setCensorRatings] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [genres, setGenres] = useState([]);
    const [technicalPrototypes, setTechnicalPrototypes] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        if (pgRes.success) setPGRatings(pgRes.data);
        let crRes = await API.getDataLookup(ENTITYNAME.CensorRating, { sort: { Description: 1 } });
        if (crRes.success) setCensorRatings(crRes.data);
        let languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        if (languages.success) setLanguages(languages.data);
        let categories = await API.getDataLookup(ENTITYNAME.ContentCategory, { sort: { Name: 1 } });
        if (categories.success) setCategories(categories.data);
        let genreRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        if (genreRes.success) setGenres(genreRes.data);
        let techRes = await API.getDataLookup(ENTITYNAME.MediaTechnicalPrototype, { sort: { Description: 1 } });
        if (techRes.success) setTechnicalPrototypes(techRes.data);
    }

    const onCheckBoxSelection = (e) => {
        let key = e.target.name;
        setSelectedFields({ ...selectedFields, [key]: { ...selectedFields[key], isSelected: e.target.value } });
    }

    const onChange = (e) => {
        let key = e.target.name;
        if (key == "PGRating" || key == "CensorRating") {
            let shortData = {
                _id: e.target.value?._id,
                Description: e.target.value?.Description
            }
            setSelectedFields({ ...selectedFields, [key]: { ...selectedFields[key], value: shortData } });
        } else if (key == "Languages" || key == "Genres" || key == "SubGenres") {
            let shortData = e.target.value?.map((x) => {
                return {
                    _id: x?._id,
                    Description: x?.Description,
                }
            });
            setSelectedFields({ ...selectedFields, [key]: { ...selectedFields[key], value: shortData } });
        } else if (key == "Categories") {
            let shortData = e.target.value?.map((x) => {
                return {
                    _id: x?._id,
                    Name: x?.Name,
                    parent: x?.parent
                }
            })
            setSelectedFields({ ...selectedFields, [key]: { ...selectedFields[key], value: shortData } });
        } else {
            setSelectedFields({ ...selectedFields, [key]: { ...selectedFields[key], value: e.target.value } });
        }
    }

    const isValid = () => {
        if(selectedFields.PGRating.isSelected && (!selectedFields.PGRating.value || Object.keys(selectedFields.PGRating.value).length == 0)) {
            toast.error(lang.please_select_pg_rating_error_message);
            return false;
        }if(selectedFields.CensorRating.isSelected && (!selectedFields.CensorRating.value || Object.keys(selectedFields.CensorRating.value).length == 0)) {
            toast.error(lang.please_select_censor_rating_error_message);
            return false;
        }if(selectedFields.Languages.isSelected && (!selectedFields.Languages.value || selectedFields.Languages.value.length == 0)) {
            toast.error(lang.please_select_languages_error_message);
            return false;
        }if(selectedFields.Categories.isSelected && (!selectedFields.Categories.value || selectedFields.Categories.value.length == 0)) {
            toast.error(lang.please_select_categories_error_message);
            return false;
        }if(selectedFields.Genres.isSelected && (!selectedFields.Genres.value || selectedFields.Genres.value.length == 0)) {
            toast.error(lang.please_select_genres_error_message);
            return false;
        }if(selectedFields.SubGenres.isSelected && (!selectedFields.SubGenres.value || selectedFields.SubGenres.value.length == 0)) {
            toast.error(lang.please_select_sub_genres_error_message);
            return false;
        }if(selectedFields.ProductionYear.isSelected && (!selectedFields.ProductionYear.value || selectedFields.ProductionYear.value == "")) {
            toast.error(lang.please_enter_production_year_error_message);
            return false;
        }if(selectedFields.MediaTechnicalPrototype.isSelected && (!selectedFields.MediaTechnicalPrototype.value || Object.keys(selectedFields.MediaTechnicalPrototype.value).length == 0)) {
            toast.error(lang.please_select_technical_prototype_error_message);
            return false;
        }if(selectedFields.Keywords.isSelected && (!selectedFields.Keywords.value || selectedFields.Keywords.value == "")) {
            toast.error(lang.please_enter_keywords_error_message);
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if(!isValid()) return;

        let selectedItems = props?.selectedItems ?? [];
        let metaData = {};
        Object.keys(selectedFields).map((key) => {
            if (selectedFields[key].isSelected && key !== 'Genres' && key !== 'SubGenres') {
                metaData[key] = selectedFields[key].value;
            }
        });

        let mediaData = {}
        Object.keys(selectedFields).map((key) => {
            if (selectedFields[key].isSelected && (key == 'Genres' || key == 'SubGenres')) {
                mediaData[key] = selectedFields[key]?.value;
            }
        });

        if (selectedItems.length == 0) return toast.error(lang.please_select_items_to_update_message);
        console.log(mediaData);
        if (Object.keys(mediaData).length > 0) {
            await API.bulkUpdateSelectedItems(ENTITYNAME.MediaEpisode, selectedItems, mediaData);
        }
        console.log(metaData);
        if (Object.keys(metaData).length > 0) {
            await API.bulkUpdateSelectedItems(ENTITYNAME.MediaEpisodeMetaData, selectedItems, metaData);
        }

        toast.success(lang.update_successfully_message);
        props.closeForm();
        props.refresh();
    }

    return (
        <BossDialog title={'Update multiple'} onClose={props.closeForm} width="65%" >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.closeForm} showTitle={false} />
            </div>
            <div className="row" style={{ display: 'flex'}}>
                <div className="col-6" style={{flex: '1'}}>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="PGRating" value={selectedFields.PGRating.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.pg_rating_label}</span>
                        </div>
                        <DropDownList
                            disabled={!selectedFields.PGRating.isSelected}
                            style={{ backgroundColor: !selectedFields.PGRating.isSelected ? "#d9dbde" : "white" }}
                            data={pgRatings}
                            name="PGRating"
                            textField="Description"
                            dataItemKey="_id"
                            onChange={onChange}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="CensorRating" value={selectedFields.CensorRating.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.censor_rating_label}</span>
                        </div>
                        <DropDownList
                            disabled={!selectedFields.CensorRating.isSelected}
                            style={{ backgroundColor: !selectedFields.CensorRating.isSelected ? "#d9dbde" : "white" }}
                            data={censorRatings}
                            name="CensorRating"
                            textField="Description"
                            dataItemKey="_id"
                            onChange={onChange}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="Languages" value={selectedFields.Languages.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.languages_label}</span>
                        </div>
                        <MultiSelect
                            disabled={!selectedFields.Languages.isSelected}
                            style={{ backgroundColor: !selectedFields.Languages.isSelected ? "#d9dbde" : "white", maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                            data={languages}
                            name="Languages"
                            textField="Description"
                            dataItemKey="_id"
                            onChange={onChange}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="Categories" value={selectedFields.Categories.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.categories_label}</span>
                        </div>
                        <MultiSelect
                            disabled={!selectedFields.Categories.isSelected}
                            style={{ backgroundColor: !selectedFields.Categories.isSelected ? "#d9dbde" : "white", maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                            data={categories}
                            dataItemKey="_id"
                            textField="Name"
                            onChange={onChange}
                            name={"Categories"}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="Genres" value={selectedFields.Genres.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.geners_label}</span>
                        </div>
                        <MultiSelect
                            disabled={!selectedFields.Genres.isSelected}
                            style={{ backgroundColor: !selectedFields.Genres.isSelected ? "#d9dbde" : "white", maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                            data={genres}
                            dataItemKey="_id"
                            textField="Description"
                            onChange={onChange}
                            name={"Genres"}
                        />
                    </div>
                </div>
               <div style={{borderLeft: '1px solid #E0E0E0', marginLeft: "-1px"}}></div>
                <div className="col-6"  style={{flex: '1'}}>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="SubGenres" value={selectedFields.SubGenres.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.sub_geners_label}</span>
                        </div>
                        <MultiSelect
                            disabled={!selectedFields.SubGenres.isSelected}
                            style={{ backgroundColor: !selectedFields.SubGenres.isSelected ? "#d9dbde" : "white", maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                            data={genres}
                            dataItemKey="_id"
                            textField="Description"
                            onChange={onChange}
                            name={"SubGenres"}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="ProductionYear" value={selectedFields.ProductionYear.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.production_year_label}</span>
                        </div>
                        <input
                            disabled={!selectedFields.ProductionYear.isSelected}
                            style={{ backgroundColor: !selectedFields.ProductionYear.isSelected ? "#d9dbde" : "white", border: "1px solid #E0E0E0", padding: "0 10px" }}
                            name="ProductionYear"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            type="text"
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="MediaTechnicalPrototype" value={selectedFields.MediaTechnicalPrototype.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.technical_prototype_label}</span>
                        </div>
                        <DropDownList
                            disabled={!selectedFields.MediaTechnicalPrototype.isSelected}
                            style={{ backgroundColor: !selectedFields.MediaTechnicalPrototype.isSelected ? "#d9dbde" : "white" }}
                            data={technicalPrototypes}
                            name="MediaTechnicalPrototype"
                            textField="Description"
                            dataItemKey="_id"
                            onChange={onChange}
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="Keywords" value={selectedFields.Keywords.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.keywords_label}</span>
                        </div>
                        <input
                            disabled={!selectedFields.Keywords.isSelected}
                            style={{ backgroundColor: !selectedFields.Keywords.isSelected ? "#d9dbde" : "white", border: "1px solid #E0E0E0", padding: "0 10px" }}
                            name="Keywords"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            type="text"
                        />
                    </div>
                    <div style={style}>
                        <div>
                            <Checkbox style={{ border: '1px solid grey' }} name="MamID" value={selectedFields.MamID.isSelected} onChange={onCheckBoxSelection} />
                            <span style={subHeadingStyle}>{lang.mamID_label}</span>
                        </div>
                        <input
                            disabled={!selectedFields.MamID.isSelected}
                            style={{ backgroundColor: !selectedFields.MamID.isSelected ? "#d9dbde" : "white", border: "1px solid #E0E0E0", padding: "0 10px" }}
                            name="MamID"
                            className="form-control form-control-sm"
                            onChange={onChange}
                            type="text"
                        />
                    </div>
                </div>
            </div>
        </BossDialog>
    )
}