import React, {useState} from 'react';

const expanderStyle = {
    margin: '6px 4px',
    padding: '2px',
    
};

const headerStyle = {
    display: 'flex',
    cursor: 'pointer'
};

const spacerStyle = {
    flex: '1'
};

const iconStyle = {
    margin: '10px 10px 5px 3px',
    flex: 'none'
};

const contentStyle = {
    overflow: 'hidden',
    transition: 'all 0.3s'
};

const contentExpandedStyle = {
    ...contentStyle,
    padding: '4px 0',

    height: 'auto',
    filter: 'opacity(1)'
};

const contentCollapsedStyle = {
    ...contentStyle,
      padding: '0 0',
    border: '1px solid transparent',
    height: '0',
    filter: 'opacity(0)'
};


export const ExpanderFaq = ({title, propsStyle, children, img,expand,color, subtext}) => {

  let titleValue = title?.split('-') ?? title;
  title = titleValue[0];
  subtext = titleValue[1];

  const titleStyle = propsStyle ? propsStyle : {
    padding: '8px',
    flex: 'none',
    fontWeight: "bold",
  };

  const subtextStyle = propsStyle ? propsStyle : {
    margin: '10px 10px 5px 3px',
    flex: 'none',
    fontSize: "12px"
  };

  var expand = expand ?? false;

  const [expanded, setExpanded] = React.useState(expand);
  const handleHeaderClick = () => {
    setExpanded(expanded => !expanded);
  };

  return (
    <div style={{...expanderStyle,backgroundColor : color}}>
      <div style={headerStyle} onClick={handleHeaderClick}>
        {img && <img className="ExpandableTagImg" src={img}  style={{ objectFit: "cover",   borderRadius:"8px"  }}/>}
        <div style={titleStyle} className='font-weight-bold'>{title} - </div> <div style={subtextStyle}>{subtext}</div>
        <div style={spacerStyle} />
        <div style={iconStyle}>{<i className={`fa-solid fa-lg fa-angle-${expanded ? "up" : "down"}`}></i>}</div>
      </div>
      <div style={expanded ? contentExpandedStyle : contentCollapsedStyle}>
        {children}
      </div>
    </div>
  );

};
