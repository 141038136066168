import React, { useEffect, useRef, useState } from 'react'
import BossDialog from '../../components/BossDialog'
import { useTranslation } from '../../locale/useTranslation'
import { EditPageHeader } from '../../components/EditPageHeader'
import * as API from '../../framework/API/api'
import { ENTITYNAME, LOGEVENT, MESSAGE_TEMPLATE, MODULE } from '../../framework/constant/constant'
import { utility } from '../../framework/utility/utilityProvider'
import { toast } from 'react-toastify'

const MessageProviderEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        _id: props?.item?._id ?? null,
        SID: props?.item?.SID ?? 0,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Curl: props?.item?.Curl ?? "",
        Archive: props?.item?.Archive ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
    const [suggestionPosition, setSuggestionPosition] = useState({ top: 0, left: 0 });
    const textareaRef = useRef(null);

    const updateSuggestionsPosition = (cursorPosition) => {
        const textArea = textareaRef.current;
        if (textArea) {
            const { scrollTop, scrollLeft, offsetTop, offsetLeft } = textArea;
            
            // Get cursor position relative to the viewport
            const rect = textArea.getBoundingClientRect();
            const lines = textArea.value.substr(0, cursorPosition).split("\n");
            const lineNumber = lines.length - 1;
            const charIndex = lines[lineNumber].length;
    
            const lineHeight = 20; // Approximate line height in px
            const charWidth = 8;   // Approximate character width in px
    
            // Calculate suggestion box position
            const topPos = offsetTop + lineNumber * lineHeight - scrollTop;
            const leftPos = offsetLeft + charIndex * charWidth - scrollLeft;
    
            // Set position relative to the text area position on the page
            setSuggestionPosition({ top: rect.top + topPos + 5, left: rect.left + leftPos });
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "Curl") {
            const cursorPos = e.target.selectionStart;
            const inputChar = e.nativeEvent.data;
            setDataItem({ ...dataItem, Curl: value });
            const isMatch = MESSAGE_TEMPLATE.some(item => 
                JSON.stringify(item).includes(inputChar)
            );
            if (inputChar == "{" || isMatch) {
                const filteredSuggestions = MESSAGE_TEMPLATE.filter(template => {
                    let newValue = value.split('{').pop().toLowerCase();
                    if(inputChar == '{') return template;
                    return template.includes(newValue);
                });
                setSuggestions(filteredSuggestions);
                setShowSuggestions(true);
                updateSuggestionsPosition(cursorPos);
            } else {
                setShowSuggestions(false);
            }
            setActiveSuggestionIndex(-1);
        } else if (name === "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [name]: value });
        }
    };


    const isValid = () => {
        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(lang.please_enter_description_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.Curl == undefined || dataItem.Curl == "") {
            toast.error(lang.please_enter_curl_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return
        let finalData = {
            _id: dataItem?._id,
            SID: dataItem?.SID ?? 0,
            Description: dataItem?.Description,
            Curl: dataItem?.Curl,
            Archive: dataItem?.Archive ?? false,
        }

        console.log(finalData);

        let res = await API.saveData(ENTITYNAME.MessageProvider, finalData)
        if (res.success) {
            let logData = { event: res.message == "update" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.MESSAGE_PROVIDER, data: res.data, message: res.message }
            API.SaveLogs(logData)
            utility.deleteLocalStorageItem(ENTITYNAME.MessageProvider);
            props.refresh();
            props.cancelEdit();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onSelectSuggestion = (suggestion) => {
        const textArea = textareaRef.current;
        const cursorPos = textArea.selectionStart;
        const value = dataItem.Curl;
    
        // Find the position of the last open brace '{' before the cursor position
        const openBracePos = value.lastIndexOf('{', cursorPos - 1);
    
        if (openBracePos === -1) return; // No '{' found, do nothing
    
        // Construct the new value by inserting the suggestion at the selection start position
        const beforeBrace = value.substring(0, openBracePos + 1); // Text up to the last '{'
        const afterBrace = value.substring(cursorPos); // Text after the cursor position
        const newCurlValue = beforeBrace + "#" + suggestion + '#}' + afterBrace;
    
        // Update the data item with the new value
        setDataItem({
            ...dataItem,
            Curl: newCurlValue
        });
    
        setShowSuggestions(false);
        setActiveSuggestionIndex(-1);
    
        // Move the cursor position after the inserted suggestion
        setTimeout(() => {
            textArea.selectionStart = textArea.selectionEnd = openBracePos + suggestion.length + 3; // Place cursor after the inserted text
            textArea.focus();
        }, 0);
    };
    

    const onKeyDown = (e) => {
        if (!showSuggestions) return;

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setActiveSuggestionIndex((prevIndex) =>
                    prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
                );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setActiveSuggestionIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
                );
                break;
            case 'Enter':
                if (activeSuggestionIndex >= 0) {
                    e.preventDefault();
                    onSelectSuggestion(suggestions[activeSuggestionIndex]);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            <BossDialog
                title={(props.item.Description && props.item.Description != "") ? props.item.Description : lang.message_provider_dialog_header}
                onClose={props.cancelEdit}
                width={'100vh'}
            >
                <div className='row'>
                    <div className='col-12'>
                        <EditPageHeader
                            showTitle={false}
                            onSubmit={handleSubmit}
                            onCancel={props.cancelEdit}
                        />
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <label>{lang.description_label} *</label>
                                <input type='text' name='Description' className='form-control form-control-sm' value={dataItem.Description} onChange={onChange} />
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12' style={{ position: "relative" }}>
                                <label>{lang.curl_label} *</label>
                                <textarea id='myTextArea' ref={textareaRef} type='text' style={{ height: '35vh' }} name='Curl' className='form-control form-control-sm' value={dataItem.Curl} onChange={onChange} onKeyDown={onKeyDown} />
                                {showSuggestions && (
                                    <ul className="list-group suggestions mt-1" style={{
                                        position: 'fixed',
                                        top: suggestionPosition.top,
                                        left: suggestionPosition.left,
                                        width: '200px'
                                    }}>
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                className={`list-group-item suggestion-item ${index === activeSuggestionIndex ? 'active' : ''}`}
                                                onClick={() => onSelectSuggestion(suggestion)}
                                            >
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <input
                                    type='checkbox'
                                    name='Archive'
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                                <label className="mr-1 pl-1">
                                    {lang.archive}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    )
}

export default MessageProviderEditForm
