/* eslint-disable */
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import { utility } from "../../../framework/utility/utilityProvider";
import { LOCALSTORAGE_KEY } from "../../../framework/constant/constant";

const ManageContentFoooter = (props, ref) => {

    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    const [selectedItemCount, setSelectedItemCount] = useState(0);
    const [message, setMessage] = useState("");

    useImperativeHandle(ref, () => {
        return {
            setSelectedItemCount: (count) => { setSelectedItemCount(count); },
            setMessage: (msg) => { setMessage(msg); },
        }
    }, []);

    useEffect(() => {
        const onMinify = () => setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
        window.addEventListener('isMinified', onMinify);
        return () => {
            window.removeEventListener('isMinified', onMinify);
        }
    }, []);

    return <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>

        <div className="mx-3" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "100%" }}>
            <div title={'Selected'}>
                <span className="footerText">{selectedItemCount}&nbsp;Selected</span>
            </div>

            <div style={{ textAlign: "right" }}>
                <span className="footerText">{utility.subString(message, 100)}</span>
            </div>
        </div>
    </div>

}

export default memo(forwardRef(ManageContentFoooter));
