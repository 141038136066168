/* eslint-disable */
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { useState } from "react";
import { toast } from "react-toastify";
import * as API from '../../framework/API/api';
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { ENTITYNAME } from "../../framework/constant/constant";
import BossDialog from "../../components/BossDialog";

const FileAttachmentForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        Description: '',
        File: ''
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState(props?.attachmentData ??[]);
    const [showForm, setShowForm] = useState(false);
    const [file, setFile] = useState({});

    const onChange = (e) => {
        if (e.target.name == 'File') {
            console.log(e.target.files[0]);
            setFile(e.target.files[0]);
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (!dataItem.Description || dataItem.Description == "") {
            toast.error(lang.please_enter_description_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.File || dataItem.File == "") {
            toast.error(lang.please_select_file_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        // upload file 
        let uploadFileRes = await API.uploadFile(file);
        if(!uploadFileRes?.success) {
            toast.error(uploadFileRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let finalData ={
            ...dataItem,
            MediaEpisode_id: props?.MediaEpisode_id
        }
        let res = await API.saveData(ENTITYNAME.FileAttachments, finalData);
        if (res?.success) {
            setGridData([...gridData, res?.data]);
            props?.setAttachmentData([...gridData, res?.data]);
        }
        setDataItem(blankDataItem);
        setShowForm(false);
    }

    const deleteAttachment = async (dataItem) => {
        let fileName = dataItem?.File?.split('\\')?.pop();
        await API.deleteFile(fileName);
        await API.deleteData(ENTITYNAME.FileAttachments, dataItem?._id, "_id");
        let deleteData = gridData?.filter(x=> x?._id != dataItem?._id);
        setGridData(deleteData);
        props?.setAttachmentData(deleteData);
    }

    const downloadAttachment = async (dataItem) => {
        let fileName = dataItem.File.split('\\').pop();
        let filePathRes = await API.readFileNameWithContent(fileName);
        console.log(filePathRes);
        if(filePathRes.success) {
            let fileUrl = filePathRes.filePath.split('/').pop();
            await API.downloadFile(fileUrl);
        }
    }

    const MyCommandCell = (props) => (
        <td>
            <RoundButton icon="download" onClick={() => downloadAttachment(props.dataItem)} />
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteAttachment(props.dataItem), () => { })} />
        </td>
    )

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
                        <RoundButton style={{ marginBottom: '5px' }} icon={'link'} title={lang.file_attachments_button_tooltip} onClick={() => setShowForm(true)} />
                        <Grid data={gridData} style={{ height: "30vh" }}>
                            <GridColumn cell={MyCommandCell} width={"100px"} locked={true} />
                            <GridColumn field="Description" title={lang.description_column} editable={false} />
                            <GridColumn field="File" title={lang.file_column} editable={false} />
                        </Grid>
                    </div>
                </div>
            </div>
            {showForm && <BossDialog
                title={lang.file_attachment_dialog_header}
                onClose={() => {setShowForm(false);  setDataItem(blankDataItem);}}
                width={"400px"}
            >
                <div className="m-1">
                    <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={() => {setShowForm(false);  setDataItem(blankDataItem);}} />
                    <div className="mt-2">
                        <label>{lang.description_label} *</label>
                        <input
                            className="form-control form-control-sm"
                            type="text"
                            value={dataItem.Description}
                            onChange={onChange}
                            name="Description"
                        />
                    </div>
                    <div className="mt-2">
                        <label>{lang.file_label} *</label>
                        <div className="d-flex">
                            <input
                                name='File'
                                className="form-control form-control-sm"
                                type='text'
                                value={dataItem.File}
                                onChange={onChange}
                                disabled={true}
                            />
                            <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '0px 0px 0px 3px' }}>
                                <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                                    <input name='File' className='custom-file-updated-input form-control form-control-sm' type="file" hidden="hidden" onChange={(e) => { onChange(e) }} />
                                </label>
                            </button>
                        </div>
                    </div>
                </div>
            </BossDialog>}
        </>
    );
}

export default FileAttachmentForm;