import { useEffect, useState } from "react";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ENTITYNAME, MEDIACATEGORIES, PLATFORMTYPE, PUBLISHING_SOURCE } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import moment from "moment";
import { utility } from "../../../../framework/utility/utilityProvider";
import { Checkbox } from "@progress/kendo-react-inputs";
import MediaEpisodePublishingEditForm from "../../MediaEpisodePublishingEditForm";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import BossDialog from "../../../../components/BossDialog";
import { useTranslation } from "../../../../locale/useTranslation";

const PublishingTab = (props) => {

    const { mediaEpisode, mediaCategory, gridData, setGridData } = props;
    const lang = useTranslation();

    const [showEdit, setShowEdit] = useState(false);
    const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white", wordWrap: "break-word" }
    const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px" }

    const [mediaEpisodePublishings, setMediaEpisodePublishings] = useState([]);
    const [removedMedia, setRemovedMedia] = useState([]);
    const [showLinearTab, setShowLinearTab] = useState(true);
    const [openPublishingDialog, setOpenPublishingDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadEditData();
    }, [mediaEpisode])

    const loadEditData = async () => {
        setIsLoading(true);
        if (!mediaCategory?.isLong) {
            setMediaEpisodePublishings(mediaEpisode.Publishings ?? []);
        } else {
            let mediaDealRightsRes = await API.getData(ENTITYNAME.MediaDealRights, { query: ["media_id", "=", mediaEpisode._id] })
            setMediaEpisodePublishings(mediaDealRightsRes?.data ?? []);
        }
        setIsLoading(false);
    }
    const onClose = () => {
        setShowEdit(!showEdit);
    }

    const onSave = async () => {
        if (mediaEpisodePublishings.length == 0) {
            toast.error(lang.please_add_atleast_one_publishing_rights_error_message)
            return;
        }
        const publishingSource = mediaCategory?.SID == MEDIACATEGORIES.Series ? PUBLISHING_SOURCE.Series : mediaCategory?.SID == MEDIACATEGORIES.Season ? PUBLISHING_SOURCE.Season : PUBLISHING_SOURCE.MediaEpisode;
        let mediaDealRightsRes = await API.updateMediaDealRights(mediaEpisode, removedMedia, mediaEpisodePublishings, publishingSource);
        if (!mediaDealRightsRes.success) {
            toast.error(mediaDealRightsRes.message)
        }
        let updateData = gridData.map((obj) => {
            if (obj.SID === mediaEpisode?.SID) {
                return {
                    ...obj,
                    Publishings: mediaDealRightsRes.data
                }
            } else {
                return obj
            }
        })
        setGridData(updateData);
        onClose();
    }

    const handleMediaPublishingData = (data) => {
        setMediaEpisodePublishings(data);
    }

    const handleRemovePublishingData = (removedDataItem) => {
        setRemovedMedia(old => [...old, removedDataItem]);
    }

    const onPublishinEditClick = (showLinearTab) => {
        setShowLinearTab(showLinearTab);
        onClose();
    }

    console.log(mediaEpisodePublishings);

    return (
        <>
            {isLoading ? <div style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
                <i className="fa fa-spinner fa-spin fa-xl " />
            </div> :
                <>
                    <div style={boxStyle}>
                        {/* Linear */}
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                            <h1 style={headingStyle}>{lang.linear_label}</h1>
                            <RoundButton icon={'pen'} title={'Edit'} onClick={() => onPublishinEditClick(true)} />
                        </div>
                        <div style={{ overflow: "auto" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Channel</th>
                                        <th className="text-nowrap" scope="col">Start Date</th>
                                        <th className="text-nowrap" scope="col">End Date</th>
                                        <th className="text-nowrap" scope="col">Is Unlimited</th>
                                        <th scope="col">Exhibition</th>
                                        <th className="text-nowrap" scope="col">Repeat</th>
                                        <th className="text-nowrap" scope="col">Play Time Number</th>
                                        <th className="text-nowrap" scope="col">Play Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mediaEpisodePublishings?.filter(x => x.PlatformType?.Description == PLATFORMTYPE[0].Description)?.map((publishingData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {utility.displayArrayInPipe(publishingData?.Channel, 'FullChannelName')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishStartDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishEndDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    <Checkbox style={{ borderColor: "grey" }} disabled={true} value={publishingData?.IsUnlimited} />
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.Exhibitions}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.Repeats}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.PlayTimeNumber}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.PlayTime?.Description}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                        {/* Non-Linear */}
                        {mediaCategory?.isLong && <>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                                <h1 style={headingStyle}>{lang.non_linear_label}</h1>
                                <RoundButton icon={'pen'} title={'Edit'} onClick={() => onPublishinEditClick(false)} />
                            </div>
                            <div style={{ overflow: "auto" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Platform</th>
                                            <th className="text-nowrap" scope="col">Start Date</th>
                                            <th className="text-nowrap" scope="col">End Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mediaEpisodePublishings?.filter(x => x.PlatformType?.SID == PLATFORMTYPE[1].SID)?.map((publishingData, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="text-nowrap" style={tableDataStyle}>
                                                        {utility.displayArrayInPipe(publishingData?.Platform, 'Description')}
                                                    </td>
                                                    <td className="text-nowrap" style={tableDataStyle}>
                                                        {moment(publishingData?.PublishStartDate).utc().format('DD-MM-YYYY')}
                                                    </td>
                                                    <td className="text-nowrap" style={tableDataStyle}>
                                                        {moment(publishingData?.PublishEndDate).utc().format('DD-MM-YYYY')}
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </>}
                    </div >

                    {showEdit && <BossDialog
                        title={'Edit Publishing'}
                        onClose={onClose}
                        width={"84vw"}
                    >
                        <SaveButton onClick={onSave}></SaveButton>
                        <CancelButton onClick={onClose}></CancelButton>
                        <div className="mt-3">
                            <MediaEpisodePublishingEditForm mediaCategory={mediaCategory ?? { isLong: true }} mediaEpisodeEntity={mediaEpisode} publishingSource={PUBLISHING_SOURCE.MediaEpisode} data={mediaEpisodePublishings} handlePublishings={handleMediaPublishingData} handleRemovePublishingData={handleRemovePublishingData} showLinearTab={showLinearTab} showNonLinearTab={!showLinearTab} setOpenPublishingDialog={setOpenPublishingDialog} openPublishingDialog={openPublishingDialog} />
                        </div>

                    </BossDialog>
                    }
                </>}
        </>
    )
}

export default PublishingTab;