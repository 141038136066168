/* eslint-disable */
import React, { useState } from "react";
import * as API from "../../../../../src/framework/API/api"
import { useEffect } from "react";
import { ASSETTYPE, ENTITYNAME, LOGOPOSITION, MODULE, THEMES } from "../../../../framework/constant/constant";
import SalesDashboardTile from "../../../../../src/modules/Dashboard/SalesDashboardTile";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "../../../../locale/useTranslation";
import MyStatusCell from "../../../../components/MyStatusCell";
import { DateOnlyCell } from "../../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { Card, Divider } from "@mui/material";
import { utility } from "../../../../framework/utility/utilityProvider";
import {useNavigate } from "react-router-dom";

const CMSDashboard = (props) => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const lang = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [ottSettingData, setOttSettingData] = useState([]);

  const [dataCount, setDataCount] = useState({
    liveRailsCount : 0,
    mediaAssetsCount : 0,
    vodCount : 0,
    campaignCount : 0,
  })

  useEffect(() => {
    loadCMSData();
  }, [])

  const loadCMSData = async () => {

    let json = await API.getCMSDashBoardView();
    setData(json.data);

    let resRecentFiveJob = await API.getRecentFiveJobs();
    setGridData(resRecentFiveJob.data);

    let ottSettingDataRes = await API.getDataLookup(ENTITYNAME.OttSetting, { sort: { applicationName: 1 } });
    let ottSettingData = ottSettingDataRes.data.length > 0 ? ottSettingDataRes.data[0] : "0";
    let themeRes = await API.getDataLookup(ENTITYNAME.Theme, { query: ["Archive", '!=', true] });
    ottSettingData.theme = themeRes.data.length > 0 ? themeRes.data.find((x) => x._id == ottSettingData?.theme)?.Name : "0";
    setOttSettingData(ottSettingData);

    let cmsCountsRes = await API.getCMSCounts();

    console.log(cmsCountsRes.data);

    setDataCount({
      ...dataCount,
      liveRailsCount : cmsCountsRes.data.find(x => x.SID === ASSETTYPE.Live).count,
      mediaAssetsCount : cmsCountsRes.data.find(x=> x?.SID == ASSETTYPE["Media Asset"]).count,
      vodCount : cmsCountsRes.data.find(x=> x?.SID == ASSETTYPE.VOD).count,
      campaignCount : cmsCountsRes.data.find(x=> x?.name == ENTITYNAME.OttCampaign).count
    })

  };

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.CurrentStatus} tileBackColor="#2ba666" />
    </div>
  );

  return (<>
    {data.length > 0 &&
      <div className="widget-body">
        <div className="row" style={{ marginLeft: "3px" }}>
          {data.map((obj, index) => {
            return (
              <div key={index} className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
                <SalesDashboardTile
                  // iconBackColor="#3c83c9" 
                  iconBackColor={obj.background ?? "#3c83c9"}
                  route={obj.route}
                  count={obj.count}
                  icon={obj.icon}
                  status={obj.title}
                  description={lang.view_details_label}
                />
              </div>
            );
          })}
        </div>
        <div className="row" style={{ marginLeft: "3px" }}>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#f58d05"
              count={dataCount.liveRailsCount}
              icon="fa-sharp fa-solid fa-satellite-dish"
              status={lang.live_label}
              description={lang.view_details_label}
              route="/home/OttAssets"
              filter= {ASSETTYPE.Live}
            />

          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#1ca666"
              count={dataCount.mediaAssetsCount}
              icon="fa-sharp fa-solid fa-photo-film"
              status={lang.media_asset_label}
              description={lang.view_details_label}
              route="/home/OttAssets"
              filter= {ASSETTYPE["Media Asset"]}
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#3c83c9"
              count={dataCount.vodCount}
              icon="fa-solid fa-martini-glass-citrus"
              status={lang.vod_label}
              description={lang.view_details_label}
              route="/home/OttAssets"
              filter= {ASSETTYPE.VOD}
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#c72848"
              count={dataCount.campaignCount}
              icon="fa-sharp fa-solid fa-bullhorn"
              status={lang.active_ottcampaign_label}
              description={lang.view_details_label}
              route="/home/OttCampaign"
            />
          </div>
        </div>
      </div>
    }
    <div className="row ml-1" >
      <div className="col-sm-12 col-md-6 col-lg-6">
        <div className="row dashboard-title-style" style={{fontSize: '15px', height: '35px'}}>
          &nbsp; <div className="mt-1">{lang.latest_jobs_label}</div>
        </div>
        <div className="row">
          <Grid data={gridData} >
            <Column field="FileName" title={lang.file_name_column} />
            <Column field="AssetId" title={lang.assetId_column} />
            <Column field="LastUpdated" title={lang.last_updated_label} cell={DateOnlyCell} />
            <Column cell={Mystatuscell} title={lang.status_column} locked={true} />
          </Grid>
        </div>
      </div>
      <div className=" col-sm-12 col-md-6 col-lg-6" style={{fontSize: '14.5px'}}>
        <Card >
          <div className='row' style={{ boxSizing: "inherit", height: "400px" }}>
            <div className="col-sm-12">
              <div className="row dashboard-title-style" style={{height: "35px", }}>
                  <i className="fa-solid fa-gear mt-2" style={{ fontSize: "1.40em", left: "30px", height: "12px", position: "absolute",color: "white" }}></i>
                  <div className="col">
                    <div className="float-left mt-1" style={{marginLeft: "40px", fontSize: "15px", color: "white", fontWeight: "500" }}>{lang.ott_setting_label}</div>
                    <div className='float-right mt-1 mr-1'>                      
                        <button title={lang.edit_button_tooltip} type="submit" onClick={(e) => {e.preventDefault(); navigate("/home/OttSetting");}} style={{ borderRadius: "20px", textAlign: 'center', height:'24px', width:'24px', pointerEvents: props.disabled ? "none" : "auto", boxShadow: "none" }} className="btn btn-default btn-circle btn-my">
                          <i className="fa fa-pen fa-fw img-circle " style={{top: "-3.5px"}}></i>
                        </button>
                    </div>
                  </div>
              </div>
              <div className='row mt-2'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "17px",color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.logo_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "12px"}}>
                  <img src={ottSettingData.logo} width="150px" />
                </div>
              </div>
              <div className='row'>
                <div className="ottSetting_cald_label col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px",color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.application_name_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px"}}>
                  {ottSettingData.applicationName}
                </div>
              </div>

              <div className='row'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px",color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.application_color_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px"}}>
                  <input
                    type="color"
                    name="applicationColor"
                    className="cursor-pointer"
                    disabled="true"
                    value={ottSettingData.applicationColor}
                  />
                </div>
              </div>
              <div className='row'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px", color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.logo_size_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px" }}>
                  {ottSettingData.logoSize}
                </div>
              </div>

              <div className='row'>
                <div className=" col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px", color: "#8b8b8d", fontWeight: "500"}}>
                   {lang.logo_position_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px" }}>
                  {utility.getKeyByValue(LOGOPOSITION, ottSettingData.logoPostion)}
                </div>
              </div>

              <div className='row'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px", color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.theme_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px" }}>
                  {ottSettingData?.theme ?? ''}
                </div>
              </div>

              <div className='row'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px", color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.bottom_menu_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px" }}>
                  {ottSettingData.showBottomMenu ? "Enable" : "Disable"}
                </div>
              </div>

              <div className='row'>
                <div className="col-5" style={{ textAlign: "left", marginLeft: "25px", marginTop: "10px", color: "#8b8b8d", fontWeight: "500"}}>
                  {lang.hamburger_menu_label} :
                </div>
                <div className='col-5' style={{ textAlign: "left", marginTop: "10px" }}>
                  {ottSettingData.showHamburgerMenu ? "Enable" : "Disable"}

                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </>
  );
};

export default CMSDashboard;
