
/* eslint-disable */
import React from "react";
import { Card } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import { useTranslation } from "../../locale/useTranslation";

const ActiveDashboardTile = (props) => {
    const lang = useTranslation();

    return (

        <Card style={{ borderRadius: "10px", padding: "16px" }}>
            <div className="summaryCardContent">
                <div className="row summaryCardRow">
                    <div className="summaryCardIconContainer" style={{ backgroundColor: props?.tileBackColor }}>
                        <i className={`fa-sharp fa-solid fa-${props?.icon} summaryDashboardicon_card`} style={props?.style}></i>
                    </div>
                    <div className="summaryCardDetails">
                        <div className='summaryCardSection'>
                            <div className="summaryDashboard_Count">{props?.totalCount}</div>
                            <div className="summaryDashboard_text">{props?.name}</div>
                        </div>
                        <Divider layout="vertical" />
                        <div className=' summaryCardSection mt-1'>
                            <div className="summaryDashboard_Count">{props?.Activecount}</div>
                            <div className="summaryDashboard_text">{lang.active_label}</div>
                            <div className="summaryDashboard_text">{props?.name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default ActiveDashboardTile