/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as API from "../../framework/API/api";
import { DAYS, ENTITYNAME, LOGEVENT, MODULE } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { utility } from '../../framework/utility/utilityProvider';
import moment from 'moment';
import { EditPageHeader } from '../../components/EditPageHeader';
import { TimeRangePicker } from '../../framework/forms/helpercomponents/timepicker/TimeRangePicker';
import BossDialog from '../../components/BossDialog';

const RateCardEditForm = (props) => {

  const lang = useTranslation();
  let blankDataItem = {
    SID: props.item.SID ?? 0,
    Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
    Channel: props.item.Channel ?? {},
    ValidDays: props.item.ValidDays ?? [],
    FromDate: moment(new Date(props.item.FromDate ?? new Date())).format('YYYY-MM-DD'),
    ToDate: moment(new Date(props.item.ToDate ?? new Date())).format('YYYY-MM-DD'),
    TimeIn: utility.convertMilisecondsToStringWithFrames(props.item.TimeIn ?? '00:00'),
    TimeOut: utility.convertMilisecondsToStringWithFrames(props.item.TimeOut ?? '00:00'),
    Rate10Sec: props.item.Rate10Sec ?? 0,
    Archive: props.item.Archive ?? false,

  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [channel, setChannel] = useState([]);

  useEffect(() => {
    loadcombo();
  }, []);


  const loadcombo = async () => {
    let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);

  }

  const onChange = async (e) => {
    if (e.target.name == "TimeIn" || e.target.name == "TimeOut") {
      setDataItem({ ...dataItem, [e.target.name]: utility.validateShortTimeString(e.target.value) });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  function validationCheck() {

    if (dataItem.Description == '' || dataItem.Description == undefined || !dataItem.Description.trim()) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Channel || Object.keys(dataItem.Channel).length == 0) {
      toast.error(`${lang.please_select_channel_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ValidDays.length == 0) {
      toast.error(`${lang.please_select_days_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.FromDate == undefined || dataItem.FromDate == '') {
      toast.error(lang.please_enter_from_date_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.ToDate == undefined || dataItem.ToDate == '') {
      toast.error(lang.please_enter_to_date_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.TimeIn == undefined || dataItem.TimeIn == '') {
      toast.error(lang.please_enter_time_in_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.TimeOut == undefined || dataItem.TimeOut == '' || dataItem.ToTime == "00:00") {
      toast.error(lang.please_enter_time_out_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.TimeIn >= dataItem.TimeOut) {
      toast.error(`${lang.time_in_should_be_less_than_time_out_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Rate10Sec == undefined || dataItem.Rate10Sec == '') {
      toast.error(lang.please_enter_rate10sec_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onSaveData = async () => {

    console.log(dataItem.TimeIn);

    if (validationCheck()) {
      const data = {
        // ...dataItem,
        SID: dataItem.SID,
        Description: dataItem.Description,
        Channel:
        {
          _id: dataItem.Channel._id,
          SID: dataItem.Channel.SID,
          FullChannelName: dataItem.Channel.FullChannelName
        },
        ValidDays: { ID: dataItem.ValidDays.ID, Name: dataItem.ValidDays.Name, SubName: dataItem.ValidDays.SubName },
        FromDate: utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.FromDate)),
        ToDate: utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.ToDate)),
        TimeIn: utility.convertShortTimeStringToMilliSeconds(dataItem.TimeIn),
        TimeOut: utility.convertShortTimeStringToMilliSeconds(dataItem.TimeOut),
        Rate10Sec: dataItem.Rate10Sec,
        Archive: dataItem.Archive ? true : false,
      }
      console.log(data)

      let res = await API.saveData(ENTITYNAME.RateCard, data);
      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.RATECARD, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.cancelEdit();
        props.refresh();
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

  return (
    <BossDialog
      title={props.SID > 0 ? dataItem.Title : `${lang.create_ratecard_dialog_header}`}
      onClose={props.cancelEdit}
      width={"60vw"}
    >
      <div className="row">
        <div className=" col-12">
          <EditPageHeader onSubmit={onSaveData} onCancel={props.cancelEdit} showTitle={false} />
          <div className="row mt-2" >
            <div className='col-12'>
              <div className="form-group">
                <label htmlFor="">{lang.description_label} *</label>
                <input name="Description" type="text" className="form-control form-control-sm" value={dataItem.Description} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="TabView">{lang.channel_label} *</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={channel}
                  name="Channel"
                  textField="FullChannelName"
                  dataItemKey="_id"
                  value={dataItem.Channel}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="TabView">{lang.days_label} *</label>
                <MultiSelect
                  style={{
                    backgroundColor: "white",
                  }}
                  data={DAYS}
                  name="ValidDays"
                  textField="Name"
                  dataItemKey="ID"
                  value={dataItem.ValidDays}
                  onChange={onChange}
                  validator={(value) => value ? "" : "Please select the value"}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.from_label} </label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  name="FromDate"
                  value={dataItem.FromDate}
                  onChange={onChange}
                  max={dataItem.ToDate}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.to_label} </label>
                <input
                  type="date"
                  className="form-control form-control-sm"
                  name="ToDate"
                  value={dataItem.ToDate}
                  onChange={onChange}
                  min={dataItem.FromDate}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.time_in_label} *</label>
                <TimeRangePicker
                  className="form-control form-control-sm mt-1"
                  name="TimeIn"
                  value={dataItem.TimeIn}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="">{lang.time_out_label} *</label>
                <TimeRangePicker
                  className="form-control form-control-sm mt-1"
                  name="TimeOut"
                  value={dataItem.TimeOut}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <div className="form-group">
                <label htmlFor="">{lang.rate_10sec_label} *</label>
                <input name="Rate10Sec" type="number" className="form-control form-control-sm" value={dataItem.Rate10Sec} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <input style={{ marginTop: "05px" }} type="checkbox" name={"Archive"} checked={dataItem.Archive} onChange={onChange} /> {lang.archive}
            </div>
          </div>
        </div>
      </div>
    </BossDialog>
  )
}

export default RateCardEditForm