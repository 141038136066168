/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import moment from "moment";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Switch } from "@mui/material";
import { utility } from "../../framework/utility/utilityProvider";

function OttCampaignEditForm() {

    const { SID } = useParams();
    const loc = useLocation();

    let blankDataItem = {
        SID: SID,
        Name: "",
        Description: "",
        FromDate: "",
        ToDate: "",
        Segments: [],
        Rails: [],
        Publish: false,
        Archive: false,
        TBA : false

    }

    const [segments, setSegments] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [showRails, setShowRails] = useState(false);

    const navigate = useNavigate();
    const lang = useTranslation();

    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadEditData = async () => {

        let linkData = {
            link_collection: 'ott_rails',
            link_field: 'Rails'
        }

        var res = await API.getEntity(ENTITYNAME.OttCampaign, parseInt(SID), linkData);
        if (res.success) {
            setDataItem({
                ...res.data,
                SID: loc.state.copy ? 0 : parseInt(SID),
                Name: loc.state?.copy ? "Copy of " + res.data.Name : res.data.Name,
                TBA : res.data.TBA ?? false,
                FromDate: moment(new Date(res.data.FromDate)).format('YYYY-MM-DD'),
                ToDate: res.data.TBA ? '' : moment(new Date(res.data.ToDate)).format('YYYY-MM-DD'),
            })
            setGridData(() => res.data.Rails);
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const loadcombo = async () => {
        var segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '=', false], sort: { Description: 1 } });
        setSegments(segmentsRes.data);
    }

    const onChange = (e) => {

        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }
        else if (e.target.name == "Publish") {
            setDataItem({ ...dataItem, Publish: !dataItem.Publish });
        }
        else if (e.target.name == "TBA") {
            setDataItem({ ...dataItem, ToDate : '' , TBA: !dataItem.TBA });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Name == undefined || dataItem.Name == '') {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Description == undefined || dataItem.Description == '') {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.FromDate == undefined || dataItem.FromDate == '') {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.TBA && (dataItem.ToDate == undefined || dataItem.ToDate == '')) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.TBA && (dataItem.FromDate > dataItem.ToDate)) {
            toast.error(`${lang.from_date_should_be_less_than_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Segments.length == 0) {
            toast.error(`${lang.please_select_segment_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.length == 0) {
            toast.error(`${lang.please_select_rails_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const onSave = async () => {

        if (isValid()) {
            let saveData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Description: dataItem.Description,
                FromDate: new Date(dataItem.FromDate).getTime(),
                ToDate: dataItem.TBA ? 0 : new Date(dataItem.ToDate).getTime(), //unlimited
                Segments: dataItem.Segments.map((x) => {
                    return { _id: x._id, SID: x.SID, Description: x.Description }
                }),
                Rails: gridData.length > 0 ? gridData.map(x => x._id) : [],
                Publish: dataItem.Publish ?? false,
                TBA: dataItem.TBA ?? false,
                Archive: dataItem.Archive ?? false,
            }
            loc.state.copy && delete saveData._id
            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.OttCampaign, saveData);
            console.log(res.data);
            if (res.success) {
                let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_OTT_CAMPAIGN : LOGEVENT.UPDATE_OTT_CAMPAIGN, module: MODULE.OTT_CAMPAIGN, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.OttCampaign);
                navigate("/home/OttCampaign");
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const handleRails = (dataList) => {
        let newData = utility.checkDuplicateInData(dataList, gridData, "Title")
        setGridData([...gridData, ...newData]);
    }

    const removeRails = (dataItem) => {
        setGridData(gridData.filter((obj) => dataItem.SID != obj.SID));
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeRails(props.dataItem), () => { })} />
        </div>
    )

    return (<>
        <div className="row m-1">
            <Form
                initialValues={dataItem}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="col-12">
                            <EditPageHeader title={`${lang.ott_campaign_page_breadcrumb} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_button_dialog_header}`)} onSubmit={onSave} onCancel={() => navigate(-1)} />
                            <div className="row mt-2">
                                <div className="col-4">
                                    <label>{lang.name_label} *</label>
                                    <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={onChange} value={dataItem.Name} />
                                </div>
                                <div className="col-8">
                                    <label>{lang.description_label} *</label>
                                    <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Description"} onChange={onChange} value={dataItem.Description} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-4">
                                    <label htmlFor="">{lang.from_date_label} *</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="FromDate"
                                        value={dataItem.FromDate}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="">{lang.to_date_label} *</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="ToDate"
                                        value={dataItem.ToDate}
                                        onChange={onChange}
                                        disabled = {dataItem.TBA}
                                    />
                                </div>
                                <div className="col-1" style={{padding : '30px 15px 0px'}}>
                                    <input type="checkbox" name={"TBA"} value={dataItem.TBA} checked={dataItem.TBA} onChange={onChange} />
                                    <label className="pl-2" htmlFor="TabView">{lang.tba_label}</label>
                                </div>
                            </div>
                            <div className="row mt-3">

                            <div className="col-4">
                                    <label htmlFor="TabView">{lang.segment_label} *</label>
                                    <MultiSelect
                                        style={{ backgroundColor: "white" }}
                                        data={segments}
                                        name="Segments"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Segments}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-1" style={{padding : '28px 15px 0px'}}>
                                    <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                                    <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                                </div>
                                <div className="col-2 pt-4">
                                    <Field
                                        name={"Publish"}
                                        component={Switch}
                                        label={lang.publish_label}
                                        onLabel={lang.rails_p_label}
                                        offLabel={lang.rails_up_label}
                                        size={"small"}
                                        onChange={onChange}
                                        checked={dataItem.Publish}
                                    />
                                    <span className={dataItem.Publish ? " pl-1 text-success" : " pl-1 text-danger"}>{dataItem.Publish ? `${lang.published_label}` : `${lang.unpublished_label}`}</span>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col">
                                        <AddRoundButton onClick={() => setShowRails(true)} title={lang.add_rails_button_tootip} />  
                                        </div>
                                        <div className="col">
                                        <p className="muted" style={{textAlign:'right', margin : '16px 16px 0px 0px', fontSize:"17px"}}>{lang.rails_label}</p>
                                        </div>
                                    </div>
                                    <Grid data={gridData} style={{ height: '55vh', margin: '5px 0px' }}>
                                        <GridColumn cell={MyCommandCell} width={"35px"} locked={true} />
                                        <GridColumn field="Title" title={lang.title_column} editable={false} />
                                        <GridColumn field="Publish" title={lang.publish_column} editable={false} />
                                        <GridColumn field="StartDate" title={lang.start_date_column} editable={false} cell={DateOnlyCell} />
                                        <GridColumn field="EndDate" title={lang.end_date_column} editable={false} cell={DateOnlyCell} />
                                    </Grid>
                                </div>
                                {showRails && <CollectionSelection entityname={ENTITYNAME.Rails} title={lang.select_rails_collection} closeForm={() => setShowRails(false)} setDataList={handleRails} width={"50vw"} height={"64vh"} />}
                            </div>
                        </div>
                    </FormElement>
                )}
            />
        </div>
    </>)
}

export default OttCampaignEditForm;