/* eslint-disable */
import React, { useState, useEffect } from "react";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { ENTITYNAME, DAYS } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import moment from "moment";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import BossDialog from "../../components/BossDialog";

const PlanningDistribution = (props) => {

    const MILLISECONDSINADAY = 86400000;

    const blankDataItem = {
        Channel: [props.selectedData.Channel], // planned item channel 
        MinimumGap: '03:00:00:00', // 3 hours by default
        ApplyFixGap: false, // retry slots
        PlanTill: moment(new Date(props.selectedData.SlotDateTime + (7 * MILLISECONDSINADAY))).format('YYYY-MM-DD'), // + 7 days
        MaxPlacement: 0, // unlimited
        IsUnlimited: true,// unlimited
        ValidDays: [], // all
        RetryGap: '00:15:00:00' // 15 mins default
    }
    useEffect(() => {
        loadcombo();
    }, []);

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channel, setChannel] = useState([]);
    const [template, setTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [hideSaveTemplateCheckBox, setHideSaveTemplateCheckBox] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [isUpdate, setIsUpdate] = useState(false);

    const lang = useTranslation();

    const loadcombo = async () => {
        let templateRes = await API.getDataLookup(ENTITYNAME.PlanningDistributionTemplate);
        setTemplate(templateRes.data);
        let channelsRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelsRes.data);
    }

    const onChange = (e) => {

        if (e.target.name == 'isUpdate') {
            setIsUpdate(e.target.checked);
            return;
        }

        if (e.target.name == 'Template') {
            setSelectedTemplate(e.target.value);
            let selectedTemplate = e.target.value;
            setDataItem({
                Channel: selectedTemplate.Channel,
                MinimumGap: utility.convertMilisecondsToStringWithFrames(selectedTemplate.MinimumGap),
                ApplyFixGap: selectedTemplate.ApplyFixGap,
                PlanTill: moment(new Date(props.selectedData.SlotDateTime + (selectedTemplate.EndDaysGap * MILLISECONDSINADAY))).format('YYYY-MM-DD'),
                MaxPlacement: selectedTemplate.MaxPlacement,
                IsUnlimited: selectedTemplate.MaxPlacement == 0,
                ValidDays: selectedTemplate.ValidDays,
                RetryGap: utility.convertMilisecondsToStringWithFrames(selectedTemplate.RetryGap),
            });
            setHideSaveTemplateCheckBox(true);
            return;
        }

        if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
            return;
        }
        if (e.target.name == 'IsUnlimited') {
            setDataItem({ ...dataItem, IsUnlimited: !dataItem.IsUnlimited, MaxPlacement: parseInt(0) });
            return;
        }
        if (e.target.name == 'ApplyFixGap') {
            setDataItem({ ...dataItem, ApplyFixGap: !dataItem.ApplyFixGap });
            return;
        }
        if (e.target.name == 'SaveAsTemplate') {
            return;
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (saveAsTemplate && (templateName == '' || templateName == undefined)) {
            toast.error(`${lang.please_enter_template_name_error_toast}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Channel.length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.MinimumGap == '' || dataItem.MinimumGap == undefined) {
            toast.error(`${lang.please_enter_minimum_gap_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.PlanTill == '' || dataItem.PlanTill == undefined) {
            toast.error(`${lang.please_select_plan_till_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.IsUnlimited && (dataItem.MaxPlacement < 1)) {
            toast.error(`${lang.please_enter_max_placement_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ValidDays.length == 0) {
            toast.error(`${lang.please_select_days_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.RetryGap == '' || dataItem.RetryGap == undefined) {
            toast.error(`${lang.please_enter_retry_gap_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (new Date(dataItem.PlanTill).getTime() <= props.selectedData.SlotDateTime) {
            toast.error(lang.plan_till_date_cannot_be_less_than_original_planned_item_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onConfirmSaveTemplate = async () => {

        if (isValid()) {

            let slotDate = moment(new Date(props.selectedData.SlotDateTime)).format('YYYY-MM-DD');

            let EndDaysGap = (new Date(dataItem.PlanTill).getTime() - new Date(slotDate).getTime()) / 86400000;

            let saveData = {
                SID: selectedTemplate.SID ? selectedTemplate.SID : 0,
                Name: saveAsTemplate ? templateName : !selectedTemplate.Name ? '' : selectedTemplate.Name,
                Channel: {_id:dataItem.Channel._id, SID:dataItem.SID, FullChannelName:dataItem.Channel.FullChannelName} ,
                MinimumGap: utility.convertStringWithFramesToMilliseconds(dataItem.MinimumGap),
                ApplyFixGap: dataItem.ApplyFixGap ?? false,
                EndDaysGap: EndDaysGap,
                PlanTill: new Date(dataItem.PlanTill).getTime(),
                MaxPlacement: dataItem.MaxPlacement,
                ValidDays: {ID:dataItem.ValidDays.ID, Name:dataItem.ValidDays.Name, SubName:dataItem.ValidDays.SubName},
                RetryGap: utility.convertStringWithFramesToMilliseconds(dataItem.RetryGap),
            }
            console.log(saveData);

            if (saveAsTemplate ? saveAsTemplate : isUpdate) {

                let res = await API.saveData(ENTITYNAME.PlanningDistributionTemplate, saveData);

                if (res.success) {
                    props.closeForm();
                    setHideSaveTemplateCheckBox(true);
                    setSaveAsTemplate(false);
                    utility.deleteLocalStorageItem(ENTITYNAME.PlanningDistributionTemplate);
                } else {
                    toast.error(res.msg, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }

            }
            props.closeForm();
            setHideSaveTemplateCheckBox(true);
            setSaveAsTemplate(false);
            props.setDistributionTemplateData(saveData);

        }
    }

    const onClear = () => {
        setHideSaveTemplateCheckBox(false);
        setDataItem(blankDataItem);
        setSelectedTemplate({});
        setSaveAsTemplate(false);
        setTemplateName('');
    }

    const deleteSelectedItem = async () => {

        const res = await API.deleteData(ENTITYNAME.PlanningDistributionTemplate, selectedTemplate.SID);
        if (res.success) {
            utility.deleteLocalStorageItem(ENTITYNAME.PlanningDistributionTemplate);
            onClear();
            loadcombo();
        }
    }

    const clearSelectedTemplate = () => {
        onClear();
        setSelectedTemplate({
            Name: ''
        });
    }


    return <>
        <BossDialog
            title={lang.planning_distribution_label}
            width={"630px"}
            onClose={props.closeForm}
        >
            <div className='row'>
                <div className="col-12">
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success mr-2" onClick={onConfirmSaveTemplate} >{lang.apply_button_text}</button>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error" onClick={props.closeForm} >{lang.cancel_button_text}</button>
                    <div className="row mt-4">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.template_label} : </label>
                        </div>
                        <div className={hideSaveTemplateCheckBox ? "col-6" : "col-8"}>
                            <div className="form-group">
                                <DropDownList
                                    data={template}
                                    name={"Template"}
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={selectedTemplate}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="ml-2">
                            {hideSaveTemplateCheckBox && <button className={`btn btn-default btn-circle btn-my`} onClick={() => ConfirmDeleteAlert(() => deleteSelectedItem(), () => { })} type="button" title={lang.delete_button_tooltip} style={{ marginLeft: "-15px" }}>
                                <i className="fa fa-trash" aria-hidden="true" ></i>
                            </button>}
                        </div>
                        {hideSaveTemplateCheckBox && <div className="ml-4">
                            <button className={`btn btn-default btn-circle btn-my`} onClick={clearSelectedTemplate} type="button" title={lang.clear_button_tooltip} style={{ marginLeft: "-15px" }}>
                                <i className="fa fa-remove" aria-hidden="true" ></i>
                            </button>
                        </div>}
                    </div>
                    {!hideSaveTemplateCheckBox && <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    name={"SaveAsTemplate"}
                                    type={"checkbox"}
                                    value={saveAsTemplate}
                                    onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                    checked={saveAsTemplate}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>}

                    {hideSaveTemplateCheckBox && <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.update_template_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input type={"checkbox"} name={"isUpdate"}
                                    onChange={(e) => onChange(e)}
                                    value={isUpdate}
                                    checked={isUpdate} />
                            </div>
                        </div>
                    </div>}

                    {(saveAsTemplate ? saveAsTemplate : isUpdate) && <div className="row">
                        <div className="col-4 mt-2">
                            <label htmlFor="TabView">{lang.template_name_label} * : </label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name={"templateName"}
                                    value={templateName ? templateName : selectedTemplate.Name}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.channel_label} * : </label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={channel}
                                    name={"Channel"}
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.min_gap_label} * : </label>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="MinimumGap"
                                    value={dataItem.MinimumGap}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group " style={{ marginTop: '8px' }}>
                                <label htmlFor="TabView">{lang.apply_fix_gap_label}</label>
                                <input
                                    style={{ marginLeft: '10px' }}
                                    name={"ApplyFixGap"}
                                    type={"checkbox"}
                                    value={dataItem.ApplyFixGap}
                                    checked={dataItem.ApplyFixGap}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.plan_till_label} * : </label>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <input type={'date'}
                                    name={"PlanTill"}
                                    placeholder={""}
                                    onChange={onChange}
                                    value={dataItem.PlanTill}
                                    className="form-control form-control-sm"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.max_placement_label}  * : </label>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    name={"MaxPlacement"}
                                    value={dataItem.MaxPlacement}
                                    onChange={onChange}
                                    disabled={dataItem.IsUnlimited}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group" style={{ marginTop: '8px' }}>
                                <label htmlFor="TabView">{lang.is_unlimited_label}</label>
                                <input
                                    style={{ marginLeft: '10px' }}
                                    name={"IsUnlimited"}
                                    type={"checkbox"}
                                    value={dataItem.IsUnlimited}
                                    checked={dataItem.IsUnlimited}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.valid_days_label} * :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={DAYS}
                                    name={"ValidDays"}
                                    textField="Name"
                                    dataItemKey="ID"
                                    value={dataItem.ValidDays}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.retry_gap_label} * :</label>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <TimePickerWithFormat
                                    className="form-control form-control-sm"
                                    name="RetryGap"
                                    value={dataItem.RetryGap}
                                    onChange={(e) => onChange(e)}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </BossDialog >
    </>
}




export default PlanningDistribution;