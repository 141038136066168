/* eslint-disable */
import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, GridColumn as Column, GridNoRecords, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { ENTITYNAME, } from '../../framework/constant/constant';
import BossDialog from '../../components/BossDialog';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { LocalDateTimeColumn } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { AuditLogResponse } from '../Audit Log/AuditLogResponse';
import moment from 'moment/moment';
const JobScheduleHistory = memo(({ jobSchedule, closeForm }) => {

    useEffect(() => {
        loadHistory();
    }, [jobSchedule]);

    const lang = useTranslation();

    const [gridData, setGridData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(`${lang.data_loading_error_message}`);
    const [showResponse, setShowResponse] = useState(false);
    const responseRef = useRef();
    const [response, _setResponse] = useState({});
    const [showResponseOpen, setShowResponseOpen] = useState(false);
    const setResponse = (data) => {
        responseRef.current = data;
        _setResponse(data);
    }


    const [oldValue, setOldValue] = useState({});
    const [newValue, setNewValue] = useState({});
    const [detailsValue, setDetailsValue] = useState({});


    const loadHistory = async () => {

        let tempData = []

        let histortRes = await API.getData(ENTITYNAME.Jobs, {
            query: [["js_jobschedule._id", '=', jobSchedule._id]],
        });

        if (histortRes.success) {
            let data = histortRes.data.map((x) => {
                return { ...x, response: JSON.stringify(x.response, null, 2) }
            })
            tempData = [...tempData, ...data]
        } else {
            setLoadingStatus(`${lang.no_record_found_error_message}`);
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
        }

        setGridData(tempData)
        setFilterData(tempData)
        if (tempData.length == 0) {
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }

    }

    const downloadExcel = async () => {
        console.log(gridData.length);
        // if (gridData.length == 0) {
        //     toast.info(lang.no_data_found_error_message, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return;
        // }

        // let storeData = gridData.map(x => {
        //     return {
        //         Title: x.mediaEpisode.Title,
        //         Channel: x.ChannelName,
        //         SlotDate: moment(new Date(x.SlotDateTime)).format("YYYY-MM-DD"),
        //         SlotTime: utility.convertMilisecondsToStringWithFrames(x.SlotDateTime),
        //         ExhibitionNumber: x.ExhibitionNumber,
        //         RepeatNumber: x.RepeatNumber
        //     }
        // })

        // let res = await API.exportCSV(storeData);
        // if (res.success) {
        //     window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
        //     toast.success(`${lang.successfully_download_success_message}`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }
        // else {
        //     toast.error(res.message, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }
    }
    const onChange = (e) => {
        let tempData = [];
        if (e.target.value.SID == 0) {
            tempData = gridData;
        } else {
            tempData = gridData.filter((x) => x.segment?.SegmentType?._id == e.target.value._id);
        }
        setSegmentTypeDataItem(e.target.value);
        console.log(tempData);
        setFilterData(tempData);

    }

    const ResponseCell = (props) => {
        return (
            <td
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
                {...{
                    [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
                }}
                {...useTableKeyboardNavigation(props.id)}
                style={props.style}
            >
                <RoundButton
                    icon="eye"
                    onClick={() => { setShowResponse(true); setResponse(props.dataItem.response); handleShowResponse(props.dataItem) }} />
            </td>
        );
    }

    let responseData = (response) => {
        let responseRes;
        try {
            responseRes = typeof response === "string" ? JSON.parse(response) : response;
        } catch (error) {
            console.error(error);
            return null;
        }
        
        const messageRes = responseRes.message
            .replace(/\\n/g, " ")
            .replace(/\\\\/g, "\\");
    
        const [messageData, ...rest] = messageRes.split(":");
        const pathMatch = rest.join(":").match(/C:\\[^ ]+/);
        const path = pathMatch ? pathMatch[0] : '';
    
        return {
            success: responseRes.success,
            message: messageData.trim(),
            path: path
        };
    };
    
    const updateResponseValue = (item) => {
        if (item?.response) {
            const responseRes = responseData(item?.response);
            setNewValue(responseRes);
        } else {
            setNewValue(item);
        }
    };
    
    const handleShowResponse = async (item) => {
        updateResponseValue(item);
        setOldValue({});
        setDetailsValue({
            EntryDateTime: item?.datetime ? moment(item.datetime).format('YYYY-MM-DD HH:mm:ss') : '',
            User: item?.user?.name || '',
            Event: ""
        });
    };

    return (<>
        <BossDialog
            title={"Jobs"}
            onClose={closeForm}
            width={"60%"}
        >
            <div className='display-flex'>
                <div className='float-left'>
                    {gridData.length > 0 && <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '5px' }} title={lang.download_button_tooltip} />}
                </div>
            </div>
            <br /><br />
            <Grid data={filterData} style={{ height: "50vh", marginTop: "04px", overflow: "hidden" }}>
                <GridNoRecords>{loadingStatus}</GridNoRecords>
                <Column field="transaction_id" title="Transcation ID" width={150} />
                <Column field="status" title="Status" width={120} />
                <Column field="datetime" title="Date Time" width={160} cell={LocalDateTimeColumn} />
                <Column field="actiontype" title="Action Type" width={100} />
                <Column field="user.name" title="User" width={100} />
                <Column field="request.RequestType.Description" title="Request" width={160} />
                <Column field="response" title="Response" width={70} cell={ResponseCell} />
            </Grid>
        </BossDialog>

        {showResponse && <BossDialog
            title={"Response"}
            onClose={() => setShowResponse(false)}
            width={"50%"}
        >
            {
                <div>
                    <div className='row'>
                        <div className="col-12">
                            <label>{lang.row_label}</label>
                            <button className="table-button"
                                type='button'
                                style={{
                                    backgroundColor: "#337ab7",
                                    color: "white",
                                    border: " none",
                                    borderRadius: "20px",
                                    padding: " 8px 16px",
                                    fontSize: "8px",
                                    float: "right",
                                    gap: "8px"
                                }}
                                onClick={() => setShowResponseOpen(true)}
                            >{lang.table_view_label}
                            </button>
                            {showResponseOpen && (
                                <AuditLogResponse
                                    details={detailsValue}
                                    oldValue={oldValue}
                                    newValue={newValue}
                                    onClose={() => setShowResponseOpen(false)}
                                />
                            )}
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <textarea
                                className="form-control form-control-sm"
                                type="text"
                                name={"Response"}
                                onChange={onChange}
                                value={response}
                                disabled={true}
                                rows={10}
                            />
                        </div>
                    </div>
                </div>
            }
        </BossDialog>}
    </>)
});

export default JobScheduleHistory;