/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { toast } from "react-toastify";
import * as API from "../../framework/API/api"
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { IMDBTYPES } from "../../framework/constant/constant";
import { getter } from "@progress/kendo-data-query";
import { utility } from "../../framework/utility/utilityProvider";
import CustomPaginationDropDown from "../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "imdbID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const IMDBEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        SearchName: "",
        Type: props?.mediaCategory ? {Description: utility.getKeyByValue(IMDBTYPES, props?.mediaCategory), SID: props?.mediaCategory} : {}
    }

    const getDataWithIMDBId = async (imdbId) => {
        let res = await API.getIMDBDetails(imdbId);
        if (res.success) {
            setGridData([res.data]);
            setDataItem({...dataItem, SearchName: res.data.Title});
            setSelectedItemDetails(res.data);
            setSelectedState({ [imdbId]: true });
        }
    }

    useEffect(() => {
        if (props?.IMDbID) {
            getDataWithIMDBId(props?.IMDbID);
        }
    }, [props?.IMDbID]);

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [selectedItemDetails, setSelectedItemDetails] = useState({});
    const initialDataState = {
        skip: 0,
        take: 10,
    };
    const [page, setPage] = useState(initialDataState);
    const [total, setTotal] = useState(1);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSearch = async () => {

        if (!dataItem.SearchName || dataItem.SearchName == "") {
            toast.error(lang.please_enter_search_name_error_message);
            return;
        }
        if (!dataItem.Type || Object.keys(dataItem.Type).length == 0) {
            toast.error(lang.please_select_type_error_message);
            return;
        }
        let payload = {
            SearchName: dataItem.SearchName ?? "",
            Type: dataItem.Type?.Description ?? "",
            Page: 1
        }
        let res = await API.getIMDBData(payload);
        console.log(res);
        if (res.success) {
            setPage(initialDataState);
            setGridData(res.data);
            setTotal(res.pagination?.total);
            setSelectedState({});
            setSelectedItemDetails({});
        } else {
            toast.error(res.message);
        }
    }

    const onSelectionChange = useCallback(
        async (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            let imdbId = gridData.find((item) => newSelectedState[item.imdbID])?.imdbID;
            let res = await API.getIMDBDetails(imdbId);
            console.log(res);
            if (res.success) {
                setSelectedItemDetails(res.data);
            }
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const pageChange = async (e) => {
        let payload = {
            SearchName: dataItem.SearchName ?? "",
            Type: dataItem.Type?.Description ?? "",
            Page: (e.page.skip / e.page.take) + 1,
        }
        let res = await API.getIMDBData(payload);
        if (res.success) {
            setGridData(res.data);
            setSelectedState({});
            setSelectedItemDetails({});
            setPage(e.page);
        } else {
            toast.error(res.message);
        }
    };

    const handleSubmit = async () => {
        if (gridData.filter((item) => selectedState[idGetter(item)]).length == 0) {
            toast.error(lang.please_select_item_first_error_message);
            return;
        }
        props.imdbDataList(selectedItemDetails);
        props.cancelEdit();
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    };
    

    return (
        <BossDialog
            title={lang.imdb_details_label}
            onClose={props.cancelEdit}
            width={"75%"}
        >
            <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={props.cancelEdit} />
            <div className="row">
                <div className="col-12">
                    <div className="row mt-2">
                        <div className="col-8">
                            <label>{lang.search_name_label} *</label>
                            <div className="d-flex">
                                <input
                                    type="text"
                                    className="form-control form-control-sm shadow-inset-2"
                                    id="searchInput"
                                    name="SearchName" value={dataItem.SearchName}
                                    onChange={(e) => setDataItem({ ...dataItem, SearchName: e.target.value })}
                                    onKeyDown={handleKeyDown} 
                                    placeholder={lang.search_name_label}
                                    onFocus={(e) => {
                                        if (props.setOutSideFocus) {
                                            props.setOutSideFocus(true);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (props.setOutSideFocus) {
                                            props.setOutSideFocus(false);
                                        }
                                    }}
                                />
                                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "30px" }} >
                                    <div className="input-group-append" style={{ height: '32px' }}>
                                        <span className="input-group-text">
                                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="col-2">
                            <label>{lang.type_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={Object.keys(IMDBTYPES).map(x => {
                                    return { Description: x, SID: IMDBTYPES[x] }
                                })}
                                name="Type"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.Type}
                                onChange={onChange}
                                disabled={props?.mediaCategory ? true : false}
                            />
                        </div>
                        <div className="col mt-3">
                            <ActionButton name={lang.search_button_text} title={lang.search_button_tooltip} onClick={onSearch} />
                        </div>
                    </div>
                    <div className="row mt-3 imdb">
                        <div className={(gridData?.filter((item) => selectedState[idGetter(item)]).length != 0) ? "col-5" : "col-12"}>
                            <Grid
                                data={gridData.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                pageable={true}
                                skip={page.skip}
                                take={page.take}
                                total={total}
                                onPageChange={pageChange}
                                onSelectionChange={onSelectionChange}
                                resizable={true}
                                style={{ height: "500px" }}
                                pager={(props) => <CustomPaginationDropDown {...props} />}

                            >
                                <GridColumn field="Poster" title={lang.poster_column} width={80} cell={ImageCell} />
                                <GridColumn field="Title" title={lang.title_column} />
                                <GridColumn field="Year" title={lang.year_column} />
                                <GridColumn field="imdbID" title={lang.imdb_id_column} />
                                <GridColumn field="Type" title={lang.type_column} />
                            </Grid>
                        </div>
                        {(gridData.filter((item) => selectedState[idGetter(item)]).length != 0) && <div className="col-7 border" style={{ height: "500px", overflowY: 'scroll', maxWidth:" 57.333333%" }}>
                            <i className="fa fa-times-circle float-right mt-1" style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => {setSelectedState({}); setSelectedItemDetails({})}} />   
                            <div className="row mt-2" >
                                <div className="col-6">  
                                    <div className='row'>
                                        <div className="col-12 mt-2">
                                            <label style={{ fontSize: '25px' }} htmlFor="Title">{utility.subString(selectedItemDetails?.Title, 21)}</label>
                                        </div>
                                        <div className="col-12 mt-3" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold" >{lang.imdb_label} : 
                                                <span className="ml-1 font-weight-normal">
                                                    <i className="fa fa-star" style={{ color: "yellow", backgroundColor: 'orange', borderRadius: '15px' }}/> {selectedItemDetails?.imdbRating} <i className="fa fa-clock-o" style={{ color: "skyblue" }}/> {selectedItemDetails?.Runtime} <i className="fa fa-calendar" style={{ color: "GrayText"}}/> {selectedItemDetails?.Year} <i className="fa fa-snowflake-o" style={{ color: "aqua" }}/> {selectedItemDetails?.Type}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold">{lang.genre_label} :</label>
                                            <label className="ml-1">{selectedItemDetails?.Genre}</label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold">{lang.language_label} : </label>
                                            <label className="ml-1">{selectedItemDetails?.Language}</label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }} >
                                            <label className="font-weight-bold">{lang.episodes_label} :</label>
                                            <label className="ml-1"> {selectedItemDetails?.Episodes ? selectedItemDetails?.Episodes : `N/A`}</label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold">{lang.actor_label} : <span className="ml-1 font-weight-normal">{selectedItemDetails?.Actors}</span></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mt-2' style={{ textAlign: "center" }}>
                                        <img style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} src={selectedItemDetails?.Poster ? selectedItemDetails?.Poster : "https://res.cloudinary.com/dl2n34gbw/image/upload/v1714465970/wymufa5fvzqe16x8st6l.png"} height={"230px"} alt='Dummy' />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <table className="table table-bordered table-sm" style={{fontSize: '12px'}}>
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">{lang.release_label}</td>
                                                <td>{selectedItemDetails?.Released}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.rated_label}</td>
                                                <td>{selectedItemDetails?.Rated}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.country_label}</td>
                                                <td>{selectedItemDetails?.Country}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.director_label}</td>
                                                <td>{selectedItemDetails?.Director}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.writer_label}</td>
                                                <td>{selectedItemDetails?.Writer}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.awards_label}</td>
                                                <td>{selectedItemDetails?.Awards}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.response_label}</td>
                                                <td>{selectedItemDetails?.Response}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-6">
                                    <table className="table table-bordered table-sm" style={{fontSize: '12px'}}>
                                        <tbody>
                                            <tr>
                                                <td className="font-weight-bold">{lang.dvd_label}</td>
                                                <td>{selectedItemDetails?.DVD}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.imdb_votes_label}</td>
                                                <td>{selectedItemDetails?.imdbVotes}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.imdb_id_label}</td>
                                                <td>{selectedItemDetails?.imdbID}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.production_label}</td>
                                                <td>{selectedItemDetails?.Production}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.website_label}</td>
                                                <td>{selectedItemDetails?.Website}</td>
                                            </tr>
                                            <tr style={{backgroundColor: "#f2f2f2"}}>
                                                <td className="font-weight-bold">{lang.box_office_label}</td>
                                                <td>{selectedItemDetails?.BoxOffice}</td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold">{lang.meta_score_label}</td>
                                                <td>{selectedItemDetails?.Metascore}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="font-weight-bold">{lang.synopsis_label} :</label>
                                    <p>{selectedItemDetails?.Plot}</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </BossDialog >
    )
}

export default IMDBEditForm;