import { useEffect } from "react";
import digiSocket from "./digiSocket";


const useDigiSocket = (eventName, eventHandler) => {

  //socket
  useEffect(() => {
    //on New connection
    digiSocket.on(eventName, eventHandler);

    return () => {
      digiSocket.off(eventName, eventHandler);
    }
  }, [eventHandler]);


  return digiSocket;

}

export default useDigiSocket;