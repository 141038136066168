/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import BossDialog from '../../../components/BossDialog';
import { useTranslation } from '../../../locale/useTranslation';
import { EditPageHeader } from '../../../components/EditPageHeader';
import * as API from '../../../framework/API/api';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import CollectionSelection from '../../../framework/forms/CollectionSelection';
import { ENTITYNAME, PRODUCTS } from '../../../framework/constant/constant';
import { utility } from '../../../framework/utility/utilityProvider';
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import { DigitalSign_ModuleColumns } from '../../../framework/utility/customColumns';

const DigitalSignUserForm = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        _id: null,
        SID: 0,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        index: null,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const [showUserModule, setShowUserModule] = useState(false)
    const [gridData, setGridData] = useState(props?.selectedItem?.Module ?? []);

    useEffect(() => {
        loadData();
    }, [props?.selectedItem]);

    const loadData = () => {
        let res = {
            _id: props?.selectedItem?._id ?? null,
            SID: props?.selectedItem?.SID ?? 0,
            name: props?.selectedItem?.name ?? '',
            email: props?.selectedItem?.email ?? '',
            password: props?.selectedItem?.password ?? '',
            confirmPassword: props?.selectedItem?.password ?? '',
            index: props?.selectedItem?.index ?? null,
        }
        setDataItem(res);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.name == undefined || dataItem.name == '' || !dataItem.name?.toString().trim()) {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.email == undefined || dataItem.email == "" || !emailRegex.test(dataItem.email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.SID == 0) {
            if (dataItem.password == undefined || dataItem.password == '' || !dataItem.password?.toString().trim()) {
                toast.error(lang.please_enter_password_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
            if (dataItem.confirmPassword == undefined || dataItem.confirmPassword == '' || !dataItem.confirmPassword?.toString().trim()) {
                toast.error(lang.please_enter_confirm_password_users_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
            if (dataItem.password !== dataItem.confirmPassword) {
                toast.error(lang.confirm_password_do_not_match_error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                return false;
            }
        }

        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        let finalData = {
            _id: dataItem?._id,
            SID: dataItem?.SID,
            name: dataItem.name,
            email: dataItem.email?.toLowerCase(),
            password: dataItem.password,
            index: dataItem.index ?? null,
            Module: gridData,

        }
        console.log(finalData);

        let checkUserExists = await API.checkUserExists(finalData?._id, finalData.email);
        if (!checkUserExists.success) {
            toast.error(checkUserExists.message);
            return;
        }
        props?.setUserData(finalData);
        setDataItem(blankDataItem);
        props?.closeForm();
    };


    const onModuleDelete = (_id) => {
        const updatedData = gridData.filter(item => item._id !== _id);
        setGridData(updatedData);
    };

    const MyCommandCell = (props) => {
        return (
            <td>
                <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onModuleDelete(props.dataItem._id), () => { })} />
            </td>
        );

    };

    const handleGridDataModule = (dataList) => {
        let newData = utility.checkDuplicateInData(dataList, gridData, "Name")
        setGridData([...gridData, ...newData]);
    }

    return (
        <BossDialog
            title={dataItem.name != "" ? dataItem.name : lang.add_user_dialog_header}
            width='700px'
            onClose={props.closeForm}
        >
            <div className="row">
                <div className='col-12'>
                    <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={props.closeForm} />
                    <div className='row'>
                        <div className='col-6'>
                            <label htmlFor="name">{lang.name_label} *</label>
                            <input
                                style={{ display: 'inline-block' }}
                                type="text"
                                className="form-control form-control-sm"
                                name="name"
                                value={dataItem.name}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="email">{lang.email_address_label} *</label>
                            <input
                                style={{ display: 'inline-block' }}
                                type="email"
                                className="form-control form-control-sm"
                                name="email"
                                value={dataItem.email}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    {dataItem.SID == 0 && <div className="mt-2 row">
                        <div className='col-6'>
                            <label htmlFor="password">{lang.password_label} *</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                style={{ display: "block", padding: "10px 20px" }}
                                className="form-control form-control-sm"
                                name="password"
                                value={dataItem.password}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "24px", cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)} />
                        </div>
                        <div className='col-6'>
                            <label htmlFor="confirm password">{lang.confirm_password_label} *</label>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                style={{ display: "block", padding: "10px 20px" }}
                                name="confirmPassword"
                                className="form-control form-control-sm"
                                value={dataItem.confirmPassword}
                                onChange={onChange}
                            />
                            <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "24px", cursor: "pointer" }} className={`icon-append fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                        </div>
                    </div>}
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <AddRoundButton style={{ marginBottom: "8px" }} title={lang.add_user_button_tooltip} onClick={() => setShowUserModule(true)} />
                    <div className="float-right mt-1">{lang.mudule_label}</div>
                    <Grid style={{ height: "40vh", margin: '0px 10px 40px 10px', overflow: "hidden" }} data={gridData} >
                        <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
                        <GridColumn field="Name" title={lang.module_column} editable={false} on />
                    </Grid>
                </div>
            </div>
            {showUserModule && <CollectionSelection customColumns={DigitalSign_ModuleColumns} wherestatement={["Products", "=", [PRODUCTS.DigitalSignage]]} closeForm={() => setShowUserModule(false)} width={"35vw"} addButtonTitle={lang.select_collection_button_text} title={lang.select_module_collection_header} entityname={ENTITYNAME.Module} setDataList={handleGridDataModule} />}
        </BossDialog>
    )
}

export default DigitalSignUserForm;