/* eslint-disable */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENTITYNAME, LOCALSTORAGE_KEY, SLOT_TIMES } from '../../../framework/constant/constant';
import { TimePickerWithFormat } from "../../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { utility } from '../../../framework/utility/utilityProvider';
import * as API from "../../../framework/API/api";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useTranslation } from '../../../locale/useTranslation';
import { Day } from '@progress/kendo-date-math';
import InfoRoundButton from '../../../components/InfoRoundButton';

export const General = (props) => {

    const startTypes = ["Nominal", "Actual"];

    const dayStartTimes = [
        { Description: "00", value: 0 },
        { Description: "01", value: 3600000 },
        { Description: "02", value: 7200000 },
        { Description: "03", value: 10800000 },
        { Description: "04", value: 14400000 },
        { Description: "05", value: 18000000 },
        { Description: "06", value: 21600000 },
        { Description: "07", value: 25200000 },
        { Description: "08", value: 28800000 },
        { Description: "09", value: 32400000 },
        { Description: "10", value: 36000000 },
        { Description: "11", value: 39600000 },
        { Description: "12", value: 43200000 },
        { Description: "13", value: 46800000 },
        { Description: "14", value: 50400000 },
        { Description: "15", value: 54000000 },
        { Description: "16", value: 57600000 },
        { Description: "17", value: 61200000 },
        { Description: "18", value: 64800000 },
        { Description: "19", value: 68400000 },
        { Description: "20", value: 72000000 },
        { Description: "21", value: 75600000 },
        { Description: "22", value: 79200000 },
        { Description: "23", value: 82800000 },
    ]

    useEffect(() => {
        loadcombo();
    }, []);

    const [dataItem, setDataItem] = useState({
            DayStartTime: props.timeZone.DayStartTime,
            StartType : props.startType,
            PrimeStartTime: utility.convertMilisecondsToStringWithFrames(props.timeZone.PrimeStartTime), // "06:00:00:00", //21600000
            PrimeEndTime: utility.convertMilisecondsToStringWithFrames(props.timeZone.PrimeEndTime), // "18:00:00:00" ,//64800000
            View: props.setShowViewType,
            ShowFormat: props.showFormatData,
            ShowNoSeg: props.showNoSegData,
            ShowContract: props.showContractData,
            weekStartDay: props.weekStartDay ?? Object.keys(Day).filter(key => key.length > 1).map(key => ({
                _id: Day[key],
                Description: key,
                bgColor: "white",
            }))[1],
        }
    );

    const [slotDuration, setSlotDuration] = useState(props.slotDurationData);
    const [selectedDayStartTime, setSelectedDayStartTime] = useState(dayStartTimes.find(x => x.value == props.timeZone.DayStartTime));
    const [view, setView] = useState([]);
    const lang = useTranslation();



    const loadcombo = async () => {
        var viewRes = await API.getDataLookup(ENTITYNAME.PlanningView, { sort: { Order: 1 } });
        setView(viewRes.data.filter(d => d.SID != 3));
    }

    const onChange = (e) => {

        if (e.target.name == "ShowFormat") {
            setDataItem({ ...dataItem, ShowFormat: e.target.value });
            props.setShowFormat(e.target.value)
        } else if (e.target.name == "ShowNoSeg") {
            setDataItem({ ...dataItem, ShowNoSeg: e.target.value });
            props.setShowNoSeg(e.target.value)
        } else if (e.target.name == "ShowContract") {
            setDataItem({ ...dataItem, ShowContract: e.target.value });
            props.setShowContract(e.target.value)
        } else if (e.target.name == "PrimeStartTime" || e.target.name == "PrimeEndTime") {
            props.setTimeZone({ ...dataItem, [e.target.name]: e.target.value });
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } else if (e.target.name == "DayStartTime") {
            props.setTimeZone({ ...dataItem, [e.target.name]: e.target.value.value });
            setSelectedDayStartTime(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value.value });
        } else if (e.target.name == "StartType") {
            props.getStartType({ ...dataItem, [e.target.name]: e.target.value });
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } else if (e.target.name == "SlotDuration") {
            setSlotDuration(e.target.value);
            utility.setSessionValue(LOCALSTORAGE_KEY.SELECTED_PLANNING_SLOT_DURATION,e.target.value)
            props.setSlotDurationValue(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } else if (e.target.name == "View") {
            props.setViewValue(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } else if (e.target.name == "weekStartDay") {
            props.setWeekViewStart(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } else if (e.target.name == "weekStartDayBackgroundColor") {
            props.setWeekViewStart({ ...dataItem.weekStartDay, bgColor: e.target.value, });
            setDataItem({ ...dataItem, weekStartDay: { ...dataItem.weekStartDay, bgColor: e.target.value, } });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }

    }

    return (
        <>
            <div className='row mb-1 ml-1'>
                <div className="col-4 form-group">
                        <label>{lang.day_Start_time_label} :</label>
                        <DropDownList
                            data={dayStartTimes}
                            value={selectedDayStartTime}
                            name={"DayStartTime"}
                            style={{
                                marginLeft: "15px",
                                width: "70px",
                                backgroundColor: "white",
                            }}
                            textField='Description'
                            dataItemKey="value"
                            onChange={onChange}
                        />
     

                </div>
                <div className="col-6 form-group">

                        <label>{lang.start_type_label} :</label>
                        <DropDownList
                            data={startTypes}
                            value={props.startType}
                            name={"StartType"}
                            style={{
                                marginLeft: "15px",
                                width: "150px",
                                backgroundColor: "white",
                            }}
                            onChange={onChange}
                        />
           
                </div>
            </div>
            <div className='row mb-4' style={{ margin: "0px 0px 0px 0px", height: "08vh", width: "735px", border: '0.05em solid #c4c2c2', borderRadius: '5px!important' }}>
                <div className='border-with-title'>&nbsp; {lang.view_label} &nbsp;
                </div>
                <div className='col-12'>
                    <div className='row'>
                        <div className="col-4">
                            <div className="form-group">
                                <label htmlFor="TabView">{lang.slot_duration_title_label} :</label>
                                <DropDownList
                                    value={props.slotDurationData + " min"}
                                    name={"SlotDuration"}
                                    style={{
                                        marginLeft: "5px",
                                        width: "100px",
                                        backgroundColor: "white",
                                    }}
                                    data={SLOT_TIMES}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="col-3 p-0 pl-1">
                            <div className="form-group">
                                <label htmlFor="TabView">{lang.view_type_label} : </label>
                                <DropDownList
                                    style={{
                                        marginLeft: "5px",
                                        width: "80px",
                                        backgroundColor: "white",
                                    }}
                                    data={view}
                                    name="View"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.View}
                                    onChange={onChange}
                                    validator={(value) => value ? "" : "Please select the value"}
                                />
                            </div>
                        </div>
                        <div className="col-5 p-0">
                            <div className="form-group">
                                <label htmlFor="TabView">Week Start Day : </label>
                                <DropDownList
                                    style={{
                                        marginLeft: "5px",
                                        width: "130px",
                                        backgroundColor: "white",
                                    }}
                                    data={Object.keys(Day).filter(key => key.length > 1).map(key => ({
                                        _id: Day[key],
                                        Description: key,
                                        bgColor: dataItem?.weekStartDay?.bgColor ?? "#FFFF00",
                                    }))}
                                    name="weekStartDay"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.weekStartDay}
                                    onChange={onChange}
                                    validator={(value) => value ? "" : "Please select the value"}
                                />
                                <input
                                    type='color'
                                    name="weekStartDayBackgroundColor"
                                    value={dataItem.weekStartDay.bgColor ?? "white"}
                                    onChange={onChange}
                                    style={{ position: "relative", top: "4px", }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row' style={{ margin: "0px 0px 0px 0px", height: "08vh", width: "735px", border: '0.05em solid #c4c2c2', borderRadius: '5px!important' }}>
                <div className='border-with-title'>&nbsp; {lang.prime_time_label} &nbsp;
                </div>
                <div className='col-12'>
                    <div className='row mb-4'>
                        <div className="col-6">
                            <div className="row form-group ml-1">
                                <label>{lang.prime_Start_time_label} :</label>
                                <TimePickerWithFormat
                                    style={{
                                        marginLeft: "8px",
                                        width: "160px",
                                        border: '1px solid grey',
                                        backgroundColor: "white",
                                    }}
                                    name="PrimeStartTime"
                                    value={dataItem.PrimeStartTime}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row form-group ml-1">
                                <label>{lang.prime_end_time_label} :</label>
                                <TimePickerWithFormat
                                    style={{
                                        marginLeft: "16px",
                                        width: "160px",
                                        border: '1px solid grey',
                                        backgroundColor: "white",
                                    }}
                                    name="PrimeEndTime"
                                    value={dataItem.PrimeEndTime}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-3' style={{ margin: "0px 0px 0px 0px", height: "08vh", width: "735px", border: '0.05em solid #c4c2c2', borderRadius: '5px!important' }}>
                <div className='border-with-title'>&nbsp; {lang.other_option_label} &nbsp;
                </div>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-4'>
                            <i className="fa fa-cube mr-2" ></i>
                            <Checkbox
                                name={"ShowFormat"}
                                label={lang.show_format_label}
                                value={dataItem.ShowFormat}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-4'>
                            <i className="fa fa-window-close mr-2" ></i>
                            <Checkbox
                                name={"ShowNoSeg"}
                                label={lang.show_no_seg_label}
                                value={dataItem.ShowNoSeg}
                                onChange={onChange}
                            />
                        </div>
                        <div className='col-4'>
                            <i className="fa fa-pencil-square-o mr-2" ></i>
                            <Checkbox
                                name={"ShowContract"}
                                label={lang.show_contract_label}
                                value={dataItem.ShowContract}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}