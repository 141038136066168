/* eslint-disable */
import { useEffect, useState } from "react";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import moment from "moment";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";

const ScheduleCreateFastPlaylist = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        FromDate: moment(props.selectedScheduleDate).format("YYYY-MM-DD"),
        ToDate: moment(props.selectedScheduleDate).format("YYYY-MM-DD"),
        Channel: props.selectedChannel
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channels, setChannels] = useState([]);
    const [statusMessage, setStatusMessage] = useState("");

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let channelRes = await API.getValidChannels(ENTITYNAME.Channel);
        setChannels(channelRes.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if(dataItem.FromDate == undefined || dataItem.FromDate == "") {
            toast.error(lang.please_select_from_date_error_message);
            return false;
        }
        if(dataItem.ToDate == undefined || dataItem.ToDate == "") {
            toast.error(lang.please_select_to_date_error_message);
            return false;
        }
        let currentDate  = utility.getCurrentDate();
        if (new Date(dataItem.FromDate).getTime() < currentDate) {
            toast.error(lang.from_date_should_be_current_date_error_message);
            return false;
        }
        if(new Date(dataItem.FromDate).getTime() > new Date(dataItem.ToDate).getTime()) {
            toast.error(lang.from_date_should_be_less_than_to_date_error_message);
            return false;
        }
        console.log(dataItem);
        if(dataItem.Channel == undefined || dataItem.Channel._id == undefined) {
            toast.error(lang.please_select_channel_error_message);
            return false;
        }
        return true;
    }

    const handleExecute = async () => {
        if(!isValid()) return;
        props.onExecute(dataItem);
    }

    const handleDownload = async () => {}

    return (
        <BossDialog title={lang.create_fast_playlist_dialog_header} onClose={props.closeForm} width="600px" height="260px">
            <div className="row mb-3">
                <div className="col-12">
                    <div className="d-flex" style={{ marginLeft: '-10px' }}>
                        <ActionButton name={lang.execute_button_text} title={lang.execute_button_tooltip} onClick={handleExecute} />
                        <CancelButton onClick={props.closeForm} />
                        <RoundButton style={{ marginLeft: "10px" }} icon="download" title={lang.download_summary_tooltip} onClick={props.onExportFastPlaylistReport} />
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.from_date_label}</label>
                            <input type="date" className="form-control form-control-sm" name="FromDate" value={dataItem.FromDate} onChange={onChange} />
                        </div>
                        <div className="col-6">
                            <label>{lang.to_date_label}</label>
                            <input type="date" className="form-control form-control-sm" name="ToDate" value={dataItem.ToDate} onChange={onChange} />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <label>{lang.channel_label}</label>
                            <DropDownList
                                data={channels}
                                textField="FullChannelName"
                                dataItemKey="_id"
                                name="Channel"
                                onChange={onChange}
                                value={dataItem.Channel}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-right mt-2' style={{ position: 'fixed', height: '30px', width: "601px", marginLeft: '-16px', background: "#3a3633" }}>
                <span className="mr-1" style={{ color: 'white' }}>{statusMessage}</span>
            </div>
        </BossDialog>
    );
}
export default ScheduleCreateFastPlaylist;