import React, { useEffect, useState } from 'react';
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { toast } from 'react-toastify';
import { ENTITYNAME, MEDIACATEGORIES, OPERATORS } from '../../framework/constant/constant';
import { utility } from '../../framework/utility/utilityProvider';
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ArrayCell } from '../../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import BossDialog from '../../components/BossDialog';

const MusicCategoryEditForm = (props) => {

    const blankDataItem = {
        ...props.item,
        SID: props.item.SID ?? 0,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Genres: props.item.Genres ?? [],
        PGRating: props.item.PGRating ?? [],
        MediaCategory: props.item.MediaCategory ?? {},
        Exhibitions: props.item.Exhibitions ?? {...OPERATORS[0], Condition:0},
        ReleaseYear: props.item.ReleaseYear ?? '',
        Language: props.item.Language ?? [],
        Channel: props.item.Channel ?? [],
        CastName : props.item.CastName ?? '',
        CastType : props.item.CastType ?? {},
        isLive: props.item.isLive ?? false,
        Archive: props.item.Archive ?? false
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const lang = useTranslation();
    const [genre, setGenre] = useState([]);
    const [mediaCategory, setMediaCategory] = useState([]);
    const [language, setLanguage] = useState([]);
    const [castType, setCastType] = useState([]);
    const [pGRating, setPGRating] = useState([]);
    const [channels, setChannels] = useState([]);
    const [showAvailableMusic, setShowAvailableMusic] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [musicData,setMusicData] = useState([]);
  

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        setGenre(genres.data);
        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
        setMediaCategory(mediaCategory.data);
        if (props.item.SID == 0) {
            setDataItem({ ...blankDataItem, MediaCategory: mediaCategory.data.find((x) => x.SID == MEDIACATEGORIES.Music) })
        }
        let language = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguage(language.data);
        let castType = await API.getDataLookup(ENTITYNAME.CastType, { sort: { Description: 1 } });
        setCastType(castType.data);
        let pGRating = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        setPGRating(pGRating.data);
        let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channelRes.data);
    }

    const onChange = (e) => {
        if (e.target.name == 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == 'isLive') {
            setDataItem({ ...dataItem, isLive: !dataItem.isLive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (isValid()) {
            let data = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Genres: dataItem.Genres,
                PGRating: dataItem.PGRating.length > 0 ? dataItem.PGRating.map(x=>{
                    return {
                        _id: x._id,
                        SID: x.SID,
                        Description: x.Description
                    }
                }) : [],
                MediaCategory: {
                    _id: dataItem.MediaCategory._id,
                    SID: dataItem.MediaCategory.SID,
                    Description: dataItem.MediaCategory.Description,
                    isLong: dataItem.MediaCategory.isLong
                },
                Exhibitions: dataItem.Exhibitions,
                ReleaseYear: dataItem.ReleaseYear,
                Language: dataItem.Language.length > 0 ? dataItem.Language.map(x=>{
                    return {
                        _id: x._id,
                        SID: x.SID,
                        Description: x.Description,
                        GoogleApiCode: x.GoogleApiCode,
                        ISOCode: x.ISOCode
                    }
                }) : [],
                Channel: dataItem.Channel.length > 0 ? dataItem.Channel.map(x=>{
                    return {
                        _id: x._id,
                        SID: x.SID,
                        FullChannelName: x.FullChannelName
                    }
                }) : [],
                CastName: dataItem.CastName,
                CastType:  Object.keys(dataItem.CastType).length > 0 ? {
                    _id: dataItem.CastType._id,
                    SID: dataItem.CastType.SID,
                    Description: dataItem.CastType.Description
                } : {},
                isLive: dataItem.isLive,
                Archive: dataItem.Archive ?? false
            }
            console.log(data)
            let res = await API.saveData(ENTITYNAME.MusicCategory, data);
            if (res.success) {
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.MusicCategory);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    const handleMusicPreview = async () => {

        setShowAvailableMusic(true);
        
        //API CALL
        const musicRes = await API.getMusicByMusicCategory(dataItem);
        console.log(musicRes);
        if(musicRes.success){
          setIsLoading(false)
          setMusicData(musicRes.data ?? []);
        } else { 
            console.log(musicRes)
        }
    
      }



    return (<>
        <BossDialog
            title={props.item.Name ?? lang.create_music_category_dialog_header}
            onClose={props.cancelEdit}
            width={"670px"}
        >
            <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} showPreview={true} onPreview = {handleMusicPreview}/>
            <div className='row mt-3'>
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.name_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Name"
                                    value={dataItem.Name}
                                    onChange={onChange}
                                />
                            </div></div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.media_category_label} : </label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <DropDownList
                                    data={mediaCategory}
                                    name={"MediaCategory"}
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.MediaCategory}
                                    onChange={onChange}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.channel_label} : </label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={channels}
                                    name={"Channel"}
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.genre_label} : </label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={genre}
                                    name={"Genres"}
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Genres}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.cast_and_crew_label} : </label>
                        </div>
                        <div className="col-8">
                            <div className="row">
                                <div className="col-6 form-group">
                                    <input type="sting" className="form-control form-control-sm" name="CastName" value={dataItem.CastName} onChange={onChange} />
                                </div>
                                <div className="col-6 form-group">
                                    <DropDownList
                                        data={castType}
                                        name={"CastType"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.CastType}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.release_year_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    type="number"
                                    className="form-control form-control-sm"
                                    name="ReleaseYear"
                                    value={dataItem.ReleaseYear}
                                    onChange={onChange}
                                />
                            </div></div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.language_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={language}
                                    name={"Language"}
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Language}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.pg_rating_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <MultiSelect
                                    data={pGRating}
                                    name={"PGRating"}
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.PGRating}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.exhibition_label} :</label>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <DropDownList
                                    data={OPERATORS}
                                    name={"Exhibitions"}
                                    textField="Description"
                                    dataItemKey="ID"
                                    value={dataItem.Exhibitions}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Condition"
                                    value={dataItem.Exhibitions?.Condition}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.is_live_label} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    name={"isLive"}
                                    type={"checkbox"}
                                    value={dataItem.isLive}
                                    checked={dataItem.isLive}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <label htmlFor="TabView">{lang.archive} :</label>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <input
                                    name={"Archive"}
                                    type={"checkbox"}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BossDialog>
        {showAvailableMusic && <BossDialog title={lang.available_music_dialog_header} onClose={()=> { setShowAvailableMusic(false); setIsLoading(true);}} width={"50vw"}>
        {isLoading && <Loader height="40vh" />}
        {!isLoading && <Grid data={musicData} style={{ height: "400px" }} rowHeight={50} resizable={true}>
          <GridColumn field={'Title'} title={'Title'} />
          <GridColumn field={'MediaCategory.Description'} title={'Media Category'} />
          <GridColumn field={'AssetId'} title={'Asset Id'} />
          <GridColumn field={'Genres'} title={'Genres'} cell={ArrayCell} format="Description" />
          <GridColumn field={'Duration'} title={'Duration'} cell={TimeCell} sortable={true} />
        </Grid>}
      </BossDialog>}

      </>)
}

export default MusicCategoryEditForm;
