/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ENTITYNAME } from "../../framework/constant/constant";
import { RadioButton } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { FILEURL } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from "../../framework/utility/utilityProvider";
import moment from 'moment';
import BossDialog from '../../components/BossDialog';

export const InvoiceDenomination = (props) => {

    const lang = useTranslation();

    const campaign = props.billingData;
    const isAgency = props.isAgency;
    const isTaxApplicable = props.billingData.IsTaxApplicable ?? false;

    const [dataItem, setDataItem] = useState({
        Tax: isTaxApplicable ? isAgency ? (campaign.Agency.DefaultTax == null ? {} : campaign.Agency.DefaultTax) : (campaign.Client.DefaultTax == null ? {} : campaign.Client.DefaultTax) : {},
        Brand: { SID: 0, Name: "ALL" },
        BankAccount: {},
        FromDate: moment(new Date(props.billingData.PeriodFrom)).format('YYYY-MM-DD'),
        ToDate: moment(new Date(props.billingData.PeriodTo)).format('YYYY-MM-DD'),
        InvoiceDate: moment(new Date()).format('YYYY-MM-DD'),
        Channel: (props.billingData.Channels != undefined ) && props.billingData.Channels.length == 1 ?  props.billingData.Channels[0] : { SID: 0, FullChannelName: "ALL" }, 
        Commercial: { SID: 0, Title: "ALL" },
    });
    const [tax, setTax] = useState([]);
    const [companyMaster, setCompanyMaster] = useState([]);
    const [bankDetail, setBankDetail] = useState([]);
    const [brands, setBrands] = useState([]);
    const [invoiceAll, setInvoiceAll] = useState(true);
    const [allBrands, setAllBrands] = useState(true);
    const [invoiceSelectedPeriod, setInvoiceSelectedPeriod] = useState(false);
    const [campaignSelectedBrand, setCampaignSelectedBrand] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState([]);
    const [invoiceTelecastDetails, setInvoiceTelecastDetails] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [validatedAmount, setValidatedAmount] = useState(0);
    const [clientInfo, setClientInfo] = useState({});
    const [gridMessage, setGridMessage] = useState(lang.please_wait_loading_data_billing_error_message);
    const [footerMessage, setFooterMessage] = useState('');
    const [disable, setDisable] = useState(false);
    let tax1 = 0;
    let tax2 = 0;

    const allBrandsRef = React.useRef(true);
    const campaignSelectedBrandRef = React.useRef(false);

    const [channels, setChannels] = useState([...props.billingData.Channels]);
    const [allChannels, setAllChannels] = useState(true);
    const allChannelsRef = React.useRef(true);
    const [campaignSelectedChannel, setCampaignSelectedChannel] = useState(false);
    const campaignSelectedChannelRef = React.useRef((props.billingData.Channels != undefined ) && props.billingData.Channels.length == 1 ?  true : false);
    const selectedChannelRef = React.useRef(channels[0]);

    const [commercials, setCommercials] = useState([...props.billingData.Commercials]);
    const [allCommercials, setAllCommercials] = useState(true);
    const allCommercialsRef = React.useRef(true);
    const [campaignSelectedCommercial, setCampaignSelectedCommercial] = useState(false);
    const campaignSelectedCommercialRef = React.useRef(false);
    const selectedCommercialRef = React.useRef({});

    const invoiceSelectedPeriodRef = React.useRef(false);
    const dataItemRef = React.useRef({});
    const selectedBrandRef = React.useRef({});

    const invoiceDetailsRef = React.useRef([]);
    const invoiceTelecastDetailsRef = React.useRef([]);

    useEffect(() => {
        loadInvoiceDetails();
        loadcombo();
    }, []);

    const borderWithTitle = {
        position: "relative", fontSize: "12px",
        top: "-0.9em", marginLeft: "0.8em", display: "inline", backgroundColor: "white"
    };

    const loadcombo = async () => {
        let taxRes = await API.getDataLookup(ENTITYNAME.Tax, { sort: { Description: 1 } });
        setTax(taxRes.data);

        let bankDetailRes = await API.getDataLookup(ENTITYNAME.BankAccount, { sort: { Accountname: 1 } });
        setBankDetail(bankDetailRes.data);

        //FOR DEFAULT TAX BASED ON CAMPAIGN'S AGENCY/CLIENT
        //  if(isTaxApplicable){
        //     let tax = isAgency ? campaign.Agency.DefaultTax : campaign.Client.DefaultTax;
        //     setDataItem({ ...dataItem, Tax: tax});
        //  }

        // COMPANY MASTER
        let companyMasters = await API.getDataLookup(ENTITYNAME.Company, { sort: { Name: 1 } });
        setCompanyMaster(companyMasters.data);

        // CLIENT INFO
        let clientInfo = await API.getDataLookup(ENTITYNAME.ClientInfo, { sort: { ClientName: 1 } });
        setClientInfo(clientInfo.data[0]);

        // CAMPAIGN'S CHANNELS
        //setChannels( [...channels, ...props.billingData.campaign.Channels]);

        let brands = [];
        props.billingData.Commercials.forEach(item => {
            if (item.Brand != undefined && item.Brand.Name != undefined) {
                brands.push(item.Brand);
            }

        });

        // FILTERING UNIQ BRANDS ONLY BASED ON NAME PROPERTY
        //https://www.tutorialspoint.com/extract-unique-objects-by-attribute-from-an-array-of-objects-in-javascript
        let map = new Map();
        for (let brand of brands) {
            map.set(brand["Name"], brand);
        }
        let iteratorValues = map.values();
        let uniqueBrands = [...iteratorValues];
        setBrands(uniqueBrands);

        setDataItem({
            Tax: isTaxApplicable ? isAgency ? (campaign.Agency.DefaultTax == null ? {} : campaign.Agency.DefaultTax) : (campaign.Client.DefaultTax == null ? {} : campaign.Client.DefaultTax) : {},
            Brand: { SID: 0, Name: "ALL" },
            BankAccount: bankDetailRes.data.find((x) => x.IsDefault) ?? bankDetailRes.data[0],
            FromDate: moment(new Date(props.billingData.PeriodFrom)).format('YYYY-MM-DD'),
            ToDate: moment(new Date(props.billingData.PeriodTo)).format('YYYY-MM-DD'),
            InvoiceDate: moment(new Date()).format('YYYY-MM-DD'),
            Channel: (props.billingData.Channels != undefined ) && props.billingData.Channels.length == 1 ?  props.billingData.Channels[0] : { SID: 0, FullChannelName: "ALL" }, 
            Commercial: { SID: 0, Title: "ALL" },
        })


    }

    const loadInvoiceDetails = async (fromDate = dataItem.FromDate, toDate = dataItem.ToDate) => {

        setInvoiceDetails([]);
        setInvoiceTelecastDetails([]);

        invoiceDetailsRef.current = [];
        invoiceTelecastDetailsRef.current = [];

        setGridMessage(lang.please_wait_loading_data_billing_error_message);

        let billingFilters = { ...dataItem };

        billingFilters.FromDate = new Date(fromDate).getTime(), //utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.FromDate)), 
            billingFilters.ToDate = new Date(toDate).getTime(),//utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.ToDate)), 
            // billingFilters.Campaign = props.billingData.campaign;
            billingFilters.CampaignId = props.billingData._id;

        billingFilters.Channel = selectedChannelRef.current;

        // FOR BRAND FILTER
        billingFilters.Brand = selectedBrandRef.current;//dataItem.Brand;
        billingFilters.IsBrandSelected = campaignSelectedBrandRef.current;//campaignSelectedBrand;



        // FOR CHANNEL FILTER
        billingFilters.IsChannelSelected = campaignSelectedChannelRef.current ;
        billingFilters.CampaignChannels = channels;

        // FOR COMMERCIAL FILTER
        billingFilters.IsCommercialSelected = campaignSelectedCommercialRef.current;
        billingFilters.SelectedCommercial = selectedCommercialRef.current;
        billingFilters.Commercials = commercials;
        billingFilters.BillingLine = props.billingData.BillingLine;


        let invoiceDetails = await API.getInvoiceDetails(billingFilters);
        let invoiceTelecastDetails = await API.getInvoiceTelecastDetails(billingFilters);

        if (invoiceDetails.data.length == 0) setGridMessage(lang.no_data_found_error_message);


        console.log(invoiceDetails.data);

        setInvoiceDetails(invoiceDetails.data);
        setInvoiceTelecastDetails(invoiceTelecastDetails.data);

        invoiceDetailsRef.current = invoiceDetails.data;
        invoiceTelecastDetailsRef.current = invoiceTelecastDetails.data;

        updateInvoicePaymentDetails(invoiceDetails.data);



    }

    const showInvoiceDetails = () => {
        setGridMessage(lang.please_wait_loading_data_billing_error_message);
        loadInvoiceDetails();

        setInvoiceDetails([]);

    }

    const updateInvoicePaymentDetails = (invoiceDetails) => {

        // TOTAL AMOUNT
        let _totalAmount = invoiceDetails.reduce(function (prev, current) {
            return prev + +current.Amount
        }, 0);

        setTotalAmount(_totalAmount);

        //COMMISSION AMOUNT
        let commissionPercentage = campaign.Commission != undefined ? parseFloat(campaign.Commission) : 0;
        let _commissionAmount = (_totalAmount / 100) * commissionPercentage;

        setCommissionAmount(_commissionAmount)

        let discountPercentage = campaign.Discount != undefined ? parseFloat(campaign.Discount) : 0;
        let _discountAmount = (_totalAmount / 100) * discountPercentage;
        setDiscountAmount(_discountAmount);

        let _taxAmount = 0;
        if (isTaxApplicable) {
            if (isAgency) {
                tax1 = campaign.Agency.DefaultTax?.SID != undefined ? parseInt(campaign.Agency.DefaultTax.Tax1) : tax1;
                tax2 = campaign.Agency.DefaultTax?.SID != undefined ? parseInt(campaign.Agency.DefaultTax.Tax2) : tax2;
            } else {

                tax1 = campaign.Client.DefaultTax?.SID != undefined ? parseInt(campaign.Client.DefaultTax.Tax1) : tax1;
                tax2 = campaign.Client.DefaultTax?.SID != undefined ? parseInt(campaign.Client.DefaultTax.Tax2) : tax1;

            }
            _taxAmount = (_totalAmount * (parseFloat(tax1 ?? 0) + parseFloat(tax2 ?? 0))) / 100;
        }

        setTaxAmount(_taxAmount);


        let _validatedAmount = ((_totalAmount - _commissionAmount) + _taxAmount) - _discountAmount;
        setValidatedAmount(_validatedAmount.toFixed(2));

        setDataItem(old => { return { ...old, TotalAmount: _totalAmount.toFixed(2), CommissionAmount: _commissionAmount.toFixed(2), TaxAmount: _taxAmount.toFixed(2), DiscountAmount: _discountAmount.toFixed(2), ValidatedAmount: _validatedAmount.toFixed(2), Commission: campaign.Commission, Discount: campaign.Discount } })

    }

    const calculateTaxAmount = () => {

        if (isTaxApplicable) {
            let _taxAmount = (dataItem.TotalAmount * (parseFloat(tax1 ?? 0) + parseFloat(tax2 ?? 0))) / 100;
            setTaxAmount(_taxAmount);
            setDataItem(old => { return { ...old, TaxAmount: _taxAmount.toFixed(2) } });
            calculateValidatedAmount();
        }

    }

    const calculateValidatedAmount = () => {

        let _validatedAmount = (parseFloat(dataItem.TotalAmount) - parseFloat(dataItem.CommissionAmount)) + (parseFloat((dataItem.TotalAmount * (parseFloat(tax1 ?? 0) + parseFloat(tax2 ?? 0))) / 100)) - parseFloat(dataItem.DiscountAmount);
        setValidatedAmount(_validatedAmount.toFixed(2));
        setDataItem(old => { return { ...old, ValidatedAmount: _validatedAmount.toFixed(2) } });

    }

    const onChange = (e) => {

        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        dataItemRef.current = { ...dataItem, [e.target.name]: e.target.value };

        if (e.target.name == "Tax") {
            tax1 = e.target.value.Tax1;
            tax2 = e.target.value.Tax2;
            calculateTaxAmount();
        }

        if (e.target.name == "Brand") {

            selectedBrandRef.current = e.target.value;
            if (e.target.value.Name == "ALL") {
                campaignSelectedBrandRef.current = false;

            } else {
                campaignSelectedBrandRef.current = true;

            }
            loadInvoiceDetails();
        }

        if (e.target.name == "Channel") {
            selectedChannelRef.current = e.target.value;

            if (e.target.value.FullChannelName == "ALL") {
                campaignSelectedChannelRef.current = false;

            } else {
                campaignSelectedChannelRef.current = true;

            }

            loadInvoiceDetails();
        }


        if (e.target.name == "Commercial") {

            selectedCommercialRef.current = e.target.value;
            if (e.target.value.Title == "ALL") {
                campaignSelectedCommercialRef.current = false;

            } else {
                campaignSelectedCommercialRef.current = true;

            }

            loadInvoiceDetails();

        }



    }

    const previewBill = async (saveNextHouseNo) => {

        if (invoiceDetails.length == 0) {
            toast.error(`${lang.no_invoice_details_are_available_to_preview_bill_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (!dataItem.BankAccount || Object.keys(dataItem.BankAccount).length == 0) {
            toast.error(`${lang.please_select_bank_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (isTaxApplicable && (!dataItem.Tax || Object.keys(dataItem.Tax).length == 0)) {
            toast.error(`${lang.please_select_tax_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (campaignSelectedBrandRef.current && selectedBrandRef.current.Name == undefined) {
            toast.error(`${lang.please_select_brand_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        printBill(saveNextHouseNo);

    }

    const printBill = async (saveNextHouseNo, invoiceNumber, generatedBill_Id = undefined) => {

        setFooterMessage(lang.generate_pdf_message);
        setDisable(true);

        let payload = {
            InvoiceDetails: invoiceDetailsRef.current ? invoiceDetailsRef.current : [],
            CampaignId: campaign ? campaign?._id : '',
            TotalAmount: totalAmount,
            CommissionAmount: commissionAmount,
            DiscountAmount: discountAmount,
            TaxAmount: taxAmount,
            ValidatedAmount: validatedAmount,
            Tax1: tax1,
            Tax2: tax2,
            Tax: dataItem.Tax,
            IsTaxApplicable: isTaxApplicable,
            BankDetailsId: dataItem.BankAccount ? dataItem.BankAccount?._id : '',
            InvoiceDate: dataItem.InvoiceDate ? dataItem.InvoiceDate : '',
            PeriodFrom: dataItem.FromDate ? dataItem.FromDate : '',
            PeriodTo: dataItem.ToDate ? dataItem.ToDate : '',
            AmountInWords: utility.numbersToWords(parseInt(validatedAmount)),
            IsBrandSelected: campaignSelectedBrandRef.current ? campaignSelectedBrandRef.current : false,
            BrandSID: selectedBrandRef.current ? selectedBrandRef.current?.SID : 0,
            CompanyId: companyMaster ? companyMaster[0]._id : '',
            IsChannelSelected: campaignSelectedChannelRef.current ? campaignSelectedChannelRef.current : false,
            ChannelSID: campaignSelectedChannelRef.current ? selectedChannelRef.current.SID : 0,
            IsCommercialSelected: campaignSelectedCommercialRef.current ? campaignSelectedCommercialRef.current : false,
            Commercial: campaignSelectedCommercialRef.current ? selectedCommercialRef.current : { SID: 0, Title: "ALL" },
            IsSaveNextHouseNo: saveNextHouseNo,
            ClientInfoId: clientInfo ? clientInfo?._id : '',
            IsAgency: isAgency ? isAgency : false,
            GeneratedBill_Id : generatedBill_Id
        };

        if(!saveNextHouseNo && invoiceNumber){
            payload.InvoiceNo = invoiceNumber;
        }

        console.log(payload);

        let res = await API.printBill(payload);
        if (res.success) {
            let url = FILEURL.BASEURL + 'downloadReport/' + res.data;
            setTimeout(() => {
                window.open(url, "_blank");
                setFooterMessage(lang.generate_pdf_successfully_message);
                setDisable(false);
            }, 6000);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setFooterMessage(res.message);
            setDisable(false);
        }
    }
    const generateExcelBill = async (saveNextHouseNo) => {

        if (invoiceDetails.length == 0) {
            toast.error(`${lang.no_invoice_details_are_available_to_preview_bill_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!dataItem.BankAccount || Object.keys(dataItem.BankAccount).length == 0) {
            toast.error(`${lang.please_select_bank_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (isTaxApplicable && (!dataItem.BankAccount || Object.keys(dataItem.Tax).length == 0)) {
            toast.error(`${lang.please_select_tax_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (campaignSelectedBrandRef.current && selectedBrandRef.current.Name == undefined) {
            toast.error(`${lang.please_select_brand_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        setFooterMessage(lang.generate_xml_message);
        setDisable(true);
        console.log(clientInfo);

        let payload = {
            InvoiceDetails: invoiceDetailsRef.current ? invoiceDetailsRef.current : [],
            CampaignId: campaign ? campaign?._id : '',
            TotalAmount: totalAmount,
            CommissionAmount: commissionAmount,
            DiscountAmount: discountAmount,
            TaxAmount: taxAmount,
            ValidatedAmount: validatedAmount,
            Tax1: tax1,
            Tax2: tax2,
            Tax: dataItem.Tax,
            IsTaxApplicable: isTaxApplicable,
            BankDetailsId: dataItem.BankAccount ? dataItem.BankAccount?._id : '',
            InvoiceDate: dataItem.InvoiceDate ? dataItem.InvoiceDate : '',
            PeriodFrom: dataItem.FromDate ? dataItem.FromDate : '',
            PeriodTo: dataItem.ToDate ? dataItem.ToDate : '',
            AmountInWords: utility.numbersToWords(parseInt(validatedAmount)),
            IsBrandSelected: campaignSelectedBrandRef.current ? campaignSelectedBrandRef.current : false,
            BrandSID: selectedBrandRef.current ? selectedBrandRef.current?.SID : 0,
            CompanyId: companyMaster ? companyMaster[0]._id : '',
            IsChannelSelected: campaignSelectedChannelRef.current ? campaignSelectedChannelRef.current : false,
            ChannelSID: campaignSelectedChannelRef.current ? selectedChannelRef.current.SID : 0,
            IsCommercialSelected: campaignSelectedCommercialRef.current ? campaignSelectedCommercialRef.current : false,
            Commercial: campaignSelectedCommercialRef.current ? selectedCommercialRef.current : { SID: 0, Title: "ALL" },
            IsSaveNextHouseNo: saveNextHouseNo,
            ClientInfoId: clientInfo ? clientInfo?._id : '',
            IsAgency: isAgency ? isAgency : false,
        };

        console.log(payload);

        let res = await API.printBillInExcel(payload);
        if (res.success) {
            if(res.dataLength == 0){
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                }); 
                setFooterMessage(lang.no_data_found_for_selected_filter_error_message);            
                return;
            }
            else
            {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName);
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setFooterMessage(lang.successfully_exported_success_message);  
            }
            
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            setFooterMessage(res.message);
        }
        setDisable(false);

    }

    const saveBills = async (saveNextHouseNo) => {

        if (invoiceDetails.length == 0) {
            toast.error(`${lang.no_invoice_details_are_available_to_bill_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!dataItem.BankAccount || Object.keys(dataItem.BankAccount).length == 0){
            toast.error(`${lang.please_select_bank_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (isTaxApplicable && (!dataItem.Tax || Object.keys(dataItem.Tax).length == 0)) {
            toast.error(`${lang.please_select_tax_billing_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        setDisable(true);

        let billingData = {};
        billingData.InvoiceDetails = invoiceDetails;
        billingData.BillingDetails = { ...dataItem };
        billingData.BillingDetails.InvoiceDate = new Date(dataItem.InvoiceDate).getTime();
        billingData.Campaign = campaign;
        billingData.IsAgency = isAgency;
        billingData.IsBrandSelected = campaignSelectedBrandRef.current;
        billingData.Brand = selectedBrandRef.current;

        billingData.IsChannelSelected = campaignSelectedChannelRef.current;
        billingData.Channel = campaignSelectedChannelRef.current ? selectedChannelRef.current : { SID: 0, FullChannelName: "ALL" };

        // FOR COMMERCIAL FILTER
        billingData.IsCommercialSelected = campaignSelectedCommercialRef.current;
        billingData.Commercial = campaignSelectedCommercialRef.current ? selectedCommercialRef.current : { SID: 0, Title: "ALL" };

        //boolean
        billingData.IsSaveNextHouseNo = saveNextHouseNo;

        console.log(billingData);

        let res = await API.saveBills(billingData);
        if (res.success) {
            printBill(false, res.invoiceNumber,res.data._id.toString());
            props.closeForm(true);
            setDisable(false);
        } else {
            console.log(res.message);
            setDisable(false);
        }



    }

    const onClickRadioButton = (e) => {

        if (e.target.name == "InvoicePeriodAll") {
            setInvoiceAll(!invoiceAll);
            setInvoiceSelectedPeriod(false);
            invoiceSelectedPeriodRef.current = false;
            loadInvoiceDetails(campaign.PeriodFrom, campaign.PeriodTo);

        }

        if (e.target.name == "InvoiceSelectedPeriod") {
            setInvoiceAll(false);
            setInvoiceSelectedPeriod(true);
            invoiceSelectedPeriodRef.current = true;

        }

        if (e.target.name == "CampaignAll") {
            setAllBrands(!allBrands);
            setCampaignSelectedBrand(false);
            allBrandsRef.current = true;
            campaignSelectedBrandRef.current = false;
            loadInvoiceDetails();
        }

        if (e.target.name == "CampaignSelectedBrand") {
            setAllBrands(false);
            setCampaignSelectedBrand(!campaignSelectedBrand);
            campaignSelectedBrandRef.current = true;
        }

        if (e.target.name == "AllChannels") {
            setAllChannels(!allChannels);
            setCampaignSelectedChannel(false);
            allChannelsRef.current = true;
            campaignSelectedChannelRef.current = false;
            loadInvoiceDetails();
        }

        if (e.target.name == "CampaignSelectedChannel") {
            setAllChannels(false);
            setCampaignSelectedChannel(!campaignSelectedChannel);
            campaignSelectedChannelRef.current = true;
        }

        if (e.target.name == "AllCommercials") {
            setAllCommercials(!allCommercials);
            setCampaignSelectedCommercial(false);
            allCommercialsRef.current = true;
            campaignSelectedCommercialRef.current = false;
            loadInvoiceDetails();
        }

        if (e.target.name == "CampaignSelectedCommercial") {
            setAllCommercials(false);
            setCampaignSelectedCommercial(!campaignSelectedCommercial);
            campaignSelectedCommercialRef.current = true;
        }


    }

    return (
        <BossDialog
            title={isAgency ? campaign.Agency?.Name : campaign.Client?.Name}
            onClose={props.closeForm}
            width={"60vw"}
        >
            <div>
                <ActionButton title={lang.preview_button_tooltip} name={lang.preview_button_text} onClick={(e) => { e.preventDefault(); previewBill(false); }} disabled={disable} />

                <ActionButton btnColor={'success'} title={lang.save_bill_button_tooltip} name={lang.save_bill_button_text} onClick={(e) => { e.preventDefault(); saveBills(true); }} disabled={disable} />

                <ActionButton btnColor={'info'} title={lang.generate_button_tooltip} name={lang.generate_button_text} onClick={(e) => { e.preventDefault(); generateExcelBill(false); }} disabled={disable} />


            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <div style={{ margin: "0px 0px 0px 0px", height: "07.5vh", width: "55vw", border: '0.05em solid #c4c2c2', borderRadius: '5px!important' }}>
                        <div style={borderWithTitle}>&nbsp; {lang.invoice_period_label} &nbsp;
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <div className="row">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        name="InvoicePeriodAll"
                                        checked={invoiceAll}
                                        onClick={onClickRadioButton}
                                    />&nbsp;&nbsp;
                                    <label htmlFor="TabView">{lang.all_label}</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <RadioButton
                                        style={{ marginLeft: "5px" }}
                                        name="InvoiceSelectedPeriod"
                                        checked={invoiceSelectedPeriod}
                                        onClick={onClickRadioButton}
                                    />&nbsp;&nbsp;
                                    <label htmlFor="TabView">{lang.select_period_label}</label>
                                    &nbsp;&nbsp;
                                    {invoiceSelectedPeriod && <>
                                        <div className="col-3" style={{ marginTop: "-16px" }}>
                                            <div className="form-group">
                                                <label className='float-left' htmlFor="TabView">{lang.from_label}</label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    name="FromDate"
                                                    type={'date'}
                                                    value={dataItem.FromDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ marginTop: "-16px" }}>
                                            <div className="form-group">
                                                <label htmlFor="TabView">{lang.to_date_label}</label>
                                                <input
                                                    className="form-control form-control-sm"
                                                    name="ToDate"
                                                    type={'date'}
                                                    value={dataItem.ToDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1" style={{ textAlign: "right" }}>
                                            <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={showInvoiceDetails} style={{ marginTop: "-07px" }} />
                                        </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* CHANNEL/BRAND/COMMERCIAL SECTION */}
                    <div className="mt-2" style={{ margin: "0px 0px 0px 0px", height: "10vh", width: "55vw", border: '0.05em solid #c4c2c2', borderRadius: '5px!important' }}>
                        <div style={borderWithTitle}>&nbsp; {lang.channel_label} / {lang.campaign_brands_label} / {lang.commercial_label} &nbsp;
                        </div>
                        <div className='row' style={{ marginLeft: "4px" }}>
                            <div className="col-3">
                                <label htmlFor="TabView">{lang.channel_label}</label>
                                <DropDownList
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                    data={channels}
                                    name="Channel"
                                    textField="FullChannelName"
                                    dataItemKey="SID"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                    defaultItem={{ SID: 0, FullChannelName: "ALL" }}
                                />
                            </div>
                            <div className="col-3">
                                <label htmlFor="TabView">{lang.campaign_brands_label}</label>
                                <DropDownList
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                    data={brands}
                                    name="Brand"
                                    textField="Name"
                                    dataItemKey="SID"
                                    value={dataItem.Brand}
                                    onChange={onChange}
                                    defaultItem={{ SID: 0, Name: "ALL" }}
                                />
                            </div>
                            <div className="col-3">
                                <label htmlFor="TabView">{lang.commercial_label}</label>
                                <DropDownList
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                    data={commercials}
                                    name="Commercial"
                                    textField="Title"
                                    dataItemKey="SID"
                                    value={dataItem.Commercial}
                                    onChange={onChange}
                                    defaultItem={{ SID: 0, Title: "ALL" }}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mt-2" style={{ margin: "0px 0px 0px 0px", height: "21.0vh", width: "55vw", border: '1px solid #c4c2c2', borderRadius: '5px!important' }}>
                        <div style={borderWithTitle}>&nbsp; {lang.details_label} &nbsp;
                        </div>
                        <div className="col-12">
                            <Grid data={invoiceDetails} style={{
                                margin: "-08px 0px 0px -05px",
                                height: "19.0vh", width: "54vw"
                            }}>
                                <GridNoRecords>
                                    {gridMessage}
                                </GridNoRecords>
                                <Column field="Caption" title={lang.caption_column} />
                                <Column field="NoofSpots" title={lang.spots_column} />
                                <Column field="SpotRate" title={lang.spot_rate_column} />
                                <Column field="Amount" title={lang.amount_column} />
                                {/* <Column field="Brand" title="Brand" /> */}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row ml-1 mt-2'>
                <div className="col-md-4">
                    <div className='row mb-1'>
                        <label htmlFor="">{lang.invoice_date_label}</label>
                        <input
                            className="form-control form-control-sm"
                            name="InvoiceDate"
                            type={'date'}
                            value={dataItem.InvoiceDate}
                            onChange={onChange}
                        />
                    </div>
                    <div className="row mb-1">
                        <label htmlFor="TabView">{lang.tax_column}</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={tax}
                            name="Tax"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Tax}
                            onChange={onChange}
                            disabled={!campaign.IsTaxApplicable}
                        />
                    </div>
                    <div className='row'>
                        <label htmlFor="TabView">{lang.bank_account_label} *</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={bankDetail}
                            name="BankAccount"
                            textField="Accountname"
                            dataItemKey="_id"
                            value={dataItem.BankAccount}
                            onChange={onChange}
                        />

                    </div>
                </div>
                <div className="col-md-2 mr-4">&nbsp;</div>
                <div className='col-md-1'>&nbsp;</div>
                <div className="col-md-4 mt-3 ml-0" style={{ textAlign: "right" }}>
                    <div className="row mb-2">
                        <div className='col-8'>
                            {lang.gross_total_label} :
                        </div>
                        <div className='col-4'>
                            {/* {dataItem.TotalAmount} */}
                            {parseFloat(totalAmount).toFixed(2)}
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className='col-8'>
                            {lang.tax_amount_label + ` (${dataItem.Tax.Tax1 ? dataItem.Tax.Tax1 : 0} + ${dataItem.Tax.Tax2 ? dataItem.Tax.Tax2 : 0})`} %:

                        </div>
                        <div className='col-4'>
                            {/* {dataItem.TaxAmount} */}
                            {parseFloat(taxAmount).toFixed(2)}
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className='col-8'>
                            {lang.discount_label} {dataItem.Discount} %:
                        </div>
                        <div className='col-4'>
                            {/* {dataItem.DiscountAmount} */}
                            {parseFloat(discountAmount).toFixed(2)}
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className='col-8'>
                            {lang.commission_label} {dataItem.Commission} %:
                        </div>
                        <div className='col-4'>
                            {/* {dataItem.CommissionAmount} */}
                            {parseFloat(commissionAmount).toFixed(2)}
                        </div>
                    </div>
                    <div className="row" style={{ fontSize: "17px", fontWeight: "700" }}>
                        <div className='col-8'>
                            {lang.total_amount_label} :
                        </div>
                        <div className='col-4'>
                            {/* {dataItem.ValidatedAmount} */}
                            {parseFloat(validatedAmount).toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-right mt-2' style={{ position: 'fixed', height: '30px', width: '60.1%', marginLeft: '-17px', padding: '5px', background: "#3a3633" }}>
                <span style={{ color: 'white' }}>{footerMessage} </span>
            </div>
        </BossDialog>
    )
}
