import moment from "moment";
import { utility } from "../../../utility/utilityProvider";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const DateTimeCell = (props) => {

  const field = props.field || "";
  let value = '';

  if (field.includes('.')) {
    let keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? moment(props.dataItem[keys[0]][keys[1]]).utc().format(props.format ?? 'll, HH:mm:ss') : ''
      : '';
    if (props.dataItem[keys[0]][keys[1]] == 0) {
      value = '';
    }
  } else {
    value = moment(props.dataItem[field]).utc().format(props.format ?? 'll, HH:mm:ss');
    if (props.dataItem[field] == 0) {
      value = '';
    }
  }

  return <td
    className={props?.className ?? ''}
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={props?.style ?? {}}
  >
    {value}
  </td>;
};

export const DateCell = (props) => {

  const field = props.field || "";

  // const value = moment(props.dataItem[field]).utc().format('DD-MMM-YYYY');
  let value = props.dataItem[field];
  if (value) {
    value = moment(props.dataItem[field]).utc().format(props.format ?? 'YYYY-MM-DD HH:mm:ss');
  }

  return <td
    className={props?.className ?? ''}
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={props?.style ?? {}}
  >
    {value}
  </td>;
};

export const DateOnlyCell = (props) => {

  const field = props.field || "";

  // console.log(field);
  // console.log(props.dataItem);
  var value = utility.getDataInsideObject(props.dataItem, field);
  if (value) {
    value = moment(utility.getDataInsideObject(props.dataItem, field)).utc().format(props.format ?? 'DD-MM-YYYY');
  }
  // const value = utility.datetimeFormat(props.dataItem[field]);

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={props?.style ?? {}}
  >
    {value}
  </td>;
};

export const LocalDateTimeColumn = (props) => {

  const field = props.field || "";
  const format = (props.format == null || props.format == "" || props.format == undefined) ? 'YYYY-MM-DD HH:mm:ss' : props.format;

  // const value = moment(props.dataItem[field]).utc().format('DD-MMM-YYYY');
  let value = props.dataItem[field];
  if (value) {
    value = moment(props.dataItem[field]).format(format);
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={props?.style ?? {}}
  >
    {value}
  </td>;
};

export const LocalDateOnlyCell = (props) => {

  const field = props.field || "";

  let value = props.dataItem[field];
  if (value) {
    value = moment(props.dataItem[field]).format('YYYY-MM-DD');
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={props?.style ?? {}}
  >
    {value}
  </td>;
};