const CustomLegend = (props) => {
    
    return  <div title={props.onHoverTitle} style={{backgroundColor: props.tileBackColor ? props.tileBackColor : "white",
                        height: props.titleHeight ? props.titleHeight : "22px",
                        border: '0.05em solid #9a9c92',borderRadius: '3px',
                        textAlign : props.titleTextAlign ? props.titleTextAlign : "center",
                        color: props.titleColor ? props.titleColor : "#f5f5f5",
                        fontWeight:props.titleFontWeight ? props.titleFontWeight :"normal",
                        fontSize: props.titleFontSize ? props.titleFontSize : "13px",
                        marginLeft: props.titleLeftMargin ? props.titleLeftMargin : "02px",
                        marginRight: props.titleRightMargin ? props.titleRightMargin : "02px",
                        marginTop: props.titleTopMargin ? props.titleTopMargin : "0px",
                        marginBottom: props.titleBottomMargin ? props.titleBottomMargin : "0px",
                        verticalAlign:props.titleVerticalAlignment ? props.titleVerticalAlignment :"center",
                        fontFamily: props.titleFontFamily ? props.titleFontFamily : "sans-serif",
                        letterSpacing:"0.8px"
                        
                        
       }}> &nbsp; &nbsp;
        {props.title} &nbsp;&nbsp;
    </div>
}

export default CustomLegend;