/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import { ENTITYNAME, LOCALSTORAGE_KEY, COLUMNSTYPE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { json, useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import EditForm from '../../framework/forms/helpercomponents/editForm'
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { utility } from "../../framework/utility/utilityProvider";
import { DeleteConfirmDialogBox } from "../../framework/forms/helpercomponents/alert/DeleteConfirmDialogBox";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { UserPreference } from "../userPreference/UserPreference";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { DateOnlyCell, DateTimeCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { toast } from 'react-toastify';
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { orderBy } from "@progress/kendo-react-data-tools";
import MyStatusCell from "../../components/MyStatusCell";
import JobScheduleHistory from "./JobScheduleHistory";
import JobScheduleTestRunPopup from "./JobScheduleTestRunPopup";

const initialSort = [
    {
        field: "_id",
        dir: "asc",
    },
];

export const JobScheduleCollection = (props) => {
    //possible props
    var page_take = props.page_take ?? 30;
    var showAdd = props.showAdd ?? true;
    var showBack = props.showBack ?? true;
    var showCommandCell = props.showCommandCell ?? true;
    var showEdit = props.showEdit ?? true;
    var showDelete = props.showDelete ?? true;
    var showTitle = props.showTitle ?? true;
    var gridRowRender = props.gridRowRender;
    var CustomEditForm = props.customEditForm ?? EditForm;
    var filterable = props.filterable ?? false;
    var sortable = props.sortable ?? true;
    var isPageable = props.isPageable ?? true;
    var wherestatement = props.wherestatement;
    var andWhereStatement = props.andWhereStatement;
    var customColumns = props.customColumns ?? [];
    var openNewEditForm = props.openNewEditForm ?? false;
    var myTitle = props.title ?? "";
    var myMenuModel = props.menuModel ?? collectionMenuModel;
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    var entityName = props.entityname;
    var showPreferenceButton = props.showPreferenceButton ?? true;
    var hideArchiveProps = props.hideArchive ?? false;

    // example custome columns[]
    const navigate = useNavigate();
    const lang = useTranslation();

    const [gridData, setGridData] = useState([]);
    const [filterGridData, setFilterGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const [warningDialog, setWarningDialog] = useState(false);
    const [selectedDataItem, setSelectedDataItem] = useState({});
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive, setHideArchive] = useState(hideArchiveProps);
    //const [entityNameData, setEntityNameData] = useState([]);
    const [dataItem, setDataItem] = useState({})
    const [sort, setSort] = useState(initialSort);

    const [dataState, setGridDataState] = useState({
        take: page_take,
        skip: 0,
    });

    //-----

    const jobScheduleRef = useRef(null);
    const [ showJobsPopup, setShowJobsPopup ] = useState(false);
    const [ showTestJobPopup, setShowTestJobPopup ] = useState(false);


    useEffect(() => {
        loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
    }, [props.entityname, props.gridData, props.title]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);

        //let finalData = gridData.filter((obj) => obj.entityName == dataItem.filterName.entityName).sort((a, b) => a.sequence - b.sequence);
        console.log(gridData)
        if (!text || text == "") {
            setFilterGridData(gridData);
        } else {
            let data = gridData.filter((item) => {
                return item.Name.toLowerCase().includes(text.toLowerCase()) || item.Description.toLowerCase().includes(text.toLowerCase()) || item.Command.toLowerCase().includes(text.toLowerCase());
            });
            setFilterGridData(data);
        }
    };

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
        }
    };

    const handleNavigate = (item) => {
        navigate(`${item.path}`, { state: item });
    }

    //onSubmit from EDIRFORM
    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        var isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var res = await API.saveData(props.entityname, dataToSave);
        console.log(res);
        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //for new created item
        if (dataItem.SID === 0) {
            setGridData([...gridData, dataItem]);
        } else {
            //UPDATE DATA GRID
            let newData = gridData.map((item) => {
                if (dataItem.SID === item.SID) {
                    item = { ...dataItem };
                }
                return item;
            });
            setGridData(newData);
        }

        //to load grid with new data
        loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        setSelectedDataItem(dataItem);
        console.log(dataItem);
        setWarningDialog(true);

    };

    const handleArchive = async (dataItem) => {
        console.log({
            ...dataItem,
            Archive: !dataItem.Archive,
        });
        var resArchiveUpdated = await API.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })
        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const onCloseUserPrefence = () => {
        loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
        setShowPreferences(!showPreferences);

    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            dataColumns={dataColumns}
            onDelete={handleDelete}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            toNavigate={handleNavigate}
            handleStartStopJob={handleStartStopJob}
            showJobs={(item) => { setShowJobsPopup(true); jobScheduleRef.current = item }}
            showTestJobPopup = {(item) => { setShowTestJobPopup(true); jobScheduleRef.current = item }}
            myMenuModel={myMenuModel}
        />
    );


    const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;


    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive) => {
        console.log(props)
        console.log("loading");
        if (props.gridColumns) {
            setDataColumns(props.gridColumns);
            setGridData(props.gridData);
            setIsLoading(false);
        } else {
            try {
                var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
                var columns = await API.getFormData(props.entityname, currentUserId);
                var json = await API.getEntitiesWithSearch(props.entityname, { query: wherestatement, archive: archive }, columns, searchText);

                setGridData(json.data);
                setFilterGridData(json.data);

                customColumns.length > 0
                    ? setDataColumns(customColumns)
                    : setDataColumns(columns);
                setIsLoading(false);
                console.log(columns)


            } catch (error) {
                console.log("error", error);
                setIsLoading(false);
                setIsNoRecords(true);
            }
        }
    };

    const onChange = (e) => {
        if (e.target.name == 'filterName') {
            var filteredData = [];
            if (e.target.value.module == "All") {
                filteredData = gridData;
            } else {
                filteredData = gridData.filter((obj) => (obj.entityName == e.target.value.entityName));
            }
        }
        setFilterGridData(filteredData.sort((a, b) => a.sequence - b.sequence));
        //setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const handleDailogClose = () => {
        setWarningDialog(!warningDialog);
    }
    const handleErrorDialogClose = () => {
        setDeleteError(!deleteError);
        setDeleteErrorMessage("");
    }
    const errorHandler = (message) => {
        setWarningDialog(!warningDialog);
        setDeleteError(true);
        setDeleteErrorMessage(message);
    }

    const onConfirmDelete = async () => {
        const res = await API.deleteData(props.entityname, selectedDataItem.SID);
        console.log("Loading");
        if (!res.success) {
            console.log(res.message);
            errorHandler(res.message);
            return;
        }
        loaddata({ per_page: page_take }, wherestatement, andWhereStatement, '');
        utility.deleteLocalStorageItem(props.entityname);
    }

    const dataStateChange = async (e) => {
        var current_page = e.dataState.skip / e.dataState.take + 1;

        await loaddata({
            per_page: e.dataState.take,
            current_page: current_page,
            orderby: "SID",
            direction: "asc",
        }, searchText);
        setGridDataState(e.dataState);
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        if (!showArchived) {
            loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, true);
        } else {
            loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false);
        }

    }

    const refreshCollection = () => {
        loaddata({ per_page: page_take }, wherestatement, andWhereStatement, "", showArchived);
        setOpenForm(false);
        setSearchText('');
    }

    const editgrid = (data) => {
        console.log(data);
        let filterGridDataLocal = [...filterGridData];
        filterGridDataLocal = filterGridDataLocal.map(item => {
            if (data._id == item._id) {
                console.log(item);
                return data;
            }
            return item;
        })


        if (!filterGridDataLocal.some((item) => item._id == data._id)) {
            // here it is new value data has been added on filter data 
            filterGridDataLocal = [...filterGridData, data];
        }
        // update ui
        setFilterGridData(filterGridDataLocal.sort((a, b) => a.sequence - b.sequence));
        setOpenForm(false)

    }

    const handleStartStopJob = async (jobSchedule) => {

        console.log(jobSchedule)
        if(!jobSchedule){
            toast.error("No Job Schedule Found", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //START
        if(jobSchedule){
            let updateData = {_id : jobSchedule._id, Disable : !jobSchedule.Disable};
            if(!jobSchedule.Disable){
                updateData.NextScheduleTime = null
            }
            let updateRes = await API.saveData(ENTITYNAME.JobsSchedule,updateData)
            if(jobSchedule.Disable){
                await API.scheduleJobs();
            } else {
                await API.stopJob(jobSchedule._id);
            }

        }

        refreshCollection();

    }

    const MyJobSchedulestatuscell = (props) => (
        <td>
          <MyStatusCell title= {props.dataItem.Disable ? "Disable" : "Active"} tileBackColor= {props.dataItem.Disable ? "red" : "#2ba666" } />
        </td>
    );

    
    return (
        <>
            <div>
                {showTitle && (
                    <>
                        <h1 className="page-title txt-color-bludeDark">
                            <i
                                className="fa fa-table marlr"
                                style={{ fontSize: "20px", color: "GrayText" }}
                            ></i>
                            <span>{myTitle === "" ? props.entityname : myTitle}</span>
                        </h1>
                    </>
                )}
                <div className="flex-container">
                    <div className="martb mar">
                        {showBack && (
                            <BackRoundButton
                                onClick={() => navigate(-1)}
                            />
                        )}

                        {showAdd && (
                            <AddRoundButton
                                onClick={() => enterEdit({ SID: 0 })}
                            />
                        )}
                    </div>

                    <div className="flex-container-reverse flex-item-auto">
                        <div className="input-group input-group w-300 martb" >
                            <input
                                type="text"
                                className="form-control shadow-inset-2 pt-0 pb-0"
                                id="searchInput"
                                placeholder={lang.search_button_text}
                                onChange={onSearch}
                                value={searchText}
                                onFocus={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(true);
                                    }
                                }}
                                onBlur={(e) => {
                                    if (props.setOutSideFocus) {
                                        props.setOutSideFocus(false);
                                    }
                                }}
                            />
                            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                    </span>
                                </div>
                            </button>
                        </div>
                        {showPreferenceButton && <div className="mar" style={{ paddingTop: "12px" }}>
                            <RoundButton
                                icon={"gear"}
                                title={lang.preference_button_tooltip}
                                onClick={() => { setShowPreferences(!showPreferences) }}
                            />
                        </div>
                        }
                        <div className="ml-2" style={{ marginTop: "11px" }}>
                            <RoundButton
                                icon={"refresh"}
                                title={lang.refresh_button_tooltip}
                                onClick={refreshCollection}
                            />
                        </div>

                        {!hideArchive && <div className="d-inline mt-3 mr-2">
                            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                        </div>}
                    </div>
                </div>
                {isLoading && <Loader />}

                {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
                {!isLoading && !isNoRecords && <Grid
                    style={{
                        height: "76vh",
                    }}
                    data={orderBy(filterGridData, sort)}
                    sortable={sortable}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    {...dataState}
                    pageable={isPageable}
                    onDataStateChange={dataStateChange}
                    total={total}
                >
                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                    )}
                    <GridColumn field="Name" title={lang.name_column} width={'160px'} />
                    <GridColumn field="" cell={MyJobSchedulestatuscell} title={lang.status_column} width={'120px'}/>
                    <GridColumn field="SuccessCount" title={lang.success_count_column} width={'120px'}/>
                    <GridColumn field="LastActivity" cell={LocalDateTimeColumn} title={lang.last_activity_count_column} width={'160px'}/>
                    <GridColumn field="NextScheduleTime" cell={LocalDateTimeColumn} title={lang.next_schedule_time_column} width={'160px'}/>
                    <GridColumn field="Description" title={lang.description_column} width={'160px'} />
                    <GridColumn field="Command" title={lang.command_column} width={'160px'} />
                    <GridColumn field="ExecuteShell" title={lang.execute_shell_column} width={'160px'} />
                    <GridColumn field="Schedule" title={lang.schedule_column} width={'160px'} />
                    <GridColumn field="Disable" title={lang.disable_column} cell={MyCheckBoxCell} width={'160px'} />
                    <GridColumn field="Notification" title={lang.enable_notification_label} cell={MyCheckBoxCell} width={'160px'} />
                    <GridColumn field="EnableErrorLog" title={lang.enable_error_log_column} cell={MyCheckBoxCell} width={'160px'} />
                    <GridColumn field="Archive" title={lang.archived_column} cell={MyCheckBoxCell} width={'160px'} />
                    
                </Grid>}

                {openForm && (
                    <CustomEditForm
                        {...props} //to get other props from customEditForm
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        editgrid={editgrid} //to refresh the collection when data added
                        item={editItem}
                        refresh={refreshCollection}
                        dataColumns={dataColumns}
                    />
                )}

                {warningDialog &&
                    <DeleteConfirmDialogBox message={`Do you really want to delete ${props.title ?? props.entityname}`} onClose={handleDailogClose} onConfirmDelete={onConfirmDelete} />}

                {deleteError &&
                    <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
                {showPreferences && <UserPreference entityName={entityName} handleClose={onCloseUserPrefence} />}

                {showJobsPopup && <JobScheduleHistory 
                    jobSchedule={jobScheduleRef.current} 
                    closeForm={() => { jobScheduleRef.current = null; setShowJobsPopup(false); }} 
                />}

                {showTestJobPopup && <JobScheduleTestRunPopup 
                    jobSchedule={jobScheduleRef.current} 
                    closeForm={() => { jobScheduleRef.current = null; setShowTestJobPopup(false); }} 
                />}

            </div>
        </>
    );
};