/* eslint-disable */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ENTITYNAME } from '../../../framework/constant/constant';
import * as API from "../../../framework/API/api";
import { useTranslation } from '../../../locale/useTranslation';
import { toast } from 'react-toastify';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import EditRoundButton from '../../../framework/forms/helpercomponents/buttons/EditRoundButton';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import RoundButton from '../../../framework/forms/helpercomponents/buttons/RoundButton';
import { colorCell } from '../../../framework/forms/helpercomponents/CustomGridCells/ColorCell';
import { Switch } from "@mui/material";

export const GenreStyle = (props) => {

    let blankDataItem = {
        genre: {},
        FontSize: 10,
        ForegroundColor: "#000000",
        BackgroundColor: "#ffffff",
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [genres, setGenres] = useState([]);
    const [data, setData] = useState(props.genreStyleData ?? []);
    const [editMode, setEditMode] = useState(false);
    const [showGenre, setShowGenre] = useState(props.showGenre ?? false);

    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let genreRes = await API.getDataLookup(ENTITYNAME.Genre, { query: ["Archive", "=", false] });
        setGenres(genreRes.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (!dataItem.genre || Object.keys(dataItem.genre).length === 0) {
            toast.error(lang.please_select_genre_error_message);
            return false;
        }
        if (!dataItem.FontSize || dataItem.FontSize < 0) {
            toast.error(lang.font_size_should_be_greater_than_zero_error_message);
            return false;
        }
        return true;
    }

    const handleSelectedData = () => {
        if (!isValid()) return;
        let tempData = {
            genre: {
                _id: dataItem.genre._id,
                SID: dataItem.genre.SID,
                Description: dataItem.genre.Description
            },
            FontSize: parseFloat(dataItem.FontSize),
            ForegroundColor: dataItem.ForegroundColor,
            BackgroundColor: dataItem.BackgroundColor
        }

        if (!editMode && data.some(x => x.genre._id === tempData.genre._id)) {
            toast.error(lang.data_already_exist_error_message);
            return;
        }

        if (editMode) {
            let updateData = data.map(x => x.genre._id === dataItem.genre._id ? dataItem : x);
            setData(updateData);
            props.setGenreStyle(updateData);
        } else {
            setData([...data, tempData]);
            props.setGenreStyle([...data, tempData]);
        }

        setDataItem(blankDataItem);
        setEditMode(false);
    }

    const deleteSelectedItem = (item) => {
        if (editMode) {
            toast.error(lang.you_can_not_delete_in_edit_mode_error_message);
            return;
        }
        let tempData = data.filter(x => x !== item);
        setData(tempData);
        props.setGenreStyle(tempData);
    }

    const editSelectedItem = (item) => {
        setEditMode(true);
        setDataItem(item);
    }

    const myCommandCell = (props) => {
        return (
            <div className='mt-2 ml-1'>
                <EditRoundButton onClick={() => editSelectedItem(props.dataItem)} />
                <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedItem(props.dataItem), () => { })} />
            </div>
        );
    }

    return (
        <div>
            <div className="row">
                <div className="col-6">
                    <label style={{FontSize: '16px'}}>{lang.genre_label}</label>
                    <Switch name="IsGenreColorPreference" checked={showGenre} onChange={(e)=> {setShowGenre(e.target.checked); props.getShowGenre(e.target.checked)} } />
                </div>
            </div>
            {showGenre && <>
            <div className="row mt-1">
                <div className='col-1'>
                    <RoundButton icon={editMode ? 'floppy-o' : 'plus'} style={{ marginTop: '20px' }} onClick={handleSelectedData} />
                </div>
                <div className="col-3">
                    <label>{lang.genre_label} *</label>
                    <DropDownList
                        style={{ BackgroundColor: 'white' }}
                        data={genres}
                        name="genre"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onChange}
                        value={dataItem.genre}
                        disabled={editMode}
                    />
                </div>
                <div className="col-2">
                    <label>{lang.font_size_label} *</label>
                    <input type="number" style={{ width: '70px' }} onChange={onChange} name='FontSize' value={dataItem.FontSize} className="form-control form-control-sm" min={0} />
                </div>
                <div className="col-3">
                    <label>{lang.foreground_Color_label} *</label>
                    <input type="color" style={{ width: '50px' }} onChange={onChange} name='ForegroundColor' value={dataItem.ForegroundColor} className="form-control form-control-sm" />
                </div>
                <div className="col-3">
                    <label>{lang.background_color_label} *</label>
                    <input type="color" style={{ width: '50px' }} onChange={onChange} name='BackgroundColor' value={dataItem.BackgroundColor} className="form-control form-control-sm" />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <Grid
                        data={data}
                        style={{ height: '260px' }}
                    >
                        <Column cell={myCommandCell} width={"75px"} />
                        <Column field="genre.Description" title={lang.genre_label} />
                        <Column field="FontSize" title={lang.font_size_label} />
                        <Column field="ForegroundColor" cell={colorCell} title={lang.foreground_Color_label} />
                        <Column field="BackgroundColor" cell={colorCell} title={lang.background_color_label} />
                    </Grid>
                </div>
            </div> </>}
        </div>
    )
}