/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { RadioButton } from "@progress/kendo-react-inputs";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-data-query";


export const ClientAgencyBookingReport = (props) => {
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);
    const defaultDates = utility.getFirstAndLastDateOfMonth();

    const blankDataItem = { AgencyButton: true, ClientButton: false, Channel: {}, SelectAll: false, salesteamlist: [], FromDate: defaultDates.FirstDate, ToDate: defaultDates.FirstDate, }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const navigate = useNavigate();
    const [channel, setChannel] = useState([]);
    const lang = useTranslation();
    const [agency, setAgency] = useState([]);
    const [client, setClient] = useState([]);
    const [salesTeam, setSalesTeam] = useState([]);
    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }
    const selectedSalesTeamItemRef = useRef([]);
    const setSelectedSalesTeamItemRef = (items) => {
        selectedSalesTeamItemRef.current = items;
    }
    const [selectedState, setSelectedState] = useState({});

    const payloadRef = useRef({});
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);

        var salesTeamRes = await API.getDataLookup(ENTITYNAME.SalesTeam, { sort: { Name: 1 } });
        setSalesTeam(salesTeamRes.data);
        var agencyRes = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
        setAgency([{ SID: 0, Name: "All" }, ...agencyRes.data]);
        var clientRes = await API.getDataLookup(ENTITYNAME.Client, { sort: { Name: 1 } });
        setClient([{ SID: 0, Name: "All" }, ...clientRes.data]);
    }

    const borderWithTitle = {
        position: "relative",
        fontWeight: "bold",
        fontSize: "13px",
        top: "-0.8em",
        marginLeft: "0.8em",
        display: "inline",
        backgroundColor: "white"
    };

    const onChange = async (e) => {
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "SelectAll") {
            setDataItem({
                ...dataItem, SelectAll: !dataItem.SelectAll,
                AgencyButton: false,
                ClientButton: false
            });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }

    };

    const onChangeRadioButton = (e) => {
        if (e.target.name == "AgencyButton") {
            setDataItem({
                ...dataItem, AgencyButton: !dataItem.AgencyButton,
                ClientButton: false
            });


        } else {
            setDataItem({
                ...dataItem, ClientButton: !dataItem.ClientButton,
                AgencyButton: false
            });
        }
    }

    const isValid = () => {
        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate < dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (selectedSalesTeamItemRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_saleteam_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true
    }

    const clientAgencyBookingExportData = async () => {
        if (isValid()) {
            var fromdate = (new Date(dataItem.FromDate)).setHours(0, 0, 0, 0);
            var todate = (new Date(dataItem.ToDate)).setHours(23, 59, 59);
            payloadRef.current = {
                startdate: fromdate,
                enddate: todate,
                channelsid: selectedChannelRef.current.SID,
                client: dataItem.ClientButton ? (dataItem.Client != undefined ? dataItem.Client.SID : 0) : 0,
                agency: dataItem.AgencyButton ? (dataItem.Agency != undefined ? dataItem.Agency.SID : 0) : 0,
                salestemsid: selectedSalesTeamItemRef.current.map((item) => { return item.SID }),
            }
            loadData();
        }
        console.log(payloadRef.current)
    }

    const loadData = async () => {
        var res = await API.getClientAgencyBookingReport(payloadRef.current);
        // setInterstitialData(resBookinSchedule); 
        if (res.success) {
            if (res.dataLength == 0) {
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            else {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };


    const onCancel = () => {
        navigate(-1);
    }

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];
        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = salesTeam.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedSalesTeamItemRef(tempSelected);
    };
    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];
        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                var filter = salesTeam.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedSalesTeamItemRef(tempSelected);
    };
    const gridRowRender = (tr, props) => {
        const trProps = {
            onContextMenu: (e) => {
                ref.current = props.dataItem;
                if (Object.keys(selectedState).length == 0) {
                    toast.error(`${lang.please_select_atleast_one_item}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }
                cm.current.show(e);
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            >
            </i>
            <span>{lang.client_agency_booking_report_header}</span>
        </h1>
        <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
            <div className="row">
                <div className="flex-container wrap">
                    <div className="row mt-1 mb-1" style={{ marginLeft: '-25px' }}>
                        <div className="col">
                            <ActionButton title={lang.download_client_agency_booking_report_tooltip_button} name={lang.export_button_text} btnColor={'info'} onClick={clientAgencyBookingExportData} />
                            <CancelButton onClick={onCancel}></CancelButton>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row mt-3">
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.from_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} max={dataItem.ToDate} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.to_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                    </div>
                </div>
            </div>
            <div className="row mt-2 mb-2">
                <div className="ml-3">
                    <input type="checkbox" name={"SelectAll"} value={dataItem.SelectAll} checked={dataItem.SelectAll} onChange={onChange} />
                    <label className="pl-2" htmlFor="TabView">{lang.select_all_label}</label>
                </div>
                <div className="col-10">
                    <div className="row">
                        <div className="col-2" style={{ padding: "0" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton
                                style={{ marginLeft: "5px" }}
                                name="ClientButton"
                                value={dataItem.ClientButton}
                                checked={dataItem.ClientButton}
                                disabled={dataItem.SelectAll}
                                label=""
                                onClick={onChangeRadioButton}
                            />&nbsp;&nbsp;&nbsp;&nbsp;
                            <label htmlFor="TabView">{lang.client_label}</label>
                        </div>
                        <div className="col-2" style={{ padding: "0" }}>
                            <RadioButton
                                name="AgencyButton"
                                checked={dataItem.AgencyButton}
                                value={dataItem.AgencyButton}
                                disabled={dataItem.SelectAll}
                                label=""
                                onClick={onChangeRadioButton}
                            />&nbsp;&nbsp;&nbsp;&nbsp;
                            <label htmlFor="TabView">{lang.agency_label}</label>
                        </div>  </div>  </div>

            </div>
            <div className="row mt-2">
                {dataItem.AgencyButton ?
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.agency_label}</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            disabled={dataItem.SelectAll}
                            data={agency}
                            name="Agency"
                            defaultValue={agency[0]}
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.Agency}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please Select Client"}
                        />
                    </div> :
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.client_label}</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            disabled={dataItem.SelectAll}
                            data={client}
                            name="Client"
                            defaultValue={client[0]}
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.Client}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please Select Agency"}
                        />
                    </div>}

                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            data={channel}
                            name={"Channel"}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-3" style={{ width: "660px", height: "40vh", margin: "1px 0px 15px 0px", border: '1px solid black', borderRadius: '5px!important' }}>
                <div style={borderWithTitle}>&nbsp; {lang.sales_team_label} &nbsp;</div>
                <div className="row">
                    <div className="col-12">
                        <Grid style={{
                            height: "36vh",
                            margin: "0px 05px 05px 05px",
                        }}
                            data={salesTeam.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                            }}
                            dataItemKey={DATA_ITEM_KEY}
                            onSelectionChange={onSelectionChange}
                            resizable={true}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                            rowRender={gridRowRender}
                        >
                            <Column
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    salesTeam.findIndex(
                                        (item) => !selectedState[idGetter(item)]
                                    ) === -1
                                }
                                locked={true}
                                filterable={false}
                            />
                            <Column field="Name" title={lang.sales_team_label} />
                            <Column field="Region.Description" title={lang.sales_team_label} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>

    </>)
}
export default ClientAgencyBookingReport;