import React, { useRef, useState } from "react";
import { Grid, GridColumn as Column, GridNoRecords, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../../locale/useTranslation";
import { useEffect } from "react";
import { ENTITYNAME, FILEURL, PARTNER_INGESTION_STATUS, STATUS_INGESTION } from "../../../framework/constant/constant";
import moment from "moment";
import { toast } from "react-toastify";
import * as API from "../../../framework/API/api";
import { DateTimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import MyStatusCell from "../../../components/MyStatusCell";
import { AuditLogResponse } from "../../Audit Log/AuditLogResponse";
import { utility } from "../../../framework/utility/utilityProvider";
import { Ingestion_log_Column } from "../../../framework/utility/customColumns";

const IngestionLogsForm = (props) => {

  const lang = useTranslation();
  const today = moment();

  const blankDataItem = {
    FromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    ToDate: today.format('YYYY-MM-DD'),
    Status: {}
  }

  const [dataItem, setDataItem] = useState(blankDataItem)
  const [status, setStatus] = useState([])
  const [gridData, setGridData] = useState([])
  const [showResponseOpen, setShowResponseOpen] = useState(false);

  const [oldValue, setOldValue] = useState({});
  const [newValue, setNewValue] = useState({});
  const [detailsValue, setDetailsValue] = useState({});
  const [showResponse, setShowResponse] = useState([]);

  useEffect(() => {
    loadCombo();
  }, [])

  const loadCombo = () => {
    let statusRes = Object.keys(PARTNER_INGESTION_STATUS).map((x) => {
      return {
        _id: PARTNER_INGESTION_STATUS[x],
        Description: x
      }
    })
    setStatus(statusRes)
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValid = () => {
    if (dataItem.FromDate == undefined || dataItem.FromDate == "") {
      toast.error(`${lang.please_select_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ToDate == undefined || dataItem.ToDate == "") {
      toast.error(`${lang.please_select_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ToDate < dataItem.FromDate) {
      toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Status).length == 0) {
      toast.error(`${lang.please_select_status_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true
  }

  const loadData = async () => {
    if (!isValid()) return;
    let startdate = utility.convertStringDatetoMilliseconds(dataItem.FromDate);
    let endDate = utility.convertStringEndDateToMilliSeconds(dataItem.ToDate);
    let status = dataItem.Status._id;
    let res = await API.getData(ENTITYNAME.IngestionLogs, {
      query: [
        ['EntryDateTime', '>=', startdate], ['EntryDateTime', '<=', endDate],
        ['Status', '=', "Complete"]//status
      ],
      sort: { EntryDateTime: -1 }
    });

    console.log(res)
    if (res.success) {
      if (res.data.length == 0) {
        toast.error(lang.no_record_found_error_message, {
          position: toast.POSITION.TOP_RIGHT
        });

      }
      setGridData(res.data);
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }


  const exportIngestionLogData = async () => {
    let columns = [{ field: 'EntryDateTime', title: 'Date' }, { field: 'User', title: 'User' }, { field: 'Event', title: 'Event' }];
    let res = await API.exportGridExcel(gridData, Ingestion_log_Column);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data);
      toast.success(lang.successfully_download_success_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.Status} tileBackColor="#72eda9" />
    </div>
  )

  const RoundedButton = (props) => <RoundButton icon={"eye"} style={{ marginLeft: '20px', marginTop: '5px' }} title={lang.show_response_label} onClick={() => handleShowResponse(props.dataItem)} />

  const handleShowResponse = async (item) => {
    setNewValue(item?.Data ? item?.Data : {});
    setOldValue({});
    setDetailsValue({
      EntryDateTime: moment(item?.EntryDateTime).format('YYYY-MM-DD HH:mm:ss'),
      User: item.Data.Title,
      Event: ""
    });
    setShowResponseOpen(true);

  }

  const onCloseResponse = () => {
    setShowResponseOpen(false);
    setShowResponse([]);
  }

  return (
    <>
      <h1 className="page-title txt-color-bludeDark">
        <i
          className="fa fa-table marlr"
          style={{ fontSize: "20px", color: "GrayText" }}
        ></i>
        <span>{lang.ingestion_logs_dialog_header}</span>
      </h1>
      <div className="row m-1">
        <div className="col-12">
          <div className="flex-container">
            <div className="">
              <label className="form-label">{lang.from_date_label} *</label>
              <input type="date" className="form-control form-control-sm" name="FromDate" value={dataItem.FromDate} onChange={onChange} style={{ width: '200px' }} />
            </div>
            <div className="ml-3">
              <label className="form-label">{lang.to_date_label} *</label>
              <input type="date" className="form-control form-control-sm" name="ToDate" value={dataItem.ToDate} onChange={onChange} style={{ width: '200px' }} />
            </div>
            <div className="ml-3">
              <label>{lang.status_label} *</label><br />
              <DropDownList
                className="form-control form-control-sm"
                style={{ backgroundColor: "white", width: '300px' }}
                data={status}
                name="Status"
                textField="Description"
                dataItemKey="_id"
                value={dataItem.Status}
                onChange={onChange}
              />
            </div>
            <div className="mt-4 ml-3">
              <ActionButton name={lang.show_button_text} onClick={loadData} />
              <RoundButton icon='download' style={{ marginLeft: '10px' }} title={lang.download_button_tooltip} onClick={exportIngestionLogData} />
            </div>
            <div className="flex-container-reverse flex-item-auto mt-3">
              <div className="input-group input-group w-300 martb" >
                <input
                  type="text"
                  className="form-control shadow-inset-2 pt-0 pb-0"
                  id="searchInput"
                  placeholder={lang.search_button_text}
                  // onChange={onSearch}
                  onFocus={(e) => {
                    if (props.setOutSideFocus) {
                      props.setOutSideFocus(true);
                    }
                  }}
                  onBlur={(e) => {
                    if (props.setOutSideFocus) {
                      props.setOutSideFocus(false);
                    }
                  }}
                />
                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <Grid data={gridData} style={{ height: '70vh' }} resizable={true}>
                <GridColumn field="Data.Title" title={lang.title_column} width={"340px"} />
                <GridColumn field="EntryDateTime" title={lang.entry_date_and_time_column} width={"200px"} cell={DateTimeCell} />
                <GridColumn field="Status" cell={Mystatuscell} title={lang.status_column} width={"140px"} />
                <GridColumn field="Message" title={lang.message_column} width={"240px"} />
                <GridColumn field="Data" title={lang.data_column} cell={RoundedButton} />
              </Grid>
            </div>
          </div>
        </div>
      </div>
      {showResponseOpen && <AuditLogResponse details={detailsValue} oldValue={oldValue} newValue={newValue} onClose={() => onCloseResponse()} />}

    </>
  )
}

export default IngestionLogsForm;
