/* eslint-disable */
import React, { useState } from "react";
import * as API from '../../framework/API/api'
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME, PRODUCTS } from "../../framework/constant/constant";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from "../../components/EditPageHeader";
import BossDialog from "../../components/BossDialog";


export const ModuleEditForm = (props) => {
  
  let blankDataItem = {
    SID: props.item.SID,
    Name: props.item.Name ?? "",
    path: props.item.path ?? "",
    Products: props.item.Products ? props.item.Products.map((x) => { return { _id: x, Description: Object.keys(PRODUCTS).find((y) => PRODUCTS[y] == x) } }) : [],
    Archive:  props.item.Archive ?? false,
    EnableAuditLog: props.item.EnableAuditLog ?? false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const lang = useTranslation();

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValid = () => {
    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Products == undefined || dataItem.Products.length == 0) {
      toast.error(`${lang.please_select_at_least_one_product_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {

    if (isValid()) {
      let localData = {
        SID: dataItem.SID,
        Name: dataItem.Name,
        path: dataItem.path ?? "",
        Products : dataItem?.Products?.length > 0 ? dataItem.Products.map((x) => x._id) : [],
        Archive: dataItem.Archive ?? false,
        EnableAuditLog: dataItem.EnableAuditLog ?? false,
      }
      console.log(localData);
      let res = await API.saveData(ENTITYNAME.Module, localData);
      if (res.success) {
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.Module);
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  return (
      <BossDialog
        title={props.item.Name ? props.item.Name : lang.create_module_header_dialog}
        onClose={props.cancelEdit}
        width={"620px"}
      >
      <div className="row">
        <div className="col-12">
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <div className="mt-2">
                  <div className="row">
                    <div className="col-12 mt-2">
                      <div className="row form-group">
                        <div className="col-6">
                            <label htmlFor="">{lang.name_label}</label>
                            <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={(e) => onChange(e)} />
                        </div>
                        <div className="col-6">
                            <label htmlFor="">{lang.path_label}</label>
                            <input type="text" className="form-control form-control-sm" name="path" value={dataItem.path} onChange={(e) => onChange(e)} />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-6">
                          <label htmlFor="">{lang.products_label}</label>
                                <MultiSelect
                                  data={Object.keys(PRODUCTS).map((obj) => { return { _id: PRODUCTS[obj], Description: obj } })}
                                  textField="Description"
                                  dataItemKey="_id"
                                  value={dataItem.Products}
                                  onChange={onChange}
                                  name={"Products"}
                                />
                        </div>
                        <div className="col-4 mt-4">
                            <Field
                              style={{border: "1px solid grey"}}
                              name={"EnableAuditLog"}
                              component={Checkbox}
                              label={lang.enable_audit_log_label}
                              onChange={(e) => onChange(e)}
                              checked={dataItem.EnableAuditLog}
                            />
                        </div>
                        <div className="col-2 mt-4">
                            <Field
                              style={{border: "1px solid grey"}}
                              name={"Archive"}
                              component={Checkbox}
                              label={lang.archive}
                              onChange={(e) => onChange(e)}
                              checked={dataItem.Archive}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )} />
        </div>
      </div>
      </BossDialog>
      
    
  )
}

export default ModuleEditForm