/* eslint-disable */

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MEDIACATEGORIES } from "../../../constant/constant";

//to show item stored in array on collection
export const HrefCell = (props) => {

  const navigate = useNavigate();

  const field = props.field || "";
  var value = '';
  var link = '';

  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    var keys = field.split('.');
    value = props.dataItem[keys[0]][keys[1]];
  }else{
    value = props.dataItem[field];
  }

  
  var whereStatement = [];

  if(field == 'SeasonsCount'){
    link = `/home/MediaEpisode/Season`
    whereStatement =  ['Series_Id', '=', props.dataItem._id]
  }else if(field == 'Media.Title'){
    var keys = field.split('.');
    link = `/home/MediaEpisode/${props.dataItem[keys[0]]['MediaCategory']['Description']}/${props.dataItem[keys[0]]['SID']}`
  }else if(field == "ContractNumber"){
    link = `/home/Contract/${props.dataItem?.ContractSID}`
  }else if(field == "usersCount"){
    link = '/home/users' + "?companyId=" + props?.dataItem?.companyId
  }else if(field == "plansCount"){
    link = '/home/digitalsignCompanyPlans' + "?companyId=" + props?.dataItem?.companyId
  }
  else{
    link = `/home/MediaEpisode/Episode`
    whereStatement =  [['MediaCategory.SID', '=', MEDIACATEGORIES.Episode],['Season_id','=',props.dataItem._id]]
  }
    
    return <td>
      <div 
        style={{color : 'blue', cursor : 'pointer'}} 
        onClick={() => {field == "ContractNumber" || field == "usersCount" || field == "plansCount" ?  window.open(link, "_blank") : navigate(link , { state: { copy: false, quickEdit: false , href : true, wherestatement : whereStatement} })}}
      >
      {value}
      </div>
    </td>;
};

export const HyperLinkCell = (props) => {

  const field = props.field || "";
  let linkContent = '';
  let linkHref = '';

  if (field.includes('.')) {
    let keys = field.split('.');
    linkContent = props.dataItem[keys[0]][keys[1]];
  } else {
    linkContent = props.dataItem[field];
  }

  if (typeof linkContent === 'string') {
    linkHref = linkContent;
  } else if (linkContent && typeof linkContent === 'object' && linkContent.message) {
    linkHref = linkContent.message;
  } else {
    linkHref = "";
  }

  return (
    <td>
      <div
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => window.open(linkHref, "_blank")}
      >
        {linkHref}
      </div>
    </td>
  );
};
