/* eslint-disable */
import React, { useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE, OTT_POSTER_TYPE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { ImageBrowser } from '../../framework/forms/helpercomponents/selectFile/ImageBrowser';
import BossDialog from '../../components/BossDialog';

const PaymentProviderEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        locale: props.item.locale ?? "",
        liveKeyID: props.item.liveKeyID ?? "",
        liveSecretKey: props.item.liveSecretKey ?? "",
        sandboxKeyID: props.item.sandboxKeyID ?? "",
        sandboxSecretKey: props.item.sandboxSecretKey ?? "",
        currencyCode: props.item.currencyCode ?? "",
        brandingName: props.item.brandingName ?? "",
        brandingLogo: props.item.brandingLogo ?? "",
        brandingColor: props.item.brandingColor ?? "#000000",
        successUrl: props.item.successUrl ?? "",
        failureUrl: props.item.failureUrl ?? "",
        paymentGatewayUrl: props.item.paymentGatewayUrl ?? "",
        message: props.item.message ?? "",
        Archive: props.item.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [imageFiles, setImageFiles] = useState(new Set());

    const onChange = (e) => {
        if (e.target.name === 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == 'brandingLogo' && e.target?.files) {
            console.log( e.target?.files);
            if (e.target.files != undefined) {
                setImageFiles(e.target.files[0]);
            } else {
                setImageFiles(new Set());
            }
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.liveKeyID == undefined || dataItem.liveKeyID == "") {
            toast.error(`${lang.please_enter_live_key_id_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.liveSecretKey == undefined || dataItem.liveSecretKey == "") {
            toast.error(`${lang.please_enter_live_secret_key_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.sandboxKeyID == undefined || dataItem.sandboxKeyID == "") {
            toast.error(`${lang.please_enter_key_id_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.sandboxSecretKey == undefined || dataItem.sandboxSecretKey == "") {
            toast.error(`${lang.please_enter_secret_key_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.currencyCode == undefined || dataItem.currencyCode == "") {
            toast.error(`${lang.please_enter_currency_code_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.brandingName == undefined || dataItem.brandingName == "") {
            toast.error(`${lang.please_enter_branding_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.paymentGatewayUrl == undefined || dataItem.paymentGatewayUrl == "") {
            toast.error(`${lang.please_enter_payment_gateway_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.brandingLogo == undefined || dataItem.brandingLogo == "" || Object.keys(dataItem.brandingLogo).length == 0) {
            toast.error(`${lang.please_enter_branding_logo_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.successUrl == undefined || dataItem.successUrl == "") {
            toast.error(`${lang.please_enter_success_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.failureUrl == undefined || dataItem.failureUrl == "") {
            toast.error(`${lang.please_enter_failure_url_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {

            let imageUrl = dataItem.brandingLogo;

            console.log(imageFiles)
            if (imageFiles.size > 0) {
                let resUrl = await API.uploadImage(imageFiles);
                if (!resUrl.success) {
                    toast.error(resUrl.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }
                imageUrl = resUrl.data;
                dataItem.brandingLogo = imageUrl;
            }

            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID,
                Name: dataItem.Name,
                locale: dataItem.locale,
                liveKeyID: dataItem.liveKeyID,
                liveSecretKey: dataItem.liveSecretKey,
                sandboxKeyID: dataItem.sandboxKeyID,
                sandboxSecretKey: dataItem.sandboxSecretKey,
                currencyCode: dataItem.currencyCode,
                brandingName: dataItem.brandingName,
                brandingLogo: imageUrl,
                brandingColor: dataItem.brandingColor,
                successUrl: dataItem.successUrl,
                failureUrl: dataItem.failureUrl,
                paymentGatewayUrl: dataItem.paymentGatewayUrl,
                Archive: dataItem.Archive ?? false,
                message: dataItem.message ?? "",
                checkDuplicate: true,
                query: [["Name", "=", dataItem.Name]]

            }
            console.log(saveData);
            const res = await API.saveData(ENTITYNAME.PaymentProvider, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_PAYMENT_PROVIDER : LOGEVENT.INSERT_PAYMENT_PROVIDER, module: MODULE.PAYMENT_PROVIDER, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.PaymentProvider);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const handleSelectedImage = (dataList) => {
        setDataItem({ ...dataItem, brandingLogo: dataList[0].ImageUrl });
    }

    return (
        <>
            <BossDialog
                title={props.item.Name ?? lang.create_payment_provider_dialog_header}
                onClose={props.cancelEdit}
                width={"720px"}
                height={"670px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.name_label} *</label>
                                <input
                                    name={"Name"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Name}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.locale_label}</label>
                                <input
                                    name={"locale"}
                                    type="text"
                                    maxLength="10"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.locale}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-6'>
                                <label>{lang.sand_box_key_id_label} *</label>
                                <input
                                    name={"sandboxKeyID"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.sandboxKeyID}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.sand_box_secret_key_label} *</label>
                                <input
                                    name={"sandboxSecretKey"}
                                    type="password"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.sandboxSecretKey}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-6'>
                                <label>{lang.live_key_id_label} *</label>
                                <input
                                    name={"liveKeyID"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.liveKeyID}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.live_secret_key_label} *</label>
                                <input
                                    name={"liveSecretKey"}
                                    type="password"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.liveSecretKey}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-6'>
                                <label>{lang.currency_code_label} *</label>
                                <input
                                    name={"currencyCode"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.currencyCode}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.branding_name_label} *</label>
                                <input
                                    name={"brandingName"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.brandingName}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-12'>
                                <label>{lang.payment_gateway_url_label} *</label>
                                <input
                                    name={"paymentGatewayUrl"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.paymentGatewayUrl}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-6'>
                                <label>{lang.branding_logo_label} *</label>
                                <ImageBrowser wherestatement={["OttPosterTypeSID", "=", OTT_POSTER_TYPE.Logo]} name={'brandingLogo'} value={dataItem.brandingLogo} onChange={onChange} setSelectedImage={handleSelectedImage} />
                            </div>
                            {/* show logo */}
                            <div className='col-1'>
                                <img src={dataItem.brandingLogo} style={{ marginTop: '25px' }} width="150px"/>
                            </div>
                            <div className='col' style={{marginLeft: "150px", marginTop: '5px'}}>
                                <label>{lang.branding_color_label}</label>
                                <input
                                    name={"brandingColor"}
                                    style={{ width: "50px" }}
                                    type="color"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.brandingColor}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-6'>
                                <label>{lang.success_url_label} *</label>
                                <input
                                    name={"successUrl"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.successUrl}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.failure_url_label} *</label>
                                <input
                                    name={"failureUrl"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.failureUrl}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className='col-12'>
                                <label>{lang.message_label}</label>
                                <textarea
                                    name={"message"}
                                    type="input"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.message}
                                />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-6 ml-1'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog >
        </>
    )
}

export default PaymentProviderEditForm;