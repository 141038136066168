/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import { toast } from "react-toastify";
import * as API from "../../framework/API/api"
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { IMDBTYPES } from "../../framework/constant/constant";
import { getter } from "@progress/kendo-data-query";
import { utility } from "../../framework/utility/utilityProvider";
import CustomPaginationDropDown from "../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import moment from "moment";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const SpotifyEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        SearchName: "",
        Type: props?.mediaCategory ? { Description: utility.getKeyByValue(IMDBTYPES, props?.mediaCategory), SID: props?.mediaCategory } : {}
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [selectedItemDetails, setSelectedItemDetails] = useState({});
    const initialDataState = {
        skip: 0,
        take: 10,
    };
    const [page, setPage] = useState(initialDataState);
    const [total, setTotal] = useState(1);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSearch = async () => {

        if (!dataItem.SearchName || dataItem.SearchName == "") {
            toast.warn('Please enter music name you want to search');
            return;
        }

        let payload = {
            searchPhrase: dataItem.SearchName ?? "",
            page: 1,
            limit: 10,
        }
        let res = await API.getSpotifyMusicData(payload);
        console.log(res);
        if (res.success) {
            setPage(initialDataState);
            setGridData(res.data);
            setTotal(res.pagination?.total);
            setSelectedState({});
            setSelectedItemDetails({});
        } else {
            toast.error(res.message);
        }
    }

    const onSelectionChange = useCallback(
        async (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedItemDetails(gridData.filter((item) => newSelectedState[idGetter(item)])[0]);

            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const pageChange = async (e) => {
        let payload = {
            searchPhrase: dataItem.SearchName ?? "",
            page: (e.page.skip / e.page.take) + 1,
            limit: 10,
        }
        let res = await API.getSpotifyMusicData(payload);
        if (res.success) {
            setGridData(res.data);
            setSelectedState({});
            setSelectedItemDetails({});
            setPage(e.page);
        } else {
            toast.error(res.message);
        }
    };

    const handleSubmit = async () => {
        if (gridData.filter((item) => selectedState[idGetter(item)]).length == 0) {
            toast.error(lang.please_select_item_first_error_message);
            return;
        }
        props.setMusicData(selectedItemDetails);
        props.cancelEdit();
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            onSearch();
        }
    };


    return (
        <BossDialog
            title={'Select Music From Spotify'}
            onClose={props.cancelEdit}
            width={"75%"}
            height={"600px"}
        >
            <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={props.cancelEdit} />
            <div className="row">
                <div className="col-12" >
                    <div className="row mt-2">
                        <div className="col-4">
                            <label>{lang.search_name_label} *</label>
                            <div className="d-flex">
                                <input
                                    type="text"
                                    className="form-control form-control-sm shadow-inset-2"
                                    id="searchInput"
                                    name="SearchName" value={dataItem.SearchName}
                                    onChange={(e) => setDataItem({ ...dataItem, SearchName: e.target.value })}
                                    onKeyDown={handleKeyDown}
                                    placeholder={'Search Music'}
                                    onFocus={(e) => {
                                        if (props.setOutSideFocus) {
                                            props.setOutSideFocus(true);
                                        }
                                    }}
                                    onBlur={(e) => {
                                        if (props.setOutSideFocus) {
                                            props.setOutSideFocus(false);
                                        }
                                    }}
                                />
                                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "30px" }} >
                                    <div className="input-group-append" style={{ height: '32px' }}>
                                        <span className="input-group-text">
                                            <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                        </span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="col mt-3">
                            <ActionButton name={lang.search_button_text} title={lang.search_button_tooltip} onClick={onSearch} />
                        </div>
                    </div>
                    <div className="row mt-3 imdb">
                        <div className={(gridData?.filter((item) => selectedState[idGetter(item)]).length != 0) ? "col-5" : "col-12"} >
                            <Grid
                                data={gridData.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                                dataItemKey={DATA_ITEM_KEY}
                                selectedField={SELECTED_FIELD}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "single",
                                }}
                                pageable={true}
                                skip={page.skip}
                                take={page.take}
                                total={total}
                                onPageChange={pageChange}
                                onSelectionChange={onSelectionChange}
                                resizable={true}
                                style={{ height: "400px" }}
                                pager={(props) => <CustomPaginationDropDown {...props} />}

                            >
                                <GridColumn field="Poster" title={lang.poster_column} width={80} cell={ImageCell} />
                                <GridColumn field="Title" title={lang.title_column} />
                                <GridColumn field="duration" title="Duration" cell={TimeCell} />
                            </Grid>
                        </div>
                        {(gridData.filter((item) => selectedState[idGetter(item)]).length != 0) && <div className="col-7 border" style={{ height: "400px", overflowY: 'auto', maxWidth: " 57.333333%" }}>
                            <i className="fa fa-times-circle float-right mt-1" style={{ fontSize: '25px', cursor: 'pointer' }} onClick={() => { setSelectedState({}); setSelectedItemDetails({}) }} />
                            <div className="row mt-2" >
                                <div className="col-6">
                                    <div className='row'>
                                        <div className="col-12 mt-2">
                                            <label style={{ fontSize: '25px' }} htmlFor="Title">{utility.subString(selectedItemDetails?.Title, 21)}</label>
                                        </div>
                                        <div className="col-12 mt-3" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold" >{'Duration'} :
                                                <span className="ml-1 font-weight-normal">
                                                    {utility.convertMilisecondsToStringWithFrames(selectedItemDetails?.duration)}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold">Explicit :</label>
                                            <label className="ml-1">{selectedItemDetails?.explicit ? 'Yes' : 'No'}</label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }}>
                                            <label className="font-weight-bold">Artist : </label>
                                            <label className="ml-1">{selectedItemDetails?.artists.map((item) => item.name).join(",")}</label>
                                        </div>
                                        <div className="col-12 mt-2" style={{ fontSize: '18px' }} >
                                            <label className="font-weight-bold">Release Date :</label>
                                            <label className="ml-1"> {moment(selectedItemDetails?.releaseDate, "YYYY-MM-DD").format("DD-MM-YYYY")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className='mt-2' style={{ textAlign: "center" }}>
                                        <img style={{ borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} width={"200px"} src={selectedItemDetails?.Poster.length > 0 ? utility.getPosterUrl(selectedItemDetails?.Poster) : "https://res.cloudinary.com/dl2n34gbw/image/upload/v1714465970/wymufa5fvzqe16x8st6l.png"} height={"230px"} alt='Dummy' />
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

        </BossDialog >
    )
}

export default SpotifyEditForm;