/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import * as API from '../../framework/API/api'
import { ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import IMDBEditForm from "./IMDBEditForm";

function MediaEpisodeCastAndCrewEditForm(props) {

  const [dataItem, setDataItem] = useState({
    Name: "",
    CastType: {}
  });

  const [castType, setCastType] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showIMDbForm, setShowIMdbForm] = useState(false);

  const lang = useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }

  useEffect(() => {
    loadCombo();
  }, [])

  const loadCombo = async () => {
    let castTypeRes = await API.getDataLookup(ENTITYNAME.CastType, { sort: { Description: 1 }, query: ["Archive", "!=", true] });
    setCastType(castTypeRes.data);

    if (castTypeRes.data?.length > 0) {
      setDataItem((oldval) => ({ ...oldval, CastType: castTypeRes.data[0] }));
    }
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const MyCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <EditRoundButton onClick={() => editCastAndCrewButton(props)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeCastAndCrew(props.dataIndex), () => { })} />
    </div>
  )

  const editCastAndCrewButton = (data) => {
    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setToggle(true);
    setEditMode(true);
  }

  const isValidforadd = () => {
    if (dataItem.Name == "") {
      toast.error(`${lang.please_enter_name_cast_crew_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.CastType == undefined || Object.keys(dataItem.CastType).length == 0) {
      toast.error(`${lang.please_select_casttype_cast_crew_media_episode_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const addCastAndCrew = (index) => {
    if (isValidforadd()) {
      const dataNew = {
        ...dataItem,
        CastType: typeof dataItem.CastType === "string" ? JSON.parse(dataItem.CastType) : dataItem.CastType,
      }
      props.setCastAndCrew([...props.data, dataNew]);
      setDataItem({ Name: '' });
      clearData();
    }
  }

  const removeCastAndCrew = (index) => {
    props.setCastAndCrew([
      ...props.data.slice(0, index),
      ...props.data.slice(index + 1, props.data.length)
    ]);
    clearData();
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editCastAndCrew = () => {
    if (isValidforadd()) {
      let local = [...props.data]
      local[selectedIndexRef.current] = dataItem;
      props.setCastAndCrew(local);
      setEditMode(false);
      clearData();
    }
  }

  const clearData = () => {
    setDataItem({ Name: '', castType: '' });
    setEditMode(false);
    setToggle(false);
  }

  const handleIMDbdataList = (data) => {
    console.log(data);
    let castAndCrewActor = data?.Actors?.split(',').map((item) => {
      return {
        Name: item,
        CastType: castType.find((x) => x.Description === "Actors")
      }
    });
    let castAndCrewDirector = data?.Director?.split(',').map((item) => {
      return {
        Name: item,
        CastType: castType.find((x) => x.Description === "Director")
      }
    });
    let castAndCrewWriter = data?.Writer?.split(',').map((item) => {
      return {
        Name: item,
        CastType: castType.find((x) => x.Description === "Writer")
      }
    });

    let castAndCrew = [...castAndCrewActor, ...castAndCrewDirector, ...castAndCrewWriter];
    let uniqueCastAndCrew = castAndCrew.filter(item => {
      return !props.data.some(existingItem => existingItem.Name == item.Name && existingItem.CastType.Description == item.CastType.Description);
    });
    props.setCastAndCrew([...props.data, ...uniqueCastAndCrew]);
    setShowIMdbForm(false);

  }

  return (<>
    <div className="row">
      {!toggle && <div className="col-12">
        <AddRoundButton onClick={() => setToggle(true)} />
        <RoundButton icon="file-text-o" title={lang.update_imdb_label} onClick={() => setShowIMdbForm(true)} />
        <Grid data={props.data} style={{ height: "30vh" }}>
          <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="CastType.Description" title={lang.cast_column} editable={false} />
          <GridColumn field="Name" title={lang.name_column} editable={false} />
        </Grid>
      </div>}
      {toggle && <div className="col-6" style={{ paddingTop: "30px" }}>
        <Grid data={props.data} style={{ height: "30vh" }}>
          <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="CastType.Description" title={lang.cast_column} editable={false} />
          <GridColumn field="Name" title={lang.name_column} editable={false} />
        </Grid>
      </div>}
      {toggle && <div className="col-6">
        {!editMode && <AddRoundButton onClick={() => addCastAndCrew()} />}
        {editMode && <SaveRoundButton onClick={() => { editCastAndCrew() }} />}
        <CancelRoundButton onClick={clearData} />
        <div className="row ml-2" >
          <div className="col-12 mt-2" style={{ border: "groove 1px" }}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">{lang.name_label} *</label>
                  <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={(e) => onChange(e)} required />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.cast_type_label} *</label><br />
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={castType}
                    name="CastType"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.CastType}
                    onChange={onChange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {showIMDbForm && <IMDBEditForm mediaCategory={props?.mediaData?.MediaCategory?.SID} IMDbID={props?.mediaData?.IMDbID ?? ""} imdbDataList={handleIMDbdataList} cancelEdit={() => setShowIMdbForm(false)} />}
  </>)
}

export default MediaEpisodeCastAndCrewEditForm
