import {
    Gantt,
    GanttWeekView,
    GanttMonthView,
    GanttDayView,
} from '@progress/kendo-react-gantt'
import { useEffect, useState } from 'react';
import * as API from '../../framework/API/api'
import { ENTITYNAME, PLATFORM } from '../../framework/constant/constant';
import { ImageCell } from '../../framework/forms/helpercomponents/CustomGridCells/ImageCell';
import moment from 'moment';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import MyStatusCell from '../../components/MyStatusCell';


const TimelineView = (props) => {

    const currentYear = (new Date()).getFullYear();
    const currentMonth = (new Date()).getMonth();
    const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    var showTitle = props.showTitle ?? true;
    var myTitle = props.title ?? "";
    const lang = useTranslation();

    const [dataItem, setDataItem] = useState({
        StartDate: moment(new Date(currentYear, currentMonth, 1)).format("YYYY-MM-DD"),
        EndDate: moment(new Date(currentYear, currentMonth, daysInCurrentMonth)).format("YYYY-MM-DD"),
        Platform: { SID: 0, Description: "All" }
    });
    const [data, setData] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        loadcombo();
        loadData(searchText);
    }, []);

    const loadcombo = async () => {
        var platformRes = (Object.keys(PLATFORM));
        setPlatform({ SID: 0, Description: "All" }, platformRes.data);
    }

    const loadData = async (searchValue) => {

        var filterPayload = {
            "Platform": dataItem.Platform,
            "StartDate": new Date(dataItem.StartDate).getTime(),
            "EndDate": new Date(dataItem.EndDate).getTime(),
            "SearchText": searchValue
        }

        let res = await API.getNonLinearPlanning(filterPayload);
        // console.log(res)
        var jobRes = await API.getData(ENTITYNAME.OttTranscodeStatus);
        // console.log(jobRes)

        if (res.success) {
            // console.log(res.data);

            let prepareData = res.data.map((item) => {
                var jobs = jobRes.data.filter((x => x.AssetId == item.mediaEpisode.AssetId));

                return {
                    id: item._id,
                    start: new Date(moment(item.PublishStartDate).utc().format("YYYY-MM-DD HH:mm:ss")),
                    end: new Date(moment(item.PublishEndDate).utc().format("YYYY-MM-DD HH:mm:ss")),
                    title: item.mediaEpisode?.Title,
                    jobStatus: jobs.length > 0 ? jobs[0].CurrentStatus : 'NA',
                    imgUrl: item.mediaEpisode?.ImageUrl,
                }
            });
            setData(prepareData);
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const handleServerSideSearch = (e) => {
        setSearchText(() => e.target.value);

        if (e.target.value == '') {
            loadData('');
        }
    };

    const taskModelFields = {
        id: 'id',
        start: 'start',
        end: 'end',
        title: 'title',
        jobStatus: 'jobStatus'
    };

    const Mystatuscell = (props) => (
        <><MyStatusCell {...props} title={props.dataItem.jobStatus} titleColor="#333" tileBackColor={props.dataItem.jobStatus == 'Complete' ? "#72eda9" : ''} /></>
    )

    const columns = [
        { field: 'imgUrl', title: '.', width: 67, cell: (props) => <ImageCell {...props} /> },
        { field: 'title', title: `${lang.title_label}`, width: 150 },
        { field: 'start', title: `${lang.start_date_label}`, width: 100, format: "{0:MM/dd/yyyy}" },
        { field: 'end', title: `${lang.end_Date_label}`, width: 100, format: "{0:MM/dd/yyyy}" },
        { field: 'jobStatus', title: `${lang.job_status_label}`, width: 125, cell: (props) => <Mystatuscell {...props} /> },
    ];

    const onSearchClick = () => {

        if (searchText != '') {
            loadData(searchText);
        } else {
            loadData('');
        }

    }

    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onSearchClick();
        }
    }

    const showfilter = () => {
        loadData(searchText);
    }

    return (
        <>
            {showTitle && (
                <>
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{myTitle}</span>
                    </h1>
                </>
            )}
            <div className='row'>
                <div className='col-2'>
                    <label htmlFor="TabView">{lang.platform_label}</label><br />
                    <DropDownList
                        data={PLATFORM}
                        name="Platform"
                        textField="Description"
                        dataItemKey="SID"
                        value={dataItem.Platform}
                        onChange={onChange}
                    />
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="">{lang.start_date_label}</label>
                        <input type="date" className="form-control form-control-sm" name="StartDate" value={dataItem.StartDate} onChange={onChange} />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="">{lang.end_Date_label}</label>
                        <input type="date" className="form-control form-control-sm" name="EndDate" value={dataItem.EndDate} onChange={onChange} />
                    </div>
                </div>
                <div className="col-3 mt-4">
                    <div className='row'>
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={showfilter} />
                    </div>
                </div>
                <div className="mt-3 input-group input-group w-300 martb">
                    <input
                        type="text"
                        className="form-control shadow-inset-2 pt-0 pb-0"
                        id="searchInput"
                        aria-label="type 2 or more letters"
                        placeholder={lang.search_button_text}
                        onKeyDown={onSearchEnter}
                        onChange={handleServerSideSearch}
                    />
                    <button id="search" style={{ border: "0px", backgroundColor: "white", height: "40px" }} onClick={onSearchClick}>
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>
                </div>
            </div>
            <Gantt
                style={{ height: "80%" }}
                taskData={data}
                taskModelFields={taskModelFields}
                columns={columns}
            >
                <GanttDayView />
                <GanttWeekView />
                <GanttMonthView />
            </Gantt>
        </>
    );
}


export default TimelineView;