
import { useEffect } from "react";
import { useTranslation } from "../locale/useTranslation";
import BossDialog from "./BossDialog";
import { useState } from "react";



const ImagePreviewPage = (props) => {
  const lang = useTranslation ();
  const [image, setImage] = useState("");

  useEffect(() => {
    if (props.image && props.image.includes("dropbox") && props.image.includes("dl=0")) {
      const updatedImage = props.image.replace("dl=0", "raw=1");
      setImage(updatedImage);
    } else {
      setImage(props.image);
    }
  }, [props.image]);

     return <BossDialog
        title={lang.preview_image_title_label}
        onClose={props.onClose}
        width={"25vw"}
      >
        <div style={{textAlign:"center"}}>
        <img src={image} width={"200px"} object-fit= "fit" />  
        </div>
          
    </BossDialog>
}

export default ImagePreviewPage;
