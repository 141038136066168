import { utility } from "../utility/utilityProvider";
import { BASEURL, MASTERBASEURL } from "./baseurl"

const OFFSET = {
    HRS: 6
}

const LOCALSTORAGE_KEY = {
    token: 'token',
    userData: 'userData',
    publishData: 'publishData',
    planningPublishData: 'planningPublishData',
    user_id: 'user_id',
    isMenuMified: 'isMenuMified',
    schedulepreference: 'schedulepreference',
    USER_LANGUAGE: 'userLanguage',
    planningpreference: 'planningpreference',
    userfavouritemenu: 'userfavouritemenu',
    applicationVersion: 'app_version',
    applicationConfiguration: 'applicationConfiguration',
    sessiontimeout: 'sessiontimeout',
    COMPANY: 'company',
    ALLMODULES: 'allmodules',
    SELECTED_PLANNING_SLOT_DURATION: "SELECTED_PLANNING_SLOT_DURATION",
    INSTANCE: 'instance',
    COMPANIES: 'companies',
    IS_ENTERPRISE_HAVE_COMPANIES: 'IS_ENTERPRISE_HAVE_COMPANIES',
}

const getBaseUrl = () => {
    return BASEURL;
    // const instanceRes = utility.getValue(LOCALSTORAGE_KEY.INSTANCE);
    // return instanceRes && instanceRes?.BaseUrl ? instanceRes?.BaseUrl : BASEURL;
}

const get_APIURL = (entityName) => {
    if (entityName == ENTITYNAME.Users || entityName == ENTITYNAME.Company || entityName == ENTITYNAME.Instance) {
        return APIMASTERURL.BASEURL;
    }

    return APIURL.BASEURL;
}

const APIURL = {
    BASEURL: getBaseUrl() + "/v2/" + "data/"
}

const APIMASTERURL = {
    BASEURL: MASTERBASEURL + "/v2/" + "data/"
}

const FrameEquivalentOfMilliSecond = 40;
const AllowedFramesPerSecond = 24;

const LOGINURL = {
    BASEURL: MASTERBASEURL + "/v2/" + "login/"
}

const APPCONFIGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "login/"
}



const PLANNINGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "planning/"
}

const SCHEDULINGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "scheduling/"
}
const BOOKINGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "booking/"
}
const USERURL = {
    BASEURL: getBaseUrl() + "/v2/" + "user/"
}

const MEDIAURL = {
    BASEURL: getBaseUrl() + "/v2/" + "media/"
}

const FILEURL = {
    BASEURL: getBaseUrl() + "/v2/" + "file/"
}

const FILEBUCKETURL = {
    BASEURL: getBaseUrl() + "/v2/" + "filebucket/"
}

const CMSURL = {
    BASEURL: getBaseUrl() + "/v2/" + "data/"
}

const PAGEURL = {
    BASEURL: getBaseUrl() + "/v2/" + "data/"
}

const RECONSILEURL = {
    BASEURL: getBaseUrl() + "/v2/" + "reconciliation/"
}

const CONTRACTURL = {
    BASEURL: getBaseUrl() + "/v2/" + "contract/"
}

const DATAMIGRATIONURL = {
    BASEURL: getBaseUrl() + "/v2/" + "datamigration/"
}


const LOGGERURL = {
    BASEURL: getBaseUrl() + "/v2/logger/"
}

const BOOKINGSUMMARYURL = {
    BASEURL: getBaseUrl() + "/v2/" + "bookingdashboard/"
}


const AMAZONMMCMEFXML = {
    BASEURL: getBaseUrl() + "/v2/" + "amazonmmcmec/"
}

const MONETIZATION = {
    BASEURL: getBaseUrl() + "/v2/" + "monetization/"
}

const WORKORDERURL = {
    BASEURL: getBaseUrl() + "/v2/" + "workorder/"
}

const LIVEEVENTURL = {
    BASEURL: getBaseUrl() + "/v2/" + "liveevent/"
}

const MANAGECONTENTURL = {
    BASEURL: getBaseUrl() + "/v2/" + "manageContent/"
}

const REPORTSURL = {
    BASEURL: getBaseUrl() + "/v2/" + "reports/"
}

const INGESTIONLOGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "ingestionpipeline/"
}

const OTTURL = {
    BASEURL: getBaseUrl() + "/v2/" + "ott/"
}

const SERVERHEALTHURL = {
    BASEURL: getBaseUrl() + "/v2/" + "serverhealth/"
}

const CONFIGURL = {
    BASEURL: MASTERBASEURL + "/v2/" + "config/"
}

const TRANSLATORURL = {
    BASEURL: getBaseUrl() + "/v2/" + "translator/"
}

const BILLINGURL = {
    BASEURL: getBaseUrl() + "/v2/" + "billing/"
}

const DASHBOARDURL = {
    BASEURL: getBaseUrl() + "/v2/" + "dashboard/"
}

const DELETELOGURL = {
    BASEURL: getBaseUrl() + "/v2/public/deleteLogHistory"
}

const DELETEPROGRAMSCHEDULEDRAFTURL = {
    BASEURL: getBaseUrl() + "/v2/scheduling/deleteProgramScheduleDraft"
}

const IMDBURL = {
    BASEURL: getBaseUrl() + "/v2/" + "imdb/"
}

const THIRDPARTYAPIURL = {
    BASEURL: getBaseUrl() + "/v2/" + "thirdPartyApi/"
}

const DIGITALSIGNURL = {
    BASEURL: getBaseUrl() + "/v2/" + "digitalsign/"
}

const RAZORPAYURL = {
    BASEURL: getBaseUrl() + "/v2/" + "razorpay/"
}

const SECONDARYEVENTURL = {
    BASEURL: getBaseUrl() + "/v2/" + "secondaryevent/"
}

const GetHeader = (method = 'GET', isFormData = false) => {
    return {
        method: method,
        accept: 'application/json',
        headers: getHeaders(isFormData),
    }
}

const getHeaders = (isFormData) => {
    let header = {
        'x-access-token': `Bearer ${utility.getAccessToken()}`,
        'x-db-name': utility.getDbName(),
        'x-company-id': utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id,
        'x-companies': utility.getValue(LOCALSTORAGE_KEY.COMPANIES)?.map(x => x._id).join(",")
    };
    if (!isFormData) {
        header['Content-Type'] = 'application/json';
    }
    return header;
}

const MILLISECONDSINADAY = 86400000;

const PRODUCTS = {
    All: 0,
    Traffic: 1,
    AdSales: 2,
    CMS: 3,
    SMS: 4,
    Monetize: 5,
    Couponing: 6,
    DigitalSignage: 7,
    Message: 8,
}

const COLUMNSTYPE = {
    input: 'input',
    select: 'select',
    checkbox: 'checkbox',
    date: 'date',
    datetime: 'datetime',
    textarea: 'textarea',
    email: 'email',
    hidden: 'hidden',
    time: 'time',
    number: 'number',
    label: 'label',
    image: 'image',
    toggle: 'toggle',
    array: 'array',
    href: 'href',
    enum: 'enum',
    platform: 'platform',
    status: 'status',
    localdatetime: 'localdatetime',
    icon: 'icon',
    arrayenum: 'arrayenum',
    hyperlink: 'hyperlink',
    color: 'color',
    archive: 'archive',
    MyScheduleTypeCell: 'MyScheduleTypeCell',
    mySecondaryEventCountCell: 'mySecondaryEventCountCell',
    myTXReadyCell: 'myTXReadyCell',
    myAnnouncementCountCell: 'myAnnouncementCountCell',
    segmentCountCell: 'segmentCountCell',
    mediadealrights: 'mediadealrights',
    provider: 'provider',
    dropdown: 'dropdown',
    multiselectdropdown: 'multiselectdropdown',
    day: 'day',
    company: 'company'
}

const RAILTYPE = {
    Logo: 1,
    HeroBanner: 2,
    Rail: 3,
    TEXT: 4,
    VerticalRail: 5,
    SingleCard: 6,
    GridRailTwoColumn: 7,
    GridRailThreeColumn: 8,
    GridRailFourColumn: 9,
    FullPageGrid2: 10,
    FullPageGrid3: 11,
    FullPageGrid4: 12,
    TextRail: 13,
    CircleRail: 14,
    PotraitRail: 15,
    LandscapeRail: 16,
    SquareRail: 17,
    FastChannel: 18,
    Document: 19,
    TopCounter: 20,
    HeroBannerPotrait: 21,
    IsFullPage: 22,
}

const PLATFORMTYPE = [

    { SID: 1, Description: "Linear" },
    { SID: 2, Description: "Non-Linear" }
];

const PLATFORM = [

    { SID: 0, Description: "All" },
    { SID: 1, Description: "Mobile" },
    { SID: 2, Description: "Android TV" },
    { SID: 3, Description: "Web" },
    { SID: 4, Description: "Tab" }

];

const REPLACEMENTMODE = {
    ALL: 1,
    Selected: 2,
    Alternate: 3,
    Random: 4
}


const ROLETYPE = {
    SuperAdmin: 0,
    Admin: 1,
    User: 2,
}


const OTTROUTETYPE = {
    Player: 1,
    Page: 2,
    NoRoute: 3,
    PiPage: 4,
    Deeplink: 5,
    WebView: 6,
}

const ASSETTYPE = {
    'Live': 1,
    'Logo': 2,
    'Poster': 3,
    'VOD': 4,
    'Media Asset': 5,
    'Text': 6,
    'Ott Campaign': 7,
    'Web': 8,
}

const OTT_POSTER_TYPE = {
    Free: 0,
    Generic: 1,
    Portrait: 2,
    Landscape: 3,
    Logo: 4,
    HeroBanner: 5,
    Circle: 6,
    Thumbnail: 7,
    New: 8,
    Poster: 9,
    Square: 10
}


const PUBLISHING_SOURCE = {
    Contract: "contract",
    Season: "season",
    Series: "series",
    MediaEpisode: "mediaepisode",
}

const MEDIACATEGORIES = {
    Program: 1,
    Episode: 2,
    Movie: 3,
    Interstitial: 4,
    Commercial: 5,
    Filler: 6,
    Music: 7,
    Series: 8,
    Season: 9,
    Trailer: 10
}


const MEDIACATEGORYTYPES = {
    Commercial: 1,
    Promo: 2,
    Ident: 3,
    BreakBumper: 4,
    Movie: 5,
    CrossPromo: 6,
    Fillers: 7,
    Sponsorship: 8,
    Vignettes: 9,
    ProgramSeries: 10, //EPISODE
    Music: 11,
    Series: 12,
    Season: 13,
    Program: 14,
    ChannelPromo: 17,
    Trailer: 20,
    Announcement: 21,
    InHouse: 26,
}

const ENTITYNAME = {
    //cms
    OttAssetType: 'ott_assettype',
    OttRailType: 'ott_railtype',
    OttPosterType: 'ott_postertype',
    OttAsset: 'ott_assets',
    OttPosters: 'ott_posters',
    OttTheme: 'ott_theme',
    OttAssetRailView: 'ott_railassetview',
    OttVideoType: 'ott_videotype',
    RailPageView: 'ott_pagerailview',
    Platform: 'ott_platform',
    Rails: 'ott_rails',
    Page: 'ott_pages',
    RailOttAssetLink: 'RailOttAssetLink',
    OttMenu: 'ottmenu',
    OttSetting: 'ottsetting',
    OttIcons: 'ott_icons',
    MarketingScreens: 'marketing_screens',
    PromotionalScreens: 'promotional_screens',
    OttProvider: 'ottprovider',
    Document: 'document',
    ScreenSaver: 'screensaver',
    WebAssets: 'webassets',
    PiPageSetting: 'pipagesettings',
    Theme: 'theme',
    OttFilterTemplate: 'ott_filtertemplate',
    //media
    MediaByCategory: 'mediabycategory',
    MediaLibraryView: 'MediaLibraryView',
    MediaEpisode: 'mediaepisode',
    MediaCategory: 'mediacategory',
    MediaCategoryType: 'mediacategorytype',
    MediaEpisodeSegment: 'mediaepisodesegment',
    MediaEpisodeSynopsis: 'mediaepisodesynopsis',
    MediaEpisodeTechnicalDetail: 'MediaEpisodeTechnicalDetail',
    MediaEpisodeMetaData: 'mediaepisodemetadata',
    MediaEpisodePoster: 'mediaepisodeposter',
    MediaEpisodeSegmentDetail: 'MediaEpisodeSegmentDetail',
    MediaTechnicalPrototype: 'mediatechnicalprototype',
    MediaEpisodeVideo: 'mediaepisodevideo',
    Interstitial: 'Interstitial',
    Segment: 'Segment',
    ProgramScheduleMediaEpisodeView: 'ProgramScheduleMediaEpisodeView',
    ProgramSchedule: 'programschedule',
    ProgramScheduleDraft: 'programscheduledraft',
    SegmentDetail: 'SegmentDetail',
    MediaDealRights: 'mediadealrights',
    MediaEpisodeGroup: 'mediaepisodegroup',
    LanguageGroup: 'languagegroup',
    MediaEpisodeLocalization: 'mediaepisodelocalization',
    Episode: 'episode',
    MusicCategory: 'musiccategory',
    FileAttachments: 'fileattachments',
    //masters
    Module: 'module',
    Language: 'language',
    CmsDashboardView: 'CmsDashboardView',
    Department: 'Department',
    Users: 'users',
    Role: 'Role',
    Channel: 'channel',
    ContentCategory: 'contentcategory',
    Content: 'content',
    MaterialContent: 'materialcontent',
    Genre: 'genre',
    PGRating: 'pgrating',
    CensorRating: 'censorrating',
    SegmentType: 'segmenttype',
    DeliveryMethod: 'deliverymethod',
    VideoType: 'videotype',
    VideoCodec: 'videocodec',
    VideoAspectRatio: 'videoaspectratio',
    VersionType: 'versiontype',
    CastType: 'casttype',
    Series: 'series',
    Season: 'season',
    PlatformType: 'platformtype',
    Region: 'region',
    Brand: 'brand',
    Product: 'product',
    CampaignType: 'campaigntype',
    DealType: 'dealtype',
    Currency: 'currency',
    Tax: 'tax',
    PlayTime: 'playtime',
    TimeZone: 'timezone',
    OrderType: 'ordertype',
    Position: 'position',
    Location: 'location',
    PartnerIntegrationTemplate: 'partnerintegrationtemplate',
    OttTranscodeStatus: 'ottTranscodeStatus',
    Prototype: 'prototype',
    PlanningFilterTemplate: 'planningfiltertemplate',
    SchedulingFilterTemplate: 'schedulingFilterTemplate',
    Licensor: 'licensor',
    Automation: 'automation',
    RepeatPattern: 'repeatpattern',
    InvoiceContent: 'invoicecontent',
    CheckList: 'checkList',
    TXMode: 'txmode',
    Variation: 'variation',
    BreakFormat: 'breakformat',
    PaymentProvider: 'paymentprovider',
    OttPeriodType: 'ottperiodtype',
    PaymentType: 'paymenttype',
    ScheduleConstraints: 'scheduleconstraints',
    Constraints: 'constraints',
    PlanningDistributionFormat: 'planningdistributionformat',
    PlanningDistributionTemplate: 'planningdistributiontemplate',
    TechnicalDetailsPrototype: 'mediatechnicalprototype',
    AudioCodec: 'audiocodec',
    FrameRate: 'framerate',
    ProtoTypeContent: 'prototypecontent',
    BreakConfiguration: 'breakconfiguration',
    PlanningSlot: 'planningslot',
    SponsorType: 'sponsortype',
    FontFamily: 'fontfamily',
    Variance: 'variance',
    Pincodes: 'pincodes',
    States: 'states',
    Countries: 'countries',
    TXStatus: 'txstatus',
    Resolution: 'resolution',
    MediaEpisodeMultiEdit: 'mediaepisodemultiedit',
    FormatSchedule: 'formatschedule',
    CountryCode: 'countrycode',
    DayPart: 'daypart',
    Instance: 'instance',
    //Monetaisation
    OttPeriod: 'ottperiod',
    PaymentPlan: 'paymentplan',
    OttProduct: 'ottproduct',
    Subscriber: 'subscriber',
    OttCampaign: 'ottcampaign',
    Coupon: 'coupon',
    OttSegment: 'ottsegment',
    PaymentPlanFeature: 'paymentplanfeature',
    SubscriberLoginHistory: 'subscriberloginhistory',
    SubscriptionHistory: 'subscriptionhistory',
    AnalyticsProviders: 'analytics_providers',
    AnalyticsEvents: 'analytics_events',
    //Sales
    Agency: 'agency',
    Client: 'client',
    Campaign: 'campaign',
    Booking: 'booking',
    SalesTeam: 'salesteam',
    ClientInfo: 'clientinfo',
    SalesOptimizationTemplate: 'salesoptimizationtemplate',
    FinancialYear: "financialyear",
    SalesTarget: 'salestarget',
    //UserPreference
    UserPreference: 'userPreferenceColumn',
    ProtoTypeSource: 'prototypeSource',
    BankAccount: 'bankaccount',
    SecondaryEventStyleSheet: 'secondaryeventstylesheet',
    SecondaryEventDefaultPrototype: 'secondaryeventdefaultprototype',
    Format: 'format',
    FormFields: 'form_fields',
    CheckListPreference: 'checklistpreference',
    //Contract
    Contract: 'contract',
    ExportTemplate: 'exporttemplate',
    SecondaryEventDynamicRule: 'secondaryeventdynamicrule',
    //others
    BREAK_CHILDREN: 'BreakChildren',
    TimeRange: 'timerange',
    PublishStatus: 'publishStatus',
    GeneratedBills: 'generatedbills',
    Company: 'Company',
    ScheduleEditStatus: 'scheduleeditstatus',
    UserDefaultDealRights: "userdefaultdealrights",
    //schedule
    SCHEDULE_MAIN_GRID: 'schedulemaingrid',
    SCHEDULE_BOOKED_COMMERCIAL_GRID: 'schedulebooking',
    //cabellabs
    CABLE_LABS: 'cablelabs',
    //User Activity Logs
    UserActivityLogs: 'useractivitylogs',
    PLAYLIST_LOCK_STATUS: 'playlistlockstatus',
    //WORK ORDER
    Workflow: 'workflow',
    WorkOrder: 'workorder',
    UserFavouriteMenu: 'userfavouritemenu',
    Version: 'version',
    VODType: 'vodtype',
    //Live Event
    Tournament: 'tournament',
    TournamentType: 'tournamenttype',
    MediaEpisodeDetailLiveEvent: 'mediaepisodedetailliveevent',
    LiveEventRound: 'liveeventround',
    LiveEventType: 'liveeventtype',
    ReconsileSetting: 'reconsilesetting',
    MediaMamAssets: 'mediamamassets',
    PlanningView: "planningView",
    RateCard: 'ratecard',
    AmazonDeliveryGroup: 'amazondeliverygroup',
    OttPackageDeliverySchedule: 'ottpackagedeliveryschedule',
    ScheduleAutoFillInterstitial: 'scheduleautofillinterstitial',
    TournamentGroup: 'tournamentGroup',
    //Faq
    FaqCategory: 'faqcategory',
    Faq: 'faq',
    TutorialVideos: 'tutorialvideos',
    //applicationconfiguration
    ApplicationConfiguration: 'applicationconfiguration',
    AgencyType: 'agencytype',
    // Digital Sign
    DigitalSignScreen: 'digitalsign_screen',
    DigitalSignDisplayShape: 'digitalsign_displayshape',
    DigitalSignOverlay: 'digitalsign_overlay',
    DigitalSignPlaylist: 'digitalsign_playlist',
    DigitalSignUnknownScreen: 'digitalsign_unknown_screen',
    DigitalSignPlaylistItem: 'digitalsign_playlist_item',
    DigitalSignScreenGroup: 'digitalsign_screen_group',
    DigitalSignContent: 'digitalsign_content',
    DigitalSignIndustry: 'digitalsign_industry',
    DigitalSignHowDidYouFound: 'digitalsign_howdidyoufoundus',
    DigitalSignNetworkScreenSize: 'digitalsign_networkscreensize',
    DigitalSignPlalyistAsRun: 'digitalsign_playlistasrun',
    DigitalSignPlan: 'digitalsign_plan',
    DigitalSignCompanyPlans: 'digitalsign_company_plans',
    DigitalSignCompanyPlanHistory: 'digitalsign_company_plan_history',
    DigitalSignTransactionHistory: 'digitalsign_transaction_history',
    DigitalSignContentGroup: 'digitalsign_content_group',
    MediaTemplate: 'mediatemplate',
    AvodConfiguration: 'avod_configuration',

    //JOB SCHEDULE
    JobsSchedule: 'js_jobschedule',
    Jobs: 'js_job',
    MessageProvider: 'message_provider',
    MessageTemplate: 'message_template',
    MessageConfiguration: 'message_configuration',
    //IngestionLogs: 'ingestion_logs',
    IngestionLogs: 'ingestionLogs',
    MatchType: "match_type",

}

const TX_MODE = {
    TX: 1,
    LIVE: 2,
    ObVan: 3,
    Repeat: 4
}

const ApplicationConfigurationKeys = {
    ExportISCICode: "ExportISCICode",
    MediaFolder: "MediaFolder",
    VideoFileExtension: "VideoFileExtension",
    isLogEnabled: "isLogEnabled",
    appLicense: "appLicense",
    PlannerExportColumns: "PlannerExportColumns",
    AutoDeleteFooterOffset: "AutoDeleteFooterOffset",
    AutoFillEpisodeStitchDuration: "AutoFillEpisodeStitchDuration",
    EnableScheduleInsertLiveEvent: "EnableScheduleInsertLiveEvent",
    MaxFooterDamageOffset: "MaxFooterDamageOffset",
    isSegmentMediaIdEditable: "isSegmentMediaIdEditable",
    epgConvertorBufferTime: "epgConvertorBufferTime"

}

const WORKORDER_STATUS = [
    { ID: 1, Description: 'To Do' },
    { ID: 2, Description: 'In Progress' },
    { ID: 3, Description: 'Done' },
]

const PUBLISH_TO = {
    schedule: 0,
    planning: 1
}


const IMPORTTYPE =
{
    Planning: 1,
    AsRun: 2
}

const LOGIN_WARNING_MESSAGE = 'Wrong username or password';


const SCHEDULETYPE = {
    Segment: -1,
    UnderRun: -2,
    Header: -3,
    OverRun: -4,
    ShortForm: 0
}

const SCHEDULECOLORPREFERENCE = {
    header: { backgroundColor: "#EDDBC0" },
    segment: { backgroundColor: "#00E7FF" },
    children: { backgroundColor: "#D6E4E5" },
    lightgrey: { backgroundColor: "#EEEEEE" },
    footer: { backgroundColor: "#DC3535", color: "#FFFFFF" },
}

const MONTH = {
    0: 'Jan',
    1: "Feb",
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
}

const DAYS = [
    { ID: 0, Name: "Sunday", SubName: "Sun" },
    { ID: 1, Name: "Monday", SubName: "Mon" },
    { ID: 2, Name: "Tuesday", SubName: "Tue" },
    { ID: 3, Name: "Wednesday", SubName: "Wed" },
    { ID: 4, Name: "Thursday", SubName: "Thu" },
    { ID: 5, Name: "Friday", SubName: "Fri" },
    { ID: 6, Name: "Saturday", SubName: "Sat" }
];

const RECONCILESTATUS = {
    None: 0,
    AutoReconciled: 1,
    ForceMatch: 2
}

const BOOKINGUPDATETYPE = {
    SPOTRATE: 0,
    RATE10SEC: 1,
    TIMERANGE: 2,
    PURGE: 3,
    UNPURGE: 4,
    COMMERCIAL: 5,
    RATE10SEC: 6,
    MAKE_EXCEPTION: 7

}

const BOOKINGSTATUS = {
    None: 0,
    Scheduled: 1,
    Reconciled: 2,
    BillGenerated: 3
}

// TO READ FILE BY EXTENSION
const FILETYPEURL =
{
    //xlsx: "readExcelNewLibrary",
    xlsx: "readExcel",
    xls: "readExcel",
    csv: "readCsv"
}

const PROTOTYPEMEDIACATEGORYGROUP_ENUM = {
    All: 0,
    AllSegments: -1,

    // It Should Be Actual SID from Media Category Table
    Program: 1,
    Episode: 2,
    Movie: 3,
    Interstitial: 4,
    Commercial: 5,
    Filler: 6,
    Music: 7,

};

const PROTOTYPEMEDIACATEGORYGROUP = [
    { ID: 0, Description: "All" },
    { ID: -1, Description: "All Segments" },
    { ID: 1, Description: "Program" },
    { ID: 2, Description: "Episode" },
    { ID: 3, Description: "Movie" },
    { ID: 4, Description: "Interstitial" },
    { ID: 5, Description: "Commercial" },
    { ID: 6, Description: "Filler" },
    { ID: 7, Description: "Music" },

];
const PROTOTYPETRIGGERTYPE = [
    { ID: 0, Description: "Duration" },
    { ID: 1, Description: "ParentStart" },
    { ID: 2, Description: "ParentEnd" },
];

const PROTOTYPETRIGGERTYPEENUM = {
    Duration: 0,
    ParentStart: 1,
    ParentEnd: 2,
};

const MEDIA_IMPORT_ARRAY = [
    { Id: 1, Description: 'Series', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Series, MediaCategorySID: MEDIACATEGORIES.Series, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description'], TemplateFormat: 'xlsx' },

    { Id: 2, Description: 'Season', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Season, MediaCategorySID: MEDIACATEGORIES.Season, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'SeriesAssetId', 'SeasonNumber', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 3, Description: 'Program', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Program, MediaCategorySID: MEDIACATEGORIES.Program, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Genre', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 4, Description: 'Movie', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Movie, MediaCategorySID: MEDIACATEGORIES.Movie, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Genre', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 10, Description: 'Episode', MediaCategoryTypeSID: MEDIACATEGORYTYPES.ProgramSeries, MediaCategorySID: MEDIACATEGORIES.Episode, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'EpisodeTitle', 'Duration', 'SeasonAssetId', 'EpisodeNumber'], TemplateFormat: 'xlsx' },

    { Id: 5, Description: 'Interstitial', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Promo, MediaCategorySID: MEDIACATEGORIES.Interstitial, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'TcIn', 'TcOut', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 6, Description: 'Commercial', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Commercial, MediaCategorySID: MEDIACATEGORIES.Commercial, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'Brand', 'Product', 'TcIn', 'TcOut', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 7, Description: 'Filler', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Fillers, MediaCategorySID: MEDIACATEGORIES.Filler, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'TcIn', 'TcOut', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 8, Description: 'Music', MediaCategoryTypeSID: MEDIACATEGORYTYPES.Music, MediaCategorySID: MEDIACATEGORIES.Music, IsContentRequired: true, IsChannelRequired: false, TemplateMandatoryFields: ['AssetId', 'Title', 'Description', 'TcIn', 'TcOut', 'Duration'], TemplateFormat: 'xlsx' },

    { Id: 12, Description: 'Planning', IsContentRequired: false, IsChannelRequired: true, TemplateMandatoryFields: ['HouseNumber', 'Date', 'Time'], TemplateFormat: 'csv' },

    { Id: 13, Description: 'Segmentation', IsContentRequired: false, IsChannelRequired: true, TemplateMandatoryFields: ['HouseNumber', 'Version', 'VersionTypSID', 'Segment', 'IN', 'OUT', 'MediaId'], TemplateFormat: 'xlsx' },

    { Id: 16, Description: 'Playlist', IsContentRequired: false, IsChannelRequired: true, TemplateFormat: 'xlsx' },

    { Id: 17, Description: 'WhatsOn Playlist', IsContentRequired: false, IsChannelRequired: true, TemplateFormat: 'txt' },

    // { Id: 18, Description: 'BossWindowMediaManagment',IsChannelRequired: true,  TemplateFormat: 'xlsx' },
    // { Id: 19, Description: 'BossWindowSegmentation',IsChannelRequired: true,  TemplateFormat: 'xlsx' },
    { Id: 20, Description: 'BossWindowSalesExecutives', TemplateFormat: 'xlsx' },
    { Id: 21, Description: 'BossWindowAgency', TemplateFormat: 'xlsx' },
    { Id: 22, Description: 'BossWindowClient', TemplateFormat: 'xlsx' },
    { Id: 23, Description: 'BossWindowCampaign', TemplateFormat: 'xlsx' },
    { Id: 24, Description: 'BTS Segmentation', IsContentRequired: false, IsChannelRequired: true, TemplateMandatoryFields: ['HouseNumber', 'Version', 'VersionTypSID', 'Segment', 'IN', 'OUT', 'MediaId'], TemplateFormat: 'xlsx' },
]

const MODULETYPES = [
    { SID: 1, Description: "Program", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "program", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Program, MediaCategorySID: MEDIACATEGORIES.Program, IsContentRequired: true, IsChannelRequired: false, IsProviderRequired: true },

    { SID: 2, Description: "Movie", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "movie", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Movie, MediaCategorySID: MEDIACATEGORIES.Movie, IsContentRequired: true, IsProviderRequired: true },

    { SID: 3, Description: "Commercial", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "commercial", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Commercial, MediaCategorySID: MEDIACATEGORIES.Commercial, IsContentRequired: true },

    { SID: 4, Description: "Interstitial", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "interstitial", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Promo, MediaCategorySID: MEDIACATEGORIES.Interstitial, IsContentRequired: true },

    { SID: 5, Description: "Filler", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "filler", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Fillers, MediaCategorySID: MEDIACATEGORIES.Filler, IsContentRequired: true },

    { SID: 6, Description: "AsRun", isChunked: true, SaveEntityName: "programschedulereconcile", EntityName: "programschedulereconcile", IsContentRequired: false }, //PENDING

    { SID: 7, Description: "Planning", isChunked: true, SaveEntityName: ENTITYNAME.ProgramSchedule, EntityName: "planning", IsContentRequired: false, IsChannelRequired: true, IsCreateMedia: true },

    { SID: 8, Description: "Schedule", isChunked: true, isChunked: false, SaveEntityName: ENTITYNAME.ProgramSchedule, EntityName: "scheduling", IsContentRequired: false, IsChannelRequired: true, IsCreateMedia: true, DownloadTemplate: true, showRestrictions: true },

    { SID: 9, Description: "Manage Content", isChunked: true, SaveEntityName: "managecontent", EntityName: "managecontent", IsContentRequired: false }, //PENDING

    { SID: 10, Description: "Series", isChunked: true, SaveEntityName: ENTITYNAME.Series, EntityName: "series", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Series, MediaCategorySID: MEDIACATEGORIES.Series, IsContentRequired: true, IsChannelRequired: false, },

    { SID: 11, Description: "Season", isChunked: true, SaveEntityName: ENTITYNAME.Season, EntityName: "season", MediaCategoryTypeSID: MEDIACATEGORYTYPES.Season, MediaCategorySID: MEDIACATEGORIES.Season, IsContentRequired: true, IsChannelRequired: false, IsProviderRequired: true },

    { SID: 12, Description: "Episode", isChunked: true, SaveEntityName: ENTITYNAME.MediaEpisode, EntityName: "episode", MediaCategoryTypeSID: MEDIACATEGORYTYPES.ProgramSeries, MediaCategorySID: MEDIACATEGORIES.Episode, IsContentRequired: true, IsChannelRequired: false, IsCreateParent: true },

    { SID: 13, Description: "Ott Asset", isChunked: true, SaveEntityName: ENTITYNAME.OttAsset, EntityName: "assetIngestion", IsContentRequired: false, IsChannelRequired: false, IsProviderRequired: true },

    { SID: 14, Description: "Segment", isChunked: false, SaveEntityName: ENTITYNAME.MediaEpisodeSegment, EntityName: "segment", IsContentRequired: false, IsChannelRequired: false },

    { SID: 15, Description: "Planning Grid", isChunked: true, SaveEntityName: ENTITYNAME.ProgramSchedule, EntityName: "planningGridImport", IsContentRequired: false, IsChannelRequired: true, IngestionTemplateType: 2, DownloadTemplate: true },

    { SID: 16, Description: "Agency", isChunked: true, SaveEntityName: ENTITYNAME.Agency, EntityName: ENTITYNAME.Agency, IsContentRequired: false, IsChannelRequired: false, DownloadTemplate: false },

    { SID: 17, Description: "Client", isChunked: true, SaveEntityName: ENTITYNAME.Client, EntityName: ENTITYNAME.Client, IsContentRequired: false, IsChannelRequired: false, DownloadTemplate: false },

    { SID: 18, Description: "OPTA", isChunked: true, SaveEntityName: ENTITYNAME.ProgramSchedule, EntityName: "opta", IsContentRequired: false, IsChannelRequired: true, DownloadTemplate: false },

];

const EXPORT_TEMPLATE_MODULES = [
    { SID: 7, Description: "Planning", SaveEntityName: ENTITYNAME.ProgramSchedule, EntityName: "epg", IsContentRequired: false },
    { SID: 9, Description: "Manage Content", SaveEntityName: "managecontent", EntityName: "managecontent", IsContentRequired: false },
]


const INGESTION_MODULETYPES = {
    Program: 1,
    Movie: 2,
    Commercial: 3,
    Interstitial: 4,
    Filler: 5,
    AsRun: 6,
    Planning: 7,
    Schedule: 8,
    ManageContent: 9,
    Series: 10,
    Season: 11,
    Episode: 12,
    OttAsset: 13,
    Segmentation: 14,
    PlanningGrid: 15,
    Agency: 16,
    Client: 17,
    OPTA: 18
}

const DATE_TIME_FORMATS = [
    { Type: "date", Format: "dd-mm-yyyy" },
    { Type: "date", Format: "dd-mmm-yyyy" },
    { Type: "date", Format: "YYYY-MM-DD HH:mm:ss" },
    { Type: "time", Format: "hh:mm" },
    { Type: "time", Format: "hh:mm:ss" },
    { Type: "time", Format: "hh:mm:ss:ff" },
    { Type: "time", Format: "Seconds" },
    { Type: "time", Format: "Milliseconds" },
    { Type: "time", Format: "Minutes" },
];


const INGESTIONTEMPLATETYPES = {
    API: 1,
    CSV: 2,
    Excel: 3
}


const SEGMENTPOSITIONS_ENUM = {
    All: 0,
    First: 1,
    Mid: 2,
    Last: 3,
    AllExceptFirst: 4,
    AllExceptLast: 5,
};

const BOOKINGPOSITIONS_ENUM = {
    Any: 1,
    First: 2,
    Last: 3,
    Mid: 4,
    Count: 5,
};


const SEGMENTPOSITIONS = [
    { ID: 0, Description: "All" },
    { ID: 1, Description: "First" },
    { ID: 2, Description: "Mid" },
    { ID: 3, Description: "Last" },
    { ID: 4, Description: "All Except First" },
    { ID: 5, Description: "All Except Last" },
];

const OPERATORS = [
    { ID: 0, Description: "Greater than", Operator: '>' },
    { ID: 1, Description: "Less than", Operator: '<' },
    { ID: 2, Description: "Equal to", Operator: '=' },
    { ID: 3, Description: "Less than equal to", Operator: '<=' },
    { ID: 4, Description: "Greater than Equal to", Operator: '>=' }
];

const RESTRICTIONS_OPERATORS = [
    { ID: 0, Description: "Greater than", Operator: '>' },
    { ID: 1, Description: "Less than", Operator: '<' },
    { ID: 2, Description: "Equal to", Operator: '=' },
    { ID: 3, Description: "Less than equal to", Operator: '<=' },
    { ID: 4, Description: "Greater than Equal to", Operator: '>=' },
    { ID: 5, Description: "Like", Operator: 'like' },
];

const ACTIVITY = [
    { ID: 0, Description: "Planning Scheduling" },
    { ID: 1, Description: "Planning" },
];

const REPORT_TYPE = {
    CSV: "CSV", // will change after confirming from Harsh Sir
    XML: "XML",
    XLSX: "XLSX",
    // CitrusEPG: "CitrusEPG",
    PXF: "PXF"
}

const REPORTTYPE = [
    { SID: 1, Description: "CSV" },// will change after confirming from Harsh Sir
    { SID: 2, Description: "XML" },
    { SID: 3, Description: "XLSX" },
    { SID: 4, Description: "PXF" },
    // { SID: 4, Description: "CitrusEPG" },
];

const EPGREPORTTYPE = [
    { SID: 1, Description: "CSV", TemplateReq: true },
    { SID: 2, Description: "XLSX", TemplateReq: true },
    { SID: 3, Description: "SAMSUNG", TemplateReq: false },
    { SID: 4, Description: "TOOBER", TemplateReq: false },
    { SID: 5, Description: "STARHUB", TemplateReq: false },
    // { SID: 4, Description: "CitrusEPG" },
];

const AUTOMATION = {
    AMAGI: 1,
    PLANETCAST: 2,
    EVERTZ: 3,
    PDF: 4,
    AMAGI_AS_RUN: 5,
    WHATSON: 6,
    NEXTGEN: 7,
    PLAYBOXATHENSA: 8,
    JAZLER: 9,
    JAZLERCOMMERCIALBREAK: 10,
    YUP_TV: 11,
    PLAYBOX: 12,
    PEBEL: 13,
    HARRIS: 14,
    KARTHAVYA: 15,
    AIRBOX: 16,
    VECTORBOX: 17,
    ENCOMPASS: 18,
    ENCOMPASS_GRAPHICS: 19,
    EXCEL: 20,
    ENCOMPASS_EXCEL: 21,
    MULTITV: 22,
    INETSAT: 23,
    ENCOMPASS_HP1: 24,
    ENCOMPASSCSV: 25,
    ENCOMPASS_EXCEL_V2: 26,
}

const MODULE = {
    ALL: 0,
    DASHBOARD: 1,
    CONTARCT_MENU: 2,
    CONTRACT: 3,
    MEDIA_MANAGEMENT_MENU: 4,
    MEDIA_EPISODE_SERIES: 5,
    MEDIA_EPISODE_SEASON: 6,
    MEDIA_EPISODE_PROGRAM: 7,
    MEDIA_EPISODE_MOVIE: 8,
    MEDIA_EPISODE_INTERSTITIAL: 9,
    MEDIA_EPISODE_COMMERCIAL: 10,
    MEDIA_EPISODE_FILLER: 11,
    MEDIA_EPISODE_MUSIC: 12,
    MEDIA_EPISODE_BREAK: 13,
    MEDIA_EPISODE_GROUP: 14,
    PLANNING_MENU: 15,
    PLANNING: 16,
    FORMAT: 17,
    SCHEDULING: 19,
    CURATE_MENU: 20,
    RAILS: 21,
    PAGE: 22,
    OTT_ASSETS: 23,
    TV: 24,
    MOBILE: 25,
    INGESTION_MENU: 26,
    OTT_POSTERS: 27,
    INGESTION_TEMPLATE: 28,
    PARTNER_INGESTION: 29,
    SALES_MENU: 30,
    AGENCY: 31,
    CLIENT: 32,
    CAMPAIGN: 33,
    BOOKING: 34,
    BOOKING_TRANSFER: 35,
    UPDATE_BOOKING_LINE_NUMBER: 36,
    SALES_TEAM: 37,
    SALES_EXECUTIVE: 38,
    IMPORT_MENU: 39,
    SCHEDULE_RECONCILIATION: 40,
    IMPORT_PLANNING: 41,
    IMPORT_AS_RUN: 42,
    MANAGE_AS_RUN: 43,
    EXPORT_MENU: 44,
    EXPORT_EPG_REPORT: 45,
    EXPORT_EPG_TEMPLATE: 46,
    SECONDARY_EVENT_MENU: 47,
    SECONDARY_EVENT_PROTOTYPE: 48,
    SECONDARY_EVENT_STYLE_SHEET: 49,
    DEFAULT_SECONDARY_EVENT_PROTOTYPR: 50,
    PROTO_TYPE_SOURCE: 51,
    SECONDARY_EVENT_DYNAMIC_RULE: 52,
    BILLING_MENU: 53,
    BILLING: 54,
    GENERATE_BILL: 55,
    MASTER_MENU: 56,
    LANGAUGE: 57,
    CONTENT: 58,
    ASSETS_TYPE: 59,
    RAILS_TYPE: 60,
    POSTERS_TYPE: 61,
    USERS: 62,
    DEPARTMENT: 63,
    ROLE: 64,
    CONTENT_CATEGORY: 65,
    REGION: 66,
    BRAND: 67,
    PRODUCT: 68,
    BANK_ACCOUNT: 69,
    TAX: 70,
    LICENSOR: 71,
    CHANNEL: 72,
    CURRENCY: 73,
    TIME_RANGE: 74,
    REPEAT_PATTERN: 75,
    INVOICE_CONTENT: 76,
    SEGMENT_TYPE: 80,
    DATA_MIGRATION: 77,
    FORM_FIELD: 78,
    COMPANY: 79,
    DASHBOARD_CONTENT: 81,
    DASHBOARD_CMS: 82,
    DASHBOARD_SALES: 83,
    DASHBOARD_BOOKING_STATUS: 84,
    DASHBOARD_BOOKING_SUMMARY: 85,
    PROGRAM_GROUP: 86,
    INTERSTITIAL_GROUP: 87,
    VARIATION: 88,
    BREAKFORMAT: 89,
    SCHEDULE_BOOKED_COMMERCIAL: 90,
    SCHEDULE_PROTOTYPE: 91,
    SCHEDULE_MEDIA: 92,
    MEDIA_BY_CATEGORY: 93,
    TIMELINE: 94,
    PROGRAM_SCHEDULE_CLASH: 95,
    PAYMENT_PROVIDER: 96,
    OTT_PERIOD_TYPE: 97,
    PAYMENT_TYPE: 98,
    OTT_PERIOD: 99,
    MONETIZATION: 100,
    PAYMENT_PLAN: 101,
    OTT_PRODUCT: 102,
    SUBSCRIBER: 103,
    OTT_CAMPAIGN: 104,
    JOBS: 105,
    MAKE_GOOD_SPOT: 106,
    SCHEDULE_CONSTRAINTS: 107,
    COUPON: 108,
    AUDITLOG: 109,
    GENRE: 110,
    MEDIACATEGORY: 111,
    OTT_SEGMENT: 112,
    PGRATING: 113,
    PLANNINGDISTRIBUTIONFORMAT: 114,
    MEDIACATEGORYTYPE: 115,
    MANAGE_CONTENT: 116,
    WORK_ORDER: 117,
    WORKFLOW: 118,
    USER_FAVOURITE: 119,
    LIVE_EVENTS: 120,
    VERSION: 121,
    LiveEventRound: 122,
    LiveEventType: 123,
    TournamentType: 124,
    MANAGE_CONTENT_EXPORT_TEMPLATE: 125,
    OPTIMISATION: 126,
    PDF2JSON: 127,
    MEDIA_EPISODE_TRAILER: 128,
    REPORTS_MENU: 129,
    INTERSTITIAL_HISTORY_REPORT: 130,
    CLIENTAGENCYBOOKINGREPORT: 131,
    TECHNICAL_DETAILS_PROTOTYPE: 132,
    AUDIOCODEC: 133,
    VIDEOCODEC: 134,
    DELIVERY_METHOD: 135,
    VIDEO_ASPECT_RATIO: 136,
    VIDEOTYPE: 137,
    BOOKING_SCHEDULE_STATUS: 138,
    SCHEDULING_MENU: 139,
    MISSINGMEDIAREPORT: 140,
    MEDIA_EPISODE_MAMASSETS: 141,
    CASTTYPE: 142,
    RATECARD: 143,
    MATERIALCONTENT: 144,
    OTTMENU: 145,
    CAMPAIGNTYPE: 146,
    DEALTYPE: 147,
    LOCATION: 148,
    OTTSETTING: 149,
    PRELOGPOSTLOGREPORT: 150,
    CREDITLINEREPORT: 151,
    SERVER_HEALTH: 152,
    PURGESPOTREPORT: 153,
    AIRTIMECONSUMPTIONREPORT: 154,
    PROTO_TYPE_CONTENT: 155,
    AMAZONDELIVERYGROUP: 156,
    SERVICE_MENU: 157,
    OTT_PACKAGE_DELIVERY_SCHEDULE: 158,
    SCHEDULE_AUTO_FILL_INTERSTITIAL: 159,
    MEDIA_EPISODE_DUMMY: 165,
    MAM_ASSET_REPORT: 166,
    PENDING_APPROVAL: 167,
    SHOW_SPOTS: 168,
    BREAKCONFIGURATION: 169,
    BILLINGSUMMARYREPORT: 170,
    PLAYLISTLOCKSTATUS: 171,
    ANNOUNCEMENT_RECONCILE: 172,
    EPGMENU: 173,
    EPGCHANNEL: 174,
    FAQCATEGORY: 175,
    FAQ: 176,
    TOP_NEWS: 177,
    TUTORIAL_VIDEOS: 178,
    LOGIN: 179,
    MEDIA_MASTER_REPORT: 180,
    MARKETINGSCREENS: 181,
    PROMOTIONALSCREENS: 182,
    OTT_PROVIDER: 183,
    MODULE_SUB_MENU: 184,
    FINANCIAL_YEAR: 185,
    SALES_TARGET: 186,
    TRAFFIC_SUB_MENU: 187,
    AD_SALES_SUB_MENU: 188,
    CMS_SUB_MENU: 189,
    MONETIZE_SUB_MENU: 190,
    DOCUMENT: 191,
    SCREEN_SAVER: 192,
    PLANNING_SLOT: 193,
    EPISODE: 194,
    WEB_ASSETS: 195,
    SPONSOR_TYPE: 196,
    PI_PAGE_SETTING: 197,
    THEME: 198,
    AGENCY_TYPE: 199,
    PLATFORM: 200,
    APPLICATION_CONFIGURATION: 201,
    JOBS_SCHEDULE: 202,
    VARIANCE: 203,
    COUNTRIES: 204,
    PINCODS: 205,
    STATES: 206,
    TIMEZONE: 207,
    MUSIC_CATEGORY: 208,
    PROGRAM_TIME_CONSUMPTION_REPORT: 209,
    MUSIC_REPORT: 210,
    TX_MODE: 211,
    DIGITAL_SIGN_MENU: 212,
    RESOLUTION: 214,
    TX_STATUS: 219,
    PLAYLIST: 220,
    DIGITAL_SIGN_OVERLAY: 221,
    FORMAT_SCHEDULE: 223,
    DIGITAL_SIGN_SCREEN: 224,
    DIGITAL_SIGN_CONTENT: 225,
    DIGITAL_SIGN_DASHBOARD: 226,
    DIGITAL_SIGN_SCREEN_GROUP: 227,
    INSTANCE: 228,
    DAYPART: 229,
    DIGITAL_SIGN_COMPANY_PLANS: 230,
    DIGITAL_SIGN_ADMIN_DASHBOARD: 231,
    DIGITAL_SIGN_PLANS: 232,
    DIGITAL_SIGN_CONTENT_GROUP: 233,
    INGESTION_REPORT: 234,
    DIGITAL_SIGN_COMPANY: 235,
    TOURNAMENTGROUP: 236,
    EPG_CONVERTOR: 237,
    Media_Template: 238,
    AVOD_CONFIGURATION: 239,
    MESSAGE_SUB_MENU: 240,
    MESSAGE_PROVIDER: 241,
    MESSAGE_TEMPLATE: 242,
    MESSAGE_CONFIGURATION: 243,
    INGESTION_LOGS: 244,
    CENSORRATING: 245,
    MATCHTYPE: 246,
    ANALYTICS_PROVIDER: 247,
    ANALYTICS_EVENTS: 248,
    MEDIA_MANAGEMENT_REPORT: 249,
}

const LANGUAGE = {
    en_us: 'en_us',
    hn: 'hn',
    Arabic: 'ar_AE',
}

const PLANNINGTYPE = {
    DealDateNotStarted: 0,
    DealDateExpired: 1,
    ExhibitionsExceeded: 2,
    RepeatsExceeded: 3,
    Premier: 4,
    Primary: 5,
    Repeat: 6,
    DealBlackout: 7
}

const TEMPLATENAME = {
    BookingImportTemplate: 'BookingImportTemplate.xlsx',
    YearlyBookingImportTemplate: 'BookingImportYearlyTemplate.xlsx',
}

const PLANNINGEVENTSTYLE = [
    {
        Id: 1,
        EventType: "Primary",
        FontSize: 10,
        ForegroundColor: '#000000',
        BackgroundColor: "#0000FF",
    },
    {
        Id: 2,
        EventType: "Repeat",
        FontSize: 10,
        ForegroundColor: '#000000',
        BackgroundColor: "#80bdff"
    },
    {
        Id: 3,
        EventType: "Invalid",
        FontSize: 10,
        ForegroundColor: '#ffffff',
        BackgroundColor: "#ff0000"
    },
    {
        Id: 5,
        EventType: "Live Events",
        FontSize: 10,
        ForegroundColor: '#000000',
        BackgroundColor: "#ffff99"
    },
    {
        Id: 6,
        EventType: "Premier",
        FontSize: 10,
        ForegroundColor: '#000000',
        BackgroundColor: "#669999"
    },
    {
        Id: 7,
        EventType: "SDD",
        FontSize: 10,
        ForegroundColor: '#000000',
        BackgroundColor: "#669999"
    }
];

const PLANNINGEVENTSTYLE_PRIORITY = {
    "Live Events": 1,
    "Repeat": 2,
    "SDD": 3,
    "Primary": 4,
    "Premier": 5,
    "Invalid": 6
}



const CHECKLIST = {
    BackToBackMedia: 1,
    BackToBackProduct: 2,
    BackToBackBrand: 3,
    UnderRunOverRun: 4,
    BackToBackCommercial: 5,
    Previewed: 6,
    CompareSegments: 7,
    SegmentSequence: 8,
    MultipleMediaVersion: 9,
    MissingSegmentAttachment: 10,
    BookedCommercialNotUsed: 11,
    SecondaryEventDuplication: 12,
    SegmentTXReady: 13,
    Segmented: 14,
    CheckTimeRange: 15,
    ClashCheck: 16,
    // DynamicSecondaryEvent  : 17, 
    SalesBreakLimit: 18,
    ValidDate: 19,
    ClientSpotSeperation: 20,
    SameBreakSameCommercial: 21,
    SameBreakSameBrand: 22,
    SecondaryEventScheduled: 23,
    BreakItemUnderRun: 24,
    BreakItemOverRun: 25,
    InvalidSlot: 26,
    EpisodeSequence: 27,
    SegmentAttached: 28,
    ValidMedia: 29,
    BackToBackMediaCategoryType: 30,
    ValidateTimeCodeAndDuration: 31,
    ValidatePreviousDayEndTime: 32,
    BackToBackEpisode: 33,
}

const MEDIATYPE = [
    { SID: 1, Name: "Opening" },
    { SID: 2, Name: "Closing" },
    { SID: 3, Name: "Mid" },
    { SID: 4, Name: "Any" },
    { SID: 5, Name: "Last" },
    { SID: 6, Name: "Fixed" }
]

const MEDIATYPEENUM = {
    OPENING: 1,
    CLOSING: 2,
    MID: 3,
    ANY: 4,
    LAST: 5,
    FIXED: 6
}

const SCHEDULE_DROP_DEST_TYPE = {
    header: 0,
    header_children: 1,
    break: 2,
    break_children: 3
}

const SCHEDULE_CHILDREN_TYPE = {
    header_children: 0,
    break_children: 1
}

const PLAYTYPE = [
    { SID: 1, Name: "Year" },
    { SID: 2, Name: "Month" },
    { SID: 3, Name: "Week" },
    { SID: 4, Name: "Day" },
]

const SALESSTATUS = [
    { SID: 1, Name: "Enable" },
    { SID: 2, Name: "Disable" },
    { SID: 3, Name: "Not for sale" },
]

const SEND_ENUM = [
    { ID: 0, Type: "Email" },
    { ID: 1, Type: "SMS" },
];

const SUBSCRIPTION_STATUS = {
    Active: 1,
    Deactive: 2
}

const SOCKET_EVENTS = {
    newConnection: "newConnection",
    onSocketData: "onSocketData",
    onSocketUsers: "onSocketUsers",
    closeConnection: "closeConnection",
    onSocketNotification: 'onSocketNotification',
    onProgressUpdate: "onProgressUpdate",
    joinCompany: "joinCompany",
    leaveCompany: "leaveCompany",
}

const MODULE_NAME = {
    PLANNING: "Planning",
}

const SOCKET_ACTION = {
    UPDATE_ACTIVE_USER: "UPDATE_ACTIVE_USER",
    ADD_ACTIVE_USER: "ADD_ACTIVE_USER",
    REMOVE_ACTIVE_USER: "REMOVE_ACTIVE_USER",
    GRID_REFRESH: "GRID_REFRESH",
    PLANNING_REFRESH: "PLANNING_REFRESH",
    PLANNING_UPDATE: "PLANNING_UPDATE",
    PLANNING_DELETE: "PLANNING_DELETE",
    PLANNING_NEW_DROP: "PLANNING_NEW_DROP",
    SCHEDULE_REFRESH: "SCHEDULE_REFRESH",
    SCHEDULE_LOCK_UPDATE: "SCHEDULE_LOCK_UPDATE",
    SCHEDULE_ADDITEM: "SCHEDULE_ADDITEM",
    SCHEDULE_DELETEITEM: "SCHEDULE_DELETEITEM",
    SCHEDULE_ON_ADD_BOOKING: "SCHEDULE_ON_ADD_BOOKING",
    SCHEDULE_BOOKING_REFRESH: "SCHEDULE_BOOKING_REFRESH",
    SHOW_TOAST_MESSAGE: "SHOW_TOAST_MESSAGE",
    DELETE_LOOKUP: "DELETE_LOOKUP",
    PROGRESS: "PROGRESS",
    DELETE_LOCAL_STORAGE_KEY: "DELETE_LOCAL_STORAGE_KEY",
    KILL_USER_SESSION: "KILL_USER_SESSION",
    PLATFORM_THEME_REFRESH: "PLATFORM_THEME_REFRESH",
    PLATFORM_GRID_REFRESH: "PLATFORM_GRID_REFRESH",
    LIVE_EVENT_REFRESH: "LIVE_EVENT_REFRESH",
    PLATFORM_PAGE_REFRESH: "PLATFORM_PAGE_REFRESH",
    PLATFORM_MENU_REFRESH: "PLATFORM_MENU_REFRESH",
    PLATFORM_RAIL_REFRESH: "PLATFORM_RAIL_REFRESH",
    DIGI_SCREEN_ON: "DIGI_SCREEN_ON",
    DIGI_SCREEN_OFF: "DIGI_SCREEN_OFF",
    DIGI_SCREEN_REFRESH: "DIGI_SCREEN_REFRESH",
    DIGI_PLAY_PLAYLIST: "DIGI_PLAY_PLAYLIST",
    DIGI_STOP_PLAYLIST: "DIGI_STOP_PLAYLIST",
    DIGI_DELETE_SCREEN: "DIGI_DELETE_SCREEN",
    RESTART_APP: "RESTART_APP",
    LIVE_SCREENSHOT: "LIVE_SCREENSHOT",
}

const MEDIA_TYPE = [
    { ID: 1, Type: "Promo" },
    { ID: 2, Type: "Commercial" },
]

const DISCOUNT_TYPE = [
    { ID: 1, Type: "Amount" },
    { ID: 2, Type: "Percentage" },
]

const EXPORT_TYPE =
{
    EXCEL: "excel",
    EPG: "epg",
    CSV: "csv",
    XML: "xml",
    PDF: "pdf"
}

const LOGEVENT = (
    {
        SAVE_PLANNING: "SAVE PLANNING",
        UPDATE_PLANNING: "UPDATE PLANNING",
        PLANNING_NEW_ADD: "PLANNING NEW ADD",
        PLANNING_UPDATE: "PLANNING UPDATE",
        DELETE_PLANNING: "DELETE PLANNING",
        SAVE_SCHEDULING: "SAVE SCHEDULING",
        UPDATE_SCHEDULING: "UPDATE SCHEDULING",
        DELETE_SCHEDULING: "DELETE SCHEDULING",
        MEDIA_EPISODE_CREATE: "Media Episode Create",
        MEDIA_EPISODE_UPDATE: "Media Episode Update",
        MEDIA_EPISODE_DELETE: " Media Episode Delete",
        MEDIA_EPISODE_ARCHIVE: "Media Episode Archive",
        MEDIA_EPISODE_UNARCHIVE: "Media Episode Un-Archive",
        SCHEDULE_EXPORT: "SCHEDULE EXPORT",
        LOCK_PLANNING: "LOCK PLANNING",
        UNLOCK_PLANNING: "UNLOCK PLANNING",
        LOCK_PLANNING_ITEM: "LOCK PLANNING ITEM",
        UNLOCK_PLANNING_ITEM: "UNLOCK PLANNING ITEM",
        UPDATE_PLANNING_SYNOPSIS: "UPDATE PLANNING SYNOPSIS",
        COPY_SCHEDULE: "COPY SCHEDULE",
        PLANNING_PREFERENCE: "PLANNING PREFERENCE",
        APPLY_SECONDARY_EVENT: "APPLY SECONDARY EVENT",
        APPLY_STYLE_SHEET: "APPLY STYLE SHEET",
        PROGRAM_REPLACEMENT: "PROGRAM REPLACEMENT",
        LOCK_SCHEDULE: "LOCK SCHEDULE",
        UNLOCK_SCHEDULE: "UNLOCK SCHEDULE",
        GLOBAL_REPLACEMENT: "GLOBAL REPLACEMENT",
        DELETE_ALL_SECONDARY_EVENT: "DELETE ALL SECONDARY EVENT",
        DELETE_FOOTER: "DELETE FOOTER",
        CHANGE_VERSION: "CHANGE VERSION",
        CREATE_AUTOMATIC_BREAKS: "CREATE AUTOMATIC BREAKS",
        USER_PREFERENCE: "USER PREFERENCE",
        MAKE_GOOD: "MAKE GOOD",
        PURGE: "PURGE",
        ATTACH_FORMAT_FROM_SCHEDULE: "Attach Format From Schedule",
        UNLOCK_PLANNING_SCHEDULE: "Unlock Planning And Schedule",
        LOG_IN: "LOG IN",
        LOG_OUT: "LOG OUT",
        DEATTACH_CAMPAIGN: "DEATTACH CAMPAIGN",
        INSERT_CAMPAIGN: "INSERT CAMPAIGN",
        UPDATE_CAMPAIGN: "UPDATE CAMPAIGN",
        ARCHIVE: "ARCHIVE",
        UNARCHIVE: "UNARCHIVE",
        INSERT_RAILS: 'INSERT RAILS',
        UNPURGE: "UNPURGE",
        MAKE_EXCEPTION: "MAKE EXCEPTION",
        CHANGE_TIME_RANGE: "CHANGE TIME RANGE",
        CHANGE_RATE10SEC: "CHANGE RATE10SEC",
        CHANGE_SPOT_RATE: "CHANGE SPOT RATE",
        CHANGE_COMMERCIAL: "CHANGE COMMERCIAL",
        UPDATE_ESTIMATE_CODE: "UPDATE ESTIMATE CODE",
        SAVE_BOOKING: "SAVE BOOKING",
        SAVE_YEARLY_BOOKING: "SAVE YEARLY BOOKING",
        BOOKING_TRANSFER: "BOOKING TRANSFER",
        UPDATE_BOOKING_LINE_NUMBER: "UPDATE BOOKING LINE NUMBER",
        DELETE_BOOKING_LINE_NUMBER: "DELETE BOOKING LINE NUMBER",
        MAKE_GOOD_TRANSFER: "MAKE GOOD TRANSFER",
        SPOTS_SCHEDULED_ADD: "SPOTS SCHEDULED ADD",
        SPOTS_SCHEDULED_REMOVE: "SPOTS SCHEDULED REMOVE",
        CREATE_PARENT_SERIES: "CREATE PARENT SERIES",
        UPDATE_PARENT_SERIES: "UPDATE PARENT SERIES",
        CREATE_SERIES: "CREATE SERIES",
        UPDATE_SERIES: "UPDATE SERIES",
        CREATE_PROGRAM: "CREATE PROGRAM",
        UPDATE_PROGRAM: "UPDATE PROGRAM",
        CREATE_MOVIE: "CREATE MOVIE",
        UPDATE_MOVIE: "UPDATE MOVIE",
        CREATE_EPISODE: "CREATE EPISODE ",
        UPDATE_EPISODE: "UPDATE EPISODE",
        DELETE_EPISODE: "DELETE EPISODE",
        CREATE_ASSETS: "CREATE ASSETS",
        UPDATE_ASSETS: "UPDATE ASSETS",
        CREATE_TRAILER: "CREATE TRAILER",
        UPDATE_TRAILER: "UPDATE TRAILER",
        CREATE_INTERSTITIAL: "CREATE INTERSTITIAL",
        UPDATE_INTERSTITIAL: "UPDATE INTERSTITIAL",
        CREATE_COMMERCIAL: "CREATE COMMERCIAL",
        UPDATE_COMMERCIAL: "UPDATE COMMERCIAL",
        CREATE_FILLER: "CREATE FILLER",
        UPDATE_FILLER: "UPDATE FILLER",
        CREATE_MUSIC: "CREATE MUSIC",
        UPDATE_MUSIC: "UPDATE MUSIC",
        CREATE_BREAK: "CREATE BREAK",
        UPDATE_BREAK: "UPDATE BREAK",
        CREATE_DUMMY: "CREATE DUMMY",
        UPDATE_DUMMY: "UPDATE DUMMY",
        CREATE_MEDIA_EPISODE_GROUP: "MEDIA EPISODE GROUP",
        UPDATE_MEDIA_EPISODE_GROUP: "UPDATE EPISODE GROUP",
        CREATE_PROGRAM_GROUP: "PROGRAM GROUP",
        UPDATE_PROGRAM_GROUP: "UPDATE GROUP",
        CREATE_INTERSTITIAL_GROUP: "PROGRAM INTERSTITIAL",
        UPDATE_INTERSTITIAL_GROUP: "UPDATE INTERSTITIAL",
        CREATE_MAM_ASSETS: "CREATE MAM ASSETS",
        UPDATE_MAM_ASSETS: "UPDATE MAM ASSETS",
        CREATE_OTT_MENU: "CREATE OTT MENU",
        UPDATE_OTT_MENU: "UPDATE OTT MENU",
        CREATE_PAGE: "CREATE PAGE",
        UPDATE_PAGE: "UPDATE PAGE",
        CREATE_RAILS: "CREATE RAILS",
        UPDATE_RAILS: "UPDATE RAILS",
        CREATE_OTT_CAMPAIGN: "CREATE OTT CAMPAIGN",
        UPDATE_OTT_CAMPAIGN: "UPDATE OTT CAMPAIGN",
        CREATE_POSTER: "CREATE POSTER",
        UPDATE_POSTER: "UPDATE POSTER",
        CREATE_DOCUMENT: "CREATE DOCUMENT",
        UPDATE_DOCUMENT: "UPDATE DOCUMENT",
        CREATE_TUTORIAL_VIDEO: "CREATE TUTORIAL VIDEO",
        UPDATE_TUTORIAL_VIDEO: "UPDATE TUTORIAL VIDEO",
        UPDATE_MARKETING_SCREEN: "UPDATE MARKETING SCREEN",
        UPDATE_PROMOTIONAL_SCREEN: "UPDATE PROMOTIONAL SCREEN",
        CREATE_WEB_ASSETS: "CREATE WEB ASSETS",
        UPDATE_WEB_ASSETS: "UPDATE WEB ASSETS",
        CREATE_SCREEN_SAVER: "CREATE SCREEN SAVER",
        UPDATE_SCREEN_SAVER: "UPDATE SCREEN SAVER",
        UPDATE_OTT_SETTING: "UPDATE OTT SETTING",
        UPDATE_OTT_SETTING_PLATFROM: "UPDATE OTT SETTING PLATFROM",
        DELETE_OTT_SETTING_PLATFORM: "DELETE OTT SETTING PLATFORM",
        UPDATE_AGENCY: "UPDATE AGENCY",
        INSERT_AGENCY: "INSERT AGENCY",
        UPDATE_CLIENT: "UPDATE CLIENT",
        INSERT_CLIENT: "INSERT CLIENT",
        UPDATE_PIPAGESETTING: "UPDATE PIPAGESETTING",
        INSERT_PIPAGESETTING: "INSERT PIPAGESETTING",
        UPDATE_THEME: "UPDATE THEME",
        INSERT_THEME: "INSERT THEME",
        UPDATE: "UPDATE",
        INSERT: "INSERT",
        CREATE_JOB_SCHEDULE: "CREATE JOB SCHEDULE",
        UPDATE_JOB_SCHEDULE: "UPDATE JOB SCHEDULE",
        CREATE_VARIANCE: "CREATE VARIANCE",
        UPDATE_VARIANCE: "UPDATE VARIANCE",
        RESTORE_SCHEDULE_DRAFT: 'RESTORE SCHEDULE DRAFT',
        PLANNING_RECURSION_ADD: "PLANNING RECURSION ADD",
        PLANNING_RECURSION_PATTERN_ADD: "PLANNING RECURSION PATTERN ADD",
        LIVE_EVENT_MISMATCH: "LIVE EVENT MISMATCH",
        UPDATE_PAYMENT_PROVIDER: "UPDATE PAYMENT PROVIDER",
        INSERT_PAYMENT_PROVIDER: "INSERT PAYMENT PROVIDER",
        UPDATE_PLANNING_TX_STATUS: "UPDATE PLANNING TX STATUS",
        UPDATE_PLANNING_COMMENT: "UPDATE PLANNING COMMENT",
        UPDATE_SCHEDULE_ITEM_DURATION: "UPDATE SCHEDULE ITEM DURATION",
        CREATE_CONTENT: "CREATE CONTENT",
        UPDATE_CONTENT: "UPDATE CONTENT",
        UPDATE_INSTANCE: "UPDATE INSTANCE",
        INSERT_INSTANCE: "INSERT INSTANCE",
        DELETE_HEADER: "DELETE HEADER",
        LOCK_SCHEDULING_ITEM: "LOCK SCHEDULE ITEM",
        UNLOCK_SCHEDULING_ITEM: "UNLOCK SCHEDULE ITEM",
    }
)

const LOGEVENTARRAY = [
    {
        ID: 1, Name: "SAVE PLANNING",
    },
    {
        ID: 2, Name: "UPDATE PLANNING",
    },
    {
        ID: 3, Name: "DELETE PLANNING",
    }
]

const LOGMODULE = [
    {
        ID: 16, Name: "PLANNING",
    },
    {
        ID: 19, Name: "SCHEDULING",
    }
]

const DATETIMEFORMATS = [
    { ID: 1, Type: 'dd/mm/yyyy' },
    { ID: 2, Type: 'YYYY-MM-DD' },
    { ID: 3, Type: 'hh:mm:ss:ff' },
    { ID: 4, Type: 'YYYY-MM-DD hh:mm:ss:ff' },
    { ID: 5, Type: 'HH:mm:ss' },
    { ID: 6, Type: 'HH:mm' },
    { ID: 7, Type: 'DD MMM YY' },
    { ID: 8, Type: 'DD MMM YYYY' },
    { ID: 9, Type: 'DD MMMM YYYY' },
    { ID: 10, Type: 'YYYYMMDD' },
    { ID: 11, Type: 'YYYY.MM.DD' },
    { ID: 12, Type: 'YYYY-MM' },
    { ID: 13, Type: 'YYYY.MM.DD.HH.mm' },
    { ID: 14, Type: 'YYYY-MM-DD-HH-mm' },
    { ID: 15, Type: 'YYYY-MM-DD HH:mm' },
    { ID: 16, Type: 'YYYY-MM-DD h:mm A' },
    { ID: 17, Type: 'YYYY-MM-DD @ h:mm A' },
    { ID: 18, Type: 'YYYY.MM.DD.HH.mm.ss' },
    { ID: 19, Type: 'MMMM Do, YYYY' },
    { ID: 20, Type: 'M/D/YYYY' },
    { ID: 21, Type: 'MM/DD' },
    { ID: 22, Type: 'MM/DD/YY' },
    { ID: 23, Type: 'MM/DD/YYYY' },
    { ID: 24, Type: 'MMM D, YY' },
    { ID: 25, Type: 'MMM D, YYYY' },
    { ID: 26, Type: 'MMM DD, YYYY' },
    { ID: 27, Type: 'MMMM D, YYYY' },
    { ID: 28, Type: 'MMMM DD, YYYY' },
    { ID: 29, Type: 'dd MMM D YY' },
    { ID: 30, Type: 'ddd MMM D YY' },
    { ID: 30, Type: 'ddd MMM DD YYYY' },
]

const RULES = [
    { Enum_ID: '1', Name: 'CannotRepeat', Enabled: true, Value: '11' },
    { Enum_ID: '2', Name: 'ValidDays', Enabled: true, Value: '12' },
]

const SUBTITLETYPE = [
    { ID: 1, Type: 'SRT' },
    { ID: 2, Type: 'VTT' },
]

const LIVE_EVENT_ROUNDS = [
    { SID: 1, Description: 'League' },
    { SID: 2, Description: 'PlayOff' },
    { SID: 3, Description: 'Semi-Final' },
]

const LIVE_EVENT_TYPES = [
    { SID: 1, Description: 'Live' },
    { SID: 2, Description: 'Repeat' },
    { SID: 3, Description: 'Highlights' },
    { SID: 4, Description: 'Pre-Match Studio' },
    { SID: 5, Description: 'Post-Match Studio' },
    { SID: 6, Description: 'Post-Match Ceremony' },
]

const MEDIA_MANAGEMENT_FILTER_BY_OPTIONS = [
    { _id: 0, text: "Publishing Dates", value: "publishingDates" },
    { _id: 1, text: "Content Categories", value: "contentCategories" }, // Done
    { _id: 2, text: "Genre", value: "genre" }, // Done
    { _id: 3, text: "PG Rating", value: "pgRating" }, // Done
    // { _id: 4, text: "Not Published", value: "notPublished" },
    { _id: 5, text: "Linear", value: "linear" }, // Done
    { _id: 6, text: "Tree View", value: "treeView" },
    { _id: 7, text: "Production Year", value: "productionYear" }, // Done
    { _id: 8, text: "Media Category", value: "mediaCategory" },
    { _id: 9, text: "Live", value: "isLive" },
];

const ASSURANCETYPE = [
    { _id: 1, Description: 'Percentage' },
    { _id: 2, Description: 'Absolute' },
]

const QCSTATUS = [
    { _id: 1, Description: 'Pass' },
    { _id: 2, Description: 'Fail' },
    { _id: 3, Description: 'Partial' },
]

const TERM = [
    { _id: 1, Description: 'Advance' },
    { _id: 2, Description: 'Credit' },
]

const RECURRING = [
    { _id: 1, Description: 'Yearly' },
    { _id: 2, Description: 'Monthly' },
    { _id: 3, Description: 'Weekly' },
]

const APPROVESTATUS = [
    { _id: 1, Description: 'Approve' },
    { _id: 2, Description: 'Pending' },
]

const MENUTYPE = {
    TopMenu: 1,
    BottomMenu: 2,
}

const LOGOPOSITION = {
    Left: 1,
    Center: 2,
    Right: 3,
}

const THEMES = {
    Dark: 1,
    Light: 2,
}

const TX_STATUS = {
    Live: 1,
    Repeat: 2,
    Transmission: 3,
    SDD: 4
}

const PLAYLIST_STATUS = {
    Standby: 1,
    PlayingOnce: 2,
    Playing: 3,
    Published: 4,
    UnPublished: 5,
}

const DELIVERYSTATUSENUM =
    [
        { Id: 1, Status: 'Pending' },
        { Id: 2, Status: 'Cancelled' },
        { Id: 3, Status: 'MetadataError' },
        { Id: 4, Status: 'AssetError' },
        { Id: 4, Status: 'Success' },
    ]
const AUTOFILLMEDIATYPE = [
    { ID: 0, Description: "AddOpening" },
    { ID: 1, Description: "AddClosing" },
    { ID: 2, Description: "PromoVariation" },
    { ID: 3, Description: "CommericalVariation" },
    { ID: 4, Description: "NetworkId" },
    { ID: 5, Description: "Fillers" },
];

const PERMALINKTYPE = {
    TITLE: 1,
    ID: 2,
};



const SCHEDULECONSTRAINTS = {
    BackToBackPromo: 1,
    BackToBackCommercial: 2,
    BackToBackBrand: 3,
    BackToBackProduct: 4,
    SameProgramSamePromo: 5,
    PromosLimitPerBreak: 6,
    CommercialLimitPerBreak: 7,
    SamePromoDifferentSize: 8,
    PlaceInBothInternalAndExternalBreaks: 9,
    PlaceInExternalBreaksOnly: 10,
    PlaceInInternalBreaksOnly: 11,
    SameBreakSameCommercial: 12,
    ExcludePreOpeningBreak: 13,
    CommercialLimitPerHour: 14
}

const BOOKINGAPPROVALSTATUS = {
    Pending: 1,
    "Auto Approved": 2,
    Approved: 3,
    Rejected: 4
}

const PAYMENTMODE = {
    Cash: 1,
    Cheque: 2,
}

const PLANNING_DELETE_OPTION = {
    DELETE_SELECTED: 0,
    PUSH_FORWARD: 1,
    PULL_BACKWARD_AND_DELETE_CURRENT: 2,
    DELETE_ALL_FORWARD_IN_STRAND: 3,
    DELETE_FUTURE_EVENT: 4,
}

const ACCESS_LEVEL = {
    Both: 0,
    Login: 1,
    Guest: 2,
}
const FREQUENCY_TYPE = {
    Everytime: 0,
    Limited: 1,
}

const RAIL_STATUS = {
    All: 0,
    Active: 1,
    Expired: 2,
    UpComing: 3
}

const DOCUMENT_TYPE = {
    Pdf: 1,
}

const SLOT_TIMES = [5, 15, 30, 60, 120, 180]
const SLOT_DIVISIONS = [1, 3, 3, 6, 5, 6];

const seriesChartColorGenerator = () => {
    const colors = [
        "#0275d8",
        "#5bc0de",
        "#5cb85c",
        "#f0ad4e",
        "#e67d4a",
        "#d9534f",
        "#ffc107",
        "#ff5722",
        "#e91e63",
        "#3f51b5",
        "#2196f3",
        "#43a047",
        "#7469B6",
        "#9BCF53",
        "#31304D"

    ];
    return colors.sort(() => Math.random() - 0.5);
}

const IMPORT_STATUS = {
    Pending: 0,
    MissingValue: 1,
    Duplicate: 2,
    Complete: 3
}

const PARTNER_INGESTION_STATUS = {
    Pending: 1,
    Failed: 2,
    Completed: 3,
}


const FONT_WEIGHT = {
    w100: 0,
    w200: 1,
    w300: 2,
    w400: 3,
    w500: 4,
    w600: 5,
    w700: 6,
    w800: 7,
    W900: 8,
}

const FORMATVARIATIONTYPE = {
    Fixed: 1,
    Variation: 2,
    FixedFamily: 3
}

const ACCESS_TYPE = {
    PUBLIC: 1,
    PRIVATE: 2,
}

const PROGRAMSCHEDULEDRAFT_SAVETYPE = {
    DATABASE: 1,
    FILE: 2,
    BOTH: 3
}

const IMDBTYPES = {
    Program: 1,
    Movie: 3,
    Series: 8,
}

const SUBSCRIPTION_HISTORY_EVENT = {
    Added: 1,
    Modified: 2,
    Deleted: 3
}

const OVERLAYTYPE = {
    Shape: 1,
    Image: 2,
    Application: 3,
}

const CONTENTTYPE = {
    Image: 1,
    Video: 2
}

const SHAPETYPE = {
    Rectangle: 1,
    Square: 2,
    Circle: 3,
    Oveal: 4,
    Sphere: 5,
    Cube: 6,
    Diamond: 7
}
const POSITION = {
    TopLeft: 1,
    TopCenter: 2,
    TopRight: 3,
    CenterLeft: 4,
    Center: 5,
    CenterRight: 6,
    BottomLeft: 7,
    BottomCenter: 8,
    BottomRight: 9,
}

const APPLICATION = {
    Clock: 1,
    DigitalClock: 2,
    Weather: 3,
}

const SCREEN_STATUS = {
    Active: 1,
    Inactive: 2,
}

const CONTENT_TYPE = {
    Image: 1,
    Video: 2,
    YouTube: 3,
    Stream: 4,
}

const PLAYLIST_SCHEDULE_TYPE = {
    SetDateRange: 1,
    OnPriorityTakeover: 2,
}

const DIGITALSIGN_TRANSACTION_STATUS = {
    Pending: 1,
    Cancelled: 2,
    Confirmed: 3
}

const ORIENTATION = {
    Landscape: 1,
    Portrait: 2,
}
const STORAGE_DESTINATION = {
    Cloudinary: 1,
    S3: 2,
    // SFTP: 3,
    // Local: 4,
    BossCloudMedia: 5
}

const TIMECODEMARKERS = [
    { ID: 1, Description: 'Skip Intro' },
    { ID: 2, Description: 'Skip Credit' },
]

const INGESTION_ACTIVITY = {
    All: 0,
    Created: 1,
    Archive: 2,
    Updated: 3
}

const USER_RESTRICTED_ACTIONS = {

    CANNOTCREATE: { ModuleSID: [MODULE.AGENCY, MODULE.CLIENT, MODULE.CAMPAIGN], SID: 1, DisplayName: "Cannot Create" },

    CANNOTEDIT: { ModuleSID: [MODULE.AGENCY, MODULE.CLIENT, MODULE.CAMPAIGN], SID: 2, DisplayName: "Cannot Edit" },

    CANNOTDELETE: { ModuleSID: [MODULE.AGENCY, MODULE.CLIENT, MODULE.CAMPAIGN], SID: 3, DisplayName: "Cannot Delete" },

    CANNOTDELETEHEADER: { ModuleSID: [MODULE.SCHEDULING], SID: 4, DisplayName: "Cannot Delete Header" },

}

const COMPANYTYPE = {
    COMPAMY: 1,
    ENTERPRISE: 2
}

const orientations = [
    { text: '0°', value: 0 },
    { text: '90°', value: 1 },
    { text: '180°', value: 2 },
    { text: '270°', value: 3 },
];

const JOB_SCHEDULE_TASK = {
    RunAPI: 1,
    RunShell: 2,
    RunBatch: 3,
    Workflow: 4,
    PartnerIngestion: 5
}

const AVOD_FEATURE = {
    LOGGEDIN: 1,
    GUEST: 2,
    SUBSCRIBED: 3,
    TVOD: 4,
    CHURNED: 5
}

const LIVE_EVENT_GENDER = {
    NA: 1,
    Men: 2,
    Women: 3
}

const MESSAGE_TYPE = {
    SMS: 1,
    WHATSAPP: 2,
    EMAIL: 3,
    VOICE: 4
}

const MESSAGE_VARIANT = {
    OTP: 1,
    NOTIFICATION: 2,
    PROMOTION: 3,
}

const SCREEN_RANGE_AND_PRICE = [
    { range: "1-10", price: 1250 },
    { range: "11-20", price: 1099 },
    { range: "21-50", price: 999 },
    { range: "51-100", price: 899 }
];

const MESSAGE_TEMPLATE = ['otp', 'user.Email', 'user.RMN', 'appName'];

const ANALYTICS_EVENTS = {
    APP_LAUNCH: 1,
    LOGIN: 2,
    LOGOUT: 3,
    NAVIGATION: 4,
    SCREEN_VIEWS: 5,
    CONTENT_SEARCH: 6,
    CONTENT_PLAY: 7,
    CONTENT_PAUSE: 8,
    CONTENT_COMPLETE: 9,
    CONTENT_RESUME: 10,
    CONTENT_DOWNLOAD: 11,
    ADD_TO_WATCHLIST: 12,
    REMOVE_FROM_WATCHLIST: 13,
    LANGUAGE_CHANGE: 14,
    UPDATE_PROFILE: 15,
    UPDATE_PASSWORD: 16,
    UPGRADE_PLAN: 17,
    PAYMENT_PAGE: 18,
    PAYMENT_SUCCESS: 19,
    PAYMENT_FAILURE: 20,
    PAYMENT_CANCEL: 21,
    DEVICE_REMOVED: 22,
    APP_CRASHES: 23,
    PLAYBACK_ERROR: 24,
    API_ERROR: 25,
    PROFILE_SWITCH: 26,
    AD_VIEWED: 27,
    AD_CLICKED: 28,
    AD_CLOSED: 29,
    AD_SKIPPED: 30,
    AD_ERROR: 31,
    APP_CLOSED: 32
};

const DEVICE_ACCESS_CONTROL = {
    Login: 1,
    Playback: 2
}

const DIGISIGN_LOGTYPE = {
    ASRUN: 0,
    AUTO_RESTART: 1,
    AUTO_REBOOT: 2,
    ASRUN_ERROR: 3
  }

export {
    ENTITYNAME, LOCALSTORAGE_KEY, APIURL, APIMASTERURL, get_APIURL, LOGINURL, PLANNINGURL, SCHEDULINGURL, MEDIAURL, FILEURL, CMSURL, PAGEURL, RECONSILEURL, CONTRACTURL, SCHEDULETYPE, WORKORDERURL, LIVEEVENTURL, GetHeader, RECONCILESTATUS,
    COLUMNSTYPE, RAILTYPE, ROLETYPE, MEDIACATEGORIES, MEDIACATEGORYTYPES, LOGIN_WARNING_MESSAGE, USERURL, MONTH, FILETYPEURL, PLATFORMTYPE, PUBLISHING_SOURCE,
    PROTOTYPEMEDIACATEGORYGROUP, PROTOTYPETRIGGERTYPE, SEGMENTPOSITIONS, OPERATORS, PROTOTYPEMEDIACATEGORYGROUP_ENUM,
    SEGMENTPOSITIONS_ENUM, SCHEDULECOLORPREFERENCE, ACTIVITY, DAYS, REPORT_TYPE, EPGREPORTTYPE,
    IMPORTTYPE, INGESTIONTEMPLATETYPES, MODULETYPES, AUTOMATION, REPORTTYPE, BOOKINGURL, DATAMIGRATIONURL, LANGUAGE, REPLACEMENTMODE, PLANNINGTYPE, BILLINGURL, PUBLISH_TO,
    BOOKINGSUMMARYURL, FrameEquivalentOfMilliSecond, AllowedFramesPerSecond, TEMPLATENAME, PLANNINGEVENTSTYLE, CHECKLIST, MODULE, OFFSET, MEDIATYPE, SCHEDULE_DROP_DEST_TYPE, SCHEDULE_CHILDREN_TYPE,
    PLATFORM, OTTROUTETYPE, PLAYTYPE, SEND_ENUM, SALESSTATUS, SUBSCRIPTION_STATUS, ASSETTYPE, SOCKET_EVENTS, MODULE_NAME, SOCKET_ACTION, MEDIA_TYPE, MEDIA_IMPORT_ARRAY, DISCOUNT_TYPE, LOGGERURL, EXPORT_TYPE, LOGEVENT, LOGMODULE, LOGEVENTARRAY, DATETIMEFORMATS,
    MONETIZATION, RULES, SUBTITLETYPE, WORKORDER_STATUS, LIVE_EVENT_ROUNDS, LIVE_EVENT_TYPES, MANAGECONTENTURL, MEDIA_MANAGEMENT_FILTER_BY_OPTIONS,
    ASSURANCETYPE, BOOKINGSTATUS, REPORTSURL, BOOKINGUPDATETYPE, QCSTATUS, TERM, RECURRING, APPROVESTATUS, BOOKINGPOSITIONS_ENUM, OTTURL, MENUTYPE, LOGOPOSITION, THEMES,
    AMAZONMMCMEFXML, SERVERHEALTHURL, DELIVERYSTATUSENUM, AUTOFILLMEDIATYPE, SCHEDULECONSTRAINTS, BOOKINGAPPROVALSTATUS, PAYMENTMODE, ApplicationConfigurationKeys, CONFIGURL, PLANNING_DELETE_OPTION, OTT_POSTER_TYPE, INGESTION_MODULETYPES,
    ACCESS_LEVEL, FREQUENCY_TYPE, RAIL_STATUS, PRODUCTS, seriesChartColorGenerator, TRANSLATORURL, DATE_TIME_FORMATS, SLOT_TIMES, DOCUMENT_TYPE, DASHBOARDURL, TX_MODE, EXPORT_TEMPLATE_MODULES, PARTNER_INGESTION_STATUS, IMPORT_STATUS, USER_RESTRICTED_ACTIONS,
    PERMALINKTYPE, MEDIATYPEENUM, FONT_WEIGHT, SLOT_DIVISIONS, FORMATVARIATIONTYPE, DELETELOGURL, ACCESS_TYPE, MILLISECONDSINADAY, PROGRAMSCHEDULEDRAFT_SAVETYPE, IMDBURL, IMDBTYPES, SUBSCRIPTION_HISTORY_EVENT, THIRDPARTYAPIURL, DIGITALSIGNURL, OVERLAYTYPE, SHAPETYPE, POSITION, TX_STATUS, PLANNINGEVENTSTYLE_PRIORITY, SCREEN_STATUS, CONTENTTYPE, CONTENT_TYPE, APPCONFIGURL, PLAYLIST_SCHEDULE_TYPE, PLAYLIST_STATUS, RAZORPAYURL, DIGITALSIGN_TRANSACTION_STATUS,
    SECONDARYEVENTURL, APPLICATION, ORIENTATION, STORAGE_DESTINATION, FILEBUCKETURL, RESTRICTIONS_OPERATORS, TIMECODEMARKERS, COMPANYTYPE, INGESTION_ACTIVITY, orientations, INGESTIONLOGURL,
    JOB_SCHEDULE_TASK, DELETEPROGRAMSCHEDULEDRAFTURL, AVOD_FEATURE, LIVE_EVENT_GENDER, MESSAGE_TYPE, MESSAGE_VARIANT, SCREEN_RANGE_AND_PRICE, PROTOTYPETRIGGERTYPEENUM, MESSAGE_TEMPLATE, ANALYTICS_EVENTS, DEVICE_ACCESS_CONTROL, DIGISIGN_LOGTYPE
}