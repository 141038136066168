/* eslint-disable */
import React,{useState,useEffect} from 'react'
import { ENTITYNAME,SEND_ENUM } from '../../framework/constant/constant';
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import { useRef } from 'react';
import BossDialog from '../../components/BossDialog';

export const PaymentsEditForm = (props) => {

  useEffect(() => {
    loadcombo();
  },[]);

  const blankDataItem = {
    Hide : false,
    EnableReminder : false
  }

  const[i, setI] = useState(0);
  const [dataItem, setDataItem] = useState(blankDataItem);
  const [paymentProvider,setPaymentProvider]= useState([]);
  const [openPayments, setOpenPayments] = useState(false);
  const[editMode, setEditMode] = useState(false);
  const lang=useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }

  const onChangeForm = (e) => {
    if(e.target.name == 'Hide'){
      setDataItem({...dataItem, Hide : !dataItem.Hide});
    }else if(e.target.name == 'EnableReminder'){
      setDataItem({...dataItem, EnableReminder : !dataItem.EnableReminder , Send: dataItem.EnableReminder ? dataItem.Send :  {}, DaysBefore: dataItem.EnableReminder ? dataItem.DaysBefore : '' });
    }else{
      setDataItem({...dataItem, [e.target.name]: e.target.value });
    }
  }

  

  const loadcombo = async () => {
    let paymentProviderData = await API.getDataLookup(ENTITYNAME.PaymentProvider, {query : ["Archive", "=", false]});
    setPaymentProvider(paymentProviderData.data);
   
  }

const isValid = () => {
  
  if(!(dataItem.PaymentProvider)){
    toast.error(`${lang.please_select_Payment_Provider_error_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });                        
    return false;
  }
  if(!(dataItem.SortIndex)){
    toast.error(`${lang.please_enter_sort_index_error_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }
  if(!(dataItem.TrialPrice)){
    toast.error(`${lang.please_enter_trial_price_error_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }
  if(!(dataItem.TrialDays)){
    toast.error(`${lang.please_enter_trial_days_error_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
    return false;
  }
  if(dataItem.EnableReminder){
    if(!(dataItem.Send) || Object.keys(dataItem.Send).length == 0){
      toast.error(`${lang.please_select_reminder_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(!(dataItem.DaysBefore)){
      toast.error(`${lang.please_enter_days_before_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

  }
  return true;
}

const clearData = () => {
  setDataItem(blankDataItem);
  setEditMode(false);
  setOpenPayments(false);
}

const addPayments = () => {

  if (isValid()) {
    const dataNew = {
      ...dataItem,      
      PaymentProvider : {
        _id: dataItem.PaymentProvider?._id,
        Name: dataItem.PaymentProvider?.Name,
        SID: dataItem.PaymentProvider?.SID
      }, 
      SortIndex : parseInt(dataItem.SortIndex),
      TrialPrice : parseFloat(dataItem.TrialPrice),
      TrialDays : parseInt(dataItem.TrialDays),           
      Send : dataItem.EnableReminder ? dataItem.Send : {},
      DaysBefore : dataItem.EnableReminder ? parseInt(dataItem.DaysBefore) : '',
      EnableReminder : dataItem.EnableReminder ?? false,
      Hide : dataItem.Hide ?? false
    }
    console.log(dataNew);
    props.setPaymentsData([...props.data, dataNew])
    setI(i + 1);
    clearData();
  }
}

const editPayments = () => {
  var data = {
    ...dataItem,
    Send: dataItem.EnableReminder ? dataItem.Send : {},
    DaysBefore: dataItem.EnableReminder ? parseInt(dataItem.DaysBefore) : '',
  }
  if(isValid()){
    let local = [...props.data]
    local[selectedIndexRef.current] = data;
    props.setPaymentsData(local);
    clearData();
  }
}

const editData = (data)=>{
  let dataItem = data.dataItem;
  setSelectedIndexRef(data.dataIndex);
  setDataItem(dataItem);
  setOpenPayments(true);
  setEditMode(true);
}

  const MyCommandCell = (props)=>(
    <div className="mt-1" style={{display:"flex"}}>
    <DeleteRoundButton onClick={()=>ConfirmDeleteAlert(()=>removePayments(props.dataIndex),()=>{})}/>
    <EditRoundButton onClick={()=>editData(props)}/>
    </div>
  )

  const removePayments = (index) => {
    if (props.data.length == 1) {
      props.setPaymentsData([]);
      setI(i - 1);

    }else {

      props.setPaymentsData([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      // clearData();
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  return (
    <>
    <AddRoundButton onClick={() => setOpenPayments(true)} style={{marginLeft:"-15px"}} title={lang.add_reminders_button_tooltip}/>

      <Grid data={props.data} style={{ height: "40vh" ,margin:"5px -15px 40px -15px"}}>
      <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="PaymentProvider.Name" title={lang.payment_Provider_label} editable={false}  />
          <GridColumn field="SortIndex" title={lang.sort_index_label} editable={false}/>
          <GridColumn field="TrialPrice" title={lang.trial_price_label} editable={false}/>
          <GridColumn field="TrialDays" title={lang.trial_days_label} editable={false}/>
          <GridColumn field="Send.Type" title={lang.send_label} editable={false} />
          <GridColumn field="DaysBefore" title={lang.dayPrior_label} editable={false}/>
          <GridColumn field="Hide" title={lang.hide} editable={false} />
          
      </Grid>
     {openPayments && <BossDialog
            title={lang.payments_dialog_header}
            onClose={clearData}
            width={820}
            height={400}
          >
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{marginLeft:'-10px'}}>
            {!editMode && <ActionButton name={lang.add_button_text} title={lang.add_button_tooltip} onClick={() => addPayments()} />}
            {editMode && <SaveButton onClick={() => editPayments() } />}
            <CancelButton onClick={clearData} />
            </div>
            <div className="row mt-2">
              <div className='col-12'>
                <div className='row'>
                  <div className="col-6">
                    <label>{lang.payment_Provider_label} *</label>
                    <DropDownList
                        data={paymentProvider}
                        name="PaymentProvider"
                        textField="Name"
                        dataItemKey="_id"
                        value={dataItem.PaymentProvider}
                        onChange={onChangeForm}
                    />
                  </div>
                  <div className="col-6">
                        <div className="form-group">
                          <label>{lang.sort_index_label} *</label>
                          <input type="number" className="form-control" style={{height:"32px"}}
                            name="SortIndex" 
                            value={dataItem.SortIndex}
                            onChange={onChangeForm}
                            min = {0}
                          /> 
                        </div>
                      </div>
                </div>                

                 {/* trail */}
                 <div className='row'>
                      <div className="col-6">
                        <div className="form-group">
                          <label>{lang.trial_price_label} *</label>
                          <input type="number" className="form-control" style={{height:"32px"}}
                            name="TrialPrice" 
                            value={dataItem.TrialPrice}
                            onChange={onChangeForm} 
                            min = {0}
                          /> 
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>{lang.trial_days_label} *</label>
                          <input type="number" className="form-control" style={{height:"32px"}}
                            name="TrialDays" 
                            value={dataItem.TrialDays}
                            onChange={onChangeForm} 
                            min = {0}
                          /> 
                        </div>
                   </div>   
                    </div>

                    <div className='row'>                 
                        <div className="col-6">
                          <input type = "checkbox" name= {"Hide"} value={dataItem.Hide} checked= {dataItem.Hide} onClick={onChangeForm} onChange={() => {}}/>  
                          <label className="pl-2" htmlFor="TabView">{lang.hide}</label> 
                        </div>  
                    </div>

                    <div className='row p-1'  style={{ margin : '18px 5px 8px 0px' ,border : '1px solid lightgrey'}}>     
                      <div className='col-6 p-4'>
                          <input name="EnableReminder" type={"checkbox"} value={dataItem.EnableReminder} checked={dataItem.EnableReminder} onClick={onChangeForm} onChange={() => {}}  />
                          <label className='ml-2'>{lang.enable_reminder_label}</label>
                      </div>
                                
                      {dataItem.EnableReminder && <div className="form-group col-3">
                        <label>{lang.send_label} *</label>
                        <DropDownList
                          data={SEND_ENUM}
                          name="Send"
                          textField="Type"
                          dataItemKey="ID"
                          value={dataItem.Send}
                          onChange={onChangeForm}
                        />
                      </div>}

                      {dataItem.EnableReminder && <div className="form-group col-3">
                        <label>{lang.daysBefore_label} *</label>
                        <input type="number" className="form-control form-control-sm" name="DaysBefore" value={dataItem.DaysBefore} onChange={onChangeForm} min = {0} />   
                      </div>}
                    </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </BossDialog>}

    </>
  )
}