import { LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant";
import { utility } from "../utility/utilityProvider";
import socket from "./socket";
import underscore from "underscore";

export const bookingSocket = {};

bookingSocket.onAddBookings = (bookingCollection, daysString) => {
    let channelGroup = underscore.groupBy(bookingCollection, (item) => item.Channel);
    let channels = Object.keys(channelGroup)
    let dates = daysString.map((dateString) => {
        let dateArray = dateString.split(" ");
        return new Date(dateArray[0]).getTime();
    });
    bookingSocket.ScheduleRefresh(channels, dates);
}

bookingSocket.onUpdateBooking = (bookingCollection, daysString) => {
    bookingSocket.onAddBookings([bookingCollection], daysString)
}

bookingSocket.onPurge = (booking) => {
    let channels = [booking.Channel]
    let dates = [booking.BookingDate]
    bookingSocket.ScheduleRefresh(channels, dates);
}

bookingSocket.onMakeGood = (booking) => {
    bookingSocket.onPurge(booking);
}

bookingSocket.onChangeCommercial = (bookings) => {
    bookingSocket.onMakeGoodMultiple(bookings);
}

bookingSocket.onDelete = (booking, daysString) => {
    bookingSocket.onAddBookings([booking], daysString);
}

bookingSocket.onMakeGoodMultiple = (bookings) => {
    let channels = bookings.map((booking) => booking.Channel);
    let dates = bookings.map((booking) => booking.BookingDate);
    bookingSocket.ScheduleRefresh(channels, dates);
}

bookingSocket.ScheduleRefresh = (channels, dates) => {
    let user = utility.getValue(LOCALSTORAGE_KEY.userData);
    channels.map((channel) => {
        dates.map((date) => {
            socket.emit(SOCKET_EVENTS.onSocketData,
                {
                    action: SOCKET_ACTION.SCHEDULE_BOOKING_REFRESH,
                    module: MODULE.SCHEDULING,
                    data: {
                        selectedChannelSID: channel.SID,
                        selectedScheduleDate: date,
                    },
                    user: { name: user.name, _id: user._id }
                });
        })
    })
}




