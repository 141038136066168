/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import {ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "../../locale/useTranslation";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

export const CreditLineReport = (props) => {
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const blankDataItem = {Channel : {}, Date: defaultDates.FirstDate}
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const navigate = useNavigate();
    const [channel, setChannel] = useState([]);
    const lang = useTranslation();
    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }
    const payloadRef = useRef({});
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const onChange = async (e) => {     
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
            setGridData([]);    
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        } 
        else
        {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }  
    };

     const isValid = () => {
        if (!utility.isValidDate(dataItem.Date)) {
            toast.error(`${lang.please_select_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    } 

     const creditLineExportData = async () => {
        if (isValid()) {
            var fdate = new Date(dataItem.Date); 
            var fromdate = fdate.getTime();
            payloadRef.current = {
                date : fromdate,
                channelsid : selectedChannelRef.current.SID,
                channelName : selectedChannelRef.current.FullChannelName,
            }
            loaCreditLinedData();
        }
    }

    const showCreditLineReportData = async () => {
        if (isValid()) { 
            var fromdate = (new Date(dataItem.Date)).setHours(0, 0, 0, 0);
            payloadRef.current = {
                date : fromdate,
                channelsid : selectedChannelRef.current.SID,
                channelName : selectedChannelRef.current.FullChannelName,
            }
            var res = await API.getShowCreditLineReportData(payloadRef.current);
            console.log(res.data.length)
            
            if(res.data.length == 0){
                toast.info(`${lang.no_data_found_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });   
                setGridData([]);             
                return;
            }
            else if(res.data.length > 0){                
                setGridData(res.data);
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
        
    };

    
    console.log(gridData)

    const loaCreditLinedData = async () => { 
        var payload = {...payloadRef.current,
        }     
        var res = await API.getCreditLineReport(payload);
         if (res.success) {
            if(res.dataLength == 0){
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });             
                return;
            }
            else
            {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            
         }
         else {
             toast.error(res.message, {
                 position: toast.POSITION.TOP_RIGHT
             });
         }
    };   

     const onCancel = () => {
        navigate(-1);
      }

    return (<>  
     <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.credit_line_report_page_breadcrumb}</span>
        </h1>   
        <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
            <div className="row">
                <div className="flex-container wrap">
                    <div className="row mt-1 mb-1" style={{marginLeft:'-25px'}}>
                        <div className="col">
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} btnColor={'primary'} onClick={showCreditLineReportData} />
                        {gridData.length > 0 &&  <ActionButton title={lang.download_credit_line_report_tooltip_button} disabled={gridData.length > 0 ? false : true} name={lang.export_button_text} btnColor= {'info'} onClick={creditLineExportData} />}
                            <CancelButton onClick={onCancel}></CancelButton>
                        </div>
                    </div>                    
                </div> 
            </div>  
            <div className="row mt-3">
                <div className="col-3">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"Date"} value={dataItem.Date} onChange={onChange} />
                    </div>
                </div>  
                <div className="col-3">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            data={channel}
                            name={"Channel"}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>                    
            </div>                               
            <div className="row mt-2">  
               
      <div>
        <Grid
          data={gridData} 
          resizable={true}       
          style={{height:"66vh",wdith:"60vw"}}
        >
          <GridColumn field="AssetId" title={lang.assetId_column} editable={false}/>
          <GridColumn field="Brand" title={lang.brand_column} editable={false}/>
          <GridColumn field="Description" title={lang.description_column}  editable={false} />          
          <GridColumn field="Duration" title={lang.duration_column}  editable={false} />
          <GridColumn field="TimeRange" title={lang.time_range_column} editable={false}/>
          <GridColumn field="TotalSpots" title={lang.total_spot_column} editable={false}/>
          <GridColumn field="ScheduleSpots" title={lang.schedule_spot_column}  editable={false} />
          <GridColumn field="TotalDuration" title={lang.total_duration_column} editable={false}/>
        </Grid>
      </div>
            </div>           
        </div>      
    </>)
}
export default CreditLineReport;