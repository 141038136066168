import React from 'react';
import { utility } from '../../framework/utility/utilityProvider';
export const LabelGroup = (props) => {

    const { onClick } = props;

    const itemsToShow = props?.value?.slice(0, 3);
    const remainingItemsCount = props?.value?.length - 3;

    return (
        <div className='pl-1 pr-2' style={{ display: "flex", borderRadius: "5px", alignItems: "center", }}>
            {itemsToShow.map((item) => (
                <div title={item} onClick={onClick} key={item?._id} className="px-3 ml-1" style={{ backgroundColor: "#f4f5fa", color: "white", borderRadius: "5px", cursor: "pointer", fontSize: "12px", height: "30px", borderWidth: "thin", boxShadow: "inset 0px 0px 0px 1px grey" }}><span style={{ verticalAlign: "center", color: "grey", position: "relative", top: "4px", fontFamily: "Roboto", letterSpacing: "0.4px" }}>{utility.convertFirstLetterToUpperCase(utility.subString(item, 7))}</span></div>
            ))}
            {remainingItemsCount > 0 && (
                <p onClick={onClick} className='ml-1' style={{ color: "grey", fontSize: "12px", position: "relative", top: "8px", cursor: "pointer" }}>+ {remainingItemsCount} more</p>
            )}
        </div>
    );
};
