/* eslint-disable */

import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { utility } from "../../../utility/utilityProvider";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { LabelGroup } from "../../../../modules/DigitalSign/labelGroup";

//to show item stored in array on collection
export const ArrayCellFieldName = (props) => {

  const field = props?.field || "";
  const value = props?.dataItem[field];
  // console.log(value);
  var displayValue = value?.map(v => v?.Name) ?? [];

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...useTableKeyboardNavigation(props.id)}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    style={props.style}>
    <LabelGroup value={displayValue} />
  </td>;
};