/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import * as API_DigiSign from "../../framework/API/api_digitalSign";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { ENTITYNAME, LOCALSTORAGE_KEY, MODULE, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, SCREEN_STATUS, COMPANYTYPE } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import MyStatusCell from "../../components/MyStatusCell";
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActivateScreenPoup from "./activateScreenPoup";
import { ScreenGroupEditForm } from "./ScreenGroupEditForm";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import useDigiSocket from "../../framework/socket/useDigiFSocket";
import digiSocket from "../../framework/socket/digiSocket";
import DigitalSignScreenGroupCollectionSelection from "./DigitalSIgnScreenGroupCollectionSelection";
import DigitalSignCollectionSelection from "./DigitalSignCollectionSelection";
import { DigitalSign_PlansColumns } from "../../framework/utility/customColumns";
import { ArrayCellFieldName } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCellFieldName";
import LastPlayItemForm from "./LastPlayItemForm";
import { BASEURL } from "../../framework/constant/baseurl";
import MyCompanyCell from "./component/MyCompanyCell";
import DigitalSignAsrunReport from "./DigitalSignAsrunReport";
import DigitalSignAsrunErrorReport from "./DigitalSignAsrunErrorReport";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const DigisignScreenCollection = (props) => {
    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showArchive = props.showArchive ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showEdit = props.showEdit ?? true;
    let showDelete = props.showDelete ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement ?? [];
    let sort = props.sort ?? { SID: 1 };
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    let entityName = props.entityname;
    let enableMutliSelect = props.enableMutliSelect ?? false;
    let module = props?.module;
    let showLogs = props.showLogs ?? false;
    let doLoad = props.isLoading ?? true;

    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(0);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const [warningDialog, setWarningDialog] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [showActivateScreenPopup, setShowActivateScreenPopup] = useState(false);
    const [showChoosePlanPopup, setShowChoosePlanPopup] = useState({
        show: false,
        data: null
    });
    const [isInsertGroupScreen, setIsInsertGroupScreen] = useState(false);

    const [groups, setGroups] = useState([]);
    const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
    const [status, setStatus] = useState([]);
    const [dataItem, setDataItem] = useState({
        group: { _id: 0, Name: 'All' },
        status: { _id: 0, Description: 'All' },
        company: { _id: 0, Name: 'All' }
    });
    const [showAttachGroupPopup, setShowAttachGroupPopup] = useState(false);
    const [showLastPlayItemPopup, setShowLastPlayItemPopup] = useState(false);
    const [showAsrunReportPopup, setShowAsrunReportPopup] = useState(false);
    const [showAsrunErrorReportPopup, setShowAsrunErrorReportPopup] = useState(false);
    const selectedAttachGroupRef = useRef({});

    const [tileView, setTileView] = useState(true);
    const [openMenuId, setOpenMenuId] = useState(null);
    const selectedPlanRef = useRef(null);
    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const lastPlayItemRef = useRef([]);
    const selectScreenItemRef = useRef({});

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const location = useLocation();
    const lang = useTranslation();
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);

    // open by defualt create new form
    useEffect(() => {
        if (location.state?.openNewEditForm) {
            onShowActivateScreenPopup();
        }
    }, [location.state?.openNewEditForm]);

    let href = location.state ? location.state.href : false;
    if (href) {
        wherestatement = location.state.wherestatement;
    }


    useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        switch (socketData.action) {
            case SOCKET_ACTION.DIGI_SCREEN_ON:
            case SOCKET_ACTION.DIGI_SCREEN_OFF:
                loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, showArchive);
                break;
            default:
                break;
        }
    })


    useEffect(() => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
        return () => {
            digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
        }
    }, []);

    useEffect(() => {
        setShowArchived(false);
        doLoad ? setIsLoading(true) : setIsLoading(false);
        setPage({ skip: 0, take: page_take });
        loadCombo();
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, false);
    }, [props.entityname, props.gridData, props.title, props.wherestatement]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = gridSort[0] ?? initialSort;

        let newWhereStatement = [...wherestatement];

        if (dataItem.group._id != 0) {
            newWhereStatement = [...newWhereStatement, ["screenGroup._id", "=", dataItem.group._id]]
        }

        if (dataItem.status._id != 0) {
            newWhereStatement = [...newWhereStatement, ["active", "=", dataItem.status._id == SCREEN_STATUS.Active]]
        }

        if (dataItem.company._id != 0) {
            newWhereStatement = [...newWhereStatement, ["Company_id", "=", dataItem.company._id]]
        }

        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
        }
    }, [searchText]);

    const loadCombo = async () => {
        const groupRes = await API_DigiSign.getDataLookup(ENTITYNAME.DigitalSignScreenGroup, { query: ["Archive", "=", false] });
        setGroups(groupRes.data);
        const statusRes = Object.keys(SCREEN_STATUS).map((key) => {
            return { _id: SCREEN_STATUS[key], Description: key }
        });
        setStatus(statusRes);
    }

    const loaddata = async (pagination, wherestatement, searchText, archive) => {
        try {
            let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
            let json = await API_DigiSign.getDigitalSignScreen(
                { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
                searchText
            );
            setGridData(json?.data.map(d => {
                d.activeAgo = utility.timeAgo(d?.activeAgo ?? 0);
                d.screenGroup = [d?.screenGroup]
                return d;
            }) ?? []);
            setTotal(json?.pagination?.total);
        } catch (error) {
            console.log("error", error);
        }
        setIsLoading(false);
    };

    const onKillUserSession = (userData) => {
        delete userData["Module"];
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
        toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            navigate(`${item.SID}`, {
                state: {
                    copy: item.copy ? true : false,
                    quickEdit: item.quickEdit ? true : false,
                    item: item,
                    prevPath: location.pathname
                }
            });
        }
    };

    const handleNavigate = (item) => {
        if (item?.Module) {
            window.open(item.path, "MyTargetWindowName");
        } else {
            navigate(`${item.path}`, { state: item });
        }
    }

    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

        if (!isDuplicate) {
            setOpenForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API_DigiSign.saveData(props.entityname, dataToSave);
        console.log(res);
        if (dataItem?.IsDefault) {
            await API.setAsDefault(entityName, dataItem?._id)
        }
        if (res.success) {
            if (showLogs) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: module, data: res.data, message: res.message };
                API.SaveLogs(logData);
            }
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname)
        //to load grid with new data
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, searchText, showArchived);
        setOpenForm(false);
    };

    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleDelete = async (dataItem) => {
        onConfirmDelete(dataItem)
    };

    const handleArchive = async (dataItem) => {
        let resArchiveUpdated = await API_DigiSign.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })

        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            if (module) {
                let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
                API.SaveLogs(logData);
            }
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const setAsDefault = async (data) => {

        var setAsDefaultRes = await API.setAsDefault(entityName, data._id);
        if (setAsDefaultRes.success) {
            let sortEvent = gridSort[0] ?? initialSort;
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            toast.error(setAsDefaultRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const handleAttachGroup = (data) => {
        selectedAttachGroupRef.current = data;
        setShowAttachGroupPopup(true);
    }

    const handleNewGroupCreate = async (data) => {
        selectedAttachGroupRef.current = data;
        setIsInsertGroupScreen(true);
        setShowScreenGroupPopup(true);
    }

    const handleLastPlayItem = async (data) => {
        lastPlayItemRef.current = data;
        setShowLastPlayItemPopup(true);
    }

    const handleAsrunReport = async (data) => {
        console.log(data);
        selectScreenItemRef.current = data;
        setShowAsrunReportPopup(true);
    }

    const handleRestartScreen = async (data) => {
        await API_DigiSign.sendEvent(data.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
    }

    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            showLogs={showLogs}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem}
            onDelete={handleDelete}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            toNavigate={handleNavigate}
            myMenuModel={myMenuModel}
            module={module}
            openNewEditForm={openNewEditForm}
            onKillUserSession={onKillUserSession}
            setAsDefault={setAsDefault}
            onAttachGroup={handleAttachGroup}
            onNewGroupCreate={handleNewGroupCreate}
            onLastPlayItem={handleLastPlayItem}
            onAsrunReport={handleAsrunReport}
            onRestart={handleRestartScreen}
        />
    );

    const Mystatuscell = (props) => {

        const field = props.field

        let tileBackColor = props.dataItem[field] ? "#63aa43" : "#E72929";
        let status = props.dataItem[field] ? "Active" : "Inactive";

        return <MyStatusCell title={status} tileBackColor={tileBackColor} />

    }

    const handleErrorDialogClose = () => {
        setDeleteError(!deleteError);
        setDeleteErrorMessage("");
    }
    const errorHandler = (message) => {
        setWarningDialog(!warningDialog);
        setDeleteError(true);
        setDeleteErrorMessage(message);
    }

    const onConfirmDelete = async (dataItem) => {
        let user = utility.getValue(LOCALSTORAGE_KEY.userData);
        let deleteRes = await API_DigiSign.removeScreenAndLog(dataItem.companyPlanId, dataItem._id, user);
        console.log("Loading");
        if (!deleteRes.success) {
            console.log(res.message);
            errorHandler(res.message);
            return;
        }

        await API_DigiSign.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
        // let socketData = { action: SOCKET_ACTION., module: MODULE.ALL, data: null };
        // await API_DigiSign.sendEvent(dataItem.socketID, socketData);
        let current_page = page.skip / page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, '', showArchived);
        socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
        utility.deleteLocalStorageItem(props.entityname);
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;
        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
        setPage(e.page);
        cancelSelectedItem()
    };

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, searchText, showArchived);
        setGridSort([initialSort]);
        loadCombo();
        setDataItem({
            group: { _id: 0, Name: 'All' },
            status: { _id: 0, Description: 'All' },
            company: { _id: 0, Name: 'All' },
        })
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, showArchived);
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const onArchiveButtonClick = async () => {
        if (selectedItemsRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        for (let i = 0; i < selectedItemsRef.current.length; i++) {
            const item = selectedItemsRef.current[i];
            await handleArchive(item);
        }
        cancelSelectedItem();
    }

    const cancelSelectedItem = () => {
        setSelectedItem([]);
        setSelectedState({});
    }

    const handleFiltersChange = (e) => {

        setIsLoading(true);

        let localFilterItem = { ...dataItem, [e.target.name]: e.target.value }

        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        let newWhereStatement = [...wherestatement];

        if (localFilterItem.group._id != 0) {
            newWhereStatement = [...newWhereStatement, ["screenGroup._id", "=", localFilterItem.group._id]]
        }

        if (localFilterItem.status._id != 0) {
            newWhereStatement = [...newWhereStatement, ["active", "=", localFilterItem.status._id == SCREEN_STATUS.Active]]
        }

        if (localFilterItem.company._id != "") {
            newWhereStatement = [...newWhereStatement, ["Company_id", "=", localFilterItem.company._id]]
        }

        console.log(newWhereStatement);
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, newWhereStatement, searchText, showArchived);
        setPage({ skip: 0, take: page_take });
    }

    const handleSelectedGroup = async (dataItem) => {

        let data = dataItem.length > 0 ? dataItem[0] : {};
        let finalData = {
            ...selectedAttachGroupRef.current,
            screenGroup: {
                _id: data?._id,
                SID: data?.SID,
                Name: data?.Name,
                Description: data?.Description
            }
        }
        let res = await API_DigiSign.saveData(ENTITYNAME.DigitalSignScreen, finalData);
        console.log(res);
        if (res.success) {
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            if (res.data?.socketID) {
                await API_DigiSign.sendEvent(res.data.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
            }

        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setShowAttachGroupPopup(false);
        refreshCollection();
    }

    const checkIfCanActivateScreen = async () => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        let canActivateScreenRes = await API_DigiSign.canActivateScreen(companyId);
        if (!canActivateScreenRes.success && canActivateScreenRes?.data?.length > 0) {
            console.log(canActivateScreenRes);
            setShowChoosePlanPopup({
                show: true,
                data: canActivateScreenRes.data
            });
            return false;
        }
        else if (!canActivateScreenRes.success) {
            toast.error(canActivateScreenRes.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        // if all screens are consumed
        else if (canActivateScreenRes.success && canActivateScreenRes.data != null && canActivateScreenRes.data.length == 0) {
            toast.info(canActivateScreenRes.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        else {
            selectedPlanRef.current = canActivateScreenRes.data;
            return true;
        }
    }

    const onShowActivateScreenPopup = async () => {
        if (isEnterpriseLogin) return setShowActivateScreenPopup(true);
        let res = await checkIfCanActivateScreen();
        if (res) {
            setShowActivateScreenPopup(true);
        }
    }

    const handleChoosePlanPopupClose = () => {
        setShowChoosePlanPopup({ ...showChoosePlanPopup, show: false });
    }

    const handleChoosePlanPopupSubmit = (selectedPlan) => {
        selectedPlanRef.current = selectedPlan[0];
        setShowActivateScreenPopup(true);
    }

    const handleMenuClick = (screenId, event) => {
        event.stopPropagation();
        setOpenMenuId(openMenuId === screenId ? null : screenId);
    };
    
    const handleAsrunErrorReport = async (data) => {
        selectScreenItemRef.current = data;
        setShowAsrunErrorReportPopup(true);
    }

    const ContextMenu = ({ screen, onClose }) => {
        return (
            <div className="context-menu" style={{ overflow: "auto", height: "300px" }}>
                <div onClick={() => { enterEdit(screen); onClose(); }}><i className="pi pi-pencil pr-1" /> Edit</div>
                <div onClick={() => { handleDelete(screen); onClose(); }}><i className="pi pi-trash pr-1" />Delete</div>
                <div onClick={() => { handleAttachGroup(screen); onClose(); }}><i className="pi pi-link pr-1" />Attach Group</div>
                <div onClick={() => { handleNewGroupCreate(screen); onClose(); }}><i className="pi pi-link pr-1" />New Group from screen</div>
                <div onClick={() => { handleLastPlayItem(screen); onClose(); }}><i className="pi pi-external-link pr-1" />Show last Play Item</div>
                <div onClick={() => { handleAsrunReport(screen); onClose(); }}><i className="pi pi-external-link pr-1" />Show Asrun Report</div>
                <div onClick={() => { handleAsrunErrorReport(screen); onClose(); }}><i className="pi pi-external-link pr-1" />Show Asrun Error Report</div>
                <div onClick={() => { handleRestartScreen(screen); onClose(); }}><i className="pi pr-1 pi-power-off" />Restart App</div>
            </div>
        );
    };

    return (
        <div>
            <div className="mb-1" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h1 className="page-title txt-color-bludeDark m-0">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>{myTitle === "" ? props.entityname : myTitle}</span>
                </h1>
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <div className="pointer p-1" onClick={() => setTileView(!tileView)}
                        title={tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`}
                    >
                        <i className={`fas ${tileView ? "fa-table-cells" : "fa-table-list"} fa-xl`} />
                    </div>
                </div>
            </div>


            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}
                    {showAdd && (
                        <AddRoundButton onClick={onShowActivateScreenPopup} title={lang.save_button_tooltip}></AddRoundButton>
                    )}
                    {showArchive && enableMutliSelect && selectedItemsRef.current.length != 0 && (
                        <ActionButton title={showArchived ? lang.un_archive_label : lang.archive} name={showArchived ? lang.un_archive_label : lang.archive}
                            onClick={onArchiveButtonClick} />
                    )}
                </div>

                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-10px' }}>
                        <label htmlFor="Node">{lang.group_label}</label>
                        <span className='float-right cursor-pointer'
                            style={{
                                padding: '2px 5px 0px 4px', border: '1px solid #ced4da',
                                fontSize: '10px', borderRadius: '10px', margin: '4px'
                            }}
                            onClick={() => { setShowScreenGroupPopup(true); setIsInsertGroupScreen(false); }} title="Add Screen Group">
                            <i className="fa fa-plus"></i>
                        </span>
                        <DropDownList
                            data={[{ _id: 0, Name: 'All' }, ...groups]}
                            style={{ backgroundColor: 'white' }}
                            name="group"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.group}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-10px' }}>
                        <label htmlFor="Node">{lang.status_label}</label>
                        <DropDownList
                            data={[{ _id: 0, Description: 'All' }, ...status]}
                            style={{ backgroundColor: 'white' }}
                            name="status"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.status}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>

                    {isEnterpriseLogin && <div className="mr-2" style={{ marginTop: '-10px', width: '150px' }}>
                        <label htmlFor="TabView">{lang.company_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={[
                                { _id: 0, Name: "All" },
                                ...companies
                            ]}
                            name="company"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.company}
                            onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>}

                </div>
            </div>
            {!isLoading ?
                tileView ?
                    gridData.length > 0 ?
                        <div className="screen-tile-container">
                            {gridData.map((screen) => (
                                <div key={screen._id} className="screen-tile" onMouseLeave={() => setOpenMenuId(null)}>
                                    <div className="screen-tile-header">
                                        <h3>{screen.Name}</h3>
                                        <div className="header-right">
                                            <span className={`status-dot ${screen.active ? 'online fa-fade' : 'offline'}`}
                                                title={screen.active ? 'Online' : 'Offline'}></span>
                                            <div className="three-dots" onClick={(e) => handleMenuClick(screen._id, e)}>
                                                <i className='fa fa-ellipsis-h' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="screen-tile-image">
                                        {(screen.screenShotUrl && screen.screenShotUrl.length > 0) ? <img
                                            src={BASEURL + '/' + screen.screenShotUrl + '?' + new Date().getTime()}
                                            alt={screen.Name}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
                                            }}
                                        /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: 'black', color: "white" }}>No Screenshot</div>}
                                    </div>
                                    {openMenuId === screen._id && (
                                        <ContextMenu
                                            screen={screen}
                                            onClose={() => setOpenMenuId(null)}
                                        />
                                    )}
                                    <div className="screen-tile-details">
                                        <p style={{ marginBottom: '10px' }} title={screen.screenGroup?.[0]?.Name}><strong>Screen Group:</strong> {utility.subString(screen.screenGroup?.[0]?.Name, 23)} </p>
                                        <p style={{ fontSize: '12px', color: 'grey', position: 'absolute', bottom: '0px' }}>{screen.activeAgo}</p>
                                        {isEnterpriseLogin && <p style={{ fontWeight: 500, fontSize: '12px', position: 'absolute', bottom: '0px', right: '5px' }}>{companies.length > 0 ? utility.subString(companies.find((c) => c?._id === screen?.Company_id)?.Name, 25) : ''}</p>}
                                    </div>
                                </div>
                            ))}
                        </div>
                        : <div style={{ height: "65vh", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', fontWeight: "500", color: "grey" }}><AddRoundButton onClick={onShowActivateScreenPopup} /> Add New Screen</div>
                    : <Grid
                        style={props.gridstyle ?? {
                            height: "76vh",
                        }}
                        sort={gridSort}
                        sortable={sortable}
                        onSortChange={onSortChange}
                        rowRender={gridRowRender}
                        filterable={filterable}
                        pageable={isPageable}
                        skip={page.skip}
                        take={page.take}
                        onPageChange={pageChange}
                        total={total}
                        resizable={true}
                        data={
                            gridData.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: enableMutliSelect,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        dataItemKey={DATA_ITEM_KEY}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                    >

                        {/* Multi Select Checkbox row */}
                        {enableMutliSelect && (<GridColumn
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={
                                gridData.findIndex(
                                    (item) => !selectedState[idGetter(item)]
                                ) === -1
                            }
                            locked={true}
                            filterable={false}
                        />
                        )}

                        {/* Edit Row */}
                        {showCommandCell && (
                            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                        )}
                        <GridColumn title="Name" field="Name" width={200} />
                        <GridColumn title="Active ago" field="activeAgo" width={200} />
                        <GridColumn title={"Status"} field="active" width={150} cell={Mystatuscell} />
                        <GridColumn title={"No. of Playlist"} field="playlist" width={200} cell={ArrayCell} format="length" />
                        <GridColumn title={"Screen Group"} field="screenGroup" width={300} cell={ArrayCellFieldName} />
                        {isEnterpriseLogin && <GridColumn field="Company_id" title={"Company"} cell={MyCompanyCell} width={200} />}
                    </Grid>
                : <Loader />}

            {openForm && (
                <CustomEditForm
                    {...props}
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    refresh={refreshCollection}
                    item={editItem}
                />
            )}

            {deleteError && <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
            {showActivateScreenPopup && <ActivateScreenPoup
                onClose={(refresh = false) => {
                    setShowActivateScreenPopup(false);
                    if (refresh) {
                        refreshCollection();
                    }
                }} lastItem={total} selectedPlan={selectedPlanRef.current} />}
            {showScreenGroupPopup && <ScreenGroupEditForm isInsertGroupScreen={isInsertGroupScreen} refresh={refreshCollection} cancelEdit={() => setShowScreenGroupPopup(false)} handleSelectedGroup={handleSelectedGroup} />}

            {showAttachGroupPopup && <DigitalSignScreenGroupCollectionSelection entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "!=", true]} title={lang.attach_screen_group_dialog_header} addButtonTitle={lang.select_button_text} closeForm={() => setShowAttachGroupPopup(false)} seletedState={selectedAttachGroupRef.current?.screenGroup} setDataList={handleSelectedGroup} width="650px" mode="single" />}

            {showChoosePlanPopup.show && <DigitalSignCollectionSelection closeForm={handleChoosePlanPopupClose} data={showChoosePlanPopup.data} mode="single" customColumns={DigitalSign_PlansColumns} setDataList={handleChoosePlanPopupSubmit} />}
            {showLastPlayItemPopup && <LastPlayItemForm data={lastPlayItemRef.current} onCloseForm={() => setShowLastPlayItemPopup(false)} />}
            {showAsrunReportPopup && <DigitalSignAsrunReport item={selectScreenItemRef.current} cancelEdit={() => setShowAsrunReportPopup(false)} />}
            {showAsrunErrorReportPopup && <DigitalSignAsrunErrorReport item={selectScreenItemRef.current} cancelEdit={() => setShowAsrunErrorReportPopup(false)} />}
        
        </div>
    );
};