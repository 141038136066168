/* eslint-disable */
import { useNavigate } from "react-router-dom";
import { ConfirmAlert } from "../../ConfirmAlert";
import { COMPANYTYPE, LOCALSTORAGE_KEY, LOGEVENT, MODULE, PRODUCTS, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useState, useRef, useEffect } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import * as API from "../../framework/API/api"
import { Avatar, Badge } from "@mui/material";
import useSocket from "../../framework/socket/useSocket";
import { GlobalSearchBar } from "../../framework/forms/helpercomponents/inputs/GlobalSearchBarV2";
import socket from "../../framework/socket/socket";
import MyPlansPopup from "./myPlansPopup";
import BossDialog from "../../components/BossDialog";
import ChangePasswordForm from "../users/ChangePasswordForm";

const Header = (props) => {

    const navigate = useNavigate();
    const lang = useTranslation();
    const version = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
    let isDigitalSignage = utility.isAutomateB();
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const notifications = utility.getValue('Notification') ?? [];
    const [notification, setNotification] = useState(utility.getValue('Notification') ?? [])

    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
   
    const [show, setShow] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showMyPlans, setShowMyPlans] = useState(false);

    const anchor = useRef();
    const isFullScreen = useRef(false);
    const notificationRef = useRef();

    const onClick = () => {
        setShow(!show);
    };
    const userData = {
        _id: user._id,
        SID: user.SID,
        userName: user.userName,
        name: user.name,
        Email: user.Email,

    }

    const onLogout = () => {

        ConfirmAlert(
            () => {
                navigate('/');
                let logData = { event: LOGEVENT.LOG_OUT, module: MODULE.LOGIN, data: userData, message: LOGEVENT.LOG_OUT };
                API.SaveLogs(logData);
                localStorage.clear();
                sessionStorage.clear();
                socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
            }, // on Click yes
            () => { }, // on click no
            `${lang.confirm_logout_label}`, //title
            `${lang.are_you_sure_you_want_to_logout}`// message
        )
    }

    const showMyPlansPopup = () => {
        setShowMyPlans(!showMyPlans);
    }

    //socket
    // const socket = useSocket(SOCKET_EVENTS.onSocketNotification, (socketData) => {

    //     let checkRoleInCurrentUser = user.Role.filter((x) => x.SID == socketData.reciever.role.SID).length > 0 ? true : false;

    //     let notifications = utility.getValue('Notification') ?? [];
    //     if(socketData.data.length != 0 && checkRoleInCurrentUser && (socketData.reciever.user.SID == 0 || socketData.reciever.user.SID == user.SID)){
    //         setNotification([{socketData : socketData ,messages : `${socketData.data.length} workorder created`},...notifications])
    //         utility.setValue('Notification',[{socketData : socketData ,messages : `${socketData.data.length} workorder created`},...notifications]);

    //         toast.info(`${socketData.data.length} workorder created`, {
    //           position: toast.POSITION.TOP_RIGHT
    //         });
    //     }

    // });

    const onNotificationClick = () => {
        utility.setValue('DashboardTab', '7');
        navigate('/home')
    }

    const onClickFullScreen = () => {


        if (isFullScreen.current) {

            //need to exit from full screen
            isFullScreen.current = false;

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }



        } else {
            isFullScreen.current = true;

            console.log('The browser is not in fullscreen mode');
            let elem = document.documentElement;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }





    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchor.current && !anchor.current.contains(event.target)) {
                setShow(false);
            }
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };
        // Attach the event listener to the body
        document.body.addEventListener('click', handleClickOutside);
        // Clean up the event listener on component unmount
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [show, showNotifications]);

    return (<>
        <div className='row headerMain'>
            <div className={`col ${isDigitalSignage && 'mt-2'}`}>
                <img src={isDigitalSignage ? require("../../img/AutomateBLogoWhiteOrange.png") : require("../../img/BossStudioLogo.png")} style={{ height: isDigitalSignage ? "22px" : "42px", marginLeft: "15px" }} alt="BMS Group" />
                {isEnterpriseLogin && <div style={{color:'#8b8b8d', marginTop:'-7px', marginLeft:"14px", fontSize:"13px",letterSpacing:'05px', fontFamily:"sans-serif" }}>Enterprise</div>}
                
            </div>

            {/* SEARCH BAR COMPONENT */}

            {/* center */}
            <div style={{ position: 'absolute', left: '35%', marginTop: '-2.5px' }} >
                <GlobalSearchBar />
            </div>
            {/* <!-- #TOGGLE LAYOUT BUTTONS --> */}

            <div className="pull-right mr-4" >
                {/* Profile button */}
                <div className="pull-right">
                    <button className="btn btn-circle btn-md " onClick={onClick} ref={anchor} title="Profile" style={{ backgroundColor: 'white', fontWeight: '800', marginTop: '7px' }}>{user.name.charAt(0)}</button>
                    <Popup anchor={anchor.current} show={show} popupClass={"popup-content"} style={{ width: '20vw', marginTop: '8px' }}>
                        <div className="row m-1 mb-4">
                            <div className="col-12">
                                <div className="d-flex">
                                    <span>Version - {version?.Version ?? ""}</span>
                                    {utility.isAutomateB() && <a className="ml-auto" href="https://automateb.com/privacy-policy/" target="_blank">{lang.privacy_policy_label}</a>}
                                </div>

                                <h3 style={{ fontWeight: '900' }}>{lang.account_label}</h3>
                                <div className="row">
                                    <div className="col-1"><b className="btn btn-circle btn-md bg-success mt-2" style={{ color: 'white' }}>{user?.name.charAt(0)}</b></div>
                                    <div className="col ml-3">
                                        <span style={{ fontSize: '16px', fontWeight: '500' }}>{utility.subString(user.name, 25)}</span>
                                        <div>{utility.subString(user?.userName, 22)}</div>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { setShowChangePassword(true); setShow(false) }}>{lang.change_password_label}</div>
                                    <hr />
                                    {(utility.isAutomateB() && !isEnterpriseLogin) && <><div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { showMyPlansPopup(); setShow(false) }}>My Plans</div><hr /></>}

                                    <div style={{ cursor: 'pointer', fontSize: '15px', fontWeight: '400' }} onClick={() => { onLogout(); setShow(false) }}>{lang.log_out_label}</div>
                                </div>
                            </div>
                        </div>
                    </Popup>
                </div>

                {/* Switch Menu Button */}
                <div title="Switch Menu" id="hide-menu" style={{ color: 'white' }} onClick={props.onMinifyMenuClick} className="pull-right btn-lg btn-circle cursor-pointer">
                    <i className="fa fa-bars"></i>
                </div>
                {/* Log Out Button
                 <div id="logout" className="pull-right nav-right-btn" title="Logout" onClick={() => { onLogout(); }}>
                    <span>
                        <a href="# " title="LogOut">
                            <i className="fa fa-right-from-bracket"></i>
                        </a>
                    </span>
                </div> */}

                {/* Full Screen Button */}
                <div title="Full Screen" id="fullscreen" style={{ color: 'white', marginRight: '-15px' }} onClick={onClickFullScreen} className="pull-right btn-lg btn-circle cursor-pointer">
                    <i className="fa fa-arrows-alt"></i>
                </div>

                {/* Notifications icon */}
                <div className="pull-right mt-2 mr-1">
                    <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={notifications.length} color="primary" max={99}>
                        <i className=" fa-solid fa-bell fa-lg" ref={notificationRef} style={{ color: 'white', cursor: 'pointer' }} onClick={() => setShowNotifications(!showNotifications)}></i>
                    </Badge>
                    <Popup anchor={notificationRef.current} show={showNotifications} popupClass={"popup-content"} style={{ width: '20vw', marginTop: '15px' }}>
                        <div className="row m-1 mb-4 ">
                            <div className="notification-box">
                                <h2 className="notification-header">Notifications <span style={{ color: 'red', margin: '1px' }}>{`(${notification.length})`}</span></h2>
                                {/* map here */}
                                {notification.map((obj) => (<div className="notifications-item" onClick={onNotificationClick}>
                                    {/* <img src="https://i.imgur.com/uIgDDDd.jpg" alt="img"/> */}
                                    <div className="notifications-item-avatar"><Avatar>{obj.socketData.user.name.slice(0, 1).toUpperCase()}</Avatar></div>
                                    <div className="text">
                                        <h4>{obj.socketData.user.name}</h4>
                                        <p>{obj.messages}</p>
                                    </div>
                                </div>))}
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
        </div>
        {showMyPlans && <MyPlansPopup onClose={showMyPlansPopup} />}
        {showChangePassword && <ChangePasswordForm data={user} isClearLocalStorage={true} isRouteHomePage={true} closeForm={() => setShowChangePassword(false) } />}
    </>)
}

export default Header;