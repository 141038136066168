/* eslint-disable */
import React, { useState, useEffect } from "react";
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { ENTITYNAME, OPERATORS, LOCALSTORAGE_KEY, MEDIACATEGORIES } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import { DataHelper } from "../../framework/helper/DataHelper";
import MyMultiSelect from "../../components/MyMultiSelect";
import BossDialog from "../../components/BossDialog";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";

export const MediaFilterTemplate = (props) => {
    console.log(props);

    const { hideFields = false, isPlanningSlot = false, showTemplate = true, disableMediaCategory = false, isManageContent = false, closeFilterPopup, isNonLinearFileds = false, filterDataItem, isFilterCleared, editMode = false, mediaCategorySID = null } = props;

    const lang = useTranslation();
    const blankDataItem = {
        Genres: [],
        PGRating: [],
        MediaCategory: [],
        Exhibitions: { ...OPERATORS[0], Condition: 0 },
        ReleaseYear: '',
        Language: [],
        Channel: [],
        CastName: '',
        CastType: {},
        isLive: false,
        CreatedFrom: '',
        CreatedTo: '',
        MinimumDaysGapInPrimaryEvent: '',
        publishingStartDate: '',
        publishingEndDate: '',
        isNonLinear: false,
        platforms: [],
        region: [],
        contentCategory: [],
        Provider: [],
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);

    const [genre, setGenre] = useState([]);
    const [mediaCategory, setMediaCategorys] = useState([]);
    const [language, setLanguage] = useState([]);
    const [castType, setCastType] = useState([]);
    const [pgRating, setPGRating] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [regions, setRegions] = useState([]);
    const [providers, setProviders] = useState([]);
    const [contentCategories, setContentCategories] = useState([]);
    const [channels, setChannels] = useState([]);
    const [vodTypes, setVodTypes] = useState([]);
    const [templateName, setTemplateName] = useState('');

    useEffect(() => {
        loadCombo();
    }, [filterDataItem]);

    const loadCombo = async () => {
        let templateRes = await API.getDataLookup(ENTITYNAME.PlanningFilterTemplate, { sort: { Name: 1 } });
        let filteredTemplate = [...templateRes.data]
        if (mediaCategorySID) {
            filteredTemplate = filteredTemplate.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == mediaCategorySID))
        }
        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } });
        let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == true && item.SID != MEDIACATEGORIES.Series);
        if (isPlanningSlot) {
            let planningSlotMediaCategory = filteredMediaCategory.filter(x => x.SID == MEDIACATEGORIES.Movie || x.SID == MEDIACATEGORIES.Program)
            setMediaCategorys(planningSlotMediaCategory);
            filteredTemplate = filteredTemplate.filter((templateItem) =>
            planningSlotMediaCategory.some((categoryItem) =>
                    templateItem.FilterModel.MediaCategory.some((mediaCategory) => mediaCategory.SID === categoryItem.SID)
            ));
        } else if (isManageContent) {
            setMediaCategorys(mediaCategory.data);
        } else {
            setMediaCategorys(filteredMediaCategory);
        }
        setTemplates(filteredTemplate);
        let genres = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        setGenre(genres.data);
        let language = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguage(language.data);
        let castType = await API.getDataLookup(ENTITYNAME.CastType, { sort: { Description: 1 } });
        setCastType(castType.data);
        let pGRating = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        setPGRating(pGRating.data);
        let channelRes = await API.getValidChannels(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channelRes.data);
        let platformRes = await API.getDataLookup(ENTITYNAME.Platform);
        setPlatforms(platformRes.data);
        let regionRes = await API.getDataLookup(ENTITYNAME.Region);
        setRegions(regionRes.data);
        let contentCategoryRes = await API.getDataLookup(ENTITYNAME.ContentCategory);
        setContentCategories(contentCategoryRes.data);
        let vodTypesRes = await API.getDataLookup(ENTITYNAME.VODType);
        setVodTypes(vodTypesRes.data);
        let providerRes = await API.getDataLookup(ENTITYNAME.OttProvider, { query: ["Archive", "!=", true] });
        setProviders(providerRes.data);
        let finalData = {
            ...blankDataItem, 
            ...filterDataItem,
        }
        if(disableMediaCategory){
            finalData.MediaCategory =  mediaCategory.data.filter((x) => x.SID == mediaCategorySID)
        }
        setDataItem(finalData);
        (props?.selectedTemplate && Object.keys(props?.selectedTemplate)?.length > 0) && setSelectedTemplate(props?.selectedTemplate);
    }

    const onChange = (e) => {
        if (e.target.value) {
            isFilterCleared && isFilterCleared(true);
        }
        if (e.target.name == 'Template') {
            setDataItem(e.target.value.FilterModel);
            setSelectedTemplate(e.target.value);
        } else if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
        } else if (e.target.name == 'isLive') {
            setDataItem({ ...dataItem, isLive: !dataItem.isLive })
        } else if (e.target.name == 'Condition') {
            setDataItem({ ...dataItem, Exhibitions: { ...dataItem.Exhibitions, [e.target.name]: e.target.value } });
        } else if (e.target.name == 'isNonLinear') {
            setDataItem({ ...dataItem, isNonLinear: !dataItem.isNonLinear });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onSearchClick = async () => {

        if (dataItem.ReleaseYear && dataItem.ReleaseYear.toString().length != 4) {
            toast.error(`${lang.please_enter_valid_release_year_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let finalData = DataHelper.savePlanningFilterTemplate(dataItem);
        if (saveAsTemplate) {
            await onConfirmSaveTemplate(finalData);
        }
        console.log(saveAsTemplate);
        !saveAsTemplate && props.setSelectedTemplate(selectedTemplate);
        props.toGetFilterDataItem && props.toGetFilterDataItem(finalData);
        props.closeFilterPopup && props.closeFilterPopup();

    }

    const onConfirmSaveTemplate = async (shortData) => {

        if (templateName.length < 0) {
            toast.error(`${lang.please_enter_template_name_to_save_template_planningfilter_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            let saveData = {
                SID: 0,
                user_id: utility.getValue(LOCALSTORAGE_KEY.user_id),
                Name: templateName,
                FilterModel: shortData
            }
            let res = await API.saveData(ENTITYNAME.PlanningFilterTemplate, saveData);
            if (!res.success) return {};
            utility.deleteLocalStorageItem(ENTITYNAME.PlanningFilterTemplate);
            setDataItem(res.data.FilterModel);
            setSelectedTemplate(res.data);
            props.setSelectedTemplate && props.setSelectedTemplate(res.data);
            setTemplates([...templates, res.data]);
            props.setTemplates && props.setTemplates([...templates, res.data]);
            setSaveAsTemplate(false);
            return res.data;
        }
    }

    const onClear = () => {
        if (disableMediaCategory) {
            setDataItem({ ...blankDataItem, MediaCategory: mediaCategory.filter((x) => x.SID == mediaCategorySID) });
        } else {
            setDataItem(blankDataItem);
        }
        setSelectedTemplate({});
        setSaveAsTemplate(false);
        setTemplateName('');
        props.onClear && props.onClear();
        props.setSelectedTemplate && props.setSelectedTemplate({});
        isFilterCleared && isFilterCleared(false);
    }

    const deleteSelectedTemplate = async () => {
        await API.deleteData(ENTITYNAME.PlanningFilterTemplate, selectedTemplate?._id , "_id");
        utility.deleteLocalStorageItem(ENTITYNAME.PlanningFilterTemplate);
        if (disableMediaCategory) {
            setDataItem({ ...blankDataItem, MediaCategory: mediaCategory.filter((x) => x.SID == mediaCategorySID) });
        } else {
            setDataItem(blankDataItem);
        }
        setTemplates(templates?.length > 0 ? templates?.filter(x=>x?._id !=  selectedTemplate?._id) : []);
        setSelectedTemplate({});
    }

    const saveSelectedTemplate = async () => {
        let finalData = DataHelper.savePlanningFilterTemplate(dataItem) ?? {};
        console.log(finalData);
        setDataItem(finalData);
        let updateDataItem = finalData
        delete updateDataItem.Condition;

        let updatedTemplate = {
            ...selectedTemplate,
            FilterModel : updateDataItem
        }

        const res = await API.saveData(ENTITYNAME.PlanningFilterTemplate, updatedTemplate);
        console.log(res);
        utility.deleteLocalStorageItem(ENTITYNAME.PlanningFilterTemplate);
        setSelectedTemplate(res.data);
    }

    return (
        <>
            <BossDialog
                title={lang.search_content_dialog_header}
                width={"600px"}
                onClose={closeFilterPopup}
            >
                <div className="row">
                    <div className="col-12">
                        <ActionButton title={props.saveButtonName ?? lang.search_button_tooltip} name={props.saveButtonName ?? lang.search_button_text} onClick={onSearchClick} />
                        <ActionButton title={lang.clear_button_tooltip} name={lang.clear_button_text} btnColor={"danger"} onClick={onClear} />
                    </div>
                </div>
                <div className='row mt-3' style={{ marginLeft: "-02px", width: "570px" }}>
                    <div className="col-12">
                        {showTemplate && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.template_label} : </label>
                            </div>
                            <div className={(selectedTemplate && Object.keys(selectedTemplate)?.length > 0) ? "col-6" :"col-8"}>
                                <div className="form-group">
                                    <DropDownList
                                        data={templates}
                                        name={"Template"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={selectedTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                {(selectedTemplate && Object.keys(selectedTemplate)?.length > 0) && <button className={`btn btn-default btn-circle btn-my`} type="button" title={lang.save_button_tooltip} onClick={saveSelectedTemplate} style={{ marginLeft: "-15px" }}>
                                    <i className="fa fa-floppy-disk" aria-hidden="true" ></i>
                                </button>}
                                {(selectedTemplate && Object.keys(selectedTemplate)?.length > 0) && <button className={`btn btn-default btn-circle btn-my`} type="button" title={lang.delete_button_tooltip} onClick={()=> ConfirmDeleteAlert(()=> {deleteSelectedTemplate(), ()=>{}})} style={{ marginLeft: "5px" }}>
                                    <i className="fa fa-trash" aria-hidden="true" ></i>
                                </button>}
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.media_category_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={mediaCategory}
                                        name="MediaCategory"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.MediaCategory}
                                        onChange={onChange}
                                        disabled={props.disableMediaCategory ?? false}
                                    />
                                </div>
                            </div>
                        </div>
                        {(!editMode || !selectedTemplate || Object.keys(selectedTemplate)?.length == 0) && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name="SaveAsTemplate"
                                        type="checkbox"
                                        value={saveAsTemplate}
                                        onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                        checked={saveAsTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        {saveAsTemplate && <div className="row">
                            <div className="col-4 mt-2">
                                <label htmlFor="TabView">{lang.template_name_label} * : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="templateName"
                                        value={templateName}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        {isNonLinearFileds && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.non_linear_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name="isNonLinear"
                                        type="checkbox"
                                        value={dataItem.isNonLinear}
                                        checked={dataItem.isNonLinear}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4 mt-3">
                                <label htmlFor="TabView">{(dataItem?.isNonLinear && isNonLinearFileds) ? lang.non_linear_publishing_label : lang.publishing_label} :</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.start_date_label} :</label>
                                <div className="form-group">
                                    <input
                                        name="publishingStartDate"
                                        className="form-control form-control-sm"
                                        type="date"
                                        value={dataItem.publishingStartDate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.end_Date_label} :</label>
                                <div className="form-group">
                                    <input
                                        name="publishingEndDate"
                                        className="form-control form-control-sm"
                                        type="date"
                                        value={dataItem.publishingEndDate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {(dataItem?.isNonLinear && isNonLinearFileds) ? <>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.vod_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: 'white' }}
                                            data={vodTypes}
                                            name={"VodTypes"}
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.VodTypes}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.provider_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: 'white' }}
                                            data={providers}
                                            name="Provider"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Provider}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.platform_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: 'white' }}
                                            data={platforms}
                                            name="platforms"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.platforms}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.region_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: 'white' }}
                                            data={regions}
                                            name="region"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.region}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </> :
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.channel_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            data={channels}
                                            name="Channel"
                                            textField="FullChannelName"
                                            dataItemKey="_id"
                                            value={dataItem.Channel}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.genre_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={genre}
                                        name="Genres"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Genres}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.language_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={language}
                                        name="Language"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Language}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.pg_rating_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={pgRating}
                                        name="PGRating"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.PGRating}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.content_category_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MyMultiSelect
                                        data={contentCategories}
                                        name="contentCategory"
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={dataItem.contentCategory}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {!hideFields && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.exhibition_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <DropDownList
                                        data={OPERATORS}
                                        name="Exhibitions"
                                        textField="Description"
                                        dataItemKey="ID"
                                        value={dataItem.Exhibitions}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name="Condition"
                                        value={dataItem.Exhibitions.Condition}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.cast_and_crew_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6 form-group">
                                        <input type="sting" className="form-control form-control-sm" name="CastName" value={dataItem.CastName} onChange={onChange} />
                                    </div>
                                    <div className="col-6 form-group">
                                        <DropDownList
                                            data={castType}
                                            name="CastType"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.CastType}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.release_year_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        name="ReleaseYear"
                                        value={dataItem.ReleaseYear}
                                        onChange={onChange}
                                    />
                                </div></div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.is_live_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name="isLive"
                                        type="checkbox"
                                        value={dataItem.isLive}
                                        checked={dataItem.isLive}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {isPlanningSlot && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.minimum_gap_in_primary_event_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <input
                                        name="MinimumDaysGapInPrimaryEvent"
                                        className="form-control form-control-sm"
                                        type="number"
                                        value={dataItem.MinimumDaysGapInPrimaryEvent}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4 mt-3">
                                <label htmlFor="TabView">{lang.created_date_label} :</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.from_label} :</label>
                                <div className="form-group">
                                    <input
                                        name="CreatedFrom"
                                        className="form-control form-control-sm"
                                        type="date"
                                        value={dataItem.CreatedFrom}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.to_label} :</label>
                                <div className="form-group">
                                    <input
                                        name="CreatedTo"
                                        className="form-control form-control-sm"
                                        type="date"
                                        value={dataItem.CreatedTo}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </BossDialog>

        </>
    );
};
