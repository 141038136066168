import { Popup } from "@progress/kendo-react-popup";
import { useRef, useState } from "react";
import AuditLog from "./AuditLog";

const Avatar = (props) => {
   

    const anchor = useRef();
    const [show, setShow] = useState(false);

    return <><button ref={anchor} onClick={() => setShow(!show)} title={props.data?.user?.name} style={{ margin: "3px 02px", fontWeight: "500",color: 'white', backgroundColor: props.data.user?.color ?? "white" }} className="btn btn-circle btn-sm" >
        {props.data?.user?.name?.toUpperCase().charAt(0)}
    </button>
        <Popup
            anchor={anchor.current}
            show={show}
            popupClass={'popup-content'}
        >
            <AuditLog module={props.data.module} closePopUp={()=>setShow(false)}/>
        </Popup>
    </>
}

export default Avatar;