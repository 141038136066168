/* eslint-disable */
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { SCHEDULETYPE } from "../../../constant/constant";

export const SegmentCountCell  = (props) => {

    let value = props?.dataItem?.SegmentCount ?? 0;
    let style = {
      ...props.style,
      backgroundColor: props.style.background,
    }

    let newStyle = {};

    newStyle = {
      ...props.style,
      backgroundColor: value == 0 ? 'red' : props.style.background,
      display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
      border: value == 0 ? '0.05em solid #9a9c92' : 'none', borderRadius: '4px',
      textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
      fontWeight: props.titleFontWeight ? props.titleFontWeight : "normal",
      fontSize: props.titleFontSize ? props.titleFontSize : "13px",
      verticalAlign: props.titleVerticalAlignment ? props.titleVerticalAlignment : "center",
      fontFamily: props.titleFontFamily ? props.titleFontFamily : "Roboto",
      width: "50%"
    }

    return <td
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...useTableKeyboardNavigation(props.id)}
      style={style}
    >
     {props?.dataItem.Type == SCHEDULETYPE.Header ? <span style={newStyle}>
      {value}
      </span> : <></>}
    </td>;
  };