import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../../components/EditPageHeader'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { PLAYLIST_SCHEDULE_TYPE } from '../../../framework/constant/constant'
import { utility } from '../../../framework/utility/utilityProvider'
import { PlaylistHelper } from './PaylistHelper'
import { useTranslation } from '../../../locale/useTranslation'
import moment from 'moment'
import BossDialog from '../../../components/BossDialog'

const SchedulePlaylistPopup = (props) => {

    const lang = useTranslation();

    const [currentPlaylistItem, setCurrentPlaylistItem] = useState({});
    const [scheduleDataItem, setScheduleDataItem] = useState({
        ScheduleType: PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover,
        StartDate: null, // moment(new Date()).format("YYYY-MM-DD"),
        EndDate: null, //moment(new Date()).add(1, "days").format("YYYY-MM-DD"),
        isNoEndDate: true
    });

    useEffect(() => {

        setCurrentPlaylistItem(props.playlistItem);
        let scheduleItem = {
            ...props.playlistItem?.Schedule,
            ScheduleType : props.playlistItem?.Schedule?.ScheduleType ?? PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover,
            StartDate: props.playlistItem?.Schedule?.StartDate ? moment(new Date(props.playlistItem?.Schedule?.StartDate)).utc().format('YYYY-MM-DDTHH:mm') : "",
            EndDate: props.playlistItem?.Schedule?.EndDate ? moment(new Date(props.playlistItem?.Schedule?.EndDate)).utc().format('YYYY-MM-DDTHH:mm') : "",
        }
        setScheduleDataItem(scheduleItem ?? scheduleDataItem)

    }, [props.playlistItem])

    const onScheduleDataItemChange = (e) => {
        console.log(e);
        
        if (e.target.name == "isNoEndDate") {
            setScheduleDataItem({
                ...scheduleDataItem,
                isNoEndDate: !scheduleDataItem.isNoEndDate, EndDate: !scheduleDataItem.isNoEndDate ? '' : scheduleDataItem.EndDate
            });
        } else if (e.target.name == "ScheduleType") {
            let item = { ...scheduleDataItem, ScheduleType: e.target.value._id };
            if (e.target.value._id == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover) {
                item = { ...item, StartDate: '', EndDate: '', isNoEndDate: true };
            } else {
                item = { 
                    ...item,
                    StartDate: currentPlaylistItem?.Schedule?.StartDate ? moment(new Date(currentPlaylistItem?.Schedule?.StartDate)).utc().format('YYYY-MM-DDT00:00') : moment(new Date()).utc().format('YYYY-MM-DDT00:00'), 
                    EndDate: currentPlaylistItem?.Schedule?.EndDate ? moment(new Date(currentPlaylistItem?.Schedule?.EndDate)).utc().format('YYYY-MM-DDT23:59') : moment(new Date()).add(1, 'd').utc().format('YYYY-MM-DDT23:59'), 
                    isNoEndDate: false 
                };
                console.log(item)
            }
            setScheduleDataItem(item);
        } else {
            setScheduleDataItem({ ...scheduleDataItem, [e.target.name]: e.target.value });
        }
    }

    const handleSchedulePlaylist = async () => {

        let res = await PlaylistHelper.handleSchedulePlaylist(currentPlaylistItem, scheduleDataItem);
        if (res.success) {
            console.log(res)
            props.close();
            props.refresh();

        } else {
            console.log(res);
        }

    }
    return (
        <BossDialog title={"Schedule Playlist"} onClose={props.close} width={"600px"}>
            <div className="row">
                <div className="col-12">
                    <EditPageHeader saveButtonTitle={"Schedule"} showTitle={false} onSubmit={handleSchedulePlaylist} onCancel={props.close} />
                    <div className='row mt-2'>
                        <div className='col-8'>
                            <label>Schedule Type *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={Object.keys(PLAYLIST_SCHEDULE_TYPE).map((key) => ({ _id: PLAYLIST_SCHEDULE_TYPE[key], Description: utility.convertCamelCaseToWords(key) }))}
                                name="ScheduleType"
                                textField="Description"
                                dataItemKey="_id"
                                value={Object.keys(PLAYLIST_SCHEDULE_TYPE).map((key) => ({ _id: PLAYLIST_SCHEDULE_TYPE[key], Description: utility.convertCamelCaseToWords(key) })).find(x => x._id == scheduleDataItem.ScheduleType)}
                                onChange={onScheduleDataItemChange}
                            />
                        </div>
                    </div>
                    {scheduleDataItem.ScheduleType != PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover &&<div className="row mt-2">
                        <div className='col-4'>
                            <label htmlFor="">{lang.start_date_label} *</label>
                            <input type="datetime-local" className="form-control form-control-sm" name="StartDate" value={scheduleDataItem.StartDate} onChange={(e) => onScheduleDataItemChange(e)} disabled={scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover} />
                        </div>
                        <div className='col-4'>
                            <label htmlFor="">{lang.end_Date_label} *</label>
                            <input type="datetime-local" className="form-control form-control-sm" name="EndDate" value={scheduleDataItem.EndDate} onChange={(e) => onScheduleDataItemChange(e)} disabled={scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover || scheduleDataItem.isNoEndDate} />
                        </div>
                        <div className='col-4 mt-4'>
                            <input type={"checkbox"} name={"isNoEndDate"}
                                onChange={onScheduleDataItemChange}
                                value={scheduleDataItem.isNoEndDate}
                                checked={scheduleDataItem.isNoEndDate}
                                disabled={scheduleDataItem.ScheduleType == PLAYLIST_SCHEDULE_TYPE.OnPriorityTakeover}
                            />
                            <label className="ml-1">No End Date</label>
                        </div>
                    </div>}
                </div>
            </div>
        </BossDialog>
    )
}

export default SchedulePlaylistPopup