const ActionButton = (props) => {
    const { isLoading, style, title, name, icon, btnColor, disabled, onClick } = props;

    // Calculate button width based on name length
    // const calculateWidth = () => {
    //     const baseWidth = 50;
    //     const perCharWidth = 4;
    //     return name ? baseWidth + name.length * perCharWidth : baseWidth;
    // };

    return (
        <button
            style={{
                ...style,
                borderRadius: '5px',
                // width: calculateWidth(), // Dynamically set the button width
            }}
            disabled={(isLoading || disabled) ?? false}
            title={title}
            type={props.type ?? "submit"}
            onClick={onClick}
            className={`btn-square btn-${btnColor ?? 'primary'}`}
        >
            {isLoading ? (
                <i className="fa fa-spinner fa-spin"></i> // Loader icon
            ) : (
                <>
                    {icon && <i className={`fa fa-${icon} fa-fw`}></i>}
                    {name && name}
                </>
            )}
        </button>
    );
};

export default ActionButton;
