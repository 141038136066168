/* eslint-disable */
import React, {useEffect, useState} from "react";
import * as API from "../../../framework/API/api";
import {
    ListBox,
    ListBoxToolbar,
    processListBoxData,
  } from "@progress/kendo-react-listbox";
import { ENTITYNAME, LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";

export const PlanningStyle = (props) => {

    const SELECTED_FIELD = "selected";
    const [userPreferenceColumns, setUserPreferenceColumns] = useState({
        Available: [],
        Selected: props.selectedColumns,
    });

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const lang=useTranslation();

    useEffect(() => {
        loadUserPreferenceColumns();  
    },[])

    const loadUserPreferenceColumns = async () => {

        var availableGetData =await API.getFormData(props.entityName);
        var availableData=availableGetData.filter((item)=>item.name!='Title'  && item.name!='BreakNo' && item.name!='Segment_id' && item.name!='MediaEpisode_id' && item.name!='ParentProgramSchedule_id' && item.name!='addBy.name' && item.name!='modBy.name'  && item.name!='addOn' && item.name!='modOn')
        props.selectedColumns.map((selectedColumn) => {
            var toBeRemovedItemIndex = availableData.findIndex((item) => item.name===selectedColumn.name)
            availableData.splice(toBeRemovedItemIndex, 1);
        })

        var finalAvailableColumns = availableData.map((value) => ({...value, [SELECTED_FIELD]: false, _id: null, user_id: user._id}));
        finalAvailableColumns.sort((a, b) => (a.name > b.name) ? 1 : -1);
        var finalSelectedColumns  = props.selectedColumns.map((value) => ({...value, [SELECTED_FIELD]: false}));
        setUserPreferenceColumns({...userPreferenceColumns, Available: finalAvailableColumns, Selected: finalSelectedColumns});
    }

    const handleItemClick = (event, data, connectedData) => {
        setUserPreferenceColumns({
        ...userPreferenceColumns,
        [data]: userPreferenceColumns[data].map((item) => {
            if (item.name === event.dataItem.name) {
            item[SELECTED_FIELD] = !item[SELECTED_FIELD];
            } else if (!event.nativeEvent.ctrlKey) {
            item[SELECTED_FIELD] = false;
            }
            return item;
        }),
        [connectedData]: userPreferenceColumns[connectedData].map((item) => {
            item[SELECTED_FIELD] = false;
            return item;
        }),
        });
    };

    const handleToolBarClick = (e) => {
        let toolName = e.toolName || "";
        let result = processListBoxData(
            userPreferenceColumns.Available,
            userPreferenceColumns.Selected,
        toolName,
        SELECTED_FIELD
        );
        // console.log(result);
        setUserPreferenceColumns({
        ...userPreferenceColumns,
        Available: result.listBoxOneData,
        Selected: result.listBoxTwoData,
        });

        props.updatePlanningPreferenceModel(result.listBoxTwoData)
    };

    return(
        <>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col k-pr-2">
                <h6>{lang.available_columns_label}</h6>
                <ListBox
                    style={{
                    height: 300,
                    width: "100%",
                    }}
                    data={userPreferenceColumns.Available}
                    textField="label"
                    selectedField={SELECTED_FIELD}
                    onItemClick={(e) => handleItemClick(e, "Available", "Selected")}
                    toolbar={() => {
                    return (
                        <ListBoxToolbar
                        tools={[
                            "moveUp",
                            "moveDown",
                            "transferTo",
                            "transferFrom",
                            // "transferAllTo",
                            // "transferAllFrom",
                        // "remove",
                        ]}
                        data={userPreferenceColumns.Available}
                        dataConnected={userPreferenceColumns.Selected}
                        onToolClick={handleToolBarClick}
                        />
                    );
                    }}
                />
                </div>
                <div className="col k-pl-0">
                <h6>{lang.selected_Columns_label}</h6>
                <ListBox
                    style={{
                    height: 300,
                    width: "100%",
                    }}
                    data={userPreferenceColumns.Selected}
                    textField="label"
                    selectedField={SELECTED_FIELD}
                    onItemClick={(e) => handleItemClick(e, "Selected", "Available")}
                />
                </div>
            </div>
        </div>
        <div className="mt-1 mb-2" style={{color:"#1274AC",textAlign:"center", fontSize:"15px"}}>
        {lang.note_preference}
        </div>
        </>
    )
}