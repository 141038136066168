import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { memo, useEffect, useState } from "react";
import * as API from '../framework/API/api';
import { ENTITYNAME } from "../framework/constant/constant";
import moment from "moment";
import { toast } from "react-toastify";
import { DateTimeCell, LocalDateTimeColumn } from "../framework/forms/helpercomponents/CustomGridCells/DateCell";
import BackRoundButton from "../framework/forms/helpercomponents/buttons/BackRoundButton";
import { useTranslation } from "../locale/useTranslation";
import RoundButton from "../framework/forms/helpercomponents/buttons/RoundButton";
import { AuditLogResponse } from "../modules/Audit Log/AuditLogResponse";

const AuditLog = ({ module, closePopUp }) => {

    const [gridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showResponseOpen, setShowResponseOpen] = useState(false);
    const [newValue, setNewValue] = useState({});
    const [oldValue, setOldValue] = useState({});
    const [detailsValue, setDetailsValue] = useState({});

    const lang = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let date = parseInt(moment().subtract(2, 'd').utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("x"));
        let res = await API.getData(ENTITYNAME.UserActivityLogs, {
            query: [
                ["EntryDateTime", '>=', date],
                ['Module', '=', module]
            ],
            sort: { EntryDateTime: -1 }
        });
        setLoading(false);
        if (res.success) {
            console.log(res.data);
            setGridData(res.data);
        } else {
            toast.error(res.message);
        }
    }

    const handleShowResponse = async (item) => {

        let getOldData = await API.getData(ENTITYNAME.UserActivityLogs, {
            query: [
                ["Entity_id", "=", item.Entity_id],
                ["EntryDateTime", '<', item.EntryDateTime],
                ['Module', '=', item.Module]
            ],
            sort: { EntryDateTime: -1 },
            page: 1,
            perPage: 1,
        });

        setNewValue(item?.Value ? item?.Value : {});
        setOldValue(getOldData.data.length != 0 ? getOldData.data[0]?.Value : {});
        setDetailsValue({
            EntryDateTime: moment(item?.EntryDateTime).format('YYYY-MM-DD HH:mm:ss'),
            User: item?.User?.name,
            Event: item?.Event
        });
        setShowResponseOpen(true);

    }

    const RoundedButton = (props) => <RoundButton icon={"eye"} style={{ marginLeft: '20px', marginTop: '5px' }} title={lang.show_response_label} onClick={() => handleShowResponse(props.dataItem)} />

    const onCloseResponse = () => {
        setShowResponseOpen(false);
    }

    return <div style={{ height: "70vh", width: "50vw", border: "2px solid #2C3333" }}>
        {/* loading */}
        {loading ?
            <div className="ml-1" style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div>
            :
            // grid data
            <div style={{ height: "100%" }}>
                <>
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{lang.audit_log_label}</span>
                        <span className="float-right mr-1" ><BackRoundButton title={lang.close_button_tooltip} icon='xmark' onClick={closePopUp} /></span>
                    </h1>
                </>
                <Grid
                    style={{ height: '85%' }}
                    data={gridData}
                    resizable={true}
                >
                    <GridColumn field="EntryDateTime" title={lang.entry_date_and_time_column} cell={LocalDateTimeColumn} width={'160px'} />
                    <GridColumn field="Value.mediaEpisode.Title" title={lang.title_column} width={'160px'} />
                    <GridColumn field="Value.Channel.FullChannelName" title={lang.channel_column} width={'160px'} />
                    <GridColumn field="Event" title={lang.event_column} width={'200px'} />
                    <GridColumn field="Message" title={lang.message_column} width={'160px'} />
                    <GridColumn field="User.name" title={lang.user_column} width={'150px'} />
                    <GridColumn field="Value.SlotDateTime" title={lang.slot_date_time_column} cell={DateTimeCell} width={'160px'} />
                    <GridColumn
                        width={"100px"}
                        cell={RoundedButton}
                        title={lang.show_details_column}
                    />
                </Grid>
            </div>
        }
        {showResponseOpen && <AuditLogResponse details={detailsValue} oldValue={oldValue} newValue={newValue} onClose={() => onCloseResponse()} />}
    </div>
}

export default memo(AuditLog);