/* eslint-disable */
import React, { useState } from "react";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

export const AnalyticsProviderEditForm = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        _id: props.item._id ?? null,
        SID: props.item.SID ?? 0,
        ProviderName: props.item.copy ? "Copy of " + props.item.ProviderName : props.item.ProviderName ?? "",
        ApiKey: props.item.ApiKey ?? "",
        Archive: props.item.Archive ?? false,
    }
    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.ProviderName == undefined || dataItem.ProviderName == "") {
            toast.error(lang.please_enter_provider_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ApiKey == undefined || dataItem.ApiKey == "") {
            toast.error(lang.please_enter_api_key_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        let localData = {
            _id: dataItem._id,
            SID: dataItem.SID,
            ProviderName: dataItem.ProviderName,
            ApiKey: dataItem.ApiKey,
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["ProviderName", "=", dataItem.ProviderName]]
        }

        let res = await API.saveData(ENTITYNAME.AnalyticsProviders, localData);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.ANALYTICS_PROVIDER, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.AnalyticsProviders);
            props.cancelEdit();
            props.refresh();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <>
            {<BossDialog
                title={props.item.ProviderName && props.item.ProviderName != "" ? dataItem.ProviderName : lang.create_analytics_provider_dialog_header}
                onClose={props.cancelEdit}
                width={"45vw"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.provider_name_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="ProviderName" onChange={onChange} value={dataItem.ProviderName} />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.api_key_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="ApiKey" onChange={onChange} value={dataItem.ApiKey} />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col'>
                                <input type="checkbox" name="Archive" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange}/>
                                <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                            </div>
                        </div>

                    </div>
                </div>
            </BossDialog>}
        </>
    )
}