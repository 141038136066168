/* eslint-disable */
import React, { memo, useEffect, useState } from 'react'
import {MultiSelect } from "@progress/kendo-react-dropdowns";
import {DAYS, ENTITYNAME, MEDIACATEGORIES, MEDIACATEGORYTYPES } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from '../../locale/useTranslation';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import moment from 'moment';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import BossDialog from '../../components/BossDialog';

export const Recursive = memo((props) => {
    const lang=useTranslation();
    //const frmDate = new Date(props.selectedAppointment.SlotDateTime);

    const [dataItem, setDataItem] = useState({
        FromDate:moment(new Date(props.selectedAppointment.SlotDateTime)).format("YYYY-MM-DD"),
        ToDate:"",
        ExcludeFromDate:"",
        ExcludeToDate:"",
        EpisodeRange:"",
        ExcludeDays:false,
        ExclusionDays:[],
        LoopTillEndDate:false,
        TwoEpisodePerDay:false,
        RepeatPattern : { Name : "" },
        ProgramGroup : { Name : "" }
    });
    const [openRepeatPatternSelection,setOpenRepeatPatternSelection] = useState(false);
    const [openProgramGroupSelection,setOpenProgramGroupSelection] = useState(false);
    const [selectAllDays, setSelectAllDays] = useState(false);

    useEffect(() => {},[]);   

    const onChange = (e) => { 

        if(e.target.name == "LoopTillEndDate")
        {
            setDataItem({ ...dataItem, LoopTillEndDate: !dataItem.LoopTillEndDate });
        }
        else if(e.target.name == "TwoEpisodePerDay")
        {
            setDataItem({ ...dataItem, TwoEpisodePerDay: !dataItem.TwoEpisodePerDay });
        }
        else if(e.target.name == "ExcludeDays")
        {
            setDataItem({ ...dataItem, ExcludeDays: !dataItem.ExcludeDays });
        }
        else if(e.target.name == "ExclusionDays"){
            if(e.target.value.length == DAYS.length){
              setDataItem({...dataItem, ExclusionDays: DAYS});
              setSelectAllDays(true);
            }
            else{
              setDataItem({...dataItem, ExclusionDays: e.target.value});
              setSelectAllDays(false);
            }
        }
        else
        {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onSelectRepeatPattern = () => {
        setOpenRepeatPatternSelection(true)
    }
    
    const onSetSelectedRepeatPattern = (dataList) => {
        setDataItem({...dataItem,RepeatPattern : dataList[0]})
    }

    const onSetSelectedProgramGroup = (dataList) => {
        setDataItem({...dataItem,EpisodeRange : "",ProgramGroup : dataList[0]})
    }

    const onApplyRecursion = () => {

        if(dataItem.ToDate == ""){
            toast.error(`${lang.to_date_is_mandatory_recursive_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }
        if(new Date(dataItem.ToDate) < new Date(props.selectedAppointment.SlotDateTime)){
            toast.error(`${lang.to_date_should_be_greater_than_base_program_start_date_recursive_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return
        }
        
        if(!(props.selectedAppointment.mediaEpisode?.MediaCategory?.SID == MEDIACATEGORIES.Episode || props.selectedAppointment.mediaEpisode?.MediaCategoryType?.SID == MEDIACATEGORYTYPES.ProgramSeries) && Object.keys(dataItem.ProgramGroup).length < 2){
            toast.error(`${lang.program_group_is_mandatory_when_applying_recursive_on_movie_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return

        }
        if(selectAllDays){
            toast.error(lang.all_days_can_not_be_excluded_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        props.onGenerateRecursion(dataItem)

    }

    const borderWithTitle = {position:"relative", fontSize:"12px",
    top:"-0.9em", marginLeft:"0.8em", display:"inline", backgroundColor: "white" };

  const allDaySelect = (e) => {
    if(e.target.checked){
        setDataItem({...dataItem, ExclusionDays: DAYS});
        setSelectAllDays(true);
    }
    else{
        setDataItem({...dataItem, ExclusionDays: []});
        setSelectAllDays(false);
    }
  }
  return (
        <BossDialog
            title={lang.recursive_dialog_header + " - " + props.selectedAppointment.Title}
            onClose={props.closeForm}
            width={"750px"}
        >  
            <div className="row">
                <div style={{marginLeft:"05px"}}>
                    <ActionButton title={lang.apply_button_tooltip} btnColor={"success"} name={lang.apply_button_text} onClick={onApplyRecursion} />
                    <CancelButton onClick={() => props.closeForm(dataItem)} />
                </div>
            </div>    
            <div className='row mt-4'>
                <div className='col-6'>
                    <div style={{ margin:"0px 0px 0px 0px",height: "270px", border: '1px solid #c4c2c2',borderRadius: '5px!important'}}>                
                        <div style={borderWithTitle}>&nbsp; {lang.inclusion_range_label} &nbsp;</div>
                        <div className='row mr-1 ml-1'>  
                            <div className='col-6'>                     
                                <div className="form-group">    
                                    <label htmlFor="">{lang.from_date_label}</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="FromDate"
                                        disabled={true}
                                        defaultValue={dataItem.FromDate}
                                        value={dataItem.FromDate}
                                        onChange={onChange}
                                    />              
                                </div>
                            </div>
                            <div className='col-6'>   
                                <div className="form-group">  
                                    <label htmlFor="">{lang.to_date_label}</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="ToDate"
                                        value={dataItem.ToDate}
                                        onChange={onChange}
                                    />              
                                </div>
                            </div> 
                        </div>
                        <div className='row mr-1 ml-1'>  
                            <div className='col-6'>
                                <label htmlFor="">{lang.episode_range_label}</label>
                                <input type="text" className="form-control form-control-sm" name="EpisodeRange" value={dataItem.EpisodeRange || ""} onChange={(e) => onChange(e)} disabled={Object.keys(dataItem.ProgramGroup).length > 1} />
                                <p className="text-muted" style={{ fontSize: "9px",margin : '0px' }}>{lang.example_label}: (1-3)</p>
                            </div>
                            <div className='col-6' style={{ marginTop : '25px'}}>  
                                <input name={"TwoEpisodePerDay"} onChange={onChange}  type={"Checkbox"} value={dataItem.TwoEpisodePerDay} style={{width: '15px',height : '15px'}}/>
                                <span htmlFor="TabView" className="pl-2" style={{}}>{lang.two_epiosde_per_day_label}</span> 
                                <p className="text-muted" style={{ fontSize: "9px",margin : '0px' }}>Note - {lang.two_epiosde_per_day_label}</p>  
                            </div>                    
                        </div>
                        <div className='row mr-1 ml-1 mt-1'>
                            <div className='col'>  
                                <input name={"LoopTillEndDate"} onChange={onChange}  type={"Checkbox"} value={dataItem.LoopTillEndDate} style={{width: '15px',height : '15px'}}/>
                                <span htmlFor="TabView" className="pl-2" style={{}}>Loop</span> 
                                <p className="text-muted" style={{ fontSize: "9px",margin : '0px' }}>{lang.loop_till_selected_end_date}</p>  
                            </div>  
                        </div>
                        <div className='row mr-1 ml-1 mt-1'>  
                            <div className="col-8" style={{ padding : "0px 0px 0px 16px" }}>
                                <label htmlFor="TabView">{lang.program_group_sub_menu}</label>
                                <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.ProgramGroup.Name} onChange={()=>""} disabled={true} />
                            </div>
                            <div className="col" style={{ padding : "0px 0px 0px 0px" }}> 
                                <ActionButton 
                                    style={{marginTop: "18px", height:"30px"}} 
                                    btnColor={'success'} 
                                    title={lang.select_button_tooltip} 
                                    name={"..."} 
                                    onClick={() => { setOpenProgramGroupSelection(true)}}
                                />
                                <ActionButton style={{marginTop: "18px", height:"30px"}} btnColor={'danger'} title={lang.select_button_tooltip} name={""} icon= {'xmark'} onClick={() => setDataItem({...dataItem,ProgramGroup : { Name : ""}})} />
                            </div> 
                        </div>
                        <p className="text-muted" style={{ fontSize: "9px", marginLeft: "20px", marginTop: "-3px" }}>{`Note - If Program Group is selected Episode Range should be disabled `}</p>  
                    </div>
                </div>                
                <div className='col-6'>
                    <div style={{ margin:"0px 0px 0px 0px",height: "270px", border: '1px solid #c4c2c2',borderRadius: '5px!important'}}>                
                        <div style={borderWithTitle}>&nbsp; {lang.exclusion_range_label} &nbsp;</div>
                        <div className='row mb-1 mr-1 ml-1'> 
                            <div className='col-8'>  
                                <input name={"ExcludeDays"}  type={"Checkbox"} value={dataItem.ExcludeDays}
                                onChange={onChange}
                                    style={{ textAlign: "left",marginRight: "5px"}}/>
                                <span style={{fontWeight: "normal",fontSize: "11px"}}>
                                    {lang.exclude_days_label}
                                </span>  
                            </div>                        
                        </div> 
                        <div className='row mr-1 ml-1'>  
                            <div className='col-6'>                     
                                <div className="form-group">    
                                    <label htmlFor="">{lang.from_date_label}</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="ExcludeFromDate"
                                        value={dataItem.ExcludeFromDate}
                                        onChange={onChange}
                                        disabled={!dataItem.ExcludeDays}
                                    />              
                                </div>
                            </div>
                            <div className='col-6'>   
                                <div className="form-group">  
                                    <label htmlFor="">{lang.to_date_label}</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="ExcludeToDate"
                                        value={dataItem.ExcludeToDate}
                                        onChange={onChange}
                                        disabled={!dataItem.ExcludeDays}
                                    />              
                                </div> 
                            </div>
                        </div>                                
                        <div className='row mr-1 ml-1'>  
                            <div className='col-12'>   
                                <div className="form-group">  
                                    <span className="float-right">{lang.select_all_label}
                                        <input 
                                        type={"checkbox"} 
                                        name={"SelectAll"}
                                        className="ml-1"
                                        onChange={allDaySelect}
                                        checked={selectAllDays}
                                        value={selectAllDays}
                                        disabled={!dataItem.ExcludeDays}
                                        />
                                    </span>
                                    <label htmlFor="TabView">{lang.days_label}</label>     
                                    <MultiSelect
                                        style={{
                                        backgroundColor: !dataItem.ExcludeDays ? '#E9ECEF' :"white",
                                        }}
                                        data={DAYS}
                                        name="ExclusionDays"
                                        textField="Name"
                                        dataItemKey="ID"
                                        value={dataItem.ExclusionDays}
                                        onChange={onChange}
                                        validator={(value) => value ? "" : "Please select the value"}
                                        disabled={!dataItem.ExcludeDays}
                                    /> 
                                </div> 
                            </div>                            
                        </div>               
                    </div>
                </div>                
            </div>
            {<div className='row mt-1'>  
                <div className="col-10" style={{ padding : "0px 0px 0px 16px" }}>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.repeat_pattern_label}</label>
                        <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.RepeatPattern.Name} onChange={()=>""} disabled={true} />
                    </div>
                </div>
                <div className="col-2" style={{ padding : "0px 16px 0px 0px" }}> 
                    <ActionButton style={{marginTop: "18px", height:"30px"}} btnColor={'success'} title={lang.select_button_tooltip} name={"..."} onClick={onSelectRepeatPattern}/>
                    <ActionButton style={{marginTop: "18px", height:"30px"}} btnColor={'danger'} title={lang.select_button_tooltip} name={""} icon= {'xmark'} onClick={() => setDataItem({...dataItem,RepeatPattern : { Name : ""}})}/>   
                </div>         
            </div>}

            {openRepeatPatternSelection && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_repeat_pattern_collection_header} entityname={ENTITYNAME.RepeatPattern} closeForm={() => setOpenRepeatPatternSelection(!openRepeatPatternSelection)} wherestatement = {['Channel.SID','=',props.selectedAppointment.ChannelSID]} setDataList={(dataList) => onSetSelectedRepeatPattern(dataList)} mode={'single'}  width={"50vw"} showCreate={true} showSelfCreated={true} path={`/home/RepeatPattern`}/>}

            {openProgramGroupSelection && (<CollectionSelection
                    title={lang.select_program_group_label}
                    entityname={ENTITYNAME.MediaEpisodeGroup}
                    wherestatement={['MediaCategory.isLong', '=', true]}
                    setDataList={onSetSelectedProgramGroup}
                    mode={"single"}
                    closeForm={() => { setOpenProgramGroupSelection(false) }}
                />)}
  </BossDialog>
  )
});
