import { useState } from "react";
import BossDialog from "../../components/BossDialog";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import * as API from "../../framework/API/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePasswordForm = (props) => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const isClearLocalStorage = props?.isClearLocalStorage ?? false;
    const isRouteHomePage = props?.isRouteHomePage ?? false;

    const blankDataItem = {
        oldPassword: "",
        newPassword: "",
        ConfirmPassword: ""
    }

    const [dataItem, setDataItem] = useState(blankDataItem)

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.oldPassword == undefined || dataItem.oldPassword == "") {
            toast.error(`${lang.please_enter_old_password_users_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.newPassword == undefined || dataItem.newPassword == "") {
            toast.error(`${lang.please_enter_new_password_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ConfirmPassword == undefined || dataItem.ConfirmPassword == "") {
            toast.error(`${lang.please_enter_confirm_password_users_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.newPassword !== dataItem.ConfirmPassword) {
            toast.error(`${lang.confirm_password_do_not_match_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleChangePassword = async () => {
        if (!isValid()) return;
        let payload = {
            data: {
                user_id: props?.data?._id,
                oldPassword: dataItem?.oldPassword?.toString(),
                newPassword: dataItem?.newPassword
            }
        }
        console.log(payload);
        let res = await API.changeOldPassword(payload);
        console.log(res);
        if (res.success) {
            isClearLocalStorage && localStorage.clear();
            isRouteHomePage && navigate('/');
            toast.success(lang.successfully_change_password_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            onCloseForm();
        } else {
            toast.error(`${res.message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onCloseForm = () => {
        props.closeForm();
        setDataItem(blankDataItem);
    }

    return (
        <BossDialog
            title={lang.change_password_dialog_header}
            onClose={onCloseForm}
            width={"500px"}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader onSubmit={handleChangePassword} showTitle={false} onCancel={onCloseForm} />
                    <div className="form-group mt-2">
                        <label htmlFor="oldPassword">{lang.old_password_label} *</label>
                        <input className="form-control" type="password" name="oldPassword" onChange={onChange} value={dataItem.oldPassword} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="newPassword">{lang.new_password_label} *</label>
                        <input className="form-control" type="password" name="newPassword" onChange={onChange} value={dataItem.newPassword} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmPassword">{lang.confirm_password_label} *</label>
                        <input className="form-control" type="password" name="ConfirmPassword" onChange={onChange} value={dataItem.ConfirmPassword} />
                    </div>
                </div>
            </div>
        </BossDialog>
    )
}

export default ChangePasswordForm;