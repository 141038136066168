/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import BossDialog from '../../components/BossDialog';

function FavouritesEditForm() {

    const lang = useTranslation();
    const navigate = useNavigate();
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    const [channel, setChannel] = useState([]);
    //setUserMenus(user.Module);
    const [userMenus, setUserMenus] = useState(user.Module);
    const [openSelectedMedia, setOpenSelectedMedia] = useState(true);

    const blankDataItem = {
        Name: "",
        Menu: {},
        URL: "",
        User_id: "",
    }

    const [dataItem, setDataItem] = useState(blankDataItem);



    const getUserFavouriteMenus = useCallback(async () => {
        var response = await API.getDataLookup(ENTITYNAME.UserFavouriteMenu, { query: ["User_id", "=", user._id] }, { sort: { Name: 1 } });
        utility.setValue(LOCALSTORAGE_KEY.userfavouritemenu, menus.data);
        utility.setValue(LOCALSTORAGE_KEY.userfavouritemenu, response.data);

        const pageReloadCountData = utility.getValue('PageReloadCount');
        console.log(pageReloadCountData);
        if (pageReloadCountData == undefined) {
            console.log("In menus get");
            var PageReloadCount = 1;
            utility.setValue('PageReloadCount', PageReloadCount);
            //   window.location.reload(false);
        }

    }, []);


    useEffect(() => {
        getUserFavouriteMenus();
    }, [])


    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        console.log(dataItem.Menu);

        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_select_user_favourite_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Menu.Name == undefined || dataItem.Menu.Name == "") {
            toast.error(`${lang.please_select_user_favourite_menu_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                ...dataItem,
                Name: dataItem.Name,
                URL: dataItem.Menu.path,
                User_id: user._id

            }

            ////DLEETING MENU PROPERTY FROM OBJECT BEFORE SAVING IT
            // delete saveData.Menu;
            var res = await API.saveData(ENTITYNAME.UserFavouriteMenu, saveData);
            if (res.success) {

                // utility.deleteLocalStorageItem('invoicecontent');
                utility.deleteLocalStorageItem(ENTITYNAME.UserFavouriteMenu)
                utility.deleteLocalStorageItem('PageReloadCount');
                setOpenSelectedMedia(!openSelectedMedia);
                getUserFavouriteMenus();
                navigate(-1);
                const event = new Event('updateMenu');
                document.dispatchEvent(event);

                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }


    const cancelEdit = () => {
        setOpenSelectedMedia(!openSelectedMedia);
        navigate(-1);
    }



    return (
        <>
            {openSelectedMedia &&
                <BossDialog
                    title={lang.user_favourite_add_button_title}
                    onClose={cancelEdit}
                    width={"440px"}
                    height={"350px"}
                >
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={cancelEdit} showTitle={false} />
                    <Form
                        onSubmit={handleSubmit}
                        initialValues={dataItem}
                        render={(formRenderProps) => (
                            <FormElement>
                                <div className='row mt-2'>
                                    <div className='col-12'>
                                        <div className='row mt-2'>

                                            <div className='col-12'>
                                                <label htmlFor="TabView">{lang.user_favorite_menu_dropdown_label} *</label>
                                                <DropDownList
                                                    style={{ backgroundColor: "white" }}
                                                    data={userMenus}
                                                    name="Menu"
                                                    textField="Name"
                                                    dataItemKey="_id"
                                                    value={dataItem.Menu}
                                                    onChange={onChangeForm}
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-2'>
                                            <div className='col-12'>
                                                <label>{lang.user_favorite_menu_text_label} *</label>
                                                <input
                                                    name={"Name"}
                                                    type="text"
                                                    style={{ border: 'solid lightgrey 1px', height: "35px" }}
                                                    onChange={onChangeForm}
                                                    value={dataItem.Name}
                                                />
                                            </div>
                                        </div>

                                        <div className='row mt-2'>
                                            <div className="col-6">
                                                <Field
                                                    name={"Archive"}
                                                    component={Checkbox}
                                                    label={lang.archive}
                                                    onChange={onChangeForm}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                    <ToastContainer />
                </BossDialog>}
        </>
    )
}
export default FavouritesEditForm;