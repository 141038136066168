import { LANGUAGE, LOCALSTORAGE_KEY } from "../framework/constant/constant";
import { utility } from "../framework/utility/utilityProvider";
import { Arabic } from "./language/Arabic";
import { en_us } from "./language/en_us";
import { hn } from "./language/hn";

export const useTranslation=()=>{
    const lang=utility?.getValue(LOCALSTORAGE_KEY.USER_LANGUAGE)?.ISOCode??LANGUAGE.en_us;
    switch (lang) {
        case LANGUAGE.en_us:
            return en_us();
        case LANGUAGE.hn:
            return hn();
        case LANGUAGE.Arabic:
            return Arabic()
        default:
            return en_us();
    }
}