/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Grid, GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { RepeatPatternDetails } from "./RepeatPatternDetails";
import { toast } from 'react-toastify';
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { utility } from "../../framework/utility/utilityProvider";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

function RepeatPatternEditForm(props) {

    const { SID } = useParams();
    const location = useLocation()
    let disable = location.state.disable ?? false
    const [channel, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [openRepeatPatternDetails, setOpenRepeatPatternDetails] = useState(false);
    const [type, setType] = useState([]);
    const [dataRepeat, setDataRepeat] = useState([])
    const [selectedState, setSelectedState] = useState({});
    const [selectedData, setSelectedData] = useState([]);
    const navigate = useNavigate();
    const lang = useTranslation();

    let blankDataItem = {
        Name: "",
        Channel: {},
        Description: "",
        Data: [],
        Archive: false
    }

    const [dataItem, setDataItem] = useState(blankDataItem)

    const [dataGrid, setDataGrid] = useState(
        gridData.map((dataItem) =>
            Object.assign(
                {
                    selected: false,
                },
                dataItem
            )
        )
    );

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const loadEditData = async () => {
        let res = await API.getEntity(ENTITYNAME.RepeatPattern, parseInt(SID));
        if (res.success) {
            setDataItem({
                ...res.data,
                Name: res.data.SID ? location.state?.copy ? 'Copy of ' + res.data.Name : res.data.Name : res.data.Name,
            })
            setDataGrid(() => res.data.Data)
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const onSelectionChange = React.useCallback((event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
        let dataIds = [...selectedData]
        if (event.dataItem.selected) {
            dataIds = selectedData?.includes(event.dataItem._id) ? selectedData.filter((obj) => obj != event.dataItem._id) : selectedData;
        }
        else {
            dataIds.push(event.dataItem._id)
        }
        setSelectedData(dataIds)
    },
        [selectedState]

    );
    const onHeaderSelectionChange = React.useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
        for (let i = 0; i < event.dataItems.length; i++) {
            if (!event.dataItems[i].selected) {
                setSelectedData(Object.keys(newSelectedState))
            }
            else {
                setSelectedData([])
            }
        }

    }, []);

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (dataGrid.length == 0) {
            toast.error(`${lang.please_attach_atleast_one_media_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onSave = async () => {

        if (isValid()) {
            const saveData = {
                SID: location.state?.copy ? 0 : dataItem.SID,
                Name: dataItem.Name,
                Channel:
                {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName,

                },
                Description: dataItem.Description,
                Data: dataGrid,
                Archive: dataItem.Archive ?? false,
            }
            if (location.state?.copy) {
                delete saveData.SID;
            }
            let res = await API.saveData(ENTITYNAME.RepeatPattern, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.REPEAT_PATTERN, data: res.data, message: res.message };
                API.SaveLogs(logData);
                navigate(-1);
                utility.deleteLocalStorageItem(ENTITYNAME.RepeatPattern);
            }
        }
    }

    const viewPrimaryPattern = () => {
        setOpenRepeatPatternDetails(!openRepeatPatternDetails);
        setType("Primary")
    }

    const viewRepeatPattern = (data) => {
        console.log(data);
        setOpenRepeatPatternDetails(true);
        setType("Repeat")
        setDataRepeat(data);

    }

    const closeRepeatPatternDetails = () => {
        setOpenRepeatPatternDetails(false)
    }

    const getMaximumPrimaryEventsNo = () => {
        console.log(dataGrid)
        let value = 0;
        if (dataGrid.length != 0 && dataGrid[dataGrid.length - 1].PrimaryEventsNo != undefined) {
            value = dataGrid[dataGrid.length - 1].PrimaryEventsNo;
        }
        return value
    }

    const getMaximumRepeatEventsNo = (primaryEventsNo) => {
        let tempMax = dataGrid.filter((obj) => obj.PrimaryEventsNo == primaryEventsNo && obj.RepeatEventsNo > 0)
        return tempMax.length + 1;

    }
    const gridSave = (dataList) => {
        let temp = [...dataList, ...dataGrid]
        let shortedByData = temp.sort((a, b) => (a.PrimaryEventsNo > b.PrimaryEventsNo) ? 1 : (a.PrimaryEventsNo < b.PrimaryEventsNo) ? -1 : 0 && (a.RepeatEventsNo > b.RepeatEventsNo) ? 1 : (a.RepeatEventsNo < b.RepeatEventsNo) ? -1 : 0);
        setDataGrid(shortedByData);
        setDataRepeat(dataList);
    }

    const deleteSelectedValue = (dataItem) => {
        if (dataItem.Type == "Primary") {
            setDataGrid(dataGrid.filter((obj) => dataItem.PrimaryEventsNo != obj.PrimaryEventsNo));
            toast.success(`${lang.primary_delete_succesfully_repeat_pattern_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            setDataGrid(dataGrid.filter((obj) => dataItem._id != obj._id))
            toast.success(`${lang.repeat_delete_succesfully_repeat_pattern_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const MySelectedCommandCell = (props) => (
        <td style={{ padding: "0px" }}><DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedValue(props.dataItem), () => { })} />
            {props.dataItem.RepeatEventsNo == 0 && <RoundButton title={lang.add_repeat_button_tooltip} icon={'circle-o-notch'} onClick={() => { viewRepeatPattern(props.dataItem) }} />}
        </td>
    )

    const deleteAll = () => {
        let data = dataGrid.filter((obj) => !selectedData.includes(obj._id));
        setDataGrid(data);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        setSelectedState({});
        setSelectedData([]);
    }
    const deleteAllConfirmAlert = () => {
        if (selectedData.length == 0) {
            toast.error(`${lang.please_select_atleast_one_repeat_pattern_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        ConfirmDeleteAlert(() => deleteAll(), () => { })
    }

    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={dataItem.Description ? `${lang.repeat_pattern_sub_menu} -> ${dataItem.Description}` : `${lang.create_repeat_pattern_editpage_header}`} onSubmit={onSave} onCancel={() => navigate(-1)} />
                <div className="row mt-2">
                    <div className="col-3">
                        <label>{lang.name_label} *</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name={"Name"}
                            onChange={onChange}
                            value={dataItem.Name}
                            disabled={disable}
                        />
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                                disabled={disable}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 form-group">
                        <label>{lang.description_label} *</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name={"Description"}
                            onChange={onChange}
                            value={dataItem.Description}
                            disabled={disable}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 form-group">
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange}
                            disabled={disable} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-12">
                        <AddRoundButton title={lang.add_primary_pattern_button_tolltip} onClick={viewPrimaryPattern} disabled={disable} />
                        <DeleteRoundButton onClick={deleteAllConfirmAlert} disabled={disable} />
                        <Grid
                            data={dataGrid.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                            style={{ height: "50vh", marginTop: "5px", overflow: "hidden" }}
                        >
                            <Column
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    dataGrid.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <Column cell={MySelectedCommandCell} width="70px" />
                            <Column field="Type" title={lang.type_column} editable={false} />
                            <Column field="PrimaryEventsNo" title={lang.primary_events_no_column} editable={false} sortable={true} />
                            <Column field="RepeatEventsNo" title={lang.repeat_events_no_column} editable={false} sortable={true} />
                            <Column field="Days" title={lang.days_column} editable={false} />
                            <Column field="NominalStart" title={lang.nominal_start_column} cell={TimeCell} editable={false} />

                        </Grid>
                    </div>
                </div>
            </div>
        </div>
        {openRepeatPatternDetails && <RepeatPatternDetails maxRepeatEventsNo={getMaximumRepeatEventsNo} maxPrimaryEventsNo={getMaximumPrimaryEventsNo} dataRepeat={dataRepeat} Type={type} closeForm={closeRepeatPatternDetails} setDataList={gridSave} type={`"Repeat"` ?? "Add"} />}
    </>)
}

export default RepeatPatternEditForm;