export const Arabic = () => {
    return{
        dashboard: "لوحة القيادة" ,
        cms_dashboard: "ج م س",
        content_dashboard: "المحتوى" ,
        sales_dashboard: "المبيعات" ,
        booking_status_dashboard: "حالة الحجز" ,
        booking_summary_dashboard: "ملخص الحجز" ,
        contract_menu: "العقد" ,
        contract_sub_menu: "العقد" ,
        media_management_menu: "إدارة وسائل الإعلام" ,
        series_sub_menu: "سلسلة الوالدين" ,
        season_sub_menu: "سلسلة" ,
        program_sub_menu: "برنامج" ,
        movie_sub_menu: "فيلم" ,
        trailer_sub_menu: "مقطورة" ,
        interstitial_sub_menu: "الخلالي" ,
        commercial_sub_menu: "تجاري" ,
        filler_sub_menu: "حشو" ,
        music_sub_menu: "الموسيقى" ,
        break_sub_menu: "استراحة" ,
        media_episode_group_sub_menu: "مجموعة حلقات الإعلام" ,
        planning_menu: "التخطيط" ,
        planning_sub_menu: "التخطيط" ,
        formats_sub_menu: "التنسيقات" ,
        program_schedule_clash_sub_menu: "اشتباك جدول البرنامج" ,
        scheduling_menu: "الجدولة" ,
        curate_menu: "المنسق" ,
        timeline_sub_menu: "الجدول الزمني" ,
        rails_sub_menu: "القضبان" ,
        pages_sub_menu: "الصفحات" ,
        assets_sub_menu: "الأصول" ,
        tv_preview_sub_menu: "معاينة التلفزيون" ,
        web_preview_sub_menu: "معاينة الويب" ,
        mobile_preview_sub_menu: "معاينة الجوال" ,
        ingestion_menu: "الابتلاع" ,
        posters_sub_menu: "ملصقات" ,
        ingestion_template_sub_menu: "قالب الابتلاع" ,
        partner_ingestion_sub_menu: "ابتلاع الشريك" ,
        jobs_sub_menu: "الوظائف" ,
        sales_menu: "المبيعات" ,
        agency_sub_menu: "وكالة" ,
        client_sub_menu: "العميل" ,
        campaign_sub_menu: "الحملة" ,
        booking_sub_menu: "الحجز" ,
        booking_transfer_sub_menu: "نقل الحجز" ,
        update_booking_line_number_sub_menu: "تحديث رقم خط الحجز",
        sales_team_sub_menu: "فريق المبيعات" ,
        sales_executive_sub_menu: "تنفيذي مبيعات" ,
        reconciliation_menu: "المصالحة",
        as_run_reconciliation_sub_menu: "كما تشغيل المصالحة" ,
        import_planning_sub_menu: "تخطيط الاستيراد" ,
        import_asRun_sub_menu: "استيراد أسرشن" ,
        manage_asRun_sub_menu: "إدارة أسرشن" ,
        export_menu: "تصدير" ,
        epg_sub_menu: "دليل البرامج الإلكتروني" ,
        epg_template_sub_menu: "نموذج دليل البرامج الإلكتروني" ,
        secondary_event_menu: "حدث ثانوي" ,
        prototype_sub_menu: "النموذج الأولي" ,
        styleSheet_sub_menu: "ورقة الأنماط" ,
        default_prototype_sub_menu: "النموذج الأولي الافتراضي" ,
        protoType_source_sub_menu: "مصدر النموذج الأولي" ,
        secondary_event_dynamic_rule_sub_menu: "القاعدة الديناميكية للحدث الثانوي" ,
        billing_menu: "الفوترة" ,
        billing_sub_menu: "الفوترة" ,
        generated_bills_sub_menu: "الفواتير التي تم إنشاؤها" ,
        masters_menu: "الماجستير" ,
        master_menu_title: "ماجستير" ,
        language_sub_menu: "اللغة" ,
        content_sub_menu: "المحتوى" ,
        assets_type_sub_menu: "نوع الأصول" ,
        rails_type_sub_menu: "نوع القضبان" ,
        posters_type_sub_menu: "نوع الملصقات" ,
        users_sub_menu: "المستخدمون" ,
        department_sub_menu: "القسم" ,
        role_sub_menu: "الدور" ,
        content_category_sub_menu: "فئة المحتوى" ,
        region_sub_menu: "المنطقة" ,
        brand_sub_menu: "العلامة التجارية" ,
        product_sub_menu: "المنتج" ,
        bank_account_sub_menu: "حساب مصرفي" ,
        tax_sub_menu: "الضريبة" ,
        licensor_sub_menu: "المرخص" ,
        channel_sub_menu: "القناة" ,
        currency_sub_menu: "العملة" ,
        time_range_sub_menu: "النطاق الزمني" ,
        repeat_pattern_sub_menu: "تكرار النمط" ,
        invoice_content_sub_menu: "محتوى الفاتورة" ,
        segemnt_type_sub_menu: "نوع الشريحة" ,
        variation_sub_menu: "الاختلاف" ,
        break_format_sub_menu: "تنسيق الفاصل" ,
        payment_provider_sub_menu: "مزود الدفع" ,
        ott_period_type_sub_menu: "نوع فترة ياتت" ,
        payment_type_sub_menu: "نوع الدفع" ,
        schedule_constraints_sub_menu: "قيود الجدول الزمني" ,
        monetization_menu: "تحقيق الدخل" ,
        ott_period_sub_menu: "الفترة" ,
        subscriber_sub_menu: "المشترك" ,
        ott_campaign_sub_menu: "الحملة" ,
        coupon_sub_menu: "القسيمة" ,
        data_migration_menu: "ترحيل البيانات" ,
        form_fields_menu: "حقول النموذج" ,
        save_button_tooltip: "حفظ" ,
        save_button_text: "حفظ" ,
        back_button_tooltip: "العودة" ,
        add_button_tooltip: "إضافة" ,
        add_button_text: "إضافة" ,
        add_multiple_button_text: "إضافة متعددة" ,
        add_dialog_header: "إضافة" ,
        new_button_dialog_header: "جديد" ,
        new_title_editpageheader: "جديد" ,
        edit_button_tooltip: "تحرير" ,
        update_button_tooltip: "تحديث" ,
        remove_button_tooltip: "إزالة" ,
        delete_button_tooltip: "حذف" ,
        select_button_tooltip: "تحديد" ,
        select_button_text: "تحديد" ,
        cancel_button_tooltip: "إلغاء" ,
        cancel_button_text: "إلغاء" ,
        preference_button_tooltip: "التفضيل" ,
        options: "خيارات" ,
        refresh_button_tooltip: "تحديث" ,
        archive: "الأرشيف" ,
        ExportBreaksOnly: "فواصل التصدير فقط" ,
        loading_pleasewait: "جاري التحميل يرجى الانتظار ...",
        no_records_found: "لم يتم العثور على سجلات!!" ,
        contract_number_label: "رقم العقد" ,
        platform_type_label: "نوع المنصة" ,
        licensor_label: "المرخص" ,
        payee_label: "المدفوع لأمره" ,
        start_date_label: "تاريخ البدء" ,
        end_Date_label: "تاريخ الانتهاء" ,
        channel_label: "القناة" ,
        platform_label: "منصة" ,
        region_label: "المنطقة" ,
        exhibition_label: "معرض" ,
        repeat_label: "تكرار" ,
        play_time_number_label: "رقم وقت اللعب" ,
        playTime_label: "وقت اللعب" ,
        is_unlimited_label: "غير محدود" ,
        add_media_episode_button_tooltip: "إضافة حلقة إعلامية" ,
        remove_media_episode_button_tooltip: "إزالة الحلقة الإعلامية" ,
        assetId_column: "رقم تعريف مادة العرض",
        title_column: "العنوان" ,
        media_category_column: "فئة الوسائط" ,
        start_date_column: "تاريخ البدء" ,
        end_date_column: "تاريخ الانتهاء" ,
        is_unlimited_column: "غير محدود" ,
        exhibition_column: "معرض" ,
        repeat_column: "تكرار" ,
        play_time_number_column: "رقم وقت اللعب" ,
        playTime_column: "وقت اللعب" ,
        media_library_dialog_header: "مكتبة الوسائط" ,
        summary: "ملخص" ,
        create_series_dialog_header: "إنشاء سلسلة الوالدين" ,
        title_label: "العنوان" ,
        content_label: "المحتوى" ,
        asset_id_label: "معرف مادة العرض",
        description_label: "الوصف" ,
        synopsis_label: "ملخص" ,
        islive_label: "مباشر" ,
        seasons_title: "سلسلة" ,
        series_cardlist_header: "سلسلة" ,
        season_cardlist_header: "الحلقة" ,
        sidefix_season_tooltip: "الموسم" ,
        s_content_label: "عص. المحتوى" ,
        season_no_label: "الموسم رقم" ,
        ep_content_label: "محتوى الحلقة ",
        episodes_label: "الحلقات" ,
        no_of_episodes_label: "لا. من الحلقات",
        duration_label: "المدة (حح:مم:سس:فف)" ,
        total_block_duration_label: "إجمالي مدة الكتلة (حح:مم:سس:فف)" ,
        season_number_column: "رقم الموسم" ,
        no_of_episodes_column: 'لا. من الحلقات',
        season_content_column: "محتوى الموسم" ,
        episode_content_column: "محتوى الحلقة" ,
        duration_column: "المدة" ,
        timerangefrom_column: "من" ,
        timerangeto_column: "إلى" ,
        select_image_collection_header: "حدد صورة" ,
        description_column: "الوصف" ,
        meta_data_label: "البيانات الوصفية" ,
        posters_label: "ملصقات" ,
        publishing_label: "النشر" ,
        search_by_title: "البحث حسب العنوان" ,
        genre_label: "النوع" ,
        language_label: "اللغة" ,
        pg_rating_label: "تصنيف ص" ,
        keywords_label: "الكلمات الرئيسية" ,
        add_poster_button_tooltip: "إضافة ملصق" ,
        poster_type_column: "نوع الملصق" ,
        image_column: "صورة" ,
        edit_poster_dialog_header: "تحرير الملصق" ,
        poster_type_label: "نوع الملصق" ,
        url_label: "عنوان يو آر إل" ,
        select_url_tooltip: "حدد عنوان يو آر إل" ,
        add_publishing_button_tooltip: "إضافة نشر" ,
        unlimited_rights_grid: "حقوق غير محدودة" ,
        tba_label: "يعلن لاحقا" ,
        tba_column: "يعلن لاحقا" ,
        publishing_dialog_header: "إنشاء النشر" ,
        platform_type_column: "نوع المنصة" ,
        channel_column: "القناة" ,
        source_column: "المصدر" ,
        enter_title: "أدخل العنوان" ,
        create_season_dialog_header: "إنشاء سلسلة" ,
        season_number_label: "رقم الموسم" ,
        series_label: "سلسلة الوالدين" ,
        starting_episode_label: "بدء الحلقة" ,
        select_series_collection_header: "تحديد سلسلة الوالدين" ,
        video_label: "فيديو" ,
        add_video_button_tooltip: "إضافة فيديو" ,
        video_type_column: "نوع الفيديو" ,
        video_type_label: "نوع الفيديو" ,
        url_column: "عنوان يو آر إل" ,
        edit_video_dialog_header: "تحرير الفيديو" ,
        create_video_dialog_header: "إنشاء فيديو" ,
        episode_title_label: "عنوان الحلقة" ,
        episode_number_label: "رقم الحلقة" ,
        release_date_label: "تاريخ الإصدار" ,
        version_label: "الإصدار" ,
        content_category_label: "فئة المحتوى" ,
        format_label: "التنسيق" ,
        cast_and_crew_label: "طاقم العمل والطاقم" ,
        dynamic_fields_label: "الحقول الديناميكية" ,
        censor_rating_label: "تصنيف الرقابة" ,
        technical_prototype_label: "النموذج الفني" ,
        production_year_label: "سنة الإنتاج" ,
        language_column: "اللغة" ,
        synopsis_column: "ملخص" ,
        cast_column: "المدلى بها" ,
        name_column: "الاسم" ,
        name_label: "الاسم" ,
        cast_type_label: "نوع المصبوب" ,
        segment_type_column: "النوع" ,
        material_id_column: "معرف المواد" ,
        actual_duration_column: "المدة الفعلية" ,
        is_default_column: "افتراضي" ,
        is_hard_parted_column: "مفترق بشدة" ,
        edit_version_dialog_header: "تحرير الإصدار" ,
        create_version_dialog_header: "إنشاء نسخة" ,
        segment_type_label: "نوع الشريحة" ,
        material_id_label: "معرف المواد" ,
        is_hard_parted_label: "مفترق بشدة" ,
        is_default_label: "افتراضي" ,
        generate_segments_button_text: "إنشاء شرائح" ,
        auto_distribute_button_text: "التوزيع التلقائي" ,
        sequence_column: "التسلسل" ,
        media_id_column: "معرف الوسائط" ,
        start_time_column: "وقت البدء" ,
        end_time_column: "وقت الانتهاء" ,
        previewed_column: "معاينة" ,
        parent_content_category_column: "فئة المحتوى الأصلي" ,
        select_format_collection: "حدد التنسيق" ,
        select_content_category_collection_header: "حدد فئة المحتوى" ,
        manual_assetid_label: "رقم تعريف مادة العرض اليدوي",
        media_category_label: "فئة الوسائط" ,
        media_category_type_label: "نوع فئة الوسائط" ,
        tc_in_label: "تي سي In (حح:مم:سس:فف)" ,
        tc_out_label: "تي سي خارج (حح:مم:سس:فف)" ,
        media_episode_group_dialog_header: "مجموعة حلقات الإعلام" ,
        program_group_dialog_header: "مجموعة البرامج" ,
        interstital_group_dialog_header: "المجموعة الخلالية" ,
        media_category_type_column: "نوع فئة الوسائط" ,
        media_category_type_collection_header: "نوع فئة الوسائط" ,
        episode_dialog_header: "الحلقة" ,
        sidefixmenu_rails_asset_tooltip: "الأصول" ,
        cardlist_rails_title: "الأصول" ,
        cardlist_rails_assets_title: "مكتبة الأصول" ,
        rails_title_editpageheader: "القضبان" ,
        display_title_label: "عنوان العرض" ,
        rails_type_label: "النوع" ,
        rails_note_label: "ملاحظة: يمكن للمستخدم تحديد" ,
        publish_label: "نشر" ,
        published_label: "منشور" ,
        unpublished_label: "غير منشورة" ,
        rails_p_label: "P" ,
        rails_up_label: "أعلى" ,
        ott_assets_collection_header: "أصول ياتت" ,
        options_actionbutton_menutext: "خيارات" ,
        delete_actionbutton_option: "حذف" ,
        moveup_actionbutton_option: "التحرك لأعلى" ,
        movedown_actionbutton_option: "تحرك لأسفل" ,
        sidefixmenu_rails_tooltip: "السكك الحديدية" ,
        cardlist_page_rails_title: "القضبان" ,
        cardlist_rails_page_title: "مكتبة السكك الحديدية" ,
        theme_label: "الموضوع" ,
        icon_url_label: "عنوان يو آر إل للأيقونة" ,
        please_fill_out_details_label: "يرجى ملء هذا الحقل." ,
        assets_dialog_header: "إنشاء الأصول" ,
        Validation: "الرجاء تحديد القيمة" ,
        asset_type_label: "نوع الأصول" ,
        portrait_image_url_label: "عنوان يو آر إل للصورة الشخصية" ,
        landscape_image_url_label: "عنوان يو آر إل للصورة الأفقية" ,
        video_playback_url_label: "عنوان يو آر إل لتشغيل الفيديو" ,
        select_portal_image_url_collection_header: "تحديد عنوان يو آر إل للصورة الشخصية" ,
        select_landscape_image_url_collection_header: "تحديد عنوان يو آر إل للصورة الأفقية" ,
        create_poster_dialog_header: "إنشاء ملصق" ,
        image_url_label: "عنوان يو آر إل للصورة" ,
        file_path_label: "مسار الملف" ,
        poster_note_label: "ملاحظة: سيتم تحميل الصورة عند الحفظ وسيتم تعيين عنوان يو آر إل" ,
        template_name_label: "اسم القالب" ,
        module_type_label: "نوع الوحدة" ,
        ingestion_template_type_label: "نوع قالب الابتلاع" ,
        save_template_text: "حفظ القالب" ,
        send_text: "إرسال" ,
        send_button_tooltip: "إرسال" ,
        params_label: "المعلمات" ,
        body_label: "الجسم" ,
        response_label: "الاستجابة" ,
        select_file_label: "حدد ملف" ,
        load_button_text: "تحميل" ,
        ingestion_template_note_label: "حسنا , استبدل قيمة المعلمة الأولى بنص البحث أثناء الحفظ" ,
        key_column: "مفتاح" ,
        value_column: "القيمة" ,
        key_label: "مفتاح" ,
        value_label: "القيمة" ,
        template_details_label: "تفاصيل القالب" ,
        template_label: "قالب" ,
        search_title_label: "عنوان البحث" ,
        ingest_button_text: "ابتلاع" ,
        ingest_button_tooltip: "ابتلاع" ,
        load_button_tooltip: "تحميل" ,
        ingestion_details_label: "تفاصيل الابتلاع" ,
        pg_rating_column: "تصنيف بي جي" ,
        genres_column: "الأنواع" ,
        show_response_label: "إظهار الاستجابة" ,
        response_dialog_header: "الاستجابة" ,
        eye_tooltip: "العين" ,
        agency_dialog_header: "إنشاء وكالة" ,
        code_label: "رمز" ,
        address_label: "العنوان" ,
        pincode_label: "الرمز السري" ,
        email_label: "البريد الإلكتروني" ,
        credit_limit_label: "الحد الائتماني" ,
        pan_label: "عموم" ,
        gst_number_label: "رقم ضريبة السلع والخدمات" ,
        default_tax_label: "الضريبة الافتراضية" ,
        is_credit_unlimited_label: "هل الائتمان غير محدود" ,
        client_dialog_header: "إنشاء عميل" ,
        agency_label: "وكالة" ,
        campaign_number_label: "رقم الحملة" ,
        order_date_label: "تاريخ الطلب *" ,
        period_from_label: "الفترة من" ,
        period_to_label: "الفترة إلى" ,
        campaign_type_label: "نوع الحملة" ,
        deal_type_label: "نوع الصفقة" ,
        client_label: "العميل" ,
        location_label: "الموقع" ,
        product_label: "المنتج" ,
        commission_label: "العمولة" ,
        target_budget_label: "الميزانية المستهدفة" ,
        target_spots_label: "النقاط المستهدفة" ,
        target_seconds_label: "الثواني المستهدفة" ,
        currency_label: "العملة" ,
        discount_label: "الخصم" ,
        tax_applicable_label: "الضريبة المطبقة" ,
        actual_booking_label: "الحجز الفعلي" ,
        commercial_label: "تجاري" ,
        fct_label: "إف سي تي" ,
        billing_label: "الفوترة" ,
        channel_tab_label: "القناة" ,
        channel_name_column: "اسم القناة" ,
        epg_code_column: "رمز دليل البرامج الإلكتروني" ,
        asrun_code_column: "رمز أسرشن" ,
        content_column: "المحتوى" ,
        add_channel_button_tooltip: "إضافة قناة" ,
        add_commercial_button_tooltip: "إضافة إعلان تجاري" ,
        add_fct_button_tooltip: "إضافة إف سي تي" ,
        add_billing_button_tooltip: "إضافة فواتير" ,
        channel_collection_header: "القنوات" ,
        commercials_collection_header: "الإعلانات التجارية" ,
        fct_column: "إف سي تي" ,
        from_date_column: "من التاريخ" ,
        to_date_column: "حتى الآن" ,
        campaign_type_column: "نوع كامباجين" ,
        rate10sec_column: "معدل ١٠ ثوان" ,
        days_column: "أيام" ,
        fct_dialog_header: "إف سي تي" ,
        billing_dialog_header: "الفوترة" ,
        from_date_label: "من التاريخ" ,
        to_date_label: "حتى الآن" ,
        rate10sec_label: "معدل ١٠ ثوان" ,
        day_label: "اليوم" ,
        select_all_label: "تحديد الكل" ,
        sun_label: "الشمس" ,
        mon_label: "الاثنين" ,
        tue_label: "الثلاثاء" ,
        wed_label: "الأربعاء" ,
        thu_label: "الخميس" ,
        fri_label: "الجمعة" ,
        sat_label: "السبت" ,
        amount_column: "المبلغ" ,
        amount_label: "المبلغ" ,
        booking_label: "الحجز" ,
        campaign_label: "الحملة" ,
        year_label: "السنة" ,
        months_label: "أشهر" ,
        booking_collection_header: "اختر حملة للحجز",
        from_label: "من:" ,
        to_label: "إلى:" ,
        total_spot_label: "مجموع البقع" ,
        total_seconds_label: "إجمالي الثواني" ,
        total_budget_label: "الميزانية الإجمالية:",
        position_label: "المنصب" ,
        download_template_tooltip: "تنزيل القالب" ,
        file_tooltip: "اختر ملف" ,
        line_no_column: "رقم الخط" ,
        commercial_column: "تجاري" ,
        program_column: "برنامج" ,
        house_number_column: "رقم المنزل" ,
        type_column: "النوع" ,
        deal_type_column: "نوع الصفقة" ,
        spot_rate_column: "السعر الفوري" ,
        position_column: "المنصب" ,
        from_time_column: "من" ,
        to_time_column: "إلى" ,
        product_code_column: "رمز المنتج" ,
        total_spot_column: "البقعة الإجمالية" ,
        total_fct_column: "إجمالي إف سي تي" ,
        total_amount_column: "المبلغ الإجمالي" ,
        booked_spots_lable: "الأماكن المحجوزة :",
        current_spots_label: 'البقع الحالية : ',
        booked_seconds_label: 'الثواني المحجوزة :',
        current_seconds_label: "الثواني الحالية :",
        booked_amount_label: "المبلغ المحجوز :",
        current_amount_label: "المبلغ الحالي :",
        add_booking_dialog_header: "إضافة حجز" ,
        type_label: "النوع" ,
        from_time_label: "من الزمن" ,
        spot_per_day_label: "بقعة (في اليوم)" ,
        spot_per_week_label: "بقعة (في الأسبوع)" ,
        rate_label: "معدل" ,
        estimate_code_label: "رمز التقدير" ,
        remark_label: "ملاحظة" ,
        count_label: "العد" ,
        transfer_from_label: "نقل من" ,
        transfer_to_label: "نقل إلى" ,
        transfer_button_tooltip: "نقل" ,
        transfer_button_text: "نقل" ,
        campaign_column: "الحملة" ,
        booking_date_column: "تاريخ الحجز" ,
        time_range_from_column: "النطاق الزمني من" ,
        time_range_to_column: "النطاق الزمني إلى" ,
        edit_actionbutton_menutext: "تحرير" ,
        delete_actionbutton_menutext: "حذف" ,
        cut_actionbutton_menutext: "قص" ,
        update_booking_line_number_label: "تحديث رقم خط الحجز" ,
        select_campaign_collection_text: "حدد الحملة" ,
        edit_campaign_collection_text: "تعديل الحملة" ,
        select_campaign_collection_tooltip: "تحديد" ,
        already_scheduled_label: "مجدول بالفعل" ,
        not_scheduled_label: "غير مجدول" ,
        edit_booking_dialog_header: "تعديل الحجز" ,
        spot_rate_label: "السعر الفوري" ,
        time_range_from_label: "النطاق الزمني من" ,
        time_range_to_label: "النطاق الزمني إلى" ,
        auto_reconcile_button_tooltip: "التوفيق التلقائي" ,
        auto_reconcile_button_text: "التوفيق التلقائي" ,
        import_as_run_button_text: "استيراد كتشغيل" ,
        import_as_run_button_tooltip: "استيراد كتشغيل" ,
        reconciled_r_label: "ر" ,
        reconciled_label: "مصالحة" ,
        non_reconciled_label: "عدم التوفيق" ,
        non_reconciled_nr_label: "ن ر" ,
        total_label: "المجموع:" ,
        total_commercial: "إجمالي التجاري:" ,
        sno_column: "رقم س",
        slot_date_time_column: "وقت تاريخ الفتحة" ,
        episode_number_column: "رقم الحلقة" ,
        category_column: "الفئة" ,
        reconciled_status_column: "حالة المصالحة" ,
        is_reconciled_column: "يتصالح" ,
        reconsilation_by_column: "المصالحة بواسطة" ,
        status_column: "الحالة" ,
        ref_force_match_label: "مباراة القوة" ,
        force_match_label: "مباراة القوة" ,
        force_match_fm_label: "إف إم" ,
        ref_force_unmatch_label: "فرض عدم التطابق" ,
        ref_cancel_label: "إلغاء" ,
        purge_button_tooltip: "تطهير" ,
        unpurge_button_tooltip: "إلغاء التطهير" ,
        campaign_no_label: "رقم الحملة",
        fic_dxb_0002486_label: "FIC-DXB-0002486" ,
        total_spot_857_label: '857' ,
        total_amount_label: "المبلغ الإجمالي" ,
        total_seconds_19935_label: '19935',
        total_cast_2478_label: '2478.00',
        purged_label: "تطهير" ,
        date_column: "التاريخ" ,
        is_purged_column: "تم تطهيره" ,
        ref_purged_label: "تطهير" ,
        ref_unpurged_label: "إلغاء التطهير" ,
        ref_calculate_spot_rate_label: "حساب السعر الفوري" ,
        total_fct_label: "إجمالي إف سي تي" ,
        total_spot_rate: "إجمالي السعر الفوري" ,
        ref_calculate_fct_label: "حساب إف سي تي" ,
        ref_change_time_range_label: "تغيير النطاق الزمني" ,
        ref_transfer_label: "نقل" ,
        ref_update_estimate_code_label: "تحديث رمز التقدير" ,
        ref_change_commercial_label: "تغيير التجاري" ,
        ref_change_spot_rate_label: "تغيير السعر الفوري" ,
        change_time_range_dialog_header: "تغيير النطاق الزمني" ,
        start_time_label: "وقت البدء" ,
        to_time_label: "إلى الوقت" ,
        transfer_dialog_header: "نقل" ,
        transfer_to_date_label: "نقل إلى التاريخ" ,
        change_spot_rate_dialog_header: "تغيير السعر الفوري" ,
        change_spot_rate_label: "تغيير السعر الفوري" ,
        change_commercial_dialog_header: "تغيير الإعلانات التجارية" ,
        change_commercial_label: "تغيير تجاري" ,
        update_product_estimate_code_dialog_header: "تحديث رمز تقدير المنتج" ,
        update_button_text: "تحديث" ,
        update_all_button_text: "تحديث الكل" ,
        add_file_label: "إضافة ملف" ,
        import_file_label: "استيراد الملفات" ,
        status_label: "الحالة" ,
        file_name_column: "اسم الملف" ,
        message_column: "رسالة" ,
        file_upload_tooltip: "تحميل الملف" ,
        import_file_name_column: "استيراد اسم الملف" ,
        comment_load_data_label: "انقر فوق تحميل لتحميل البيانات" ,
        program_label: "برنامج" ,
        select_program_booking_collection_header: "اختر برنامج للحجز" ,
        export_report_dialog_header: "تصدير دليل البرامج الإلكتروني" ,
        import_template_dialog_heade: "استيراد القالب" ,
        filters_label: "الفلاتر" ,
        operator_label: "المشغل" ,
        operator_column: "المشغل" ,
        export_button_tooltip: "تصدير" ,
        export_button_text: "تصدير" ,
        export_playlist_button_tooltip: "تصدير قائمة التشغيل" ,
        export_and_sftp_playlist_button_tooltip: "تصدير وقائمة تشغيل سفتب" ,
        export_playlist_button_text: "تصدير قائمة التشغيل" ,
        up_button_tooltip: "فوق" ,
        down_button_tooltip: "أسفل" ,
        export_template_editpageheader: "تصدير قالب دليل البرامج الإلكتروني" ,
        save_template_as_label: "حفظ القالب باسم" ,
        module_name_label: "اسم الوحدة" ,
        selected_Columns_label: "الأعمدة المحددة" ,
        available_Column_label: "العمود المتاح" ,
        display_name_label: "اسم العرض" ,
        add_blank_button_tooltip: "إضافة فارغة" ,
        add_blank_button_text: "إضافة فارغة" ,
        add_column_button_tooltip: "إضافة عمود" ,
        add_column_button_text: "إضافة عمود" ,
        available_Column: "العمود المتاح" ,
        display_column: "اسم العرض" ,
        format_name_column: "التنسيق" ,
        id_label: "الهوية" ,
        source_label: "المصدر" ,
        segment_position_label: "موضع القطاع" ,
        group_label: "مجموعة" ,
        select_media_episode_collection_header: "حدد حلقة الوسائط" ,
        start_type_label: "نوع البدء" ,
        start_offset_label: "بدء الإزاحة" ,
        time_hh_mm_ss_ff_label: 'ح ح :م م :س س: و و',
        negative_label: "سلبي" ,
        end_type_label: "نوع النهاية" ,
        end_offset_label: "نهاية الإزاحة" ,
        add_secondary_event_button_toottip: "إضافة أحداث ثانوية" ,
        text_number_column: "رقم النص" ,
        prefix_column: "بادئة" ,
        rule_column: "القاعدة" ,
        text_number_label: "رقم النص" ,
        prefix_label: "بادئة" ,
        rule_label: "القاعدة" ,
        style_sheet_editpageheader: "ورقة الأنماط" ,
        add_prototype_button_tooltip: "إضافة نموذج أولي" ,
        id_column: "الهوية" ,
        protoType_source_column: "مصدر النمط الأولي" ,
        group_column: "مجموعة" ,
        select_prototype_collection_header: "حدد النموذج الأولي" ,
        default_prototype_editpageheader: "النموذج الأولي الافتراضي" ,
        create_secondary_event_dynamic_rule_dialog_header: "إنشاء قاعدة ديناميكية للحدث الثانوي" ,
        formula_label: "الصيغة" ,
        secondary_event_overview_dialog_header: "نظرة عامة على الحدث الثانوي" ,
        start_type_column: "نوع البدء" ,
        start_offset_column: "بدء الإزاحة" ,
        end_type_column: "نوع النهاية" ,
        end_offset_column: "إنهاء الإزاحة" ,
        confirm_button_text: "تأكيد" ,
        billing_period_label: "فترة الفوترة" ,
        upto_label: "حتى" ,
        generate_bill_button_tooltip: "إنشاء فاتورة" ,
        generate_bill_button_text: "إنشاء فاتورة" ,
        agency_column: "وكالة" ,
        client_column: "العميل" ,
        comission_column: "نسبة العمولة" ,
        from_column: "من" ,
        to_column: "إلى" ,
        total_budget_spot_column: "إجمالي نقاط الميزانية" ,
        total_booked_spot_column: "إجمالي المواقع المحجوزة" ,
        missing_spot_column: "النقاط المفقودة" ,
        reconciled_spots_column: "نقاط المصالحة" ,
        generated_bill_Spots_column: "إنشاء بقع الفاتورة" ,
        contact_label: "كونتيكت" ,
        contact_info_label: "معلومات الاتصال" ,
        telephone_label: "الهاتف" ,
        address1_label: "العنوان ١" ,
        address2_label: "العنوان ٢" ,
        agency_info_label: "معلومات الوكالة" ,
        spots_label: "البقع" ,
        amounts_label: "المبالغ" ,
        total_booked_label: "إجمالي الحجوزات" ,
        missing_label: "مفقود" ,
        generated_bill_label: "الفاتورة التي تم إنشاؤها" ,
        payment_recived_label: "تم استلام الدفع" ,
        due_amount_label: "المبلغ المستحق" ,
        credit_balance_label: "الرصيد الدائن" ,
        selected_campaign_info_label: "معلومات الحملة المختارة" ,
        booked_label: "محجوز" ,
        detail_for_selected_date_range_and_campaign_label: "تفاصيل النطاق الزمني والحملة المحددة" ,
        invoice_denomination_dialog_header: "فئة الفاتورة" ,
        invoice_period_label: "فترة الفاتورة" ,
        invoice_campaign_brabd_label: "العلامات التجارية للحملة" ,
        select_period_label: "حدد الفترة" ,
        show_button_tooltip: "عرض" ,
        show_button_text: "عرض" ,
        details_label: "التفاصيل" ,
        caption_column: "التسمية التوضيحية" ,
        spots_column: "البقع" ,
        tax_column: "الضريبة" ,
        tax_label: "الضريبة" ,
        tax_not_applicable_label: "الضريبة غير مطبقة" ,
        discount_amount_label: "مبلغ الخصم" ,
        invoice_date_label: "تاريخ الفاتورة" ,
        bank_account_label: "حساب مصرفي" ,
        gross_total_label: "الإجمالي الإجمالي" ,
        tax_amount_label: "مبلغ الضريبة" ,
        commission_amount_label: "مبلغ العمولة" ,
        target_amount_label: "المبلغ المستهدف" ,
        invoice_no_column: "رقم الفاتورة" ,
        campaign_no_column: "رقم الحملة",
        invoice_date_column: "تاريخ الفاتورة" ,
        period_from_column: "الفترة من" ,
        period_to_column: "الفترة إلى" ,
        validated_amount_column: "المبلغ الذي تم التحقق من صحته" ,
        discount_percentage_column: "نسبة الخصم" ,
        discount_amount_column: "مبلغ الخصم" ,
        company_menu: "الشركة" ,
        logo_url_label: "عنوان يو آر إل للشعار" ,
        suffix_label: "لاحقة" ,
        next_number_label: "الرقم التالي" ,
        full_channel_name_label: "اسم القناة الكامل" ,
        short_channel_name_label: "اسم القناة القصيرة" ,
        epg_code_label: "رمز دليل البرامج الإلكتروني" ,
        as_run_code_label: "كرمز تشغيل" ,
        automation_channel_name_label: "اسم قناة الأتمتة" ,
        color_label: "اللون" ,
        automation_label: "الأتمتة" ,
        repeated_pattern_details_dialog_header: "تفاصيل النمط المتكررة" ,
        add_repeat_button_tooltip: "إضافة تكرار" ,
        create_repeat_pattern_editpage_header: "إنشاء نمط تكرار" ,
        create_variation_editpage_header: "إنشاء اختلاف" ,
        create_break_format_editpage_header: "إنشاء تنسيق الفاصل" ,
        edit_variation_editpage_header: "تحرير الاختلاف" ,
        edit_break_format_editpage_header: "تحرير تنسيق الفاصل" ,
        add_primary_pattern_button_tolltip: "إضافة أساسي" ,
        primary_events_no_column: "الأحداث الأساسية لا" ,
        repeat_events_no_column: "تكرار الأحداث لا" ,
        nominal_start_column: "البداية الاسمية" ,
        primary_events_no_label: "الأحداث الأساسية لا" ,
        days_label: "أيام" ,
        times_label: "الأوقات" ,
        create_invoice_content_dialog_header: "إنشاء محتوى الفاتورة" ,
        number_length_label: "طول الرقم" ,
        label: "التسمية" ,
        sequence_label: "التسلسل" ,
        entity_name_label: "اسم الكيان" ,
        max_length_label: "الطول الأقصى" ,
        width_label: "العرض" ,
        required_label: "مطلوب" ,
        edit_label: "تحرير" ,
        slot_time_column: "وقت الفتحة" ,
        please_generate_segment_to_load_media_label: "يرجى إنشاء مقطع لتحميل الوسائط!" ,
        filter_button_tooltip: "تصفية" ,
        search_content_dialog_header: "محتوى البحث" ,
        clear_button_tooltip: "واضح" ,
        clear_button_text: "واضح" ,
        search_button_tooltip: "البحث" ,
        search_button_text: "بحث" ,
        select_preference_for_dialog_header: "تحديد تفضيل العمود ل ",
        available_label: "متوفر" ,
        preference_label: "التفضيل" ,
        note_preference: "ملاحظة: - بالنسبة للأعمدة الافتراضية , اترك التفضيل فارغا" ,
        house_number_label: "رقم المنزل" ,
        category_type_label: "نوع الفئة" ,
        slot_time_label: "وقت الفتحة" ,
        actual_duration_label: "المدة الفعلية" ,
        nominal_duration_label: "المدة الاسمية" ,
        segments_label: "الشرائح" ,
        recursive_button_text: "العودية" ,
        recursive_dialog_header: "العودية" ,
        generate_button_toottip: "إنشاء" ,
        generate_button_text: "إنشاء" ,
        inclusion_range_label: "نطاق التضمين" ,
        episode_range_label: "نطاق الحلقة" ,
        example_label: "مثال" ,
        exclusion_range_label: "نطاق الاستبعاد" ,
        exclude_days_label: "استبعاد الأيام" ,
        format_icon_tooltip: "التنسيق" ,
        no_segment_icon_tooltip: "لا شريحة" ,
        contract_icon_tooltip: "العقد" ,
        slot_duration_title_label: "مدة الفتحة" ,
        maximize_button_tooltip: "تكبير" ,
        copy_Schedule_button_tooltip: "نسخ" ,
        planing_preference_button_tooltip: "تفضيل التخطيط" ,
        export_planning_button_tooltip: "تخطيط التصدير" ,
        selected_label: "محدد" ,
        copied_label: "تم النسخ" ,
        select_channels_dialog_header: "تحديد القنوات" ,
        date_label: "التاريخ" ,
        copy_Schedule_dialog_header: "نسخ الجدول" ,
        copy_from_label: "نسخ من" ,
        copy_to_label: "نسخ إلى" ,
        selected_date_should_be_equal_error_message: "يجب أن يكون النطاق الزمني المحدد متساويا" ,
        from_channel_label: "من القناة" ,
        activity_label: "النشاط" ,
        create_button_tootltip: "إنشاء" ,
        create_button_text: "إنشاء" ,
        planing_preference_dialog_header: "تفضيل التخطيط" ,
        events_label: "الأحداث" ,
        columns_label: "الأعمدة" ,
        general_label: "عام" ,
        note_select_the_events_you_want_to_change: "حدد الأحداث التي تريد تغييرها" ,
        events_type_lable: "نوع الأحداث" ,
        font_size_label: "حجم الخط" ,
        foreground_Color_label: "لون المقدمة" ,
        background_color_label: "لون الخلفية" ,
        available_columns_label: "الأعمدة المتاحة" ,
        view_type_label: "نوع العرض" ,
        show_format_label: "إظهار التنسيق" ,
        show_no_seg_label: "إظهار عدم التسلسل" ,
        show_contract_label: "عرض العقد" ,
        day_Start_time_label: "وقت بدء اليوم" ,
        prime_end_time_label: "وقت انتهاء الذروة" ,
        prime_Start_time_label: "وقت بدء الذروة" ,
        episode_Column: "الحلقة" ,
        season_Column: "الموسم" ,
        versions_column: "الإصدارات" ,
        search_filter_dialog_header: "عامل تصفية البحث" ,
        release_year_label: "سنة الإصدار" ,
        is_live_label: "مباشر" ,
        save_as_template_label: "حفظ كقالب" ,
        show_search_options_media_liberary_tooltip: "إظهار خيارات البحث" ,
        place_holder_dialog_header: "حامل المكان" ,
        please_choose_one_publishing_rights_collection_header: "الرجاء اختيار حقوق نشر واحدة" ,
        export_excel_dialog_header: "تصدير اكسل" ,
        export_excel_button_tooltip: "تصدير اكسل" ,
        export_csv_button_tooltip: "تصدير سي إس في" ,
        export_excel_button_text: "تصدير اكسل" ,
        history_dialog_header: "التاريخ" ,
        slot_date_column: "تاريخ الفتحة" ,
        exhibition_no_column: "المعرض رقم" ,
        repeat_no_column: "كرر لا" ,
        planning_loaded_label_note: "تم تحميل التخطيط" ,
        planning_loading_label_note: "تخطيط التحميل....",
        time_column: "الوقت" ,
        prototypes_column: "النماذج الأولية" ,
        info_column: "معلومات" ,
        cut_button_tooltip: "قص" ,
        paste_button_tooltip: "لصق" ,
        download_pdf_button_tooltip: "تنزيل ملف 	پ ذف" ,
        open_planning_button_tooltip: "التخطيط المفتوح" ,
        delete_all_footer_button_tooltip: "حذف كل التذييل" ,
        delete_All_secondary_events_button_tooltip: "حذف جميع الأحداث الثانوية" ,
        global_replacement_button_tooltip: "الاستبدال العالمي" ,
        apply_style_sheet_button_tooltip: "تطبيق ورقة الأنماط" ,
        apply_button_tooltip: "تطبيق" ,
        apply_button_text: "تطبيق" ,
        schedule_preference_button_tooltip: "تفضيل الجدول" ,
        auto_save_button_tooltip: "حفظ تلقائي" ,
        secondary_events_label: "الأحداث الثانوية" ,
        text_1_column: "النص ١" ,
        text_2_column: "النص ٢" ,
        text_3_column: "النص ٣" ,
        prototypes_label: "النماذج الأولية" ,
        insert_break_actionbutton_menutext: "إدراج فاصل" ,
        delete_header_actionbutton_menutext: "حذف الرأس" ,
        program_replacement_actionbutton_menutext: "استبدال البرنامج" ,
        attach_format_reload_actionbutton_menutext: "إرفاق تنسيق" ,
        history_actionbutton_menutext: "التاريخ" ,
        select_program_to_replace_with_collection_title: "حدد البرنامج لاستبداله" ,
        global_replacement_dialog_header: "الاستبدال العالمي" ,
        replace_button_tooltip: "استبدال" ,
        replace_button_text: "استبدال" ,
        replacement_method_label: "طريقة الاستبدال" ,
        include_isBreak_label: "تضمين هو استراحة" ,
        replacement_material_label: "المواد البديلة" ,
        delete_all_button_tooltip: "حذف الكل" ,
        delete_all_button_text: "حذف الكل" ,
        group_all_button_tooltip: "حلقة وسائل الإعلام الجماعية" ,
        group_all_button_text: "حلقة وسائل الإعلام الجماعية" ,
        select_media_episode_dialoag_header: "حدد حلقة إعلامية" ,
        select_media_episode_group_dialoag_header: "حدد مجموعة حلقات الوسائط" ,
        remove_filter_actionbutton_menutext: "إزالة الفلتر" ,
        filter_actionbutton_menutext: "تصفية" ,
        copy_actionbutton_menutext: "نسخ" ,
        paste_actionbutton_menutext: "لصق" ,
        se_overview_actionbutton_menutext: "نظرة عامة على س إي" ,
        global_replacement_actionbutton_menutext: "الاستبدال العالمي" ,
        sales_team_label: "فريق المبيعات" ,
        username_label: "اسم المستخدم" ,
        department_label: "القسم" ,
        password_label: "كلمة المرور" ,
        phone_no_label: "رقم الهاتف" ,
        pobox_label: "صندوق بريد" ,
        reporting_to_lable: "تقديم التقارير إلى" ,
        role_label: "الدور" ,
        issalesexecutive_label: "هو مدير المبيعات" ,
        sales_executive_editpageheader: "تنفيذي مبيعات" ,
        email_column: "البريد الإلكتروني" ,
        phone_no_column: "رقم الهاتف" ,
        select_sales_executive_colllection_header: "حدد تنفيذي مبيعات" ,
        select_collection_button_text: "تحديد" ,
        export_planet_cast_button_tooltip: "تصدير الكوكب المصبوب" ,
        check_list_button_tooltip: "قوائم المراجعة" ,
        check_lists_dialog_header: "قوائم المراجعة" ,
        success_label: "النجاح" ,
        failure_lable: "فشل" ,
        module_menu: "وحدة" ,
        sales_team_dailog_header: "إنشاء فريق مبيعات" ,
        module_dialog_header: "وحدة" ,
        role_dialog_header: "الدور" ,
        admin_label: "المشرف" ,
        sales_label: "المبيعات" ,
        media_management_label: "إدارة وسائل الإعلام" ,
        rights_manager_label: "مدير الحقوق" ,
        role_editpageheader: "الدور" ,
        select_module_collection_header: "حدد الوحدة النمطية" ,
        users_editpage_header: "المستخدم" ,
        select_Role_collection_header: "تحديد الدور" ,
        module_column: "وحدة" ,
        role_column: "الدور" ,
        add_role_collection_text: "إضافة دور" ,
        add_role_button_text: "إضافة من الدور" ,
        add_module_collection_text: "إضافة وحدة" ,
        sales_executive_editpage_header: "تنفيذي مبيعات" ,
        timeline_page_breadcrumb: "الخط الزمني" ,
        assets_type_page_breadcrumb: "نوع الأصول" ,
        rails_type_page_breadcrumb: "نوع القضبان" ,
        posters_type_page_breadcrumb: "نوع الملصقات" ,
        department_page_breadcrumb: "القسم" ,
        users_page_breadcrumb: "المستخدمون" ,
        role_page_breadcrumb: "الدور" ,
        assets_page_breadcrumb: "الأصول" ,
        posters_page_breadcrumb: "ملصقات" ,
        client_page_breadcrumb: "العميل" ,
        agency_page_breadcrumb: "الوكالة" ,
        sales_team_page_breadcrumb: "فريق المبيعات" ,
        trailer_page_breadcrumb: "مقطورة" ,
        sales_executive_page_breadcrumb: "تنفيذي مبيعات" ,
        content_page_breadcrumb: "المحتوى" ,
        channel_page_breadcrumb: "القناة" ,
        currency_page_breadcrumb: "العملة" ,
        repeat_pattern_page_breadcrumb: "تكرار النمط" ,
        invoice_content_page_breadcrumb: "محتوى الفاتورة" ,
        segment_type_page_breadcrumb: "نوع الشريحة" ,
        contract_page_breadcrumb: "العقد" ,
        series_page_breadcrumb: "سلسلة الوالدين" ,
        season_page_breadcrumb: "سلسلة" ,
        programs_page_breadcrumb: "البرامج" ,
        episode_page_breadcrumb: "الحلقة" ,
        movies_page_breadcrumb: "أفلام" ,
        interstitial_page_breadcrumb: "الخلالي" ,
        commercial_page_breadcrumb: "تجاري" ,
        music_page_breadcrumb: "الموسيقى" ,
        filler_page_breadcrumb: "حشو" ,
        break_page_breadcrumb: "استراحة" ,
        group_page_breadcrumb: "مجموعة" ,
        program_group_page_breadcrumb: "مجموعة البرامج" ,
        interstital_group_page_breadcrumb: "المجموعة الخلالية" ,
        schedule_constraints_page_breadcrumb: "قيود الجدول الزمني" ,
        campaign_page_breadcrumb: "الحملة" ,
        utilisied_label: "مستخدم" ,
        over_booked_label: "أكثر من حجز" ,
        counts_label: "التهم" ,
        active_label: "نشط" ,
        top_10_agency_label: "أفضل ١٠ وكالات" ,
        top_10_client_label: "أفضل ١٠ عملاء" ,
        pending_vs_on_air_booking_label: "معلق مقابل على الحجز الجوي" ,
        revenue_by_location_label: "الإيرادات حسب الموقع" ,
        revenue_by_months_label: "الإيرادات حسب الأشهر" ,
        booking_by_deal_type_label: "الحجز حسب نوع الصفقة" ,
        time_range_label: "النطاق الزمني" ,
        language_page_breadcrumb: "اللغة" ,
        region_page_breadcrumb: "المنطقة" ,
        brand_page_breadcrumb: "العلامة التجارية" ,
        product_page_breadcrumb: "المنتج" ,
        rails_page_breadcrumb: "القضبان" ,
        page_breadcrumb: "الصفحات" ,
        content_caegory_page_breadcrumb: "فئة المحتوى" ,
        ingestion_page_breadcrumb: "الابتلاع" ,
        jobs_page_breadcrumb: "الوظائف" ,
        prototype_page_breadcrumb: "النموذج الأولي" ,
        prototype_style_sheet_page_breadcrumb: "ورقة أنماط النموذج الأولي" ,
        default_prototype_style_sheet_page_breadcrumb: "ورقة أنماط النموذج الأولي الافتراضية" ,
        prototype_source_page_breadcrumb: "مصدر النموذج الأولي" ,
        export_template_page_breadcrumb: "تصدير القالب" ,
        form_fields_page_breadcrumb: "حقول النموذج" ,
        secondary_event_dynamic_rule_page_breadcrumb: "القاعدة الديناميكية للحدث الثانوي" ,
        format_page_breadcrumb: "التنسيق" ,
        time_range_page_breadcrumb: "النطاق الزمني" ,
        company_page_breadcrumb: "الشركة" ,
        bank_account_page_breadcrumb: "حساب مصرفي" ,
        tax_page_breadcrumb: "الضريبة" ,
        licensor_page_breadcrumb: "المرخص" ,
        delete_successfully_grid_data_success_toast_message: "حذف بنجاح" ,
        delete_all_successfully_grid_data_success_toast_message: "تم حذف الكل بنجاح" ,
        please_select_first_contract_collection_error_message: "الرجاء الاختيار أولا!!" ,
        please_enter_contarct_number_contract_error_message: "الرجاء إدخال رقم العقد" ,
        please_select_platform_type_error_message: "الرجاء تحديد نوع النظام الأساسي" ,
        please_select_licensor_error_message: "الرجاء اختيار المرخص" ,
        please_select_payee_error_message: "الرجاء اختيار المدفوع لأمره" ,
        please_select_platform_error_message: "الرجاء اختيار المنصة" ,
        please_select_channel_error_message: "الرجاء اختيار القناة" ,
        please_select_region_error_message: "الرجاء اختيار المنطقة",
        please_select_content_error_message: "الرجاء اختيار المحتوى",
        please_select_play_time_error_message: "الرجاء تحديد وقت اللعب" ,
        please_select_media_category_type_error_message: "الرجاء تحديد نوع فئة الوسائط",
        please_select_genres_media_episode_error_message: "الرجاء اختيار الأنواع" ,
        please_select_department_error_message: "الرجاء اختيار القسم" ,
        please_enter_title_error_message: "الرجاء إدخال العنوان" ,
        please_enter_description_error_message: "الرجاء إدخال الوصف" ,
        please_enter_name_error_message: "الرجاء إدخال الاسم" ,
        please_enter_phone_no_error_message: "الرجاء إدخال رقم الهاتف" ,
        please_enter_email_error_message: "الرجاء إدخال بريد إلكتروني صحيح" ,
        please_select_roletype_error_message: "الرجاء اختيار نوع الدور",
        please_enter_user_name_users_error_message: "الرجاء إدخال اسم المستخدم" ,
        please_enter_password_users_error_message: "الرجاء إدخال كلمة المرور" ,
        invalid_duration_error_message: "الرجاء إدخال مدة صالحة" ,
        please_attach_atleast_one_media_episode_group_error_message: "يرجى إرفاق وسائط واحدة على الأقل" ,
        please_add_atleast_one_module_rights_users_error_message: "الرجاء إضافة حقوق وحدة واحدة على الأقل" ,
        please_select_reportingto_user_error_message: "يرجى تحديد الإبلاغ إلى" ,
        please_select_sales_team_error_message: "الرجاء اختيار فريق المبيعات" ,
        exhibition_should_not_be_zero_error_message: "يجب أن يكون المعرض أكبر من الصفر" ,
        repeat_should_not_be_zero_error_message: "يجب أن يكون التكرار أكبر من الصفر" ,
        play_time_number_should_not_be_zero_error_message: "يجب أن يكون رقم وقت اللعب أكبر من الصفر" ,
        end_date_should_be_greater_than_start_date_error_message: "يجب أن يكون تاريخ الانتهاء أكبر من تاريخ البدء" ,
        please_attach_atleast_one_media_contract_error_message: "يرجى إرفاق وسائط واحدة على الأقل" ,
        please_select_season_content_series_error_message: "الرجاء تحديد محتوى الموسم" ,
        value_is_not_greater_then_zero_season_number_series_error_message: "يجب أن يكون رقم الموسم أكبر من الصفر" ,
        please_select_epiosde_content_series_error_message: "الرجاء تحديد محتوى الحلقة" ,
        value_is_not_greater_then_zero_episode_number_series_error_message: "يجب أن يكون رقم الحلقة أكبر من الصفر" ,
        please_attach_series_season_error_message: "يرجى إرفاق سلسلة" ,
        please_add_atleast_one_season_series_error_message: "يرجى إضافة موسم واحد على الأقل" ,
        entering_same_season_number_twice_series_error_message: "إدخال نفس رقم الموسم مرتين" ,
        enter_title_expender: "أدخل العنوان" ,
        please_select_poster_type_first_media_episode_poster_error_message: "الرجاء اختيار نوع الملصق",
        please_select_url_first_media_episode_poster_error_message: "الرجاء تحديد عنوان يو آر إل أولا" ,
        please_select_the_value_in_start_date_media_episode_publishing_error_message: "الرجاء تحديد القيمة في تاريخ البدء" ,
        please_select_the_value_in_end_date_media_episode_publishing_error_message: "الرجاء تحديد القيمة في تاريخ الانتهاء" ,
        you_delete_this_deal_right_from_contract_only_media_episode_publishing_error_message: "أنت تحذف هذه الصفقة مباشرة من العقد فقط !!" ,
        please_select_ottvideo_type_media_episode_video_error_message: "الرجاء تحديد نوع الفيديو" ,
        create_dialog_header: "إنشاء" ,
        synopsis_can_not_be_null_media_episode_Synopsis_error_message: "ملخص لا يمكن أن يكون لاغيا" ,
        please_select_language_media_episode_Synopsis_error_message: "الرجاء اختيار اللغة",
        please_enter_name_cast_crew_media_episode_error_message: "الرجاء إدخال الاسم" ,
        please_select_casttype_cast_crew_media_episode_error_message: "الرجاء إدخال نوع الإرسال" ,
        please_select_segment_type_media_episode_version_error_message: "الرجاء اختيار نوع الشريحة",
        please_generate_segments_first_media_episode_version_error_message: "يرجى إنشاء شرائح" ,
        media_episode_entity_duration_segment_error_message: "المدة الفعلية فارغة. يرجى تحديث المدة الصالحة في تجزئة المدة المراد تطبيقها ",
        please_select_brand_media_episode_error_message: "الرجاء اختيار العلامة التجارية" ,
        please_select_product_media_episode_error_message: "الرجاء اختيار المنتج" ,
        please_enter_value_media_episode_error_message: "الرجاء إدخال القيمة" ,
        please_enter_valid_value_tcin_should_not_be_greater_than_tcout_media_episode_error_message: "الرجاء إدخال قيمة صالحة , يجب ألا يكون TcIn أكبر من TcOut" ,
        error_while_generating_house_number_media_episode_error_message: "خطأ أثناء إنشاء رقم المنزل" ,
        already_exists_global_error_message: "موجود بالفعل!!" ,
        you_can_update_this_deal_right_from_contract_only_media_episode_publishing_error_message: "يمكنك تحديث هذه الصفقة مباشرة من العقد فقط !!" ,
        this_segment_is_already_been_scheduled_segmnet_media_episode_error_message: "تم تحديد موعد هذا الجزء بالفعل" ,
        please_enter_title_display_rails_error_message: "الرجاء إدخال عرض العنوان" ,
        atleast_attach_one_ottassets_rails_error_message: "على الأقل إرفاق أصول أوت واحد" ,
        please_enter_enter_icon_uel_error_message: "الرجاء إدخال عنوان يو آر إل للرمز" ,
        create_label: "إنشاء" ,
        edit_lable: "تحرير" ,
        select_label: "تحديد" ,
        please_select_media_category_type_first_media_episode_group_error_message: "الرجاء تحديد نوع فئة الوسائط أولا" ,
        please_select_role_users_error_message: "الرجاء اختيار الدور" ,
        user_name_error_message: "مستخدم / تنفيذي مبيعات بنفس اسم المستخدم" ,
        no_data_found_scheduling_reconciliation_error_message: "لم يتم العثور على بيانات!" ,
        schedule_and_as_run_data_load_successfully_scheduling_reconciliation_success_message: "جدولة و أسرشن البيانات التي تم تحميلها بنجاح" ,
        error_while_loading_as_run_data_scheduling_reconciliation_error_message: "خطأ أثناء تحميل بيانات أسرشن:" ,
        auto_recocile_successfully_scheduling_reconciliation_success_message: "تمت التسوية التلقائية بنجاح !!" ,
        select_first_scheduling_reconciliation_error_message: "الرجاء تحديد الجدول الزمني وكعنصر تشغيل للمباراة!" ,
        already_reconsiled_scheduling_reconciliation_error_message: "تمت تسويته بالفعل!" ,
        select_item_have_different_assetid_scheduling_reconciliation_error_message: "العنصر المحدد له معرف أصول مختلف!" ,
        force_unmatch_successfully_scheduling_reconciliation_success_message: "قوة لا مثيل لها بنجاح !!" ,
        please_select_schedule_and_as_run_item_scheduling_reconciliation_error_message: "الرجاء تحديد الجدول الزمني وعنصر أسرشن!" ,
        select_item_is_not_reconsiled_scheduling_reconciliation_error_message: "لم يتم إعادة تسوية العنصر المحدد!" ,
        select_schedule_and_asrun_item_not_reconciled_together_scheduling_reconciliation_error_message: "لم يتم التوفيق بين الجدول الزمني المحدد والبند الذي تم تشغيله معا" ,
        select_schedule_item_already_billed_error_message: "تمت فوترة عنصر الجدول المحدد بالفعل.",
        saved_successfully_scheduling_reconciliation_success_message: "تم الحفظ بنجاح !!" ,
        role_type_label: "نوع الدور" ,
        please_load_first_scheduling_reconciliation_error_message: "الرجاء التحميل أولا !!" ,
        tx_mode_label: "وضع تكساس" ,
        segment_count_label: "عدد الشرائح" ,
        create_segment_type_dialog_header: "إنشاء نوع شريحة" ,
        please_enter_type_name_segment_type_error_message: "الرجاء إدخال اسم النوع" ,
        please_select_tx_mode_segment_type_error_message: "الرجاء تحديد وضع تكساس" ,
        please_enter_segment_count_segment_type_error_message: "الرجاء إدخال عدد الشرائح",
        interstitial_group_sub_menu: "المجموعة الخلالية" ,
        program_group_sub_menu: "مجموعة البرامج" ,
        variation_page_breadcrumb: "الاختلاف" ,
        breakformat_page_breadcrumb: "تنسيق الفاصل" ,
        payment_provider_page_breadcrumb: "مزود الدفع" ,
        ott_period_type_page_breadcrumb: "نوع فترة ياتت" ,
        payment_type_page_breadcrumb: "نوع الدفع" ,
        ott_period_page_breadcrumb: "الفترة" ,
        subscriber_page_breadcrumb: "مشترك" ,
        media_type_label: "نوع الوسائط" ,
        atleast_attach_one_item_variation_error_message: "إرفاق عنصر واحد على الأقل" ,
        please_select_media_type_error_message: "الرجاء تحديد نوع الوسائط" ,
        season_number_should_not_be_zero_error_message: "يجب أن يكون رقم الموسم أكبر من الصفر" ,
        number_of_epiosde_should_not_be_zero_error_message: "يجب أن يكون عدد الحلقات أكبر من الصفر" ,
        starting_epiosde_should_not_be_zero_error_message: "يجب أن تكون حلقة البداية أكبر من الصفر" ,
        please_select_potraitimageurl_otteditform_error_message: "الرجاء تحديد عنوان يو آر إل لصورة لَوحَة" ,
        please_select_landscapeimageurl_otteditform_error_message: "الرجاء تحديد عنوان يو آر إل للصورة الأفقية" ,
        include_pre_opening_label: "تضمين ما قبل الافتتاح" ,
        include_closing_label: "تضمين الإغلاق" ,
        break_number_column: "رقم الفاصل" ,
        break_duration_column: "مدة الاستراحة" ,
        commercial_duration_column: "المدة التجارية" ,
        promo_duration_Column: "مدة العرض الترويجي" ,
        total_break_duration_label: "إجمالي مدة الاستراحة" ,
        segment_count_should_be_zero_error_message: "يجب أن يكون عدد الشرائح أكبر من واحد" ,
        invalid_slot_duration_error_message: "مدة الفتحة غير صالحة" ,
        invalid_total_break_duration_error_message: "إجمالي مدة الاستراحة غير صالحة" ,
        break_not_distribute_breakformat_error_message: "فواصل غير موزعة" ,
        slot_duration_should_Be_greater_than_total_break_duration_breakformat_error_message: "يجب أن تكون مدة الفتحة أكبر من إجمالي مدة الاستراحة" ,
        break_count_segment_count_does_not_match_breakformat_error_message: "عدد الكسر وعدد الشرائح غير متطابقين" ,
        please_enter_template_name_error_toast: "الرجاء إدخال اسم القالب" ,
        please_select_module_type_error_message: "الرجاء تحديد نوع الوحدة" ,
        please_select_ingestion_template_type_error_message: "الرجاء تحديد نوع قالب الابتلاع" ,
        saved_successfully_message: "تم الحفظ بنجاح" ,
        please_enter_code_error_message: "الرجاء إدخال الرمز" ,
        please_select_agency_error_message: "الرجاء اختيار الوكالة",
        please_select_default_tax_error_message: "الرجاء تحديد الضريبة الافتراضية" ,
        please_select_atleast_one_Sales_executive: "يرجى اختيار مسؤول مبيعات واحد على الأقل" ,
        add_sales_executive_button_tooltip: "إضافة تنفيذي مبيعات" ,
        please_enter_prefix_error_message: "الرجاء إدخال البادئة" ,
        please_enter_suffix_error_message: "الرجاء إدخال لاحقة" ,
        please_enter_next_number_error_message: "الرجاء إدخال الرقم التالي" ,
        please_enter_number_length_error_message: "الرجاء إدخال طول الرقم" ,
        please_attach_atleast_one_media_error_message: "يرجى إرفاق وسائط واحدة على الأقل" ,
        please_enter_full_Channel_name_error_message: "الرجاء إدخال اسم القناة بالكامل",
        please_enter_short_Channel_name_error_message: "الرجاء إدخال اسم القناة القصيرة" ,
        please_select_atleast_one_repeat_pattern_error_message: "الرجاء تحديد تكرار واحد على الأقل!" ,
        primary_delete_succesfully_repeat_pattern_error_message: "الحذف الأساسي بنجاح" ,
        repeat_delete_succesfully_repeat_pattern_error_message: "كرر الحذف بنجاح" ,
        please_select_atleast_one_days_repeat_pattern_error_message: "يرجى تحديد يوم واحد على الأقل!" ,
        please_enter_automation_channel_name_error_message: "الرجاء إدخال اسم قناة الأتمتة" ,
        please_enter_epg_code_error_message: "الرجاء إدخال رمز دليل البرامج الإلكتروني" ,
        please_select_automation_error_message: "الرجاء اختيار الأتمتة" ,
        please_enter_color_error_message: "الرجاء إدخال اللون" ,
        please_enter_valid_start_time_message: "الرجاء إدخال وقت بدء صالح" ,
        please_add_atleast_one_module_role_error_message: "الرجاء إضافة وحدة واحدة على الأقل!" ,
        data_loaded_successfully_ingestion_template_message: "تم تحميل البيانات بنجاح" ,
        please_enter_positive_value_in_creditlimit_error_message: "الرجاء إدخال قيمة موجبة في الحد الائتماني",
        please_enter_gst_no_error_message: "الرجاء إدخال رقم ضريبة السلع والخدمات",
        please_enter_campaign_no_error_message: "الرجاء إدخال رقم الحملة" ,
        please_select_client_error_message: "الرجاء اختيار العميل" ,
        period_from_should_be_less_then_periodto_campaign_error_message: "يجب أن تكون الفترة من أقل من الفترة إلى" ,
        commission_exceeded_from_limit_campaign_error_message: "تجاوزت العمولة من الحد",
        discount_exceeded_from_limit_campaign_error_message: "تجاوز الخصم من الحد",
        please_enter_target_budget_error_message: "الرجاء إدخال الميزانية المستهدفة",
        please_enter_target_spots_error_message: "الرجاء إدخال النقاط المستهدفة" ,
        please_enter_target_seconds_error_message: "الرجاء إدخال الثواني المستهدفة" ,
        please_attach_atleast_one_commercials_campaign_error_message: "يرجى إرفاق إعلان تجاري واحد على الأقل" ,
        please_attach_atleast_one_channel_campaign_error_message: "يرجى إرفاق قناة واحدة على الأقل" ,
        please_select_campaign_type_error_message: "الرجاء اختيار نوع الحملة",
        please_enter_rate10sec_error_message: "يرجى تحديد معدل ١٠ ثوان",
        please_enter_fct_error_message: "الرجاء إدخال إف سي تي" ,
        please_enter_from_date_error_message: "الرجاء إدخال من التاريخ" ,
        please_enter_to_date_error_message: "الرجاء إدخال حتى الآن" ,
        please_enter_amount_error_message: "الرجاء إدخال المبلغ" ,
        data_loading_error_message: "تحميل البيانات ...",
        no_record_found_error_message: "لم يتم العثور على سجل" ,
        total_sum_spot_rate_success_message: "إجمالي معدل المجموع الفوري" ,
        total_fct_success_message: "إجمالي إف سي تي" ,
        booking_is_used_in_schedule_show_spot_error_message: "يتم استخدام الحجز في الجدول الزمني" ,
        changes_Will_Reflect_in_new_planned_items_only_segmenttype_error_message: "ستنعكس التغييرات في البنود الجديدة المخطط لها فقط" ,
        please_select_campaign_first_booking_error_message: "الرجاء اختيار الحملة أولا",
        error_reading_file_booking_error_message: "خطأ في قراءة الملف" ,
        wrong_month_selected_booking_error_message: "تم تحديد شهر خاطئ" ,
        wrong_year_selected_booking_error_message: "تم اختيار سنة خاطئة" ,
        error_downloading_template_booking_error_message: "خطأ في تنزيل القالب" ,
        please_select_a_value_to_update_booking_error_message: "الرجاء تحديد قيمة لتحديثها" ,
        exceding_target_spots_limit_booking_error_message: "تجاوز حد النقاط المستهدفة" ,
        exceding_target_budget_booking_error_message: "تجاوز الميزانية المستهدفة" ,
        commercials_not_updated_in_line_no_booking_error_message: "الإعلانات التجارية غير محدثة في السطر رقم" ,
        exceding_target_seconds_booking_error_message: "تجاوز الثواني المستهدفة" ,
        booking_saved_booking_error_message: "تم حفظ الحجز" ,
        no_data_found_booking_error_message: "لم يتم العثور على بيانات" ,
        from_time_should_be_less_than_To_time_booking_error_message: "من وقت يجب أن يكون أقل من ذلك إلى الوقت" ,
        please_enter_spot_booking_error_message: "الرجاء إدخال البقعة" ,
        please_enter_rate_booking_error_message: "الرجاء إدخال السعر" ,
        please_select_transfer_from_date_booking_transfer_error_message: "يرجى تحديد التحويل من التاريخ" ,
        please_select_transfer_date_booking_transfer_error_message: "يرجى تحديد تاريخ النقل" ,
        please_select_item_booking_transfer_error_message: "الرجاء تحديد العنصر أولا!" ,
        booking_transfer_succesfully_to_booking_transfer_error_message: "نقل الحجز بنجاح إلى" ,
        exceding_target_spots_limit_update_booking_line_number_error_message: "تجاوز حد النقاط المستهدفة" ,
        exceding_target_budget_update_booking_line_number_error_message: "تجاوز الميزانية المستهدفة" ,
        exceding_target_seconds_update_booking_line_number_error_message: "تجاوز الثواني المستهدفة" ,
        loading_data_update_booking_line_number_error_message: "تحميل البيانات !!" ,
        data_loaded_update_booking_line_number_success_message: "تم تحميل البيانات بنجاح !!" ,
        loading_booking_update_booking_line_number_success_message: "جاري تحميل الحجز...",
        bill_is_already_generated_for_this_linenumber_update_booking_line_number_success_message: "تم إنشاء فاتورة بالفعل لرقم الخط هذا !!" ,
        please_select_file_error_message: "الرجاء تحديد الملف" ,
        downlaod_file_error_message: "تنزيل الملف" ,
        no_import_type_defined_error_message: "لم يتم تحديد نوع الاستيراد" ,
        upload_Successfully_import_success_message: "تم التحميل بنجاح" ,
        asrun_Save_Successfully_import_success_message: "حفظ أسرشن بنجاح" ,
        asrun_save_failed_import_error_message: "فشل حفظ أسرشن" ,
        update_failed_import_error_message: "فشل التحميل" ,
        assest_not_found_import_message: "لم يتم العثور على مساعدة" ,
        planning_imported_successfully_import_message: "تم استيراد التخطيط بنجاح" ,
        import_planning_failed_import_message: "فشل تخطيط الاستيراد",
        read_to_upload_import_message: "قراءة للتحميل" ,
        please_select_from_date: "يرجى الاختيار من التاريخ" ,
        please_select_to_date_greater_than_from_date_error_message: "يرجى تحديد إلى تاريخ أكبر من من التاريخ" ,
        please_select_the_value_in_template_name_first_export_report_error_message: "الرجاء تحديد القيمة في اسم القالب أولا!" ,
        please_select_the_value_in_format_first_export_report_error_message: "الرجاء تحديد القيمة بالتنسيق أولا" ,
        please_add_at_least_one_item_list_export_report_error_message: "الرجاء إضافة قائمة عناصر واحدة على الأقل." ,
        please_select_the_value_in_filters_first_export_report_error_message: "الرجاء تحديد القيمة في عوامل التصفية أولا." ,
        Please_select_the_value_in_Operator_first_export_report_error_message: "الرجاء تحديد القيمة في المشغل أولا." ,
        value_can_not_be_null_export_report_error_message: "القيمة لا يمكن أن تكون فارغة" ,
        report_is_exported_successfully_message: "تم تصدير دليل البرامج الإلكتروني بنجاح" ,
        please_select_valid_dates_export_template_error_message: "يرجى تحديد تواريخ صالحة" ,
        exporting_data_please_wait_export_template_error_message: "تصدير البيانات يرجى الانتظار ....",
        successfully_exported_export_template_message: "تم تصديرها بنجاح" ,
        no_data_found_to_export_template_message: "لم يتم العثور على بيانات للتصدير" ,
        please_select_the_value_in_available_column_export_template_error_message: "الرجاء تحديد القيمة في العمود المتاح" ,
        please_enter_display_name_error_message: "الرجاء إدخال اسم العرض" ,
        please_enter_savetempleteas_export_template_error_message: "الرجاء إدخال حفظ تمبليت باسم" ,
        please_add_column_atleast_one_item_export_template_error_message: "الرجاء إضافة عمود واحد على الأقل" ,
        is_saved_successfully_export_template_message: "تم الحفظ بنجاح" ,
        please_select_module_error_message: "الرجاء اختيار الوحدة النمطية" ,
        please_enter_id_error_message: "الرجاء إدخال المعرف" ,
        please_select_source_error_message: "الرجاء اختيار المصدر" ,
        please_select_atleast_one_secondary_events_error_message: "يرجى تحديد حدث ثانوي واحد على الأقل" ,
        invalid_startoffset_error_message: "إزاحة بدء غير صالحة" ,
        invalid_endoffset_error_message: "إزاحة نهاية غير صالحة" ,
        please_enter_text_no_error_message: "الرجاء إدخال رقم النص" ,
        please_select_the_value_in_rule_first_error_message: "الرجاء تحديد القيمة في القاعدة أولا" ,
        please_attach_atleast_one_prototype_stylesheet_error_message: "يرجى إرفاق نموذج أولي واحد على الأقل" ,
        please_enter_formula_error_message: "الرجاء إدخال الصيغة" ,
        please_wait_loading_data_billing_error_message: "الرجاء الانتظار , تحميل البيانات ..." ,
        data_loaded_successfully_billing_template_message: "تم تحميل البيانات بنجاح" ,
        please_select_a_billing_detail_first_billing_template_message: "الرجاء تحديد تفاصيل الفوترة أولا!" ,
        no_reconciled_spots_found_for_selected_details_billing_template_message: "لم يتم العثور على نقاط توفيق للتفاصيل المحددة!" ,
        please_select_bank_billing_error_message: "الرجاء اختيار البنك!" ,
        please_select_tax_billing_error_message: "الرجاء اختيار الضريبة!" ,
        please_select_brand_billing_error_message: "الرجاء اختيار العلامة التجارية!" ,
        no_invoice_details_are_available_to_bill_billing_error_message: "لا تتوفر تفاصيل الفاتورة للفاتورة!" ,
        campaign_brands_label: "العلامة التجارية" ,       
        all_label: "الكل" ,
        selected_brand: "العلامة التجارية المختارة" ,
        selected_channel: "القناة المختارة" ,
        brand_label: "العلامة التجارية" ,
        please_select_file_first_datamigration_error_message: "الرجاء تحديد الملف أولا !!" ,
        empty_media_grid_datamigration_error_message: "يرجى تحميل البيانات في الشبكة أدناه للاستيراد !!" ,
        please_select_type_datamigration_error_message: "الرجاء تحديد النوع !!",
        imported_successfully_datamigration_success_message: "تم الاستيراد بنجاح" ,
        please_select_language_error_message: "الرجاء اختيار اللغة",
        please_select_logo_url_first_error_mesage: "الرجاء تحديد عنوان يو آر إل للشعار أولا." ,
        please_select_segment_type_and_channel_format_error_message: "يرجى اختيار نوع الشريحة والقناة",
        new_create_formfield_dialog_header: "إنشاء جديد" ,
        please_select_segment_type_format_error_message: "الرجاء اختيار نوع الشريحة",
        not_allowed_for_segments_format_error_message: "غير مسموح به للشرائح" ,
        cannot_delete_segments_format_error_message: "لا يمكن حذف شريحة" ,
        select_atleast_one_mediaDealRight_planner_error_message: "حدد صفقة إعلامية واحدة على الأقل بشكل صحيح" ,
        first_copy_planner_items_you_want_to_paste_planner_error_message: "عناصر مخطط النسخ الأولى التي تريد لصقها.",
        please_select_area_on_planner_where_to_paste_planner_error_message: "يرجى تحديد المنطقة في المخطط حيث يتم لصقها." ,
        from_date_cant_be_greater_than_to_date_paste_planner_error_message: "من التاريخ لا يمكن أن يكون أكبر من التاريخ!" ,
        call_planner_error_message: "دعوة" ,
        data_copy_schedule_please_wait_copyschedule_error_message: 'الرجاء الانتظار....',
        copy_schedule_data_copyschedule_successfully_message: "نسخ نسخ بيانات الجدول بنجاح .....",
        no_data_found_to_copy_schedule_error_message: "لم يتم العثور على بيانات لنسخ الجدول الزمني" ,
        applying_recursive_pattern_please_wait_planner_error_message: "تطبيق نمط العودية , يرجى الانتظار ..." ,
        recursive_pattern_applied_successfully_planner_success_message: "تم تطبيق النمط العودي بنجاح" ,
        pasted_pattern_planner_error_message: "لصق" ,
        items_pattern_planner_error_message: "العناصر" ,
        to_date_is_mandatory_recursive_error_message: "حتى الآن إلزامي",
        to_date_should_be_greater_than_base_program_start_date_recursive_error_message: "حتى الآن يجب أن يكون أكبر من تاريخ بدء البرنامج الأساسي" ,
        select_one_item_to_open_schedule_planner_helper_error_message: "حدد عنصرا واحدا لفتح الجدول الزمني" ,
        copy_button_text: "نسخ" ,
        open_schedule_button_text: "الجدول المفتوح" ,
        paste_button_text: "لصق" ,
        delete_button_text: "حذف" ,
        error_fetching_channel_list_planning_wrapper_error_message: "خطأ في جلب قائمة القنوات" ,
        please_select_planning_item_planning_wrapper_error_message: "الرجاء تحديد عنصر التخطيط" ,
        recursive_applied_successfully_planning_wrapper_message: 'تطبيق العودية بنجاح !!',
        select_program_group_label: "حدد مجموعة البرامج" ,
        please_enter_template_name_to_save_template_planningfilter_error_message: "الرجاء إدخال اسم القالب لحفظ القالب" ,
        selected_content_text_planning_footer: "المحتوى المحدد" ,
        copied_content_text_planning_footer: "المحتوى المنسوخ" ,
        first_copy_planner_items_you_want_to_paste_planning_performance_error_message: "عناصر مخطط النسخ الأولى التي تريد لصقها." ,
        please_select_area_on_planner_where_to_paste_planning_performance_error_message: "يرجى تحديد المنطقة في المخطط حيث يتم اللصق." ,
        select_atleast_one_mediadealright_planning_performance_error_message: "حدد صفقة واحدة على الأقل بشكل صحيح" ,
        first_select_replace_items_global_replacement_error_message: "حدد أولا استبدال العناصر" ,
        please_select_content_to_delete_planning_performance_error_message: "الرجاء تحديد المحتوى المراد حذفه",
        not_allowed_Control_with_schedule_planning_performance_error_message: "غير مسموح به , التحكم مع الجدول الزمني" ,
        entry_already_exist_try_with_different_values_collection_error_message: "الإدخال موجود بالفعل , حاول بقيم مختلفة." ,
        please_select_first_collection_error_message: "الرجاء الاختيار أولا!" ,
        already_attached_collection_error_message: "مرفق بالفعل!!" ,
        export_current_booking_tooltip: "تصدير الحجز الحالي" ,
        export_saved_booking_tooltip: "تصدير الحجز المحفوظ" ,
        please_select_poster_type_error_message: "الرجاء اختيار نوع الملصق",
        browser_button_text: "تصفح" ,
        file_selected_label: "الملف المحدد" ,
        only_jpg_jpeg_and_png_files_are_allowed_msg: "يسمح فقط بملفات ي ب ز / ي ب ه ز و بي إن جي!" ,
        please_add_programs_format_error_message: "الرجاء إضافة تنسيق البرامج" ,
        please_select_atleast_one_item: "يرجى تحديد عنصر واحد على الأقل." ,
        please_enter_season_number_error_message: "الرجاء إدخال رقم الموسم" ,
        repeat_pattern_label: "تكرار النمط" ,
        select_repeat_pattern_collection_header: "حدد نمط التكرار" ,
        please_select_from_date_error_message: "يرجى الاختيار من التاريخ" ,
        please_select_to_date_error_message: "الرجاء الاختيار حتى الآن" ,
        please_select_to_date_grater_then_from_date_error_message: "الرجاء تحديد تاريخ أكبر من من التاريخ" ,
        parent_content_category_label: "فئة المحتوى الأصلي" ,
        rail_will_be_added_soon2_label: "سيتم إضافة السكك الحديدية قريبا2!" ,
        rail_will_be_added_soon3_label: "ستتم إضافة السكك الحديدية قريبا3!" ,
        no_select_image_error_message: "لا توجد صورة محددة" ,
        please_enter_key_error_message: "الرجاء إدخال المفتاح" ,
        please_enter_value_error_message: "الرجاء إدخال القيمة" ,
        please_wait_generating_bill: "توليد الفاتورة. يرجى الانتظار...",
        bill_generated_successfully: "تم إنشاء الفاتورة بنجاح" ,
        please_select_template_error_message: "الرجاء اختيار النموذج" ,
        please_select_file_first_error_message: "الرجاء تحديد الملف أولا!" ,
        please_enter_first_error_message: "الرجاء الدخول أولا!" ,
        please_select_channel_first: "الرجاء اختيار القناة أولا",
        please_select_start_date_error_message: "الرجاء تحديد تاريخ البدء" ,
        please_select_end_date_error_message: "الرجاء تحديد تاريخ الانتهاء" ,
        please_enter_valid_sequnce_number_error_message: "الرجاء إدخال رقم تسلسل صحيح" ,
        program_schedule_clash_breadcrumb: "اشتباك جدول البرنامج" ,
        clash_channel_column: "قناة الصدام" ,
        clashed_time_Column: "اشتبك الوقت" ,
        source_time_Column: "وقت المصدر" ,
        no_data_found_error_message: "لم يتم العثور على بيانات!" ,
        movie_name_column: "اسم الفيلم" ,
        create_ott_period_type_dialog_header: "إنشاء فترة ياتت" ,
        create_subscriber_dialog_header: "إنشاء مشترك" ,
        play_type_label: "نوع اللعب" ,
        ott_period_type_label: "نوع فترة ياتت" ,
        please_enter_play_time_error_message: "يجب أن يكون وقت اللعب أكبر من الصفر" ,
        please_select_play_type_error_message: "الرجاء تحديد نوع اللعب" ,
        please_select_ott_period_type_error_message: "الرجاء تحديد نوع فترة ياتت" ,
        ott_period_label: "فترة أوت" ,
        payment_type_label: "نوع الدفع" ,
        trials_tab_label: "المحاكمات" ,
        fixed_trials_tab_label: "المحاكمات الثابتة" ,
        reminders_tab_label: "تذكيرات" ,
        payment_Provider_label: "مزود الدفع" ,
        send_label: "تذكير" ,
        dayPrior_label: "يوم التذكير المسبق" ,
        enable: "تمكين" ,
        trials_dialog_header: "المحاكمات" ,
        reminders_dialog_header: "تذكيرات" ,
        add_trial_button_tooltip: "إضافة نسخة تجريبية" ,
        add_reminders_button_tooltip: "إضافة تذكيرات" ,
        paymentPlan_sub_menu: "خطة الدفع" ,
        please_select_Payment_Provider_error_message: "يرجى تحديد مزود الدفع" ,
        please_select_PlayType_error_message: "الرجاء تحديد نوع اللعب" ,
        please_select_OttPeriod_error_message: "الرجاء تحديد فترة ياتت" ,
        please_select_Currency_error_message: "الرجاء اختيار العملة",
        please_select_PaymentType_error_message: "يرجى تحديد نوع الدفع",
        ott_product_sub_menu: "المنتج" ,
        ott_product_label: "المنتج" ,
        sales_status_label: "حالة المبيعات" ,
        geo_Region_label: "المنطقة الجغرافية" ,
        create_ott_product_type_dialog_header: "إنشاء منتج ياتت" ,
        please_select_geo_region_error_message: "الرجاء تحديد المنطقة الجغرافية",
        please_select_sales_status_error_message: "الرجاء تحديد حالة المبيعات" ,
        please_select_Payment_Plan_error_message: "يرجى تحديد خطة الدفع" ,
        show_app_bar_label: "إظهار شريط التطبيقات" ,
        isHomePage_label: "هي الصفحة الرئيسية" ,
        route_type_label: "نوع المسار" ,
        page_label: "الصفحة" ,
        rails_l_label: "ل" ,
        rails_uat_label: "ش" ,
        live_label: "مباشر" ,
        uat_label: "يو ايه تي" ,
        media_is_already_attached_with_another_asset_error_message: "الوسائط مرتبطة بالفعل بأصل آخر" ,
        add_payment_plan_tooltip: "إضافة خطة دفع" ,
        trial_price_label: "سعر المحاكمة" ,
        trial_days_label: "أيام المحاكمة" ,
        sort_index_label: "فرز الفهرس" ,
        hide: "إخفاء" ,
        select_payment_plan_collection: "حدد خطة الدفع" ,
        payments_dialog_header: "المدفوعات" ,
        daysBefore_label: "قبل أيام" ,
        subscription_id_label: "معرف الاشتراك" ,
        package_label: "الحزمة" ,
        last_updated_label: "آخر تحديث" ,
        please_enter_subscription_id_error_message: "الرجاء إدخال معرف الاشتراك" ,
        please_enter_package_error_message: "الرجاء إدخال الحزمة" ,
        please_select_status_error_message: "الرجاء اختيار الحالة",
        please_enter_last_update_error_message: "الرجاء إدخال آخر تحديث" ,
        please_select_route_type: "الرجاء تحديد نوع المسار",
        please_select_video_playback_url: "الرجاء تحديد عنوان يو آر إل لتشغيل الفيديو" ,
        please_select_page: "الرجاء اختيار الصفحة",
        ott_campaign_page_breadcrumb: "الحملة" ,
        edit_campaign_editpage_header: "تحرير حملة ياتت" ,
        add_rails_button_tootip: "إضافة قضبان" ,
        select_rails_collection: "حدد القضبان" ,
        publish_column: "ينشر" ,
        from_date_should_be_less_than_to_date_error_message: "من التاريخ يجب أن يكون أقل من التاريخ" ,
        please_select_rails_error_message: "يرجى تحديد سكة واحدة على الأقل" ,
        ott_rails_type_column: "نوع القضبان القطنية" ,
        add_above_option: "أضف أعلاه" ,
        add_below_option: "أضف أدناه" ,
        select_any_one_collection_selection_error_message: "حدد أي واحد" ,
        please_attach_media_first: "يرجى إرفاق الوسائط" ,
        edit_actionbutton_option: "تحرير" ,
        profile_label: "الملف الشخصي" ,
        asset_label: "معرف مادة العرض",
        job_id_label: "معرف الوظيفة" ,
        job_started_label: "بدأت" ,
        job_finished_label: "انتهى" ,
        elapsed_label: "انقضت" ,
        job_input_file_label: "ملف الإدخال" ,
        media_type_column: "نوع الوسائط" ,
        file_location_column: "موقع الملف" ,
        attach_media_button_tooltip: "إرفاق الوسائط" ,
        attach_media_button_text: "إرفاق الوسائط" ,
        select_media_collection_header: "حدد الوسائط" ,
        assets_label_ott_collection: "الأصول" ,
        select_assets_collection_header: "حدد الأصول" ,
        some_bookings_are_already_Scheduled_Could_not_transfer_error_message: "بعض الحجوزات مجدولة بالفعل , لا يمكن نقلها" ,
        jobs_title: "الوظائف" ,
        total_assets_label: "إجمالي الأصول" ,
        makegoodspot_sub_menu: "جعل بقعة جيدة" ,
        makegoodspot_title: "جعل بقعة جيدة" ,
        makegoodspottransfer_dialog_header: "إجراء نقل فوري جيد" ,
        select_item_date_between_from_and_to_error_message: "يجب أن يكون تاريخ النقل المحدد بين فترة الحملة من وتاريخه" ,
        please_transfer_error_date_greater_than_bookingdate_message: "يرجى تحديد تاريخ نقل أكبر من تاريخ الحجز الحالي!" ,
        latest_jobs_label: "أحدث الوظائف" ,
        invalid_date_range_copy_to_planning_error_message: "نسخة غير صالحة إلى النطاق التاريخي" ,
        invalid_date_range_copy_from_planning_error_message : "نسخة غير صالحة من النطاق الزمني",
        date_range_between_seven_day_copy_from_planning_error_message: "يجب أن يكون نطاق النسخ من التاريخ في غضون سبعة أيام" ,
        date_range_between_seven_day_copy_to_planning_error_message: "يجب أن يكون نطاق النسخ إلى التاريخ في غضون سبعة أيام" ,
        height_label: "الارتفاع" ,
        create_ott_asset_label: "إنشاء أصول ياتت" ,
        media_label: "وسائل الإعلام" ,
        recent_jobs_label: "الوظائف الأخيرة" ,
        please_select_type_error_message: "الرجاء اختيار النوع",
        create_schedule_constraints_editpage_header: "إنشاء قيود الجدول الزمني" ,
        schedule_constraints_error_message: "قيود الجدول الزمني" ,
        constraints_lable: "القيود" ,
        fill_under_run_button_tooltip: "ملء تحت تشغيل مع الخلالي" ,
        please_select_amount_error_message: "الرجاء تحديد المبلغ" ,
        please_enter_sort_index_error_message: "الرجاء إدخال فهرس الفرز" ,
        please_enter_trial_price_error_message: "الرجاء إدخال سعر التجربة" ,
        please_enter_trial_days_error_message: "الرجاء إدخال أيام المحاكمة" ,
        please_select_reminder_error_message: "الرجاء اختيار تذكير" ,
        please_enter_days_before_error_message: "الرجاء إدخال الأيام السابقة" ,
        enable_reminder_label: "تمكين التذكير" ,
        coupon_page_breadcrumb: "القسيمة" ,
        discount_type_label: "نوع الخصم" ,
        discount_value_label: "قيمة الخصم" ,
        create_coupon_dialog_header: "إنشاء قسيمة" ,
        please_select_discount_type_error_message: "الرجاء اختيار نوع الخصم",
        percentage_can_not_be_greater_than_error_message: "لا يمكن أن تكون النسبة المئوية أكبر من مائة" ,
        enable_fixed_trial_label: "تمكين الإصدار التجريبي الثابت" ,
        limited_period_label: "فترة محدودة" ,
        valid_from_label: "صالح من" ,
        valid_to_label: "صالح حتى" ,
        please_select_valid_from_date_error_message: "الرجاء اختيار صالح من" ,
        please_select_valid_to_date_error_message: "الرجاء تحديد صالح إلى" ,
        discount_should_be_greater_then_zero_error_message: "يجب أن يكون الخصم أكبر من الصفر" ,
        please_select_valid_date_error_message: "يرجى تحديد تاريخ صالح" ,
        payment_plan_label: "خطة الدفع" ,
        coupon_label: "القسيمة" ,
        add_coupon_tooltip: "إضافة قسيمة" ,
        select_coupon_collection: "اختر القسيمة" ,
        code_column: "رمز" ,
        discount_type_column: "نوع الخصم" ,
        discount_value_column: "قيمة الخصم" ,
        audit_log_menu: "سجل التدقيق" ,
        audit_log_breadcrumb: "سجل التدقيق" ,
        genre_sub_menu_: "النوع" ,
        media_category_sub_menu: "فئة الوسائط" ,
        genre_page_breadcrumb: "النوع" ,
        media_category_page_breadcrumb: "فئة الوسائط" ,
        module_label: "وحدة" ,
        event_label: "حدث" ,
        please_select_event_error_message: "الرجاء اختيار الحدث" ,
        download_button_tooltip: "تحميل",
        date_time_label: "التاريخ والوقت" ,
        confirm_password_label: "تأكيد كلمة المرور" ,
        password_do_not_match_error_message: "كلمة المرور غير متطابقة" ,
        please_enter_confirm_password_users_error_message: "الرجاء إدخال تأكيد كلمة المرور" ,
        atleast_attach_atleast_one_platform_error_message: "يرجى تحديد منصة واحدة على الأقل" ,
        upload_file_button_tooltip: "تحميل ملف" ,
        role_tab_label: "الدور" ,
        add_channel_button_text: "إضافة قناة" ,
        select_channel_collection_header: "حدد القناة" ,
        please_add_atleast_one_channel_users_error_message: "الرجاء إضافة قناة واحدة على الأقل" ,
        please_select_order_date_error_message: "الرجاء تحديد تاريخ الطلب" ,
        please_select_period_from_date_error_message: "يرجى تحديد الفترة من" ,
        please_select_period_to_date_error_message: "يرجى تحديد الفترة إلى" ,
        please_select_deal_type_error_message: "الرجاء اختيار نوع الصفقة",
        please_select_location_error_message: "الرجاء اختيار الموقع",
        please_select_product_error_message: "الرجاء اختيار المنتج" ,
        ott_segment_sub_menu: "الشريحة" ,
        payment_plan_page_breadcrumb: "خطة الدفع" ,
        ott_segment_page_breadcrumb: "الشريحة" ,
        create_segment_dialog_header: "إنشاء شريحة" ,
        segment_label: "الشريحة" ,
        please_select_segment_error_message: "الرجاء اختيار شريحة",
        monetization_dashboard: "تحقيق الدخل" ,
        segment_by_subscribe_label: "الشريحة حسب المشترك" ,
        segment_by_product_label: "التقسيم حسب المنتج" ,
        products_column: "المنتجات" ,
        total_coupon_column: "إجمالي القسيمة" ,
        total_subscriber_column: "إجمالي المشتركين" ,
        coupon_name_column: "اسم القسيمة" ,
        pgrating_sub_menu: "تصنيف ص" ,
        pgrating_page_breadcrumb: "تصنيف ص" ,
        open_in_a_new_tab_label: "فتح في علامة تبويب جديدة" ,
        column_preference_label: "تفضيل العمود" ,
        parent_label: "الوالد" ,
        overrun_underrun_label: "تجاوز وأندر ران" ,
        copy_count_label: "عدد النسخ" ,
        selected_header_label: "الرأس المحدد" ,
        selected_count_label: "العدد المحدد" ,
        platform_column: "المنصة" ,
        please_enter_old_password_users_error_message: "الرجاء إدخال كلمة المرور القديمة" ,
        old_password_does_not_match_error_message: "كلمة المرور القديمة غير متطابقة" ,
        please_enter_new_password_error_message: "الرجاء إدخال كلمة مرور جديدة" ,
        confirm_password_do_not_match_error_message: "تأكيد كلمة المرور غير متطابقة" ,
        old_password_label: "كلمة المرور القديمة" ,
        new_password_label: "كلمة مرور جديدة" ,
        account_label: "الحساب" ,
        change_password_label: "تغيير كلمة المرور" ,
        log_out_label: "تسجيل الخروج" ,
        media_by_category_menu: "وسائل الإعلام حسب الفئة" ,
        movie_label: "فيلم" ,
        season_label: "الموسم" ,
        view_details_label: "عرض التفاصيل" ,
        executive_label: "تنفيذي" ,
        subscriber_label: "مشترك" ,
        confirm_logout_label: "تأكيد تسجيل الخروج" ,
        are_you_sure_you_want_to_logout: "هل أنت متأكد من أنك تريد تسجيل الخروج؟" ,
        yes_label: "نعم",
        no_label: "لا" ,
        confirm_delete_label: "تأكيد الحذف" ,
        are_you_sure_you_want_to_delete: "هل أنت متأكد من أنك تريد الحذف؟" ,
        scheduling_label: "الجدولة" ,
        planning_label: "التخطيط" ,
        options_label: "خيارات" ,
        un_archive_label: "إلغاء الأرشيف" ,
        create_from_label: "إنشاء من" ,
        category_label: "الفئة" ,
        select_category_label: "اختر الفئة" ,
        grid_view_label: "عرض الشبكة" ,
        tile_view_label: "عرض البلاط" ,
        linear_label: "خطي" ,
        non_linear_label: "غير خطي" ,
        audit_log_label: "سجل التدقيق" ,
        entry_date_and_time_column: "وقت تاريخ الدخول" ,
        event_column: "الحدث" ,
        user_column: "المستخدم" ,
        close_button_tooltip: "إغلاق" ,
        apply_recursive_button_text_planning: "تطبيق العودية" ,
        history_button_text_planning: "التاريخ" ,
        open_schedule_button_text_planning: "الجدول المفتوح" ,
        insert_program_group_label_planning: "إدراج مجموعة البرامج" ,
        export_planner_button_text: "مخطط التصدير" ,
        export_epg_button_text: "تصدير دليل البرامج الإلكتروني" ,
        planning_loaded_label: "تم تحميل التخطيط" ,
        planning_loading_error_label: "خطأ في تحميل التخطيط" ,
        error_fetching_planning_data_error_message: "خطأ في جلب بيانات التخطيط" ,
        planning_loading_label: "تخطيط التحميل" ,
        creating_label: "إنشاء" ,
        successfully_created_group_label: "تم إنشاء مجموعة بنجاح!" ,
        pasted_label: "لصق" ,
        items_label: "العناصر" ,
        last_updated_from_label_msg: "آخر تحديث من ",
        cannot_delete_segment_label_msg: "لا يمكن حذف شريحة" ,
        select_item_to_error_message: "حدد عنصرا إلى" ,
        cannot_cut_footer_msg_scheduling: "لا يمكن قص التذييل",
        cannot_delete_multiple_footer_msg_scheduling: "لا يمكن حذف تذييل متعدد." ,
        cannot_delete_multiple_footer_please_select_one_footer_at_a_time_msg_scheduling: "لا يمكن حذف تذييل متعدد، الرجاء تحديد تذييل واحد في كل مرة.",
        no_item_selected_to_copy_msg_scheduling: "لم يتم تحديد أي عنصر للنسخ" ,
        cannot_copy_segment_msg_scheduling: "لا يمكن النسخ - شريحة" ,
        cannot_copy_break_msg_scheduling: "لا يمكن نسخ - استراحة" ,
        deleting_footer_msg_scheduling: "حذف التذييل" ,
        footer_deleted_msg_scheduling: ' تذييل محذوف ',
        no_footer_to_deleted_error_message: "لا يوجد تذييل للحذف" ,
        all_footer_deleted_error_message: "تم حذف كل التذييل" ,
        there_are_some_at_server_side_please_contact_admin: "هناك البعض على جانب الخادم , يرجى الاتصال بالمسؤول" ,
        loading_schedule_please_wait_msg_scheduling: "جدول التحميل , يرجى الانتظار ..." ,
        loading_schedule_completed_msg_scheduling: "اكتمل جدول التحميل !!" ,
        please_selct_one_item_msg_scheduling: "يرجى اختيار عنصر واحد" ,
        no_media_selected_msg_scheduling: "لم يتم تحديد أي وسائط" ,
        deleted_secondary_events_msg_scheduling: "الأحداث الثانوية المحذوفة",
        all_secondary_events_deleted_successfully_msg_scheduling: "تم حذف جميع الأحداث الثانوية بنجاح !!" ,
        style_sheet_applied_successfully_msg_scheduling: "تم تطبيق ورقة الأنماط بنجاح !!" ,
        secondary_event_updated_msg_scheduling: "تم تحديث الحدث الثانوي" ,
        no_secondary_events_error_message: "لا توجد أحداث ثانوية" ,
        select_target_item_from_schedule_error_message: "حدد العنصر الهدف من الجدول" ,
        first_cut_or_copy_item_error_message: "أول قص أو نسخ عنصر." ,
        please_select_Item_to_paste_error_message: "الرجاء تحديد العنصر المراد لصقه." ,
        Please_select_one_Item_where_need_to_paste_error_message: "الرجاء تحديد عنصر واحد , حيث تحتاج إلى لصق." ,
        delete_msg: "حذف" ,
        cut_msg: "حذف" ,
        cannot_segment_please_unselect_segment_error_message: "لا يمكن التقسيم , يرجى إلغاء تحديد الشريحة" ,
        Confirmation_label: "تأكيد" ,
        are_you_sure_you_want_to_delete_footer_label: "هل أنت متأكد من أنك تريد حذف التذييل؟" ,
        Are_you_sure_you_want_to_items_label_confirm_alert: "هل أنت متأكد من أنك تريد ذلك" ,
        cannot_select_footer_error_message: "لا يمكن تحديد التذييل",
        cannot_multi_select_items_from_different_headers_error_message: "لا يمكن تحديد العناصر متعددة من رؤوس مختلفة." ,
        please_select_first_error_message: "الرجاء الاختيار أولا." ,
        cannot_select_items_from_different_headers_error_message: "لا يمكن تحديد عناصر من رؤوس مختلفة." ,
        please_choose_right_media_categor_error_message: "الرجاء اختيار فئة الوسائط المناسبة" ,
        prototype_drop_not_allowed_for_headers_error_message: "إسقاط النموذج الأولي غير مسموح به للرؤوس" ,
        break_type_item_is_not_allowed_error_message: "عنصر نوع الفاصل غير مسموح به." ,
        cannot_move_segment_to_different_program_schedule_error_message: "لا يمكن نقل الجزء إلى جدول برنامج مختلف" ,
        prototype_drop_not_allowed_for_headers_and_footers_error_message: "إسقاط النموذج الأولي غير مسموح به للرؤوس والتذييلات" ,
        please_select_header_firsts_error_message: "الرجاء تحديد الرأس أولا" ,
        cannot_delete_selected_header_contains_booked_commercials_error_message: 'لا يمكن حذف !! يحتوي العنوان المحدد على الإعلانات التجارية المحجوزة ',
        cannot_delete_selected_header_contains_reconciled_items_error_message: 'لا يمكن حذف !! يحتوي الرأس المحدد على عناصر تمت تسويتها ',
        confirm_delete_header_confirm_alert_msg: "تأكيد حذف الرأس" ,
        are_you_sure_you_want_to_delete_confirm_alert_msg: "هل أنت متأكد من أنك تريد حذف" ,
        select_one_program_to_replace_with_error_message: "حدد برنامجا واحدا لاستبداله" ,
        header_from_confirm_alert_msg: ' رأس من ',
        please_select_schedule_item_error_message: "الرجاء تحديد عنصر الجدول" ,
        not_allowed_on_this_postion_error_message: "غير مسموح به في هذا المنصب" ,
        please_select_one_item_error_message: "الرجاء اختيار عنصر واحد" ,
        no_secondary_events_attached_to_delete_error_message: "لا توجد أحداث ثانوية مرفقة بالحذف" ,
        prototype_drop_not_allowed_for_break_error_message: "إسقاط النموذج الأولي غير مسموح به للكسر" ,
        added_secondary_events_success_message: "الأحداث الثانوية المضافة" ,
        please_load_schedule_first_error_message: "يرجى تحميل الجدول أولا." ,
        variations_are_not_available_info_message: "الاختلافات غير متوفرة" ,
        process_completed_success_message: "اكتملت العملية" ,
        select_atleast_one_header_error_message: "حدد رأسا واحدا على الأقل" ,
        user_favourite_page_breadcrumb: "المفضلة" ,
        please_enter_template_name_to_save_template_error_message: "الرجاء إدخال اسم القالب لحفظ القالب" ,
        rmn_label: "ر م ن" ,
        please_enter_rmn_error_message: "الرجاء إدخال رقم ر م ن" ,
        expand_collapse_headers_label: "توسيع / طي الرؤوس" ,
        pages_label: "الصفحات" ,
        total_product_column: "إجمالي المنتج" ,
        update_synopsis: "تحديث الملخص" ,
        invalid_time_range: "النطاق الزمني غير صالح" ,
        schedule_automatic_break_tooltip: "إنشاء فواصل تلقائية" ,
        select_icon_url_collection_header: "حدد الرمز" ,
        planningdistributionformat_sub_menu: "تنسيق توزيع التخطيط" ,
        planningdistributionformat_page_breadcrumb: "تنسيق توزيع التخطيط" ,
        edit_planningdistributionformat_editpage_header: "تحرير تنسيق توزيع التخطيط" ,
        create_planningdistributionformat_editpage_header: "إنشاء تنسيق توزيع التخطيط" ,
        subtitle_label: "الترجمة" ,
        subtitle_type_lable: "نوع الترجمة" ,
        please_select_segment_type_error_message: "الرجاء اختيار نوع الشريحة",
        audio_track_label: "المسار الصوتي" ,
        track_number_label: "رقم المسار" ,
        add_language_group_prototype_button_tooltip: "إضافة مجموعة لغوية" ,
        add_language_group_prototype_button_text: "إضافة مجموعة لغوية" ,
        please_enter_track_number_error_message: "الرجاء إدخال رقم المسار" ,
        please_select_atleast_one_language_error_message: "يرجى تحديد لغة واحدة على الأقل" ,
        language_already_added_error_message: "تمت إضافة اللغة بالفعل" ,
        track_number_already_added_error_message: "رقم المسار مضاف بالفعل" ,
        please_select_atleast_one_rule_error_message: "الرجاء تحديد قاعدة واحدة على الأقل" ,
        update_template_label: "تحديث النموذج" ,
        media_category_type_sub_menu: "نوع فئة الوسائط" ,
        work_order_dashboard: "أمر العمل" ,
        todo_tile_label: "أن تفعل" ,
        in_progress_tile_label: "قيد التقدم" ,
        done_tile_label: "تم" ,
        workflow_label: "سير العمل" ,
        notes_label: "ملاحظات" ,
        please_select_atleast_one_role_error_message: "الرجاء تحديد دور واحد على الأقل" ,
        workflow_dialog_header: "إنشاء سير عمل" ,
        workorder_dialog_header: "إنشاء أمر عمل" ,
        roles_label: "الأدوار" ,
        workflow_page_breadcrumb: "سير العمل" ,
        workflow_menu: "سير العمل" ,
        current_status_column: "الوضع الحالي" ,
        created_date_column: "تاريخ الإنشاء" ,
        please_select_workflow_error_message: "الرجاء تحديد سير العمل" ,
        media_column: "وسائل الإعلام" ,
        transmission_label: "الإرسال" ,
        transmission_column: "الإرسال" ,
        mamID_label: "معرف مام" ,
        apply_distribution_button_text_planning: "توزيع التخطيط" ,
        min_gap_label: "الحد الأدنى للفجوة" ,
        apply_fix_gap_label: "تطبيق إصلاح الفجوة" ,
        plan_till_label: "خطة حتى" ,
        max_placement_label: "ماكس التنسيب" ,
        valid_days_label: "أيام صالحة" ,
        retry_gap_label: "إعادة محاولة الفجوة" ,
        planning_distribution_label: "تخطيط التوزيع" ,
        please_enter_minimum_gap_error_message: "الرجاء إدخال الحد الأدنى للفجوة" ,
        please_select_plan_till_date_error_message: "يرجى تحديد الخطة حتى تاريخه" ,
        please_enter_max_placement_error_message: "الرجاء إدخال الحد الأقصى للموضع" ,
        please_select_days_error_message: "يرجى تحديد الأيام" ,
        please_enter_retry_gap_error_message: "الرجاء إدخال فجوة إعادة المحاولة" ,
        please_select_starting_role_error_message: "الرجاء تحديد دور البداية" ,
        starting_role_label: "دور البداية" ,
        plan_till_date_cannot_be_less_than_original_planned_item_error_message: "لا يمكن أن تكون الخطة حتى الآن أقل من العنصر المخطط الأصلي" ,
        applying_planning_distribution_template_please_wait_planner_status_message: "تطبيق نموذج توزيع التخطيط ، يرجى الانتظار ..." ,
        planning_distribution_template_applied_success_planner_status_message: "تطبيق نموذج توزيع التخطيط بنجاح" ,
        note_for_parent_select_null_label: "ملاحظة: بالنسبة للوالد ، حدد null" ,
        payments_label: "المدفوعات" ,
        schedule_autofill_button_tooltip: "تعبئة تلقائية للإعلانات التجارية المحجوزة" ,
        schedule_reset_all_header_to_nominal_start_time_button_tooltip: "إعادة تعيين كل وقت بدء الرأس إلى الوقت الاسمي المخطط له" ,
        work_orders_label: "أوامر العمل" ,
        role_wise_work_orders_label: "أوامر العمل الحكيمة للأدوار" ,
        user_wise_work_orders_label: "أوامر العمل الحكيمة للمستخدم" ,
        select_media_info_message: "حدد الوسائط" ,
        history_label: "التاريخ" ,
        booking_details_label: "تفاصيل الحجز" ,
        show_jobs_button_text: "عرض الوظائف" ,
        please_check_label: "يرجى التحقق" ,
        categories_label: "الفئات" ,
        job_status_label: "الحالة الوظيفية" ,
        invalid_start_date: "تاريخ بدء غير صالح" ,
        invalid_end_date: "تاريخ انتهاء غير صالح" ,
        target_budget_should_be_greater_then_zero: "يجب أن تكون الميزانية المستهدفة أكبر من الصفر" ,
        target_spots_should_be_greater_then_zero: "يجب أن تكون النقاط المستهدفة أكبر من الصفر" ,
        target_seconds_should_be_greater_then_zero: "يجب أن تكون الثواني المستهدفة أكبر من القيمة السالبة" ,
        please_select_date_format_error_message: "الرجاء تحديد تنسيق التاريخ" ,
        no_data_schedule_to_save_scheduling_reconciliation_error_message: "لم يتم العثور على بيانات الجدول الزمني" ,
        no_data_asrun_to_save_scheduling_reconciliation_error_message: "لم يتم العثور على بيانات أسرشن" ,
        reset_password_label: "إعادة تعيين كلمة المرور" ,
        date_column_is_not_present_in_this_csv_file_label: "عمود التاريخ غير موجود في ملف سي إس في هذا" ,
        time_column_is_not_present_in_this_csv_file_label: "عمود الوقت غير موجود في ملف سي إس في هذا" ,
        house_number_column_is_not_present_in_this_csv_file_label: "عمود رقم الدار غير موجود في ملف سي إس في هذا" ,
        user_favourite_add_button_title: "إضافة المفضلة" ,
        user_favourite_menu: "المفضلة" ,
        user_favorite_menu_dropdown_label: "القائمة" ,
        user_favorite_menu_text_label: "الاسم" ,
        please_select_user_favourite_menu_error_message: "الرجاء اختيار القائمة" ,
        please_select_user_favourite_name_error_message: "الرجاء إدخال الاسم" ,
        vodtype_label: "أنواع صوت د" ,
        replace_existing_planning_label: "استبدال التخطيط الحالي" ,
        region_column: "المنطقة" ,
        live_events_menu: "الأحداث المباشرة" ,
        vodtypes: "نوع صوت د" ,
        version_sub_menu: "الإصدار" ,
        version_page_breadcrumb: "الإصدار" ,
        spot_can_not_be_negative_error_message: "بقعة لا يمكن أن تكون سلبية" ,
        rate_can_not_be_negative_error_message: "لا يمكن أن يكون المعدل سلبيا" ,
        please_add_booking_first_booking_error_message: "يرجى إضافة الحجز أولا",
        transfer_from_and_transfer_to_are_same_date_error_message: "لا يمكن نقلها في نفس التاريخ" ,
        tournament_dialog_header: "إنشاء بطولة" ,
        live_match_dialog_header: "إنشاء مباراة" ,
        no_booking_data_error_message: "لم يتم العثور على بيانات الحجز!",
        ott_assets_video: "فيديو" ,
        ott_assets_poster: "ملصق" ,
        update_successfully_booking_success_toast_message: "تم تحديث الحجز بنجاح" ,
        please_select_a_value_to_delete_booking_error_message: "الرجاء تحديد قيمة لحذفها" ,
        ott_assets_video_url_error_message: "الرجاء إدخال عنوان يو آر إل" ,
        LiveEventRound_sub_menu: "جولة الحدث المباشر" ,
        LiveEventType_sub_menu: "نوع الحدث المباشر" ,
        TournamentType_sub_menu: "نوع البطولة" ,
        commission_negative_value_campaign_error_message: "لا يمكن أن تكون العمولة سلبية" ,
        discount_negative_value_campaign_error_message: "لا يمكن أن يكون الخصم سلبيا" ,
        invalid_spots_value_line_no_booking_error_message: "قيمة البقع السلبية في السطر رقم" ,
        channels_not_updated_in_booking_error_message: "لم يتم تحديث القناة في السطر رقم" ,
        attach_media_label: "إرفاق الوسائط" ,
        media_episode_localization: "التعريب" ,
        media_episode_id_column: "معرف الحلقة الإعلامية" ,
        manage_content_template_sub_menu: "إدارة قالب المحتوى" ,
        fct_can_not_be_negative_error_message: "إف سي تي لا يمكن أن تكون سلبية" ,
        rate_10_sec_can_not_be_negative_error_message: "معدل ١٠ ثوان لا يمكن أن يكون سلبيا" ,
        amount_can_not_be_negative_error_message: "المبلغ لا يمكن أن يكون سلبيا" ,
        booked_spots_scheduled_error_message: "بعض الحجوزات المحددة مستخدمة بالفعل في الجدول الزمني." ,
        booking_line_no: "رقم السطر" ,
        please_select_import_type_error_message: "الرجاء تحديد نوع الاستيراد" ,
        optimisation_sun_menu: "التحسين" ,
        optimisation_page_breadcrumb: "التحسين" ,
        booking_date_label: "تاريخ الحجز" ,
        agency_name_column: "اسم الوكالة" ,
        assurance_type_column: "نوع التأمين" ,
        assured_value_column: "القيمة المضمونة" ,
        please_select_booking_date_error_message: "يرجى تحديد تاريخ الحجز",
        optimisation_label: "التحسين" ,
        please_show_atleast_one_item_error_message: "يرجى إظهار عنصر واحد على الأقل!" ,
        please_enter_valid_percentage_error_message: "الرجاء إدخال نسبة صحيحة" ,
        campaign_target_seconds_actual_seconds_booking_error: "لا يمكن أن تكون الثواني المستهدفة أقل من إجمالي الثواني المحجوزة" ,
        campaign_target_budget_actual_budget_booking_error: "لا يمكن أن تكون الميزانية المستهدفة أقل من إجمالي الميزانية المحجوزة",
        campaign_target_spots_actual_spots_booking_error: "لا يمكن أن تكون المواقع المستهدفة أقل من إجمالي المواقع المحجوزة" ,
        assured_value_is_greater_than_total_fct_error_message: "القيمة المضمونة أكبر من إجمالي إف سي تي" ,
        assured_value_is_less_than_zero_error_message: "القيمة المضمونة أقل من الصفر" ,
        booking_schedule_status: "حالة جدول الحجز" ,
        makegood_column: "اصنع الخير" ,
        booked_spots_unique_line_no_selection_error_message: "يرجى تحديد المواقع التي تحمل نفس السطر لا للتحديث" ,
        booking_file_import_error_message: 'تعذر قراءة البيانات من الملف. خطأ - ',
        spot_status_column: "الحالة" ,
        booking_transfer_from_date_greater_than_transfer_to_date_error_message: "لا يمكن أن يكون النقل من التاريخ أكبر من النقل إلى التاريخ" ,
        spot_time_from_time_to_error_message: "من الوقت يجب أن يكون أقل من الوقت" ,
        material_type_label: "نوع المادة" ,
        cancreatedeal_label: "يمكن إنشاء صفقة" ,
        please_enter_material_type_error_message: "الرجاء إدخال نوع المادة" ,
        please_select_media_category_error_message: "الرجاء اختيار فئة الوسائط",
        automation_type_label: "نوع الأتمتة" ,
        download_button_text: "تحميل" ,
        edit_black_out_date_button_tooltip: "تحرير تاريخ التعتيم" ,
        add_black_out_date_button_tooltip: "إضافة تاريخ حجب الحجوزات" ,
        edit_black_out_date_dialog_header: "تحرير التعتيم" ,
        add_black_out_date_dialog_header: "إضافة تعتيم" ,
        black_out_date_dialog_header: "تاريخ حجب الخدمة" ,
        please_add_atleast_one_black_out_date_error_message: "يرجى إضافة تاريخ حجب واحد على الأقل" ,
        update_successfully_grid_data_success_toast_message: "تم التحديث بنجاح" ,
        black_out_channel_column: "قناة التعتيم" ,
        black_out_start_date_column: "تاريخ بدء التعتيم" ,
        black_out_end_date_column: "تاريخ انتهاء التعتيم" ,
        contract_start_date_should_be_greater_than_start_date_error_message: "يجب أن يكون تاريخ بدء العقد أكبر من تاريخ البدء" ,
        contract_end_date_should_be_greater_than_end_date_error_message: "يجب أن يكون تاريخ انتهاء العقد أكبر من تاريخ الانتهاء" ,
        some_bookings_are_out_of_campaign_date_range_could_not_transfer_error_message: "بعض الحجوزات خارج النطاق الزمني للتخييم ، لا يمكن نقلها" ,
        report_menu: "تقرير" ,
        interstitial_history_sub_menu: "التاريخ الخلالي" ,
        interstitial_history_report_dialog_header: "تقرير التاريخ الخلالي" ,
        client_agency_booking_report_sub_menu: "تقرير حجز وكالة العميل" ,
        primary_channel_column: "القناة الأساسية" ,
        order_date_error_message: "يجب أن يكون تاريخ الطلب أقل من الفترة من" ,
        please_select_tournament_type: "الرجاء اختيار نوع البطولة",
        please_enter_tournament_ID: "الرجاء إدخال معرف البطولة" ,
        tournament_type_label: "نوع البطولة" ,
        tournament_ID_label: "معرف البطولة" ,
        as_run_label: "أسرشن" ,
        schedule_label: "الجدول الزمني" ,
        update_booking_multiple_dialog_header: "تحديث الحجز" ,
        commercial_duration_lable:'المدة',
        no_as_run_found_error_message: "لم يتم العثور على أسرشن" ,
        copy_from_and_copy_to_date_range_should_be_same: "يجب أن يكون نطاق النسخ من والنسخ إلى التاريخ هو نفسه !!" ,
        exclusive_label: "حصري" ,
        exclusive_start_date_column: "تاريخ البدء الحصري" ,
        exclusive_end_date_column: "تاريخ انتهاء حصري" ,
        technical_details_prototype_sub_menu: "نماذج التفاصيل الفنية" ,
        technical_details_prototype_page_breadcrumb: "نماذج التفاصيل الفنية" ,
        audio_codec_page_breadcrumb: "برنامج ترميز الصوت" ,
        video_codec_page_breadcrumb: "ترميز الفيديو" ,
        delivery_method_page_breadcrumb: "طريقة التسليم" ,
        video_aspect_ratio_page_breadcrumb: "نسبة العرض إلى الارتفاع للفيديو" ,
        video_type_page_breadcrumb: "نوع الفيديو" ,
        audio_codec_sub_menu: "برنامج ترميز الصوت" ,
        video_codec_sub_menu: "ترميز الفيديو" ,
        delivery_method_sub_menu: "طريقة التسليم" ,
        video_aspect_ratio_sub_menu: "نسبة العرض إلى الارتفاع للفيديو" ,
        video_type_sub_menu: "نوع الفيديو" ,
        is_exclusive_column: "حصري" ,
        ref_off_setting_label: "إعداد الإزاحة" ,
        offset_time_minus_label: "وقت الإزاحة ناقص" ,
        offset_time_plus_label: "تعويض الوقت زائد" ,
        ignore_duration_lable: "مدة إيغونري" ,
        reference_no_label: "الرقم المرجعي" ,
        please_enter_reference_no_error_message: "الرجاء إدخال الرقم المرجعي" ,
        create_technical_details_prototype_header_dialog: "إنشاء نموذج أولي للتفاصيل الفنية" ,
        audio_codec_label: "برنامج ترميز الصوت" ,
        video_codec_label: "ترميز الفيديو" ,
        video_aspect_ratio_label: "نسبة العرض إلى الارتفاع للفيديو" ,
        delivery_method_label: "طريقة التسليم" ,
        is_quality_checked_label: "يتم فحص الجودة" ,
        enable_caption_label: "تمكين التسمية التوضيحية" ,
        distributer_label: "الموزع" ,
        please_select_audio_codec_error_message: "الرجاء اختيار برنامج ترميز الصوت" ,
        please_select_video_codec_error_message: "الرجاء اختيار برنامج ترميز الفيديو" ,
        please_select_delivery_method_error_message: "يرجى تحديد طريقة التسليم" ,
        please_select_video_aspect_ratio_error_message: "الرجاء تحديد نسبة العرض إلى الارتفاع للفيديو" ,
        please_select_video_type_error_message: "الرجاء اختيار نوع الفيديو",
        offsetsetting_dialog_header: "إيقاف إعداد الضبط" ,
        please_enter_OffSet_Time_Minus_error_message: "الرجاء إدخال وقت الإزاحة ناقص" ,
        please_enter_offSet_time_plus_error_message: "الرجاء إدخال وقت الإزاحة بلس" ,
        booking_schedule_status_sub_menu: "حالة جدول الحجز" ,
        edit_multiple_tooltip: "تحرير متعددة" ,
        select_commercial_error_message:"الرجاء اختيار تجاري",
        invalid_spotRate_error_message:'يرجى إدخال سعر فوري صالح',
        invalid_rate10Sec_error_message:'يرجى إدخال سعر صالح ١٠ ثوان',
        please_select_atleast_one_value_to_update_error_message:'الرجاء تحديد قيمة واحدة على الأقل لتحديثها',
        rate_10_sec_column:"معدل ١٠ ثوان",
        missing_media_report_dialog_header: "تقرير إعلامي مفقود" ,
        missing_media_report_sub_menu: "تقرير إعلامي مفقود" ,
        schedule_date_label: "تاريخ الجدول" ,
        please_select_schedule_date_error_message: "يرجى تحديد تاريخ الجدول الزمني" ,
        mam_assets_sub_menu: "ماماست" ,
        mamassets_page_breadcrumb: "مام أسيتس" ,
        create_mamasset_dialog_header: "إنشاء ماماست" ,
        please_select_programeditstatus_error_message: "الرجاء تحديد حالة تحرير البرنامج" ,
        end_time_label: "وقت الانتهاء" ,
        program_edit_status_label: "حالة تحرير البرنامج" ,
        tx_ready_label: "تكساس جاهز" ,
        subtitle_availble_label: "الترجمة متوفرة" ,
        censorship_report_available_label: "تقرير الرقابة متاح" ,
        qcpass_label: "ممر مراقبة الجودة" ,
        please_enter_valid_end_time_message: "الرجاء إدخال وقت انتهاء صالح" ,
        please_enter_valid_actual_duration_message: "الرجاء إدخال المدة الفعلية الصالحة" ,
        time_in_label: "الوقت في" ,
        time_out_label: "مهلة" ,
        commercial_limit_label: "الحد التجاري" ,
        please_enter_valid_commercial_limit_error_message: "يرجى إدخال حد تجاري صالح" ,
        contract_number_column: "رقم العقد" ,
        duration_should_be_greater_than_zero_error_message: "يجب أن تكون المدة أكبر من الصفر" ,
        number_of_episode_can_be_greater_than_zero_error_message: "يجب أن يكون عدد الحلقات أكبر من الصفر" ,
        staring_episode_can_be_greater_than_zero_error_message: "يجب أن تكون الحلقة الأولى أكبر من الصفر" ,
        segment_qc_page_header: "مراقبة الجودة للقطاع" ,
        segment_qc_button_tooltip: "مراقبة الجودة للقطاع" ,
        media_title_label: "عنوان الوسائط" ,
        qc_status_label: "حالة مراقبة الجودة" ,
        frame_rate_label: "معدل الإطارات" ,
        note_label: "ملاحظة" ,
        please_select_qc_status_error_message: "يرجى تحديد حالة مراقبة الجودة" ,
        please_enter_note_error_message: "الرجاء إدخال ملاحظة" ,
        please_select_frame_rate_error_message: "يرجى تحديد معدل الإطارات" ,
        please_enter_positive_value_in_pin_code_error_message: "الرجاء إدخال قيمة موجبة في الرمز السري" ,
        please_enter_commission_error_message: "الرجاء إدخال العمولة" ,
        please_enter_discount_error_message: "الرجاء إدخال الخصم" ,
        please_configure_checklist_preference_error_message : "الرجاء تكوين تفضيل قائمة التحقق !!" ,
        preference_saved_successfully : "تم حفظ التفضيل بنجاح !!" ,
        please_select_csv_file_error_message: "الرجاء تحديد ملف سي إس في!" ,
        cannot_delete_channel_campaign_already_has_booking_error_message: "لا يمكن حذف حملة القناة لديها حجز بالفعل",
        cannot_delete_commercial_campaign_already_has_booking_error_message: "لا يمكن حذف الحملة التجارية لديها حجز بالفعل",
        ref_change_rate10sec_label: "معدل التغيير ١٠ ثوان" ,
        change_rate10sec_dialog_header: "معدل التغيير ١٠ ثوان" ,
        change_rate10sec_label: "معدل التغيير ١٠ ثوان" ,
        import_pdf_file : "استيراد ملف 	پ ذف" ,
        please_select_atleast_one_media_category_type_error_message:"الرجاء تحديد نوع فئة وسائط واحد على الأقل",
        please_select_episode_content_error_message : "الرجاء اختيار محتوى إبسيود",
        iso_code_label: "رمز انا اس او" ,
        google_api_code_label: "رمز واجهة برمجة تطبيقات جوجل" ,
        create_language_dialog_header: "إنشاء لغة" ,
        please_enter_iso_code_error_message: "الرجاء إدخال رمز انا اس او" ,
        please_enter_google_api_code_error_message: "الرجاء إدخال رمز واجهة برمجة تطبيقات جوجل" ,
        click_here_to_check_ISO_code_error_message: "انقر هنا للتحقق من رمز انا اس او" ,
        booking_update_successfully:"تم تحديث الحجز بنجاح",	
        cast_Type_page_breadcrumb: "نوع الزهر" ,
        cast_Type_sub_menu: "نوع الزهر" ,
        spots_out_of_campaign_date_range_error_message:"هناك بعض المواقع خارج النطاق الزمني للحملة. يرجى التحقق ",
        import_pdf_booking_file : "استيراد حجز 	پ ذف" ,
        import_booking_label: "حجز الاستيراد" ,
        download_template_label: "تنزيل القالب" ,
        export_saved_booking_label: "تصدير الحجز المحفوظ" ,
        export_current_booking_label: "تصدير الحجز الحالي" ,
        planning_is_locked_on_some_days_in_this_date_range: "التخطيط مقفل في بعض الأيام في هذا النطاق الزمني" ,
        payment_schedule_label: "جدول الدفع" ,
        promotional_excerpts_label: "مقتطفات ترويجية" ,
        governing_laws_label: "القوانين الحاكمة" ,
        add_linear_page_header: "إضافة خطي" ,
        add_linear_button_tooltip: "إضافة خطي" ,
        amount_should_be_negative_error_message: "المبلغ لا يمكن أن يكون سالبا" ,
        currency_column: "العملة" ,
        total_booked_spots_label:"الأماكن المحجوزة",
        total_booked_seconds_label:"الثواني المحجوزة",
        total_booked_amount_label:"المبلغ المحجوز",
        booking_exceeded_target_budget:"تجاوز الميزانية المستهدفة، يرجى التحديث والمحاولة مرة أخرى",
        booking_exceeded_target_seconds:"تجاوز الميزانية المستهدفة، يرجى التحديث والمحاولة مرة أخرى",
        booking_exceeded_target_amount: "تجاوز الميزانية المستهدفة، يرجى التحديث والمحاولة مرة أخرى",
        broadcaster_id_label: "معرف المذيع" ,
        restriction_label: "التقييد" ,
        create_restriction_dialog_header: "إنشاء قيود" ,
        dynamic_rules_label: "القواعد الديناميكية" ,
        censorship_details_label: "تفاصيل الرقابة" ,
        term_label: "المصطلح" ,
        reason_label: "السبب" ,
        hold_all_payment_label: "تعليق جميع المدفوعات" ,
        generate_payment_button_text: "إنشاء الدفع" ,
        contract_payment_schedule_dialog_header: "جدول دفع العقد" ,
        generate_payment_schedule_dialog_header: "إنشاء جدول الدفع" ,
        due_date_label: "تاريخ الاستحقاق" ,
        interest_rate_label: "سعر الفائدة" ,
        no_of_installment_label: "لا. من الأقساط",
        recurring_label: "متكررة" ,
        due_date_column: "تاريخ الاستحقاق" ,
        due_amount_column: "المبلغ المستحق" ,
        approve_status_column: "الموافقة على الحالة" ,
        amount_paid_column: "المبلغ المدفوع" ,
        balance_amount_column: "مبلغ الرصيد" ,
        please_select_recurring_error_message: "الرجاء اختيار متكرر" ,
        interest_rate_should_be_negative_error_message: "يجب أن يكون سعر الفائدة سلبيا" ,
        no_of_installment_should_be_greater_than_zero_error_message: "يجب أن يكون عدد الأقساط أكبر من الصفر" ,
        generate_payment_label: "إنشاء الدفع" ,
        are_you_sure_you_want_to_generate_payment_schedule: "هل أنت متأكد من أنك تريد تجديد جدول الدفع" ,
        amount_paid_label: "المبلغ المدفوع" ,
        approve_status_label: "حالة الموافقة" ,
        remark_column: "ملاحظة" ,
        edit_contract_title_button_text: "تحرير عنوان العقد" ,
        edit_black_out_date_button_text: "تحرير تاريخ التعتيم" ,
        delete_contract_title_button_text: "حذف عنوان العقد",
        classification_label: "التصنيف" ,
        cut_tc_in_label: "قطع تي سي In (حح:مم:سس:فف)" ,
        cut_tc_out_label: "قطع تي سي (حح:مم:سس:فف)" ,
        done_date_label: "تاريخ الانتهاء" ,
        result_label: "النتيجة" ,
        officer_label: "الضابط" ,
        cut_label: "قص" ,
        classification_column: "التصنيف" ,
        cut_tc_in_column: "قطع تي سي في" ,
        cut_tc_out_column: "قطع تي سي خارج" ,
        done_date_column: "تاريخ الانتهاء" ,
        reference_no_column: "الرقم المرجعي" ,
        result_column: "النتيجة" ,
        officer_column: "ضابط" ,
        cut_column: "قص" ,
        create_censorship_details_dialog_header: "إنشاء تفاصيل الرقابة" ,
        please_enter_valid_value_cut_tcin_error_message: "الرجاء إدخال قطع ح في صالح" ,
        can_not_delete_error_message: "لا يمكن حذف لوحة القيادة بشكل صحيح" ,
        add_brand_button_tooltip: "إضافة علامة تجارية" ,
        add_product_button_tooltip: "إضافة منتج" ,
        ratecard_page_breadcrumb: "بطاقة الأسعار" ,
        ratecard_sun_menu: "بطاقة الأسعار" ,
        create_ratecard_dialog_header: "إنشاء بطاقة أسعار" ,
        rate_10sec_label: "معدل ١٠ ثوان" ,
        please_enter_time_in_error_message: "الرجاء إدخال الوقت في" ,
        please_enter_time_out_error_message: "الرجاء إدخال المهلة" ,
        view_label: "عرض" ,
        other_option_label: "خيار آخر" ,
        prime_time_label: "وقت الذروة" ,
        no_rights_scheduling_error_message: "لا توجد حقوق جدولة" ,
        used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip: "يستخدم هذا لتكوين لاحقة البادئة والرقم التالي التزايدي لمعرف الأصل" ,
        sales_executive_label : "تنفيذي مبيعات" ,
        please_select_sales_executive_error_message : "الرجاء اختيار تنفيذي المبيعات" ,
        material_content_page_breadcrumb : "المحتوى المادي" ,
        material_content_sub_menu : "المحتوى المادي" ,
        added_by_column: "أضيفت بواسطة",
        modified_by_column: "تم التعديل بواسطة" , 
        show_history_button_text: "إظهار التاريخ" ,
        successfully_download_success_message: "تم التنزيل بنجاح" ,
        top_5_sales_executive_label: "أفضل 5 تنفيذي مبيعات" ,
        add_job_url_button_tooltip: "إضافة عنوان يو آر إل للوظيفة" ,
        checklist_preference_label : "تفضيل قائمة المراجعة" ,
        select_playback_url_header: "حدد عنوان يو آر إل للتشغيل" ,
        duration_mismatch_error_message:"عدم تطابق المدة",
        no_invoice_details_are_available_to_preview_bill_error_message: "لا توجد تفاصيل فاتورة متاحة للمعاينة!" ,
        selected_commercial_label : "إعلان تجاري مختار" ,
        no_data_schedule_to_download_error_message: "لا يوجد جدول بيانات للتنزيل" ,
        from_date_can_not_be_less_than_campaign_start_date_error_message: "من التاريخ لا يمكن أن يكون أقل من تاريخ بدء الحملة" ,
        to_date_can_not_be_greater_than_campaign_end_date_error_message: "حتى الآن لا يمكن أن يكون أكبر من تاريخ انتهاء الحملة" ,
        cancel_invoice_waiting_message : "إلغاء الفاتورة، يرجى الانتظار...",
        series_dialog_header: "سلسلة" ,
        manage_content_template_breadcrumb : "إدارة قالب المحتوى" ,
        ottmenu_sub_menu: "القائمة" ,
        ottmenu_page_breadcrumb: "القائمة" ,
        cardlist_pages_menu_title: "الصفحات" ,
        select_pages_header_collection: "الصفحات" ,
        ottmenu_title_editpageheader: "القائمة" ,
        sidefixmenu_rails_pages_tooltip: "الصفحات" ,
        atleast_attach_one_ott_pages_rails_error_message: "على الأقل إرفاق صفحة واحدة" ,
        menu_type_label: "نوع القائمة" ,
        team_a_label: "الفريق أ" ,
        team_b_label: "الفريق ب" ,
        round_label: "جولة" ,
        bottom_menu_sub_menu: "القائمة السفلية" ,
        bottom_menu_page_breadcrumb: "القائمة السفلية" ,
        top_menu_sub_menu: "القائمة العلوية" ,
        top_menu_page_breadcrumb: "القائمة العلوية" ,
        please_select_asset_type_to_media_asset_error_message: "يرجى تحديد نوع الأصل إلى أصل الوسائط" ,
        multi_versioning_tooltip_button: "الإصدارات المتعددة" ,
        title_view_tooltip_button: "عرض العنوان" ,
        close_title_view_tooltip_button: "إغلاق عرض العنوان" ,
        sidefix_episode_tooltip: "الحلقة" ,
        please_select_valid_start_date_error_message: "يرجى تحديد تاريخ بدء صالح" ,
        please_select_valid_end_date_error_message: "يرجى تحديد تاريخ انتهاء صالح" ,
        please_select_exclusive_start_date_error_message: "يرجى تحديد تاريخ البدء الحصري" ,
        please_select_exclusive_end_date_error_message: "يرجى تحديد تاريخ الانتهاء الحصري" ,
        please_select_start_date_smaller_than_end_date_media_episode_publishing_error_message: "يجب أن يكون تاريخ البدء أقل من تاريخ الانتهاء" ,
        amount_paid_can_not_be_negative_error_message: "المبلغ المدفوع لا يمكن أن يكون سلبيا" ,
        print_button_tooltip : "طباعة" ,
        print_telecast_certificate_in_pdf_button_tooltip: "طباعة شهادة البث التلفزيوني بتنسيق 	پ ذف" ,
        print_telecast_certificate_in_excel_button_tooltip: "طباعة شهادة البث التلفزيوني في اكسل" ,
        cancel_invoice_button_tooltip: "إلغاء الفاتورة" ,
        campagn_type_page_breadcrumb : "نوع الحملة" ,
        deal_type_page_breadcrumb : "نوع الصفقة" ,
        location_page_breadcrumb : "الموقع" ,
        campaigntype_sub_menu: "نوع الحملة" ,
        dealtype_sub_menu: "نوع الصفقة" ,
        location_sub_menu: "الموقع" ,
        ott_setting_sub_menu: "الإعدادات" ,
        ott_setting_page_breadcrumb: "الإعدادات" ,
        application_name_label: "اسم التطبيق" ,
        logo_label: "الشعار" ,
        logo_position_label: "موضع الشعار" ,
        pi_page_template_label: "قالب صفحة باي" ,
        show_bottom_menu_label: "إظهار القائمة السفلية" ,
        home_page_label: "الصفحة الرئيسية" ,
        show_hamburger_menu_label: "عرض قائمة الهامبرغر" ,
        image_cdn_base_url_label: "عنوان يو آر إل الأساسي لصورة ج د ن" ,
        video_cdn_base_url_label: "عنوان يو آر إل الأساسي ل ج د ن للفيديو" ,
        image_cdn_credentials_label: "صورة ج د ن كريد" ,
        video_cdn_credentials_label: "فيديو ج د ن كريد" ,
        key_name_label: "اسم المفتاح" ,
        key_value_label: "القيمة الرئيسية" ,
        add_image_cdn_credentials_dialog_header: "إضافة صورة ج د ن كريد" ,
        add_video_cdn_credentials_dialog_header: "إضافة فيديو ج د ن كريد" ,
        please_enter_key_name_error_messgae: "الرجاء إدخال اسم المفتاح" ,
        please_enter_key_value_error_message: "الرجاء إدخال قيمة المفتاح" ,
        settings_label: "الإعدادات" ,
        please_enter_application_name_error_message: "الرجاء إدخال اسم الطلب" ,
        please_select_logo_error_message: "الرجاء اختيار الشعار" ,
        please_select_logo_position_error_message: "الرجاء تحديد موضع الشعار" ,
        please_select_pi_page_template_error_message: "الرجاء تحديد قالب صفحة باي" ,
        please_select_home_page_error_message: "الرجاء اختيار الصفحة الرئيسية",
        please_select_theme_error_message: "الرجاء اختيار الموضوع" ,
        please_enter_image_cdn_base_url_error_message: "الرجاء إدخال عنوان يو آر إل الأساسي لصورة ج د ن" ,
        please_enter_video_cdn_base_url_error_message: "الرجاء إدخال عنوان يو آر إل الأساسي ل ج د ن للفيديو" ,
        icon_label: "أيقونة" ,
        please_select_icon_error_message: "الرجاء تحديد الرمز" ,
        please_select_hamburger_menu_error_message : "الرجاء اختيار قائمة الهامبرغر" ,
        hamburger_menu_label : "قائمة الهامبرغر" ,
        select_package_header: "اختر الحزمة" ,
        please_select_package_error_message: "الرجاء اختيار الحزمة" ,
        vod_type_validation_error_message: "يمكن تطبيق نوع صوت د المجاني مع أ ف أو د فقط" ,
        server_health_sub_menu: "صحة الخادم" ,
        server_health_page_breadcrumb: "صحة الخادم" ,
        text_number_can_not_be_negative_error_message: "لا يمكن أن يكون رقم النص سالبا" ,
        prelog_postlog_report_sub_menu: "تقرير ما قبل / بعد السجل" ,        
        pre_log_report_title_button: "تقرير ما قبل السجل" ,        
        post_log_report_title_button: "تقرير ما بعد السجل" ,
        download_pre_log_report_tooltip_button: "تنزيل تقرير السجل المسبق" ,        
        download_post_log_report_tooltip_button: "تنزيل تقرير سجل ما بعد" ,
        credit_line_report_sub_menu: "تقرير حد الائتمان" ,
        download_credit_line_report_tooltip_button: "تنزيل تقرير حد الائتمان" ,        
        please_select_date_error_message: "الرجاء تحديد التاريخ" ,
        logo_size_label: "حجم الشعار" ,
        application_color_label: "لون التطبيق" ,
        purge_Spot_report_sub_menu: "تقرير تطهير البقعة" ,        
        download_purge_Spot_report_tooltip_button: "تنزيل تقرير تطهير الموقع" ,         
        download_client_agency_booking_report_tooltip_button: "تنزيل تقرير حجز وكالة العميل" ,
        download_purge_spot_report_tooltip_button: "تنزيل تقرير بقعة التطهير" ,
        client_agency_booking_report_header: "تقرير حجز وكالة العميل" , 
        pre_post_log_report_header: "تقرير ما قبل / بعد السجل" ,       
        credit_line_report_header: "تقرير حد الائتمان" ,        
        purge_spot_report_header: "تقرير تطهير البقعة" ,
        interstitial_history_report_header: "تقرير التاريخ الخلالي" ,        
        download_interstitial_history_report_tooltip_button: "تنزيل تقرير التاريخ الخلالي" ,
        telecast_certificate_details_not_found_error_message:"لم يتم العثور على تفاصيل البث التلفزيوني",
        air_time_consumption_report_sub_menu: "تقرير استهلاك وقت البث" ,
        download_air_time_consumption_report_tooltip_button: "تنزيل تقرير استهلاك وقت البث" ,   
        air_time_consumption_report_header: "تقرير استهلاك وقت البث" ,  
        prototypecontent_sub_menu: "محتوى النموذج الأولي" ,
        prototypecontent_page_breadcrumb: "محتوى النموذج الأولي" , 
        create_prototypecontent_dialog_header: "إنشاء محتوى النموذج الأولي" ,
        please_enter_max_length_error_message: "الرجاء إدخال الحد الأقصى للطول" ,
        manual_prototype_id_label: "معرف يدوي" ,
        prototype_id_label: "الهوية" ,
        error_while_generating_prototype_number_error_message: "خطأ أثناء إنشاء رقم النموذج الأولي" ,
        refresh_generated_segments_tooltip: "تحديث الشرائح التي تم إنشاؤها" ,
        generated_segments_selected_segmentType_mismatch_error_message: "عدد الشرائح التي تم إنشاؤها ليس هو نفسه نوع الشريحة المحدد" ,
        materialID_mediaID_mismatch_generated_segment_error_message: "معرف المواد ليس هو نفسه معرف الوسائط في المقطع الذي تم إنشاؤه , يرجى التحديث" ,
        please_select_subtitle_type_error_message: "الرجاء تحديد نوع الترجمة" ,
        number_of_generated_segments_is_not_same_as_selected_segment_Type_error_message: "عدد الشرائح التي تم إنشاؤها ليس هو نفسه نوع الشريحة المحدد",
        select_material_content_first_error_message: "حدد محتوى المواد أولا" ,
        segment_already_exist_with_same_material_id_and_segment_type_error_message: "الشريحة موجودة بالفعل بنفس معرف المادة ونوع الشريحة" ,
        amazon_delivery_group_page_breadcrumb: "مجموعة توصيل أمازون" ,
        amazon_delivery_group_sub_menu: "مجموعة توصيل أمازون" ,        
        amazon_delivery_group_edit_page_header: "مجموعة توصيل أمازون" ,
        tv_preview_label: "معاينة التلفزيون" ,
        mobile_preview_label: "معاينة الجوال" ,
        ditribute_by_percentage_label: "توزيع حسب٪",
        ditribute_by_percentage_error_message: "يجب أن يكون مجموع التوزيع التجاري المختار مائة" ,
        select_commercial_for_booking_error_message: "يرجى اختيار إعلان تجاري واحد على الأقل للحجز" ,
        distribute_selected_equal_lable:"توزيع المحدد بالتساوي" ,
        feature_label : "الميزات" ,
        select_secondary_event_error_message: "حدد الحدث الثانوي أولا" ,
        delete_secondary_event_tooltip : "حذف الحدث الثانوي المحدد" ,
        select_campaign_header_dialog: "اختر الحملة" ,
        select_pages_header_dialog: "حدد الصفحات" ,
        display_name_column: "اسم العرض" ,
        spot_separation_label: "فصل البقعة" ,
        please_enter_valid_spot_separation_error_message: "الرجاء إدخال فصل موضعي صالح" ,
        ott_package_delivery_schedule_menu: "جدول تسليم حزمة ياتت" ,
        service_menu: "الخدمة" ,
        ott_package_delivery_schedule_page_breadcrumb: "جدول تسليم حزمة ياتت" ,
        schedule_delivery_button_tooltip: "جدولة التسليم" ,
        schedule_delivery_button_text: "جدولة التسليم" ,		
        schedule_delivered_successfully_success_message: "تم تسليم الجدول بنجاح" ,
        schedule_auto_fill_interstitial_page_breadcrumb : "جدولة التعبئة التلقائية الخلالية" ,        
        schedule_auto_fill_interstitial_sub_menu: "جدولة التعبئة التلقائية الخلالية" ,        
        schedule_auto_fill_interstitial_edit_page_header: "جدولة التعبئة الخلالية التلقائية" ,
        interstitial_label: "الخلالي" ,
        filler_label: "الحشو" ,
        music_label: "موسيقى" ,
        new_subscriber_label: "مشترك جديد في الأسبوع" ,
        no_pack_users_label: "لا يوجد مستخدمو حزمة" ,
        users_subscription_expiring_in_current_week_label: "انتهاء صلاحية المستخدم في الأسبوع" ,        
        user_by_plan_label: "المستخدم حسب الخطة" ,	
        media_asset_label : "أصول الوسائط" ,
        vod_label : "صوت د" ,        
        dummy_page_breadcrumb: "دمية" ,        
        dummy_sub_menu: "دمية" ,
        ref_attach_formats_label: "إرفاق تنسيق" ,
        format_attached_success_message: "تم إرفاق التنسيق بنجاح" ,
        ref_delete_formats_label: "حذف جميع التنسيقات" ,
        formats_deleted_success_message: "تم حذف جميع التنسيقات بنجاح" ,
        ad_tag_label: "علامة الإعلان" ,
        insert_break_label : "إدراج فاصل" ,
        slot_duration_label : "مدة الفتحة" ,
        please_enter_slot_duration_error : "الرجاء إدخال مدة الفتحة" ,
        actual_duration_slot_duration_format_error_message: "المدة الفعلية لا يمكن أن تكون أكبر من مدة الفتحة" ,
        total_amount_is_smaller_than_amount_paid_error_message: "المبلغ الإجمالي أقل من المبلغ المدفوع" ,
        is_dummy_label : "إيزومي" ,
        downloading_log_report_label: "تنزيل تقرير السجل",
        bill_generate_label: "إنشاء مشروع قانون",
        contract_run_report_button_tooltip: "تقرير تشغيل العقد",
        contract_run_report_label: "تقرير تشغيل العقد",
        total_run_column: "إجمالي التشغيل",
        preview_button_tooltip: "معاينة",
        preview_image_title_label: "معاينة الصورة",
        poster_dimension_title_label: "بعد الملصق",
        show_poster_dimension_title_label: "إظهار أبعاد الملصقات",
        mam_asset_report_sub_menu: "تقرير مام للأصول",
        mam_asset_report_page_breadcrumb: "تقرير مام للأصول",
        schedule_duration_column: "مدة الجدولة",
        mam_duration_column: "مدة مام",
        total_column:'توتال',
        schudule_column:'الجدول الزمني',
        pending_colun:'معلق',
        tomorrow_pending_booking_status_label: "غدا في انتظار الحجز",
        pending_approval_sun_menu: "في انتظار الموافقة",
        pending_approval_page_breadcrumb: "في انتظار الموافقة",
        approve_button_text: "موافقة",
        reject_button_text: "رفض",
        booking_status_updated_successfully: "تم تحديث حالة الحجز بنجاح",
        total_segment_duration_lable: "إجمالي مدة الشريحة",
        other_label: "أخرى",
        comment_label: "تعليق",
        please_enter_comment_error_message:'الرجاء إدخال تعليق',
        unpurged_label: "غير مطهر",
        please_add_deeplink_in_video_list_error_message: "الرجاء إضافة رابط عميق في قائمة مقاطع الفيديو",
        please_attach_atleast_one_video_error_message: "يرجى إرفاق مقطع فيديو واحد على الأقل",
        please_attach_atleast_one_poster_error_message: "يرجى إرفاق ملصق واحد على الأقل",
        deeplink_url_label: "رابط رابط عميق",
        rental_wholesale_price_label: "سعر الجملة للإيجار",
        rental_retail_price_label: "سعر التجزئة للإيجار",
        actual_on_air_column: "الفعلي على الهواء",
        nominal_on_air_column: "الاسمية على الهواء",
        parent_series_code_label: "رمز السلسلة الرئيسية",
        series_code_label: "رمز السلسلة",
        duration_only_label: "المدة",
        reference_code_label: "الرمز المرجعي",
        material_type_column: "نوع المادة",
        break_configuration_page_breadcrumb: "كسر التكوين",
        break_configuration_sub_menu: "كسر التكوين",
        create_break_configuration_dialog_header: "إنشاء تكوين فواصل",
        please_select_atleast_one_allow_media_category_type_error_message:'يرجى تحديد نوع واحد على الأقل من فئة الوسائط',
        please_select_atleast_one_day_error_message: "يرجى تحديد يوم واحد على الأقل",
        please_enter_from_time_label: "الرجاء الدخول من الوقت",
        please_enter_to_time_label: "الرجاء إدخال الوقت",
        fct_should_be_greater_than_zero_error_message: "يجب أن يكون FCT أكبر من الصفر",
        from_time_should_be_less_than_To_time_error_message: "من الوقت يجب أن يكون أقل من الوقت",
        to_date_should_be_greater_than_from_date_error_message: "حتى الآن يجب أن يكون أكبر من من التاريخ",
        fct_already_exists_error_message: "FCT موجود بالفعل في نفس القناة / النطاق الزمني / الأيام",
        fct_should_not_be_greater_than_campaign_target_seconds_error_message: "يجب ألا تكون FCT أكبر من الثواني المستهدفة للحملة",
        sort_by_label: "فرز حسب",
        billing_summary_report_sub_menu:"تقرير ملخص الفوترة",
        no_scheduling_found_auto_fill_commercial_error : "لم يتم العثور على جدولة",
        no_break_item_found_auto_fill_commercial_error: "لا توجد عناصر استراحة مجدولة في هذا اليوم",
        no_booked_spot_found_auto_fill_commercial_error : "الأماكن المحجوزة غير متوفرة",
        save_bill_button_text: "حفظ الفاتورة",
        save_bill_button_tooltip: "حفظ الفاتورة",
        preview_button_text: "معاينة",
        parent_series_lable: "سلسلة الوالدين",
        ott_setting_label: "إعداد أو تي تي",
        bottom_menu_label: "القائمة السفلية",
        series_only_label: "سلسلة",
        add_insert_break_label: "إضافة فاصل إدراج",
        playlist_lock_status_sub_menu: "حالة قفل قائمة التشغيل",
        playlist_lock_page_breadcrumb: "حالة قفل قائمة التشغيل",
        generate_pdf_message: "يرجى الانتظار لإنشاء ملف بي دي إف ...",
        generate_pdf_successfully_message: "إنشاء ملف بي دي إف بنجاح",
        payment_received_label: "تم استلام الدفعة",
        payment_received_button_tooltip: "تم استلام الدفعة",
        payment_received_button_text: "تم استلام الدفعة",
        print_button_text: "طباعة",
        to_txt_button_toolip: "إلى رسالة قصيرة",
        to_txt_button_text: "إلى رسالة قصيرة",
        pdf_button_text: "بي دي إف",
        excel_button_text: "اكسل",
        received_date_label: "تاريخ الاستلام",
        payment_mode_label: "وضع الدفع",
        cheque_no_label: "رقم الشيك",
        payment_received_successfully: "تم استلام الدفعة بنجاح",
        is_paymnet_received_column: "هل تم استلام قسط",
        payment_received_date_column: "تاريخ استلام الدفع",
        payment_mode_column: "وضع الدفع",
        cheque_no_column: "رقم الشيك",
        payment_already_received_error_message: "الدفعة المستلمة بالفعل",
        please_select_received_date_error_message: "يرجى تحديد تاريخ الاستلام",
        please_select_payment_mode_error_message: "الرجاء تحديد طريقة الدفع",
        please_enter_cheque_no_error_message: "الرجاء إدخال رقم الشيك",
        booked_commercial_label : "تجاري محجوز",
        booked_announcement_label : "إعلان محجوز",
        announcement_label : "الإعلانات",
        export_commercial_break_playlist_button_tooltip: "تصدير قائمة تشغيل الفاصل التجاري",
        credit_line_report_page_breadcrumb: "تقرير حد الائتمان",
        brand_column: "العلامة التجارية",
        time_range_column: "النطاق الزمني",
        schedule_spot_column: "جدولة البقعة",
        total_duration_column: "المدة الإجمالية",
        booking_schedule_status_header: "حالة جدول الحجز",
        no_data_found_for_selected_filter_error_message: "لم يتم العثور على بيانات للفلاتر المحددة!",
        artist_label: "فنان",
        please_enter_artist_error_message: "الرجاء إدخال الفنان",
        announcement_reconcile_sun_menu: "إعلان التوفيق",
        announcement_reconcile_page_breadcrumb: "إعلان التوفيق",
        edit_header_dialog: "تحرير",
        actual_on_air_label: "الفعلية على الهواء",
        reconciled_successfully_message: "تمت المصالحة بنجاح",
        reconcile_label: "المصالحة",
        please_enter_actualonair_error_message: "الرجاء إدخال الفعلية على الهواء",
        please_select_atleast_one_saleteam_error_message:'يرجى اختيار فريق مبيعات واحد على الأقل',
        export_announcement_tooltip:"إعلان تصدير",
        export_jazzler_automation_playlist : "تصدير جازلر التجاري بريك",
        point_of_contact_label: "نقطة الاتصال",
        please_enter_point_of_contact_error_message: "الرجاء إدخال نقطة الاتصال",
        please_enter_pin_code_error_message: "الرجاء إدخال الرقم السري",
        epg_menu: "دليل البرامج الإلكتروني",
        epg_channel_sub_menu: "القنوات",
        epg_channel_page_breadcrumb: "القنوات",
        create_channel_dialog_header: "إنشاء قناة",
        please_enter_image_url_error_message: "الرجاء إدخال عنوان يو آر إل للصورة",
        faq_category_page_breadcrumb: "فئة الأسئلة الشائعة",
        faq_category_sub_menu: "فئة الأسئلة الشائعة",
        faq_page_breadcrumb: "الأسئلة الشائعة",
        faq_sub_menu: "الأسئلة الشائعة",
        please_select_faq_Category_error_message: "الرجاء اختيار الفئة",
        faq_category_label: "فئة الأسئلة الشائعة",
        question_label: "سؤال",
        answer_label: "الإجابة",
        please_enter_question_error_message: "الرجاء إدخال السؤال",
        please_enter_answer_error_message: "الرجاء إدخال الإجابة",
        select_a_category_column: "حدد فئة",
        faqs_column: "الأسئلة الشائعة",
        faqs_dailog_header:'الأسئلة الشائعة',
        faqs_page_breadcrumb: "الأسئلة الشائعة",
        top_news_menu : "أهم الأخبار",
        top_news_page_breadcrumb: "أهم الأخبار",
        favorite_channel_sub_menu: "القناة المفضلة",
        favorite_channel_page_breadcrumb: "القناة المفضلة",
        create_favorite_channel_dialog_header: "إنشاء قناة مفضلة",
        logo_column: "الشعار",
        please_select_atleast_one_channel_error_message: "يرجى تحديد قناة واحدة على الأقل",
        tutorial_videos_page_breadcrumb: "مقاطع فيديو تعليمية",
        tutorial_videos_sub_menu: "مقاطع فيديو تعليمية",
        summary_label: "ملخص",
        playback_url_label: "عنوان يو آر إل للتشغيل",
        create_tutorial_videos_dialog_header: "إنشاء مقاطع فيديو تعليمية",
        please_enter_summary_error_message: "الرجاء إدخال الملخص",
        please_enter_playback_url_error_message: "الرجاء إدخال عنوان يو آر إل للتشغيل",
        create_top_news_dialog_header: "إنشاء أهم الأخبار",
        source_id_label: "معرف المصدر",
        featured_image_url_label: "عنوان يو آر إل للصورة المميزة",
        image_caption_label: "شرح الصورة",
        time_stamp_label: "الطابع الزمني",
        pull_latest_top_news_button_tooltip: "سحب آخر الأخبار",
        thumbnail_url_label: "عنوان يو آر إل للصورة المصغرة",
        please_enter_thumbnail_url_error_message:'الرجاء إدخال عنوان يو آر إل للصورة المصغرة',
        please_enter_source_id_error_message: "الرجاء إدخال معرف المصدر",
        please_enter_image_caption_error_message: "الرجاء إدخال التسمية التوضيحية للصورة",
        header_address_label: "عنوان الرأس",
        footer_address_label: "عنوان التذييل",
        ok_label: "حسنا",
        session_expired_please_login_again_label: "انتهت الجلسة. يرجى تسجيل الدخول مرة أخرى",
        please_select_episodes_first : "الرجاء اختيار الحلقات أولا",
        successfully_saved_updated_message: "تم حفظ / تحديث !! بنجاح",
        please_select_all_platforms_message : "يرجى تحديد جميع المنصات لتغيير الموقف",
        please_book_spots_within_campaign_date_message: "يرجى حجز الأماكن ضمن النطاق الزمني للحملة: ",
        invalid_file_format : "تنسيق ملف غير صالح",
        import_already_in_progress : "الاستيراد قيد التقدم بالفعل. تم إحباط العملية",
        invalid_template_file : 'ملف قالب غير صالح !!',
        date_time_houseNumber_column_is_mandatory: "عمود التاريخ والوقت ورقم المنزل إلزامي في ملف الاستيراد",
        commercial_used_in_booking_cannot_change_duration_message : "لا يمكن للإعلان التجاري المستخدم في الحجز تغيير المدة !!",
        please_enter_team : "الرجاء إدخال الفريق",
        please_select_round_error : "الرجاء اختيار الجولة",
        please_select_start_date_between_tournament_date_range_error : "يرجى تحديد تاريخ البدء بين نطاق تاريخ البطولة",
        match_scheduled_successfully_message : 'المباراة مجدولة بنجاح!!',
        some_spots_are_reconciled_bill_generated_selected_spots: "تتم تسوية بعض النقاط أو إنشاء فاتورة في أماكن محددة",
        media_master_report_sub_menu: "التقرير الرئيسي لوسائل الإعلام",
        media_master_report_page_breadcrumb: "التقرير الرئيسي لوسائل الإعلام",
        poster_column: "ملصق",
        video_column: "فيديو",
        product_column: "المنتج",
        data_load_successfully_message: "تم تحميل البيانات بنجاح",
        please_enter_address_error_message: "الرجاء إدخال العنوان",
        target_seconds_should_be_greater_then_zero_error_message: "يجب أن تكون الثواني المستهدفة أكبر من الصفر",
        Missing_seasone_detail_form_error_message: 'تفاصيل الموسم مفقودة',
        ott_menu: "أو تي تي",
        to_do_column: "المهام الواجبة",
        in_progress_column: "قيد التقدم",
        Done_column: "تم",
        Work_order_button_tooltip: "أمر العمل",
        save_and_create_work_order_button_tooltip: "حفظ وإنشاء أوامر العمل",
        save_and_create_work_order_button_text: "حفظ وإنشاء أوامر العمل",
        planning_prime_time_sub_menu: "التخطيط لوقت الذروة",
        breaks_label: 'فرامل',
        add_line_button_tooltip: "إضافة سطر",
        add_line_button_text: "إضافة فاصل",
        perday_label: "لكل يوم",
        perweek_label: "لكل أسبوع",
        booking_yearly_sub_menu: 'الحجز السنوي',
        booking_yearly_page_breadcrumb: "الحجز السنوي",
        days_of_week_column: "أيام الأسبوع",
        edit_multiple_button_text: "تحرير المتعدد",
        delete_multiple_button_tooltip: "حذف متعدد",
        delete_multiple_button_text: "حذف عدة",
        announcements_label: "الإعلانات",
        recorded_commercial_label: "تجاري مسجل",
        read_outs_column: 'القراءات',
        scripts_column: 'البرامج النصية',
        booking_status_column: "حالة الحجز",
        asset_id_column: "معرف الأصل",
        delete_strand_label: 'حذف ستراند',
        delete_strand_items_confirm_message: "حذف عناصر السلسلة",
        press_yes_to_delete_all_the_strand_from_the_selected_slot_onwards_confirm_message : 'اضغط نعم لحذف كل الخصلات من الفتحة المحددة فصاعدًا',
        show_media_label: "إظهار الوسائط",
        lock_unlock_label: "قفل/فتح",
        show_exhibitions_label: "إظهار المعارض",
        expand_all_breaks_label: "توسيع جميع الفواصل",
        mnimize_all_breaks_label: "تصغير جميع الفواصل",

    }
};


