import React, { useEffect, useState } from 'react'
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from '../../components/EditPageHeader';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import ArchiveIconCell from '../../components/ArchiveIconCell';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import BossDialog from '../../components/BossDialog';

const RegionEditForm = (props) => {
    const lang = useTranslation();

    const blankDataItem = {
        SID: props.item.SID ?? 0,
        Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description ?? '',
        Currency: props.item.Currency ?? {},
        Archive: props.item.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [currency, setCurrency] = useState([]);
    const [tabNumber, setTabNumber] = useState("1");
    const [pincodesData, setPincodesData] = useState([]);
    const [statesData, setStatesData] = useState([]);
    const [countriesData, setCountriesData] = useState([]);
    const [showPincodes, setShowPincodes] = useState(false);
    const [showStates, setShowStates] = useState(false);
    const [showCountries, setShowCountries] = useState(false);

    useEffect(() => {
        loadcombo();
        if (props.item.SID > 0) {
            loadEditData();
        }
    }, []);

    const loadcombo = async () => {
        let currency = await API.getDataLookup(ENTITYNAME.Currency);
        setCurrency(currency.data);
    }

    const loadEditData = async () => {

        let res = await API.getRegionData(props.item.SID);
        console.log(res);

        if (!res.success) {
            return;
        }
        let data = {
            SID: res.data.SID ?? 0,
            Description: props.item.copy ? 'copy of ' + res.data.Description : res.data.Description ?? '',
            Currency: res.data.Currency ?? {},
            Archive: res.data.Archive ?? false,
        };
        setDataItem(data);
        setPincodesData(res.data.Pincodes);
        setStatesData(res.data.States);
        setCountriesData(res.data.Countries);
    }

    function validationCheck() {
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Currency).length == 0) {
            toast.error(`${lang.please_enter_currency_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {

        if (validationCheck()) {
            const saveData = {
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                Currency: {
                    _id: dataItem.Currency._id,
                    SID: dataItem.Currency.SID,
                    Description: dataItem.Currency.Description
                },
                Pincodes: pincodesData?.length > 0 ? pincodesData.map(x => x._id) : [],
                States: statesData?.length > 0 ? statesData.map(x => x._id) : [],
                Countries: countriesData?.length > 0 ? countriesData.map(x => x._id) : [],
                Archive: dataItem.Archive ?? false,
            }

            console.log(saveData);

            let res = await API.saveData(ENTITYNAME.Region, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.REGION, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.Region);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onChangeForm = (e) => {

        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const onAddGridData = (tabNumber) => {
        if (tabNumber == "1") {
            setShowPincodes(true);
        } else if (tabNumber == "2") {
            setShowStates(true);
        } else if (tabNumber == "3") {
            setShowCountries(true);
        }
    }

    const handleSelectedData = (data) => {
        let newData = [];
        if (tabNumber?.toString() == "1") {
            newData = utility.checkDuplicateInData(data, pincodesData, "Description");
            setPincodesData([...pincodesData, ...newData]);
        } else if (tabNumber?.toString() == "2") {
            newData = utility.checkDuplicateInData(data, statesData, "Description");
            setStatesData([...statesData, ...newData]);
        } else if (tabNumber?.toString() == "3") {
            newData = utility.checkDuplicateInData(data, countriesData, "Description");
            setCountriesData([...countriesData, ...newData]);
        }
    }

    const deleteSelectedData = (data) => {
        if (tabNumber?.toString() == "1") {
            setPincodesData(pincodesData.filter((item) => item.SID !== data.SID));
        } else if (tabNumber?.toString() == "2") {
            setStatesData(statesData.filter((item) => item.SID !== data.SID));
        } else if (tabNumber?.toString() == "3") {
            setCountriesData(countriesData.filter((item) => item.SID !== data.SID));
        }
    }

    const myCommandCell = (props) => {
        return <td>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedData(props.dataItem), () => { })} />
        </td>;
    }

    const deleteAllData = () => {
        if (tabNumber?.toString() == "1") {
            setPincodesData([]);
        } else if (tabNumber?.toString() == "2") {
            setStatesData([]);
        } else if (tabNumber?.toString() == "3") {
            setCountriesData([]);
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.SID > 0 ? props.item.Description : "Region"}
                onClose={props.cancelEdit}
                width={"800px"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChangeForm}
                                    value={dataItem.Description}
                                />
                            </div>
                            <div className="col-6">
                                <label htmlFor="TabView">{lang.default_currency_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={currency}
                                    name="Currency"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Currency}
                                    onChange={onChangeForm}
                                />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3">
                                <input
                                    type={"checkbox"} name={"Archive"} style={{ marginTop: "10px" }}
                                    onChange={(e) => onChangeForm(e)}
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive} />
                                <label className="ml-1">{lang.archive}</label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={tabNumber}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange} aria-label="tabs example">
                                                <Tab label={lang.pincodes_label} value="1" />
                                                <Tab label={lang.states_label} value="2" />
                                                <Tab label={lang.countries_label} value="3" />
                                            </TabList>
                                        </Box>

                                        <TabPanel value={tabNumber?.toString()}>
                                            <>
                                                <AddRoundButton title={lang.add_button_tooltip} style={{ marginLeft: "-15px" }} onClick={() => onAddGridData(tabNumber?.toString())} />
                                                <DeleteRoundButton title={lang.delete_all_button_tooltip} style={{ marginLeft: "5px" }} onClick={() => ConfirmDeleteAlert(() => deleteAllData(), () => { })} />
                                                <Grid data={tabNumber?.toString() == "1" ? pincodesData : tabNumber?.toString() == "2" ? statesData : countriesData} style={{ height: "30vh", margin: "5px -15px 5px -15px", }}>
                                                    <GridColumn cell={myCommandCell} width={"60px"} text locked={true} />
                                                    <GridColumn field="Description" title={lang.description_column} editable={false} />
                                                    <GridColumn field="Archive" title={lang.archived_column} editable={false} cell={ArchiveIconCell} />
                                                </Grid>
                                                {(showPincodes || showStates || showCountries) && <CollectionSelection title={lang.add_button_text} entityname={tabNumber?.toString() == "1" ? ENTITYNAME.Pincodes : tabNumber?.toString() == "2" ? ENTITYNAME.States : ENTITYNAME.Countries} closeForm={() => showPincodes ? setShowPincodes(false) : showStates ? setShowStates(false) : setShowCountries(false)} setDataList={handleSelectedData} width={"50vw"} />}
                                            </>
                                        </TabPanel>
                                    </TabContext>
                                </Box>

                            </div>
                        </div>
                    </div>
                </div>

            </BossDialog>
        </>
    )
}

export default RegionEditForm
