/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import { RadioButton } from "@progress/kendo-react-inputs";
import CancelButton from "../../framework/forms/helpercomponents/buttons/CancelButton";
import { toast } from "react-toastify";

export const PrelogPostlogReport = (props) => {
    const defaultDates = utility.getFirstAndLastDateOfMonth();

    const blankDataItem = { AgencyButton: true, ClientButton: false, FromDate: defaultDates.FirstDate, ToDate: defaultDates.FirstDate }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const lang = useTranslation();
    const [agency, setAgency] = useState([]);
    const [client, setClient] = useState([]);

    const payloadRef = useRef({});
    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var agencyRes = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
        setAgency([{ SID: 0, Name: "All" }, ...agencyRes.data]);
        var clientRes = await API.getDataLookup(ENTITYNAME.Client, { sort: { Name: 1 } });
        setClient([{ SID: 0, Name: "All" }, ...clientRes.data]);
    }

    const onChange = async (e) => {
        if (e.target.name == "SelectAll") {
            setDataItem({
                ...dataItem, SelectAll: !dataItem.SelectAll,
                AgencyButton: false,
                ClientButton: false
            });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const onChangeRadioButton = (e) => {
        if (e.target.name == "AgencyButton") {
            setDataItem({
                ...dataItem, AgencyButton: !dataItem.AgencyButton,
                ClientButton: false
            });


        } else {
            setDataItem({
                ...dataItem, ClientButton: !dataItem.ClientButton,
                AgencyButton: false
            });
        }
    }

    const isValid = () => {
        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate < dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const onCancel = () => {
        navigate(-1);
    }

    const prePostlogReportExportData = async (onAirTime,isPreLogReport) => {
        if (isValid()) {
            var fromdate = (new Date(dataItem.FromDate)).setHours(0, 0, 0, 0);
            var todate = (new Date(dataItem.ToDate)).setHours(23, 59, 59);
            payloadRef.current = {
                startdate: fromdate,
                enddate: todate,
                onAirTime: onAirTime
            }
            prePostlogReportData(isPreLogReport);
            setLoading(true);
        }
    }

    const prePostlogReportData = async (isPreLogReport) => {
        var payload = {
            ...payloadRef.current,
            client: dataItem.ClientButton ? (dataItem.Client != undefined ? dataItem.Client.SID : 0) : 0,
            agency: dataItem.AgencyButton ? (dataItem.Agency != undefined ? dataItem.Agency.SID : 0) : 0,
        }
        var res = await API.getPrePostlogReportData(payload,isPreLogReport);
        if (res.success) {
            if(res.dataLength == 0){
                toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });             
                return;
            }
            else
            {
                window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
            }
            
        }
        else {
            setLoading(false);
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    return (<>
 <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.pre_post_log_report_header}</span>
        </h1>   

            <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
                <div className="row">
                    <div className="flex-container wrap">
                        <div className="row mt-1 mb-1" style={{ marginLeft: '-25px' }}>
                            <div className="col">
                                <ActionButton title={lang.download_pre_log_report_tooltip_button} name={lang.pre_log_report_title_button} btnColor={'info'} onClick={()=>prePostlogReportExportData("NominalOnAir",true)} />
                                <ActionButton title={lang.download_post_log_report_tooltip_button} name={lang.post_log_report_title_button} btnColor={'info'} onClick={()=>prePostlogReportExportData("ActualOnAir",false)} />
                                <CancelButton onClick={onCancel}></CancelButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-2">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.from_date_label} *</label>
                            <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} max={dataItem.ToDate} />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.to_date_label} *</label>
                            <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} min={dataItem.FromDate} />
                        </div>
                    </div>
                    <div className="ml-3" style={{marginTop:"26px"}}>
                        <input type="checkbox" name={"SelectAll"} value={dataItem.SelectAll} checked={dataItem.SelectAll} onChange={onChange} />
                        <label className="pl-2" htmlFor="TabView">{lang.select_all_label}</label>
                    </div>                        
                    <div className="col-1 mt-4" style={{ padding: "0" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <RadioButton
                        style={{ marginLeft: "5px" }}
                        name="ClientButton"
                        disabled={dataItem.SelectAll}
                        value={dataItem.ClientButton}
                        checked={dataItem.ClientButton}
                        label=""
                        onClick={onChangeRadioButton}
                        />&nbsp;&nbsp;&nbsp;&nbsp;
                        <label htmlFor="TabView">{lang.client_label}</label>
                    </div>
                    <div className="col-1 mt-4" style={{ padding: "0" }}>
                        <RadioButton
                            name="AgencyButton"
                            checked={dataItem.AgencyButton}
                            value={dataItem.AgencyButton}
                            label=""
                            disabled={dataItem.SelectAll}
                            onClick={onChangeRadioButton}
                        />&nbsp;&nbsp;&nbsp;&nbsp;
                        <label htmlFor="TabView">{lang.agency_label}</label>
                    </div>

                    {dataItem.AgencyButton ?
                        <div className="col-3">
                            <label htmlFor="TabView">{lang.agency_label}</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                disabled={dataItem.SelectAll}
                                data={agency}
                                name="Agency"
                                defaultValue={agency[0]}
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.Agency}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please Select Client"}
                            />
                        </div> :
                        <div className="col-3">
                            <label htmlFor="TabView">{lang.client_label}</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                disabled={dataItem.SelectAll}
                                data={client}
                                name="Client"
                                defaultValue={client[0]}
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.Client}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please Select Agency"}
                            />
                        </div>
                    }

                </div>
               
            </div>
    </>)
}
export default PrelogPostlogReport;