/* eslint-disable */
import Card from 'react-bootstrap/Card';
import { CardMenuButton } from '../../forms/helpercomponents/buttons/CardMenuButton';
import classNames from 'classnames';
import { useTranslation } from '../../../locale/useTranslation';
import { utility } from '../../utility/utilityProvider';
import { RAIL_STATUS } from '../../constant/constant';

export const RailsCardList = (props) => {

    const lang = useTranslation();

    const { index, onDragStart, customCard, onDrop, dataItem, onDeleteDataItem, onEditDataItem, setHoveredDataItemPostion, customeModelFields, posterURL } = props;

    const modelFields = {
        Title: "Title",
        Description: "Description",
        PosterURL: "PotraitImageUrl",
    };

    const FinalModelFeilds = customeModelFields ?? modelFields

    const Title = dataItem[FinalModelFeilds.Title];
    const Description = dataItem[FinalModelFeilds.Description];
    let PotraitImageUrl = dataItem[FinalModelFeilds.PosterURL] ?? posterURL;

    try{
        PotraitImageUrl = !customCard ? PotraitImageUrl : require("../../../img/" + PotraitImageUrl);
    }catch{
        PotraitImageUrl = require("../../../img/RailIcon1.png");
    }

    const AssetId = dataItem.AssetId;

    const RailTypes = dataItem.RailType
    let tooltipText = "";
    if (props.title == "SEASON" || props.title == "EPISODE") {
        tooltipText = Title + '\n' + AssetId;
    } else if (props.title == "Rails" || props.title == "Rail Library") {
        tooltipText = Title + '\n' + `${lang.total_assets_label}: ` + dataItem.ott_assets.length + '\n' + RailTypes;
    }
    else {
        tooltipText = Title + '\n' + Description + '\n';
    }

    return (
        <div
            className='grabbable'
            draggable
            onDragStart={(e) => { }}
            onDrag={(e) => {
                onDragStart(e, index, dataItem)
            }}
            onDragEnter={(e) => setHoveredDataItemPostion(index, dataItem)} //onDragEnter(e,index)}
            onDragEnd={(e) => {
                e.currentTarget.classList.remove("dragging");
                onDrop(e);
            }}
            onDragOver={(e) => { e.preventDefault() }}
        >
            <Card className={classNames("m-1", dataItem.isSelected ? "media-card-selected" : "media-card")} onClick={(e) => props.onSelection(index, dataItem)}>
                <div style={{ position: 'absolute', right: "10px", top: '0px', display: 'flex', zIndex: "99" }}>
                {dataItem.Campaigns.length > 0 && <i className={`fa fa-copyright float-right fa-s`} style={{margin : '10px 4px 0px 0px'}} title={`${lang.campaign_actionbutton_option} : ${dataItem.Campaigns.length}`} ></i>}
                
                    <span className="mt-2" title={dataItem.IsActive == RAIL_STATUS.Active ? lang.active_label : dataItem.IsActive == RAIL_STATUS.UpComing ? lang.upcoming_label : lang.expire_label} 
                    style={{ 
                        fontSize : '10px',
                        fontWeight : 'bold',
                        color : 'white',
                        backgroundColor: dataItem.IsActive == RAIL_STATUS.Active ? 'green' : dataItem.IsActive == RAIL_STATUS.UpComing ? '#FF9800'  : 'red',
                        padding : '0px 4px',
                        border : `1px solid ${dataItem.IsActive == RAIL_STATUS.Active ? 'green' : dataItem.IsActive == RAIL_STATUS.UpComing ? '#FF9800'  : 'red'}`,
                        borderRadius : '5px',
                        height: '18px'
                    }}>{dataItem.IsActive == RAIL_STATUS.Active ? lang.active_label : dataItem.IsActive == RAIL_STATUS.UpComing ? lang.upcoming_label : lang.expire_label}</span>
                     

                    {!props.MenuModel && onEditDataItem && <p className="ponter-cursor" title={lang.edit_button_tooltip} onClick={() => onEditDataItem(index, dataItem)} ><i className="fa fa-pen text-primary" /></p>}
                    {!props.MenuModel && onDeleteDataItem && <p className="ponter-cursor" title={lang.delete_button_tooltip} onClick={() => onDeleteDataItem(index, dataItem)}><i className="fa fa-trash text-danger" /></p>}

                    {props.MenuModel && <CardMenuButton {...props} />}


                </div>
                <div className='row'>

                    <div className="col-5"><Card.Img src={PotraitImageUrl} draggable={false} style={{ backgroundSize: 'contain', padding: '20px', borderRadius: "20px", width: "100px" }} /></div>
                    <Card.Body title={tooltipText} className='col-7 pl-0'>
                        <div style={{ marginTop:'5px', fontSize: "15px",fontWeight: '500' }}>{utility.subString(Title, 14)}</div>
                        <div style={{ fontSize: "12px" }}>{RailTypes != undefined && RailTypes}</div>
                        <Card.Title style={{ fontSize: "21px", float: 'right', fontWeight: '500', margin: '7px 4px 4px 20px', color: 'LightSeaGreen' }}>{`${dataItem?.ott_assets.length}`}</Card.Title>
                        {props.dataItem.PlatformTypes?.map((item) => {
                            return (
                                <i className={`fa fa-${item.icon} float-right fa-xs`} style={{ margin : '15px 0px 0px 4px' }} ></i>
                            )
                        })}
                    </Card.Body>
                </div>
            </Card>
        </div>
    );

}


