import { useTranslation } from "../../../../locale/useTranslation";

const UpdateRoundButton = (props) => {
    const lang=useTranslation();
    
    return <button  title={lang.update_button_tooltip} type="submit" onClick={props.onClick} style={{margin:"0px 02px"}} className="btn btn-default btn-circle btn-my">
       <i className="fa-solid fa-floppy-disk"></i>
      
    </button>
}

export default UpdateRoundButton;