/* eslint-disable */
import React, { useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const OttPeriodEditForm = (props) => {

    const lang = useTranslation();
    const [ottPeriodType, setOttPeriodType] = useState([]);

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Days: props.item.Days ?? 0,
        OttPeriodType: props.item.OttPeriodType ?? {},
        Archive: props.item.Archive ?? false
    }

    useEffect(() => {
        loadcombo();
    }, []);

    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async () => {
        let ottPeriodTypeRes = await API.getDataLookup(ENTITYNAME.OttPeriodType);
        setOttPeriodType(ottPeriodTypeRes.data);
    }


    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (parseInt(dataItem.Days) < 1 || dataItem.Days.length == 0) {
            toast.error(`${lang.please_enter_play_time_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (Object.keys(dataItem.OttPeriodType).length == 0) {
            toast.error(`${lang.please_select_ott_period_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                SID: dataItem.SID ?? 0,
                Name: dataItem.Name,
                Days: parseInt(dataItem.Days),
                OttPeriodType: {
                    _id: dataItem.OttPeriodType._id,
                    SID: dataItem.OttPeriodType.SID,
                    Name: dataItem.OttPeriodType.Name,
                },
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Name", "=", dataItem.Name]]

            }
            let res = await API.saveData(ENTITYNAME.OttPeriod, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.OTT_PERIOD, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.OttPeriod);
                props.cancelEdit();
                props.refresh();
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Name ?? `${lang.create_ott_period_type_dialog_header}`}
                onClose={props.cancelEdit}
                width={"650px"}
                height={"290px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.name_label} *</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Name}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.days_label} *</label>
                                            <input
                                                name={"Days"}
                                                type="number"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Days}
                                                min={0}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label htmlFor="TabView">{lang.ott_period_type_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: "white" }}
                                                data={ottPeriodType}
                                                name="OttPeriodType"
                                                textField="Name"
                                                dataItemKey="SID"
                                                value={dataItem.OttPeriodType}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                        <div className='col-6 mt-4'>
                                            <Field
                                                style={{ border: "1px solid grey" }}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>
    )
}

export default OttPeriodEditForm;