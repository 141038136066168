/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, OPERATORS } from "../../framework/constant/constant";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { UserPreference } from "../userPreference/UserPreference";
import { utility } from "../../framework/utility/utilityProvider";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { ScheduleFilter } from "./SchedulingFilter";
import { useTranslation } from "../../locale/useTranslation";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import FilterRoundButton from "../../framework/forms/helpercomponents/buttons/FilterRoundButton";

export const ScheduleMediaCollection = (props) => {
    //possible props

    const [selectedState, setSelectedState] = useState([]);// multiple selection grid

    var myTitle = props.title ?? "";
    var filterByDate = props.filterByDate ?? true;
    var isBreak = props.IsBreak ?? false;
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    const [gridData, setGridData] = useState([]);
    const [gridDataToShow, setGridDataToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [mediaCategories, setMediaCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [products, setProducts] = useState([])
    const [mediaCategoryType, setMediaCategoryType] = useState([])
    const [selectedMediaCategories, setSelectedMediaCategories] = useState({ value: [] })
    const [searchText, setSearchText] = useState('');
    const [preferenceState, setPreferenceState] = useState({});
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [isFilterCleared, setIsFilterCleared] = useState(false);
    const [page, setPage] = useState({
        skip: 0,
        take: 20,
      });
    const [total, setTotal] = useState(1);
    const [footerItem,setFooterItem] = useState({
        SelectedItems : 0,
        TotalDuration : 0,
    })
    const lang=useTranslation();

    var blankFilterDataItem = {
        MediaCategory: [],
        MediaCategoryType: [],
        Duration : {...OPERATORS[0],Condition : 0},
        Brand : [],
        Product : [],
    }
    const [filterDataItem, setFilterDataItem] = useState(blankFilterDataItem);
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';
    useEffect(() => {
        // console.log(selectedState);
        loadMasters();
        loaddata('', {}, props.payload ?? {}, { per_page: page.take, current_page: 1});
        
    }, [props.payload]);


    const loadMasters = async () => {
        var mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType)
        setMediaCategoryType(mediaCategoryType.data);

        var mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory)
        var filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == false)
        setMediaCategories(() => [{ _id: 0, SID: 0, Description: 'Select All' }, ...filteredMediaCategory])
        setSelectedMediaCategories(() => ({ value: [{ _id: 0, SID: 0, Description: 'Select All' }, ...filteredMediaCategory] }))

        var brand = await API.getDataLookup(ENTITYNAME.Brand)
        setBrands(brand.data);
        var product = await API.getDataLookup(ENTITYNAME.Product)
        setProducts(product.data);
    }

    const loaddata = async (searchText, filters, payload ,pagination) => {

        // payload = {
        //     ScheduleDate: scheduleStart,
        //     ScheduleEndDate: utility.addTimeToDateInMilliseconds(scheduleStart, 23, 59, 59, 999),
        //     channelSID: selectedChannel.SID
        // }

        try {
            var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            var columns = await API.getFormData(props.entityname, currentUserId);
            console.log(payload);
            var json = await API.loadSchedulingMediaLibrary(searchText, filters, payload, filterByDate,pagination,isBreak);
            setDataColumns(columns);
            setGridData(json.data);
            console.log(json.data)
            setGridDataToShow(json.data ?? []);
            setTotal(json.pagination.total);
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            setIsNoRecords(true);
        }
    }


    //Setting Preference
    const setPreference = (data) => {
        //   if(data.Selected.length>0){
        //   setPreferenceState(data);
        //   setDataColumns(data.Selected);
        // }
        // else{
        //   loaddata({ per_page: page_take }, wherestatement, andWhereStatement,searchText);
        // }
    }

    const onCloseUserPrefence = () => {
        loaddata(searchText, filterDataItem,props.payload ,{ per_page: page.take, current_page: 1});
        setShowPreferences(!showPreferences);

    }

    const MyDateCell = (props) => <DateCell {...props} />;
    const MyArrayCell = (props) => <ArrayCell {...props} />;

    const MyTimeCell = (props) => <TimeCell {...props} />;

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const MyImageCell = (props) => <ImageCell {...props} />;

    const onFilterSearchChange = (e) => {
        setSearchText(() => e.target.value);

        if (e.target.value == '') {
            loaddata('', filterDataItem,props.payload,{ per_page: page.take, current_page: 1});
        }
    };

    const onSearchClick = () => {

        if (searchText != '') {
            loaddata(searchText, filterDataItem,props.payload,{ per_page: page.take, current_page: 1});
        } else {
            loaddata('', filterDataItem,props.payload,{ per_page: page.take, current_page: 1});
        }

    }

    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // handleServerSideSearch(event);
            onSearchClick();
        }
    }


    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];

        var newSelectedData = [];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);
            if (alreadySelected) {
                newSelectedData = selectedState.filter(item => item._id != dataItem._id)
            } else {
                newSelectedData = [...selectedState,dataItem]
            }
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                newSelectedData = []
            } else {
                newSelectedData = [dataItem]
            }
        }
        setSelectedState(newSelectedData);
        setFooterItem({SelectedItems : newSelectedData.length ,TotalDuration : newSelectedData.reduce((acc,x) => acc + x.Duration,0)})
    };

    const GridRowRenderMediaLibrary = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                const propsData=[props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedState && selectedState.length == 1) 
                {
                    setSelectedState(propsData);
                }

                e.dataTransfer.clearData();
                if (selectedState.length > 1) {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.MediaEpisode, propsData:selectedState }));

                } else {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.MediaEpisode, propsData }));
                }

            },
            onDragOver: e => { e.preventDefault() }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const toGetFilteredData = (data) => {
        console.log(data);
        var finalData = {
            ...data,
        }
        setFilterDataItem(finalData);
        loaddata(searchText,finalData,props.payload,{ per_page: page.take, current_page: 1});
    }

    const pageChange = async (e) => {
        var current_page = e.page.skip / e.page.take + 1;
    
        await loaddata(searchText, filterDataItem,props.payload,{ per_page: e.page.take, current_page: current_page});
        setPage(e.page);
    };

    const refreshMediaCollection = async() => {
        await props.refreshMediadata();
      }

    return (
        <div style={{
            position:'absolute',
            left:0,
            height: "100%",
            width:'100%',
            padding:'5px',
            marginTop:'-12px',
        }}>

            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">                
                    <FilterRoundButton onClick={() => { setFilterPopupOpen(true) }} />
                    <PreferenceRoundButton onClick={() => { setShowPreferences(!showPreferences) }} />
                    <RefreshButton onClick={ ()=>{loaddata(searchText,filterDataItem,props.payload??{},{ per_page: page.take, current_page: 1})} }/>
                </div>
            </div>
            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">                
                    <div className="input-group input-group martb" >                                                  
                        <input
                            type="search"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="search"
                            aria-label="type 2 or more letters"
                            placeholder={lang.search_button_text}
                            onKeyDown={onSearchEnter}
                            onChange={onFilterSearchChange}
                        />
                        <button style={{ border: "0px", backgroundColor: "white", height: "40px" }} onClick={onSearchClick}>
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>



            </div>

            {isLoading  && <div className="ml-1">
            <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
         </div>}
            {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}

            {!isLoading && !isNoRecords && 
                <Grid
                style={{
                    height: "76%",
                    width:'100%',                    
                }}
                resizable = {true}
                data={gridDataToShow.map(item => {
                    item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                    return item;
                })}
                rowRender={GridRowRenderMediaLibrary}
                reorderable={true}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                selectable={{
                    enabled: true,
                    drag: true,
                    cell: false,
                    mode: {
                        value: 'multiple',
                        label: 'Multiple selection mode'
                    },
                }}
                pageable={true}
                skip={page.skip}
                take={page.take}
                onPageChange={pageChange}
                total={total}
                onSelectionChange={onSelectionChange}
            >
                {/* Rows Loop */}
                {dataColumns.map((column, index) => {
                    if (column.type === COLUMNSTYPE.date) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyDateCell}
                                title={column.label}
                                width={140}
                            />
                        );
                    } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyTimeCell}
                                title={column.label}
                                width={140}
                            />
                        );
                    } else if (column.name === "SID") {
                        return;
                    } else if (column.type === COLUMNSTYPE.image) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyImageCell}
                                title={column.label}
                                width={column.width ?? 200}
                            />
                        );
                    } else if (column.type === COLUMNSTYPE.select) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyDatalookupCell}
                                title={column.label}
                                width={140}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.array) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyArrayCell}
                                title={column.label}
                                width={140}
                                format={column.format ?? "Description"} //display item in object
                            />
                        );
                    } else {
                        return (
                            column.type !== COLUMNSTYPE.hidden && (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    title={column.label}
                                    width={140}
                                />
                            )
                        );
                    }
                })}
            </Grid>}
            <div className='row' style={{ display: "flex" }}>
                <div style={{ marginLeft: "20px" }}><p>{'Selected Items'} : {footerItem.SelectedItems}</p></div>
                <div style={{ marginLeft: "20px" }}><p>{'Total Duration'} : {utility.convertMilisecondsToStringWithFrames(footerItem.TotalDuration)}</p></div>
            </div>

            {showPreferences && <UserPreference entityName={'mediaepisode'} data={preferenceState} setState={setPreference} handleClose={onCloseUserPrefence} />}
            {filterPopupOpen && <ScheduleFilter data={filterDataItem} toGetFilterDataItem={toGetFilteredData} isFilterCleared={setIsFilterCleared} closeFilterPopup = {() => setFilterPopupOpen(false)}/> }

        </div>
    );
};
