/* eslint-disable */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import RoundButton from "../framework/forms/helpercomponents/buttons/RoundButton";
import MediaCard from "./MediaCard";
import * as API from "../framework/API/api";
import { toast } from "react-toastify";
import { useTranslation } from "../locale/useTranslation";
import AddRoundButton from "../framework/forms/helpercomponents/buttons/AddRoundButton";
import { ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, RAIL_STATUS } from "../framework/constant/constant";
import { RailsCardList } from "../framework/OTT/Component/RailsCustomCard";
import { utility } from "../framework/utility/utilityProvider";
import PreferenceRoundButton from "../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { UserPreference } from "../modules/userPreference/UserPreference";
import CardListGridView from "./CardListGridView";
import { useRef } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TreeView } from "@progress/kendo-react-treeview";

export const CardList = (props) => {
  //need to define
  //title
  //showSearch
  //on Add click
  //data -- list data
  // onDragStart
  // onDragEnter
  // onDrop
  //onDeleteDataItem
  //onEditDataItem
  //onSearch
  //selectedIconColor
  //setSelectedIconColor

  const {
    title,
    onAddClick,
    onDeleteClick,
    showSearch,
    cardData,
    close,
    containerName,
    onDragEnter,
    entityName,
    customCard,
    inTileView,
    showTileView,
    columnEntityName,
    onEditDataItem,
    selectedIconColor = { Mobile: true, TV: true, Web: true, Tab: true },
    setSelectedIconColor = () => { },
    showDeleteAll = false,
    onDeleteAllData
  } = props;

  var showPreferenceButton = props.showPreferenceButton ?? false;
  var showEpisodeArchiveCheckbox = props.showEpisodeArchiveCheckbox ?? false;
  var showExport = props.showExport ?? false;
  var showMultiVersioning = props.showMultiVersioning ?? false
  var showMultiSelect = props.showMultiSelect ?? false;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedDataItem, setSelectedDataItem] = useState({});
  const [selectedState, setSelectedState] = useState({}); // for multi select
  const [selectedData, setSelectedData] = useState([]);
  const [showUpdateMultiple, setShowUpdateMultiple] = useState(false);
  const [showMultiVersionPopup, setShowMutliVersionPopup] = useState(false);
  const [data, setData] = useState([]);

  const [orginalData, setOrginalData] = useState([]); // for search
  const [isMoredata, setIsMoredata] = useState(true);
  const [dataColumns, setDataColumns] = useState([]);
  const [tileView, setTileView] = useState(inTileView); //true to show tile
  const [showPreferences, setShowPreferences] = useState(false);
  const [selectAll, setSelectAll] = useState(data.length != selectedData.length ? true : false);

  const [railsStatusItem, setRailsStatusItem] = useState({
    _id: 0,
    Description: "All"
  });
  const lang = useTranslation();
  //Export Excel
  const _export = useRef(null);

  var currentPage = 1;
  useEffect(() => {
    loadDataIntialData();
  }, [cardData]);

  useEffect(() => {
    loadColums();
  }, []);

  const loadColums = async () => {
    let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
    let columns = await API.getFormData(columnEntityName, currentUserId);
    setDataColumns(columns);
  }


  let final_data_rail = [];

  const loadDataIntialData = async () => {


    //if card data is defined by user then no need to take data from network;
    if (cardData) {
      setData(cardData);
      setIsMoredata(false);
      setOrginalData(cardData);
      return;
    }

    var res = {};

    if (entityName == ENTITYNAME.Rails) {
      var columns = await API.getFormData(
        ENTITYNAME.Rails,
        utility.getValue(LOCALSTORAGE_KEY.user_id)
      );
      res = await API.getRailsWithCampaign(
        {
          query: ["Archive", "=", false],
          page: currentPage,
          perPage: 100,
          archive: false,
          sort: { SID: 1 },
        },
        columns,
        '',
        RAIL_STATUS.Active
      );

    } else {

      res = await API.getData(entityName, {
        pagination: {
          per_page: 10,
          current_page: currentPage,
          orderby: "SID",
          direction: "asc",
        },
        query: ["Archive", "=", false],
      });
    }
    if (res.success) {

      if (entityName == ENTITYNAME.Rails) {

        let ott_types = await API.getDataLookup(ENTITYNAME.OttRailType);
        let ott_Platform_Types = await API.getDataLookup(ENTITYNAME.Platform);

        final_data_rail = res.data.map((item) => {
          return {
            ...item,
            PotraitImageUrl: ott_types.data.find((data) => data.SID == item.OttRailTypeSID).Icon,
            RailType: ott_types.data.find((data) => data.SID == item.OttRailTypeSID).Description,
            PlatformTypes: ott_Platform_Types.data?.filter((data) => item.PlatformSIDs?.includes(data.SID))
          };
        });
      } else {
        final_data_rail = res.data;
      }

      setData((oldData) => [...final_data_rail]);
      setOrginalData((oldData) => [...final_data_rail]);
      // setIsMoredata(orginalData.length < res.pagination.total)
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onCloseUserPrefence = () => {
    loadColums();
    loadDataIntialData();
    setShowPreferences(!showPreferences);

  }

  const loadData = async () => {
    if (cardData) return; // if data comes from parent component then there is no need to maintain pagination way

    let res = await API.getData(entityName, {
      pagination: {
        per_page: 10,
        current_page: currentPage,
        orderby: "SID",
        direction: "asc",
      },
    });
    if (res.success) {
      console.log();
      setData((oldData) => [...oldData, ...res.data]);
      setOrginalData((oldData) => [...oldData, ...res.data]);
      setIsMoredata(orginalData.length < res.pagination.total);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchMoreData = () => {
    console.log("fetchmoredata");
    currentPage++;
    loadData();
  };

  const setHoveredDataItemPostion = (index, dataItem) => {
    //if index  -1 means we need to add card at last

    setSelectedIndex((ind) => (index == -1 ? data.length - 1 : index));
    setSelectedDataItem(dataItem);
  };

  let defaultKeys = [];

  const onSearch = (e) => {

    const keyword = e.target.value?.trim();
    let datakey = Object.keys(orginalData[0]);
    datakey.map((key) => {
      if (key == "Title" && !defaultKeys.includes(key)) {
        defaultKeys.push(key);
      }
      else if (key == "Name" && !defaultKeys.includes(key)) {
        defaultKeys.push(key);
      }
      else if (key == "AssetId" && !defaultKeys.includes(key)) {
        defaultKeys.push(key);
      }
      else if (key == "ReferenceCode" && !defaultKeys.includes(key)) {
        defaultKeys.push(key);
      }
    });
    let dataStore = [];
    if (keyword != "") {
      defaultKeys.map((item) => {
        var filterData = orginalData.filter((obj) => obj[item] && obj[item].toLowerCase().includes(keyword.toLowerCase()));
        if (filterData.length != 0) {
          dataStore.push(...filterData);
        }
      });
      const uniqData = [...new Set(dataStore)];
      handleRailStatusChange({ target: { value: railsStatusItem } }, uniqData)
    } else {
      handleRailStatusChange({ target: { value: railsStatusItem } }, orginalData)
      // If the text field is empty, show all users
    }
  };

  const onSelection = (index, dataItem) => {
    let copyData = [...data];
    resetSelection(copyData);
    copyData[index] = { ...Object.assign(dataItem, { isSelected: true }) };
    setData(copyData);
  };

  const resetSelection = (copyData) => {
    copyData.map((dataItem) => {
      Object.assign(dataItem, { isSelected: false });
    });
  };

  //show all 3 colour

  const onFilterIconClick = (type) => {
    var filterData = [];

    setSelectedIconColor({ ...selectedIconColor, [type]: !selectedIconColor[type] })

    if (type == "Mobile") {
      if (selectedIconColor.Mobile) {
        filterData = [
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4'))
        ]
      } else {
        filterData = [
          ...orginalData.filter((x) => x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4'))
        ]
      }
    } else if (type == 'TV') {
      if (selectedIconColor.TV) {
        filterData = [
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4'))
        ]
      } else {
        filterData = [
          ...orginalData.filter((x) => x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4')),
        ]
      }
    } else if (type == 'Web') {
      if (selectedIconColor.Web) {
        filterData = [
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4')),
        ]
      } else {
        filterData = [
          ...orginalData.filter((x) => x.PlatformSIDs?.includes('3')),
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Tab && x.PlatformSIDs?.includes('4')),
        ]
      }
    } else if (type == 'Tab') {
      if (selectedIconColor.Tab) {
        filterData = [
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
        ]
      } else {
        filterData = [
          ...orginalData.filter((x) => x.PlatformSIDs?.includes('4')),
          ...orginalData.filter((x) => selectedIconColor.Mobile && x.PlatformSIDs?.includes('1')),
          ...orginalData.filter((x) => selectedIconColor.TV && x.PlatformSIDs?.includes('2')),
          ...orginalData.filter((x) => selectedIconColor.Web && x.PlatformSIDs?.includes('3')),
        ]
      }
    } else {
      setSelectedIconColor({
        Mobile: true,
        TV: true,
        Web: true,
        Tab: true
      })
    }

    let data = [...new Set(filterData)]
    handleRailStatusChange({ target: { value: railsStatusItem } }, data)
    // setData(Array.from(data));
    // console.log(filterData)
    // console.log(data);
    // console.log(Array.from(data))

  }

  const excelExport = () => {
    if (data.length == 0) {
      return;
    }
    if (_export.current !== null) {
      _export.current.props.data.map(item => {
        item.Duration = item.Duration ? utility.convertMilisecondsToStringWithFrames(item.Duration) : '';
        item.modOn = item.modOn ? utility.convertMilisecondsToDateTimeString(item.modOn) : '';
        item.addOn = item.addOn ? utility.convertMilisecondsToDateTimeString(item.addOn) : '';
      })
      _export.current.save();
    }
  };

  const onSegmentationExport = async () => {
    if (_export.current !== null) {
      // _export.current.props.data.map(item => {
      //   console.log(item);
      // })
      let payload = _export.current.props.data;
      console.log(payload)
      var res = await API.getSegmentationDataExport(payload);
      if (res.success) {
        if (res.dataLength == 0) {
          toast.info(`${lang.no_data_found_for_selected_filter_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
        else {
          window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
          toast.success('Successfully Exported!', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };


  const onUpdateMultiple = () => {
    setShowUpdateMultiple(true);
  }

  const onMultiVersioning = () => {

    if (selectedData.length == 0) {
      toast.error(`${lang.please_select_episodes_first}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    setShowMutliVersionPopup(true);

  }

  const onSelectAllData = (e) => {
    let newSelectedState = {};
    if (data.length > 0) {
      data.map((item) => {
        newSelectedState = { ...newSelectedState, [item._id]: e.target.checked }
      });
    }
    setSelectedState(newSelectedState);
    setSelectedData(data);
    setSelectAll(e.target.checked);
  }

  const handleRailStatusChange = (e, data) => {
    let newData = [];
    if (e.target.value._id != RAIL_STATUS.All) {
      newData = data.filter((x) => x.IsActive == e.target.value._id)
    } else {
      newData = [...data]
    }

    setRailsStatusItem(e.target.value);
    setData(newData);
  }

  return (
    <div className={props.isWide ? "side-bar-items-30 marlr p-1" : "side-bar-items marlr p-1"}

      style={{ backgroundColor: "#EEEEEE", borderRadius: "10px" }}
    >
      <div className="row">
        {title && <h3 className='col'>{title}</h3>}
        {selectedData.length > 1 && !tileView ?
          <p className="col-1" title={'Update multiple'} style={{ marginTop: '23px' }} >
            <i className="fas fa-pencil fa-lg ponter-cursor" onClick={onUpdateMultiple} />
          </p> : ''}
        {showMultiVersioning && !tileView ?
          <p className="col-1" title={lang.export_segmentation_tooltip_button} style={{ marginTop: '23px' }} >
            <i className="fas fa-file-export fa-lg ponter-cursor" onClick={onSegmentationExport} />
          </p> : ''}
        {showMultiVersioning && !tileView ?
          <p className="col-1" title={lang.multi_versioning_tooltip_button} style={{ marginTop: '23px' }} >
            <i className="fas fa-layer-group fa-lg ponter-cursor" onClick={onMultiVersioning} />
          </p> : ''}
        {showExport && !tileView ?
          <p className="col-1" title={lang.export_excel_button_tooltip} style={{ marginTop: '23px' }} >
            <i className="fas fa-download fa-lg ponter-cursor" onClick={excelExport} />
          </p> : ''}
        {showTileView && <p className="col-1" title={!tileView ? lang.title_view_tooltip_button : lang.close_title_view_tooltip_button} style={{ marginTop: '23px' }} >
          <i className={`fas ${tileView ? 'fa-table-cells' : 'fa-table-list'} fa-lg ponter-cursor`} onClick={() => setTileView(!tileView)} />
        </p>}
        {showDeleteAll && <p className="col-1" title={lang.delete_all_button_tooltip} style={{ marginTop: '23px', marginRight: '-10px' }} >
          <i className="fas fa-trash-alt ponter-cursor" onClick={onDeleteAllData} />
        </p>}
        <p className="col-1 mr-2" title={lang.close_button_tooltip} style={{ marginTop: '22px' }} >
          <i className="fa fa-close fa-lg ponter-cursor" onClick={close} />
        </p>
      </div>
      <div className="flex-container">
        <div className="martb mar">
          {onAddClick && <AddRoundButton onClick={onAddClick} />}
          {showPreferenceButton && <PreferenceRoundButton icon={"gear"} title={lang.preference_button_tooltip} onClick={()=>{ setShowPreferences(!showPreferences) }}/>}
        </div>
        <div className="flex-container-reverse flex-item-auto">
          {showSearch && (
            <div className="input-group input-group w-300 ">
              <input
                type="text"
                className="form-control shadow-inset-2 pt-0 pb-0"
                id="filter-icon"
                aria-label="type 2 or more letters"
                placeholder={lang.search_by_title}
                onChange={onSearch}
              />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      {/* (props.title == 'Rails' || props.entityName == ENTITYNAME.Rails) */}
      {(props.title == 'Rails' || props.entityName == ENTITYNAME.Rails) && <div className="row p-1">
        <div className="col-5" style={{ paddingRight : '0px'}}>
          <DropDownList
            data={Object.keys(RAIL_STATUS).map((obj, index) => { return { _id: index, Description: obj } })}
            style={{
              backgroundColor: 'white',
            }}
            name="railsStatus"
            textField="Description"
            dataItemKey="_id"
            value={railsStatusItem}
            onChange={(e) => handleRailStatusChange(e, orginalData)}
          />

        </div>

        <div className="col-7" style={{ textAlign: 'right' }}>
          <RoundButton icon={"mobile"} title={"Moblie"} btnColor={selectedIconColor.Mobile ? 'warning' : 'white'} onClick={() => onFilterIconClick("Mobile")} />
          <RoundButton icon={"television"} title={"TV"} btnColor={selectedIconColor.TV ? 'warning' : 'white'} onClick={() => onFilterIconClick("TV")} />
          <RoundButton icon={"globe"} title={"Web"} btnColor={selectedIconColor.Web ? 'warning' : 'white'} onClick={() => onFilterIconClick("Web")} />
          <RoundButton icon={"tablet"} title={"Tablet"} btnColor={selectedIconColor.Tab ? 'warning' : 'white'} onClick={() => onFilterIconClick("Tab")} />
        </div></div>}
      <div>
        <div className="float-right">
          {showMultiSelect && <>
            <input name={"SelectAll"} type={"Checkbox"} value={selectAll} onChange={(e) => onSelectAllData(e)} checked={data.length == selectedData.length && selectAll}
              style={{ marginLeft: "10px", textAlign: "right", marginRight: "5px" }} />
            <label>{lang.select_all_label}</label>
          </>}
          {showEpisodeArchiveCheckbox && <>
            <input name={"Archive"} type={"Checkbox"} value={props.archiveEpisode} onChange={() => { props.setArchiveEpisode(!props.archiveEpisode); props.loadEpisodes(undefined, !props.archiveEpisode) }} checked={props.archiveEpisode}
              style={{ marginLeft: "10px", textAlign: "right", marginRight: "5px" }} />
            <label>{lang.archive}</label>
          </>}
        </div>
        {showMultiSelect && showEpisodeArchiveCheckbox && <br />}
      </div>

      <InfiniteScroll
        dataLength={data.length}
        next={fetchMoreData}
        hasMore={isMoredata}
      // loader={<h6>Loading...</h6>}
      // endMessage={<h6>All Loaded</h6>}
      >
        {tileView ? <div
          id="MainCardContainer"
          style={{ height: showEpisodeArchiveCheckbox ? "68vh" : "71vh", overflowY: "auto", overflowX: "hidden" }}
          draggable
          onDragEnter={(e) => {
            if (e.target.id == "MainCardContainer") {
              setSelectedIndex((old) => e.target.children.length + 1);
            }
            onDragEnter(e, selectedIndex, containerName);
          }}
        >
          {
            data.map((dataItem, index) => {
              return (
                <>
                  {customCard ?
                    <RailsCardList
                      key={index}
                      index={index}
                      dataItem={dataItem}
                      {...props}
                      setHoveredDataItemPostion={setHoveredDataItemPostion}
                      onSelection={onSelection} /> :
                    <MediaCard
                      key={index}
                      index={index}
                      dataItem={dataItem}
                      {...props}
                      setHoveredDataItemPostion={setHoveredDataItemPostion}
                      onSelection={onSelection}
                    />}</>
              );
            })}
        </div> : <CardListGridView data={data} setData={setData} _export={_export} dataColumns={dataColumns} onEditDataItem={onEditDataItem} onArchiveEpisodes={props.onArchiveEpisodes} archiveEpisode={props.archiveEpisode} enableSelection={props.enableSelection} showMultiVersioning={showMultiVersioning} selectedState={selectedState} setSelectedData={setSelectedData} showMultiVersionPopup={showMultiVersionPopup} setShowMutliVersionPopup={setShowMutliVersionPopup} showUpdateMultiple={showUpdateMultiple} setShowUpdateMultiple={setShowUpdateMultiple} loadEpisodes={props.loadEpisodes} />
        }
      </InfiniteScroll>
      {showPreferences && <UserPreference entityName={columnEntityName} preferenceColumnENntityName={columnEntityName} handleClose={onCloseUserPrefence} />}
      {cardData && cardData ? <div>{`${lang.total_label} ${cardData.length}`}</div> : final_data_rail.length > 0 ? <div>{`${lang.total_label} ${final_data_rail.length}`}</div> : <div>{`${lang.total_label} ${data.length}`}</div>}
    </div>
  );
};
