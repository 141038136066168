/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, OPERATORS } from "../../framework/constant/constant";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { DataHelper } from "../../framework/helper/DataHelper";
import BossDialog from "../../components/BossDialog";

export const ScheduleFilter = (props) => {
    //possible props
    let showTemplate = props.showTemplate ?? true;
    const [mediaCategories, setMediaCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const lang = useTranslation();

    let blankDataItem = {
        MediaCategory: [],
        MediaCategoryType: [],
        Duration: OPERATORS[0],
        Condition: '00:00:00:00',
        Brand: [],
        Product: [],
        IsDummy: false,
        CreatedFrom: '',
        CreatedTo: '',
    }

    const [dataItem, setDataItem] = useState({
        ...blankDataItem,
        ...props.data,
        Condition: (props.data?.Duration?.Condition && utility.convertMilisecondsToStringWithFrames(props.data?.Duration?.Condition)) ?? blankDataItem.Condition
    });

    const [template, setTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [hideSaveTemplateCheckBox, setHideSaveTemplateCheckBox] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [isCleared, setIsCleared] = useState(false);


    useEffect(() => {
        loadCombo();
        if (props.selectedTemplate && Object.keys(props.selectedTemplate).length > 0) {
            setSelectedTemplate(props.selectedTemplate);
            setHideSaveTemplateCheckBox(true);
            if (props.selectedTemplate.FilterModel) {
                setDataItem({
                    ...props.selectedTemplate.FilterModel,
                    Condition: utility.convertMilisecondsToStringWithFrames(props.selectedTemplate.FilterModel.Duration.Condition)
                })
            }
        }
    }, [props.mediaCategory]);


    const loadCombo = async () => {

        if (props.templateData && props.templateData.length > 0) {
            setTemplate(props.templateData.filter((x) => x._id != 0));
        } else {
            let templateRes = await API.getDataLookup(ENTITYNAME.SchedulingFilterTemplate, { sort: { Name: 1 } });
            setTemplate(templateRes.data);
        }

        let mediaCategoryType = await API.getDataLookup(ENTITYNAME.MediaCategoryType, { sort: { Description: 1 } })
        let filteredMediaCategoryType = [...mediaCategoryType.data]

        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { Description: 1 } })
        let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == false)
        setMediaCategories(filteredMediaCategory)

        if (props.disableMediaCategory && props.mediaCategory) {
            setDataItem({ ...dataItem, MediaCategory: filteredMediaCategory.filter((x) => x.SID == props.mediaCategory) })
            filteredMediaCategoryType = filteredMediaCategoryType.filter((x) => x.MediaCategorySID == props.mediaCategory)
        }
        setMediaCategoryType(filteredMediaCategoryType);

        let brand = await API.getDataLookup(ENTITYNAME.Brand, { sort: { Name: 1 } })
        setBrands(brand.data);
        let product = await API.getDataLookup(ENTITYNAME.Product, { sort: { Name: 1 } });
        setProducts(product.data);

    }


    const onChange = (e) => {

        if (e.target.name == 'IsDummy') {
            setDataItem({ ...dataItem, IsDummy: e.target.checked });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
        if (e.target.value) {
            setIsCleared(false);
        }

        if (e.target.name == 'Template') {
            setDataItem(e.target.value.FilterModel);
            setSelectedTemplate(e.target.value);
            props.setSelectedTemplate && props.setSelectedTemplate(e.target.value)
            setHideSaveTemplateCheckBox(true);
            return;
        }

        if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
            return;
        }

        //EDIT MODE
        if(props.selectedTemplate && Object.keys(props.selectedTemplate).length == 0){
            setHideSaveTemplateCheckBox(false);
        }
    }

    const onSearchClick = () => {

        let filterDataItem = {
            MediaCategory: dataItem.MediaCategory,
            MediaCategoryType: dataItem.MediaCategoryType,
            Brand: dataItem.Brand,
            Product: dataItem.Product,
            Duration: { ...dataItem.Duration, Condition: dataItem?.Condition && typeof dataItem?.Condition == 'string' ? utility.convertStringWithFramesToMilliseconds(dataItem.Condition) : dataItem?.Condition },
            IsDummy: dataItem.IsDummy ?? false,
            CreatedFrom: dataItem.CreatedFrom,
            CreatedTo: dataItem.CreatedTo,
        }

        console.log(filterDataItem);

        if (filterDataItem.MediaCategory.length == 0 && filterDataItem.MediaCategoryType.length == 0 && filterDataItem.Brand.length == 0 && filterDataItem.Product.length == 0 && filterDataItem.Duration.Condition == 0 && !filterDataItem.CreatedFrom && !filterDataItem.CreatedTo) {
            props.isFilterCleared(true);
        } else {
            props.isFilterCleared(false);
        }

        if (saveAsTemplate) {
            if (!templateName || templateName == "") {
                toast.error(lang.please_enter_template_name_to_save_template_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            } else {
                onConfirmSaveTemplate();
            }
        }

        props.toGetFilterDataItem(filterDataItem);
        props.closeFilterPopup();

    }


    const onConfirmSaveTemplate = async () => {

        let saveData = {
            SID: 0,
            user_id: utility.getValue(LOCALSTORAGE_KEY.user_id),
            Name: templateName,
            FilterModel: DataHelper.saveScheduleFilterTemplate(dataItem)
        }

        console.log(saveData);

        let res = await API.saveData(ENTITYNAME.SchedulingFilterTemplate, saveData);

        if (!res.success) return;
        utility.deleteLocalStorageItem(ENTITYNAME.SchedulingFilterTemplate);
        loadCombo();
        props.loadcombo();
        setSelectedTemplate(res.data);
        props.setSelectedTemplate && props.setSelectedTemplate(res.data);
        setHideSaveTemplateCheckBox(true);
        setSaveAsTemplate(false);
        setTemplateName('');

    }

    const onClear = () => {
        setHideSaveTemplateCheckBox(false);
        setDataItem({...blankDataItem, MediaCategory: mediaCategories.filter((x) => x.SID == props.mediaCategory)});
        setSelectedTemplate({});
        props.setSelectedTemplate && props.setSelectedTemplate({});
        setSaveAsTemplate(false);
        setTemplateName('');
        setIsCleared(true);
        props.onClear();
    }

    const deleteItem = async () => {
        const res = await API.deleteData(ENTITYNAME.SchedulingFilterTemplate, selectedTemplate.SID);
        console.log(res);
        utility.deleteLocalStorageItem(ENTITYNAME.SchedulingFilterTemplate);
        onClear();
        props.onClear()
        loadCombo();
        props.loadcombo();
        props.setSelectedTemplate && props.setSelectedTemplate({});
        props.closeFilterPopup()
        props.refresh();
    }

    const saveItem = async () => {
        let updateDataItem = DataHelper.saveScheduleFilterTemplate(dataItem) ?? {};

        delete updateDataItem.Condition;

        let updatedTemplate = {
            ...selectedTemplate,
            FilterModel : updateDataItem
        }

        const res = await API.saveData(ENTITYNAME.SchedulingFilterTemplate, updatedTemplate);
        console.log(res);
        utility.deleteLocalStorageItem(ENTITYNAME.SchedulingFilterTemplate);
        props.toGetFilterDataItem(dataItem);
        loadCombo();
        props.loadcombo();
        setSelectedTemplate(res.data);
        props.setSelectedTemplate && props.setSelectedTemplate(res.data);
    }

    return (
        <>
            <BossDialog
                title={lang.search_content_dialog_header}
                width={"600px"}
                onClose={() => {
                    props.closeFilterPopup();
                    if (isCleared) {
                        onSearchClick();
                    }
                }}
            >
                 <div className="row">
                    <div className="col-12">
                        <ActionButton title={lang.search_button_tooltip} name={lang.search_button_text} onClick={onSearchClick} />
                        <ActionButton title={lang.clear_button_tooltip} name={lang.clear_button_text} btnColor={"danger"} onClick={onClear} />
                    </div>
                </div>
                <div className='row mt-3' style={{ marginLeft: "-02px", width: "570px" }}>
                    <div className="col-12">
                        {showTemplate && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.template_label} : </label>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <DropDownList
                                        data={template}
                                        name={"Template"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={selectedTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-2">
                                {hideSaveTemplateCheckBox && <button className={`btn btn-default btn-circle btn-my`} onClick={saveItem} type="button" title={lang.save_button_tooltip} style={{ marginLeft: "-15px" }}>
                                    <i className="fa fa-floppy-disk" aria-hidden="true" ></i>
                                </button>}
                                {hideSaveTemplateCheckBox && <button className={`btn btn-default btn-circle btn-my`} onClick={deleteItem} type="button" title={lang.delete_button_tooltip} style={{ marginLeft: "5px" }}>
                                    <i className="fa fa-trash" aria-hidden="true" ></i>
                                </button>}
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.media_category_label} </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MultiSelect
                                        data={mediaCategories}
                                        name={"MediaCategory"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.MediaCategory}
                                        onChange={onChange}
                                        disabled={(props.disableMediaCategory && props.mediaCategory) ?? false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.media_category_type_label} </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MultiSelect
                                        data={mediaCategoryType}
                                        name={"MediaCategoryType"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.MediaCategoryType}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.brand_label} </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MultiSelect
                                        data={brands}
                                        name={"Brand"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={dataItem.Brand}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.product_label} </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <MultiSelect
                                        data={products}
                                        name={"Product"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={dataItem.Product}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.duration_label} :</label>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <DropDownList
                                        data={OPERATORS}
                                        name={"Duration"}
                                        textField="Description"
                                        dataItemKey="ID"
                                        value={dataItem.Duration}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <TimePickerWithFormat
                                        name="Condition"
                                        value={dataItem.Condition}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 mt-3">
                                <label htmlFor="TabView">{lang.created_date_label} :</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{fontSize: '11px', color: 'grey'}}>{lang.from_label}</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedFrom"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedFrom}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{fontSize: '11px', color: 'grey'}}>{lang.to_label}</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedTo"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedTo}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {showTemplate && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.is_dummy_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name={"IsDummy"}
                                        type={"checkbox"}
                                        value={dataItem.IsDummy}
                                        checked={dataItem.IsDummy}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}

                        {!hideSaveTemplateCheckBox && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name={"SaveAsTemplate"}
                                        type={"checkbox"}
                                        value={saveAsTemplate}
                                        onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                        checked={saveAsTemplate}
                                        onChange={() => {}}
                                    />
                                </div>
                            </div>
                        </div>}

                        {saveAsTemplate && <div className="row">
                            <div className="col-4 mt-2">
                                <label htmlFor="TabView">{lang.template_name_label} * : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name={"templateName"}
                                        value={templateName}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </BossDialog>

        </>
    );
};
