import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import * as API from "../../../API/api";
import { useEffect } from "react";
import { utility } from "../../../utility/utilityProvider";
import { ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES, PRODUCTS, ROLETYPE } from "../../../constant/constant";
import { RoundIcon } from "./RoundIcon";
import { useTranslation } from "../../../../locale/useTranslation";


export const GlobalSearchBar = () => {

  const navigate = useNavigate();
  const lang = useTranslation();
  const location = useLocation();

  const baseURL = window.location.protocol + window.location.host;

  const [searchResult, setsearchResult] = useState([]);
  const [search, setSearch] = useState('');
  const [showResultBox, setShowResultBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState([]);
  const [modules,setModules] = useState([]);
  const selectedRef = useRef(null);

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  const version = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
  const isDigitalSignage = version && version.IsDigitalSignage || baseURL.includes('cms.automateb') || baseURL.includes('app.automateb')

  const getModule = async () => {
    
    if (user?.RoleType?.ID == ROLETYPE.SuperAdmin || user?.RoleType?.ID == ROLETYPE.Admin) {
      let moduleValue = await API.getData(ENTITYNAME.Module);
      setModules(moduleValue.data);
      if(isDigitalSignage){
        setApps(user?.Module ?? []);
      } else {
        setApps(moduleValue.data);
      }
    } else {
      setApps(user?.Module ?? []);
    }
  };

  useEffect(() => {

    getModule();

    document.addEventListener("searchBarOpen", onSearchBarOpen);
    // on escape remove focus from searchInput
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        document.getElementById("searchInput").blur();
      }
    })
    return () => {
      document.removeEventListener("searchBarOpen", onSearchBarOpen);
    }
  }, [])

  const onSearchBarOpen = (event) => {
    //create focus on searchInput
    document.getElementById("searchInput").focus();
  }

  const handleSearch = async (event) => {
    selectedRef.current = null;
    setSearch(event.target.value);

    if (event.target.value.length > 0 && event.target.value.trim().length > 0) {
      setLoading(true);

      //FOR DIGITAL SIGN USER DO NOT CALL global search API
      let res = isDigitalSignage  ? { data : [] } : await API.globalSearch(event.target.value);
      
      const appData = apps.filter(item => { return item.Name.toLowerCase().includes(search.toLowerCase()) }).map(data => ({ ...data, isApp: true }));
      // mix apps and media randomly
      setsearchResult([...appData, ...res.data]);
      setLoading(false);
    } else {
      setsearchResult([]);
    }
  };

  const onItemClick = (item) => {
    if (item.isApp) {
      navigate(item?.path);
    } else if (item.MediaCategory.Description) {
      let link = "MediaEpisode/" + item?.MediaCategory?.Description + "/" + item.SID;
      setsearchResult([]);
      setSearch('');

      // if(location.pathname);
      navigate(link);

      location.pathname.toLowerCase().includes("mediaepisode") && window.location.reload(false);
    } else {
      console.log(item);
      console.log("item not found");
    }
  }

  const getColorAndIcon = (mediacategorySID) => {
    switch (mediacategorySID) {
      case MEDIACATEGORIES.Movie:
        return { color: "#c94d27", icon: "fa-m" };
      case MEDIACATEGORIES.Commercial:
        return { color: "#ff7272", icon: "fa-c" };
      case MEDIACATEGORIES.Program:
        return { color: "#f9a825", icon: "fa-p" };
      case MEDIACATEGORIES.Episode:
        return { color: "#c94d27", icon: "fa-e" };
      default:
        return { color: "#7458e0", icon: "fa-i" };
    }
  }

  return (
    <div className="mt-2">
      <i id="searchIcon" style={{ color: "#e4e4e4", position: "absolute", left: "5px", top: "15px" }} className="fa fa-search fa-md"></i>
      <input
        onKeyUp={(e) => {
          if (e.key == 'ArrowDown' && showResultBox) {
            const dataItems = document.getElementsByClassName("dataItem");
            if (dataItems.length > 0) {
              if (selectedRef.current != null && selectedRef.current < dataItems.length - 1) {
                dataItems.item(selectedRef.current).classList.remove("hover");
                dataItems.item(selectedRef.current + 1).classList.add("hover");
                const scrollDiv = document.getElementById("scrollbarForSeachBox");
                scrollDiv.scrollTop = dataItems.item(selectedRef.current + 1).offsetTop - scrollDiv.offsetTop - 30;
                selectedRef.current = selectedRef.current + 1;
              }
              else if (selectedRef.current == dataItems.length - 1) {
                return;
              }
              else {
                dataItems.item(0).classList.add("hover");
                selectedRef.current = 0;
              }
            }
          }
          if (e.key == 'ArrowUp' && showResultBox) {
            const dataItems = document.getElementsByClassName("dataItem");
            if (dataItems.length > 0) {
              if (selectedRef.current != null && selectedRef.current > 0) {
                dataItems.item(selectedRef.current).classList.remove("hover");
                dataItems.item(selectedRef.current - 1).classList.add("hover");
                const scrollDiv = document.getElementById("scrollbarForSeachBox");
                scrollDiv.scrollTop = dataItems.item(selectedRef.current - 1).offsetTop - scrollDiv.offsetTop - 30;
                selectedRef.current = selectedRef.current - 1;
              }
              else if (selectedRef.current == 0) {
                return;
              }
              else {
                dataItems.item(dataItems.length - 1).classList.add("hover");
                selectedRef.current = dataItems.length - 1;
              }
            }
          }
          if (e.key == 'Enter' && showResultBox) {
            const dataItems = document.getElementsByClassName("dataItem");
            if (dataItems.length > 0 && selectedRef.current != null && selectedRef.current < dataItems.length) {
              dataItems.item(selectedRef.current).click();
              document.getElementById("searchInput").blur();
            }
          }
        }}
        style={{
          height: "30px",
          width: "500px",
          outline: "1px inset gray",
        }}
        autoComplete="off"        
        type="text"
        className="bg-transparent form-control shadow-inset-2 pt-0 pb-0 pl-4 "
        id="searchInput"
        aria-label="type 2 or more letters"
        placeholder={lang.global_search_bar_placeholder}
        onChange={handleSearch}
        value={search}
        onFocus={() => {
          //add class bg-white and remove bg-dark
          setShowResultBox(true);
          selectedRef.current = null;
          document.getElementById("searchIcon").style.color = "gray";
          document.getElementById("searchInput").classList.remove("bg-transparent");
          document.getElementById("searchInput").classList.add("bg-white");
          document.getElementById("searchInput").style.color = "black";
          document.getElementById("searchInput").style.fontWeight = "400";
        }}
        onBlur={() => {
          //remove class bg-white and add bg-dark
          setTimeout(() => {
            setSearch('');
            setsearchResult([]);
            document.getElementById("searchIcon").style.color = "#e4e4e4";
            document.getElementById("searchInput").classList.remove("bg-white");
            document.getElementById("searchInput").classList.add("bg-transparent");
            document.getElementById("searchInput").style.color = "white";
            document.getElementById("searchInput").style.fontWeight = "300";
            setShowResultBox(false);
            selectedRef.current = null;
          }, 150);
        }}
      />

      {showResultBox && (
        <div className="mt-2 dataResult">
          <div id="scrollbarForSeachBox" className="scrollbarForSeachBox" style={{ height: "calc(100% - 40px)", overflow: "auto" }}>
            {
              search.length > 0 && searchResult.length > 0 ? searchResult.map((item, i) => {

                return <div style={{ position: "relative" }} className="dataItem" onClick={() => {
                  onItemClick(item);
                }}>

                  {item?.isApp ? item.Name : item.Title}

                  <div className="dataitemText">{item?.isApp ? item.Name : item?.AssetId ?? ''} {!item?.isApp && '- '} {item?.MediaCategory?.Description ?? ''}</div>
                  <RoundIcon title={item?.isApp ? "App" : item?.MediaCategory?.Description ?? "Content"} icon={item?.isApp ? 'fa-a' : getColorAndIcon(item?.MediaCategory?.SID)?.icon} onClick={() => {
                    console.log(item)
                    if (!item?.isApp) {
                      onItemClick(item);
                    } else {
                      navigate(item.path);
                    }
                  }} color="white" bgColor={item?.isApp ? "#1fa8e8" : getColorAndIcon(item?.MediaCategory?.SID)?.color} style={{ position: "absolute", right: "15px", top: "10px" }} />

                </div>
              }) : searchResult.length == 0 && apps.filter(item => { return item.Name.toLowerCase().includes(search.toLowerCase()) }).length > 0 ? apps.filter(item => { return item.Name.toLowerCase().includes(search.toLowerCase()) }).map((item, i) => {

                return <div style={{ position: "relative" }} className="dataItem" onClick={() => {
                  navigate(item.path);
                }}>

                  {item.Name}

                  <div className="dataitemText">{item.Name}</div>
                  <RoundIcon title="App" icon="fa-a" onClick={() => navigate(item.path)} color="white" bgColor="#1fa8e8" style={{ position: "absolute", right: "15px", top: "10px" }} />

                </div>
              }) :
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {loading ? <i className="fa fa-spinner fa-spin fa-2xl" /> :
                    searchResult.length == 0 && search.length > 0 ? <img src={require('../../../../img/noRecords.jpeg')} alt="search" style={{}} /> :
                      <p style={{ color: "black", fontSize: "16px" }}>Search for apps, content, and more.</p>
                  }
                </div>
            }

          </div>
          <div style={{ height: "40px", position: "absolute", bottom: 0, width: "100%", overflow: "hidden" }}>
            <hr className="mb-1 mt-0" />
            <RoundIcon title={"App"} icon={'fa-a'} color="white" bgColor={"#1fa8e8"} style={{ scale: "0.7", marginLeft: "5px" }} /><p style={{ display: "inline-block", marginTop: "7px", fontSize: "11px", marginRight: "3px" }}> App</p>

            <RoundIcon title={"Episode"} icon={getColorAndIcon(MEDIACATEGORIES.Episode).icon} color="white" bgColor={getColorAndIcon(MEDIACATEGORIES.Episode).color} style={{ scale: "0.7" }} /><p style={{ display: "inline-block", marginTop: "7px", fontSize: "11px", marginRight: "3px" }}> Episode</p>

            <RoundIcon title={"Interstitial"} icon={getColorAndIcon(MEDIACATEGORIES.Interstitial).icon} color="white" bgColor={getColorAndIcon(MEDIACATEGORIES.Interstitial).color} style={{ scale: "0.7" }} /><p style={{ display: "inline-block", marginTop: "7px", fontSize: "11px", marginRight: "3px" }}> Interstitial</p>

            <RoundIcon title={"Movie"} icon={getColorAndIcon(MEDIACATEGORIES.Movie).icon} color="white" bgColor={getColorAndIcon(MEDIACATEGORIES.Movie).color} style={{ scale: "0.7" }} /><p style={{ display: "inline-block", marginTop: "7px", fontSize: "11px", marginRight: "3px" }}> Movie</p>

            <RoundIcon title={"Program"} icon={getColorAndIcon(MEDIACATEGORIES.Program).icon} color="white" bgColor={getColorAndIcon(MEDIACATEGORIES.Program).color} style={{ scale: "0.7" }} /><p style={{ display: "inline-block", marginTop: "7px", fontSize: "11px", marginRight: "3px" }}> Program</p>

            <p style={{ color: "gray", paddingRight: "5px", lineHeight: "16px", marginLeft: "22px", display: "inline-block" }}>Shortcut <span style={{ backgroundColor: "#ededed", padding: "2px 5px", marginLeft: "2px", borderRadius: "5px", color: "gray", fontSize: "12px", fontWeight: "bold" }}>Control + /</span></p>
          </div>
        </div>

      )}


    </div>
  );
};