/* eslint-disable */
import React, { useRef, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import * as API from '../../../framework/API/api';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import IngestionTemplateParamFilters from "./IngestionTemplateParamFilters";
import { toast } from 'react-toastify';
import { useTranslation } from "../../../locale/useTranslation";
import { ENTITYNAME, GetHeader, INGESTIONTEMPLATETYPES } from "../../../framework/constant/constant";
import { useEffect } from "react";
import HeaderRowAndSheetPopup from "../../PartnerIngestion/SubComponents/HeaderRowAndSheetPopup";
import { RadioButton } from "@progress/kendo-react-inputs";

const IngestionTemplateMiddleSection = (props) => {

  const [showHeaderRowSheetIndexPopup,setShowHeaderRowSheetIndexPopup] = useState(false);
  const [ selectedExcelSheets, setSelectedExcelSheets ] = useState([]);
  const [headerRowSheetIndex,setHeaderRowSheetIndex] = useState({
    HeaderRowIndex : 1,
    SheetIndex : 0
  });

  const blankRequestResponseDetails = {
    Url: "",
    RequestType: { SID: 1, Description: "GET" },
    Response: "",
    Body: "",
    ResponseKeysObject: { SID: 0, Description: "ROOT" },
    Curl: "",
    IsCurl: false    
  }

  useEffect(() => {

    if (props.SID > 0) {
      loadEditData();
    }

  }, [])

  const [templateMiddleSectionDataItem, setTemplateMiddleSectionDataItem] = useState(blankRequestResponseDetails);
  const templateMiddleSectionDataItemReference = useRef(blankRequestResponseDetails);
  const [paramsValues, setParamsValues] = useState([]);
  const textareaRef = useRef(null);
  const setDataItem = (obj) => {
    setTemplateMiddleSectionDataItem(obj);
    templateMiddleSectionDataItemReference.current = obj;
  }

  const loadEditData = async () => {
    let res = await API.getEntity(ENTITYNAME.PartnerIntegrationTemplate, parseInt(props.SID));
    console.log(res);
    if (res.success) {
      setDataItem({
        RequestType: res.data.RequestType ?? { SID: 1, Description: "GET" },
        Url: res.data.Url,
        Body: res.data.Body,
        Response: res.data.Response,
        ResponseKeysObject: res.data.ResponseKeysObject,
        IsCurl:res.data.IsCurl,
        Curl:res.data.Curl
      });
      setSelectedOption(res.data.IsCurl ? "CurlButton" : "RequestButton");
      if (props.isAPISelected && res.data.Response) setResponseKeysList(res.data.Response);

      setParamsValues(res.data.ParamKeys);

      if (props.isAPISelected && res.data.Response) getKeysListFromAPIResponse();
      else {
        props.getKeysList(res.data.PartnerKeysList);
      }
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const [fileDataItem, setFileDataItem] = useState(!props.isAPISelected ? { file: "" } : {});
  const [files, setFiles] = useState([]);// to store selected file

  const lang = useTranslation();

  const [requestType, setrequestType] = useState([{ SID: 1, Description: "GET" }, { SID: 2, Description: "POST" }, { SID: 3, Description: "PUT" }, { SID: 4, Description: "DELETE" }]);
  const [responseKeys, setResponseKeys] = useState([{ SID: 0, Description: "ROOT" }]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedResponseTab, setselectedResponseTab] = useState(0);
  const [selectedOption, setSelectedOption] = useState("RequestButton");
  const handleChange = (e) => {

    setDataItem({ ...templateMiddleSectionDataItem, [e.target.name]: e.target.value });

    props.updateRequestResponseDetail({ ...templateMiddleSectionDataItem, [e.target.name]: e.target.value });

    if (e.target.name == "ResponseKeysObject") {
      getKeysListFromAPIResponse();
    }

  };

  const handleFileChange = async (e) => {
    
      setFileDataItem({ ...fileDataItem, [e.target.name]: e.target.value });
      setFiles(e.target.files[0]);

    if(props.headerDataItem.IngestionTemplateType.SID == INGESTIONTEMPLATETYPES.Excel){
      let excelSheetsRes = await API.getExcelSheets(e.target.files[0]);
      if (excelSheetsRes.success && excelSheetsRes.data.length > 0) {
        setSelectedExcelSheets(excelSheetsRes.data);
      }
      setHeaderRowSheetIndex({ HeaderRowIndex: 1, SheetIndex: 0 });
      setShowHeaderRowSheetIndexPopup(true);
    } else {
      loadDataFromFile(e.target.files[0])
    }

  };

  const handleOnHeaderRowSheetIndexConfirm = () => {
    loadDataFromFile(files);
  }

  const handleTabSelect = (e) => {
    setSelectedTab(e.selected);
  };

  const getQueryParamString = function (paramKeyValues) {

    let queryString = "";

    for (let i = 0; i < paramKeyValues.length; i++) {
      let separator = (i == 0) ? "?" : "&";
      queryString = queryString + separator + paramKeyValues[i]["paramkey"] + "=" + paramKeyValues[i]["paramvalue"];
    }

    return queryString;
  }

  const loadDataFromFile = async (files) => {
    let res = await API.readFile(files,headerRowSheetIndex.HeaderRowIndex - 1,headerRowSheetIndex.SheetIndex);

    if (res.success && res.data && res.data.columns && res.data.columns.length > 0) {
      let keyList = [];
      res.data.columns.forEach((item, index) => {
        if(item && item.length > 0){
          let obj = { SID: index, Description: item };
          keyList.push(obj);
        }
      });

      props.getKeysList(keyList);

      toast.success(`${lang.data_loaded_successfully_ingestion_template_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(res.data?.columns.length == 0 ? "Not data found" : "Error Loading Data. Please try again !!", {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    setShowHeaderRowSheetIndexPopup(false);
  }

  const onSendRequest = async (e) => {

    let filterString = getQueryParamString(paramsValues);
    let finalUrl = templateMiddleSectionDataItem.Url + filterString;

    //ADDING PARAMS VALUES
    props.updateRequestResponseDetail({ ...templateMiddleSectionDataItem, Params: paramsValues });
    props.getParams(paramsValues);

    let response = {};
    let responseJson = {};
    try {

      if (templateMiddleSectionDataItem.RequestType.Description == "POST") {
        let Finalinit = { ...GetHeader(templateMiddleSectionDataItem.RequestType.Description) }; // POST
        response = await fetch(finalUrl, Finalinit);
        responseJson = await response.json();
      } else {
        response = await fetch(finalUrl);
        responseJson = await response.json();
      }

    } catch (error) {
      toast.error('Invalid Request, Please Check Api Request Type & Parameters', {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    // TO SHOW REPONSE ON UI
    setDataItem({ ...templateMiddleSectionDataItem, Response: responseJson });
    

    // ADDING RESPONSE TO SEND BACK TO SAVE IN TEMPLATE
    props.updateRequestResponseDetail({ ...templateMiddleSectionDataItem, Response: responseJson });

    setResponseKeysList(responseJson);
    getKeysListFromAPIResponse();

  }

  const setResponseKeysList = function (responseJson) {

    let keys = getAllKeys(responseJson,'');
    let keysFromResponse = keys.map((x,i) => { return { SID: i+1, Description: x } });
    
    setResponseKeys([{ SID: 0, Description: "ROOT" }, ...keysFromResponse]);

  }

  // GETTING ALL KEYS FROM RESPONSE OBJECT
  const getAllKeys = (obj, parentKey = '', result = []) => {
    for (let key in obj) {
        let fullKey = parentKey ? `${parentKey}.${key}` : key;
        result.push(fullKey);
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
            getAllKeys(obj[key], fullKey, result);
        }
    }
    return result;
  };

  //GETTING VALUE BY KEY
  const getValueByKeyPath = (obj, keyPath) => {
    return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
  }

  // CONVERTING KEYS ARRAY LIST TO LIST OF OBJECTS TO USE IT IN DROPDOWNS
  const getKeysListFromAPIResponse = function () {
    let keysList = [];
    const selectedObject = templateMiddleSectionDataItemReference.current.ResponseKeysObject;

    // 0 SID means ROOT Selected
    const finalObject = selectedObject && selectedObject.SID > 0 ? 
      getValueByKeyPath(templateMiddleSectionDataItemReference.current.Response, selectedObject.Description) :
      templateMiddleSectionDataItemReference.current.Response;

    if (finalObject == undefined) return null;

    let tempFinalObject = { ...finalObject};

    if (Array.isArray(finalObject)) {
      tempFinalObject = { ...finalObject[0] };
    }

    keysList = getAllKeys(tempFinalObject, '', keysList).map((x, i) => { return { SID: i + 1, Description: x } });
    props.getKeysList(keysList);
  }

  const updateParams = (data) => {
    setParamsValues(() => data);
    props.getParams(data);
  }


  const onChangeRadioButtton = (e) => {
    setSelectedOption(e.target.name);
    let item = {}
    if (e.target.name == "RequestButton") {
      item = { ...templateMiddleSectionDataItem, Curl: "", IsCurl: false }
    } else {
      item = { ...templateMiddleSectionDataItem, Url: "", IsCurl: true }
    }
    setDataItem(item);
  }
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "Curl") {
      const cursorPos = e.target.selectionStart;
      setDataItem({ ...templateMiddleSectionDataItem, Curl: value });

      if (value.includes('{')) {
        const filteredSuggestions = MESSAGE_TEMPLATE.filter(template =>
          template.toLowerCase().includes(value.split('{').pop().toLowerCase())
        );
        setSuggestions(filteredSuggestions);
        setShowSuggestions(true);
        updateSuggestionsPosition(cursorPos);
        setActiveSuggestionIndex(-1);
      } else {
        setShowSuggestions(false);
      }
    } else if (name === "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [name]: value });
    }
  };


  return (
    <div className={`${props.isAPISelected ? "col-md-12 col-lg-8 col-sm-12 col-xs-12" : "col-12"} mt-2`} style={{ padding: props.isAPISelected ? '0px 5px 0px 15px' : '0px 15px 0px 15px' }}>
      <Form
        initialValues={templateMiddleSectionDataItem}
        render={(formRenderProps) => (
          <FormElement>
            {props.isAPISelected ?
              <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", height: "80vh" }}>

                <div className="row">
                  <div className="ml-2" style={{ marginTop: "10px" }}>
                    <RadioButton
                      name="RequestButton"
                      className="ml-4"
                      checked={selectedOption == "RequestButton"}
                      label={lang.request_label}
                      onClick={onChangeRadioButtton}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <RadioButton
                      name="CurlButton"
                      checked={selectedOption == "CurlButton"}
                      label={lang.curl_label}
                      onClick={onChangeRadioButtton}
                    />
                  </div>
                </div>
                {selectedOption == "RequestButton" ? <>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-md-2 col-lg-2 col-sm-2 col-xs-3" style={{ marginTop: "10px" }}>
                      <DropDownList
                        style={{
                          height: "38px",
                          backgroundColor: "white"
                        }}
                        data={requestType}
                        name="RequestType"
                        textField="Description"
                        value={templateMiddleSectionDataItem.RequestType}
                        onChange={handleChange}
                      />

                    </div>

                    <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8" style={{ marginTop: "10px", padding: '0px' }}>
                      <input type="text" className="form-control form-control-md" name="Url" value={templateMiddleSectionDataItem.Url} onChange={(e) => handleChange(e)} />

                    </div>

                    <div className="col-md-1 col-lg-2 col-sm-1 col-xs-1" style={{ marginTop: "13px", padding: '0px 0px 0px 5px' }}>
                      <button title={lang.send_button_tooltip} type="button" onClick={(e) => onSendRequest(e)} className="btn-square btn-primary" >
                        {lang.send_text}
                      </button>
                    </div>

                    <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3" style={{ marginTop: "10px" }}>

                      <DropDownList
                        style={{
                          height: "38px",
                          backgroundColor: "white"
                        }}
                        data={responseKeys}
                        name="ResponseKeysObject"
                        textField="Description"
                        value={templateMiddleSectionDataItem.ResponseKeysObject}
                        onChange={handleChange}
                      />

                    </div>

                  </div>

                  {/* Params And Body Section */}
                  <div className="row" style={{ margin: "10px 5px 5px 5px" }}>
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <TabStrip selected={selectedTab} onSelect={handleTabSelect} >

                        {/* Platform Tab */}
                        <TabStripTab title={lang.params_label}>
                          <IngestionTemplateParamFilters updateParams={updateParams} data={paramsValues} />
                        </TabStripTab>

                        <TabStripTab title={lang.body_label}>

                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">

                              {/* <input type="text" className="form-control form-control-lg " name="SearchTextValue" value={templateMiddleSectionDataItem.SearchTextValue} onChange={(e) => handleChange(e)} /> */}
                              <textarea className="form-control form-control-lg " name="Body" value={templateMiddleSectionDataItem.Body} onChange={(e) => handleChange(e)} style={{ width: "57vw", height: "26vh" }} />

                            </div>
                          </div>
                        </TabStripTab>
                      </TabStrip>
                    </div>
                  </div>


                </>
                  :
                  <div className='row mt-2'>
                    <div className='col-md-10 col-lg-10 col-sm-10 col-xs-10 ml-3' style={{ position: "relative",marginTop: "10px"}}>
                      <textarea id='myTextArea' ref={textareaRef} type='text' style={{ height: '20vh' }} name='Curl' className='form-control form-control-sm' value={templateMiddleSectionDataItem.Curl} onChange={(e) => handleChange(e)}/>
                    </div>
                    <div className="col-md-1 col-lg-1 col-sm-1 col-xs-1" style={{ marginTop: "10px", padding: '0px 0px 0px 5px' }}>
                      <button title={lang.send_button_tooltip} type="button" onClick={(e) => onSendRequest(e)} className="btn-square btn-primary" >
                        {lang.send_text}
                      </button>
                    </div>
                  </div>
                }
                {/* Response Section */}
                <div className="row" style={{ margin: "10px 5px 5px 5px" }}>
                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <TabStrip selected={selectedResponseTab} style={{ height: "26vh" }} >
                      <TabStripTab title={lang.response_label}>
                        <pre>
                          <code>{JSON.stringify(templateMiddleSectionDataItem.Response, null, 2)}</code>
                        </pre>
                      </TabStripTab>
                    </TabStrip>
                  </div>
                </div>
              </div>


              :
              <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", height: "95px" }}>

                <div className="row" style={{ margin: "5px", padding: "25px 15px" }}>

                  <div className="col-md-4 col-sm-4 col-xs-4" style={{ marginTop: "5px", padding: '0px' }}>
                    <input type="text" className="form-control form-control-sm" name="filename" value={fileDataItem.file ?? ""} disabled={true} />
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4" style={{ margin: "5px", padding: '0px' }}>
                    <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '0px 0px 0px 3px' }}>
                      <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                        <input id="fileupload" name="file" className='custom-file-updated-input form-control form-control-sm' type="file" hidden="hidden" onChange={handleFileChange} />
                      </label>
                    </button>

                    {/* <button title="Load" type="button" onClick={(e) => loadDataFromFile(e)} className="btn-square btn-primary" >
                      {lang.load_button_text}
                    </button> */}
                  </div>

                </div>
              </div>}
          </FormElement>
        )}
      />

      {showHeaderRowSheetIndexPopup && <HeaderRowAndSheetPopup onConfirm={handleOnHeaderRowSheetIndexConfirm} onClose={() => { setShowHeaderRowSheetIndexPopup(false) }} sheets={selectedExcelSheets} setHeaderRowSheetIndex={setHeaderRowSheetIndex} />}

    </div>
  )
};

export default IngestionTemplateMiddleSection;
