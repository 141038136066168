/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import BossDialog from "../../components/BossDialog";

const ClientEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    Name: "",
    Code: "",
    Email: "",
    CreditLimit: 0,
    PAN: "",
    GST: "",
    Agency: [],
    DefaultTax: {},
    IsCreditUnlimited: false,
    Archive: false,
    Address: "",
    PinCode: "",
    SalesTeam: {},
    SalesExecutive: [],
    SpotSeparation: '00:00:00:00',

  })

  const [showCollectionSellection, setShowCollectionSelection] = useState(false)
  const [defaultTax, setDefaultTax] = useState([]);
  const [salesTeam, setSalesTeam] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [agency, setAgency] = useState([]);
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const lang = useTranslation();

  useEffect(() => {
    pageRender();
  }, []);

  const pageRender = async () => {
    await loadcombo();
    if (props?.item?.SID > 0) {
      await loadEditData();
    }
  }

  const loadEditData = async () => {

    let linkData = {
      link_collection: 'users',
      link_field: 'SalesExecutive'
    }

    var res = await API.getEntity(ENTITYNAME.Client, props.item.SID, linkData);
    console.log(res.data);
    if (res.success) {
      setDataItem({
        ...dataItem, ...res.data,
        Name:  props?.item?.copy ? 'Copy of ' + res.data.Name : res.data.Name ,
        SpotSeparation: res.data.SpotSeparation == null ? "00:00:00:00" : utility.convertMilisecondsToStringWithFrames(res.data.SpotSeparation),
        Agency: Array.isArray(res.data.Agency) ? res.data.Agency : [res.data.Agency]
      })
      setGridData(() => res.data.SalesExecutive)
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const isValid = () => {

    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Name.trim()) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Code == "" || dataItem.Code == undefined) {
      toast.error(`${lang.please_enter_code_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Code.trim()) {
      toast.error(`${lang.please_enter_code_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Agency.length == 0) {
      toast.error(`${lang.please_select_agency_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Email == undefined || dataItem.Email == "" || !emailRegex.test(dataItem.Email)) {
      toast.error(`${lang.please_enter_email_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.CreditLimit < 0) {
      toast.error(`${lang.please_enter_positive_value_in_creditlimit_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.PinCode < 0) {
      toast.error(`${lang.please_enter_positive_value_in_pin_code_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.DefaultTax).length == 0) {
      toast.error(`${lang.please_select_default_tax_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (gridData.length == 0) {
      toast.error(`${lang.please_select_atleast_one_Sales_executive}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }


  const loadcombo = async () => {
    var defaultTax = await API.getDataLookup(ENTITYNAME.Tax, { sort: { Description: 1 } });
    setDefaultTax(defaultTax.data);
    var agency = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
    setAgency(agency.data);
    var salesTeamRes = await API.getDataLookup(ENTITYNAME.SalesTeam, { sort: { Name: 1 } });
    setSalesTeam(salesTeamRes.data);
    if (props.item.SID == 0) {
      setDataItem({ ...dataItem, DefaultTax: utility.getDefaultItem(defaultTax.data) });
    }
  }

  const onchange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else if (e.target.name == "IsCreditUnlimited") {
      setDataItem({ ...dataItem, IsCreditUnlimited: !dataItem.IsCreditUnlimited, CreditLimit: 0 });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const handleSubmit = async () => {

    if (isValid()) {

      var localData = {
        _id: dataItem._id,
        SID: props?.item?.copy ? 0 : dataItem.SID,
        Name: dataItem.Name,
        Code: dataItem.Code,
        Agency: dataItem.Agency && dataItem.Agency.length > 0 ? dataItem.Agency.map((x) => {
          return { _id: x._id, SID: x.SID, Name: x.Name, DefaultTax: x.DefaultTax }
        }) : [],
        Address: dataItem.Address,
        PinCode: dataItem.PinCode,
        Email: dataItem.Email,
        PAN: dataItem.PAN,
        GST: dataItem.GST,
        DefaultTax: {
          _id: dataItem.DefaultTax._id,
          SID: dataItem.DefaultTax.SID,
          Description: dataItem.DefaultTax.Description
        },
        CreditLimit: parseInt(dataItem.CreditLimit),
        SalesTeam: {
          _id: dataItem.SalesTeam._id,
          SID: dataItem.SalesTeam.SID,
          Name: dataItem.SalesTeam.Name
        },
        SalesExecutive: gridData?.length > 0 ? gridData?.map(x => x._id) : [],
        SpotSeparation: dataItem.SpotSeparation ? utility.convertStringWithFramesToMilliseconds(dataItem?.SpotSeparation) : "",
        IsCreditUnlimited: dataItem.IsCreditUnlimited ?? false,
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Name", "=", dataItem.Name]]
      }

      if (props?.item?.copy) {
        delete localData.SID;
        delete localData._id;
      }
      console.log(localData);
      let res = await API.saveData(ENTITYNAME.Client, localData);

      if (res.success) {
        let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_CLIENT : LOGEVENT.INSERT_CLIENT, module: MODULE.CLIENT, data: res.data, message: res.message };
        API.SaveLogs(logData);
        utility.deleteLocalStorageItem('client');
        props.cancelEdit();
        props.refresh();
      }
      else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const viewCollectionSelection = (e) => {
    if (Object.keys(dataItem.SalesTeam).length == 0) {
      toast.error(`${lang.please_select_sales_team_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      setShowCollectionSelection(!showCollectionSellection);
    }
  }

  const handleGridData = (dataList) => {
    var newData = utility.checkDuplicateInData(dataList, gridData, "Name")
    setGridData([...gridData, ...newData]);
  }

  const deleteSelectedGridData = (dataItem) => {
    setGridData(gridData.filter((obj) => dataItem.SID != obj.SID))
  }

  const MyCommercialCommandCell = (props) => (
    <td style={{ padding: "0px" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedGridData(props.dataItem), () => { })} />
    </td>
  )
  

  return (<>

    <BossDialog
      title={
        props?.item?.Name ? props?.item?.Name : `${lang.client_dialog_header}`
      }
      onClose={props.cancelEdit}
      width={"70vw"}
    >
      <div className="row m-1">
        <div className="col-12">
          <EditPageHeader title={""}
            onSubmit={handleSubmit}
            onCancel={props.cancelEdit}
            showTitle={false}
          />
          <div className="row mt-2">
            <div className="col-12">
              <div className="row form-group">
                <div className="col-4">
                  <label htmlFor="TabView">{lang.name_label} *</label>
                  <input className="form-control form-control-sm" type="text" name={"Name"} onChange={onchange} value={dataItem.Name} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.code_label} *</label>
                  <input className="form-control form-control-sm" type="text" name={"Code"} onChange={onchange} value={dataItem.Code} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.agency_label} *</label>
                  <MultiSelect
                    style={{
                      backgroundColor: "white",
                    }}
                    data={agency}
                    name="Agency"
                    textField="Name"
                    dataItemKey="_id"
                    value={dataItem.Agency}
                    onChange={onchange}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-4">
                  <label htmlFor="TabView">{lang.address_label}</label>
                  <input className="form-control form-control-sm" type="text" name={"Address"} onChange={onchange} value={dataItem.Address} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.pincode_label}</label>
                  <input className="form-control form-control-sm" type="number" name={"PinCode"} onChange={onchange} value={dataItem.PinCode} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.email_label} *</label>
                  <input className="form-control form-control-sm" type="email" name={"Email"} value={dataItem.Email} onChange={onchange} />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-4">
                  <label htmlFor="TabView">{lang.credit_limit_label}</label>
                  <input className="form-control form-control-sm" disabled={dataItem.IsCreditUnlimited} type="number" name={"CreditLimit"} onChange={onchange} value={dataItem.CreditLimit} min={0} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.pan_label}</label>
                  <input className="form-control form-control-sm" type="text" name={"PAN"} value={dataItem.PAN} onChange={onchange} />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.gst_number_label}</label>
                  <input className="form-control form-control-sm" name={"GST"} type="text" onChange={onchange} value={dataItem.GST} />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-4">
                  <label htmlFor="TabView">{lang.default_tax_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={defaultTax}
                    name="DefaultTax"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.DefaultTax}
                    onChange={onchange}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="TabView">{lang.sales_team_label} *</label>
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={salesTeam}
                    name="SalesTeam"
                    textField="Name"
                    dataItemKey="_id"
                    value={dataItem.SalesTeam}
                    onChange={onchange}
                  />
                </div>
                <div className='col-4'>
                  <label>{lang.spot_separation_label}</label>
                  <TimePickerWithFormat
                    className="form-control form-control-sm"
                    name="SpotSeparation"
                    value={dataItem.SpotSeparation}
                    onChange={onchange}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-4">
                  <input type="checkbox" name={"IsCreditUnlimited"} value={dataItem.IsCreditUnlimited} checked={dataItem.IsCreditUnlimited} onChange={onchange} />
                  <label className="pl-2" htmlFor="TabView">{lang.is_credit_unlimited_label}</label>
                </div>
                <div className="col">
                  <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
                  <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="float-left">
                <AddRoundButton title={lang.add_sales_executive_button_tooltip} onClick={viewCollectionSelection}></AddRoundButton>
              </div>
              <div className="float-right mt-2">
                {lang.sales_executive_label}
              </div>
              <div className="mt-5">
                <Grid style={{ height: "25vh", marginBottom: '35px', overflow: "hidden" }} data={gridData}>
                  <GridColumn cell={MyCommercialCommandCell} width={"40px"} locked={true} />
                  <Column field="name" title={lang.name_column} editable={false} />
                  <Column field="PhoneNo" title={lang.phone_no_column} />
                  <Column field="Email" title={lang.email_column} editable={false} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCollectionSellection && <CollectionSelection addButtonTitle={lang.select_collection_button_text} title={lang.select_sales_executive_colllection_header} entityname={ENTITYNAME.Users} wherestatement={[['IsSalesExecutive', '=', true], ['SalesTeam.SID', '=', dataItem.SalesTeam.SID]]} setDataList={handleGridData} closeForm={viewCollectionSelection} width={"50vw"}  />}
    </BossDialog>
  </>)
}

export default ClientEditForm;