import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import * as API from '../../framework/API/api'
import { utility } from "../../framework/utility/utilityProvider";
import { LOCALSTORAGE_KEY, LOGIN_WARNING_MESSAGE, MODULE, PRODUCTS, SOCKET_ACTION } from "../../framework/constant/constant";
import { ConfirmAlertOk } from "../../ConfirmAlert";
import { useTranslation } from "../../locale/useTranslation";
import DigitalSignLoginForm from "../DigitalSign/LoginPage/DigitalSignLoginForm";
import { ToastContainer, toast } from "react-toastify";
import * as API_DigiSign from '../../framework/API/api_digitalSign';
import { DigitalSignCanActivateScreenForm } from "../DigitalSign/LoginPage/DigitalSignCanActivateScreenForm";
import { DigitalSignHelper } from "../DigitalSign/helper/DigitalSignHelper";

const Login = () => {

    const baseURL = window.location.protocol + window.location.host;
    const navigate = useNavigate();
    const lang = useTranslation();
    const [showError, setShowError] = useState(false);
    const [version, setVersion] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [isDigitalSignage, setIsDigitalSignage] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const urlParams = new URLSearchParams(useLocation().search ?? "");
    const activationCode = urlParams.get('activationCode');
    const activationInProgress = useRef(false);

    useEffect(() => {
        let userToken = utility.getValue(LOCALSTORAGE_KEY.token);
        let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        let isDigitalSignage = utility.isAutomateB();

        if (userToken && userData) {
            if (isDigitalSignage) {
                if (activationCode) {
                    handleActivationScreen();
                    return;
                } else {
                    navigate("/home/digitalsignDashboard");
                }
            } else {
                navigate("/home");
            }
        } else {
            utility.clearLocalStorage();
            loadAppVersion();
        }
    }, []);

    const handleActivationScreen = async () => {
        if (activationInProgress.current) {
            console.warn("Activation is already in progress");
            return;
        }
        activationInProgress.current = true;
        try {
            const activateScreenRes = await activateScreen();
            navigate(`/canActivateScreen?success=${activateScreenRes.success}&message=${activateScreenRes.message}`);
        } finally {
            activationInProgress.current = false;
        }
    }

    const checkIfCanActivateScreen = async () => {
        let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        let canActivateScreenRes = await API_DigiSign.canActivateScreen(companyId);

        if (!canActivateScreenRes.success && canActivateScreenRes?.data?.length > 0) {
            return { success: true, data: canActivateScreenRes.data, message: canActivateScreenRes.message };
        }
        else if (!canActivateScreenRes.success) {
            return { success: false, data: null, message: canActivateScreenRes.message };
        }
        // if all screens are consumed
        else if (canActivateScreenRes.success && canActivateScreenRes.data != null && canActivateScreenRes.data.length == 0) {
            return { success: false, data: null, message: canActivateScreenRes.message };
        }
        else {
            return { success: true, data: canActivateScreenRes.data, message: canActivateScreenRes.message };
        }
    }

    const activateScreen = async () => {
        const res = await checkIfCanActivateScreen();
        const finalCompanyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
        
        if (!res.success) {
            return { success: false, message: res.message };
        }

        let selectedPlanId = null;
        if(Array.isArray(res?.data) && res?.data?.length > 0){
            if(res.data?.length == 1){
                selectedPlanId = res.data[0]?._id
            } else {
                navigate(`/canActivateScreen`, { state: { isChoosePlan: true, plans: res.data, activationCode: activationCode }});
                return;
            }
        } else {
            selectedPlanId = res.data?._id;
        }
        let activateScreenRes = await DigitalSignHelper.activateScreenFunc({ activationCode: activationCode, name: "Screen", selectedPlanId: selectedPlanId, companyId: finalCompanyId });
        return activateScreenRes;

    };

    const loadAppVersion = async () => {
        setIsLoading(true);
        let version = utility.getValue(LOCALSTORAGE_KEY.applicationVersion);
        if (version != null) {
            setVersion(version?.Version);
            return;
        }
        let versionRes = await API.getAppVersion();
        if (versionRes.success && Object.keys(versionRes.data).length > 0) {
            if (versionRes?.data?.IsDigitalSignage || baseURL.includes('app.automateb') || baseURL.includes('cms.automateb')) {
                setIsDigitalSignage(true);
            }
            setVersion(versionRes.data.Version);
            utility.setValue(LOCALSTORAGE_KEY.USER_LANGUAGE, versionRes?.data?.Language);
            utility.setValue(LOCALSTORAGE_KEY.applicationVersion, { Version: versionRes?.data?.Version, IsDigitalSignage: versionRes?.data?.IsDigitalSignage });
        }
        setIsLoading(false);
    }

    const handleLoginClick = async (e) => {
        e.preventDefault && e.preventDefault();

        // Show an alert or take appropriate action when there is no internet connection
        if (!navigator.onLine) {
            ConfirmAlertOk(
                () => { },
                lang.warning_label,
                lang.no_internet_connection_please_check_your_connection_and_try_again_label,
            )
            return;
        }

        const username = e.target.username.value;
        const password = e.target.password.value;

        let response = await API.login(username, password);

        if (response.success) {
            let loginDataRes = await utility.setLoginData(response);
            if (loginDataRes?.data?.Company && loginDataRes?.data?.Company?.Products?.length == 1 && loginDataRes?.data.Company?.Products.some(x => x == PRODUCTS.DigitalSignage)) {
                if (activationCode) {
                    await handleActivationScreen();
                    return;
                }
                let module = loginDataRes?.data?.Module ?? [];
                if (module.length > 0 && module?.some(x => x?.path == "/home/digitalsignDashboard")) {
                    navigate('/home/digitalsignDashboard');
                } else if (module.length > 0 && module?.some(x => x?.path == "/home/digitalSignAdminDashboard")) {
                    navigate('/home/digitalSignAdminDashboard');
                }
            } else {
                navigate('/home');
            }

        } else {
            setShowError(true)
        }

    }

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>

            {isLoading && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
                <i className="fa fa-spinner fa-spin fa-2xl" />
            </div>}

            {!isDigitalSignage && !isLoading ?
                <div className="container">
                    <div className="panel panel-default" style={{ border: 'solid 2px gray', borderRadius: 5, position: 'fixed', left: '47%', top: '40%', backgroundColor: 'white', zIndex: 100, marginTop: '-200px', width: 400, marginLeft: '-200px' }}>
                        <div className="panel-heading">
                            <div className="panel-title text-center "><span> <img style={{ height: "5rem" }} src={require("../../img/BossStudioLogo.png")} alt="SmartAdmin" /> </span></div>
                        </div>

                        <div className="panel-body">
                            <div className="well no-padding">
                                <form name="form" className="smart-form client-form" onSubmit={handleLoginClick}>
                                    <header>
                                        {lang.sign_in_label}
                                    </header>
                                    <fieldset>
                                        <section>
                                            <label className="label">{lang.username_login_label}</label>
                                            <label className="input">
                                                <i className="icon-append fa fa-user" />
                                                <input placeholder="BMS or abc@gmail.com" type="text" name="username" id="username" className="form-control" ng-model="username" required tabIndex={1} />
                                                <b className="tooltip tooltip-top-right"><i className="fa fa-user txt-color-teal" /> {lang.please_enter_user_name_login_label}</b>
                                            </label>
                                        </section>
                                        <section>
                                            <label className="label">{lang.password_login_label}</label>
                                            <label className="input">
                                                <i className="icon-append fa fa-lock" />
                                                <i style={{ marginRight: '10%', borderLeftWidth: 'none', borderLeftStyle: 'none' }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={(e) => { e.preventDefault(); handleTogglePassword(); }} />
                                                <input
                                                    placeholder="********"
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    id="password"
                                                    className="form-control"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    style={{ paddingRight: '70px' }}
                                                    required
                                                    tabIndex={2}
                                                />
                                                <b className="tooltip tooltip-top-right">
                                                    <i className="fa fa-lock txt-color-teal" />
                                                    {lang.enter_your_password_login_label}
                                                </b>
                                            </label>
                                        </section>

                                    </fieldset>
                                    <footer>
                                        {showError && <div ng-show="error" className="alert alert-danger">
                                            <a href="# " className="close" data-dismiss="alert"><span></span></a>{LOGIN_WARNING_MESSAGE}
                                        </div>}
                                        <span className="float-left mt-4">{version}</span>
                                        <button type="submit" ng-disabled="form.$invalid || dataloading" className="btn btn-danger" ng-click="login()">{lang.login_lable}</button>
                                    </footer>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                : isDigitalSignage && <DigitalSignLoginForm handleLoginClick={handleLoginClick} showError={showError} />}
            <ToastContainer />
        </>
    )
}

export default Login