/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "../../locale/useTranslation";
import DynamicDropdown from "../../framework/forms/helpercomponents/dropdown/DynamicDropdown";

function SeasonMetaDataEditForm({dataItem,setMataData}) {

  

  const [languages, setLanguages] = useState([]);
  const [pgRatings, setPGRatings] = useState([])
  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  const onChange = (e) => {
  
    setMataData({ ...dataItem, [e.target.name]: e.target.value });

  }

  const loadcombo = async () => {
   
    let languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(languages.data);
    let pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
    setPGRatings(pgRes.data);
  }

  return (
    <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "5px 10px 10px 10px" }}>
      <div className="row">
        <div className="col-12">
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div className="row mt-2">
                  <div className="marlr">
                  </div>
                </div>
                <div className="mt-2" style={{ border: "groove 1px" }}>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">
                      <div className="row">
                      

                      <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.production_year_label}</label>
                                <input type="text" className="form-control form-control-sm" name="ProductionYear" value={dataItem?.ProductionYear} onChange={(e) => onChange(e)} required />
                              </div>
                            </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.language_label}</label>
                            <MultiSelect
                              data={languages}
                              textField="Description"
                              value={dataItem?.Languages}
                              onChange={onChange}
                              name={"Languages"}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.pg_rating_label}</label><br />
                            {/* old */}
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={pgRatings}
                              name="PGRating"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem?.PGRating}
                              onChange={onChange}
                            />
                            {/* new */}

                            {/* <DynamicDropdown
                              data={pgRatings}
                              name="PGRating"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem?.PGRating}
                              onChange={onChange}
                              enityname={ENTITYNAME.PGRating}
                            defaultValue={dataItem.PGRating}
                            onChange={(value) => {
                              setDataItem({ ...dataItem, PGRating: value })
                              props.setMataData({ ...dataItem, PGRating: value });
                            }}
                            /> */}
                          </div>
                        </div>


                        <div className='col-6'>
                          <div className="form-group">
                            <label htmlFor="">{lang.keywords_label}</label>
                            <input name="Keywords" type="text" className="form-control form-control-sm" value={dataItem?.Keywords} onChange={onChange} />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )} />
        </div>

      </div>
    </div>
  )
}

export default SeasonMetaDataEditForm;