import React, {useEffect, useState} from 'react';

const expanderStyle = {
    margin: '6px 4px',
    padding: '2px',
    
};

const headerStyle = {
    display: 'flex',
    cursor: 'pointer'
};

const titleStyle = {
    padding: '8px',
    flex: 'none',
    fontWeight: "bold"
};

const subtextStyle = {
  margin: '10px 10px 5px 3px',
  flex: 'none',
  fontSize: "12px"
};

const spacerStyle = {
    flex: '1'
};

const iconStyle = {
    margin: '10px 10px 5px 3px',
    flex: 'none'
};

const contentStyle = {
    overflow: 'hidden',
    transition: 'all 0.3s'
};

const contentExpandedStyle = {
    ...contentStyle,
    padding: '4px 0',

    height: 'auto',
    filter: 'opacity(1)'
};

const contentCollapsedStyle = {
    ...contentStyle,
      padding: '0 0',
    border: '1px solid transparent',
    height: '0',
    filter: 'opacity(0)'
};


export const Expander = ({title, children, img,expand,color, subtext}) => {
  var expand = expand ?? false;

    const [expanded, setExpanded] = React.useState(expand);
    const [imageUrl, setImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');

    const handleHeaderClick = () => {
    	setExpanded(expanded => !expanded);
    };

    const handleError = () => {
      setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
    }

    useEffect(() => {
      setImageUrl(img)
    },[img])

  	return (
      <div style={{...expanderStyle,backgroundColor : color}}>
        <div style={headerStyle} onClick={handleHeaderClick}>
          {img && <img className="ExpandableTagImg" src={imageUrl} onError={handleError}  style={{ objectFit: "cover",   borderRadius:"8px"  }}/>}
          <div style={titleStyle}>{title}</div> <div style={subtextStyle}>{subtext}</div>
          <div style={spacerStyle} />
          <div style={iconStyle}>{<i className={`fa-solid fa-lg fa-angle-${expanded ? "up" : "down"}`}></i>}</div>
        </div>
        <div style={expanded ? contentExpandedStyle : contentCollapsedStyle}>
          {children}
        </div>
      </div>
    );
};
