/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import BossDialog from '../../components/BossDialog'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation';
import { Switch } from '@mui/material';
import * as API from '../../framework/API/api'
import { ENTITYNAME } from '../../framework/constant/constant'

export const SubscriberProfileEditForm = (props) => {
    const lang = useTranslation();
    const blankDataItem = {
        Description: props?.data?.Description ?? "",
        EnablePgRestriction: props?.data?.EnablePgRestriction ?? false,
        EnableParentalControl: props?.data?.EnableParentalControl ?? false,
        ContinueWatchings: props?.data?.ContinueWatchings ?? [],
        Restricted_PgRatings: props?.data?.Restricted_PgRatings ?? [],
        index: props?.data?.index ?? null
    };
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [pgRatings, setPgRatings] = useState([]);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let pgRatingRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        setPgRatings(pgRatingRes.data);
    };

    const isValid = () => {
        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!isValid()) return;
        let finalData = {
            Description: dataItem?.Description ?? "",
            EnablePgRestriction: dataItem?.EnablePgRestriction ?? false,
            EnableParentalControl: dataItem?.EnableParentalControl ?? false,
            Restricted_PgRatings: dataItem?.EnablePgRestriction ? dataItem?.Restricted_PgRatings : [],
            ContinueWatchings: dataItem?.ContinueWatchings ?? [],
            index: dataItem.index ?? null
        }
        console.log(finalData);

        props?.handleProfileData(finalData)
        props?.onClose()
    };

    const onChange = (e) => {
        const { name, checked, value } = e.target;
        if (name === "EnablePgRestriction" || name === "EnableParentalControl") {
            setDataItem({ ...dataItem, [name]: checked });
        } else {
            setDataItem({ ...dataItem, [name]: value });
        }
    };

    const onChangePgRestriction = (e, description) => {
        const isChecked = e.target.checked;
        setDataItem((prevState) => ({
            ...prevState,
            Restricted_PgRatings: isChecked
                ? [...prevState.Restricted_PgRatings, description]
                : prevState.Restricted_PgRatings.filter((item) => item !== description),
        }));
    };

    return (
        <BossDialog
            title={`${lang.subscriber_profile_dialog_header}`}
            onClose={props.onClose}
            width="52vw"
        >
            <div className='row mt-2'>
                <div className='col-12'>
                    <EditPageHeader
                        title={""}
                        onSubmit={handleSubmit}
                        onCancel={props.onClose}
                        showTitle={false}
                    />
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <label>{lang.description_label} *</label>
                            <input
                                name="Description"
                                type="text"
                                className="form-control form-control-sm"
                                onChange={onChange}
                                value={dataItem.Description}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-8">
                            <label className='mr-1 mt-2'>{lang.enable_pg_restriction_label}</label>
                            <Switch
                                name="EnablePgRestriction"
                                checked={dataItem.EnablePgRestriction}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-4">
                            <label className='mr-1 mt-2'>{lang.enable_parent_control_label}</label>
                            <Switch
                                name="EnableParentalControl"
                                checked={dataItem.EnableParentalControl}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-11 ml-3 border'style={{ maxHeight: '270px', overflowY: 'auto' }}>
                            {dataItem.EnablePgRestriction && (<>
                                <div className='row ml-2'>
                                    {pgRatings.map((x) => (
                                        <div className="col-6 p-1 mt-2" key={x._id}>
                                            <div className="row d-flex ">
                                                <div className="col-6 d-flex">
                                                    <label>{x?.Description}</label>
                                                </div>
                                                <div className="col-6">
                                                    <Switch
                                                        checked={dataItem.Restricted_PgRatings.includes(x.Description) ?? false}
                                                        onChange={(e) => onChangePgRestriction(e, x.Description)}
                                                        size="small"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        </BossDialog>
    );
};

