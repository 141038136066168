/* eslint-disable */
import { useState, useEffect, memo, useRef } from "react";
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { WrappedTextCell } from "../../framework/forms/helpercomponents/CustomGridCells/WrappedTextCell";
import { useTranslation } from "../../locale/useTranslation";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { ContextMenu } from "primereact/contextmenu";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import moment from "moment";
import { toast } from "react-toastify";
import MediaFastSegmentationPopup from "../Segment/MediaFastSegmentationPopup";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { UserPreference } from "../userPreference/UserPreference";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { utility } from "../../framework/utility/utilityProvider";
import useSocket from "../../framework/socket/useSocket";
import BossDialog from "../../components/BossDialog";
import { MediaFilterTemplate } from "./MediaFilterTemplate";

// wherestatement={['MediaCategory.isLong', '=', true]} 
const PlanningMediaLibraryCollection = memo((props) => {


    let myTitle = props.title ?? "";
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    const [gridData, setGridData] = useState([]);
    const [gridDataToShow, setGridDataToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [mediaCategories, setMediaCategories] = useState([])
    const [selectedMediaCategories, setSelectedMediaCategories] = useState({ value: [] });
    const [searchText, setSearchText] = useState('');
    const [filterDataItem, setFilterDataItem] = useState({});
    const [total, setTotal] = useState(1);
    const contextMenuRef = useRef(null);
    const selectedItemsRef = useRef([]);
    const [channelsList, setChannelList] = useState([]);
    const [openPopupScheduleMatch, setOpenPopupScheduleMatch] = useState(false);
    const [fastSegmentationPopupOpen, setFastSegmentationPopupOpen] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferenceState, setPreferenceState] = useState({});
    const [dataColumns, setDataColumns] = useState([]);
    const [scheduleMatchDataItem, setScheduleMatchDataItem] = useState({
        Channels: [],
        StartDate: "",
        StartTime: "",
    });
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [isFilterCleared, setIsFilterCleared] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});

    const setSelectedItemRef = (data) => {
        selectedItemsRef.current = data;
    }
    const initialSort = { field: 'SID', dir: "asc" }
    const [gridSort, setGridSort] = useState([initialSort]);
    const [page, setPage] = useState({
        skip: 0,
        take: 50,
    });
    const lang = useTranslation();

    useEffect(() => {
        loadMasters();
        if (props.channels.length > 0) {
            loaddata('', filterDataItem);
        }
    }, [props.channels]);

    const socket = useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

        //console.log(socketData)
        if (socketData.module != MODULE.LIVE_EVENTS) return;


        switch (socketData.action) {
            case SOCKET_ACTION.LIVE_EVENT_REFRESH:
                loaddata('', filterDataItem);
                break;
            default:
                break;
        }

    });


    const loadMasters = async () => {
        let channels = await API.getDataLookup(ENTITYNAME.Channel);
        if (channels.success) {
            setChannelList(channels.data);
        }
        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory)
        let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == true)
        setMediaCategories(() => [{ _id: 0, SID: 0, Description: 'Select All' }, ...filteredMediaCategory])
        setSelectedMediaCategories(() => ({ value: [{ _id: 0, SID: 0, Description: 'Select All' }, ...filteredMediaCategory] }))
    }

    const loaddata = async (searchText, filters, pagination = { per_page: page.take, current_page: 1, orderby: "SID", direction: "asc" }) => {

        try {
            setIsLoading(true);

            var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            var columns = await API.getFormData('planningmedialibrary', currentUserId);
            let json = await API.loadPlanningMediaLibrary(searchText, filters, pagination, props.channels);
            setDataColumns(columns);
            console.log(json);
            if (json.success) {
                setGridData(json.data);
                setGridDataToShow(json.data)
                setTotal(json.pagination.total);
                setIsLoading(false);
            } else {
                console.log(json.message);
                setIsNoRecords(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
            setIsNoRecords(true);
        }
    }

    const MyWrappedTextCell = (props) => <WrappedTextCell {...props} />;


    const onFilterSearchChange = (e) => {
        setSearchText(() => e.target.value);

        let sortEvent = gridSort[0] ?? initialSort;

        if (e.target.value == '') {
            loaddata('', filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        }
    };

    const onSearchClick = () => {

        let sortEvent = gridSort[0] ?? initialSort;

        if (searchText != '') {
            loaddata(searchText, filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });

        } else {
            loaddata('', filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        }

    }

    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            // Cancel the default action, if needed
            event.preventDefault();
            // handleServerSideSearch(event);
            onSearchClick();
        }
    }

    const toGetFilteredData = (data) => {
        let finalData = {
            ...data,
            ReleaseYear: data.ReleaseYear == "" ? null : parseInt(data.ReleaseYear),
        }
        setFilterDataItem(finalData);
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata(searchText, finalData, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
    }

    const onCloseUserPrefence = () => {
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir });
        setShowPreferences(!showPreferences);
    }



    const onDropDownChange = (event) => {
        //selectedMediaCategories stores all the selected dropdown values

        const currentSelectAll = selectedMediaCategories.value.some((i) => i.SID == 0);
        const nextSelectAll = event.value.some((i) => i.SID == 0);
        let value = event.value;
        const currentCount = selectedMediaCategories.value.length;
        const nextCount = value.length;

        if (
            nextCount > currentCount &&
            !currentSelectAll &&
            !nextSelectAll &&
            mediaCategories.length - 1 === nextCount
        ) {
            value = mediaCategories;
        } else if (
            nextCount < currentCount &&
            currentCount === mediaCategories.length &&
            currentSelectAll &&
            nextSelectAll
        ) {
            value = value.filter((v) => v.SID != 0);
        } else if (!currentSelectAll && nextSelectAll) {
            value = mediaCategories;
        } else if (currentSelectAll && !nextSelectAll) {
            value = []
        }


        setSelectedMediaCategories(() => ({ value: value }));

        let isSelectAll = value.some(v => v.SID == 0)

        if (isSelectAll || value.length == 0) {
            setGridDataToShow(() => gridData)
        } else {
            setGridDataToShow(() => {
                return gridData.filter(data => {
                    let isSameMedia = value.some(v => v.SID == data.MediaCategory.SID)
                    if (isSameMedia) {
                        return data
                    }

                })
            })
        }

    }

    const pageChange = async (e) => {

        let current_page = e.page.skip / e.page.take + 1;

        let sortEvent = gridSort[0] ?? initialSort;

        await loaddata(searchText, filterDataItem, { per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir });

        setPage(e.page);
    };

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata(searchText, filterDataItem, { per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir });
    }

    const ItemRender = (li, itemProps) => {

        return <div>
            <input
                type="checkbox"
                name={itemProps.dataItem}
                checked={itemProps.selected}
                onChange={(e) => itemProps.onClick(itemProps.index, e)}
            />
            &nbsp;{li.props.children}
        </div>
    };

    const onContextMenu = (e) => {
        e.syntheticEvent.preventDefault();
        contextMenuRef.current.show(e.syntheticEvent);
        console.log(e);
        setSelectedItemRef(e.dataItem);
    }

    const onChangeScheduleMatch = (e) => {
        setScheduleMatchDataItem({
            ...scheduleMatchDataItem,
            [e.target.name]: e.target.value,
        });
    };

    const onScheduleMatch = async (index, dataItem) => {
        contextMenuRef.current.hide();

        if (dataItem.Islive && dataItem.StartDateTime == null) {
            toast.error("This is not live media");
            return;
        }

        if (dataItem.StartDateTime == null) {
            toast.error("Start Date Time is not available for this media");
            return;
        }
        setOpenPopupScheduleMatch(true);
        let item = {
            ...dataItem,
            Channel: scheduleMatchDataItem.Channels,
            StartDate: moment(new Date(dataItem.StartDateTime))
                .utc()
                .format("YYYY-MM-DD"),
            StartTime: moment(new Date(dataItem.StartDateTime))
                .utc()
                .format("HH:mm:ss:SS"),
        };
        setScheduleMatchDataItem(item);
        console.log(item)
    };

    const onPublish = async () => {
        const currentDateInMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());

        //CHANNEL VALIDATION
        if (!scheduleMatchDataItem?.Channels || scheduleMatchDataItem?.Channels?.length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        //CANNOT SCHEDULE MATCH BEFORE CURRENT DATE //need to ask
        if (currentDateInMillisec > scheduleMatchDataItem?.StartDateTime) {
            let msg = scheduleMatchDataItem.Type.Description == "Live"
                ? `Cannot schedule ${scheduleMatchDataItem.Type.Description}, schedule date already passed`
                : `Cannot schedule ${scheduleMatchDataItem.Type.Description} before current date `;
            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        //CANNOT SCHEDULE MATCH BEFORE LIVE DATE
        if (
            !scheduleMatchDataItem?.IsLive &&
            scheduleMatchDataItem.StartDateTime >
            utility.getLocalDateTimeToUtcMiliseconds(
                new Date(scheduleMatchDataItem.StartDate)
            )
        ) {
            toast.error(
                ` Cannot schedule ${scheduleMatchDataItem.Type.Description} before live start date `,
                {
                    position: toast.POSITION.TOP_RIGHT,
                }
            );
            return false;
        }

        console.log("success");

        setOpenPopupScheduleMatch(false);

        let slotdatetime = utility.getLocalDateTimeToUtcMiliseconds(
            new Date(
                `${scheduleMatchDataItem.StartDate} ${scheduleMatchDataItem.StartTime}`
            )
        )

        console.log(scheduleMatchDataItem)

        var payload = {
            MediaEpisode_id: scheduleMatchDataItem?.mediaEpisode_id,
            SlotDateTime: slotdatetime,
            ScheduleDate: slotdatetime,
            Channel: scheduleMatchDataItem.Channels,
            MediaEpisodeLiveEventDetail_id: scheduleMatchDataItem?.mediaepisodedetailliveevent?._id,
        };

        //call schedule match api here
        var scheduleResponse = await API.scheduleMatch(payload);
        console.log(scheduleResponse);

        if (scheduleResponse.success) {
            onRefresh();
            props.planningLoading();
            toast.success(`${lang.match_scheduled_successfully_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });

            setScheduleMatchDataItem({
                Channels: [],
                StartDate: "",
                StartTime: "",
            });

            //REFRESH PLANNING, In Loop If Multiple Items Published
            for (let index = 0; index < payload.Channel.length; index++) {
                const ch = payload.Channel[index];

                socket.emit(SOCKET_EVENTS.onSocketData, {
                    action: SOCKET_ACTION.PLANNING_REFRESH,
                    module: MODULE.PLANNING,
                    data: { scheduleScheduleStartDate: payload.SlotDateTime, scheduleScheduleEndDate: payload.SlotDateTime, channelSID: ch.SID },
                });

            }
        } else {
            console.log(scheduleResponse)
            toast.error(scheduleResponse.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const onRefresh = () => {
        let current_page = page.skip / page.take + 1;
        setSearchText("");
        loaddata('', filterDataItem, { per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" });
    }

    const loadSavedFastSegments = () => {
        onRefresh();
    }

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const value = selectedMediaCategories.value
    const selected = value.length;

    return (
        <>
            <div style={{ height: "100%", width: "100%!important" }}>

                <h1 className="page-title" style={{ marginBottom: "-5px" }}>
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>{myTitle === "" ? props.entityname : myTitle}</span>
                </h1>

                <div className="flex-container pr-2 pl-2">
                    <div className="flex-container-reverse flex-item-auto">
                        {/* <FilterRoundButton onClick={() => { setFilterPopupOpen(true) }} /> */}
                        <PreferenceRoundButton onClick={() => { setShowPreferences(!showPreferences) }} />
                        <RefreshButton onClick={onRefresh} />
                    </div>
                </div>
                <div className="flex-container">

                    <div className="input-group input-group martb marlr">
                        <input
                            type="search"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="search"
                            value={searchText}
                            aria-label="type 2 or more letters"
                            placeholder={lang.search_button_text}
                            onKeyDown={onSearchEnter}
                            onChange={onFilterSearchChange}
                        />
                        <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "38px", marginTop: '-1px' }} onClick={() => setFilterPopupOpen(true)} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={{ backgroundColor: isFilterCleared ? "black" : "white" }}>
                                    <i style={{ margin: "5px 0px", color: isFilterCleared ? "white" : "black" }} className="fa fa-list" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
                {isLoading && <div className="ml-1">
                    <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
                </div>}
                {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
                {!isLoading && !isNoRecords && <Grid
                    style={{ position: "absolute", left: 0, top: "180px", right: 0, bottom: 0 }}
                    data={gridDataToShow}
                    rowRender={props.MediaLibraryCollectionGridRowRender}
                    // reorderable={true}
                    resizable={true}
                    pageable={true}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    onContextMenu={onContextMenu}
                    sort={gridSort}
                    sortable={true}
                    onSortChange={onSortChange}
                >
                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={140}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={140}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        } else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <Column
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={140}
                                        sortable={true}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}


            </div>
            <ContextMenu model={[

                {
                    label: `Publish Match`, icon: "fa-solid fa-calendar-days fa-lg", command: () => {
                        onScheduleMatch(0, selectedItemsRef.current)
                    }
                },
                { label: `${lang.fast_segmentation_actionbutton_menutext}`, icon: 'pi pi-fw pi-bolt', command: () => { setFastSegmentationPopupOpen(true) } },


            ]} ref={contextMenuRef} />
            {/* scheduleMatch */}
            {openPopupScheduleMatch && (
                <BossDialog
                    title={`Publish Match`}
                    onClose={() => {
                        setOpenPopupScheduleMatch(false);
                        setScheduleMatchDataItem({
                            Channels: [],
                            StartDate: "",
                            StartTime: "",
                        });
                    }}
                    width={"600px"}
                >
                    <div className="row">
                        <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <MultiSelect
                                data={channelsList}
                                textField="FullChannelName"
                                value={scheduleMatchDataItem.Channels}
                                onChange={onChangeScheduleMatch}
                                name={"Channels"}
                            />
                        </div>
                        <div className="col-3 form-group">
                            <label htmlFor="TabView">{lang.start_date_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="StartDate"
                                value={scheduleMatchDataItem.StartDate}
                                onChange={onChangeScheduleMatch}
                                disabled={scheduleMatchDataItem?.IsLive}
                                min={moment(new Date(scheduleMatchDataItem?.StartDateTime))
                                    .utc()
                                    .format("YYYY-MM-DD")}
                            />
                        </div>

                        <div className="col-3">
                            <label htmlFor="">{lang.start_time_label} *</label>
                            <TimePickerWithFormat
                                className="form-control form-control-sm"
                                name="StartTime"
                                value={scheduleMatchDataItem.StartTime}
                                onChange={onChangeScheduleMatch}
                                disabled={true}
                            />
                        </div>
                    </div>
                    <div className="row mt-1 mb-1" style={{ marginLeft: "-25px" }}>
                        <div className="col">
                            <ActionButton
                                title={lang.save_button_tooltip}
                                name={"Confirm"}
                                onClick={onPublish}
                            />
                            <ActionButton
                                title={lang.cancel_button_tooltip}
                                name={lang.cancel_button_text}
                                btnColor={"danger"}
                                onClick={() => {
                                    setOpenPopupScheduleMatch(false);
                                    setScheduleMatchDataItem({
                                        Channels: [],
                                        StartDate: "",
                                        StartTime: "",
                                    });
                                }}
                            />
                        </div>
                    </div>
                </BossDialog>
            )}

            {showPreferences && <UserPreference entityName={'planningmedialibrary'} data={preferenceState} setState={() => { }} handleClose={onCloseUserPrefence} />}

            {fastSegmentationPopupOpen && <MediaFastSegmentationPopup mediaEpisodeEntity={selectedItemsRef.current} programScheduleEntity={{}} refreshSegments={loadSavedFastSegments} onClose={() => setFastSegmentationPopupOpen(false)} source={'media'} />}

            {filterPopupOpen && <MediaFilterTemplate
                filterDataItem={filterDataItem}
                toGetFilterDataItem={toGetFilteredData}
                isFilterCleared={setIsFilterCleared}
                closeFilterPopup={() => setFilterPopupOpen(false)}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
            />}
        </>
    );
});

export default PlanningMediaLibraryCollection;
