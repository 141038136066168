
import { useTranslation } from "../../../../locale/useTranslation";

const BrowseRoundButton = (props) => {
    const lang=useTranslation();
    
    return <button  title={lang.select_url_tooltip} type="submit" onClick={props.onClick} style={{margin:"0px 02px"}} className="btn btn-default btn-circle btn-my">
       <i className="fa fa-ellipsis fa-fw img-circle"></i>
      
    </button>
}

export default BrowseRoundButton;
