/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";
import useSocket from "../../../framework/socket/useSocket";
import { LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../../../framework/constant/constant";
import Avatar from "../../../components/Avatar";

const PlaylistFooter = (props) => {

    const [activteUsers, setActivteUsers] = useState([]);
    let user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const lang = useTranslation();
    const {
        statusMessage,
        statusInfo,
        expandToggle,
        onHeaderExpandChange,
        selectedState,
        copyPasteCount,
        totalDevices,
        playlistData,
        setShowScreenGroupPopup
    } = props

    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified))

    window.addEventListener('isMinified', () => {
        setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    });

    //for activate user;
    useEffect(() => {
        socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.ADD_ACTIVE_USER, module: MODULE.PLAYLIST, data: { name: user.name, _id: user._id, color: user.color, selectedScheduleDate: statusInfo.selectedScheduleDate, selectedChannel: statusInfo.selectedChannel } });
        return () => {
            socket.emit(SOCKET_EVENTS.onSocketUsers, { action: SOCKET_ACTION.REMOVE_ACTIVE_USER, module: MODULE.PLAYLIST, data: { name: user.name, _id: user._id } });
        }
    }, [statusInfo]);

    //socket
    const socket = useSocket(SOCKET_EVENTS.onSocketUsers, (socketData) => {
        if (socketData.module != MODULE.PLAYLIST) return; //no need to filter active user from module 
        switch (socketData.action) {
            case SOCKET_ACTION.ADD_ACTIVE_USER:
            case SOCKET_ACTION.REMOVE_ACTIVE_USER:
                let scheduleActiveUsers = socketData.data.filter((item) => item.module == MODULE.PLAYLIST && item.user.selectedChannel.SID == statusInfo.selectedChannel.SID && item.user.selectedScheduleDate == statusInfo.selectedScheduleDate)
                if (scheduleActiveUsers.length > 0) setActivteUsers(scheduleActiveUsers);
                break;
            default:
                break;
        }
    });


    return <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>
        <div className="row mr-2">
            <div className="col-9" >
                <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}>
                    <div className="ml-1">
                        {activteUsers?.map((item) => {
                            return <Avatar data={item} />
                        })}
                    </div>
                    <div title={lang.selected_count_label} className="iconBase2" >
                        <span className="footerText">{selectedState.length}&nbsp;{lang.selected_label}</span>
                    </div>
                    <div title={lang.copy_count_label} className="iconBase2" >
                        <span className="footerText">{copyPasteCount.copy}&nbsp;{lang.copied_label}</span>
                    </div>
                    <div title={lang.cut_label} className="iconBase2" >
                        <span className="footerText">{copyPasteCount.cut}&nbsp;{lang.cut_label}</span>
                    </div>
                    <div title={"Attached Screen Groups"} className="iconBase2" onClick={() => { setShowScreenGroupPopup(true) }} >
                        <span className="footerText">{"Screen Groups : "}&nbsp;{
                            playlistData?.screenGroup?.length == 0 ?
                                'No Screen Groups'
                                : playlistData?.screenGroup.map(screen => {
                                    return screen.Name;
                                }).join(', ').substring(0, 30)
                        }</span>
                    </div>
                    <div title={'Total Devices'} className="iconBase2" >
                        {<span className="footerText"><i className="fa fa-display pr-1" style={{ color: "grey" }} />{totalDevices.online + totalDevices.offline}&nbsp;Total</span>}
                    </div>
                    <div title={'Online Devices'} className="iconBase2" >
                        {<span className="footerText"><i className="fa fa-display pr-1" style={{ color: "green" }} />{totalDevices.online}&nbsp;Online</span>}
                    </div>
                    <div title={'Offline Devices'} className="iconBase2" >
                        {<span className="footerText"><i className="fa fa-display pr-1" style={{ color: "grey" }} />{totalDevices.offline}&nbsp;Offline</span>}
                    </div>
                </div>
            </div>
            <div className="col-3 mt-2" style={{ textAlign: "right" }}>
                <div className="row">
                    <div className="col" style={{ color: "white" }}>
                        {statusMessage}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default PlaylistFooter;