import { Splitter } from "@progress/kendo-react-layout";
import MediaByCategoryCollection from "./mediaByCategoryCollection";
import { useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { TreeView } from "@progress/kendo-react-treeview";
import { toast } from "react-toastify";
import { utility } from "../../../framework/utility/utilityProvider";
import { ENTITYNAME } from "../../../framework/constant/constant";
import { useTranslation } from "../../../locale/useTranslation";
import ContentCategoryEditForm from "../../masters/ContentCategoryEditForm";
import { EditPageHeader } from "../../../components/EditPageHeader";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import BackRoundButton from "../../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { useNavigate, useNavigation } from "react-router-dom";

const MediaByCategoryMain = () => {

    const lang = useTranslation();
    const navigate = useNavigate();

    var selectedItem = null;

    const [horizontalPanes, setHorizontalPanes] = useState([
        {
            size: "25%",
            collapsible: true
        },
        {},

    ]);

    const [tree, setTree] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [gridData, setGridData] = useState([]);
    const [originalGridData, setoriginalGridData] = useState([])
    const [showEditForm, setShowEditForm] = useState(false);
    const [searchText, setSearchText] = useState('')

    useEffect(() => {
        loadTree();
    }, []);

    const loadTree = async () => {
        let res = await API.getContentCategories();
        if (res.success) {
            setTree(res.data);
            console.log(res.data);
        } else {
            toast.error(res.message);
        }
    }

    const onHorizontalSplitterChange = (event) => {
        setHorizontalPanes(event.newState);
    };

    const onExpandChange = (event) => {
        event.item.expanded = !event.item.expanded;
        setTree([...tree])
    };

    const setChildren = (parentId, children) => {

        const treeLocal = [...tree];
        treeLocal.map(item => {
            item.children = item.children.map(i => {
                if (i._id === parentId) {
                    i.children = children;
                }
                return i;
            })

            return item;
        });

        setTree(treeLocal);
    }

    const onItemClick = async (event) => {
        //for highlighting selected item
        // if (selectedItem) {
        //     selectedItem.selected = false;
        // }
        // event.item.selected = true;
        selectedItem = event.item;
        setSelectedCategory(selectedItem);

        let res = await API.getContentCategories(selectedItem._id);
        let resMedia = await API.getMediaFromContentCategory(selectedItem._id);
        console.log(resMedia);
        if (resMedia.success) {
            setGridData(resMedia.data);
            setoriginalGridData(resMedia.data)
        }
        if (res.success) {
            if (res.data.length > 0 && res.data[0].children.length > 0) {
                setChildren(selectedItem._id, res.data[0].children);
            }
        }

    };

    //search by title
    const setSearch = (text) => {
        setSearchText(text);

        if (text == '') {
            setGridData(originalGridData);
        } else {
            var data = originalGridData.filter((x) => x.Title.toLowerCase().includes(text.toLowerCase()));
            setGridData(data)
        }
    }

    const handleSubmit = async (dataItem, dataToSave) => {
        console.log("handleSUbmit");
        //if datatosave is null then dataitem is datasave
        dataToSave = dataToSave == null ? dataItem : dataToSave;

        var isDuplicate = await utility.checkDuplicate(ENTITYNAME.ContentCategory, dataToSave);

        if (!isDuplicate) {
            setShowEditForm(true);
            toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var res = await API.saveData(ENTITYNAME.ContentCategory, dataToSave);
        console.log(res);
        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        utility.deleteLocalStorageItem(ENTITYNAME.ContentCategory)
        loadTree();
        setShowEditForm(false);
    };

    return <Splitter
        panes={horizontalPanes}
        onChange={onHorizontalSplitterChange}
        style={{ height: '93%' }}>

        <div style={{ margin: '0px 0px 0px 15px' }}>
            <div>
                <h1 className="page-title txt-color-bludeDark" style={{ margin: '10px 5px' }}>
                    <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                    <span>{lang.media_by_category_menu}</span>
                </h1>
            </div>
            <div className="flex-container">
                <div className="martb mar">
                    <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    <AddRoundButton onClick={() => setShowEditForm(true)}></AddRoundButton>
                </div>
            </div>
            <TreeView
                size={"large"}
                data={tree}
                textField="Name"
                childrenField="children"
                expandIcons={true}
                onExpandChange={onExpandChange}
                onItemClick={onItemClick}
            />
        </div>
        <MediaByCategoryCollection selectedCategory={selectedCategory} data={gridData} setSearch={setSearch} />

        {showEditForm && <ContentCategoryEditForm
            cancelEdit={() => setShowEditForm(false)}
            onSubmit={handleSubmit}
            refresh={() => loadTree()}
            item={{ SID: 0 }}
        />}

    </Splitter>

}

export default MediaByCategoryMain;