import {
  startTransition,
  useCallback,
  useEffect,
  useState,
  useRef,
  cloneElement
} from "react";
import * as API from "../../framework/API/api";
import { Splitter } from "@progress/kendo-react-layout";
import Planning_prformance from "./Planning_performance";
import PlanningMediaLibraryCollection from "./PlanningMediaLibraryCollection";
import {
  ENTITYNAME,
  FILEURL,
  LOCALSTORAGE_KEY,
  PLANNINGTYPE,
  EXPORT_TYPE,
  OFFSET,
  MODULE,
  PLANNING_DELETE_OPTION,
  MEDIACATEGORIES,
  SOCKET_EVENTS,
  SOCKET_ACTION,
  MILLISECONDSINADAY,
  SCHEDULETYPE
} from "../../framework/constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { DatePicker, DateTimePicker } from "@progress/kendo-react-dateinputs";
import { utility } from "../../framework/utility/utilityProvider";
import moment from "moment";
import { PlanningHelper } from "./helper/PlanningHelper";
import PlaningFooter from "./PlanningFooter";
import { PlanningPreference } from "./planningPreference/PlanningPreference";
import { Recursive } from "./Recursive";
import MediaEpisodeHistory from "./MediaEpisodeHistory";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { toast } from "react-toastify";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { CopySchedule } from "./CopySchedule";
import { ComboBox, DropDownList, MultiColumnComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import PlanningDistribution from "./PlanningDistribution";
import { Calendar } from 'primereact/calendar';
import TabContext from "@mui/lab/TabContext/TabContext";
import { Box, Tab } from "@mui/material";
import TabList from "@mui/lab/TabList/TabList";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import PlanningVersionTab from "./PlanningVersionTab";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import ProgressBarPopup from '../../framework/forms/helpercomponents/ProgressBarPopup';
import InfoRoundButton from "../../components/InfoRoundButton";
import { EditPageHeader } from "../../components/EditPageHeader";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import socket from "../../framework/socket/socket";
import PlanningLiveEventsTab from "./PlanningLiveEventsTab";
import PlanningSeriesTab from "./PlanningSeriesTab";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import BossDialog from "../../components/BossDialog";

const PlannerWrapper = (props) => {

  const { isPrimeTime } = props;

  const lang = useTranslation();
  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  const disableLiveEventSection = company && company?.disableLiveEventSection != undefined && company?.disableLiveEventSection;
  var publishData = utility.getSeassionValue(LOCALSTORAGE_KEY.planningPublishData);
  const [showFilter, setShowFilter] = useState(publishData === null);
  const [ChannelList, setChannelList] = useState(
    publishData ? publishData.channels : []
  );
  const [searchChannel, setSearchChannel] = useState("");
  const [filterChannels, _setFilterChannels] = useState(
    publishData ? publishData.channels.map((x) => {
      return {
        ...x,
        name: x.FullChannelName,
      }
    }) : []
  );
  const filterChannelRef = useRef(filterChannels);
  const setFilterChannels = (data) => {
    filterChannelRef.current = data;
    _setFilterChannels(data);
  }
  const [isFirst, setIsFirst] = useState(publishData ? false : true);
  const [date, setDate] = useState(
    publishData ? new Date(publishData.planningdate) : new Date()
  );
  const [selectedDate, setSelectedDate] = useState(
    publishData ? new Date(publishData.planningdate) : new Date()
  );

  const [statusMsg, setStatusMsg] = useState("");

  const [showProgressBar, setShowProgressBar] = useState({ okButton: false, progressBarPopup: false });
  const [progressData, setProgressData] = useState({ message: '', progress: 0 });

  const [panes, setPanes] = useState([
    {
    },
    {
      size: "30%",
      collapsible: true,
    },
  ]);
  const planningRef = useRef();
  const liveEventRef = useRef();
  const plannerSelectedItemsRef = useRef([]);
  const [selectedPlanningItem, setSelectedPlanningItem] = useState([]);
  const [plannerCopiedItems, setPlannerCopiedItems] = useState([]);
  const [openPlanningPreference, setOpenPlanningPreference] = useState();
  const [showRecursivePopup, setShowRecursivePopup] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showInsertProgramGroup, setShowInsertProgramGroup] = useState(false);
  const [showExhibitions, setShowExhibitions] = useState(false);
  const [programScheduleExhibitionData, setProgramScheduleExhibitionData] = useState([]);
  const [exhibitionStatus, setExhibitionStatus] = useState('Loading');

  const [doLoad, setDoLoad] = useState(false);
  const [planningGroupItems, setPlanningGroupItems] = useState([]);
  const [showExportExcelPopup, setShowExportExcelPopup] = useState(false);
  const [showCopySchedulePopup, setShowCopySchedulePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [excelFromDate, setExcelFromDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
  const [excelToDate, setExcelToDate] = useState(new Date());
  const [exportChannel, setExportChannel] = useState({});
  const [durationType, setDurationType] = useState("Nominal");
  const [startType, setStartType] = useState("Nominal");
  const [showPlanningDistributionTemplate, setShowPlanningDistributionTemplate] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const [showCtrlAPopup, setShowCtrlAPopup] = useState(false);
  const [selectedCtrlAOption, setSelectedCtrlAOption] = useState(-1);
  const [selectedDeleteOption, setSelectedDeleteOption] = useState(0);
  const [showLoopPopup, setShowLoopPopup] = useState(false);
  const [showAutoPlanningPopup, setShowAutoPlanningPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAutoPlanningTentativePopup, setShowAutoPlanningTentativePopup] = useState(false);
  const [autoPlanningTentativeData, setAutoPlanningTentativeData] = useState({});
  const [autoPlaningData, setAutoPlaningData] = useState({
    Channel: {},
    FromDate: "",
    ToDate: "",
  });

  const [showCheckList, setShowCheckList] = useState(false);
  const [payload, setPayload] = useState({});
  const [loopPopupData, setLoopPopupData] = useState({
    channels: [],
    startDate: new Date(),
    startDateTime: "00:00:00:00",
    endDate: new Date(),
    endDateTime: "00:00:00:00",
    duration: 0,
    data: {}
  });


  const [epgColumns, setEpgColumns] = useState([]);
  const [epgColumn, setEpgColumn] = useState({});

  //for loading channel list
  useEffect(() => {
    loadChannelsList();
    loadCombo();
  }, []);

  const loadCombo = async () => {
    let epgExportRes = await API.getDataLookup(ENTITYNAME.ExportTemplate, { query: ["Archive", "!=", true] });

    setEpgColumns(epgExportRes.data);

  }


  const loadChannelsList = async () => {
    let response = await API.getValidChannels();

    if (response.success) {
      setChannelList(
        response.data.map((d) => ({
          ...d,
          name: d.FullChannelName,
          SID: d.SID,
          Color: d.Color,
        }))
      );

      //setting first channel in filter list by default
      console.log(publishData)
      if (publishData?.channels.length === 0) {
        setFilterChannels([{
          ...response.data[0],
          name: response.data[0].FullChannelName,
          SID: response.data[0].SID,
          Color: response.data[0].Color,
        }])
      }

    } else {
      toast.error(`${lang.error_fetching_channel_list_planning_wrapper_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  };
  const onChange = (event) => {
    setPanes(event.newState);
  };

  const planningLoading = () => {
    setDoLoad((old) => old ? false : true);
  }



  const handleChannelFilterDialog = () => {
    setShowFilter(!showFilter);
  };

  const handleSelectedChannels = (channel, event) => {
    startTransition(() => {
      if (event.target.checked) {
        var localeFilterChannel = [...filterChannelRef.current, {
          ...channel,
          name: channel.name,
          SID: channel.SID,
          Color: channel.Color,
        }]
        setFilterChannels(localeFilterChannel);
      } else {
        var locale = [...filterChannelRef.current.filter((x) => x.name != channel.name)]
        setFilterChannels(locale);
      }
    });
  };
  //checkbox Component for filter channels
  const MyCheckbox = (props) => {
    const { channel } = props;
    return (
      <Checkbox
        key={channel.SID}
        size={"large"}
        defaultChecked={
          filterChannels.find((c) => c.SID === channel.SID) === undefined
            ? false
            : true
        }
        onClick={(event) => handleSelectedChannels(channel, event)}
      >
        <span className="pl-2">{channel.name}</span>
      </Checkbox>
    );
  };

  const handleFilterPopupConfirm = () => {

    if (filterChannelRef.current.length == 0) {
      toast.info(`${lang.please_select_channel_first}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    // Earlier there was .add(1, 'day') in below lines which was causing issue of not loading planning date if saturday is selected.
    //setDate(new Date(moment(date2).add(1, 'day')));
    setSelectedDate(new Date(moment(date)));
    setIsFirst(false);

    // we will save here in local storage.
    //var data = {planningdate:new Date(moment(date2).add(1, 'day')), channels:filterChannels}
    var data = {
      planningdate: new Date(moment(date)),
      channels: filterChannelRef.current,
    };

    console.log(data);

    PlanningHelper.setPlanningPublishDataInLocalStorage(data);
    //here it changes date of scheduler after confirming the popup
    handleChannelFilterDialog();
    setExportChannel(filterChannelRef.current[0]);
    window.location.reload();
  };

  const handleSearchBox = (event) => {
    startTransition(() => setSearchChannel(event.value));
  };

  //changes date in scheduler
  const handleChangeDate = (event) => {
    setDate(event.value);
  };

  //changes date in scheduler
  const onDateChange = useCallback((dateFromPlanner) => {
    let finalDate = new Date(dateFromPlanner.getTime());
    setSelectedDate(finalDate);
    setDate(finalDate);
    var data = {
      planningdate: finalDate,
      channels: filterChannels,
    };
    PlanningHelper.setPlanningPublishDataInLocalStorage(data);

  },
    [selectedDate]
  );

  const setStatus = useCallback((msg) => {
    setStatusMsg(msg);
  }, [])


  const onSelectedPlanningItem = useCallback((data) => {
    setSelectedPlanningItem(data);
    plannerSelectedItemsRef.current = data;
  }, [])

  const onSetPlannerCopiedItems = useCallback((data) => {

    setPlannerCopiedItems(data);

  }, [])

  const onClickPlanningPreference = useCallback(() => {
    setOpenPlanningPreference(false);
  }, [])

  const updatePlanningPreferenceStyle = useCallback(() => {



    setOpenPlanningPreference(false);
    planningLoading();

  }, []);

  const onOpenPlanningPreference = () => {
    setOpenPlanningPreference(true);
  }

  const onSelectApplyRecursive = useCallback(() => {

    console.log(plannerSelectedItemsRef.current);

    if (plannerSelectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_planning_item_planning_wrapper_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    // console.log(plannerSelectedItemsRef.current[0].RecursionParentProgramSchedule_id);
    if (plannerSelectedItemsRef.current[0].RecursionParentProgramSchedule_id ?? "" != "") {
      toast.error(`${lang.selected_item_already_part_of_another_strand_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    setShowRecursivePopup(true);
  }, [])


  const onSelectDistributePlanningAcrossChannels = useCallback(async () => {

    console.log("apply distribute");

    if (plannerSelectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_planning_item_planning_wrapper_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    setShowPlanningDistributionTemplate(true);

  }, [])

  const setDistributionTemplateData = async (distributionTemplate) => {

    setStatusMsg(`${lang.applying_planning_distribution_template_please_wait_planner_status_message}`)

    for (let index = 0; index < plannerSelectedItemsRef.current.length; index++) {

      const plannedItem = plannerSelectedItemsRef.current[index];

      await API.distributePlanningAcrossChannels(plannedItem, distributionTemplate);

    }

    planningLoading(); // refreshing for now then we will add episodes from response
    setStatusMsg(`${lang.planning_distribution_template_applied_success_planner_status_message}`)

  }


  const onGenerateRecursion = useCallback(async (dataItem) => {

    var startDate = utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.FromDate));
    var endDate = utility.getLocalDateTimeToUtcMiliseconds(new Date(dataItem.ToDate))

    const lockedData = await API.getData(ENTITYNAME.PLAYLIST_LOCK_STATUS, { query: [['date', '>=', startDate], ['date', '<=', endDate], ['channel._id', '=', plannerSelectedItemsRef.current[0]?.Channel?._id]] });

    if (lockedData.data.length > 0) {
      setStatusMsg(`${lang.planning_is_locked_on_some_days_in_this_date_range}`);
      toast.error(`${lang.planning_is_locked_on_some_days_in_this_date_range}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return
    }

    setShowRecursivePopup(false);

    setStatusMsg(`${lang.applying_recursive_pattern_please_wait_planner_error_message}`)
    var response = await API.applyRecursion(plannerSelectedItemsRef.current[0], dataItem);


    if (response.success) {
      setStatusMsg(`${lang.recursive_applied_successfully_planning_wrapper_message}`)

      if (response.slotChanged) {
        toast.info("Selected Item Slot Changed According to the Repeat Pattern Rules", {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLANNING_REFRESH, module: MODULE.PLANNING, data: { scheduleScheduleStartDate: startDate, scheduleScheduleEndDate: endDate, channelSID: plannerSelectedItemsRef.current[0]?.Channel?.SID } });

      planningLoading(); // refreshing for now then we will add episodes from response

    }
    else {
      setStatusMsg(response.message);
      toast.error(response.message, {
        position: toast.POSITION.TOP_RIGHT
      });

    }
  }, [])

  const closeFormHistory = () => {
    setShowHistory(false)
  }

  const onSelectShowHistory = useCallback(() => {

    if (plannerSelectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_planning_item_planning_wrapper_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    setShowHistory(true);

  }, [])

  const onSelectShowExhibition = useCallback(async () => {

    if (plannerSelectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_planning_item_planning_wrapper_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    setShowExhibitions(true);

    console.log(plannerSelectedItemsRef.current[0])

    //need to ask that we have to fetch data
    var res = await API.getData(ENTITYNAME.ProgramSchedule, {
      query: [
        ['MediaDealRight_id', '=', plannerSelectedItemsRef.current[0].MediaDealRight_id],
        ['MediaEpisode_id', '=', plannerSelectedItemsRef.current[0].MediaEpisode_id],
        ['PlanningType', '=', PLANNINGTYPE.Primary]
      ]
    });

    // console.log(res.data)
    if (res.data.length == 0) {
      setExhibitionStatus('No Record Found')
    }
    setProgramScheduleExhibitionData(res.data);

  }, [])

  const closeInsertProgramGroup = useCallback(() => {

    setShowInsertProgramGroup(false);

  }, [])


  const onInsertProgramGroup = useCallback(() => {

    setShowInsertProgramGroup(true);

  }, [])

  const addProgramGroup = useCallback((programGroups) => {

    if (programGroups.length > 0) {
      setPlanningGroupItems(programGroups[0]);
    }

  }, [])

  const showExcelPopup = useCallback(() => {

    setShowExportExcelPopup(true);

  }, [])

  const closeExcelPopup = useCallback(() => {

    setShowExportExcelPopup(false);

  }, [])

  const closeLoopPopup = useCallback(() => {
    setShowLoopPopup(false);
  }, [])

  const onLoopConfirm = async () => {
    setShowLoopPopup(false);
    let startDate = new Date(new Date(loopPopupData.startDate).setUTCHours(0, 0, 0, 0, 0)).getTime() + utility.convertStringWithFramesToMilliseconds(loopPopupData.startDateTime);
    let endDate = new Date(new Date(loopPopupData.endDate).setUTCHours(0, 0, 0, 0, 0)).getTime() + utility.convertStringWithFramesToMilliseconds(loopPopupData.endDateTime);
    console.log(startDate, endDate)

    let res = await API.loopPlanning(startDate, endDate, loopPopupData.channels, loopPopupData.data);
    if (res.success) {
      toast.success("Loop Planned", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setDoLoad(!doLoad);
  }

  const getAutoPlanningTentativeData = async () => {

    setIsLoading(true);

    let payload = {
      Channel: autoPlaningData?.Channel,
      FromDate: utility.convertStringDatetoMilliseconds(autoPlaningData?.FromDate),
      ToDate: utility.convertStringDatetoMilliseconds(autoPlaningData?.ToDate)
    }

    let res = await API.getAutoPlanningPlottingDataByPlanningSlots(payload);

    console.log(res)

    if (res.success) {
      setShowAutoPlanningPopup(false);
      setShowAutoPlanningTentativePopup(true);
      setAutoPlanningTentativeData(res.data);
      setIsLoading(false);
      toast.success("Auto Planning Plotted", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    // setDoLoad(!doLoad);
  }

  const onAutoPlanningPlotting = async () => {

    //SEND DATA IN CHUNKS
    const CHUCKEDSIZE = 10;

    let onSaveResposnse = { success: false };

    setShowProgressBar({ okButton: true, progressBarPopup: true });


    let dataRowIndex = 0;
    while (dataRowIndex < autoPlanningTentativeData.length) {
      setProgressData({ message: 'Data uploading...', progress: Math.round(((dataRowIndex + 1) / autoPlanningTentativeData.length) * 100) });

      let chunckedData = [...autoPlanningTentativeData.slice(dataRowIndex, dataRowIndex + CHUCKEDSIZE)];
      console.log(chunckedData)
      onSaveResposnse = await API.savePlanningSlotSchedule(chunckedData);

      console.log(onSaveResposnse)

      if (!onSaveResposnse.success) break;

      dataRowIndex = dataRowIndex + CHUCKEDSIZE;
    }

    if (onSaveResposnse.success) {
      setProgressData({ message: 'Process completed', progress: 100 });
      toast.success('Process completed', {
        position: toast.POSITION.TOP_RIGHT
      });
      setShowAutoPlanningTentativePopup(false);
      toast.success("Auto Planning Plotted", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Something went wrong, Please try again !! ", {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setDoLoad(!doLoad);
  }

  const onShowLoopPopup = (data) => {
    console.log(data)
    setLoopPopupData({
      channels: [data.Channel],
      startDate: new Date(data.SlotDateTime),
      startDateTime: utility.convertMilisecondsToStringWithFrames(data.SlotDateTime),
      endDate: new Date(data.SlotDateTime + data.mediaEpisode.Duration),
      endDateTime: utility.convertMilisecondsToStringWithFrames(data.SlotDateTime + data.mediaEpisode.Duration),
      duration: data.mediaEpisode.Duration,
      data: PlanningHelper.prepareDataforbackend(data)
    });

    setShowLoopPopup(true);

  }

  const onShowAutoPlaningPopup = () => {

    setAutoPlaningData({
      Channel: publishData.channels[0],
      FromDate: moment(publishData.planningdate).utc().format('YYYY-MM-DD'),
      ToDate: moment(publishData.planningdate).utc().add(7, 'd').format('YYYY-MM-DD'),
    });

    setShowAutoPlanningPopup(true);

  }

  const onShowCopySchedulePopup = useCallback(() => {

    setShowCopySchedulePopup(true);

  }, [])

  const onCloseCopySchedulePopup = useCallback(() => {

    setShowCopySchedulePopup(false);

  }, [])


  const exportExcel = async (exportPDF = false) => {
    if (excelFromDate > excelToDate) {
      toast.error(`${lang.from_date_cant_be_greater_than_to_date_paste_planner_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    console.log("handleExcelExport");
    console.log(utility.getValue(LOCALSTORAGE_KEY.planningpreference).data[0].value.SelectedColumns);
    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000;
    let planningpreference = utility.getValue(LOCALSTORAGE_KEY.planningpreference)?.data[0]?.value;
    planningpreference = {
      EventStyle: planningpreference?.EventStyle,
      GenreStyle: planningpreference?.GenreStyle,
      IsGenreColorPreference: planningpreference?.IsGenreColorPreference,
    }

    var payload = {
      param: {
        query: {
          fromDate: new Date(excelFromDate.setUTCHours(0, 0, 0, 0)).getTime(),
          toDate: new Date(excelToDate.setUTCHours(23, 59, 59, 99)).getTime(),
          channelSID: [exportChannel?.SID],
        }
      },
      columns: epgColumn?.Columns && epgColumn?.Columns?.length > 0 ? epgColumn?.Columns?.map(x => x?.AvailableColumn) : [],
      slotDuration: planningRef.current.getSelectedSlotDuration() ?? 15,
      planningPreference: { ...planningpreference, weekStartDay: utility.getValue(LOCALSTORAGE_KEY.planningpreference)?.data[0]?.value?.weekStartDay, },
      selectedColumns: utility.getValue(LOCALSTORAGE_KEY.planningpreference)?.data[0]?.value?.SelectedColumns,
      offsetTime: offsetTime,
      isNominalDuration: durationType != "ActualDuration" ? true : false,
      isNominalStart: startType == "Nominal"
    }
    console.log(payload);

    var res = exportPDF ? await API.exportPDF(payload) : await API.exportPlanning(payload);
    console.log(res);
    if (res.success) {
      var filePath = FILEURL.BASEURL + 'downloadReport/' + res.data;
      console.log(filePath);
      window.open(filePath);
      setShowExportExcelPopup(false);
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const exportEPG = async () => {
    if (excelFromDate > excelToDate) {
      toast.error(`${lang.from_date_cant_be_greater_than_to_date_paste_planner_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      return;
    }

    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000;

    var payload = {
      param: {
        query: {
          fromDate: new Date(excelFromDate.setUTCHours(0, 0, 0, 0)).getTime(),
          toDate: new Date(excelToDate.setUTCHours(23, 59, 59, 99)).getTime(), // Adding 1 Day To Cater End Date
          channelSID: exportChannel.SID,
          offsetTime: offsetTime,
        }
      }

    }
    console.log(payload);

    var res = await API.exportEPG(payload)
    console.log(res);
    if (res.success) {
      var filePath = FILEURL.BASEURL + 'downloadReport/' + res.data;
      console.log(filePath);
      window.open(filePath);
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  
  const isValid = () => {
    if(Object.keys(exportChannel).length == 0) {
      toast.error(lang.please_select_channel_error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
      return false;
    }
    return true;
  }

  const handleExport = async (exportType) => {
    if(!isValid()) return
    switch (exportType) {
      case EXPORT_TYPE.EXCEL:
        await exportExcel();
        break;
      case EXPORT_TYPE.EPG:
        await exportEPG();
        break;
      case EXPORT_TYPE.PDF:
        await exportExcel(true);
        break;

      default:
        exportExcel();
        break;
    }

  }

  const onDurationTypeChange = useCallback((value) => {

    setDurationType(value)
  }, [])

  const onStartTypeChange = useCallback((value) => {

    utility.setValue(LOCALSTORAGE_KEY.planningpreference, {
      ...utility.getValue(LOCALSTORAGE_KEY.planningpreference),
      data: [{
        ...utility.getValue(LOCALSTORAGE_KEY.planningpreference).data[0],
        value: {
          ...utility.getValue(LOCALSTORAGE_KEY.planningpreference).data[0].value,
          StartType: value ?? "Nominal"
        }
      }]
    })

    setStartType(value)
  }, [])


  const MediaLibraryCollectionGridRowRender = (tr, props) => {
    const trProps = {
      draggable: true,
      onDragStart: (e) => {
        // to revert change width height back to normal as commented  zindex uncomment, id =drag-ghost and remove drag-ghost-del from ondrag end too
        // also go to planning performance.js and seee instructions there ln 816
        var elem = document.createElement("div");
        let duration = props.dataItem.Duration?.toString();
        elem.id = "drag-ghost-del";
        elem.textNode = "Dragging";
        elem.style.position = "fixed";
        elem.style.width = "0px";//150px
        elem.style.height = "0px";//200px
        elem.style.backgroundColor = "#3F497F";
        elem.style.whiteSpace = "pre-line";
        elem.textContent =
          props.dataItem.Title + "\r\n" + props.dataItem.AssetId;
        elem.style.opacity = 0;
        // elem.style.zIndex = 10000;
        elem.style.transition = "0";//0.23
        elem.title = duration;
        document.body.appendChild(elem);
        e.dataTransfer.setDragImage(elem, 0, 0);
        var dataTransfer = {
          dataItem: props.dataItem,
          filterChannels: filterChannels,
        };
        e.dataTransfer.clearData();
        e.dataTransfer.setData("text/plain", JSON.stringify(dataTransfer));
      },
      onDragOver: (e) => { },
      onDragEnd: (e) => {
        var elem = document.getElementById("drag-ghost");
        var elem2 = document.getElementById("drag-ghost-del");
        if (elem) {
          document.body.removeChild(elem);
        }
        document.body.removeChild(elem2);
      },
    };
    return cloneElement(tr, { ...trProps }, tr.props.children);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onShowMedia = (mediaEpisode) => {
    let link = "/home/MediaEpisode/" + mediaEpisode.MediaCategory.Description + "/" + mediaEpisode.SID;
    window.open(link, "_blank");
  }

  const showCtrlADialog = () => {
    setShowCtrlAPopup(true);
  }

  const showDeleteMenu = () => {
    if (showLoopPopup) {
      return;
    }
    setSelectedDeleteOption(0);
    setShowDeletePopup(!showDeletePopup);
  }

  const onClickCtrlACancelAction = () => {
    setShowCtrlAPopup(false);
  }

  const onClickCtrlAOptions = (option) => {
    if (selectedCtrlAOption == option) {
      setSelectedCtrlAOption(-1);
      return;
    }
    setSelectedCtrlAOption(option);
  }

  const onClickDeleteOptions = (option) => {
    if (selectedDeleteOption == option) {
      return;
    }
    setSelectedDeleteOption(option);
  }

  const onClickCtrlAOkayAction = () => {
    if (selectedCtrlAOption == -1) {
      onClickCtrlACancelAction();
    } else {
      switch (selectedCtrlAOption) {
        case 0:
          planningRef.current.selectAllPlanningData();
          break;
        case 1:
          planningRef.current.selectAllPlanningDataForSelectedDate();
          break;
        case 2:
          planningRef.current.selectItemsOfSimilarEpisodes();
          break;
        case 3:
          planningRef.current.selectAllEpisodesOfSelectedSeries();
          break;
        default:
          break;
      }
      setShowCtrlAPopup(false);
    }
  }

  const onClickDeleteOkayAction = async () => {
    setShowDeletePopup(false);
    await planningRef.current.deletePlanningItemFromMenu(selectedDeleteOption);
    if (selectedDeleteOption != PLANNING_DELETE_OPTION.DELETE_SELECTED) setDoLoad(!doLoad);
  }

  const onCheckList = () => {
    setShowCheckList(!showCheckList);
  }

  const refreshLiveEvent = () => {
    if (liveEventRef.current) liveEventRef.current.onRefresh();
  }

  return (
    <>
      <Splitter panes={panes} onChange={onChange} style={{ height: "89%" }}>
        <Planning_prformance
          ref={planningRef}
          filterChannels={filterChannels}
          date={selectedDate}
          onDateChange={onDateChange}
          setStatus={setStatus}
          onSelectedPlanningItem={onSelectedPlanningItem}
          onSetPlannerCopiedItems={onSetPlannerCopiedItems}
          onSelectApplyRecursive={onSelectApplyRecursive}
          onSelectShowHistory={onSelectShowHistory}
          onInsertProgramGroup={onInsertProgramGroup}
          doLoad={doLoad}
          durationType={durationType}
          planningGroupItems={planningGroupItems}
          onSelectDistributePlanningAcrossChannels={onSelectDistributePlanningAcrossChannels}
          isPrimeTime={isPrimeTime}
          onShowMedia={onShowMedia}
          onShowExhibitions={onSelectShowExhibition}
          showCtrlADialog={showCtrlADialog}
          showDeleteMenu={showDeleteMenu}
          onShowLoopPopup={onShowLoopPopup}
          showCheckList={showCheckList}
          onCheckList={onCheckList}
          refreshLiveEvent={refreshLiveEvent}
          setPayload={setPayload}
          startType={startType}
          setStartType={setStartType}
        />
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label={lang.media_library_dialog_header} value="1" />
              <Tab label={lang.series_library_label} value="2" />
              <Tab label={lang.version_label} value="3" />
              {!disableLiveEventSection && <Tab label={lang.live_events_label} value="4" />}
            </TabList>
          </Box>
          <TabPanel value="1" style={{ height: "100%", padding: 0 }} >
            <PlanningMediaLibraryCollection
              title={lang.media_library_dialog_header}
              entityname={ENTITYNAME.MediaEpisode}
              channels={filterChannelRef.current}
              MediaLibraryCollectionGridRowRender={MediaLibraryCollectionGridRowRender}
              planningLoading={planningLoading}
              payload={payload}
            />
          </TabPanel>

          <TabPanel value="2" style={{ height: "100%", padding: 0 }} >
            <PlanningSeriesTab
              doLoad={doLoad}
              channels={filterChannels}
            />
          </TabPanel>
          <TabPanel value="3" style={{ height: "100%", padding: 0 }} >
            <PlanningVersionTab plannerSelectedItem={plannerSelectedItemsRef.current.length > 0 ? plannerSelectedItemsRef.current[0] : {}} onShowMedia={onShowMedia} setDoLoad={setDoLoad} fromPlanning={true} source={'planning'} />
          </TabPanel>
          {!disableLiveEventSection && <TabPanel value="4" style={{ height: "100%", padding: 0 }} >
            <PlanningLiveEventsTab ref={liveEventRef} channels={filterChannelRef.current} MediaLibraryCollectionGridRowRender={MediaLibraryCollectionGridRowRender} planningLoading={planningLoading} showLoading={false} payload={payload} />
          </TabPanel>}
        </TabContext>
      </Splitter>

      <PlaningFooter
        handleChannelFilterDialog={handleChannelFilterDialog}
        statusMsg={statusMsg}
        selectedPlanningItemCount={plannerSelectedItemsRef.current.length}
        plannerCopiedItemsCount={plannerCopiedItems.length}
        onOpenPlanningPreference={onOpenPlanningPreference}
        planningLoading={planningLoading}
        showExcelPopup={showExcelPopup}
        onShowCopySchedulePopup={onShowCopySchedulePopup}
        onDurationTypeChange={onDurationTypeChange}
        durationType={durationType}
        startType={startType}
        onStartTypeChange={onStartTypeChange}
        date={selectedDate}
        onCheckList={onCheckList}
        onAutoPlanning={onShowAutoPlaningPopup}
      ></PlaningFooter>
      {/* all dialogs here */}

      {showFilter && (
        <BossDialog
          title={lang.select_channels_dialog_header}
          onClose={handleChannelFilterDialog}
          width={700}
          closeIcon={isFirst}
        >
          <div className="row" >
            <div className="col-6" style={{ height: '100%' }}>
              <label>{lang.channel_label}:</label>
              <fieldset
                className="border rounded-4 pt-3 pr-3 pl-3"
                style={{ height: "350px", overflow: 'hidden', maxHeight: "345px", overflowY: "auto" }}
              >
                <Input
                  placeholder={lang.search_button_text}
                  value={searchChannel.cuent}
                  onChange={handleSearchBox}
                  autoFocus={true}
                  type={"text"}
                  className="mb-3"
                />
                {searchChannel.length !== 0
                  ? ChannelList.map((channel) => {
                    if (
                      channel.name
                        .toLowerCase()
                        .includes(searchChannel.toLowerCase())
                    )
                      return (
                        <div className="mb-2" key={channel.SID}>
                          <MyCheckbox channel={channel} />
                          <br />
                        </div>
                      );
                  })
                  : ChannelList.map((channel) => (
                    <div className="mb-2" key={channel.SID}>
                      <MyCheckbox channel={channel} />
                      <br />
                    </div>
                  ))}
              </fieldset>
            </div>
            <div className="col-6" style={{ height: '100%' }}>
              <label className="mb-2">{lang.date_label}:</label>
              {/* <Calendar value={date} onChange={handleChangeDate} /> */}
              <div >
                <Calendar
                  inline
                  value={date}
                  onChange={handleChangeDate}
                />
              </div>
            </div>
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleChannelFilterDialog}
            >
              {lang.cancel_button_text}
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleFilterPopupConfirm}
            >
              {lang.confirm_button_text}
            </button>
          </DialogActionsBar>
        </BossDialog>
      )}

      {openPlanningPreference && (<PlanningPreference closeForm={onClickPlanningPreference} entityName={ENTITYNAME.UserPreference} updatePlanningPreferenceStyle={updatePlanningPreferenceStyle} />)}

      {showRecursivePopup && (<Recursive
        selectedAppointment={plannerSelectedItemsRef.current[0]}
        closeForm={() => setShowRecursivePopup(false)}
        onGenerateRecursion={onGenerateRecursion} />)}

      {showPlanningDistributionTemplate && <PlanningDistribution selectedData={plannerSelectedItemsRef.current[0]} closeForm={() => setShowPlanningDistributionTemplate(false)} setDistributionTemplateData={setDistributionTemplateData} />}

      {showHistory && (<MediaEpisodeHistory selectedData={plannerSelectedItemsRef.current[0].mediaEpisode} closeForm={closeFormHistory} />)}

      {showExhibitions && <BossDialog
        title={'Exhibitions'}
        onClose={() => setShowExhibitions(false)}
        width={"50%"}
        height={"62%"}
      >
        <Grid data={programScheduleExhibitionData} style={{ height: "50vh", marginTop: "04px", overflow: "hidden" }}>
          <GridNoRecords>
            {exhibitionStatus}
          </GridNoRecords>
          <GridColumn field="Channel.FullChannelName" title={lang.channel_column} width={188} />
          <GridColumn field="SlotDateTime" title={lang.slot_date_column} cell={DateOnlyCell} width={180} />
          <GridColumn field="SlotDateTime" title={lang.slot_time_column} cell={TimeCell} width={180} />
          <GridColumn field="ExhibitionNumber" title={lang.exhibition_no_column} width={180} />
          <GridColumn field="RepeatNumber" title={lang.repeat_no_column} width={180} />
        </Grid>
      </BossDialog>}

      {showInsertProgramGroup && (<CollectionSelection
        title={lang.select_program_group_label}
        entityname={ENTITYNAME.MediaEpisodeGroup}
        wherestatement={['MediaCategory.isLong', '=', true]}
        setDataList={addProgramGroup}
        mode={"single"}
        closeForm={closeInsertProgramGroup}
      />)}

      {showCopySchedulePopup &&
        <CopySchedule setDoLoad={setDoLoad} closeForm={onCloseCopySchedulePopup} plannerPayload={{ date: date, channels: filterChannels }} />}

      {showExportExcelPopup && <BossDialog
        title={'Export'}
        width={"900px"}
        onClose={closeExcelPopup}

      >
        <RoundButton style={{ marginBottom: "10px", marginLeft: "0" }} icon={"file-excel"} title={lang.export_excel_button_tooltip} name={lang.export_planner_button_text} onClick={() => { handleExport(EXPORT_TYPE.EXCEL) }} />
        <RoundButton style={{ marginBottom: "10px", marginLeft: "5px" }} title={lang.export_epg_button_text} icon={"download"} name={lang.export_epg_button_text} onClick={() => { handleExport(EXPORT_TYPE.EPG) }} />
        {<RoundButton style={{ marginBottom: "10px", marginLeft: "5px" }} title={'Export PDF'} icon={"file-pdf"} name={'Export PDF'} onClick={() => { handleExport(EXPORT_TYPE.PDF) }} />}
        <div className='row'>
          <div className="col-4">
            <label>{lang.channel_label} * :</label>
            <DropDownList
              data={ChannelList}
              textField="name"
              dataItemKey="_id"
              value={exportChannel}
              onChange={(e) => { startTransition(() => setExportChannel(e.target.value)) }}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-4">
            <label>Start:</label>
            <DropDownList
              value={startType}
              style={{
                // width: "160px",
              }}
              data={["Nominal", "Actual"]}
              onChange={(e) => {
                onStartTypeChange(e.value);
              }}
            />
          </div>
          <div className="col-4">
            <label>Duration:</label>
            <DropDownList
              value={durationType}
              style={{
                // width: "160px",
              }}
              data={["Nominal", "ActualDuration"]}
              onChange={(e) => {
                onDurationTypeChange(e.value);
              }}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
          <label htmlFor="">{lang.from_label}</label>
            <input className="form-control form-control-sm" type={'date'} name={"From"} placeholder={""} label={lang.from_label} onChange={(e) => { startTransition(() => setExcelFromDate(new Date(e.target.value))) }} value={moment(excelFromDate).utc().format('YYYY-MM-DD')} style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px', height: '30px' }} />
             </div>
          <div className="col-4">
          <label htmlFor="">{lang.to_label}</label>
            <input className="form-control form-control-sm" type={'date'} name={"To"} placeholder={""} label={lang.to_label} onChange={(e) => { startTransition(() => setExcelToDate(new Date(e.target.value))) }} value={moment(excelToDate).utc().format('YYYY-MM-DD')} style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px', height: '30px' }} />
            </div>
          <div className="col-4">
            <label htmlFor="">{lang.epg_template_label}:</label>
            <DropDownList
              data={epgColumns}
              name="EpgExport"
              textField="Name"
              dataItemKey="_id"
              value={epgColumn}
              onChange={(e) => setEpgColumn(e.target.value)}
            />
          </div>
        </div>

      </BossDialog>}
      {showCtrlAPopup && <BossDialog
        title={'Options'}
        width={"350px"}
        onClose={onClickCtrlACancelAction}
      >
        <div>
          <div className="mb-3">
            <Checkbox style={{ border: "1px solid grey" }} name="" value={selectedCtrlAOption == 0} onChange={() => onClickCtrlAOptions(0)} />
            <span style={{ fontSize: "16px", paddingLeft: "5px" }}>Select all items in planning grid</span>
          </div>
          <div className="mb-3" >
            <Checkbox style={{ border: "1px solid grey" }} name="" value={selectedCtrlAOption == 1} onChange={() => onClickCtrlAOptions(1)} />
            <span style={{ fontSize: "16px", paddingLeft: "5px" }}>Select all items for selected date</span>
          </div>
          <div className="mb-3" >
            <Checkbox style={{ border: "1px solid grey" }} name="" value={selectedCtrlAOption == 2} onChange={() => onClickCtrlAOptions(2)} />
            <span style={{ fontSize: "16px", paddingLeft: "5px" }}>Select all similar episodes</span>
          </div>
          <div>
            <Checkbox style={{ border: "1px solid grey" }} name="" value={selectedCtrlAOption == 3} onChange={() => onClickCtrlAOptions(3)} />
            <span style={{ fontSize: "16px", paddingLeft: "5px" }}>Select all episodes of selected series</span>
          </div>
        </div>
        <div className="mt-3" >
          <ActionButton style={{ marginLeft: "0", marginRight: "10px" }} title={'Ok'} name={'Ok'} onClick={onClickCtrlAOkayAction} />
          <ActionButton btnColor={'danger'} title={lang.cancel_button_text} name={lang.cancel_button_text} onClick={onClickCtrlACancelAction} />
        </div>

      </BossDialog>}

      {showDeletePopup && <BossDialog
        title={'Delete Linked Event'}
        width={"450px"}
        onClose={showDeleteMenu}
      >
        <div>
          <div className="mb-3">
            <Checkbox style={{ border: "1px solid grey" }} name="" value={selectedDeleteOption == PLANNING_DELETE_OPTION.DELETE_SELECTED} onChange={() => onClickDeleteOptions(PLANNING_DELETE_OPTION.DELETE_SELECTED)} />
            <label style={{ paddingLeft: "5px", color: '#3A3633' }}>{lang.delete_selected_label}<InfoRoundButton message={"Selected item will be deleted and the slot will remain empty."} /></label>
          </div>
          <div className="mb-3" >
            <Checkbox disabled={plannerSelectedItemsRef.current.length > 1} style={{ border: "1px solid grey" }} name="" value={selectedDeleteOption == PLANNING_DELETE_OPTION.PUSH_FORWARD} onChange={() => onClickDeleteOptions(PLANNING_DELETE_OPTION.PUSH_FORWARD)} />
            <label style={{ paddingLeft: "5px", color: '#3A3633' }}>{lang.delete_slot_and_push_forward_in_the_strand_label}<InfoRoundButton message={"This slot will be completely removed and episodes will move to a forward slot."} /></label>
          </div>
          <div className="mb-3" >
            <Checkbox disabled={plannerSelectedItemsRef.current.length > 1} style={{ border: "1px solid grey" }} name="" value={selectedDeleteOption == PLANNING_DELETE_OPTION.PULL_BACKWARD_AND_DELETE_CURRENT} onChange={() => onClickDeleteOptions(PLANNING_DELETE_OPTION.PULL_BACKWARD_AND_DELETE_CURRENT)} />
            <label style={{ paddingLeft: "5px", color: '#3A3633' }}>{lang.delete_slot_and_pull_backward_in_the_strand_label}<InfoRoundButton message={"This slot will be retained but episodes will be moved to an earlier slot and the selected episode will be removed."} /></label>
          </div>
          <div className="mb-3">
            <Checkbox disabled={plannerSelectedItemsRef.current.length > 1} style={{ border: "1px solid grey" }} name="" value={selectedDeleteOption == PLANNING_DELETE_OPTION.DELETE_ALL_FORWARD_IN_STRAND} onChange={() => onClickDeleteOptions(PLANNING_DELETE_OPTION.DELETE_ALL_FORWARD_IN_STRAND)} />
            <label style={{ paddingLeft: "5px", color: '#3A3633' }}>{lang.delete_all_the_events_forward_in_the_strand_label}<InfoRoundButton message={"This will remove all the slots from selected event forward in the strand."} /></label>
          </div>
          <div>
            <Checkbox disabled={plannerSelectedItemsRef.current.length > 1} style={{ border: "1px solid grey" }} name="" value={selectedDeleteOption == PLANNING_DELETE_OPTION.DELETE_FUTURE_EVENT} onChange={() => onClickDeleteOptions(PLANNING_DELETE_OPTION.DELETE_FUTURE_EVENT)} />
            <label style={{ paddingLeft: "5px", color: '#3A3633' }}>{lang.delete_episode_in_future_from_selected_slot_forward_label}<InfoRoundButton message={"This episode will be completely removed from the planning from selected event forward."} /></label>
          </div>
          {plannerSelectedItemsRef.current.length > 1 && <div>
            <i className="fa fa-circle-info text-primary mx-1 mt-2" />
            <small className="text-primary">{lang.to_enable_other_options_select_only_one_item_label}</small>
          </div>
          }
        </div>
        <div className="mt-3" >
          <ActionButton style={{ borderRadius: '5px', marginLeft: "0", marginRight: "10px" }} title={'Ok'} name={'Ok'} onClick={onClickDeleteOkayAction} />
          <ActionButton btnColor={'danger'} title={lang.cancel_button_text} name={lang.cancel_button_text} onClick={showDeleteMenu} />
        </div>

      </BossDialog>}

      {showLoopPopup && <BossDialog
        style={{
          position: "absolute",
          top: "-100px"
        }}
        title={'Loop'}
        width={"600px"}
        onClose={closeLoopPopup}

      >
        <EditPageHeader onSubmit={onLoopConfirm} onCancel={closeLoopPopup} showTitle={false} />
        <br />
        <div className="row">
          <div className="col-6">
            <label>From Slot Date</label>
            <DatePicker
              format={
                {
                  skeleton: "yMMMd",
                }
              }
              disabled={true}
              value={loopPopupData.startDate}
            />
          </div>
          <div className="col-6">
            <label>From Slot Time</label>
            <TimePickerWithFormat disabled={true} value={loopPopupData.startDateTime} />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <label>End Slot Date</label>
            <DatePicker
              value={loopPopupData.endDate}
              format={
                {
                  skeleton: "yMMMd",
                }
              }
              onChange={(e) => {
                setLoopPopupData({ ...loopPopupData, endDate: e.target.value })
              }}
            />
          </div>
          <div className="col-6">
            <label>End Slot Time</label>
            <TimePickerWithFormat value={loopPopupData.endDateTime}
              onChange={(e) => {
                setLoopPopupData({ ...loopPopupData, endDateTime: e.target.value })
              }} />
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-6">
            <label>{lang.channel_label}:</label>
            <MultiSelect
              data={ChannelList}
              textField="FullChannelName"
              dataItemKey="_id"
              value={loopPopupData.channels}
              onChange={(e) => {
                if (e.target.value.length == 0) {
                  setLoopPopupData({ ...loopPopupData, channels: [loopPopupData.data.Channel] })
                } else {
                  setLoopPopupData({ ...loopPopupData, channels: e.target.value })
                }
              }}
            />
          </div>
        </div>
      </BossDialog>}

      {showAutoPlanningPopup && <BossDialog
        title={'Auto Planning Plotting'}
        width={"600px"}
        onClose={() => setShowAutoPlanningPopup(false)}

      >
        <EditPageHeader saveButtonTitle={"Generate"} onSubmit={getAutoPlanningTentativeData} onCancel={() => setShowAutoPlanningPopup(false)} showTitle={false} />
        <br />
        <div className='row'>
          <div className="col-4">
            <label>{lang.channel_label}:</label>
            <DropDownList
              data={ChannelList}
              textField="name"
              dataItemKey="_id"
              value={autoPlaningData.Channel}
              onChange={(e) => { startTransition(() => setAutoPlaningData({ ...autoPlaningData, Channel: e.target.value })) }}
            />
          </div>
          <div className="col-4">
            <label htmlFor="">{lang.from_label}</label>
            <input className="form-control form-control-sm" type={'date'} name={"From"} placeholder={""} label={lang.from_label} onChange={(e) => { startTransition(() => setAutoPlaningData({ ...autoPlaningData, FromDate: e.target.value })) }} value={autoPlaningData.FromDate} style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px', height: '30px' }} />
          </div>
          <div className="col-4">
            <label htmlFor="">{lang.to_label}</label>
            <input className="form-control form-control-sm" type={'date'} name={"To"} placeholder={""} label={lang.to_label} onChange={(e) => { startTransition(() => setAutoPlaningData({ ...autoPlaningData, ToDate: e.target.value })) }} value={autoPlaningData.ToDate} style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px', height: '30px' }} />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12" style={{ textAlign: "left" }}>
            <div className="form-group">
              <div style={{ height: "35px", backgroundColor: "#cce5ed" }} className="form-control form-control-sm" name="DisplayName">{isLoading ? "Preparing tentative schedule, wait for magic to happen..." : ""}</div>
            </div>
          </div>
        </div>
      </BossDialog>}

      {showAutoPlanningTentativePopup && <BossDialog title={"Tentative Schedule"} onClose={() => { setShowAutoPlanningTentativePopup(false); setIsLoading(true); }} width={"50vw"}>
        <EditPageHeader saveButtonTitle={"Plot"} onSubmit={onAutoPlanningPlotting} onCancel={() => setShowAutoPlanningTentativePopup(false)} showTitle={false} />
        {isLoading && <Loader height="40vh" />}
        {!isLoading && <Grid data={autoPlanningTentativeData} style={{ height: "400px" }} rowHeight={50} resizable={true}>
          <GridColumn field={'mediaEpisode.Title'} title={'Title'} width={'200px'} />
          <GridColumn field={'SlotDateTime'} title={'Date'} cell={DateOnlyCell} width={'120px'} />
          <GridColumn field={'SlotDateTime'} title={'Time'} cell={TimeCell} width={'120px'} />
          <GridColumn title={'Channel'} field={'Channel.FullChannelName'} width={'120px'} />
          <GridColumn field={'PlanningSlot.Description'} title={'Slot'} width={'120px'} />
          <GridColumn field={'mediaEpisode.MediaCategory.Description'} title={'Media Category'} width={'120px'} />
          <GridColumn field={'mediaEpisode.Duration'} title={'Duration'} cell={TimeCell} sortable={true} width={'120px'} />
          <GridColumn field={'mediaEpisode.Genres'} title={'Genres'} cell={ArrayCell} format={'Description'} width={'200px'} />
        </Grid>}
      </BossDialog>}

      <ProgressBarPopup ModuleID={MODULE.PLANNING} />
      {showProgressBar.progressBarPopup && <div id='myProgressBar'>
        <div className='myProgressBarBox'>
          <ProgressBar
            label={(props) => { return <strong>{props.value}%</strong> }}
            labelPlacement='center'
            progressStyle={{ backgroundColor: "green" }}
            value={progressData.progress}
            style={{ width: "360px" }}
          />
          <div className='message'> <p>{progressData.message}</p> </div>
          {showProgressBar.okButton && <button onClick={() => { setShowProgressBar({ ...showProgressBar, progressBarPopup: false }); }} style={{ width: "50px" }} className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-success text-white' >Ok</button>}
        </div>
      </div>}
    </>
  );
};

export default PlannerWrapper;
