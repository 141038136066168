const ArchiveIconCell = (props) => {
    let field = props.field ?? '';
    let value = '';

    if (field.includes('.')) {
        //For field value coming as example :- MediaCategory.Duration
        var keys = field.split('.');
        value = props.dataItem?.mediaEpisode != undefined ?
            props.dataItem?.mediaEpisode?.Archive != undefined ? props.dataItem?.mediaEpisode?.Archive : false
            : false;
    } else {
        value = props.dataItem[field];
    }
    const myStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: value ? 'red' : '#63ba3c',
        borderRadius: '5px',
        height: '17px',
        width: '16px',
    };

    return (
        <td style={props.style}>
            <i id={props.dataItem?._id} className={`fas fa-archive icon`} style={{ fontSize: '8px', color: 'white', ...myStyle }}></i>
            <label id={props.dataItem?._id} className="ml-1" style={{ color: props.style.color ?? '#424242', textTransform: 'capitalize' }}>{JSON.stringify(value)}</label>
        </td>
    );
}

export default ArchiveIconCell;
