/* eslint-disable */
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { toast } from 'react-toastify';
import { DateCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { useTranslation } from "../../../locale/useTranslation";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import BossDialog from "../../../components/BossDialog";

const DATA_ITEM_KEY = "ProgramSchedule_id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const UnPublishSelectionCollection = (props) => {

  const lang = useTranslation();

  const [gridData, setGridData] = useState(props.data);
  const [selectedState, setSelectedState] = useState({});

  const gridRef = useRef(props.data);

  useEffect(() => {
    
  }, []);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);
 
    },[selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    if(props.mode == 'single'){
      toast.info(`${lang.select_any_one_collection_selection_error_message}` , {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
  
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);
    
  }, []);

  const checkIfItemExist =(item) => {

    var isFound = false;
    var existingEntries = props.existingItems ?? [];
    // console.log("checking duplicate")
    // console.log(item.SID);
    // console.log(props);
    if(props.checkDuplicate??false)
      {
            isFound = existingEntries.some(element => {
            console.log("props");
            console.log(props);
            if (element.SID === item.SID) {
              return true;
            }
        
            return false;
          });
      }
      // console.log("isFound");
      // console.log(isFound);
      return isFound;
    
  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {
    var msg = `${lang.please_select_first_collection_error_message}`

    var selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      var key = obj[DATA_ITEM_KEY];
      //if true means checked
      if(selectedState[key])
      {
        // checking duplicate as well
        if(!checkIfItemExist(obj))
        {
          selectedDataList.push(obj);
        }else{
          msg = `${lang.already_attached_collection_error_message}`
        }        
      }      
    })

    if(selectedDataList.length != 0){
        props.onConfirm(selectedDataList)
    }else{
      // alert(msg)
      toast.info(msg , {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  return (
    <BossDialog title={props.title} onClose={props.closeForm} width={"500px"}>
        <div className="row mt-1 mb-3" style={{marginLeft:'-25px'}}>
                  <div className="col">
                    <ActionButton title={lang.save_button_tooltip} name={'Confirm'} onClick={onSelection} />
                    <ActionButton title={lang.cancel_button_tooltip} name={lang.cancel_button_text} btnColor={'danger'} onClick={props.closeForm} />
                  </div>
        </div>

        <Grid data={gridData.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  }))}
                  dataItemKey={DATA_ITEM_KEY}
                  selectedField={SELECTED_FIELD}
                  selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: props.mode ?? "multiple",
                  }}
                  ref={gridRef}
                  onSelectionChange={onSelectionChange}
                  onHeaderSelectionChange={onHeaderSelectionChange}
                  style={{height:"200px"}}
                >
                    <GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={ gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1 }
                        locked={true}
                    />
                    <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} />
                    <GridColumn field="SlotDateTime" title={lang.slot_date_time_column} editable={false} cell={DateCell} />
                </Grid>
            </BossDialog>
  )
}

export default UnPublishSelectionCollection