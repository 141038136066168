/* eslint-disable */
import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import * as API from "../../framework/API/api";
import { DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { utility } from '../../framework/utility/utilityProvider';
import moment from 'moment';
import { ENTITYNAME, FILEURL, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import { MenuCommandCell } from '../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BossDialog from '../../components/BossDialog';

const MediaEpisodeHistory = memo(({ selectedData, closeForm, showTitle, isFromContract, segment }) => {

    let data = Array.isArray(selectedData) ? selectedData : [selectedData];

    console.log(data);

    useEffect(() => {
        loadHistory();
    }, []);

    const lang = useTranslation();

    const [gridData, setGridData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(`${lang.data_loading_error_message}`);
    const [segmentTypeDataItem, setSegmentTypeDataItem] = useState({
        SID: 0,
        Description: "All"
    });
    const [segmentTypes, setSegmentTypes] = useState([]);
    const [disable, setDisable] = useState(false);

    const loadHistory = async () => {

        let segmentTypeRes = await API.getDataLookup(ENTITYNAME.SegmentType, { sort: { Description: 1 } });

        if (segment) {
            setSegmentTypeDataItem(segmentTypeRes.data.find((x) => x.SID == segment?.SegmentType?.SID));
            setDisable(true);
        }
        segmentTypeRes.data.unshift({ SID: 0, Description: "All" });
        setSegmentTypes(segmentTypeRes.data);

        let tempData = []

        for (const element of data) {
            let res = await API.getMediaHistory(element, isFromContract ?? false, segment?._id);
            if (res.success) {
                tempData = [...tempData, ...res.data]
            }
            else {
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }
        setGridData(() => tempData.map(data => {
            return {
                ChannelName: (data.Channel?.FullChannelName ?? data.Channel?.name ?? ""),
                ...data
            }
        }))
        setFilterData(() => tempData.map(data => {
            return {
                ChannelName: (data.Channel?.FullChannelName ?? data.Channel?.name ?? ""),
                ...data
            }
        }))
        if (gridData.length == 0) {
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }

    }

    const downloadExcel = async () => {
        console.log(gridData.length == 0);
        if (gridData.length == 0) {
            toast.info(lang.no_data_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let storeData = gridData.map(x => {
            return {
                Title: x.mediaEpisode.Title,
                Channel: x.ChannelName,
                SlotDate: moment(new Date(x.SlotDateTime)).format("YYYY-MM-DD"),
                SlotTime: utility.convertMilisecondsToStringWithFrames(x.SlotDateTime),
                ExhibitionNumber: x.ExhibitionNumber,
                RepeatNumber: x.RepeatNumber
            }
        })

        let res = await API.exportCSV(storeData);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const mediaHistoryMenuModel = (props) => {

        const menu = [
            {
                label: 'Options',
                items: [
                    { label: 'Go To Planning', icon: 'pi pi-fw pi-calendar', command: () => props.onGoToPlanning(props.dataItem) },
                    { label: 'Go To Scheduling', icon: 'pi pi-fw pi-server', command: () => props.onGoToScheduling(props.dataItem) },
                ]
            }
        ]
        return menu;
    }

    // open planning
    const onGoToPlanning = (props) => {
        let data = { planningdate: new Date(moment(props?.SlotDateTime)), channels: [props?.Channel] }
        utility.deleteSessionStorageItem(LOCALSTORAGE_KEY.planningPublishData);
        utility.setSessionValue(LOCALSTORAGE_KEY.planningPublishData, data);
        window.open(`/home/planning`, "_blank");
    }

    // open scheduling
    const onGoToScheduling = (props) => {
        utility.setSessionValue(
            LOCALSTORAGE_KEY.publishData,
            props
        );
        window.open(`/home/Scheduling`, "_blank");
    }

    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            onGoToPlanning={onGoToPlanning}
            onGoToScheduling={onGoToScheduling}
            myMenuModel={mediaHistoryMenuModel}
        />
    );

    const onChange = (e) => {
        let tempData = [];
        if (e.target.value.SID == 0) {
            tempData = gridData;
        } else {
            tempData = gridData.filter((x) => x.segment?.SegmentType?._id == e.target.value._id);
        }
        setSegmentTypeDataItem(e.target.value);
        console.log(tempData);
        setFilterData(tempData);

    }

    return (
        <BossDialog
            title={lang.history_dialog_header}
            onClose={closeForm}
            width={"50%"}
        >
            <div className='display-flex'>
                <div className='float-left'>
                    {gridData.length > 0 && <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '5px' }} title={lang.download_button_tooltip} />
                    }</div>
                <div className='float-right'>
                    <label className="k-form-field">{lang.segment_type_label} : </label>
                    <DropDownList
                        style={{ backgroundColor: "white", width: "200px", marginLeft: "8px" }}
                        data={segmentTypes}
                        name="SegmentType"
                        textField="Description"
                        dataItemKey="SID"
                        value={segmentTypeDataItem}
                        onChange={onChange}
                        disabled={disable}
                    />
                </div>
            </div>
            <br /><br />
            <Grid data={filterData} style={{ height: "50vh", marginTop: "04px", overflow: "hidden" }}>
                <GridNoRecords>
                    {loadingStatus}
                </GridNoRecords>
                {segment && <Column cell={MyCommandCell} width={"55px"} />}
                <Column field="mediaEpisode.Title" title={lang.title_column} width={188} />
                <Column field="ChannelName" title={lang.channel_column} width={188} />
                <Column field="segment.SegmentType.Description" title={lang.segemnt_type_sub_menu} width={188} />
                <Column field="SlotDateTime" title={lang.slot_date_column} cell={DateOnlyCell} width={180} />
                <Column field="SlotDateTime" title={lang.slot_time_column} cell={TimeCell} width={180} />
                <Column field="ExhibitionNumber" title={lang.exhibition_no_column} width={180} />
                <Column field="RepeatNumber" title={lang.repeat_no_column} width={180} />
            </Grid>
        </BossDialog>
    )
});

export default MediaEpisodeHistory;