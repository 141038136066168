
import { useTranslation } from "../../../../locale/useTranslation";

const PreviewRoundButton = (props) => {
    const lang = useTranslation();

    return <button disabled={props.disabled ?? false} title={props.title ?? lang.preview_button_tooltip} type="submit" onClick={props.onClick} style={{ margin: "0px 02px", pointerEvents: props.disabled ? "none" : "auto", boxShadow: "none" }} className="btn btn-default btn-circle btn-my">
        <i className="fa-solid fa-eye img-circle"></i>

    </button>
}

export default PreviewRoundButton;
