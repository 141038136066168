export const RoundIcon = ({
    title = '',
    color = "black",
    bgColor = "white",
    onClick = () => { },
    icon = "fa-i",
    style = {}
}) => {
    return <div title={title} className="btn btn-default btn-circle btn-my" style={{ ...style, border: `1px solid ${bgColor}`, backgroundColor: bgColor }} onClick={onClick}>
        <i style={{ color: color, position: "relative", top: "1px" }} className={`fa ${icon} fa-fw`} />
    </div>
}