/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../../src/framework/API/api";
import SalesDashboardTile from "./SalesDashboardTile";
import PieCharts from "./Charts/PieChart";
import DonutChart from './Charts/DonutChart';
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { utility } from "../../framework/utility/utilityProvider";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import GlobalizationChart from "./Charts/GlobalizationChart";
import { MONTH, PLATFORM } from "../../framework/constant/constant";



const MonetizationDashboard = (props) => {

    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const [dataItem, setDataItem] = useState({
        FromDate: defaultDates.FirstDate,
        ToDate: defaultDates.LastDate,
    });
    const payloadRef = useRef({
        payload: {
            fromDate: new Date(defaultDates.FirstDate).getTime(),
            toDate: new Date(defaultDates.LastDate).getTime(),
        }
    });

    const [segmnetBySubscriber, setSegmnetBySubscriber] = useState([]);
    const [segmnetByProduct, setSegmnetByProduct] = useState([]);
    const [gridData, setGridData] = useState([]);
    const lang = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [userByPlanData, setUserByPlanData] = useState([]);
    const [tabNumber, setTabNumber] = useState("1");


    //GENERAL TAB
    const [dataCount, setDataCount] = useState({
        subscriberCount: 0,
        productCount: 0,
        segmentCount: 0,
        couponCount: 0,
        newSubscriberCount: 0,
        noPackageUserSubscriberCount: 0,
        expiringUserInWeekCount: 0,
        activeSubscriber: [],
    })

    //SUBSCRIPTION TAB
    const [subscriptionData, setSubscriptionData] = useState({
        subscriberRegistrationCount: [],
        subscriberRegistrationPlatformWise: {
            MOBILE: 0,
            WEB: 0,
            TAB: 0,
            TV: 0
        },
        purchasesByPaymentGateWay: [
            { _id: [1], name: ['Razor Pay'], value: 5 },
            { _id: [2], name: ['Cash Free'], value: 10 },
            { _id: [3], name: ['Zep Pay'], value: 4 },
            { _id: [4], name: ['PayPal'], value: 5 },
            { _id: [5], name: ['PayU'], value: 6 },
        ]
    });

    //REVENUE TAB
    const [revenueData, setRevenueData] = useState({
        revenueByMonth: {
            month: Object.keys(MONTH).map((x, i) => MONTH[i]),
            value: [1200, 1500, 1000, 1800, 1100, 1200, 1500, 1700, 800, 1100, 900, 1600]
        },
        revenueByPlatform: {
            platform: PLATFORM.filter((x) => x.SID != 0).map((y) => y.Description),
            value: [2500, 2700, 2200, 2000]
        },
        revenueByPaymentGateway: {
            paymentProvider: ['Razor Pay', 'Cash Free', 'Zep Pay', 'PayU'],
            value: [2800, 2000, 2100, 2500]
        },
        revenueByCurrency: {
            currency: ['Dollar', 'Diram', 'INR', 'Pound', 'Euro'],
            value: [3100, 2800, 2900, 2400, 2200]
        }
    });

    useEffect(() => {
        loadData();
    }, [])

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const loadData = async () => {

        if (payloadRef.current == undefined) {
            return;
        }

        let getMonetizationGeneralTabDataRes = await API.getMonetizationCounts();

        console.log(getMonetizationGeneralTabDataRes);

        let userByPlan = await API.getUserByPlanData();
        let resTotal = await API.getSegmentCollectionData();

        let getMonetizationSubscriptionTabDataRes = await API.getMonetizationDashBoardData(payloadRef.current);

        let getSubscriptionGraphDetailsRes = await API.getSubscriptionGraphDetails(payloadRef.current);

        if (getMonetizationGeneralTabDataRes.success) {

            setDataCount({
                ...dataCount,
                subscriberCount: getMonetizationGeneralTabDataRes.data.subscriber.count,
                productCount: getMonetizationGeneralTabDataRes.data.productCount.count,
                segmentCount: getMonetizationGeneralTabDataRes.data.segmentCount.count,
                couponCount: getMonetizationGeneralTabDataRes.data.couponCount.count,
                newSubscriberCount: getMonetizationGeneralTabDataRes.data.newSubscriberInWeek.count,
                noPackageUserSubscriberCount: getMonetizationGeneralTabDataRes.data.noPackUserSubscriber.count,
                expiringUserInWeekCount: getMonetizationGeneralTabDataRes.data.expiringUserInWeek.count,
                activeSubscriber: getMonetizationGeneralTabDataRes.data.activeSubscriber.count,
            });
        }

        setUserByPlanData(userByPlan.data);
        setSegmnetBySubscriber(resTotal.data);
        setSegmnetByProduct(resTotal.data);
        setGridData(resTotal.data);
        setSubscriptionData({
            ...getMonetizationSubscriptionTabDataRes.data,
            ...getSubscriptionGraphDetailsRes.data,
            //DUMMY DATA FOR NOW
            purchasesByPaymentGateWay: [
                { _id: [1], name: ['Razor Pay'], value: 5 },
                { _id: [2], name: ['Cash Free'], value: 10 },
                { _id: [3], name: ['Zep Pay'], value: 4 },
                { _id: [4], name: ['PayPal'], value: 5 },
                { _id: [5], name: ['PayU'], value: 6 },
            ]
        });

        setIsLoading(false);
    };

    const refreshCollection = () => {
        setIsLoading(true);
        loadData();
    };

    return (
        <>
            {/* <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    <div style={{ paddingTop: "02px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                </div>
            </div> */}

            <div className="row" style={{ margin: "0px" }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label={lang.general_label} value="1" />
                                <Tab label={lang.subscription_label} value="2" />
                                <Tab label={lang.revenue_label} value="3" />
                            </TabList>
                        </Box>
                        <TabPanel value={"1"}>
                            <div className="widget-body">
                                <div className="row" style={{ marginLeft: "3px" }}>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#1ca666"
                                            count={dataCount.productCount}
                                            icon="fa-sharp fa-solid fa-boxes-packing"
                                            status={lang.product_label}
                                            description={lang.view_details_label}
                                            route="/home/OttProduct"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#c72848"
                                            count={dataCount.segmentCount}
                                            icon="fa-solid fa-layer-group"
                                            status={lang.segments_label}
                                            description={lang.view_details_label}
                                            route="/home/OttSegment"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-4 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#3c83c9"
                                            count={dataCount.couponCount}
                                            icon="fa-sharp fa-solid fa-id-card"
                                            status={lang.coupon_label}
                                            description={lang.view_details_label}
                                            route="/home/Coupon"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="p-1 dashboard-title-style">{lang.region_details_monetization_dashboard_label}</div>
                                    <Grid style={{ height: "27vh", }} data={gridData}>
                                        <Column field="Description" title={lang.region_column} editable={false} />
                                        <Column field="Products" title={lang.products_column} editable={false} />
                                        <Column field="TotalCoupons" title={lang.total_coupon_column} editable={false} />
                                        <Column field="TotalSubscribers" title={lang.total_subscriber_column} editable={false} />
                                    </Grid>
                                </div>
                                <div className='row mt-4'>
                                    <div className="col-6">
                                        <PieCharts title={lang.segment_by_subscribe_label} listData={segmnetBySubscriber}></PieCharts>
                                    </div>
                                    <div className="col-6">
                                        <PieCharts title={lang.segment_by_product_label} listData={segmnetByProduct}></PieCharts>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={"2"}>
                            <div className="widget-body">
                                <div className="row" style={{ marginLeft: "3px" }}>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#f58d05"
                                            count={subscriptionData.registeredSubscribers}
                                            icon="fa-sharp fa-solid fa-users"
                                            status={lang.total_subscriber_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#c72848"
                                            count={subscriptionData.activeSubscribers}
                                            icon="fa-sharp fa-solid fa-user-check"
                                            status={lang.total_active_subscriber_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#FA6166"
                                            count={subscriptionData.newSubscribers}
                                            icon="fa-sharp fa-solid fa-person-circle-plus"
                                            status={lang.new_subscriber_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ marginLeft: "3px" }}>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#FF69B3"
                                            count={subscriptionData.nonPackSubscribers}
                                            icon="fa-sharp fa-solid fa-users-slash"
                                            status={lang.total_deactive_subscriber_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#FA6166"
                                            count={subscriptionData.totalActivations}
                                            icon="fa-sharp fa-solid fa-user-plus"
                                            status={lang.total_activation_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                    <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-4">
                                        <SalesDashboardTile
                                            iconBackColor="#98BC62"
                                            count={subscriptionData.expiringUsers}
                                            icon="fa-sharp fa-solid fa-user-minus"
                                            status={lang.expiring_users_label}
                                            description={lang.view_details_label}
                                            route="/home/Subscriber"
                                        />
                                    </div>
                                </div>
                                <div className='row'>

                                    <div className="col-md-6">
                                        <GlobalizationChart
                                            name={subscriptionData?.subscriberRegistrationCount.length > 0 ? subscriptionData?.subscriberRegistrationCount?.map((x) => MONTH[x?._id?.month - 1]) : []}
                                            totaldata={subscriptionData?.subscriberRegistrationCount.length > 0 ? subscriptionData?.subscriberRegistrationCount?.map((x) => x?.count) : []}
                                            title={lang.customers_registration_count_graph}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <GlobalizationChart
                                            name={PLATFORM.filter((y) => y.SID != 0).map((x) => x.Description)}
                                            totaldata={PLATFORM.filter((y) => y.SID != 0).map((key) => subscriptionData?.subscriberRegistrationPlatformWise[key.Description.trim().toUpperCase()])}
                                            title={lang.customers_registration_platform_wise}
                                            isLoading={isLoading}
                                        />
                                    </div>

                                </div>

                                <div className='row mt-4'>
                                    <div className="col-6">
                                        <DonutChart title={lang.subscribers_by_plan_label} root={`/home/Client`} listData={userByPlanData} isLoading={isLoading}></DonutChart>
                                    </div>

                                    {/* ADDING DUMMY DATA FOR NOW */}
                                    <div className="col-6">
                                        <DonutChart title={lang.purchases_by_payment_gateway} root={`/home/Client`} listData={subscriptionData.purchasesByPaymentGateWay}></DonutChart>

                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={"3"}>
                            <div className="widget-body">
                                <div className="row">

                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#0E46A3", borderRadius: "10px", padding: "10px 20px" }}>
                                            <div style={{ display: 'flex' }}>
                                                <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', color: "white" }}>Current Year</h1>
                                                {/* <p className="poppins-extrabold" style={{ margin: '22px 2px 0px 2px', color: "white" }}>/year</p> */}
                                            </div>
                                            <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', padding:'30px 0px 0px 0px', color: '#66c4ef', textAlign : 'right' }}>$5631.11</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12" >
                                        <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#0E46A3", borderRadius: "10px", padding: "10px 20px" }}>
                                            <div style={{ display: 'flex' }}>
                                                <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', color: "white" }}>Current Month</h1>
                                                {/* <p className="poppins-extrabold" style={{ margin: '22px 2px 0px 2px', color: "white" }}>/curr. month</p> */}
                                            </div>
                                            <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', padding:'30px 0px 0px 0px', color: '#66c4ef', textAlign : 'right' }}>$523.78</h1>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12" >
                                        <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#0E46A3", borderRadius: "10px", padding: "10px 20px" }}>
                                            <div style={{ display: 'flex' }}>

                                                <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', color: "white" }}>Previous Month</h1>
                                                {/* <p className="poppins-extrabold" style={{ margin: '22px 1px 0px 1px', color: "white" }}>/prev. month</p> */}
                                            </div>
                                            <h1 className="poppins-extrabold" style={{ fontSize: '40px', margin: '0px', padding:'30px 0px 0px 0px', color: '#66c4ef', textAlign : 'right' }}>$480.12</h1>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className="col-md-6">
                                        <GlobalizationChart name={revenueData.revenueByMonth.month} totaldata={revenueData.revenueByMonth.value} title={lang.revenue_by_month_label} isLoading={isLoading} />
                                    </div>
                                    <div className="col-md-6">
                                        <GlobalizationChart name={revenueData.revenueByPlatform.platform} totaldata={revenueData.revenueByPlatform.value} title={lang.revenue_by_platform_label} isLoading={isLoading} />
                                    </div>
                                    <div className="col-md-6">
                                        <GlobalizationChart name={revenueData.revenueByPaymentGateway.paymentProvider} totaldata={revenueData.revenueByPaymentGateway.value} title={lang.revenue_by_payment_gateway_label} isLoading={isLoading} />
                                    </div>
                                    <div className="col-md-6">
                                        <GlobalizationChart name={revenueData.revenueByCurrency.currency} totaldata={revenueData.revenueByCurrency.value} title={lang.revenue_by_currency_label} isLoading={isLoading} />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>

                    </TabContext>
                </Box>
            </div>



        </>
    );
};

export default MonetizationDashboard;
