/* eslint-disable */
import React, { useState } from 'react'
import { Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { EditPageHeader } from '../../components/EditPageHeader';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { TimeRangePicker } from '../../framework/forms/helpercomponents/timepicker/TimeRangePicker';
import { useTranslation } from '../../locale/useTranslation';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import BossDialog from '../../components/BossDialog';

export const RepeatPatternDetails = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        _id: 0,
        Type: props.Type,
        PrimaryEventsNo: props.dataRepeat.PrimaryEventsNo ?? "",
        RepeatEventsNo: "",
        Days: [],
        NominalStart: [],
        Sun: false, Mon: false, Tue: false, Wed: false, Thu: false, Fri: false, Sat: false,
        SelectAll: [],
        SunTime: "__:__", MonTime: "__:__", TueTime: "__:__",
        WedTime: "__:__", ThuTime: "__:__", FriTime: "__:__", SatTime: "__:__",
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [nominalStartLineCount, setNominaStartLineCount] = useState([1]);
    var Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    const onchange = (e) => {
        if (e.target.name == 'SelectAll') {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, Sun: e.target.value, Mon: e.target.value, Tue: e.target.value, Wed: e.target.value, Thu: e.target.value, Fri: e.target.value, Sat: e.target.value });
        }
        else {
            if (e.target.name == "Sun" || e.target.name == "Mon" || e.target.name == "Tue" || e.target.name == "Wed" || e.target.name == "Thu" || e.target.name == "Fri" || e.target.name == "Sat") {
                var temp = Days.filter((day) => dataItem[day] == true)
                if (e.target.value) {
                    !temp.includes(e.target.name) && temp.push(e.target.name);
                } else {
                    temp = temp.filter((day) => day != e.target.name)
                }
                console.log(temp)
                setDataItem({ ...dataItem, [e.target.name]: e.target.value, SelectAll: temp.length == 7 ? true : false })
            }
            else {
                setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            }
        }
    }

    const onSave = (e) => {
        var temp = dataItem.Days;
        var pn = props.Type == "Repeat" ? dataItem.PrimaryEventsNo : props.maxPrimaryEventsNo() + 1;
        var rn = props.Type == "Repeat" ? props.maxRepeatEventsNo(dataItem.PrimaryEventsNo) : 0;
        for (let i = 0; i < Days.length; i++) {
            for (let j = 0; j < nominalStartLineCount.length; j++) {
                const count = nominalStartLineCount[j];
                if (dataItem[Days[i]] && dataItem[`${Days[i]}Time${count}`]) {
                    // console.log(dataItem[`${Days[i]}Time${count}`]);
                    var tempTime = dataItem[`${Days[i]}Time${count}`].replace(/_/g, "0");
                    var data = {
                        _id: pn + "_" + rn,
                        Type: dataItem.Type,
                        PrimaryEventsNo: pn,
                        RepeatEventsNo: rn,
                        Days: Days[i],
                        NominalStart: utility.convertShortTimeStringToMilliSeconds(tempTime)
                    }
                    temp.push(data);
                    pn = props.Type != "Repeat" ? pn + 1 : pn;
                    rn = props.Type == "Repeat" ? rn + 1 : rn;
                }
            }
        }
        console.log(temp);
        if (temp.length == []) {
            toast.error(`${lang.please_select_atleast_one_days_repeat_pattern_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            props.setDataList(temp)
            setDataItem(blankDataItem)
            props.closeForm();
        }
    }

    const Cancel = () => {
        props.closeForm()
        setDataItem(blankDataItem)
    }

    const onAddNominalStartLine = () => {
        if (nominalStartLineCount.length < 5) {
            setNominaStartLineCount([...nominalStartLineCount, nominalStartLineCount[nominalStartLineCount.length - 1] + 1])
        }
        // else{
        //     toast.error(``, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }
    }

    return (
        <BossDialog
            title={lang.repeated_pattern_details_dialog_header}
            onClose={Cancel}
            width={"700px"}
        >
            <EditPageHeader title={""} onSubmit={onSave} onCancel={Cancel} showTitle={false} />
            <Form
                initialValues={dataItem}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="marlr"></div>
                        <div className="row mt-2">
                            <div className='col-12'>
                                <div className="row mt-2 mb-2" style={{marginLeft: '-5px'}}>
                                    <div className="col-3">
                                        <Checkbox style={{border: '1px solid grey'}} name={"SelectAll"} label={lang.select_all_label} value={dataItem.SelectAll} onChange={onchange} />
                                    </div>
                                    <div className="col-3">
                                        <label>{lang.type_label}: {props.Type}</label>
                                    </div>

                                    {props.Type == "Repeat" && <div className="col">
                                        <label>{lang.primary_events_no_label}: </label>
                                        <input
                                            className="pl-2"
                                            type="number"
                                            name={"PrimaryEventsNo"}
                                            onChange={onchange}
                                            value={dataItem.PrimaryEventsNo}
                                            disabled={true}
                                        />
                                    </div>}
                                </div>
                                <ActionButton title={lang.add_line_button_tooltip} name={lang.add_line_button_text} btnColor={'info'} onClick={onAddNominalStartLine} />
                                <div className='row'>
                                    <div className='col-12' >
                                        <div className='row m-2 border'>
                                            <div className='col-2'>
                                                <div className='mt-2'>{lang.days_label}
                                                    <div style={{marginTop: '18px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Sun"}
                                                            label={lang.sun_label}
                                                            onChange={onchange}
                                                            value={dataItem.Sun}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Mon"}
                                                            label={lang.mon_label}
                                                            onChange={onchange}
                                                            value={dataItem.Mon}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Tue"}
                                                            label={lang.tue_label}
                                                            onChange={onchange}
                                                            value={dataItem.Tue}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Wed"}
                                                            label={lang.wed_label}
                                                            onChange={onchange}
                                                            value={dataItem.Wed}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Thu"}
                                                            label={lang.thu_label}
                                                            onChange={onchange}
                                                            value={dataItem.Thu}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Fri"}
                                                            label={lang.fri_label}
                                                            onChange={onchange}
                                                            value={dataItem.Fri}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '21px'}}>
                                                        <Checkbox
                                                            style={{border: '1px solid grey'}}
                                                            name={"Sat"}
                                                            label={lang.sat_label}
                                                            onChange={onchange}
                                                            value={dataItem.Sat}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {nominalStartLineCount.map((x) =>
                                                <div className='col-2'>
                                                    <div className='mt-2'>{'Start Time'}
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`SunTime${x}`}
                                                                value={dataItem[`SunTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`MonTime${x}`}
                                                                value={dataItem[`MonTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`TueTime${x}`}
                                                                value={dataItem[`TueTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`WedTime${x}`}
                                                                value={dataItem[`WedTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`ThuTime${x}`}
                                                                value={dataItem[`ThuTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`FriTime${x}`}
                                                                value={dataItem[`FriTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                        <div className='mt-2 mb-2'>
                                                            <TimeRangePicker
                                                                className="form-control form-control-sm mt-1"
                                                                name={`SatTime${x}`}
                                                                value={dataItem[`SatTime${x}`]}
                                                                onChange={onchange}
                                                                mask={'__:__'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FormElement>
                )}

            />
        </BossDialog>

    )
}
