/* eslint-disable */
import { useEffect, useState } from 'react';
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import * as API_DIGITAL_SIGN from '../../../framework/API/api_digitalSign';
import { ENTITYNAME, LOCALSTORAGE_KEY } from '../../../framework/constant/constant';
import { utility } from '../../../framework/utility/utilityProvider';

const MyCompanyCell = (props) => {

    const field = props.field || "" ;
    const value = props.dataItem != undefined ? props.dataItem[field] : "";
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES)
    const [displayValue] = useState(companies.length > 0 ? companies.find(x => x?._id == value)?.Name : "");

    return (
        <td
            colSpan={props.colSpan} role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...useTableKeyboardNavigation(props.id)}
        >   
            {displayValue}
        </td>
    );
}

export default MyCompanyCell;