import MenuButton from "../buttons/MenuButton";

export const MenuCommandCell = (props) => {
 
  return <td style={props.style} className={props.className} colSpan={props.colSpan} role={"gridcell"}>
    <div className="row">
      <div className="col-1">
        <MenuButton title={'options'} props={props} collectionMenuModel={props.myMenuModel}/>
      </div>
    </div>
  </td>;
};