import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../locale/useTranslation'
import { toast } from "react-toastify";
import { ACCESS_TYPE, FILEURL, PROGRAMSCHEDULEDRAFT_SAVETYPE } from '../../../framework/constant/constant';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../../components/EditPageHeader";
import * as API from '../../../framework/API/api'
import { utility } from '../../../framework/utility/utilityProvider';
import BossDialog from '../../../components/BossDialog';

const ScheduleDraftEditForm = (props) => {
  const lang = useTranslation();
  const { scheduleDate, scheduleEndDate, channel, close } = props;

  const [dataItem, setDataItem] = useState({
    ScheduleDate: scheduleDate,
    ChannelSID: channel.SID,
    FileName: `Schedule-Draft-${channel.FullChannelName}-${utility.convertMilisecondsToDateString(scheduleDate)}`,
    AccessType: { _id: ACCESS_TYPE.PUBLIC, Description: Object.keys(ACCESS_TYPE).find((x) => ACCESS_TYPE[x] == ACCESS_TYPE.PUBLIC) },
    SaveType: { _id: PROGRAMSCHEDULEDRAFT_SAVETYPE.DATABASE, Description: Object.keys(PROGRAMSCHEDULEDRAFT_SAVETYPE).find((x) => PROGRAMSCHEDULEDRAFT_SAVETYPE[x] == PROGRAMSCHEDULEDRAFT_SAVETYPE.DATABASE) },
    Remarks: ''
  })

  useEffect(() => {
  }, [])

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value })
  }

  const isValid = () => {
    if (dataItem.FileName == undefined || dataItem.FileName == "" || !dataItem.FileName?.toString().trim()) {
      toast.error(`Please enter file name`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const onSaveDraft = async () => {

    if (!isValid()) return;

    let payload = {
      ScheduleDate: scheduleDate,
      ChannelSID: channel.SID,
      FileName: dataItem.FileName,
      AccessType: dataItem.AccessType._id,
      SaveType: dataItem.SaveType._id,
      Remarks: dataItem.Remarks
    }

    console.log(payload)

    const scheduleSaveRes = await API.programScheduleDraft(payload);
    if (scheduleSaveRes.success) {
      if (dataItem.SaveType._id == PROGRAMSCHEDULEDRAFT_SAVETYPE.FILE || dataItem.SaveType._id == PROGRAMSCHEDULEDRAFT_SAVETYPE.BOTH) {
        let url = FILEURL.BASEURL + 'downloadReport/' + scheduleSaveRes.fileName;
        window.open(url, "_blank");
      }
      toast.success("Schedule saved as draft", { position: toast.POSITION.TOP_RIGHT });
      close();

    } else {
      toast.error(scheduleSaveRes.message, { position: toast.POSITION.TOP_RIGHT });
    }

  }


  return (
    <BossDialog
      title={`${channel.FullChannelName} ${utility.convertMilisecondsToDateTimeString(scheduleDate)} - ${utility.convertMilisecondsToDateTimeString(scheduleEndDate)}`}
      onClose={close}
      width={"800px"}
    >
      <EditPageHeader title={""} onSubmit={onSaveDraft} saveButtonTitle={'Save Draft'} onCancel={close} showTitle={false} />
      <div className="row">
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-12 form-group">
              <label htmlFor="TabView">{lang.file_name_column} *</label>
              <input type="text" className="form-control form-control-sm" name="FileName" onChange={onChange} value={dataItem.FileName} />
            </div>

            <div className="col-6 form-group">
              <label htmlFor="TabView">{'Access Type'} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={Object.keys(ACCESS_TYPE).map((x) => {
                  return {
                    _id: ACCESS_TYPE[x], Description: x
                  }
                })}
                name="AccessType"
                textField="Description"
                dataItemKey="_id"
                value={dataItem.AccessType}
                onChange={onChange}
              />
            </div>
            <div className="col-6 form-group">
              <label htmlFor="TabView">{'Save Type'} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={Object.keys(PROGRAMSCHEDULEDRAFT_SAVETYPE).map((x) => {
                  return {
                    _id: PROGRAMSCHEDULEDRAFT_SAVETYPE[x], Description: x
                  }
                })}
                name="SaveType"
                textField="Description"
                dataItemKey="_id"
                value={dataItem.SaveType}
                onChange={onChange}
              />
            </div>
            <div className="col-12 form-group">
              <label htmlFor="TabView">{lang.remark_label} *</label>
              <textarea
                type="text"
                className="form-control form-control-sm"
                name="Remarks"
                onChange={onChange}
                value={dataItem.Remarks}
              />
              <p className='muted'>{lang.schedule_draft_3_month_auto_delete_note}</p>
            </div>
          </div>
        </div>
      </div>
    </BossDialog >
  )
}

export default ScheduleDraftEditForm