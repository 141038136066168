/* eslint-disable */
import React from 'react'
import { Chart, ChartSeries, ChartSeriesItem, ChartLegend, ChartTooltip, } from '@progress/kendo-react-charts';
import { useTranslation } from '../../../locale/useTranslation';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { seriesChartColorGenerator } from '../../../framework/constant/constant';

const PieCharts = (props) => {

  const lang = useTranslation();
  const navigate = useNavigate();

  return (
    <div >

      <a onClick={(e) => { e.preventDefault(); navigate(props.root) }} style={{ textDecorationLine: "none" }}>
        <div className="panel panel-default" style={{ color: "#333", backgroundColor: "#f5f5f5", borderColor: "#ddd" }}>
          <div className="panel-heading cursor-pointer" style={{
            padding: "10px 10px", borderColor: "1px solid transparent", borderBottom: "1px solid transparent", borderTopRightRadius: "1px"   // border-top-right-radius: 1px;
            , borderTopLeftRadius: "1px", textAlignLast: "center"
          }}>{props.title}</div>

          {props?.isLoading ?

            <Loader height="53.5vh" bgColor="white" /> : props.listData == 0 ?
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '400px', width: '100%', backgroundColor: "white" }}>
                {lang.no_data_found_error_message}
              </div> :

              <div className="panel-body"><iframe className="chartjs-hidden-iframe" style={{ width: "100%", height: "800px", display: "block", border: "0px", height: "0px", margin: "0px", position: "absolute", inset: "0px" }}></iframe>

                <Chart id="myDispatchChart" style={{ display: "block", height: "400px" }} seriesColors={seriesChartColorGenerator()}>
                  <ChartTooltip />

                  <ChartSeries>
                    <ChartSeriesItem
                      type="pie"
                      autoFit="true"
                      data={props.listData}
                      field={props.title == `${lang.segment_by_product_label}` ? `Products` : props.title == `${lang.segment_by_subscribe_label}` ? `TotalSubscribers` : "value"}
                      categoryField={props.title == `${lang.segment_by_product_label}` ? `Description` : props.title == `${lang.segment_by_subscribe_label}` ? `Description` : "name"}
                      labels={{ visible: true, content: props.label }}
                    />
                  </ChartSeries>
                  <ChartLegend position="bottom" />
                </Chart></div>
          }
        </div></a>
    </div>

  )
}

export default PieCharts;
