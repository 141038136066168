
import { GRID_COL_INDEX_ATTRIBUTE } from '@progress/kendo-react-grid';
import React, { useState, useEffect } from 'react';

export const ByteToSizeCell = (props) => {
  const { colSpan, ariaColumnIndex, isSelected, style, columnIndex } = props;
  const field = props.field || "" ;
  const value = props.dataItem != undefined ? props.dataItem[field] : 1;
  
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (value === 0) {
      setDisplayValue("0 Bytes");
      return;
    }

    const i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
    const displaySize = (value / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    setDisplayValue(displaySize);
  }, [value]);

  return (
    <td
      colSpan={colSpan}
      role="gridcell"
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
      }}
      style={style}
    >
      {displayValue}
    </td>
  );
};
