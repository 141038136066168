/* eslint-disable */
import React, { useState, useEffect } from "react";
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import * as API from "../../framework/API/api";
import { ACCESS_LEVEL, ASSETTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import { DataHelper } from "../../framework/helper/DataHelper";
import { ConfirmAlert } from "../../ConfirmAlert";
import MyMultiSelect from "../../components/MyMultiSelect";
import BossDialog from "../../components/BossDialog";
import { Description } from "@mui/icons-material";

const OttFilterTemplate = (props) => {

    let showDefaultButton = props.showDefaultButton ?? true;

    const blankDataItem = {
        AssetType: { _id: 0, Description: 'All' },
        Provider: { _id: 0, Description: 'All' },
        VodType: { _id: 0, Name: 'All' },
        AccessLevel: { _id: ACCESS_LEVEL.Both, Description: utility.getKeyByValue(ACCESS_LEVEL, ACCESS_LEVEL.Both) },
        Genres: [],
        PGRating: [],
        ReleaseYear: '',
        MediaCategory: [],
        Languages: [],
        CastAndCrews: [],
        CreatedFrom: '',
        CreatedTo: '',
        Segments: {_id: 0, Description: 'All'}
    }

    const [isCleared, setIsCleared] = useState(true);
    const [dataItem, setDataItem] = useState(props?.filterDataItem && Object.keys(props?.filterDataItem)?.length > 0 ? props?.filterDataItem : blankDataItem);
    const [openForm, setFormOpen] = useState(!showDefaultButton);
    const [template, setTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(props?.filterDataItem?.Template ?? {});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [hideSaveTemplateCheckBox, setHideSaveTemplateCheckBox] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [clearFilter, setClearFilter] = useState(false);

    const [assetTypes, setAssetTypes] = useState([]);
    const [providers, setProviders] = useState([]);
    const [accessLevels, setAccessLevels] = useState([]);
    const [vodtypes, setVodTypes] = useState([]);
    const [geners, setGeners] = useState([]);
    const [pgRatings, setPgRatings] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [mediaCategorys, setMediaCategorys] = useState([]);
    const [castAndCrews, setCastAndCrews] = useState([]);
    const [segments, setSegments] = useState([]);


    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
        setIsCleared(true);
    }, []);

    const loadcombo = async () => {
        let templateRes = await API.getDataLookup(ENTITYNAME.OttFilterTemplate, { sort: { Name: 1 } });
        setTemplate(templateRes.data);
        let assetTypeRes = (Object.keys(ASSETTYPE).map((item) => {
            return { "Description": item, "_id": ASSETTYPE[item] }
        }));
        assetTypeRes.unshift({ _id: 0, Description: 'All' });
        setAssetTypes(assetTypeRes);
        let providerRes = await API.getDataLookup(ENTITYNAME.OttProvider, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        providerRes.data.unshift({ _id: 0, Description: 'All' });
        setProviders(providerRes.data);
        let accessLevelRes = (Object.keys(ACCESS_LEVEL).map((item) => {
            return { "Description": item, "_id": ACCESS_LEVEL[item] }
        }));
        setAccessLevels(accessLevelRes);
        let vodTypeRes = await API.getDataLookup(ENTITYNAME.VODType, { query: ['Archive', '!=', true], sort: { Name: 1 } });
        vodTypeRes.data.unshift({ _id: 0, Name: 'All' });
        setVodTypes(vodTypeRes.data);
        let genersRes = await API.getDataLookup(ENTITYNAME.Genre, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        setGeners(genersRes.data);
        let pgRatingRes = await API.getDataLookup(ENTITYNAME.PGRating, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        setPgRatings(pgRatingRes.data);
        let mediaCategory = await API.getDataLookup(ENTITYNAME.MediaCategory, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        let filteredMediaCategory = mediaCategory.data.filter(item => item.isLong == true && item.SID != MEDIACATEGORIES.Episode);
        setMediaCategorys(filteredMediaCategory);
        let languagesRes = await API.getDataLookup(ENTITYNAME.Language, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        setLanguages(languagesRes.data);
        let castAndCrewRes = await API.getDataLookup(ENTITYNAME.CastType, { query: ['Archive', '!=', true], sort: { Description: 1 } });
        setCastAndCrews(castAndCrewRes.data);
        let segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '!=', true], sort: { Description: 1 } });
        segmentsRes.data.unshift({ _id: 0, Description: 'All' });
        setSegments(segmentsRes.data);
    }
   
    const onChange = (e) => {

        if (e.target.value) {
            setIsCleared(false);
        }

        if (e.target.name == 'Template') {
            setDataItem(e.target.value.FilterModel)
            let finalData = DataHelper.saveOttFilterTemplate(e.target.value.FilterModel);
            setSelectedTemplate({ ...e.target.value, FilterModel: finalData });
            setHideSaveTemplateCheckBox(true);
            return;
        }
        if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
            return;
        }
        if (e.target.name == 'SaveAsTemplate') {
            console.log(e.target.value);
            return;
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        setHideSaveTemplateCheckBox(false);

    }

    const onSearchClick = () => {
        let shortData = DataHelper.saveOttFilterTemplate(dataItem);

        let finalData = {
            ...shortData,
            Template: selectedTemplate,
        }
        console.log(finalData)
        props.toGetFilterDataItem(finalData);

        setFormOpen(false);
        props.onClose && props.onClose(false)

        if (saveAsTemplate) {
            onConfirmSaveTemplate();
        }
    }

    const onConfirmSaveTemplate = async () => {

        if (templateName.length < 0) {
            toast.error(`${lang.please_enter_template_name_to_save_template_planningfilter_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            let saveData = {
                SID: 0,
                user_id: utility.getValue(LOCALSTORAGE_KEY.user_id),
                Name: templateName,
                FilterModel: DataHelper.saveOttFilterTemplate(dataItem)
            }

            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.OttFilterTemplate, saveData);

            if (!res.success) return;
            setDataItem(res.data.FilterModel);
            setSelectedTemplate(res.data);
            setHideSaveTemplateCheckBox(true);
            setSaveAsTemplate(false);
            utility.deleteLocalStorageItem(ENTITYNAME.OttFilterTemplate);
            loadcombo();

        }

    }

    const onClear = () => {
        setHideSaveTemplateCheckBox(false);
        setDataItem(blankDataItem);
        setSelectedTemplate({});
        setSaveAsTemplate(false);
        setTemplateName('');
        setIsCleared(true);
        props.onClear && props.onClear();
    }

    const iconToolTip = () => {

        if (!isCleared) {

            let assetTypeFilter = Object.keys(dataItem.AssetType).length != 0 ? "Asset Type \r\n" : "";

            return (assetTypeFilter);
        }
        return lang.show_search_options_media_liberary_tooltip
    }

    const deleteItem = async () => {
        await API.deleteData(ENTITYNAME.OttFilterTemplate, selectedTemplate._id, "_id");
        utility.deleteLocalStorageItem(ENTITYNAME.OttFilterTemplate);
        onClear();
        loadcombo();
    }

    return <>
        {showDefaultButton &&
            <button style={{ border: "0px", backgroundColor: "white", height: '40px' }} onClick={() => setFormOpen(true)} title={iconToolTip()} aria-label="Advanced search options" >
                <div className="input-group-append" >
                    <span className="input-group-text" style={{ backgroundColor: isCleared ? "white" : "black" }}>
                        <i style={{ margin: "5px 0px", color: isCleared ? "black" : "white" }} className="fa fa-list" />
                    </span>
                </div>
            </button>}

        {openForm &&
            <BossDialog
                title={lang.search_filter_dialog_header}
                width={"600px"}
                height={dataItem.AssetType._id == ASSETTYPE["Media Asset"] ? "685px" : "auto"}
                onClose={() => {

                    if (props.onClose) {
                        props.onClose(false);
                        setClearFilter(false);
                    } else if (clearFilter) {
                        props.toGetFilterDataItem({ ...dataItem, Template: selectedTemplate });
                        setFormOpen(false);
                        setClearFilter(false);
                    }
                    else {
                        setFormOpen(false);
                        setClearFilter(false);
                    }
                }}
            >
                <div className="row">
                    <div className="col-12">
                        <ActionButton title={props.saveButtonName ?? lang.search_button_tooltip} name={props.saveButtonName ?? lang.search_button_text} onClick={onSearchClick} />
                        <ActionButton title={lang.clear_button_tooltip} name={lang.clear_button_text} btnColor={"danger"} onClick={() => { onClear(); setClearFilter(true); }} />
                    </div>
                </div>
                <div className='row mt-3' style={{ marginLeft: "-02px", width: "570px" }}>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.template_label} : </label>
                            </div>
                            <div className={hideSaveTemplateCheckBox ? "col-7" : "col-8"}>
                                <div className="form-group">
                                    <DropDownList
                                        data={template}
                                        name={"Template"}
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={selectedTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-1">
                                {hideSaveTemplateCheckBox && <button className={`btn btn-default btn-circle btn-my`} onClick={() => ConfirmAlert(() => deleteItem(), () => { })} type="button" title={lang.delete_button_tooltip} style={{ marginLeft: "-15px" }}>
                                    <i className="fa fa-trash" aria-hidden="true" ></i>
                                </button>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.asset_type_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <DropDownList
                                        data={assetTypes}
                                        name="AssetType"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.AssetType}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {dataItem.AssetType._id == ASSETTYPE["Media Asset"] && <>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.media_category_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: '#F0F0F0' }}
                                            data={mediaCategorys}
                                            name={"MediaCategory"}
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.MediaCategory}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.genre_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: '#F0F0F0' }}
                                            data={geners}
                                            name="Genre"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Genre}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.cast_and_crew_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: '#F0F0F0' }}
                                            data={castAndCrews}
                                            name="CastAndCrews"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.CastAndCrews}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.pg_rating_label} : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: '#F0F0F0' }}
                                            data={pgRatings}
                                            name="PGRating"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.PGRating}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.language_label} :</label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <MyMultiSelect
                                            style={{ backgroundColor: '#F0F0F0' }}
                                            data={languages}
                                            name={"Languages"}
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Languages}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.release_year_label} : </label>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            name="ReleaseYear"
                                            value={dataItem.ReleaseYear}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>}
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.provider_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <DropDownList
                                        data={providers}
                                        name="Provider"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Provider}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.access_level_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <DropDownList
                                        data={accessLevels}
                                        name="AccessLevel"
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.AccessLevel}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.vodtype_label} : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <DropDownList
                                        data={vodtypes}
                                        name="VodType"
                                        textField="Name"
                                        dataItemKey="_id"
                                        value={dataItem.VodType}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-4">
                                <label htmlFor="TabView">{lang.segment_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <DropDownList
                                        data={segments}
                                        name="Segments"
                                        textField="Description"
                                        dataItemKey={"_id"}
                                        value={dataItem.Segments}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-4 mt-3">
                                <label htmlFor="TabView">{lang.created_date_label} :</label>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.from_label} :</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedFrom"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedFrom}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <label htmlFor="TabView" style={{ fontSize: '11px', color: 'grey' }}>{lang.to_label} :</label>
                                <div className="form-group">
                                    <input
                                        name={"CreatedTo"}
                                        className="form-control form-control-sm"
                                        type={"date"}
                                        value={dataItem.CreatedTo}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {!hideSaveTemplateCheckBox && <div className="row">
                            <div className="col-4">
                                <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        name={"SaveAsTemplate"}
                                        type={"checkbox"}
                                        value={saveAsTemplate}
                                        onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                        checked={saveAsTemplate}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}

                        {saveAsTemplate && <div className="row">
                            <div className="col-4 mt-2">
                                <label htmlFor="TabView">{lang.template_name_label} * : </label>
                            </div>
                            <div className="col-8">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        name={"templateName"}
                                        value={templateName}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </BossDialog>}
    </>
}

export default OttFilterTemplate;