/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import DonutChart from './Charts/DonutChart';
import PieCharts from './Charts/PieChart';
import GlobalizationChart from './Charts/GlobalizationChart';
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import ColumnChart from './Charts/ColumnChart';
import AreaChart from './Charts/AreaChart';
import { toast } from 'react-toastify';
import ActiveDashboardTile from './ActiveDashboardTile';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import 'hammerjs';
import BarGraph from "./Charts/BarGraph";

const BookingSummary = (props) => {

    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const defaultParam = {
        FromDate: defaultDates.FirstDate,
        ToDate: defaultDates.LastDate,
        Channel: utility.getValue('BookingSummaryChannels') ?? {}
    }
    const [dataItem, setDataItem] = useState(defaultParam);
    const [isLoading, setIsLoading] = useState(false);
    const dataItemRef = useRef(defaultParam);
    const dataItemRefSetter = (data) => {
        dataItemRef.current = data;
        setDataItem(data);
    };

    const [channel, setChannel] = useState([]);

    const [dashBoardData, setDashBoardData] = useState({
        totalCount: props.bookingSummaryDashboardData.totalCount ?? 0,
        top10Agency: props.bookingSummaryDashboardData.top10Agency ?? 0,
        top10Client: props.bookingSummaryDashboardData.top10Client ?? 0,
        topRevenueByLocation: props.bookingSummaryDashboardData.topRevenueByLocation ?? 0,
        topBookingByDealType: props.bookingSummaryDashboardData.topBookingByDealType ?? 0,
        pendingVsOnAirBooking: props.bookingSummaryDashboardData.pendingVsOnAirBooking ?? 0,
        totalMonthlyRevenue: props.bookingSummaryDashboardData.totalMonthlyRevenue ?? 0,
        top5SalesExecutive: props.bookingSummaryDashboardData.top5SalesExecutive ?? 0,
        bookingScheduleAndPendingCount: props.bookingSummaryDashboardData.bookingScheduleAndPendingCount ?? [],
        salestargetvsachived: props.bookingSummaryDashboardData.salestargetvsachived ?? { data : [] , categories : []},
    })
    const lang = useTranslation();
    const payloadRef = useRef({});

    const buttonStyle = { padding: "0 5px", display: "inline-block", backgroundColor: "white", height: '30px', }

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getValidChannels();
        setChannel(channelRes.data);
        if (channelRes.data.length > 0) {
            dataItemRefSetter({ ...dataItem, Channel: Object.keys(defaultParam.Channel).length > 0 ? defaultParam.Channel : utility.getDefaultItem(channelRes.data) })
            payloadRef.current = {
                channelsid: Object.keys(defaultParam.Channel).length > 0 ? defaultParam.Channel.SID : utility.getDefaultItem(channelRes.data).SID,
                startdate: new Date(dataItemRef.current.FromDate).getTime(),
                enddate: new Date(dataItemRef.current.ToDate).getTime()
            }

            for (const element of Object.keys(props.bookingSummaryDashboardData)) {
                const key = element;
                const value = props.bookingSummaryDashboardData[key];
                if (value.length == 0) {
                    loadData();
                    break;
                }
            }
        }
    }

    const isValid = () => {
        if (!utility.isValidDate(dataItemRef.current.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItemRef.current.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItemRef.current.FromDate > dataItemRef.current.ToDate){
            toast.error(`${lang.from_date_should_be_less_than_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItemRef.current.Channel.length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const OnShow = async () => {

        if (isValid()) {
            payloadRef.current = {
                channelsid: dataItemRef.current.Channel.SID,
                startdate: new Date(dataItemRef.current.FromDate).getTime(),
                enddate: new Date(dataItemRef.current.ToDate).getTime()
            }
            utility.setValue('BookingSummaryChannels', dataItemRef.current.Channel);
            loadData();
            console.log(dataItemRef.current.Channel)
            console.log(dataItemRef.current)
        }
    }
    const loadData = async () => {

        if (payloadRef.current == undefined) {
            return;
        }
        setIsLoading(true);
        let dashboardData = {
            totalCount: [],
            top10Agency: [],
            top10Client: [],
            topRevenueByLocation: [],
            topBookingByDealType: [],
            pendingVsOnAirBooking: [],
            totalMonthlyRevenue: [],
            top5SalesExecutive: [],
            currentDaysScheduleBookingPending: [],
            salestargetvsachived : { data : [] , categories : []} ,
        }
        
        let res = await API.getBookingSummaryDashboardData(payloadRef.current);
        let resSalesTargetvsAchived = await API.getSalesTargetvsAchived(payloadRef.current);

        if(res.success && resSalesTargetvsAchived.success){

            dashboardData.totalCount = res.data.totalAndActiveCount;
            dashboardData.top10Agency = res.data.top10Agencies;
            dashboardData.top10Client = res.data.top10Clients;
            dashboardData.topRevenueByLocation = res.data.topLocations;
            dashboardData.topBookingByDealType = res.data.bookingByDealType;
            dashboardData.pendingVsOnAirBooking = res.data.airedAndPending;
            dashboardData.totalMonthlyRevenue = res.data.revenueByMonth;
            dashboardData.top5SalesExecutive = res.data.top5SalesExecutive;
            dashboardData.bookingScheduleAndPendingCount = res.data.bookingScheduleAndPendingCount;

            //FROM DIFFERENT API
            dashboardData.salestargetvsachived = { data : resSalesTargetvsAchived.data?.data ?? [], categories : resSalesTargetvsAchived.data?.quarter ?? [] }

        } else {
            console.log(res.message)
            toast.error(!res.success ? res.message : resSalesTargetvsAchived.message, {
                position: toast.POSITION.TOP_RIGHT 
            });
            return;
        }

        setDashBoardData(dashboardData);
        props.setBookingSummaryDashboardData(dashboardData);
        setIsLoading(false);

    }

    const onChange = (e) => {
        dataItemRefSetter({ ...dataItem, [e.target.name]: e.target.value });
    }

    const topSalesExecutivelabel = (props) => {
        return `${props.dataItem.name} Total : ${props.dataItem.value}`;
    };

    const topAgencylabel = (props) => {
        return `${props.dataItem.name} Total : ${props.dataItem.value}`;
    };

    // User For Revenue By Location
    let locationTotalsr = [];
    let locationName = [];

    if (dashBoardData.topRevenueByLocation != undefined && dashBoardData.topRevenueByLocation.length > 0) {
        dashBoardData.topRevenueByLocation.map((item) => {
            locationTotalsr.push(item.totalsr);
            locationName.push(item.name);

        })
    }

    // User For Revenue By Months
    let monthTotalsr = [];
    let monthName = [];
    if (dashBoardData.totalMonthlyRevenue != undefined && dashBoardData.totalMonthlyRevenue.length > 0) {
        dashBoardData.totalMonthlyRevenue.map((item) => {
            monthTotalsr.push(item.totalRevenue);
            monthName.push(item.months);

        })
    }

    return (
        <div className="row">
            <div className='col-12'>
                <div className="row form-group">
                    <div className="col-2">
                        <label htmlFor="">{lang.from_date_label} </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="FromDate"
                            value={dataItem.FromDate}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-2">
                        <label htmlFor="">{lang.to_date_label} </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="ToDate"
                            value={dataItem.ToDate}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-4">
                        <label htmlFor="TabView">{lang.channel_label} *</label><br />
                        <DropDownList
                            style={{
                            backgroundColor:"white",
                            }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                            />    
                    </div>
                    <div className="col" style={{ textAlign: "left", marginTop: "20px" }}>
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={OnShow} />
                    </div>
                </div>
                <div className="widget-body ml-2">
                    <div className="row">
                        {/* Client Count */}
                        <div className="summaryDashboard_forCard col-sm-12 col-md-6 col-lg-3">
                            <ActiveDashboardTile
                                tileBackColor="#CA4D27"
                                icon="fa-sharp fa-solid fa-user"
                                totalCount={dashBoardData.totalCount.totalClientCount?? 0}
                                name={lang.client_label}
                                Activecount={dashBoardData.totalCount.totalActiveClientCount ?? 0}
                            />
                        </div>
                        <div className="summaryDashboard_forCard col-sm-12 col-md-6 col-lg-3">
                            <ActiveDashboardTile
                                icon="fa-sharp fa-solid fa-landmark"
                                tileBackColor="#DEB632"
                                totalCount={dashBoardData.totalCount.totalAgencyCount ?? 0}
                                name={lang.agency_label}
                                Activecount={dashBoardData.totalCount.totalActiveAgencyCount ?? 0}
                            />
                        </div>
                        <div className="summaryDashboard_forCard col-sm-12 col-md-6 col-lg-3">
                            <ActiveDashboardTile
                                icon="fa-sharp fa-solid fa-kaaba"
                                tileBackColor="#23A8E8"
                                totalCount={dashBoardData.totalCount.totalCampaignCount ?? 0}
                                name={lang.campaign_label}
                                Activecount={dashBoardData.totalCount.totalActiveCampaignCount ?? 0}
                            />
                        </div>
                        <div className="summaryDashboard_forCard col-sm-12 col-md-6 col-lg-3">
                            <ActiveDashboardTile
                                icon="fa-sharp fa-solid fa-users"
                                tileBackColor="#7458E0"
                                totalCount={dashBoardData.totalCount.totalExecutiveCount ?? 0}
                                name={lang.executive_label}
                                Activecount={dashBoardData.totalCount.totalActiveExecutiveCount ?? 0}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-6">
                        <PieCharts title={lang.top_10_agency_label} root={`/home/Agency`} listData={dashBoardData.top10Agency} label={topAgencylabel} isLoading={isLoading} ></PieCharts>
                    </div>
                    <div className="col-md-6">
                        <DonutChart title={lang.top_10_client_label} root={`/home/Client`} listData={dashBoardData.top10Client} isLoading={isLoading}></DonutChart>
                    </div>                
                </div>
                <div className='row'>
                <div className="col-6">
                        <PieCharts title={lang.top_5_sales_executive_label} root={`/home/users/SalesExecutive`} listData={dashBoardData.top5SalesExecutive} label={topSalesExecutivelabel} isLoading={isLoading} ></PieCharts>
                    </div>
                    <div className="col-md-6">
                        <AreaChart pendingVsOnAirBooking={dashBoardData.pendingVsOnAirBooking} isLoading={isLoading}></AreaChart>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                        <GlobalizationChart name={locationName} totaldata={dashBoardData.topRevenueByLocation} title={lang.revenue_by_location_label} isLoading={isLoading} />
                    </div>
                    <div className="col-md-6">
                        <ColumnChart chartData={dashBoardData.topBookingByDealType} categories = {[]} isLoading={isLoading} title={lang.booking_by_deal_type_label}></ColumnChart>
                    </div>
                   
                </div>

                <div className='row'>
                    <div className="col-md-6">
                        <GlobalizationChart name={monthName} totaldata={monthTotalsr} title={lang.revenue_by_months_label} isLoading={isLoading}/>
                    </div>
                    <div className="col-md-6">
                        <BarGraph chartData={dashBoardData.salestargetvsachived.data} categories = {dashBoardData.salestargetvsachived.categories} isLoading={isLoading} title= {lang.sales_target_achived_label}></BarGraph>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-6'>
                        <div className="panel panel-default" style={{height:"438px", color:"#333", backgroundColor:"#f5f5f5", borderColor:"#ddd"}}>
                            <div className="panel-heading" style={{ borderColor:"1px solid transparent", borderBottom: "1px solid transparent", borderTopRightRadius:"1px" 
                            , borderTopLeftRadius :"1px", textAlignLast:"center"}}>{lang.tomorrow_pending_booking_status_label}
                            </div>
                            { isLoading ? 
                            <Loader height="53.5vh" bgColor="white"/> :
                            <div className="panel-body" style={{height:"390px", display:"block",width:"100%", backgroundColor: 'white'}}>                            
                                <Grid data={dashBoardData.bookingScheduleAndPendingCount} resizable={true} >
                                    <Column field="ChannelName" title={lang.channel_name_column} />
                                    <Column field="total" title={lang.total_column} />
                                    <Column field="schedule" title={lang.schudule_column} />
                                    <Column field="pending" title={lang.pending_colun} />
                                </Grid>
                            </div> }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BookingSummary;
