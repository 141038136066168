/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, SOCKET_EVENTS, SOCKET_ACTION, LOGEVENT, SUBSCRIPTION_STATUS } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import MyStatusCell from "../../components/MyStatusCell";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const DATA_ITEM_KEY = "_id";

export const SubscriberCollection = (props) => {

    //possible props
    let page_take = props.page_take ?? 50;
    let showAdd = props.showAdd ?? true;
    let showBack = props.showBack ?? true;
    let showExport = props.showExport ?? true;
    let showArchive = props.showArchive ?? true;
    let showCommandCell = props.showCommandCell ?? true;
    let showEdit = props.showEdit ?? true;
    let showDelete = props.showDelete ?? true;
    let showTitle = props.showTitle ?? true;
    let gridRowRender = props.gridRowRender;
    let CustomEditForm = props.customEditForm ?? EditForm;
    let filterable = props.filterable ?? false;
    let sortable = props.sortable ?? true;
    let isPageable = props.isPageable ?? true;
    let wherestatement = props.wherestatement;
    let andWhereStatement = props.andWhereStatement;
    let sort = props.sort ?? { SID: 1 };
    let customColumns = props.customColumns ?? [];
    let openNewEditForm = props.openNewEditForm ?? false;
    let myTitle = props.title ?? "";
    let myMenuModel = props.menuModel ?? collectionMenuModel;
    // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
    let entityName = props.entityname;
    let showPreferenceButton = props.showPreferenceButton ?? true;
    let hideArchiveProps = props.hideArchive ?? false;
    let disableMutliSelect = props.disableMutliSelect ?? false;

    //Add this for media management preference column
    const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
    // example custome columns[]
    const [gridData, setGridData] = useState([]);
    const [total, setTotal] = useState(1);
    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState({ SID: 1 });
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [hideArchive, setHideArchive] = useState(hideArchiveProps);
    const [paymentPlans,setPaymentPlans] = useState([]);
    const [filterItem,setFilterItem ] = useState({
        status : { _id : 0, Description : 'All' },
        plan : { _id : 0, Description : 'All' },
    });

    const [selectedState, setSelectedState] = useState({});
    const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
    const [gridSort, setGridSort] = useState([initialSort]);

    const selectedItemsRef = useRef([]);
    const setSelectedItem = (items) => {
        selectedItemsRef.current = items;
    }

    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });

    //only to get wherestatement from href cell
    const location = useLocation();
    const lang = useTranslation();

    let href = location.state ? location.state.href : false;
    if (href) {
        wherestatement = location.state.wherestatement
    }


    useEffect(() => {
        setShowArchived(false);
        setPage({ skip: 0, take: page_take });
        loadCombo();
        loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false,filterItem);
    }, [props.entityname, props.gridData, props.title]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = gridSort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setIsLoading(true);
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,filterItem);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setIsLoading(true);
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,filterItem);
        }
    }, [searchText]);

    const loadCombo = async() => {
        const planRes = await API.getDataLookup(ENTITYNAME.PaymentPlan, { query: ["Archive", '=', false], sort: { SID: 1 } });
        setPaymentPlans(planRes.data);
    }

    const enterEdit = (item) => {
        console.log("onEnterEdit");
        if (openNewEditForm === false) {
            setOpenForm(true);
            setEditItem(item);
        } // need to navigate to full page edit view
        else {
            navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false, item: item } });
        }
    };



    const handleCancelEdit = () => {
        setOpenForm(false);
    };

    const handleArchive = async (dataItem) => {

        var resArchiveUpdated = await API.saveData(entityName, {
            ...dataItem,
            Archive: !dataItem.Archive
        })


        if (resArchiveUpdated.success) {
            console.log(`Archive is been updated to ${!dataItem.Archive}`);
            let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: MODULE.PAGE, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
            API.SaveLogs(logData);
            let sortEvent = gridSort[0] ?? initialSort;
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,filterItem);
            socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
            utility.deleteLocalStorageItem(entityName);
        } else {
            console.log(resArchiveUpdated.message);
        }
    }

    const onCloseUserPrefence = () => {
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,filterItem);
        setShowPreferences(!showPreferences);
    }


    //Edit Row
    const MyCommandCell = (props) => (

        <MenuCommandCell
            {...props}
            //   publish={onPublish}
            //   history={history}
            enterEdit={enterEdit}
            cancelEdit={handleCancelEdit}
            item={editItem}
            dataColumns={dataColumns}
            onArchive={handleArchive}
            showEdit={showEdit}
            showDelete={showDelete}
            myMenuModel={myMenuModel}
            openNewEditForm={openNewEditForm}
        />
    );

    const MyCustomToggleButton = (props) => (
        <MyToggleButton
            {...props}
            entityName={entityName}
            size={"small"}
        />
    );

    const MyDateCell = (props) => <DateOnlyCell {...props} />;
    const MyArrayCell = (props) => <ArrayCell {...props} />;

    const MyTimeCell = (props) => <TimeCell {...props} />;

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const MyImageCell = (props) => <ImageCell {...props} />;

    const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;

    const Mystatuscell = (props) => {

        let tileBackColor = props.dataItem[props.field] == 'Active' ? "#63aa43" : "#E72929";

        return <MyStatusCell title={props.dataItem[props.field]} tileBackColor={tileBackColor} />

    }

    const MyPackageCell = (props) => {

        let activePacks = 'No Active Pack';
        if (props.dataItem?.ActivePlan && props.dataItem?.ActivePlan.length > 0) {
            activePacks = props.dataItem?.ActivePlan.map((x) => x.Description).join(',')
        }

        return props.dataItem?.ActivePlan.length > 0 ? <MyStatusCell title={activePacks} tileBackColor={'#007bff'} /> :
            <MyStatusCell title={activePacks} tileBackColor={'#ffffff'} titleColor={'#000000'} />

    }

    const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, filter) => {

        console.log(filter)

        console.log('here')

        if (props.gridColumns) {
            setDataColumns(props.gridColumns);
            setGridData(props.gridData);
            setIsLoading(false);
        } else {
            try {
                let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
                let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
                let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
                let filters = { plan : filter?.plan?._id ?? 0, status : filter?.status?.Description ?? 'All' }
                let json = await API.getSubscribers(
                    { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
                    columns,
                    searchText,
                    filters
                );

                console.log(json.data)
                setGridData(json.data);
                setTotal(json.pagination.total);
                //if custmoe colum is coming from compemnent level then no need  a column from Server API
                customColumns.length > 0
                    ? setDataColumns(customColumns)
                    : setDataColumns(columns);

                // console.log(columns)
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
                setIsLoading(false);
                setIsNoRecords(true);
            }
        }
    };

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = gridSort[0] ?? initialSort;

        await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,filterItem);

        setPage(e.page);
    };

    const onArchiveClick = () => {
        setShowArchived(!showArchived);
        let sortEvent = gridSort[0] ?? initialSort;
        if (!showArchived) {
            loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true, filterItem);
        } else {
            let current_page = page.skip / page.take + 1;
            loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false, filterItem);
        }
    }

    const refreshCollection = () => {
        setIsLoading(true);
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived, filterItem);
        setGridSort([initialSort]);
    }

    const onSortChange = (e) => {
        setGridSort(e.sort)
        let sortEvent = e.sort[0] ?? sort;
        let current_page = page.skip / page.take + 1;
        loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterItem);
    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API.exportGridExcel(gridData, dataColumns);
        console.log(res.data);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const onArchiveButtonClick = async () => {
        if (selectedItemsRef.current.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        for (const element of selectedItemsRef.current) {
            const item = element;
            await handleArchive(item);
        }
    }

    const onEnterEdit = () => {
        enterEdit({ SID: 0 });
    }

    const handleFiltersChange = (e) => {
        
        let sortEvent = gridSort[0] ?? initialSort;
        loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived,{...filterItem,[e.target.name] : e.target.value});
        setFilterItem({...filterItem,[e.target.name] : e.target.value});
        setPage({ skip: 0, take: page_take });
    }




    return (
        <div>
            {showTitle && (
                <>
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{myTitle === "" ? props.entityname : myTitle}</span>
                    </h1>
                </>
            )}
            <div className="flex-container">
                <div className="martb mar">
                    {showBack && (
                        <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                    )}

                    {showAdd && (
                        <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
                    )}
                </div>


                <div className="flex-container-reverse flex-item-auto">

                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                    {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
                        <PreferenceRoundButton
                            icon={"gear"}
                            title={lang.preference_button_tooltip}
                            onClick={() => { setShowPreferences(!showPreferences) }}
                        />
                    </div>}
                    <div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RefreshButton
                            onClick={refreshCollection}
                        />
                    </div>
                    {showArchive && disableMutliSelect && (<div className="mt-1" style={{ paddingTop: "12px" }}>
                        <RoundButton
                            icon={"inbox"}
                            // style={{ margin: "0px 03px" }}
                            // iconColor={"white"}
                            title={showArchived ? lang.un_archive_label : lang.archive}
                            onClick={onArchiveButtonClick}
                        ></RoundButton></div>
                    )}

                    {showExport && (
                        <div className="m-1" style={{ paddingTop: "12px" }}>
                            <RoundButton
                                icon={"download"}
                                btnColor={"info"}
                                style={{ color: 'white' }}
                                // iconColor={"white"}
                                title={lang.export_button_tooltip}
                                onClick={downloadExcel}
                            /></div>
                    )}

                    {!hideArchive && <div className="d-inline mt-3 mr-2">
                        <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
                    </div>}
                    
                    <div className="d-inline mr-2 col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="status">{lang.status_label}</label>
                        <DropDownList
                        data={[{ _id: 0, Description: 'All' },...Object.keys(SUBSCRIPTION_STATUS).map((key) => { return { _id: SUBSCRIPTION_STATUS[key], Description: key } })]}
                        style={{ backgroundColor: 'white' }}
                        name="status"
                        textField="Description"
                        dataItemKey="_id"
                        value={filterItem.status}
                        onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                    <div className="d-inline col-2" style={{ marginTop: '-9px' }}>
                        <label htmlFor="Plan">Plan</label>
                        <DropDownList
                        data={[{ _id : 0, Description : 'All' },...paymentPlans]}
                        style={{
                            backgroundColor: 'white',
                        }}
                        name="plan"
                        textField="Description"
                        dataItemKey="_id"
                        value={filterItem.plan}
                        onChange={(e) => handleFiltersChange(e)}
                        />
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}

            {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
            {!isLoading && !isNoRecords &&
                <Grid
                    style={props.gridstyle ?? {
                        height: "76vh",
                    }}
                    sort={gridSort}
                    sortable={sortable}
                    onSortChange={onSortChange}
                    rowRender={gridRowRender}
                    filterable={filterable}
                    pageable={isPageable}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    data={gridData}
                    dataItemKey={DATA_ITEM_KEY}
                >

                    {/* Edit Row */}
                    {showCommandCell && (
                        <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
                    )}

                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        }
                        else if (column.label === "Package") {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyPackageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.type === COLUMNSTYPE.datetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyTimeCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                            //we are not returning any value for SID column
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyImageCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.toggle) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyCustomToggleButton}
                                    title={column.label}
                                    width={85}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.checkbox) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyCheckBoxCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyArrayCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.status) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={Mystatuscell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.icon) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={IconCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                    sortable={false}
                                />
                            );
                        }
                        else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}

            {openForm && (
                <CustomEditForm
                    {...props} //to get other props from customEditForm
                    cancelEdit={handleCancelEdit}
                    onSubmit={() => { }}
                    refresh={() => loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived)} //to refresh the collection when data added
                    item={editItem}
                    dataColumns={dataColumns}
                />
            )}

            {showPreferences && <UserPreference entityName={ENTITYNAME.Subscriber} preferenceColumnENntityName={ENTITYNAME.Subscriber} handleClose={onCloseUserPrefence} />}
        </div>
    );
};
