/* eslint-disable */
import * as React from "react";
import { ConfirmDeleteAlert } from "../../../ConfirmAlert";
import CancelRoundButton from "../../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import DeleteRoundButton from "../../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import EditRoundButton from "../../../framework/forms/helpercomponents/buttons/EditRoundButton";
import UpdateRoundButton from "../../../framework/forms/helpercomponents/buttons/UpdateRoundButton";
import { useTranslation } from "../../../locale/useTranslation";

const UpdateBookingLineNumberCommandCell = (props) => {
    const lang=useTranslation();
    const {
      dataItem
    } = props;
    const inEdit = dataItem[props.editField];
    const hideDelete = props.hideDelete??false;
    return inEdit ? <td style={props.style} className={props.className} colSpan={props.colSpan} role={"gridcell"}>
      <UpdateRoundButton onClick={() =>  props.onUpdate(dataItem)}/> 
      <CancelRoundButton onClick={() =>  props.onCancel(dataItem)}/> 
      </td> : <td style={props.style} className={props.className} colSpan={props.colSpan} role={"gridcell"}>
        <EditRoundButton onClick={() => props.onEdit(dataItem)}/> 
        {!hideDelete && <DeleteRoundButton onClick={()=>props.onRemove(dataItem)}/>}
      </td>;
  };

  export {UpdateBookingLineNumberCommandCell};