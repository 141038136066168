/* eslint-disable */
import RailComponent from "./RailComponent";
import TextComponent from "./TextComponent";
import { RAILTYPE } from '../../../../../framework/constant/constant'
import HeroBanner from "./HeroBanner";
import Logo from './Logo'
import { useState, useEffect } from "react";
import { SingleCard } from "./SingleCard";



import SanityMobilePreview from 'sanity-mobile-preview'
import 'sanity-mobile-preview/dist/index.css?raw'
import { Link } from "react-router-dom";
import { useTranslation } from "../../../../../locale/useTranslation";
import Grid from "./Grid";
import FullPageGrid from "./FullPageGrid";
import CircleRailComponent from "./CircleRailCompoent";
import CircleRailComponentTv from "./CircleRailCompoentTv";
import BottomMenu from "./bottomMenu";
import TopMenu from "./topMenu";
import HeroBannerPotrait from "./HeroBannerPotrait";

const Page = (props) => {

    const { page, isMobile, ottSetting, bottomMenu, changePage } = props;
    // console.log(isMobile);
    // console.log(page);
    // console.log(ottSetting);

    const statusLogoAsset = { PotraitImageUrl: ottSetting?.logo, logoPosition: ottSetting?.logoPostion }

    const lang = useTranslation();

    function getRail(rail) {

        if (rail.PlatformSIDs.includes('1') && isMobile) {
            // console.log(rail);
            switch (parseInt(rail.OttRailTypeSID)) {
                case RAILTYPE.Logo:
                    return <Logo key={1} asset={rail.OttAsset[0]} isMobile={isMobile} />
                case RAILTYPE.TEXT:
                    return <TextComponent key={2} label={rail.DisplayTitle} />
                case RAILTYPE.HeroBanner:
                    return <HeroBanner key={3} rail={rail} isMobile={true} />
                case RAILTYPE.HeroBannerPotrait:
                    return <HeroBannerPotrait key={8} rail={rail} />
                case RAILTYPE.Rail:
                    return <RailComponent key={4} rail={rail} isMobile={true} />
                case RAILTYPE.SingleCard:
                    return <SingleCard key={5} rail={rail} isMobile={true} />
                case RAILTYPE.GridRailTwoColumn:
                    return <Grid columns={2} key={6} rail={rail} isMobile={true} />
                case RAILTYPE.GridRailThreeColumn:
                    return <Grid columns={3} key={7} rail={rail} isMobile={true} />
                case RAILTYPE.GridRailFourColumn:
                    return <Grid columns={4} key={8} rail={rail} isMobile={true} />
                case RAILTYPE.FullPageGrid2:
                    return <FullPageGrid columns={2} key={9} rail={rail} isMobile={true} />
                case RAILTYPE.FullPageGrid3:
                    return <FullPageGrid columns={3} key={10} rail={rail} isMobile={true} />
                case RAILTYPE.FullPageGrid4:
                    return <FullPageGrid columns={4} key={11} rail={rail} isMobile={true} />
                case RAILTYPE.CircleRail:
                    return <CircleRailComponent columns={4} key={12} rail={rail} isMobile={true} />
                default:
                    return <RailComponent key={4} rail={rail} isMobile={true} />
            }
        } else if (rail.PlatformSIDs.includes('2') && !isMobile) {
            switch (parseInt(rail.OttRailTypeSID)) {
                case RAILTYPE.Logo:
                    return <Logo key={6} asset={rail.OttAsset[0]} isMobile={isMobile} />
                case RAILTYPE.TEXT:
                    return <TextComponent key={7} label={rail.DisplayTitle} />
                case RAILTYPE.HeroBanner:
                    return <HeroBanner key={8} rail={rail} />
                case RAILTYPE.HeroBannerPotrait:
                    return <HeroBannerPotrait key={8} rail={rail} />
                case RAILTYPE.Rail:
                case RAILTYPE.PotraitRail:
                case RAILTYPE.LandscapeRail:
                case RAILTYPE.SquareRail:
                    return <RailComponent key={9} rail={rail} />
                case RAILTYPE.TopCounter:
                    return <RailComponent key={10} rail={rail} showCounter={true} />
                case RAILTYPE.SingleCard:
                    return <SingleCard key={10} rail={rail} />
                case RAILTYPE.GridRailTwoColumn:
                    return <Grid columns={2} key={11} rail={rail} />
                case RAILTYPE.GridRailThreeColumn:
                    return <Grid columns={3} key={12} rail={rail} />
                case RAILTYPE.GridRailFourColumn:
                    return <Grid columns={4} key={13} rail={rail} />
                case RAILTYPE.FullPageGrid2:
                    return <FullPageGrid columns={2} key={14} rail={rail} />
                case RAILTYPE.FullPageGrid3:
                    return <FullPageGrid columns={3} key={15} rail={rail} />
                case RAILTYPE.FullPageGrid4:
                    return <FullPageGrid columns={4} key={16} rail={rail} />
                case RAILTYPE.CircleRail:
                    return <CircleRailComponentTv columns={4} key={17} rail={rail} isMobile={true} />
                default:
                    return <h1 className="text-center display-4">{lang.rail_will_be_added_soon3_label + rail.OttRailTypeSID}</h1>
            }
        }
    }

    return <div style={{ backgroundColor: props?.bgColor ?? "black", color: "white", margin: "-10px", marginTop: "-35px", overflowX: "hidden", paddingBottom: "50px" }}>{
        isMobile && page
            ?
            <div className="mobilePosition" style={{ height: '50%', scale: '0.7', marginTop: "40px" }} >
                <SanityMobilePreview preSelectedDevice="note8">
                    <iframe src={`https://webos.bossstudio.tv/#/mobile?pageId=${page?._id}`} style={{ width: "100%", height: "100%" }}></iframe>
                    {/* <div className="style-4" style={{ height: "100%", overflow: "auto", userSelect: "none", background: "black", color: "white" }}>
                        {
                            <Logo key={1} asset={statusLogoAsset} isMobile={isMobile} />
                        }
                        {

                            page?.Rails?.map((rail, index) => {
                                return <div key={index}>
                                    {getRail(rail)}
                                </div>
                            })
                        }
                        {ottSetting.showBottomMenu && <TopMenu ottSetting={ottSetting} />}
                        {ottSetting.showBottomMenu && <BottomMenu bottomMenu={bottomMenu} ottSetting={ottSetting} changePage={changePage} />}
                    </div> */}
                </SanityMobilePreview >
            </div>
            :
            <div >
                <iframe src={`https://webos.bossstudio.tv?pageId=${page?._id}`} style={{ width: "100%", height: "calc(100vh - 58px)" }}></iframe>
                {/* {
                    <Logo key={1} asset={statusLogoAsset} isMobile={isMobile} />
                }
                {
                    page?.Rails?.map((rail, index) => {
                        return <div key={index} style={{ overflow: "hidden" }}>
                            {getRail(rail)}
                        </div>
                    })
                } */}
            </div>
    }
    </div>

}
export default Page;