/* eslint-disable */
import React, { useEffect, useState } from "react";
import BossDialog from "../../components/BossDialog";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import { utility } from "../../framework/utility/utilityProvider";

const MediaCategoryTypeEditForm = (props) => {

    const [mediaCategory, setMediaCategory] = useState([]);

    const [dataItem, setDataItem] = useState({
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        MediaCategory: props.item.MediaCategorySID ?? {},
        MaterialType: props.item.MaterialType ?? "",
        AutomationType: props.item.AutomationType ?? "",
        CanCreateDeal: props.item.CanCreateDeal ?? false,
        Archive: props.item.Archive ?? false
    });

    const lang = useTranslation();


    useEffect(() => {
        loadcombo();
    }, [])


    const loadcombo = async () => {
        var mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory);
        setMediaCategory(mediaCategoryRes?.data);
        setDataItem({ ...dataItem, MediaCategory: mediaCategoryRes.data?.find(obj => obj.SID == props.item?.MediaCategorySID) })
    }

    const isValid = () => {
        if (!dataItem.MediaCategory || Object.keys(dataItem.MediaCategory).length === 0) {
            toast.error(`${lang.please_select_media_category_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Description === '' || dataItem.Description === undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }


    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                SID: props.item.SID ?? 0,
                Description: dataItem.Description ?? '',
                MediaCategorySID: dataItem.MediaCategory.SID,
                MaterialType: dataItem.MaterialType,
                CanCreateDeal: dataItem.CanCreateDeal ?? false,
                Archive: dataItem.Archive ?? false,
                AutomationType: dataItem.AutomationType
            }
            var res = await API.saveData(ENTITYNAME.MediaCategoryType, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.MEDIACATEGORYTYPE, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.refresh();
                props.cancelEdit();
                utility.deleteLocalStorageItem(ENTITYNAME.MediaCategoryType);
                return;
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    return (
        <>
            <BossDialog
                title={props.item.SID > 0 ? props.item.Description : lang.media_category_type_label}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <Form
                    render={() => (
                        <FormElement>
                            <div className="row">
                                <div className="col-12">

                                    <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={props.cancelEdit} />
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label>{lang.media_category_label} *</label>
                                            <DropDownList
                                                data={mediaCategory}
                                                name="MediaCategory"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.MediaCategory}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>{lang.description_label} *</label>
                                            <input
                                                name={"Description"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.Description}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <label>{lang.material_type_label}</label>
                                            <input
                                                name={"MaterialType"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.MaterialType}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label>{lang.automation_type_label}</label>
                                            <input
                                                name={"AutomationType"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.AutomationType}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col mt-3">
                                            <Checkbox
                                                name={"CanCreateDeal"}
                                                style={{ marginLeft: "5px", marginBottom: "20px" }}
                                                value={dataItem.CanCreateDeal}
                                                onChange={onChange}
                                            />
                                            <label className="pl-1">{lang.cancreatedeal_label}</label>

                                        </div>
                                        <div className="col mt-3">
                                            <Checkbox
                                                name={"Archive"}
                                                style={{ marginLeft: "5px", marginBottom: "20px" }}
                                                value={dataItem.Archive}
                                                onChange={onChange}
                                            />
                                            <label className="pl-1">{lang.archive}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>)
};

export default MediaCategoryTypeEditForm;