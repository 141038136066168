/* eslint-disable */

import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

//to show item stored in array on collection
export const WrappedTextCell = (props) => {

    const field = props.field || "";
    var str = props.dataItem[field];

    if(field.includes('.')){
      var [key1,key2] = field.split('.');
      str = props.dataItem[key1] == undefined ? '' : props.dataItem[key1][key2];
    }

    return <td colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
     style={{textAlign : 'center' ,whiteSpace : 'nowrap'}}>
      { str != undefined && str.length > 32  ? str.slice(0, 32-1) + '...' : str}
    </td>;
  };