//to show duration in string format on collection
import { utility } from "../../../utility/utilityProvider";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const TimeCell = (props) => {

  const field = props.field || "";
  let value = ''


  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    let keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? utility.convertMilisecondsToStringWithFrames(props.dataItem[keys[0]][keys[1]]) : '' 
    : '';
  }else{
    value = utility.convertMilisecondsToStringWithFrames(props.dataItem[field]);
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...useTableKeyboardNavigation(props.id)}
    //BY COMMENTING WITH LINE FOR ONCLICK EVENT SELECTION FOR GRID BY CLICKING TIME CELL IS NOT WORKING
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    // style={{textAlign : 'center'}}
    style={props.style}
    >
    {value}
  </td>;
};

export const TimeHyperLinkCell = (props) => {

  const field = props.field || "";
  let value = ''

  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    let keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? utility.convertMilisecondsToStringWithFrames(props.dataItem[keys[0]][keys[1]]) : '' 
    : '';
  }else{
    value = utility.convertMilisecondsToStringWithFrames(props.dataItem[field]);
  }

  const goToSchedule = (e) => {
    console.log(props.dataItem)
    props.onClick(props.dataItem)
  }

  return <td
    onClick={goToSchedule}
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}
    className={"timecell"}
    >
    {value}
  </td>;
};

export const ShortTimeCell = (props) => {

  const field = props.field || "";
  var value = ''

  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    var keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? utility.convertMilisecondsToShortTimeString(props.dataItem[keys[0]][keys[1]]) : ''
    : '';
  }else{
    value = utility.convertMilisecondsToShortTimeString(props.dataItem[field]);
  }

  // const value = moment(props.dataItem[field]).utc().format('DD-MMM-YYYY');

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}
    >
    {value}
  </td>;
};

export const TimeCellWithOutFrames = (props) => {

  const field = props.field || "";
  var value = ''

  if(field.includes('.')){
    //For field value coming as example :- MediaCategory.Duration
    var keys = field.split('.');
    value = props.dataItem[keys[0]] ?
      props.dataItem[keys[0]][keys[1]] ? utility.convertMilisecondsToStringWithFrames(props.dataItem[keys[0]][keys[1]],false) : ''
    : '';
  }else{
    value = utility.convertMilisecondsToStringWithFrames(props.dataItem[field],false);
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    // style={{textAlign : 'center'}}
    style={props.style}
    >
    {value}
  </td>;
};