
import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, MEDIACATEGORIES } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const PlanningEditDummyPopup = (props) => {

    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({})

    
    useEffect(() => {
        setDataItem({ Title : props.selectedPlanningItem.customTitle , Duration : utility.convertMilisecondsToStringWithFrames(props.selectedPlanningItem.customDuration)})
        loadcombo()
    }, [])

    
    const loadcombo = async () => {
        console.log(props.selectedPlanningItem)
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Title == undefined || dataItem.Title.trim() == "") {
            toast.error(`${lang.please_enter_title_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Duration == undefined || dataItem.Duration.includes('_') || utility.convertStringWithFramesToMilliseconds(dataItem.Duration) == 0) {
            toast.error(`${lang.duration_should_be_greater_than_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;

    }

    const onEditDummyPlanning = async () => {

        if (!isValid()) return;

        let updatedData = {
            SID: props.selectedPlanningItem.SID,
            _id: props.selectedPlanningItem._id,
            SlotDateTime : props.selectedPlanningItem.SlotDateTime,
            Segment_id : props.selectedPlanningItem.Segment_id,
            NominalDuration : props.selectedPlanningItem.NominalDuration,
            ActualDuration : props.selectedPlanningItem.ActualDuration,
            customTitle : dataItem.Title,
            customDuration : utility.convertStringWithFramesToMilliseconds(dataItem.Duration),
        }
    
        //to edit in ProgramScheduleTable
        var res = await API.saveData(ENTITYNAME.ProgramSchedule, updatedData);
        if(res.success){
            props.onUpdateFromPlanningEditForm(updatedData)
            props.onClose();
        
        }else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const onClose = () => {
        props.onClose();
        setDataItem({ Title: '', Duration: '00:00:00:00' })
    }


    return (
        <>
            <BossDialog
                title={lang.edit_dummy_actionbutton_menutext}
                onClose={onClose}
                width={'500px'}
            >
                <EditPageHeader saveButtonTitle={'Edit'} title={""} onSubmit={onEditDummyPlanning} onCancel={onClose} showTitle={false} />
                <div className="row mt-1">
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.title_label} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="text" name="Title" onChange={onChange} value={dataItem.Title} />

                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.duration_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Duration"
                            value={dataItem.Duration}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </div>

                </div>
            </BossDialog>
        </>

    )
}

export default PlanningEditDummyPopup