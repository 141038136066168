import * as React from "react";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from "../../locale/useTranslation";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
const ContractCommandCell = (props) => {

  const lang=useTranslation();
  const {
    dataItem
  } = props;
  const inEdit = dataItem[props.editField];
  return inEdit ? <td className="k-command-cell">
    </td> : <td className="k-command-cell">
      {props.dataItem.PlatformType?.SID == 1 && <RoundButton icon={'bandcamp'} title={lang.edit_black_out_date_button_tooltip} onClick={() => props?.onEditBlackOutDate(dataItem)}/> }
      <EditRoundButton onClick={() => props?.onEditContractTitle(dataItem)}/> 
      <DeleteRoundButton onClick={() =>  ConfirmDeleteAlert(()=>{props?.onRemoveContractTitle(dataItem)},()=>{})}/> 
    </td>;
};

const DropDownCell = props => {

  const handleChange = e => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  };
  const {
    dataItem
  } = props;
  const field = props.field || '';
  const dataValue = dataItem[field] === null ? '' : dataItem[field];
  return <td>
        {dataItem.inEdit ? <DropDownList style={{
      width: "100px"
    }} onChange={handleChange} value={props.data?.find(c => c?.Description === dataValue?.Description)} data={props?.data} textField="Description" /> : dataValue?.Description}
      </td>;
};

export {ContractCommandCell, DropDownCell};