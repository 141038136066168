/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const editField = "inEdit";

const BookingTransfer = () => {
    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified))
    const [dataItem, setDataItem] = useState({});
    const [channel, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [filter, setFilter] = useState({
        logic: "and",
        filters: []
    });
    const lang = useTranslation();
    let bookingsOutOfCampaignDateRange = [];

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let channel = await API.getDataLookup(ENTITYNAME.Channel);
        setChannel(channel.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const loaddata = async () => {
        let data = {
            Channel: dataItem.Channel,
            FromDate: new Date(dataItem.FromDate).getTime()
        }
        if (data.Channel == undefined) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (dataItem.FromDate == undefined) {
            toast.error(`${lang.please_select_transfer_from_date_booking_transfer_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let Payload =
        {
            "channelSID": data.Channel.SID,
            "BookingDate": data.FromDate,

        }
        let res = await API.loadBookingData(Payload);
        // console.log(res);
        setSelectedItem([]);
        setGridData(res.data);


    }


    const isValidToUpdate = async () => {
        //CHECKING FOR SCHEDULED SPOTS IF ANY

        let scheduledSpots = selectedItem.filter((val) => {
            // return val.NominalOnAir != undefined && val.NominalOnAir > 0;
            // console.log(val.NominalOnAir);
            return val.NominalOnAir != 0;
        });
        //   console.log(scheduledSpots);

        return scheduledSpots.length > 0 ? false : true;

    };

    const updateBooking = async (transferDate, bookings) => {


        let maxLineNo = await GetFreshMaxLineNo(bookings[0].Campaign_Id);

        bookings.map(async (object) => {

            if (transferDate <= object.campaign[0].PeriodTo) {
                console.log(transferDate > object.campaign[0].PeriodTo);
                let res = await API.saveData(ENTITYNAME.Booking, {
                    ...object,
                    BookingDate: transferDate,
                    LineNumber: maxLineNo
                });
                if (!res.success) {
                    //   toast.error(res.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //   });
                    console.log(res.message)
                    return;
                }
            } else {
                console.log("Out of campaign date period");

                bookingsOutOfCampaignDateRange.push(object);
            }

        });
    }

    const transferToDate = async () => {

        let _isValidToUpdate = await isValidToUpdate();
        if (!_isValidToUpdate) {
            toast.error(`${selectedItem.length > 1 ? lang.booked_spots_scheduled_error_message : lang.booking_is_used_in_schedule_show_spot_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        // console.log(selectedItem);
        let transferDate = new Date(dataItem.transferDate).getTime();
        if (dataItem.transferDate == undefined) {
            toast.error(`${lang.please_select_transfer_date_booking_transfer_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (selectedItem.length == 0) {
            toast.error(`${lang.please_select_item_booking_transfer_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (dataItem.FromDate == dataItem.transferDate) {
            toast.error(`${lang.transfer_from_and_transfer_to_are_same_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (dataItem.FromDate > dataItem.transferDate) {
            toast.error(`${lang.booking_transfer_from_date_greater_than_transfer_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }


        let tempUpdated = [];
        let ScheduledBookings = [];

        // STEP 1 - GET UNIQUE CAMPAIGN ID
        const uniqueCampaignId = Array.from(new Set(selectedItem.map((item) => item.Campaign_Id)));

        // STEP 2 - LOOPING THROUGH EACH CAMPAIGN_ID TO GET BOOKINGS OF EACH CAMPAIGN
        for (const campaign_Id of uniqueCampaignId) {

            // STEP 3 - FILTERING UNIQUE BOOKINGS FOR EACH CAMPAIGN_ID
            let campaignWiseBooking = selectedItem.filter((booking) => booking.Campaign_Id == campaign_Id);

            //  STEP 4 - GETTING UNIQUE LINE NO FROM BOOKINGS FILTERED BASED ON CAMPAIGN_ID
            const campaignWiseUniqueLineNo = Array.from(new Set(campaignWiseBooking.map((item) => item.LineNumber)));
            console.log(campaignWiseUniqueLineNo);

            //  STEP 5 - LOOPING THROUGH EACH UNIQU LINE TO GET ALL BOOKINGS EACH LINE NO
            for (const lineNo of campaignWiseUniqueLineNo) {
                //  STEP 6 - GETTING FRESH MAX LINE NO FOR EACH LINE NO
                //var maxLineNo =await  GetFreshMaxLineNo(campaign_Id);
                //  console.log(lineNo);
                //  console.log(maxLineNo);

                // STEP - 6 FILLTERING BOOKING BASED ON EACH UNIQUE LINE NO
                let bookingsWithUniqueLineNo = campaignWiseBooking.filter((booking) => booking.LineNumber == lineNo);

                await updateBooking(transferDate, bookingsWithUniqueLineNo);

                let logCapture = bookingsWithUniqueLineNo.map((x) => {
                    let newObj = {
                        ...x,
                        transferDate: transferDate
                    }
                    delete newObj.campaign;
                    return newObj;
                });

                API.SaveLogs({ event: LOGEVENT.BOOKING_TRANSFER, module: MODULE.BOOKING_TRANSFER, data: logCapture, message: "Booking Transfer" });
        
                
            }
        }

 


        // selectedItem.map((object) => {
        //     if (object.NominalOnAir == null || object.NominalOnAir == '' || object.NominalOnAir == 0) {
        //         var updatedData = {
        //             ...object,
        //             BookingDate: transferDate
        //         }
        //         tempUpdated.push(updatedData);

        //         var setData = selectedItem.filter((obj) => obj.tempUpdated == dataItem.transferDate);
        //         setGridData(setData);
        //     }
        //     else {
        //         ScheduledBookings.push(object);
        //     }
        // })

        // var res = await API.saveData(ENTITYNAME.Booking, tempUpdated);
        // if (res.success) {
        //     loaddata();
        //     toast.success(`${lang.booking_transfer_succesfully_to_booking_transfer_error_message} ` + dataItem.transferDate, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return;
        // } else {
        //     console.log(res.message);
        // }

        // if (ScheduledBookings.length > 0) {
        //     toast.info(`${lang.some_bookings_are_already_Scheduled_Could_not_transfer_error_message}`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        // }


        if (bookingsOutOfCampaignDateRange.length > 0) {
            toast.info(`${lang.some_bookings_are_out_of_campaign_date_range_could_not_transfer_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }


        loaddata();

    }

    const GetFreshMaxLineNo = async (campaign_Id) => {

        let lineNo = 0;
        let resBooking = await API.getData(ENTITYNAME.Booking, { query: ['Campaign_Id', '=', campaign_Id] });

        if (resBooking.data.length > 0) {
            // sorting in descending order by line no to get highest line no
            resBooking.data.sort((a, b) => parseInt(b.LineNumber) - parseInt(a.LineNumber));
            lineNo = resBooking.data[0].LineNumber;

        }
        return lineNo + 1;
    }

    const itemChange = (event) => {

        // to check isHeaderSelected and add dataItem in array
        if (!event.dataItem[SELECTED_FIELD]) {
            setSelectedItem([...selectedItem, event.dataItem]);
        } else {
            var remove = selectedItem.filter((obj) => obj._id != event.dataItem._id);
            setSelectedItem(remove);
        }
        const field = event.field || "";
        const newData = gridData.map((item) =>
            item._id === event.dataItem._id
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setGridData(newData);
    };

    const gridRowRender = (tr, props) => {
        const trProps = {
            onContextMenu: (e) => {
                ref.current = props.dataItem;
                cm.current.show(e);
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    return <>
        <div className="row" >
            <div className="col-12">
                <div className="row" style={{ height: "05vh", fontSize: "10px", margin: "2px" }}>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="">{lang.transfer_from_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="FromDate"
                                value={dataItem.FromDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-2" style={{ marginTop: "20px" }}>
                        <ActionButton btnColor={'success'} title={lang.load_button_tooltip} name={lang.load_button_text} onClick={loaddata} />
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="">{lang.transfer_to_label} *</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="transferDate"
                                value={dataItem.transferDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-1" style={{ marginTop: "20px", textAlign: "right" }}>
                        <ActionButton title={lang.transfer_button_tooltip} name={lang.transfer_button_text} onClick={() => transferToDate()} />
                    </div>
                </div>
            </div>
        </div>

        <Grid
            data={filterBy(gridData, filter)}
            editField={editField}
            selectedField={SELECTED_FIELD}
            selectable={{
                enabled: true,
                mode: "multiple",
            }}

            dataItemKey={DATA_ITEM_KEY}
            onItemChange={itemChange}
            rowRender={gridRowRender}

            filterable={true}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}

            style={{ margin: "28px 20px 20px 20px", border: '1px solid black', borderRadius: '5px!important', height: "78vh", width: "83.7vw", float: "left" }}>
            {/* add checkbox here */}
            <Column field={SELECTED_FIELD} width="40px" cell={CheckboxCell} filterable={false} editor='boolean' />
            <Column field="MediaEpisode.AssetId" title={lang.assetId_column} width="130px" />
            <Column field="MediaEpisode.Title" title={lang.title_column} width="160px" />
            <Column field="campaign.0.CampaignNo" title={lang.campaign_no_column} width="220px" />
            <Column field="BookingDate" title={lang.booking_date_column} cell={DateOnlyCell} filter="date" format="{0:d}" width="160px" />
            <Column field="CampaignType.Description" title={lang.description_column} width="120px" />
            <Column field="DealType.Description" title={lang.deal_type_column} width="120px" />
            <Column field="Channel.FullChannelName" title={lang.channel_name_column} width="120px" />
            <Column field="TimeRangeFrom" title={lang.time_range_from_column} cell={TimeCell} filterable={false} width="130px" />
            <Column field="TimeRangeTo" title={lang.time_range_to_column} cell={TimeCell} filterable={false} width="130px" />
            <Column field="SpotRate" title={lang.spot_rate_column} width="120px" />
        </Grid>

        <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>
            <div className="row m-2">
                <div className="col-6" >
                    <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}>
                        <div title="Copy Count" className="iconBase2" >
                            <span style={{ color: "white", fontWeight: "400", fontSize: "12px", }}>{selectedItem.length}&nbsp;{lang.selected_label}</span>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}




export default BookingTransfer;