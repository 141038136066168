import React, { useEffect, useRef, useState } from 'react';
import { getMyPlansWithAllPlans, getRazorpayKeyID, razorpayCreateOrder, updateTransactionHistory, verifyPayment } from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { DIGITALSIGN_TRANSACTION_STATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, SCREEN_RANGE_AND_PRICE } from '../../framework/constant/constant';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import { getData } from '../../framework/API/api';
import moment from 'moment';
import * as API from '../../framework/API/api_digitalSign'
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';
import { Divider, Slider } from '@mui/material';

const MyPlansPopup = ({ onClose }) => {

    const [loading, setLoading] = useState(false);
    const [myPlans, setMyPlans] = useState([]);
    const [userData, setUserData] = useState(null);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [showPurchasePlan, setShowPurchasePlan] = useState(false);
    const [screens, setScreens] = useState(5);
    const [isModified, setIsModified] = useState(false);

    const isYearlyRef = useRef(false);
    const [isYearly, setIsYearlySelected] = useState(false);
    const [isMonthly, setIsMonthlySelected] = useState(false);
    const setIsYearly = (isYearly) => {
        setIsYearlySelected(isYearly);
        isYearlyRef.current = isYearly;
    }


    const [dataItem, setDataItem] = useState({
        screens: 5,
        storage: 5 * 1024, // for mb in db
        ogPerScreen: SCREEN_RANGE_AND_PRICE[0].price,
        perScreen: SCREEN_RANGE_AND_PRICE[0].price,
        ogPrice: SCREEN_RANGE_AND_PRICE[0].price * 5,
        price: SCREEN_RANGE_AND_PRICE[0].price * 5,
        tenure: 30,
    })

    const setBillingPlan = (screens) => {

        setScreens(screens);

        const pricePerScreen = getPriceByRange(screens);
        const discountedPrice = Math.round(isYearlyRef.current ? pricePerScreen - (pricePerScreen * 0.1) : pricePerScreen).toFixed(2);
        setDataItem((old) => ({
            ...old,
            screens: screens,
            storage: screens * 1024,
            ogPerScreen: pricePerScreen,
            perScreen: discountedPrice,
            ogPrice: (isYearlyRef.current ? (pricePerScreen * screens * 12) : pricePerScreen * screens),
            price: (isYearlyRef.current ? (discountedPrice * screens * 12) : discountedPrice * screens),
            tenure: isYearlyRef.current ? 365 : 30
        }))
    }



    const lang = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        setUserData(userData);
        let res = await getMyPlansWithAllPlans();
        if (!res.success) {
            toast.error(res.message);
        } else {
            setMyPlans(res.data?.myPlans ?? []);
        }
        setLoading(false);
    }

    const showTransactionHistoryPopup = async () => {
        setShowTransactionHistory(true);
        let res = await getData(ENTITYNAME.DigitalSignTransactionHistory, { query: ["userID", "=", userData._id] });
        if (res.success) {
            setTransactionHistoryData(res.data);
        } else {
            setShowTransactionHistory(false);
            toast.error(res.message);
        }
    }

    const confirmPayment = async (response) => {
        console.log(response);
        let res = await verifyPayment(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
        console.log(res);
        if (!res.success) {
            await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
            toast.error("Payment Failed");
        } else {
            let transactionRes = await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Confirmed);
            if (!transactionRes.success) {
                toast.error("Your payment is successful but unable to update transaction history. Please contact us.");
            }
            else {
                toast.success("Payment Success\n Plan has been added.");
                setShowPurchasePlan(false);
                await loadData();
            }
        }
    }

    const handlePayment = async () => {

        var planObj = {
            _id: "",
            SID: 0,
            Name: "Custom",
            Price: dataItem.price,
            Tenure: dataItem.tenure,
            screens: dataItem.screens,
            storage: dataItem.storage
        }

        const response = await razorpayCreateOrder(planObj.Price, planObj);
        console.log(response);

        if (!response.success) {
            toast.error(response.message);
            return;
        }

        const { orderID, currency, amount: orderAmount, keyID } = response.data;

        const options = {
            key: keyID, // Replace with your Razorpay key_id
            amount: orderAmount,
            currency: currency,
            name: 'AutomateB',
            description: 'Test Transaction',
            order_id: orderID,
            handler: confirmPayment,
            modal: {
                ondismiss: async function () {
                    // This function will be called when the user cancels the payment
                    await updateTransactionHistory(orderID, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
                    toast.error("Payment Canceled");
                }
            },
            prefill: {
                name: userData.name,
                email: userData.Email,
                contact: userData.PhoneNo
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: 'orange'
            }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const downloadInvoice = async (trHistory) => {
        let res = await API.paymentInvoice(trHistory)
        if (res.success) {
            window.open(FILEURL.BASEURL + "downloadReport/" + res.data);
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };
    const colorText = {
        color: "#919496",
        letterSpacing: '1px',
    }
    const getPriceByRange = (screens) => {
        for (let screen of SCREEN_RANGE_AND_PRICE) {
            const [min, max] = screen.range.split('-');
            console.log([min, max])
            if (screens >= parseInt(min) && screens <= parseInt(max)) {

                let price = screen.price;
                return price;
            }
        }
        return 0;
    };

    const togglePlan = () => {

        setIsYearly(!isYearly);
        setIsMonthlySelected()
        setBillingPlan(screens)
    };

    return (
        <BossDialog
            onClose={onClose}
            showHeader={true}
            style={{ background: "transparent" }}
            width={showTransactionHistory ? "800px" : showPurchasePlan ? "800px" : "600px"}
            // height={"500px"}
            height={loading ? "350px" : showPurchasePlan ? "auto" : "510px"}

        >
            {
                loading ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }
                    } >
                        <i className={"fa fa-spinner fa-spin fa-3x"} />
                    </div >
                    : showPurchasePlan ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row plan-container mb-4">
                                    <div className="col-md-6">
                                        <div className="plan-header">Customize your plan</div>
                                        <div className="plan-subheader">save 10% with yearly payment</div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <div className="toggle-container pull-right">
                                            <div className="toggle-button">
                                                <div className={`yearly ${isYearly ? 'active' : ''}`} onClick={() => { setIsYearly(true); setBillingPlan(screens) }} >
                                                    YEARLY <span className="discount">-10%</span>
                                                </div>
                                                <div className={`monthly ${!isYearly ? 'active' : ''}`} onClick={() => { setIsYearly(false); setBillingPlan(screens) }}>
                                                    MONTHLY
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider></Divider>
                                <div className="row mt-4">
                                    <div className="col-md-12 mt-4" style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <span className='ScreenCount'>{dataItem.screens}</span>
                                        <span style={{ fontSize: '15px' }}>Screens</span>
                                    </div>
                                    <div className="col-md-12">
                                        <Slider
                                            min={1}
                                            max={100}
                                            step={1}
                                            value={screens}
                                            valueLabelDisplay="auto"
                                            onChange={(e) => {
                                                if (!isModified) {
                                                    setIsModified(true);
                                                }
                                                setBillingPlan(e.target.value)
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-2">
                                        <div className='titleBold'>{Math.ceil(dataItem.storage / 1024)} GB</div>
                                        <div className='titleNormal'>Storage</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span className='titleBold'>INR</span>
                                            <span className='titleBold'>{dataItem.perScreen}</span>
                                            {isYearly && <span className='titleBold' style={{ alignItems: 'center', fontSize: '18px', textDecoration: "line-through", color: '#696966', marginRight: '02px' }}> {dataItem.ogPerScreen}</span>}
                                        </div>

                                        <div className='titleNormal'>per screen/month</div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='titleBold'>10%</div>
                                        <div className='titleNormal'>Save with yearly plan</div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-0" style={{ backgroundColor: 'rgb(31,33,33)', padding: '10px', borderRadius: '5px' }}>
                                    <div className="col-md-9">
                                        <div className='titleBold' style={{marginRight:'10px', color: '#c8cbcf', display: 'flex', alignItems: 'center' }}> <span className='priceBold'>INR</span>
                                            <span className='priceBold'> {dataItem.price}</span>
                                            {isYearly && <span className='priceBold' style={{ textDecoration: 'line-through', color: '#b8b8b6', fontSize: '18px' }} > {dataItem.ogPrice}</span>}
                                        </div>
                                        <div className='titleNormal' style={{ color: '#b7b9bd' }}>Total Cost/{isYearlyRef.current ? "Year" : "Month"}</div>
                                    </div>

                                    <div className=" col-md-3 pull-right">
                                        <button title="Get Started" onClick={() => {
                                            handlePayment();
                                        }} style={{ fontSize: "16px", backgroundColor: "#FF6600", borderRadius: "4px", height: "50px", width: "100%" }} type="submit" className={`btn-square btn-success`} >
                                            &nbsp; Pay Now &nbsp;
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : showTransactionHistory ?
                            <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>Transaction History</h1>
                                <div className='mt-4' style={{ height: "392px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table border solid 2px">
                                        <thead className='border solid 2px'>
                                            <tr>
                                                <th style={{ width: "30px" }}></th>
                                                <th scope="col" style={{ width: "105px" }}>Date</th>
                                                <th scope="col">Order ID</th>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Screens</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Tenure(Days)</th>
                                                <th scope="col">Transaction Status</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border solid 2px'>
                                            {transactionHistoryData.map(trHistory => <tr key={trHistory._id}>
                                                <td>{trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Confirmed && <i className='fa fa-download cursor-pointer' onClick={() => downloadInvoice(trHistory)}></i>}</td>
                                                <td style={{ width: "30px" }}>{moment(trHistory.orderCreatedOn).format("ll")}</td>
                                                <td>{trHistory.orderID.replace("order_", "")}</td>
                                                <td>{trHistory.plan.Name}</td>
                                                <td>{trHistory.plan.screens}</td>
                                                <td>{trHistory.plan.storage}</td>
                                                <td>{trHistory.plan.Tenure}</td>
                                                <td style={{ textAlign: "center", color: "white" }}><div style={{ backgroundColor: trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Cancelled ? "#f4511e" : trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Pending ? "#deb633" : "#03a782", display: "inline-block", padding: "2px 8px", borderRadius: "5px", borderColor: "white", fontSize: "12px" }}>{Object.keys(DIGITALSIGN_TRANSACTION_STATUS)[Object.values(DIGITALSIGN_TRANSACTION_STATUS).indexOf(trHistory.status)]}</div></td>
                                                <td>{trHistory.currency + " " + trHistory.amount}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                    <SaveButton tooltip="OK" title="OK" onClick={() => { setShowTransactionHistory(false) }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>
                            : <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>My Plan</h1>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <span style={{ fontWeight: "bold", paddingTop: "5px", fontSize: "18px", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.name ?? "")}</span>
                                        <span style={{ fontWeight: "bold", paddingTop: 0, marginTop: 0, fontSize: "14px", color: "grey", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.Company.Name ?? "")}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }} className='text-success'> {parseInt(myPlans.reduce((acc, plan) => acc + plan.plan.screens, 0)) - parseInt(myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0))}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Active  Screens</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }}> {myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0)}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Balance  Screens</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4' style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Total Screens</th>
                                                <th scope="col">Screens Left</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Tenure</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {myPlans.map(plan => <tr key={plan._id}>
                                                <td>{plan.plan.Name}</td>
                                                <td>{plan.plan.screens}</td>
                                                <td>{plan.balanceScreens}</td>
                                                <td>{plan.plan.storage}MB</td>
                                                <td className={`${plan.balanceScreens > 0 ? "text-success" : "text-danger"}`}>{plan.balanceScreens > 0 ? "Active" : "Inactive"}</td>
                                                <td>{userData?.Company?.SignUpData?.currency ?? 'INR' + " " + plan?.plan?.Price}</td>
                                                <td>{plan.plan.Tenure}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                    <CancelButton onClick={onClose} style={{ borderColor: "white", marginLeft: 0, }} />
                                    <SaveButton tooltip="Transaction History" title="Transaction History" onClick={showTransactionHistoryPopup} style={{ borderRadius: "5px", backgroundColor: "#03a782", borderColor: "white" }} />
                                    <SaveButton tooltip="Purchase Plan" title="Purchase Plan" onClick={() => {
                                        setShowPurchasePlan(true);
                                    }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>}
        </BossDialog>
    );
}


export default MyPlansPopup;
