/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, SOCKET_ACTION, SOCKET_EVENTS, PRODUCTS } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { PlatformCell, arrayEnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import { getter } from "@progress/kendo-data-query";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { utility } from "../../framework/utility/utilityProvider";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import socket from "../../framework/socket/socket";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { Loader } from "../../framework/forms/helpercomponents/Loader";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

export const ModuleCollection = (props) => {

  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showImport = props.showImport ?? false;
  let showExport = props.showExport ?? true;
  let showArchive = props.showArchive ?? true;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showDelete = props.showDelete ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let CustomEditForm = props.customEditForm ?? EditForm;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? false;
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let sort = props.sort ?? { SID: 1 };
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let myMenuModel = props.menuModel ?? collectionMenuModel;
  // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let enableMutliSelect = props.enableMutliSelect ?? false;

  //Add this for media management preference column
  const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [selectedState, setSelectedState] = useState({});
  const [products, setProducts] = useState([]);
  const [productsItem, setProductsItem] = useState({ SID: PRODUCTS.All, Description: "All" });
  const [showProductsValue, setShowProductsValue] = useState(false);
  const [filterProduct, setFilterProduct] = useState([]);
  const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
  const [gridSort, setGridSort] = useState([initialSort]);

  const selectedItemsRef = useRef([]);
  const setSelectedItem = (items) => {
    selectedItemsRef.current = items;
  }

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const location = useLocation();
  const lang = useTranslation();

  let href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement
  }

  useEffect(() => {
    setShowArchived(false);
    setProducts(Object.keys(PRODUCTS).map((obj) => { return { SID: PRODUCTS[obj], Description: obj } }));
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false, filterProduct);
  }, [props.entityname, props.gridData, props.title]);

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    let sortEvent = gridSort[0] ?? initialSort;
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        setIsLoading(true);
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setIsLoading(true);
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
    }
  }, [searchText]);

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, filterProduct) => {

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
        let json = await API.getEntitiesWithSearch(
          props.entityname,
          { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: sort },
          columns,
          searchText,
          filterProduct
        );
        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    }
  };


  const onKillUserSession = (userData) => {
    delete userData["Module"];
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.KILL_USER_SESSION, module: MODULE.ALL, data: userData })
    toast.success(`${userData.name} session has been killed successfully`, { position: toast.POSITION.TOP_RIGHT });
  }

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
    }
  };

  const handleNavigate = (item) => {
    navigate(`${item.path}`, { state: item });
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    console.log(dataItem);
    onConfirmDelete(dataItem)
  };

  const handleArchive = async (dataItem) => {
    if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
      var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
        _id: dataItem._id,
        archive: !dataItem.Archive,
        entityName: entityName
      })
    } else {
      var resArchiveUpdated = await API.saveData(entityName, {
        ...dataItem,
        Archive: !dataItem.Archive
      })
    }

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let sortEvent = gridSort[0] ?? initialSort;
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
    setShowPreferences(!showPreferences);
  }

  const setAsDefault = async (data) => {

    let setAsDefaultRes = await API.setAsDefault(entityName, data._id);
    if (setAsDefaultRes.success) {
      let sortEvent = gridSort[0] ?? initialSort;
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      toast.error(setAsDefaultRes.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      history={history}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
      openNewEditForm={openNewEditForm}
      onKillUserSession={onKillUserSession}
      setAsDefault={setAsDefault}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.CurrentStatus} titleColor="#333" tileBackColor="#72eda9" />
    </div>
  )

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }
  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  }

  const onConfirmDelete = async (dataItem) => {
    const res = await API.deleteData(props.entityname, dataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    let current_page = page.skip / page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, '', showArchived, filterProduct);
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname);
  }

  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;
    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
    setPage(e.page);
    cancelSelectedItem()
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, true, filterProduct);
    } else {
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, false, filterProduct);
    }
    cancelSelectedItem();
  }

  const refreshCollection = () => {
    setIsLoading(true);
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
    setGridSort([initialSort]);
  }

  const downloadExcel = async () => {

    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.exportGridExcel(gridData, dataColumns);
    console.log(res.data);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    selectedIds.forEach((value) => {
      if (newSelectedState[value]) {
        let filter = gridData.find((obj) => obj._id == value);
        tempSelected.push(filter);
      }
    });

    setSelectedItem(tempSelected);
  };

  const onHeaderSelectionChange = (event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

    let selectedIds = Object.keys(newSelectedState);
    let tempSelected = [];

    if (checked) {
      selectedIds.forEach((value) => {
        if (newSelectedState[value]) {
          let filtered = gridData.find((obj) => obj._id == value);
          tempSelected.push(filtered);
        }
      });
    }
    setSelectedItem(tempSelected);
  };

  const onArchiveButtonClick = async () => {
    if (selectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    for (const element of selectedItemsRef.current) {
      const item = element;
      await handleArchive(item);
    }
    cancelSelectedItem();
  }

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname)
    //to load grid with new data
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
    setOpenForm(false);
  };

  const onEnterEdit = () => {
    enterEdit({ SID: 0 });
    cancelSelectedItem();
  }

  const cancelSelectedItem = () => {
    setSelectedItem([]);
    setSelectedState({});
  }

  const onShowProductsValue = () => {
    if (selectedItemsRef.current.length == 0) {
      toast.error(`${lang.please_select_atleast_one_item}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setShowProductsValue(true);
  }

  const handleProductChanges = (e) => {
    setProductsItem(e.target.value);
    let value = e.target.value;
    if(value?.SID == PRODUCTS.All){
      setFilterProduct([]);
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, []);
    }else{
      let query = [["Products", "in", [e.target.value.SID]]];
      setFilterProduct(query);
      loaddata({ per_page: page.take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, query);
    }
  }

  const handleProductsData = async (data) => {

    let updatedData = selectedItemsRef.current.map((item) => {
      const existingProducts = new Set(item.Products);
      const newProducts = data
        .filter((x) => !existingProducts.has(x.SID))
        .map((x) => x.SID);
      return {
        ...item,
        Products: [...item.Products, ...newProducts]
      }
    })

    let res = await API.saveData(entityName, updatedData);
    if (res.success) {
      toast.success(`${lang.successfully_add_product_succes_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      setSelectedItem([]);
      setSelectedState({});
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
      setProductsItem({ SID: PRODUCTS.All, Description: "All" });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  let timeOut;
  const handleColumnResize = (event) => {
    let lockedCol = event.columns.filter((item) => item?.locked).length ?? 1;
    try {
      if (event.index == 0) return;
      if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
      let column = dataColumns[event.index - lockedCol];
      let payload = {
        entityName: column?.entityName,
        name: column?.name,
        width: event.newWidth
      };
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        API.updateUserPreferenceColumnWidth(payload);
      }, 700);
    } catch (error) {
      toast.error("Error handling column resize:", error);
    }
  };

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort;
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, andWhereStatement, searchText, showArchived, filterProduct);
  }


  return (
    <div>
      {showTitle && (
        <>
          <h1 className="page-title txt-color-bludeDark">
            <i
              className="fa fa-table marlr"
              style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{myTitle === "" ? props.entityname : myTitle}</span>
          </h1>
        </>
      )}
      <div className="flex-container">
        <div className="martb mar">
          {showBack && (
            <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
          )}

          {showAdd && (
            <AddRoundButton onClick={onEnterEdit}></AddRoundButton>
          )}
        </div>


        <div className="flex-container-reverse flex-item-auto">

          <div className="input-group input-group w-300 martb" >
            <input
              type="text"
              className="form-control shadow-inset-2 pt-0 pb-0"
              id="searchInput"
              placeholder={lang.search_button_text}
              onChange={onSearch}
              onFocus={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(true);
                }
              }}
              onBlur={(e) => {
                if (props.setOutSideFocus) {
                  props.setOutSideFocus(false);
                }
              }}
            />
            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
              <div className="input-group-append">
                <span className="input-group-text">
                  <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                </span>
              </div>
            </button>
          </div>
          {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
            <PreferenceRoundButton
              icon={"gear"}
              title={lang.preference_button_tooltip}
              onClick={() => { setShowPreferences(!showPreferences) }}
            />
          </div>}
          <div className="mt-1" style={{ paddingTop: "12px" }}>
            <RefreshButton
              onClick={refreshCollection}
            />
          </div>
          {showArchive && enableMutliSelect && (<div className="mt-1" style={{ paddingTop: "12px" }}>
            <RoundButton
              icon={"inbox"}
              // style={{ margin: "0px 03px" }}
              // iconColor={"white"}
              title={showArchived ? lang.un_archive_label : lang.archive}
              onClick={onArchiveButtonClick}
            ></RoundButton></div>
          )}
          {showImport && (
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"upload"}
                btnColor={"warning"}
                // style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={lang.import_file_label}
                onClick={() => navigate('/home/DataMigration', { state: { mediaCategory: props.mediaCategory } })} //route navigate to data migration module
              /></div>
          )}
          {showExport && (
            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"info"}
                style={{ color: 'white' }}
                // iconColor={"white"}
                title={lang.export_button_tooltip}
                onClick={downloadExcel}
              /></div>
          )}
          <div className="m-1" style={{ paddingTop: "12px" }}>
            <RoundButton
              icon={"paperclip"}
              title={lang.add_product_button_tooltip}
              onClick={onShowProductsValue}
            /></div>

          {!hideArchive && <div className="d-inline mt-3 mr-2">
            <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
          </div>}
          <div className="d-inline mr-2 col-2" style={{ marginTop: '-9px' }}>
            <label htmlFor="">{lang.products_label}</label>
            <DropDownList
              data={products}
              name={"Products"}
              textField="Description"
              dataItemKey="SID"
              style={{ backgroundColor: 'white' }}
              value={productsItem}
              onChange={(e) => handleProductChanges(e)}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      {!isLoading &&
        <Grid
          style={props.gridstyle ?? {
            height: "76vh",
          }}
          rowRender={gridRowRender}
          filterable={filterable}
          sortable={sortable}
          onSortChange={onSortChange}
          pageable={isPageable}
          skip={page.skip}
          take={page.take}
          onPageChange={pageChange}
          total={total}
          resizable={true}
          onColumnResize={handleColumnResize}
          data={
            gridData.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: enableMutliSelect,
            drag: false,
            cell: false,
            mode: "multiple",
          }}
          dataItemKey={DATA_ITEM_KEY}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
        >

          {/* Multi Select Checkbox row */}
          {enableMutliSelect && (<GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex(
                (item) => !selectedState[idGetter(item)]
              ) === -1
            }
            locked={true}
            filterable={false}
          />
          )}

          {/* Edit Row */}
          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateOnlyCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.type === COLUMNSTYPE.datetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={DateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={TimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.name === "SID") {
              return;
              //we are not returning any value for SID column
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={ImageCell}
                  title={column.label}
                  width={column.width ?? 100}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={85}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={CheckboxCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={ArrayCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                />
              );
            }
            else if (column.type === COLUMNSTYPE.href) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={HrefCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.enum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={EnumCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.platform) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={PlatformCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.status) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={Mystatuscell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.localdatetime) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={LocalDateTimeColumn}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.icon) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={IconCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.arrayenum) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={arrayEnumCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>}

      {openForm && (
        <CustomEditForm
          {...props} //to get other props from customEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          refresh={refreshCollection} //to refresh the collection when data added
          item={editItem}
          dataColumns={dataColumns}
        />
      )}

      {deleteError &&
        <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
      {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

      {showProductsValue && <CollectionSelection customColumns={[{
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "Products",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
      },]} data={products.filter(x => x.SID != PRODUCTS.All)} title={lang.select_product_header_dialog} setDataList={handleProductsData} closeForm={() => setShowProductsValue(false)} width={"50vw"} searchByColumn={'Description'} />}
    </div>
  );
};