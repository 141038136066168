import { useEffect, useState } from "react";
import MediaEpisodeMataDataEditForm from "../../MediaEpisodeMataDataEditForm"
import { utility } from "../../../../framework/utility/utilityProvider";
import underscore from "underscore";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ENTITYNAME } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import BossDialog from "../../../../components/BossDialog";
import { DataHelper } from "../../../../framework/helper/DataHelper";
import SeasonMetaDataEditForm from "../../SeasonMetaDataEditForm";

const MetaDataTab = (props) => {

    const none = "N/A";

    const { mediaEpisode={}, entityName, gridData=[], setGridData } = props;

    const [showEditMetaData, setShowEditMetaData] = useState(false);
    const [metaData, setMetaData] = useState({});
    const [loading, setLoading] = useState(true);
    const [tabNumber, setTabNumber] = useState("1");
    const isHideMetaDataFields  = entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season;

    const boxStyle = { margin: "0px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white" }
    const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
    const subHeadingStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 0", padding: 0 }
    const subHeadingDetailStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: 0, marginBottom: "25px" }
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px 5px" }

    useEffect(() => {
        loadMediaEpisodeMetaData();
    }, [mediaEpisode]);

    const loadMediaEpisodeMetaData = async () => {
        setLoading(true);
        if(isHideMetaDataFields){
            let metaValue = Object.keys(mediaEpisode).length > 0 ? {
                Languages: mediaEpisode?.Languages,
                PGRating: mediaEpisode?.PGRating,
                ProductionYear: mediaEpisode?.ProductionYear,
                Keywords: mediaEpisode?.Keywords,
            } : {};
            setMetaData(metaValue); 
            setLoading(false);
            return;
        }
        let responseMetaData = await API.getData(ENTITYNAME.MediaEpisodeMetaData, { query: ['MediaEpisodeSID', '=', mediaEpisode?.SID] });
        if (responseMetaData.success && responseMetaData.data.length > 0) {
            setMetaData(responseMetaData.data[0]);
        }
        setLoading(false);
    }

    const onCloseMetaData = () => {
        setShowEditMetaData(!showEditMetaData);
    }

    const castAndCrew = underscore.groupBy(metaData?.CastAndCrew, (CastAndCrew) => CastAndCrew?.CastType?.Description);

    //from edit form
    const getMetaData = async (data) => {
        setMetaData(data);
    }

    const onSaveMetadata = async () => {
        if(isHideMetaDataFields){
            let finalData ={
                ...mediaEpisode,
                Languages: metaData?.Languages?.length > 0 ? metaData.Languages?.map(x => {return { _id: x._id, Description: x.Description }}) : [],
                PGRating: (metaData?.PGRating && Object.keys(metaData.PGRating).length > 0) ? { _id: metaData.PGRating?._id, Description: metaData.PGRating?.Description } : {},
                ProductionYear: metaData?.ProductionYear,
                Keywords: metaData?.Keywords
            }
            let response = await API.saveData(entityName, finalData);
            if (!response.success) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            let updateData = gridData.map((obj) => {
                if (obj.SID == mediaEpisode?.SID) {
                    return { ...obj, ...response.data };
                }
                return obj;
            })
            setGridData(updateData);
        }else{
            let finalData = DataHelper.saveMetaDataEntity(metaData, mediaEpisode);
            let response = await API.saveData(ENTITYNAME.MediaEpisodeMetaData, finalData);
            if (!response.success) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }
            let updateData = gridData.map((obj) => {
                if (obj.SID == mediaEpisode?.SID) {
                    return { ...obj, Metadata: response.data };
                }
                return obj;
            })
            setGridData(updateData);

        }
        onCloseMetaData();
    }

    return (
        loading ? <div style={{ ...boxStyle, height: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <i className="fa fa-spinner fa-spin fa-xl " />
        </div> :
            <>
                {/* Genral */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>General</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={()=>{setShowEditMetaData(true); setTabNumber("1");}} />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        <div>
                            <h2 style={subHeadingStyle}>Pg Rating</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.PGRating?.Description ?? none}</p>
                            {!isHideMetaDataFields && <><h2 style={subHeadingStyle}>Censor Rating</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.CensorRating?.Description ?? none}</p></>}
                            <h2 style={subHeadingStyle}>Languages</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(metaData?.Languages, 'Description') ?? none}</p>
                        </div>
                        <div>
                            <h2 style={subHeadingStyle}>Production Year</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.ProductionYear ?? none}</p>
                            <h2 style={subHeadingStyle}>Keywords</h2>
                            <p style={subHeadingDetailStyle}>{metaData?.Keywords?.length == 0 ? none : metaData?.Keywords ?? none}</p>
                            {!isHideMetaDataFields && <><h2 style={subHeadingStyle}>Categories</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(metaData?.Categories, 'Name') ?? none}</p></>}
                        </div>
                    </div>

                </div><hr/>
                {!isHideMetaDataFields && <>
                {/* Synopsis */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Synopsis</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={()=>{setShowEditMetaData(true); setTabNumber("2");}} />
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Language</th>
                                <th scope="col">Synopsis</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metaData?.Synopsis?.map((synopsis, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>{synopsis?.Language?.Description}</td>
                                        <td style={tableDataStyle}>{utility.subString(synopsis?.Synopsis, 100)}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div><hr/>

                {/* Cast & Crew */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Cast & Crew</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={()=>{setShowEditMetaData(true); setTabNumber("3");}} />
                    </div>

                    {Object.keys(castAndCrew).map((key, index) => {
                        return (<>
                            <h2 style={subHeadingStyle}>{key}</h2>
                            <p style={subHeadingDetailStyle}>{utility.displayArrayInPipe(castAndCrew[key], 'Name') ?? none}</p>
                        </>
                        )
                    })}

                </div><hr/>

                {/* Audio Track */}
                <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Audio Track</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={()=>{setShowEditMetaData(true); setTabNumber("5");}} />
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Language</th>
                                <th scope="col">Track Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metaData?.AudioTrack?.map((AudioTrack, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>{AudioTrack?.Language?.Description}</td>
                                        <td style={tableDataStyle}>{AudioTrack?.TrackNumber}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div><hr/>

                {/* Dynamic Field */}
                {metaData?.dynamicField && <div style={boxStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                        <h1 style={headingStyle}>Dynamic Fields</h1>
                        <RoundButton icon={'pen'} title={'Edit'} onClick={()=>{setShowEditMetaData(true); setTabNumber("4");}} />
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>

                        {
                            Object.keys(metaData?.dynamicField).map((key, index) => {
                                if (typeof metaData?.dynamicField[key] == 'object') {
                                    return null;
                                } else {
                                    return (
                                        <div key={index}>
                                            <h2 style={subHeadingStyle}>{key.toUpperCase()}</h2>
                                            <p style={subHeadingDetailStyle}>{metaData?.dynamicField[key] ?? none}</p>
                                        </div>
                                    )
                                }
                            })

                        }

                    </div>
                </div>}  </>}

                {showEditMetaData && <BossDialog
                    title={'Edit Meta Data'}
                    onClose={onCloseMetaData}
                    width={"900px"}
                >
                    <SaveButton onClick={onSaveMetadata}></SaveButton>
                    <CancelButton onClick={onCloseMetaData}></CancelButton>
                    <div className="mt-3"></div>
                    {isHideMetaDataFields ? 
                    <SeasonMetaDataEditForm dataItem={metaData} setMataData={getMetaData} /> :
                    <MediaEpisodeMataDataEditForm tabNumber={tabNumber} getMetaData={getMetaData} metaData={metaData} /> }
                </BossDialog>
                }
            </>

    )
}

export default MetaDataTab;