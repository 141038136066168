import React, { useEffect, useRef, useState } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Switch } from "@mui/material";
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useNavigate, useParams } from "react-router-dom";
import BrowseRoundButton from "../forms/helpercomponents/buttons/BrowseRoundButton";
import InfoRoundButton from "../../components/InfoRoundButton";
import { ENTITYNAME, LOGEVENT, MODULE, PERMALINKTYPE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant";
import RoundButton from "../forms/helpercomponents/buttons/RoundButton";
import BossDialog from "../../components/BossDialog";
import * as API from "../API/api";
import { toast } from "react-toastify";
import { utility } from "../utility/utilityProvider";
import PiPageSettingCollectionSelection from "../forms/PiPageSettingCollectionSelection";
import { RadioButton } from "@progress/kendo-react-inputs";
import socket from "../socket/socket";

const PiAndSeoEditForm = (props) => {

  const lang = useTranslation();
  const navigate = useNavigate();
  const { SID } = useParams();

  const blankDataItem = {
    SID: 0,
    description: false,
    episodeNo: false,
    releaseDate: false,
    pgRating: false,
    castAndCrew: false,
    language: false,
    genre: false,
    provider: false,
    metaTitle: {},
    metaDescription: {},
    metaRobot: {},
    metaGooglebot: {},
    PermalinkType: {},
  };

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [openFormField, setOpenFormField] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({
    description: "",
    example: <p></p>,
    image: <img src="" />,
  });
  const [selectedPermalink, setSelectedPermalink] = useState("Title")
  const selectedItemRef = useRef("");

  useEffect(() => {
    loadEditData();
  }, []);

  const loadEditData = async () => {
    let res = await API.getData(ENTITYNAME.PiPageSetting);
    let resData = res.data[0]
    if (res.success) {
      let data = {
        ...resData,
        SID: resData?.SID ?? 0,
        description: resData.description ?? false,
        episodeNo: resData.episodeNo ?? false,
        releaseDate: resData.releaseDate ?? false,
        pgRating: resData.pgRating ?? false,
        castAndCrew: resData.castAndCrew ?? false,
        language: resData.language ?? false,
        genre: resData.genre ?? false,
        provider: resData.provider ?? false,
        metaTitle: resData.metaTitle ?? {},
        metaDescription: resData.metaDescription ?? {},
        metaRobot: resData.metaRobot ?? {},
        metaGooglebot: resData.metaGooglebot ?? {},
        PermalinkType: resData.PermalinkType == PERMALINKTYPE.TITLE ? setSelectedPermalink("Title") : setSelectedPermalink("ID")
      };

      setDataItem(data);
    }
  };

  const onChange = (e) => {
    if (e.target.name === "description") {
      setDataItem({ ...dataItem, description: !dataItem.description });
    } else if (e.target.name === "episodeNo") {
      setDataItem({ ...dataItem, episodeNo: !dataItem.episodeNo });
    } else if (e.target.name === "releaseDate") {
      setDataItem({ ...dataItem, releaseDate: !dataItem.releaseDate });
    } else if (e.target.name === "pgRating") {
      setDataItem({ ...dataItem, pgRating: !dataItem.pgRating });
    } else if (e.target.name === "castAndCrew") {
      setDataItem({ ...dataItem, castAndCrew: !dataItem.castAndCrew });
    } else if (e.target.name === "language") {
      setDataItem({ ...dataItem, language: !dataItem.language });
    } else if (e.target.name === "genre") {
      setDataItem({ ...dataItem, genre: !dataItem.genre });
    } else if (e.target.name === "provider") {
      setDataItem({ ...dataItem, provider: !dataItem.provider });
    }
    else if (e.target.name == "Title" || e.target.name == "ID") {
      setSelectedPermalink(e.target.name);
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

  };

  const handleSubmit = async () => {
    let saveData = {
      _id: dataItem._id,
      SID: dataItem.SID ?? 0,
      description: dataItem.description ?? false,
      episodeNo: dataItem.episodeNo ?? false,
      releaseDate: dataItem.releaseDate ?? false,
      pgRating: dataItem.pgRating ?? false,
      castAndCrew: dataItem.castAndCrew ?? false,
      language: dataItem.language ?? false,
      genre: dataItem.genre ?? false,
      provider: dataItem.provider ?? false,
      metaTitle: dataItem.metaTitle ?? {},
      metaDescription: dataItem.metaDescription ?? {},
      metaRobot: dataItem.metaRobot ?? {},
      metaGooglebot: dataItem.metaGooglebot ?? {},
      PermalinkType: selectedPermalink == "ID" ? PERMALINKTYPE.ID : PERMALINKTYPE.TITLE
    };

    let res = await API.saveData(ENTITYNAME.PiPageSetting, saveData);
    console.log(res);

    if (res.success) {
      let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_PIPAGESETTING : LOGEVENT.INSERT_PIPAGESETTING, module: MODULE.PI_PAGE_SETTING, data: res.data, message: res.message };
      API.SaveLogs(logData);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.PLATFORM_THEME_REFRESH, module: MODULE.PLATFORM, data: null });
      utility.deleteLocalStorageItem(ENTITYNAME.PiPageSetting);
      toast.success('Successfully updated', {
        position: toast.POSITION.TOP_RIGHT,
      })
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSelectedData = (dataList, selectedName) => {
    console.log(selectedName);
    setDataItem({ ...dataItem, [selectedName]: dataList[0] });
  };

  const borderStyle = {
    border: "1px solid #ced4da", /* Your existing border styles */
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
    backgroundColor: "#FAFAFA"
  };

  return (
    <>
      <Form
        initialValues={dataItem}
        render={(formRenderProps) => (
          <FormElement>
            <EditPageHeader title={`${lang.pi_page_setting_page_editpageheader} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_title_editpageheader}`)}
              onSubmit={handleSubmit}
              onCancel={() => navigate(-1)}
            />
            <div className="m-2">
              <label style={{ marginTop: '10px', fontSize: "17px", fontWeight: 400 }}>{lang.pi_label}</label>
              <div
                className="border mt-2 form-group with-3d-effect" style={borderStyle}
              >
                <div className="row mt-2 mb-4">
                  <div className="col-12">
                    <div className="row ml-3">
                      <div className="col-3">
                        <label>{lang.show_description_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"description"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.description}
                        />
                      </div>
                      <div className="col-3">
                        <label>{lang.show_episode_no_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"episodeNo"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.episodeNo}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 ml-3">
                      <div className="col-3">
                        <label>{lang.release_date_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"releaseDate"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.releaseDate}
                        />
                      </div>
                      <div className="col-3">
                        <label>{lang.show_pg_rating_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"pgRating"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.pgRating}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 ml-3">
                      <div className="col-3">
                        <label>{lang.show_cast_and_crew_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"castAndCrew"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.castAndCrew}
                        />
                      </div>
                      <div className="col-3">
                        <label>{lang.show_language_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"language"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.language}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 ml-3">
                      <div className="col-3">
                        <label>{lang.show_genre_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"genre"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.genre}
                        />
                      </div>
                      <div className="col-3">
                        <label>{lang.show_provider_label}</label>
                      </div>
                      <div className="col-3">
                        <Field
                          name={"provider"}
                          component={Switch}
                          size={"small"}
                          onChange={(e) => onChange(e)}
                          checked={dataItem.provider}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label style={{ fontSize: "17px", fontWeight: 400 }}>{lang.seo_label}</label>
              <div
                className="border mt-2 form-group with-3d-effect" style={borderStyle}
              >
                <div className="row mt-2 mb-4">
                  <div className="col-12">
                    <div className="row mt-2 ml-1">
                      <div className="col-4">
                        <label className="mt-2">{lang.meta_title_label}</label>
                        <InfoRoundButton
                          message={lang.show_repeat_pattern_detail_tooltip}
                          onClick={""}
                        />
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="metaTitle"
                          value={dataItem.metaTitle?.name}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col mt-4">
                        <BrowseRoundButton
                          onClick={() => {
                            setOpenFormField(true);
                            selectedItemRef.current = "metaTitle";
                          }}
                        />
                        <RoundButton
                          icon={"eye"}
                          title={lang.show_response_label}
                          onClick={() => {
                            setModal(true);
                            setData({
                              description:
                                "A meta title (a.k.a., the “title tag”) tells search engines what the title of a webpage is. This title is typically displayed on search results pages as the clickable headline of a result",
                              example: (
                                <p>
                                  <code className="b-blog__code__code">
                                    &lt;title&gt;Best&nbsp;Homemade&nbsp;Pizza&nbsp;Recipe&nbsp;(1&nbsp;Hour&nbsp;or&nbsp;Overnight)&lt;/title&gt;
                                  </code>
                                </p>
                              ),
                              image: (
                                <img
                                  style={{
                                    height: "20vh",
                                    width: "100%",
                                    marginLeft: "20px",
                                  }}
                                  src="https://res.cloudinary.com/dl2n34gbw/image/upload/v1708927957/blk6v2a26k22k3q0w1hz.png"
                                />
                              ),
                            });
                          }}
                        />
                      </div>
                      <div className="col-4">
                        <label className="mt-2">
                          {lang.meta_description_label}
                        </label>
                        <InfoRoundButton
                          message={lang.show_repeat_pattern_detail_tooltip}
                          onClick={""}
                        />
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="metaDescription"
                          value={dataItem.metaDescription?.name}
                          onChange={onChange}
                        />
                      </div>

                      <div className="col mt-4">
                        <BrowseRoundButton
                          onClick={() => {
                            setOpenFormField(true);
                            selectedItemRef.current = "metaDescription";
                          }}
                        />
                        <RoundButton
                          icon={"eye"}
                          title={lang.show_response_label}
                          onClick={() => {
                            setModal(true);
                            setData({
                              description:
                                "Provides a short description of the page.This description is often used in the snippet shown in search results.",
                              example: (
                                <p>
                                  &lt;meta name="description" content="Make your
                                  businessvisible online with 55+ tools for SEO,
                                  PPC, content, social media, competitive
                                  research, and more."&gt;
                                </p>
                              ),
                              image: (
                                <img
                                  style={{
                                    height: "20vh",
                                    width: "100%",
                                    marginLeft: "20px",
                                  }}
                                  src="https://res.cloudinary.com/dl2n34gbw/image/upload/v1708928730/mmer2zojlhulxnwgeryd.png"
                                />
                              ),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 ml-1">
                      <div className="col-4">
                        <label className="mt-2">{lang.meta_robot_label}</label>
                        <InfoRoundButton
                          message={lang.show_repeat_pattern_detail_tooltip}
                          onClick={""}
                        />
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="metaRobot"
                          value={dataItem.metaRobot?.name}
                          onChange={onChange}
                        />
                      </div>

                      <div className="col mt-4">
                        <BrowseRoundButton
                          onClick={() => {
                            setOpenFormField(true);
                            selectedItemRef.current = "metaRobot";
                          }}
                        />
                        <RoundButton
                          icon={"eye"}
                          title={lang.show_response_label}
                          onClick={() => {
                            setModal(true);
                            setData({
                              description:
                                "Controls how search engines crawl and index your pages. You can use “index” and “follow” to allow both actions. Or “noindex” and “nofollow” to prevent them. If you don’t specify anything, “index” and “follow” are the default values.",
                              example: (
                                <p>
                                  &lt;meta name="robots"
                                  content="index,follow"&gt;
                                </p>
                              ),
                              image: (
                                <img
                                  style={{ height: "20vh", width: "120vh", marginLeft: "30px" }}
                                  src="https://res.cloudinary.com/dl2n34gbw/image/upload/v1708580661/wi6yda1xo2rw3qykux65.png"
                                />
                              ),
                            });
                          }}
                        />
                      </div>

                      <div className="col-4">
                        <label className="mt-2">
                          {lang.meta_googlebot_label}
                        </label>
                        <InfoRoundButton
                          message={lang.show_repeat_pattern_detail_tooltip}
                          onClick={""}
                        />

                        <input
                          type="text"
                          className="form-control form-control-sm"
                          name="metaGooglebot"
                          value={dataItem.metaGooglebot?.name}
                          onChange={onChange}
                        />
                      </div>
                      <div className="col mt-4">
                        <BrowseRoundButton
                          onClick={() => {
                            setOpenFormField(true);
                            selectedItemRef.current = "metaGooglebot";
                          }}
                        />{" "}
                        <RoundButton
                          icon={"eye"}
                          title={lang.show_response_label}
                          onClick={() => {
                            setModal(true);
                            setData({
                              description:
                                "Similar to robots, but specific to Google.",
                              example: (
                                <p>
                                  &lt;meta name="googlebot"
                                  content="index,follow"&gt;
                                </p>
                              ),
                              image: (
                                <img
                                  style={{
                                    height: "15vh",
                                    width: "100vh",
                                    marginLeft: "10vh",
                                  }}
                                  src="https://res.cloudinary.com/dl2n34gbw/image/upload/v1708580687/tzkan7ixrqpwg3tnzygf.png"
                                />
                              ),
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label style={{ fontSize: "17px", fontWeight: 400 }}>{lang.permalink_structure_label}</label>
              <div
                className="border mt-2 form-group with-3d-effect" style={borderStyle}
              >
                <div className="row mt-2 mb-3">
                  <div className="col-12">
                    <div className="row mt-3">
                      <div className="col-5" style={{ marginLeft: "60px" }}>
                        <RadioButton
                          name="Title"
                          checked={selectedPermalink == "Title"}
                          label={lang.title_label}
                          onClick={onChange}
                        />
                        <p style={{ fontSize: "15px", marginTop: "7px", marginLeft: "20px", backgroundColor: 'rgb(211, 211, 211)', alignSelf: 'flex-start' }}>https://www.binging.tv/in/movies/laxmii/1260036200</p>
                      </div>
                      <div className="col-5" style={{ marginLeft: "60px" }}>
                        <RadioButton
                          name="ID"
                          checked={selectedPermalink == "ID"}
                          label={lang.id_label}
                          onClick={onChange}
                        />
                        <p style={{ fontSize: "15px", marginTop: "7px", marginLeft: "20px", backgroundColor: 'rgb(211, 211, 211)', alignSelf: 'flex-start' }}>https://www.hotstar.com/in/sid=1260036200</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormElement >
        )}
      />
      {
        openFormField && (
          <PiPageSettingCollectionSelection
            wherestatement={["entityName", "=", ENTITYNAME.Episode]}
            entityname={ENTITYNAME.FormFields}
            customColumns={""}
            title={lang.select_Field_tooltip}
            closeForm={() => setOpenFormField(!openFormField)}
            setDataList={handleSelectedData}
            mode={"single"}
            width={"50vw"}
            height={"60vh"}
            selectedName={selectedItemRef.current}
          />
        )
      }
      {
        modal && (
          <BossDialog
            title={"Description"}
            onClose={() => {
              setModal(false);
              setData({ title: "", example: <p></p> });
            }}
            width={"50%"}
          >
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-6">
                    <h5 style={{ marginLeft: "20vh" }}>
                      {lang.description_label}
                    </h5>
                  </div>
                  <div className="col-6">
                    <h5 style={{ marginLeft: "20vh" }}>{lang.example_label}</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <p>{data.description}</p>
                  </div>
                  <div className="col-6">{data.example}</div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h5 style={{ marginLeft: "40vh", marginTop: "10px" }}>
                      {lang.screenshot_label}
                    </h5>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "10px", width: "100%" }}>
                  {data.image}
                </div>
              </div>
            </div>
          </BossDialog>
        )
      }
    </>
  );
};

export default PiAndSeoEditForm;
