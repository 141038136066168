import RoundButton from "../buttons/RoundButton";



export const CommandCell = (props) => {

  return <td>
    <div className="row">
      <div className="col-1">
      <RoundButton title={props.title ?? 'Add'} icon={props.icon ?? 'plus'} btnColor={props.btnColor ?? 'primary'} iconColor={props.iconColor ?? ''} onClick={() => props.onClick(props.dataItem)} />
      </div>
    </div>
  </td>;
};
