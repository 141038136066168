/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MediaEpisodeSynopsisEditForm from "./MediaEpisodeSynopsisEditForm";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import MediaEpisodeCastAndCrewEditForm from "./MediaEpisodeCastAndCrewEditForm";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import DynamicFieldsTab from "./DynamicFieldsTab";
import AudioTrack from "./AudioTrack";
import MediaEpisodeCensorshipDetailsEditForm from "./MediaEpisodeCensorshipDetailsEditForm";

function MediaEpisodeMataDataEditForm(props) {

  let isFromMusicForm = props?.isFromMusicForm ?? false;
  
  const [pgRatings, setPGRatings] = useState([]);
  const [censorRatings, setCensorRatings] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [mediaTechnicalPrototypes, setMediaTechnicalPrototypes] = useState([]);
  const [synopsisData, setSynopsisData] = useState(props.metaData?.Synopsis ?? []);
  const [castAndCrewData, setCastAndCrewData] = useState(props.metaData?.CastAndCrew ?? []);
  const [tabNumber, setTabNumber] = React.useState(props?.tabNumber ?? "1");
  const [audioTrackData, setAudioTrackData] = useState(props.metaData?.AudioTrack ?? []);
  const [dynamicFieldData, setDynamicFieldData] = useState(props.metaData?.dynamicField ?? {});
  const lang = useTranslation();

  const [dataItem, setDataItem] = useState(props?.metaData);
  const [censorshipDetailData, setCensorshipDetails] = useState(props.metaData?.CensorshipDetail ?? []);

  useEffect(() => {
    loadcombo();
    if (props.SID > 0 && !props.metaData.hasOwnProperty('_id')) {
      loadEditData();
    } else {
      setSynopsisData(props.metaData?.Synopsis);
      setCastAndCrewData(props.metaData?.CastAndCrew);
      setAudioTrackData(props.metaData?.AudioTrack);
      setDynamicFieldData(props.metaData?.dynamicField);
      setCensorshipDetails(props.metaData?.CensorshipDetail);
      setDataItem(props?.metaData)
    }
  }, [props.metaData, props.SID]);

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const loadcombo = async () => {
    var pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
    setPGRatings(pgRes.data);
    var crRes = await API.getDataLookup(ENTITYNAME.CensorRating, { sort: { Description: 1 } });
    setCensorRatings(crRes.data);
    var mtpRes = await API.getDataLookup(ENTITYNAME.MediaTechnicalPrototype, { sort: { Description: 1 } });
    setMediaTechnicalPrototypes(mtpRes.data);
    var languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(languages.data);
    var categories = await API.getDataLookup(ENTITYNAME.ContentCategory, { sort: { Name: 1 } });
    setCategories(categories.data);
  }

  const onChange = (e) => {
    setDataItem({...dataItem, [e.target.name]: e.target.value });
    props.getMetaData({
      ...dataItem,
      [e.target.name]: e.target.value,
      Synopsis: synopsisData,
      CastAndCrew: castAndCrewData,
      AudioTrack: audioTrackData,
      dynamicField : dynamicFieldData,
      CensorshipDetail: censorshipDetailData,
    });
    // setMetaDataInMainObject();
  }

  const loadEditData = async () => {

    console.log("loadEditData");

    var res = await API.getData(ENTITYNAME.MediaEpisodeMetaData, { query: ['MediaEpisodeSID', '=', parseInt(props.SID)] });
    if (res.success) {
      if (res.data.length > 0 && res.data[0] != undefined) {
        setDataItem({ ...res.data[0]});
        setSynopsisData(res.data[0].Synopsis ?? []);
        setCastAndCrewData(res.data[0].CastAndCrew ?? []);
        setAudioTrackData(res.data[0].AudioTrack ?? []);
        setDynamicFieldData(res.data[0].dynamicField ?? []);
        setCensorshipDetails(res.data[0].CensorshipDetail ?? []);
      
        const saveData = {
          ...res.data[0],
          Synopsis: res.data[0]?.Synopsis,
          CastAndCrew: res.data[0]?.CastAndCrew,
          AudioTrack: res.data[0]?.AudioTrack,
          dynamicField : res.data[0]?.dynamicField,
          CensorshipDetail: res.data[0]?.CensorshipDetail
        }
  
        props.getMetaData(saveData);
      }

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  //not using now
  const setMetaDataInMainObject = (synopsis = synopsisData, castAndCrew = castAndCrewData, audioTrack = audioTrackData, dynamicField = dynamicFieldData, censorshipDetail = censorshipDetailData) => {

    const saveData = {
      ...dataItem,
      Languages: dataItem.Languages && dataItem.Languages.length > 0 ? dataItem.Languages?.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          Description: item.Description
        }
      }) : [],
      PGRating: dataItem.PGRating && Object.keys(dataItem.PGRating).length > 0 ? {
        _id: dataItem.PGRating._id,
        SID: dataItem.PGRating.SID,
        Description: dataItem.PGRating.Description
      } : {},
      CensorRating: dataItem.CensorRating && Object.keys(dataItem.CensorRating).length > 0 ? {
        _id: dataItem.CensorRating._id,
        SID: dataItem.CensorRating.SID,
        Description: dataItem.CensorRating.Description
      } : {},
      MediaTechnicalPrototype: dataItem.MediaTechnicalPrototype && Object.keys(dataItem.MediaTechnicalPrototype).length > 0 ?  {
        _id: dataItem.MediaTechnicalPrototype._id,
        SID: dataItem.MediaTechnicalPrototype.SID,
        Description: dataItem.MediaTechnicalPrototype.Description
      } : {},
      Categories: dataItem.Categories && dataItem.Categories.length > 0 ?  dataItem.Categories?.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          Name: item.Name,
          parent: item.parent
      }}) : [],
      ProductionYear: dataItem.ProductionYear,
      Synopsis: synopsis,
      CastAndCrew: castAndCrew,
      AudioTrack: audioTrack,
      dynamicField : dynamicField,
      CensorshipDetail : censorshipDetail
    }
    console.log(saveData);
    props.getMetaData(saveData);
    props.expand &&  props.expand(true);
  }

  const setSynopsis = (data) => {
    setSynopsisData(() => data)
    setMetaDataInMainObject(data, castAndCrewData,audioTrackData,dynamicFieldData, censorshipDetailData);
  }

  const setCastAndCrew = (data) => {
    setCastAndCrewData(() => data);
    setMetaDataInMainObject(synopsisData, data,audioTrackData,dynamicFieldData, censorshipDetailData);
  }

  const setAudioTrack = (data) => {
    setAudioTrackData(()=> data);
    setMetaDataInMainObject(synopsisData,castAndCrewData, data,dynamicFieldData, censorshipDetailData);
  }

  const setDynamicField = (data) => {
    setDynamicFieldData(()=> data);
    setMetaDataInMainObject(synopsisData,castAndCrewData, audioTrackData,data, censorshipDetailData);
  }

  const setCensorshipDetailData = (data) => {
    setCensorshipDetails (()=> data);
   setMetaDataInMainObject(synopsisData,castAndCrewData, audioTrackData,dynamicFieldData, data);
 }

  return (
    <div>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px" }}>
              <div className="row" style={{ margin: "0px" }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tabNumber}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label={lang.general_label} value="1" />
                        {!isFromMusicForm  && <Tab label={lang.synopsis_label} value="2" />}
                        <Tab label={lang.cast_and_crew_label} value="3" />
                        {!isFromMusicForm  && <Tab label={lang.dynamic_fields_label} value="4" />}
                        {!isFromMusicForm  && <Tab label={lang.audio_track_label} value="5" />}
                        {!isFromMusicForm  && <Tab label={lang.censorship_details_label} value="6" />}
                      </TabList>
                    </Box>
                    <TabPanel value={"1"}>
                      <div className="row" style={{ margin: "5px" }}>
                        <div className="col-9 mt-2">
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="TabView">{lang.pg_rating_label}</label><br />
                                <DropDownList
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  data={pgRatings}
                                  name="PGRating"
                                  textField="Description"
                                  dataItemKey="_id"
                                  value={dataItem.PGRating}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="TabView">{lang.censor_rating_label}</label><br />
                                <DropDownList
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  data={censorRatings}
                                  name="CensorRating"
                                  textField="Description"
                                  dataItemKey="_id"
                                  value={dataItem.CensorRating}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.language_label}</label>
                                <MultiSelect
                                  data={languages}
                                  dataItemKey="_id"
                                  textField="Description"
                                  value={dataItem.Languages}
                                  onChange={onChange}
                                  name={"Languages"}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="TabView">{lang.technical_prototype_label}</label><br />
                                <DropDownList
                                  style={{
                                    backgroundColor: "white",
                                  }}
                                  data={mediaTechnicalPrototypes}
                                  name="MediaTechnicalPrototype"
                                  textField="Description"
                                  dataItemKey="_id"
                                  value={dataItem.MediaTechnicalPrototype}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">

                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.production_year_label}</label>
                                <input type="text" className="form-control form-control-sm" name="ProductionYear" value={dataItem.ProductionYear} onChange={(e) => onChange(e)} required />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.keywords_label}</label>
                                <input type="text" className="form-control form-control-sm" name="Keywords" value={dataItem.Keywords} onChange={(e) => onChange(e)} required />
                              </div>
                            </div>

                          </div>
                          
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.categories_label}</label>
                                <MultiSelect
                                  dataItemKey="_id"
                                  data={categories}
                                  textField="Name"
                                  value={dataItem.Categories}
                                  onChange={onChange}
                                  name={"Categories"}
                                />
                              </div>
                            </div> 
                            <div className="col-6">
                              <div className="form-group">
                                <label htmlFor="">{lang.mamID_label}</label>
                                <input type="text" className="form-control form-control-sm" name="MamID" value={dataItem.MamID} onChange={(e) => onChange(e)} required />
                              </div>
                            </div>        
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    {!isFromMusicForm  && <TabPanel value={"2"}>
                      <MediaEpisodeSynopsisEditForm data={synopsisData ?? []} setSynopsisData={setSynopsis} />
                    </TabPanel>}
                    <TabPanel value={"3"}>
                      <MediaEpisodeCastAndCrewEditForm mediaData={props?.mediaData} data={castAndCrewData ?? []} setCastAndCrew={setCastAndCrew} />
                    </TabPanel>
                    {!isFromMusicForm  && <TabPanel value={"4"}>
                      <DynamicFieldsTab dataItem={dynamicFieldData} setDynamicFieldData={setDynamicField} />
                    </TabPanel>}
                    {!isFromMusicForm  && <TabPanel value={"5"}>
                      <AudioTrack data={audioTrackData ?? []} setAudioTrackData={setAudioTrack} />
                    </TabPanel>}
                    {!isFromMusicForm  && <TabPanel value={"6"}>
                      <MediaEpisodeCensorshipDetailsEditForm  data={censorshipDetailData ?? []} setCensorshipDetailData={setCensorshipDetailData}/>
                    </TabPanel>}
                  </TabContext>
                </Box>
              </div>
            </div>
          </FormElement>
        )} />
    </div>
  )
}

export default MediaEpisodeMataDataEditForm
