import { useState, useEffect } from "react";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ENTITYNAME } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api';
import { useTranslation } from "../../../../locale/useTranslation";
import { utility } from "../../../../framework/utility/utilityProvider";
import { Checkbox } from "@progress/kendo-react-inputs";
import MediaEpisodeSegmentEditForm from "../../../Segment/MediaEpisodeSegmentEditForm";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import BossDialog from "../../../../components/BossDialog";

const VersionTab = (props) => {

    const {mediaEpisode} = props;

    const [showEdit, setShowEdit] = useState(false);
    const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white", wordWrap: "break-word" }
    const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px" }

    const lang = useTranslation();

    const [metaData, setMetaData] = useState({});
    const [mediaEpisodeSegments, setMediaEpisodeSegments] = useState([]);
    const [deletedSegments, setDeletedSegments] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, [mediaEpisode])

    const loadData = async () => {

        setLoading(true);
        let metaDataRes = await API.getData(ENTITYNAME.MediaEpisodeMetaData, { query: ['MediaEpisode_id', '=', mediaEpisode._id] });
        let mediaEpisodeSegmentres = await API.getData(ENTITYNAME.MediaEpisodeSegment, { query: ['MediaEpisodeSID', '=', parseInt(mediaEpisode.SID)] });
        if (metaDataRes.success && metaDataRes.data.length > 0) {
            setMetaData(metaDataRes.data[0]);
        }
        if (mediaEpisodeSegmentres.success) {
            setMediaEpisodeSegments(mediaEpisodeSegmentres.data)
        }
        setLoading(false);

    }

    const onClose = () => {
        setShowEdit(!showEdit);
    }

    const onSave = () => {

        // Saving Segments
        mediaEpisodeSegments.map(async(item) => {
            await API.saveData(ENTITYNAME.MediaEpisodeSegment, { ...item, MediaEpisodeSID: mediaEpisode.SID });
        });

        // Deleting Segments
        deletedSegments.map(async (item) => {
            await API.deleteData(ENTITYNAME.MediaEpisodeSegment, item.SID);
        });

        onClose();

    }

    const handleSegmentData = (segments) => {
        setMediaEpisodeSegments(segments);
    }

    const handleDeletedSegments = (segment) => {
        setDeletedSegments([...deletedSegments, segment]);
    }


    return (
        <>
            <div style={boxStyle}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
                    <h1 style={headingStyle}>Version</h1>
                    <RoundButton icon={'pen'} title={'Edit'} onClick={()=> setShowEdit(true)} />
                </div>
                {loading ? <div style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
                    <i className="fa fa-spinner fa-spin fa-xl " />
                </div> :
                    <div style={{ overflow: "auto" }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Language</th>
                                    <th scope="col">Material ID</th>
                                    <th className="text-nowrap" scope="col">Actual Duration</th>
                                    <th className="text-nowrap" scope="col">Is Default</th>
                                    <th scope="col">Archive</th>
                                    <th className="text-nowrap" scope="col">Is Hard Parted</th>
                                    <th scope="col">Transmission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mediaEpisodeSegments?.map((segment, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-nowrap" style={tableDataStyle}>{segment?.SegmentType?.Description}</td>
                                            <td className="text-nowrap" style={tableDataStyle}>{segment?.Language?.Language?.Description}</td>
                                            <td className="text-nowrap" style={tableDataStyle}>{segment?.SegmentNumber}</td>
                                            <td className="text-nowrap" style={tableDataStyle}>{utility.convertMilisecondsToStringWithFrames(segment?.ActualDuration)}</td>
                                            <td style={tableDataStyle}>
                                                <Checkbox style={{ borderColor: "grey" }} disabled={true} value={segment?.IsDefault} />
                                            </td>
                                            <td style={tableDataStyle}>
                                                <Checkbox style={{ borderColor: "grey" }} disabled={true} value={segment?.Archive} />
                                            </td>
                                            <td style={tableDataStyle}>
                                                <Checkbox style={{ borderColor: "grey" }} disabled={true} value={segment?.IsHardParted} />
                                            </td>
                                            <td style={tableDataStyle}>
                                                <Checkbox style={{ borderColor: "grey" }} disabled={true} value={segment?.TXReady} />
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                }

            </div>



            {showEdit && <BossDialog
                title={'Edit Version'}
                onClose={onClose}
                width={"1000px"}
            >
                <SaveButton onClick={onSave}></SaveButton>
                <CancelButton onClick={onClose}></CancelButton>
                <div className="mt-3">
                    <MediaEpisodeSegmentEditForm SID={mediaEpisode.SID} segmentData={mediaEpisodeSegments} getSegmentData={handleSegmentData} mediaEpisodeEntity={mediaEpisode} audioTrack={metaData.AudioTrack} handleDeletedSegments={handleDeletedSegments} />
                </div>

            </BossDialog>
            }
        </>
    )
}

export default VersionTab;