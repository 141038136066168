import {Outlet, Navigate, useLocation} from 'react-router-dom';
import { LOCALSTORAGE_KEY, ROLETYPE } from './framework/constant/constant';
import { utility } from './framework/utility/utilityProvider';

const PrivateRoute=()=>{

    const location = useLocation();
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    const isSuperAdmin = user && user.RoleType && user.RoleType.ID == ROLETYPE.SuperAdmin;
    const isAdmin = user && user.RoleType && user.RoleType.ID == ROLETYPE.Admin;
    
    if(utility.getValue(LOCALSTORAGE_KEY.token)===null ){
        return <Navigate to='/' />
    }
    else if(isSuperAdmin || location.pathname == "/home" || location.pathname == "/home/Dashboard" || (isAdmin && utility.isCurrentCompanyModuleEnabledByPath(location, company))  || (user.Module != undefined && user.Module.length > 0 && user.Module.some(x => x.path != undefined && location.pathname.toLowerCase().includes(x.path.toLowerCase())))){
        return <Outlet />
    }
    else
    {
        // return <Outlet />
        return <Navigate to='/error' />
    }
}

export {PrivateRoute}