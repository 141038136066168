/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { Form, FormElement } from '@progress/kendo-react-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as API from "../../framework/API/api"
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE } from '../../framework/constant/constant';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { EditPageHeader } from '../../components/EditPageHeader';
import { FCT } from './CampaignEditForm/FCT';
import { Billing } from './CampaignEditForm/Billing';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import moment from 'moment';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import { utility } from '../../framework/utility/utilityProvider';
import DynamicDropdown from '../../framework/forms/helpercomponents/dropdown/DynamicDropdown';
import CampaignTypeEditForm from '../masters/CampaignTypeEditForm';
import DealTypeEditForm from '../masters/DealTypeEditForm';
import LocationEditForm from '../masters/LocationEditForm';
import SponsorTypeEditForm from '../masters/SponsorTypeEditForm';
import { AgencyEditForm } from './AgencyEditForm';
import ClientEditForm from './ClientEditForm';
import CurrencyEditForm from '../masters/CurrencyEditForm';
import UserEditForm from '../../framework/OTT/UserEditForm';
import ProductEditForm from '../masters/ProductEditForm';
import BossDialog from '../../components/BossDialog';

const CampaignEditForm = (props) => {

  //dropdown
  const [campaignType, setCampaignType] = useState([]);
  const [agency, setAgency] = useState([]);
  const salesExecutiveForFilter = useRef([]);
  const [client, setClient] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [product, setProduct] = useState([]);
  const [dealType, setDealType] = useState([]);
  const [location, setLocation] = useState([]);
  const [salesExecutive, setSalesExecutive] = useState([]);
  const [sponsorTypes, setSponsorTypes] = useState([]);


  //openSelectionCollection
  const [openChannels, setOpenChannels] = useState(false);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [openCommercials, setOpenCommercials] = useState(false);
  const [selectedCommercials, setSelectedCommercials] = useState([]);
  const [fctData, setFCTData] = useState([]);
  const [selectedBilling, setSelectedBilling] = useState([]);
  const [openFCT, setOpenFCT] = useState();
  const [openBilling, setOpenBilling] = useState();

  const [tabNumber, setTabNumber] = React.useState("1");
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalSpots, setTotalSpots] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [editMode, setEditMode] = useState({});
  // const [disable, setDisable] = useState(false);

  const navigate = useNavigate();
  const loc = useLocation();
  const { SID } = useParams();
  const lang = useTranslation();

  let blankDataItem = {
    SID: SID,
    CampaignNo: 'Auto-Generated',
    Title: '',
    TargetBudget: 0,
    TargetSpots: 0,
    TargetSeconds: 0,
    Commission: 0,
    OrderDate: moment(new Date()).format('YYYY-MM-DD'),
    PeriodFrom: moment(new Date()).format('YYYY-MM-DD'),
    PeriodTo: moment(new Date()).format('YYYY-MM-DD'),
    CampaignType: {},
    Agency: {},
    Client: {},
    Currency: {},
    Product: {},
    DealType: {},
    Channels: [],
    Commercials: [],
    Location: {},
    Discount: 0,
    ReferenceNo: '',
    SalesExecutive: {},
    SponsorType: {},
    Term: '',
    IsDraft: false

  }

  const [dataItem, setDataItem] = useState(blankDataItem);

  const defaultFormState = {
    campaignType: false,
    dealType: false,
    product: false,
    client: false,
    agency: false,
    location: false,
    salesExecutive: false,
    sponsorTypes: false,
    product: false,
    currency: false
  }
  const [showEditForm, setShowEditForm] = useState(defaultFormState);

  useEffect(() => {
    loadcombo();
  }, []);

  const loadcombo = async (isSilent = false) => {

    let campaignType = await API.getDataLookup(ENTITYNAME.CampaignType, { sort: { Description: 1 } });
    setCampaignType(campaignType.data);
    let client = await API.getDataLookup(ENTITYNAME.Client, { sort: { Name: 1 }, query: ["Archive", "!=", true] });
    setClient(client.data);
    let currency = await API.getDataLookup(ENTITYNAME.Currency, { sort: { Description: 1 } });
    setCurrency(currency.data);
    let product = await API.getDataLookup(ENTITYNAME.Product, { sort: { Name: 1 } });
    setProduct(product.data);
    let dealType = await API.getDataLookup(ENTITYNAME.DealType, { sort: { Description: 1 } });
    setDealType(dealType.data);
    let location = await API.getDataLookup(ENTITYNAME.Location, { sort: { Name: 1 } });
    setLocation(location.data);
    let salesExecutive = await API.getDataLookup(ENTITYNAME.Users, { sort: { Name: 1 }, query: ["IsSalesExecutive", "=", true] });
    salesExecutiveForFilter.current = (salesExecutive.data);
    let sponsorRes = await API.getDataLookup(ENTITYNAME.SponsorType);
    setSponsorTypes(sponsorRes.data);

    if(isSilent) return;
    //
    let channelRes = await API.getValidChannels();
    setSelectedChannels([utility.getDefaultItem(channelRes.data)])

    

    if (SID == 0) {
      setDataItem({ ...dataItem, Product: utility.getDefaultItem(product.data), Currency: utility.getDefaultItem(currency.data), CampaignType: utility.getDefaultItem(campaignType.data), DealType: utility.getDefaultItem(dealType.data), Location: utility.getDefaultItem(location.data) });
    }

    if (props.fromBooking) {
      loadEditData();
    } else {
      if (SID > 0) {
        loadEditData();
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const onChange = (e) => {

    if (e.target.name == "Client") {
      Array.isArray(e.target.value.Agency) ? setAgency(e.target.value.Agency) : setAgency([e.target.value.Agency])
      let tempSalesExecutive = [];
      if (e.target.value.SalesExecutive.length > 0) {
        e.target.value.SalesExecutive?.map((id) => {
          tempSalesExecutive.push(salesExecutiveForFilter.current?.find((x) => x._id == id) ?? {})
        })
      }
      setDataItem({ ...dataItem, [e.target.name]: e.target.value, SalesExecutive: {} });
      setSalesExecutive(tempSalesExecutive)
    }

    else if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }

    else if (e.target.name == "IsTaxApplicable") {
      setDataItem({ ...dataItem, IsTaxApplicable: !dataItem.IsTaxApplicable });
    }

    else setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const loadEditData = async () => {
    let sid = props?.fromBooking ? props?.data?.SID : SID;
    let res = await API.getEntity(ENTITYNAME.Campaign, parseInt(sid));
    console.log(res.data);
    if (res.success) {
      setDataItem({
        ...blankDataItem,
        ...res.data,
        Title: props.fromBooking ? res.data.Title : loc?.state?.copy ? 'Copy of ' + res?.data?.Title : res?.data?.Title,
        CampaignNo: props?.fromBooking ? res?.data?.CampaignNo : loc?.state?.copy ? 'Copy of ' + res?.data?.CampaignNo : res?.data?.CampaignNo,
        OrderDate: moment(new Date(res?.data?.OrderDate)).format('YYYY-MM-DD'),
        PeriodFrom: moment(new Date(res?.data?.PeriodFrom)).format('YYYY-MM-DD'),
        PeriodTo: moment(new Date(res?.data?.PeriodTo)).format('YYYY-MM-DD'),
        Term: props?.fromBooking ? res?.data?.Term : loc?.state?.copy ? 'Copy of ' + res?.data?.Term : res?.data?.Term,
      });
      let commercialRes = await API.getData(ENTITYNAME.MediaEpisode, {query: ["_id",  "in", res?.data?.Commercials?.map(item => item?._id)]});
      // SETTING GRIDS
      setSelectedChannels(() => res.data.Channels);
      setSelectedCommercials(() => commercialRes?.data?.length > 0 ? commercialRes?.data : []);
      setFCTData(() => res.data.FctDetail);
      setSelectedBilling(() => res.data.BillingLine);

      // setSalesExecutive(clientForFilter.current.find(x => x._id == res.data.Client._id)?.SalesExecutive)

      // LOADING ACTUAL BOOKING
      let totalSpotRate = 0;
      let totalDyrationSeconds = 0;
      let resBooking = await API.getData(ENTITYNAME.Booking, { query: [['Campaign_Id', '=', res.data._id], ['Purge', '=', false]] });
      if (resBooking.data.length > 0) {
        //If booking avaliable disable Agency or client 
        // setDisable(true);
        resBooking.data.map((obj, index) => {
          let rate = obj.SpotRate.length == 0 ? 0 : obj.SpotRate;
          //total budget
          totalSpotRate = totalSpotRate + parseFloat(rate);
          //total durationc
          totalDyrationSeconds = totalDyrationSeconds + parseInt(obj.MediaEpisode?.Duration ?? 0) / 1000


        })
        //total spots
        setTotalSpots(resBooking.data.length);
        setTotalBudget(parseFloat(totalSpotRate).toFixed(2));
        setTotalSeconds(parseFloat(totalDyrationSeconds).toFixed(2));
      }

    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const onCancelEdit = () => {
    if (props.fromBooking) {
      props.onCloseEdit();
    } else {
      navigate(-1);
    }
  }

  const isValid = () => {

    // if (dataItem.CampaignNo == "" || dataItem.CampaignNo == undefined || !dataItem.CampaignNo.trim()) {
    //   toast.error(`${lang.please_enter_campaign_no_error_message}`, {
    //     position: toast.POSITION.TOP_RIGHT
    //   });
    //   return false;
    // }
    if (dataItem.ReferenceNo == "" || dataItem.ReferenceNo == undefined || !dataItem.ReferenceNo.trim()) {
      toast.error(`${lang.please_enter_reference_no_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.OrderDate)) {
      toast.error(`${lang.please_select_order_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Title == "" || dataItem.Title == undefined || !dataItem.Title.trim()) {
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.PeriodFrom)) {
      toast.error(`${lang.please_select_period_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.PeriodTo)) {
      toast.error(`${lang.please_select_period_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.PeriodFrom > dataItem.PeriodTo) {
      toast.error(`${lang.period_from_should_be_less_then_periodto_campaign_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.OrderDate > dataItem.PeriodFrom) {
      toast.error(`${lang.order_date_error_message} `, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.CampaignType.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_campaign_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.DealType.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_deal_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Agency.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_agency_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Client.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_client_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Location.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_location_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.SalesExecutive.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_sales_executive_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Product.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_product_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Commission > 100) {
      toast.error(`${lang.commission_exceeded_from_limit_campaign_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Commission < 0) {
      toast.error(lang.commission_negative_value_campaign_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Discount > 100) {
      toast.error(`${lang.discount_exceeded_from_limit_campaign_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Discount < 0) {
      toast.error(lang.discount_negative_value_campaign_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.TargetBudget == "" || dataItem.TargetBudget == undefined || dataItem.TargetBudget < 1) {
      toast.error(`${lang.target_budget_should_be_greater_then_zero}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.TargetSpots == "" || dataItem.TargetSpots == undefined || dataItem.TargetSpots < 1) {
      toast.error(`${lang.target_spots_should_be_greater_then_zero}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.TargetSeconds == undefined || dataItem.TargetSeconds < 1) {
      toast.error(lang.target_seconds_should_be_greater_then_zero_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.Currency.hasOwnProperty('_id')) {
      toast.error(`${lang.please_select_Currency_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (selectedChannels.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_channel_campaign_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }
    if (selectedCommercials.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_commercials_campaign_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }


    //FOR TARGET BUDGET VS ACTUAL BUDGET
    if (parseFloat(dataItem.TargetBudget) < parseFloat(totalBudget)) {
      toast.error(`${lang.campaign_target_budget_actual_budget_booking_error}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }

    //FOR TARGET SPOTS VS ACTUAL SPOTS
    if (parseInt(dataItem.TargetSpots) < parseInt(totalSpots)) {
      toast.error(`${lang.campaign_target_spots_actual_spots_booking_error}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }

    //FOR TARGET SECONDS VS ACTUAL SECONDS
    if (parseFloat(dataItem.TargetSeconds) < parseFloat(totalSeconds)) {
      toast.error(`${lang.campaign_target_seconds_actual_seconds_booking_error}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false
    }


    return true;
  }

  const onSaveData = async (isDraft = false) => {

    if (!isDraft && !isValid()) return;

    const data = {
      _id: dataItem._id,
      SID: props?.fromBooking ? props?.data?.SID : loc?.state?.copy ? 0 : parseInt(SID),
      CampaignNo: dataItem.CampaignNo,
      Title: dataItem.Title,
      Term: dataItem.Term,
      TargetBudget: dataItem.TargetBudget,
      TargetSpots: dataItem.TargetSpots,
      TargetSeconds: dataItem.TargetSeconds,
      Commission: dataItem.Commission,
      OrderDate: new Date(dataItem.OrderDate).getTime(),
      PeriodFrom: new Date(dataItem.PeriodFrom).getTime(),
      PeriodTo: new Date(dataItem.PeriodTo).getTime(),
      CampaignType: {
        _id: dataItem.CampaignType._id,
        SID: dataItem.CampaignType.SID,
        Description: dataItem.CampaignType.Description
      },
      Agency: {
        _id: dataItem.Agency._id,
        SID: dataItem.Agency.SID,
        Name: dataItem.Agency.Name,
        DefaultTax: dataItem.Agency.DefaultTax
      },
      Client: {
        _id: dataItem.Client._id,
        SID: dataItem.Client.SID,
        Name: dataItem.Client.Name,
        DefaultTax: dataItem.Client.DefaultTax
      },
      Currency: {
        _id: dataItem.Currency._id,
        SID: dataItem.Currency.SID,
        Description: dataItem.Currency.Description,
      },
      Product: {
        _id: dataItem.Product._id,
        SID: dataItem.Product.SID,
        Name: dataItem.Product.Name
      },
      DealType: {
        _id: dataItem.DealType._id,
        SID: dataItem.DealType.SID,
        Description: dataItem.DealType.Description,
      },
      Channels: selectedChannels.map(({ _id, SID, EPGCode, FullChannelName }) => ({ _id, SID, EPGCode, FullChannelName })),
      Commercials: selectedCommercials.map(({ _id, SID, AssetId, Title, Duration, Brand, Product }) => ({ _id, SID, AssetId, Title, Duration, Brand, Product })),
      Location: {
        _id: dataItem.Location._id,
        SID: dataItem.Location.SID,
        Name: dataItem.Location.Name
      },
      SponsorType: {
        _id: dataItem.SponsorType._id,
        SID: dataItem.SponsorType.SID,
        Description: dataItem.SponsorType.Description
      },
      Discount: dataItem.Discount,
      ReferenceNo: dataItem.ReferenceNo,
      FctDetail: fctData,
      BillingLine: selectedBilling,
      SalesExecutive: { _id: dataItem.SalesExecutive._id, name: dataItem.SalesExecutive.name, PhoneNo: dataItem.SalesExecutive.PhoneNo, Email: dataItem.SalesExecutive.Email },
      Archive: dataItem.Archive ?? false,
      IsTaxApplicable: dataItem.IsTaxApplicable ?? false,
      IsDraft: isDraft ?? false,
      checkDuplicate: true,
      query: [["CampaignNo", "=", dataItem.CampaignNo]]

    }

    //when create from copy is true
    if (!props.fromBooking) {
      loc?.state?.copy && delete data?._id;
    }

    let res = await API.saveData(ENTITYNAME.Campaign, data);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_CAMPAIGN : LOGEVENT.INSERT_CAMPAIGN, module: MODULE.CAMPAIGN, data: res.data, message: res.message };
    API.SaveLogs(logData);
    props?.fromBooking && props.setCampaignData(res.data);
    setSelectedChannels([]);
    setSelectedCommercials([]);
    setFCTData([]);
    setSelectedBilling([]);
    props?.fromBooking ? props.onCloseEdit() : navigate(-1);
  };

  const deleteSelectedChannel = async (item) => {
    if (dataItem._id != undefined && dataItem.SID > 0) {
      let resBooking = await API.getData(ENTITYNAME.Booking, { query: [['Campaign_Id', '=', dataItem._id], ['Purge', '=', false], ['Channel.SID', '=', item.SID]] });
      if (resBooking.data.length > 0) {
        toast.error(`${lang.cannot_delete_channel_campaign_already_has_booking_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }
    setSelectedChannels(selectedChannels.filter((obj) => item.SID != obj.SID));
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const deleteSelectedCommercial = async (item) => {
    if (dataItem._id != undefined && dataItem.SID > 0) {
      let resBooking = await API.getData(ENTITYNAME.Booking, { query: [['Campaign_Id', '=', dataItem._id], ['Purge', '=', false], ['MediaEpisode.SID', '=', item.SID]] });
      if (resBooking.data.length > 0) {
        toast.error(`${lang.cannot_delete_channel_campaign_already_has_booking_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
    }
    setSelectedCommercials(selectedCommercials.filter((obj) => item.SID != obj.SID));
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const MyChannelCommandCell = (props) => (
    <td style={{ padding: "0px" }}>  <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedChannel(props.dataItem), () => { })} /></td>
  )

  const MyCommercialCommandCell = (props) => (
    <td style={{ padding: "0px" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedCommercial(props.dataItem), () => { })} />
    </td>
  )

  const onClickFCT = () => {
    if (!openFCT) {
      setEditMode({})
    }
    setOpenFCT(() => !openFCT);
  }

  const onClickBilling = () => {
    setOpenBilling(() => !openBilling);
  }

  const setFCT = (data) => {
    setFCTData(data);
  }

  const onAddBilling = async (data) => {
    setSelectedBilling(data);
  }

  const commercialGridRowRender = (tr, props) => {

    const trProps = {
      style: props.dataItem.Archive ? { backgroundColor: "#FF0032" } : { backgroundColor: "#FFFFFF" },
    };

    return React.cloneElement(tr, { ...trProps }, tr.props.children);
  };

  const onCreateMasterForm = (key) => {
    setShowEditForm({
      ...defaultFormState,
      [key]: true
    });
  }

  const onCloseMasterForm = () => {
    setShowEditForm(defaultFormState)
  }

  return (
    <>
      <div className='row m-1'>
        <div className='col-12'>
          <EditPageHeader saveButtonTitle={dataItem?.IsDraft ? lang.publish_button_text : lang.save_button_text} title={`${lang.campaign_sub_menu} -> ` + (dataItem.Title ? dataItem.Title : `${lang.new_title_editpageheader}`)} onSubmit={() => onSaveData(false)} onCancel={onCancelEdit} showSpots={((props.fromBooking || SID > 0) && !dataItem?.IsDraft) ? true : false} onShowSpots={() => navigate('/home/ShowSpots', { state: dataItem })} showDraft={(SID == 0 || dataItem?.IsDraft) ? true : false} onSaveDraft={() => onSaveData(true)} />

          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div className='row mt-2' >
                  <div className='col-7' style={{ borderRight: "ridge" }}>
                    <div className='row' style={{ height: "65px", marginBottom: "1px" }}>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.campaign_number_label}</label>
                        <label htmlFor="" style={{ marginLeft: "10px", fontWeight: "bold", color: "rgb(13 41 255 / 91%)" }}>{dataItem.CampaignNo}</label>
                        {/* <input name="CampaignNo" type="text" className="form-control form-control-sm" value={dataItem.CampaignNo} onChange={onChange} /> */}
                      </div>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.reference_no_label} *</label>
                        <input name="ReferenceNo" type="text" className="form-control form-control-sm " value={dataItem.ReferenceNo} onChange={onChange} />
                      </div>

                    </div>

                    <div className='row' >
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.title_label} *</label>
                        <input name="Title" type="text" className="form-control form-control-sm" value={dataItem.Title} onChange={onChange} />
                      </div>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="">{lang.order_date_label}</label>
                          <input type={'date'}
                            name={"OrderDate"}
                            placeholder={""}
                            label={lang.order_date_label}
                            onChange={onChange}
                            value={dataItem.OrderDate}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="">{lang.period_from_label} *</label><br />
                          <input type={'date'}
                            name={"PeriodFrom"}
                            placeholder={""}
                            label={lang.period_from_label}
                            onChange={onChange}
                            value={dataItem.PeriodFrom}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.period_to_label} *</label><br />
                        <input type={'date'}
                          name={"PeriodTo"}
                          placeholder={""}
                          label={lang.period_from_label}
                          onChange={onChange}
                          value={dataItem.PeriodTo}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.campaign_type_label} *</label>
                          <div style={{ display: 'flex' }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={campaignType}
                              name="CampaignType"
                              textField="Description"
                              dataItemKey="_id"
                              defaultValue={campaignType[0]}
                              value={dataItem.CampaignType}
                              onChange={onChange}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('campaignType') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.deal_type_label} *</label>
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={dealType}
                              name="DealType"
                              textField="Description"
                              dataItemKey="_id"
                              defaultValue={dealType[0]}
                              value={dataItem.DealType}
                              onChange={onChange}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('dealType') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.client_label} *</label>
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={client}
                              name="Client"
                              textField="Name"
                              dataItemKey="_id"
                              value={dataItem.Client}
                              onChange={onChange}
                              disabled={SID > 0 && !dataItem.IsDraft}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('client') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.agency_label} *</label>
                          <div style={{ display: 'flex' }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={agency}
                              name="Agency"
                              textField="Name"
                              dataItemKey="_id"
                              value={dataItem.Agency}
                              onChange={onChange}
                              disabled={SID > 0 && !dataItem.IsDraft}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('agency') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="TabView">{lang.location_label} *</label>
                        <div style={{ display: 'flex' }}>
                          <DropDownList
                            style={{ backgroundColor: 'white' }}
                            data={location}
                            name="Location"
                            textField="Name"
                            dataItemKey="_id"
                            defaultValue={location[0]}
                            value={dataItem.Location}
                            onChange={onChange}
                          />
                          <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('location') }}><i className="fa-solid fa-plus fa-xs"></i>
                          </button>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.sales_executive_label} *</label>
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={salesExecutive}
                              name="SalesExecutive"
                              textField="name"
                              dataItemKey="_id"
                              value={dataItem.SalesExecutive}
                              onChange={onChange}
                              disabled={SID > 0 && !dataItem.IsDraft}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('salesExecutive') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "10px" }}>
                          <label htmlFor="TabView">{lang.sponsor_type_label}</label>
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={sponsorTypes}
                              name="SponsorType"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.SponsorType}
                              onChange={onChange}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('sponsorTypes') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label className='mr-2'>{lang.archive}</label>
                          <input name="Archive" type={"checkbox"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.product_label} *</label>
                          <div style={{ display: "flex" }}>
                            <DynamicDropdown
                              data={product}
                              name="Product"
                              textField="Name"
                              dataItemKey="_id"
                              value={dataItem?.Product}
                              onChange={onChange}
                              style={{ backgroundColor: 'white' }}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('product') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                          {/* <DropDownList
                            style={{ backgroundColor: 'white' }}
                            data={product}
                            name="Product"
                            textField="Name"
                            dataItemKey="_id"
                            defaultValue={product[0]}
                            value={dataItem.Product}
                            onChange={onChange}
                          /> */}
                        </div>
                        <div>
                          <label htmlFor="">{lang.term_label}</label>
                          <input name="Term" type="text" className="form-control form-control-sm" value={dataItem.Term} onChange={onChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-5' >
                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.commission_label}</label>
                        <input name="Commission" type="number" className="form-control form-control-sm" value={dataItem.Commission} onChange={onChange} />
                      </div>
                      <div className='col-6' style={{ marginTop: "25px", fontSize: "18px" }}>
                        {lang.actual_booking_label}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.target_budget_label} *</label>
                        <input name="TargetBudget" type="number" className="form-control form-control-sm" value={dataItem.TargetBudget} onChange={onChange} />
                      </div>
                      <div className='col-6' style={{ marginTop: "25px", fontSize: "18px" }}>
                        {totalBudget}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.target_spots_label} *</label>
                        <input name="TargetSpots" type="number" className="form-control form-control-sm" id="Description" value={dataItem.TargetSpots} onChange={onChange} />
                      </div>
                      <div className='col-6' style={{ marginTop: "25px", fontSize: "18px" }}>
                        {totalSpots}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "5px" }}>
                        <label htmlFor="">{lang.target_seconds_label} *</label>
                        <input name="TargetSeconds" type="number" className="form-control form-control-sm" value={dataItem.TargetSeconds} onChange={onChange} />
                      </div>
                      <div className='col-6' style={{ marginTop: "20px", fontSize: "18px" }}>
                        {totalSeconds}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-6">
                        <div className="form-group" style={{ marginBottom: "5px" }}>
                          <label htmlFor="TabView">{lang.currency_label} *</label>
                          <div style={{ display: "flex" }}>
                            <DropDownList
                              style={{ backgroundColor: 'white' }}
                              data={currency}
                              name="Currency"
                              textField="Description"
                              dataItemKey="_id"
                              defaultValue={currency[0]}
                              value={dataItem.Currency}
                              onChange={onChange}
                            />
                            <button type="button" title={lang.create_content_button_tooltip} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                              style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { onCreateMasterForm('currency') }}><i className="fa-solid fa-plus fa-xs"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row'>
                      <div className="form-group col-6" style={{ marginBottom: "25px" }}>
                        <label htmlFor="">{lang.discount_label}</label>
                        <input name="Discount" type="number" className="form-control form-control-sm" value={dataItem.Discount} onChange={onChange} />
                      </div>

                    </div>
                    <div className='row'>
                      <div className="form-group col" style={{ marginBottom: "2px" }}>
                        <label className='mr-2'>{lang.tax_applicable_label}</label>
                        <input name="IsTaxApplicable" type={"checkbox"} value={dataItem.IsTaxApplicable} checked={dataItem.IsTaxApplicable} onChange={onChange} />
                      </div>
                    </div>

                  </div>
                </div>

              </FormElement>

            )} />
        </div>

        <div className='col-12'>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="tabs example">
                  <Tab label={lang.channel_tab_label} value="1" />
                  <Tab label={lang.commercial_label} value="2" />
                  <Tab label={lang.fct_label} value="3" />
                  <Tab label={lang.billing_label} value="4" />
                </TabList>
              </Box>

              <TabPanel value={"1"}>
                <>
                  <AddRoundButton title={lang.add_channel_button_tooltip} style={{ marginLeft: "-15px" }} onClick={() => setOpenChannels(true)} />
                  <Grid data={selectedChannels} style={{ height: "40vh", margin: "5px -15px 40px -15px", }}>
                    <GridColumn cell={MyChannelCommandCell} width={"40px"} text locked={true} />
                    <GridColumn field="FullChannelName" title={lang.channel_name_column} editable={false} />
                    <GridColumn field="EPGCode" title={lang.epg_code_column} editable={false} />
                  </Grid>
                  {openChannels && <CollectionSelection title={lang.channel_collection_header} entityname={ENTITYNAME.Channel} closeForm={() => setOpenChannels(!openChannels)} setDataList={(dataList) => setSelectedChannels((old) => [...old, ...dataList])} width={"50vw"} height={"64vh"} existingItems={selectedChannels} checkDuplicate={true} />}
                </>

              </TabPanel>
              <TabPanel value={"2"}>
                <>
                  <AddRoundButton title={lang.add_commercial_button_tooltip} style={{ marginLeft: "-15px" }} onClick={() => setOpenCommercials(true)} />
                  <Grid data={selectedCommercials} style={{ height: "40vh", margin: "5px -15px 40px -15px" }} rowRender={commercialGridRowRender}>
                    <GridColumn cell={MyCommercialCommandCell} width={"40px"} locked={true} />
                    <GridColumn field="Title" title={lang.title_column} editable={false} />
                    <GridColumn field="Brand.Name" title={lang.brand_page_breadcrumb} editable={false} />
                    <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                    <GridColumn field="Duration" title={lang.duration_column} editable={false} cell={TimeCell} />
                  </Grid>
                  {openCommercials && <CollectionSelection title={lang.commercials_collection_header} entityname={ENTITYNAME.MediaEpisode} wherestatement={[['MediaCategory.SID', '=', MEDIACATEGORIES.Commercial], ['IsBreak', '=', false]]} closeForm={() => setOpenCommercials(!openCommercials)} setDataList={(dataList) => setSelectedCommercials((old) => [...old, ...dataList])} width={"50vw"} height={"64vh"} existingItems={selectedCommercials} checkDuplicate={true} />}
                </>

              </TabPanel>
              <TabPanel value={"3"}>
                <>
                  <FCT closeForm={onClickFCT} totalSeconds={totalSeconds} campaignData={dataItem} data={fctData ?? []} entityname={ENTITYNAME.Campaign} setDataList={setFCT} checkDuplicate={true} />
                </>
              </TabPanel>
              <TabPanel value={"4"}>
                <>
                  <Billing closeForm={onClickBilling} campaignData={dataItem} data={selectedBilling ?? []} entityname={ENTITYNAME.Campaign} setDataList={onAddBilling} checkDuplicate={true} />
                </>

              </TabPanel>
            </TabContext>
            {showEditForm.campaignType && <CampaignTypeEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.dealType && <DealTypeEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.location && <LocationEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.sponsorTypes && <SponsorTypeEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.agency && <AgencyEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.client && <ClientEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.currency && <CurrencyEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
            {showEditForm.salesExecutive && <BossDialog title={lang.sales_dialog_header} onClose={onCloseMasterForm} width={"60vw"}>
            <div><UserEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} IsSalesExecutive={true} quickCloseForm={true}/></div></BossDialog>}
            {showEditForm.product && <ProductEditForm item={{ SID: 0 }} cancelEdit={onCloseMasterForm} refresh={() => loadcombo(true)} />}
          </Box>
        </div>
      </div >
    </>
  )
}

export default CampaignEditForm