import { DropDownList } from '@progress/kendo-react-dropdowns'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../locale/useTranslation'
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton'
import { EditPageHeader } from '../../components/EditPageHeader'
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AVOD_FEATURE, ENTITYNAME, LOGEVENT, MODULE } from '../../framework/constant/constant'
import MyMultiSelect from '../../components/MyMultiSelect'
import * as API from "../../framework/API/api";
import { toast } from 'react-toastify'
import BossDialog from '../../components/BossDialog'
import AddTagsForm from './AddTagsForm'
import { ConfirmDeleteAlert } from '../../ConfirmAlert'
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton'
import { Switch } from '@mui/material'
import { utility } from '../../framework/utility/utilityProvider'

const AvodConfigurationForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        _id: props.item._id,
        SID: props.item.SID,
        Title: props.item.copy ? "Copy of " + props.item.Title : props.item.Title ?? "",
        Segment: props.item.Segment ?? "",
        Platforms: props.item.Platforms ?? "",
    }

    const [dataItem, setDataItem] = useState(blankDataItem)
    const [segments, setSegments] = useState([]);
    const [platforms, setPlateform] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [gridData, setGridData] = useState([])
    const [avodFeatures, setAvodFeatures] = useState([])
    const [avodFeature, setAvodFeature] = useState({})
    const [deleteItems, setDeleteItems] = useState([]);
    const selectedItemRef = useRef(0);
    const setSelectedItemRef = (data) => {
        selectedItemRef.current = data;
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        loadCombo()
        if (props.item.SID > 0) {
            loadEditData()
        }
    }, [])

    const loadEditData = () => {
        setDataItem({ ...props.item, Title: props.item.copy ? "Copy of " + props.item.Title : props.item.Title ?? "" })
        setGridData(props?.item?.Tags ?? [])

        if (props.item.Features) {
            setAvodFeature(props.item.Features);
        }
    }

    const loadCombo = async () => {
        let avodFeatureRes = Object.keys(AVOD_FEATURE).map((x) => {
            return {
                _id: AVOD_FEATURE[x],
                Description: x
            }
        })
        setAvodFeatures(avodFeatureRes)
        let segmentsRes = await API.getDataLookup(ENTITYNAME.OttSegment, { query: ["Archive", '!=', true], sort: { Description: 1 } });
        setSegments(segmentsRes.data);

        let platformRes = await API.getDataLookup(ENTITYNAME.Platform, { query: ['Archive', '!=', true], sort: { Description: 1 } })
        setPlateform(platformRes.data)
    }

    const isValid = () => {
        if (dataItem.Title == undefined || dataItem.Title == "") {
            toast.error(lang.please_select_title_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        if (dataItem.Segment == undefined || dataItem.Segment == "") {
            toast.error(lang.please_select_segment_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        if (dataItem.Platforms == undefined || dataItem.Platforms == "") {
            toast.error(lang.please_select_platform_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        if (gridData.length == 0) {
            toast.error(lang.please_add_atleast_one_Ad_tags_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;

        let tagsData = gridData && gridData.length > 0 ? gridData.map(item => { delete item.index; return item }) : [];

        let finalData = {
            _id: dataItem?._id,
            SID: dataItem?.SID ?? 0,
            Title: dataItem?.Title,
            Segment: dataItem?.Segment && Object.keys(dataItem?.Segment).length > 0 ? {
                _id: dataItem?.Segment?._id,
                Description: dataItem?.Segment?.Description
            } : {},
            Platforms: dataItem?.Platforms.length > 0 ? dataItem?.Platforms?.map((x) => ({
                _id: x?._id,
                Description: x?.Description
            })) : [],
            Features: avodFeature,
            Tags: tagsData
        }
        let res = await API.saveData(ENTITYNAME.AvodConfiguration, finalData)
        console.log(res);
        if (res.success) {
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.AVOD_CONFIGURATION, data: res.data, message: res.message };
            API.SaveLogs(logData);
            utility.deleteLocalStorageItem(ENTITYNAME.AvodConfiguration);
            props.refresh();
            props.cancelEdit();
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const deleteSelectedValue = (index, dataItem) => {
        setDeleteItems([...deleteItems, dataItem]);
        let removeData = [...gridData.slice(0, index), ...gridData.slice(index + 1, gridData.length)]
        setGridData(removeData);
    }

    const editSelectedValue = (item) => {
        setSelectedItemRef(item);
        setShowPopup(true);
    };

    const MyCommandCell = (cell) => {
        return <td>
            <RoundButton icon="trash" Title={lang.delete_button_tooltip} onClick={() => ConfirmDeleteAlert(() => deleteSelectedValue(cell.dataIndex, cell.dataItem), () => { })} />
            <RoundButton icon="pencil" Title={lang.edit_button_tooltip} onClick={() => editSelectedValue({ ...cell.dataItem, index: cell.dataIndex })} />
        </td>
    }

    const handleTagData = (item) => {

        if (gridData.length > 0 && gridData.some(x => x?.Platform?._id == item?.Platform?._id)) {
            toast.error(lang.already_exists_global_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return;
        }
        setGridData((prevGridData) => {
            let updatedData = [...prevGridData];
            if (item.index !== undefined && item.index !== null) {
                updatedData[item.index] = item;
            } else {
                updatedData.push(item);
            }
            return updatedData;
        });
    }

    const onAvodFeatureChange = (e) => {
        setAvodFeature({ ...avodFeature, [e.target.name]: e.target.checked });
    }

    return (
        <BossDialog
            title={props.item.Title ?? `${lang.avod_Configuration_dialog_header}`}
            onClose={props.cancelEdit}
            width={"60vw"}
        >
            <div className="row">
                <div className='col-12'>
                    <EditPageHeader
                        title={dataItem?.Title != "" ? dataItem?.Title : lang.avod_feature_label}
                        onSubmit={handleSubmit}
                        onCancel={props.cancelEdit}
                    />
                    <div className="row mt-2">
                        <div className='col-12'>
                            <label>{lang.title_label} *</label>
                            <input type="text" className="form-control form-control-sm"
                                name="Title"
                                onChange={onChange}
                                value={dataItem.Title}
                            />
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-6'>
                            <label>{lang.features_label}</label>
                            <div className='border'>
                                {avodFeatures.map((x) => {
                                    return (
                                        <div className='d-flex ml-3'>
                                            <Switch name={x.Description} checked={avodFeature[x.Description]} value={avodFeature[x.Description]} onChange={onAvodFeatureChange} />
                                            <label className='mt-2'>{x.Description}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <label>{lang.segment_label} *</label>
                                    <DropDownList
                                        style={{ backgroundColor: "white" }}
                                        className='form-control form-control-lm'
                                        data={segments}
                                        name='Segment'
                                        textField='Description'
                                        dataItemKey='_id'
                                        value={dataItem.Segment}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-12'>
                                    <label>{lang.platform_label} *</label>
                                    <MyMultiSelect
                                        style={{ backgroundColor: 'white' }}
                                        data={platforms}
                                        name={"Platforms"}
                                        textField="Description"
                                        dataItemKey="_id"
                                        value={dataItem.Platforms}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <div className="col-12">
                            <AddRoundButton onClick={() => { setShowPopup(true); setSelectedItemRef({}) }} />
                            <span className='float-right' style={{ marginTop: "15px" }}>Ad Tags</span>
                            <Grid style={{ height: "25vh", marginTop: "5px" }} data={gridData}>
                                <Column editable={false} cell={MyCommandCell} width={"100px"} />
                                <Column field="Platform.Description" title={lang.platform_column} />
                                <Column field="AddTag" title={lang.ad_tag_column} />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && <AddTagsForm onClose={() => setShowPopup(false)} handleTagData={handleTagData} data={selectedItemRef.current} />}
        </BossDialog >

    )
}

export default AvodConfigurationForm
